import config from '@/config'
import { requests } from './BaseService'

export const PaymentService = {
  getIntegrations: (locationId: string) =>
    requests(config.restApiUrl).get(`/payments/integrations/${locationId}`),
  getDefaultPaymentProvider: (locationId: string) =>
    requests(config.restApiUrl).get(
      `/payments/integrations/provider/default/public?altType=location&altId=${locationId}`
    ),
}
