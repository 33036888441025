<script setup lang="ts">
import ClassBookingImg from '@/assets/class_booking.svg'
import CollectiveBookingImg from '@/assets/collective-booking.png'
import EventBookingImg from '@/assets/event-booking.png'
import RoundRobinImg from '@/assets/round-robin.png'
import { GroupList } from '@/class/pages/GroupList'
import { CalendarCheck01Icon } from '@gohighlevel/ghl-icons/24/outline'
import { UIButton, UIModal, UIModalHeader } from '@gohighlevel/ghl-ui'

const groupList = new GroupList()

const props = defineProps({
  show: Boolean,
  primaryButtonText: String,
  disabledConfirm: Boolean,
  openUnassignedCalendarModal: Function,
  openTeamCalendarModal: Function,
  openClassBookingCalendarModal: Function,
})

const emit = defineEmits(['onConfirm', 'onModalClose', 'update:show'])

const handleSave = () => {
  emit('onConfirm')
}
const handleCancel = () => {
  emit('onModalClose')
}
</script>

<template>
  <UIModal
    id="generalConfirmationModal"
    :width="700"
    :show="show"
    @update:show="val => $emit('update:show', val)"
  >
    <template #header>
      <UIModalHeader
        id="generalConfirmationModalHeader"
        type="primary"
        :title="$t('settings.calendar.select_calendar_type')"
        :icon="CalendarCheck01Icon"
        @close="handleCancel"
      >
      </UIModalHeader>
    </template>

    <div class="flex justify-center space-x-7 py-8">
      <div
        class="flex h-64 w-56 flex-col items-center justify-between rounded-md border border-gray-400 py-8"
      >
        <div class="flex flex-col items-center space-y-4">
          <img :src="RoundRobinImg" class="w-20" alt="" />
          <div class="w-2/3 text-center text-sm font-medium">
            {{ $t('settings.calendar.round_robin_booking') }}
          </div>
        </div>
        <UIButton
          id="save-contact-button"
          size="small"
          type="primary"
          @click="openTeamCalendarModal"
        >
          {{ $t('settings.calendar.select') }}
        </UIButton>
      </div>

      <div
        class="flex w-56 flex-col items-center justify-between rounded-md border border-gray-400 py-8"
      >
        <div class="flex flex-col items-center space-y-4">
          <img :src="EventBookingImg" class="w-20" alt="" />
          <div class="w-2/3 text-center text-sm font-medium">
            {{ $t('settings.calendar.unassigned_booking') }}
          </div>
        </div>

        <UIButton
          id="save-contact-button"
          size="small"
          type="primary"
          @click="openUnassignedCalendarModal"
        >
          {{ $t('settings.calendar.select') }}
        </UIButton>
      </div>
      <div
        class="flex w-56 flex-col items-center justify-between rounded-md border border-gray-400 py-8"
      >
        <div class="flex flex-col items-center space-y-4">
          <img :src="ClassBookingImg" class="w-20" alt="" />
          <div class="w-3/4 text-center text-sm font-medium">
            {{ $t('settings.calendar.class_booking') }}
          </div>
        </div>

        <UIButton
          id="save-contact-button"
          size="small"
          type="primary"
          @click="openClassBookingCalendarModal"
        >
          {{ $t('settings.calendar.select') }}
        </UIButton>
      </div>
      <div
        class="flex w-56 flex-col items-center justify-between rounded-md border border-gray-400 py-6"
      >
        <div class="flex flex-col items-center space-y-4">
          <img :src="CollectiveBookingImg" class="w-20" alt="" />
          <div class="w-2/3 text-center text-sm font-medium text-gray-400">
            {{ $t('settings.calendar.collective_booking') }}
          </div>
        </div>

        <div class="flex flex-col items-center">
          <div class="mb-4 text-center text-sm font-medium">
            {{ $t('settings.calendar.coming_soon') }}
          </div>
          <UIButton
            id="save-contact-button"
            size="small"
            type="primary"
            :disabled="true"
          >
            {{ $t('settings.calendar.select') }}
          </UIButton>
        </div>
      </div>
    </div>
    <template #footer>
      <!-- <UIModalFooter
        id="generalConfirmationModalFooter"
        negative-text="Cancel"
        @negative-click="handleCancel"
      /> -->
    </template>
  </UIModal>
</template>
