export const defaultPrimarySettings = {
  primaryColor: '#178af6ff',
  backgroundColor: '#ffffff',
  buttonText: 'Schedule Meeting',
  showCalendarTitle: true,
  showCalendarDescription: true,
  showCalendarDetails: true,
}

export const setDefaultPrimarySettings = () => {
  return Object.assign({}, defaultPrimarySettings)
}
