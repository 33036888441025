{
  "settings": {
    "calendar": {
      "calendar_settings": "Configurações do calendário",
      "group": "Grupo",
      "manage_your_calendars_and_groups": "Gerencie seus calendários e grupos",
      "groups": "Grupos",
      "create_group": "Criar Grupo",
      "create_calendar": "Criar Calendário",
      "calendar_name": "Nome do Calendário",
      "group_duration": "Duraçãdo do Grupo",
      "calendar_type": "Tipo do Calendário",
      "action": "Ação",
      "round_robin": "Revezamento",
      "event": "Evento",
      "group_name": "Nome do Evento",
      "calendars": "Calendários",
      "date_updated": "Última atualização",
      "status": "Status",
      "action_dropdown": "Menu de Ações",
      "edit": "Editar",
      "duplicate": "Duplicar",
      "copy_embed_code": "Copiar Código Embutido",
      "copy_permanent_link": "Copiar Link Permanente",
      "copy_new_slug_link": "Copiar Link de Agendamento",
      "copy_scheduling_link": "Copilar Link de Agendamento (Legado Descontinuado)",
      "deactivate_calendar": "Desativar Calendário",
      "move_to_group": "Mover para o Grupo",
      "delete_calendar": "Excluir Calendário",
      "delete_group": "Excluir",
      "delete_this_group": "Excluir este grupo",
      "activate_calendar": "Ativar Calendário",
      "duration": "Duração (mins)",
      "all": "Todos",
      "draft": "Tornar Rascunho",
      "active": "Ativar",
      "rearrange_calendars": "Reorganizar Calendários",
      "deactivate_all_calendars_in_group": "Desativar todos os calendários do grupo",
      "activate_all_calendars_in_group": "Ativar todos os calendários do grupo",
      "no_groups_found": "Nenhum grupo de calendários foi encontrado",
      "no_groups_in_location": "Comece a usar o Grupo criando um novo grupo de calendários",
      "no_calendars_found": "Comece a usar o Calendário criando um novo calendário",
      "no_calendars_found_search": "Nenhum calendário foi encontrado",
      "delete_calendar_message": "Você está prestes a excluir este Calendário. Todos os agendamentos dentro dele serão excluídos também",
      "delete_calendar_message_title": "Excluir este Calendário?",
      "no_calendars_found_title": "Nenhum Calendário Encontrado",
      "no_groups_in_location_title": "Nenhum Grupo Encontrado",
      "delete_group_confirmation_title": "Excluir este Grupo?",
      "delete_group_confirmation": "Tem certeza de que deseja excluir o grupo selecionado?",
      "delete_group_confirmation_checkbox_title": "Excluir calendários e compromissos associados",
      "delete_group_confirmation_checkbox_description": "Selecionar esta opção também excluirá os calendários deste grupo, juntamente com todos os compromissos nesses calendários",
      "group_status_message_title": "{status} este Grupo?",
      "calendar_status_message_title": "{status} este Calendário?",
      "calendar_status_message": "Você está prestes a {status} o Calendário",
      "group_status_message": "Você está prestes a {status} o Grupo",
      "cancel": "Cancelar",
      "new_service_menu": "Novo Cardápio de Serviços",
      "service_menus": "Menu de Serviços",
      "manage_your_calendars_groups_and_service_menus": "Gerencie seus calendários, grupos e menu de serviços",
      "deactivate_service_menu": "Desativar Menu de Serviços",
      "delete_service_menu": "Excluir este Menu de Serviço",
      "delete_service_menu_confirmation": "Você tem certeza de que quer excluir este menu de serviços?",
      "service_menu_status_message_title": "{status} este Menu de Serviços?",
      "service_menu_status_message": "Você está prestes a {status} Menu de Serviços",
      "no_service_menus_in_location_title": "Crie o seu menu de serviços personalizado",
      "add_new_service_menu": "Adicionar novo menu de serviços",
      "no_service_menus_in_location": "Comece criando um novo menu de serviços e adicionando grupos a ele",
      "no_service_menu_found": "Nenhum menu de serviço encontrado",
      "no_service_menu_found_desc": "Sua busca não correspondeu a nenhum menu de serviços",
      "rooms": "Quartos",
      "equipments": "Equipamentos",
      "share_otl": "Compartilhe sua disponibilidade a cada vez com um link exclusivo que expira após uma reserva, garantindo acesso controlado",
      "generate_link": "Gerar novo link",
      "share_schedule_link": "O link de agendamento é determinado pelo slug. Ajuste o slug e o link de agendamento se adapta automaticamente à modificação",
      "share_permanent_link": "Ideal para funis, redirecionamentos de site ou anúncios, o link permanente permanece constante, sem ser afetado por mudanças de slug",
      "copy": "Cópia",
      "share_embed_code": "Coloque este código em seu HTML onde você deseja que seu widget {embedType} apareça",
      "scheduling_link": "Link de agendamento",
      "permanent_link": "Link Permanente",
      "one_time_link": "Link de uso único",
      "embed_code": "Código de incorporação",
      "loading": "Carregando...",
      "round_robin_booking": "Round Robin",
      "round_robin_description": "Distribui agendamentos entre os membros da equipe em uma ordem rotativa",
      "round_robin_example": "Por exemplo: chamadas de vendas, sessões de integração",
      "unassigned_booking": "Calendário de Eventos",
      "unassigned_description": "Para agendar eventos físicos sem associação de anfitrião",
      "unassigned_example": "Por exemplo: Conferências, seminários públicos, exposições",
      "class_booking": "Reserva de Classe",
      "class_booking_description": "Um anfitrião encontra-se com vários participantes",
      "class_booking_example": "Por exemplo: Webinars, treinamentos em grupo, aulas online",
      "collective_booking": "Reserva Coletiva",
      "collective_booking_description": "Múltiplos anfitriões se encontram com um participante",
      "collective_booking_example": "Por exemplo: Entrevistas em painel, avaliações de comitê",
      "service_booking": "Agendamento de Serviço",
      "service_booking_description": "Agendamento contínuo para empresas baseadas em serviços",
      "service_booking_example": "Por exemplo: agendamentos de spa, serviços de reparo, consultas",
      "personal_booking": "Reserva pessoal",
      "personal_booking_description": "Agenda reuniões individuais com um membro específico da equipe",
      "personal_booking_example": "Por exemplo: Reuniões com clientes, consultas privadas",
      "personal": "Pessoal",
      "explore_more_types": "Explore mais tipos",
      "scheduling_type": "Tipo de agendamento",
      "scheduling_type_description": "Escolha um tipo de agendamento para o seu novo calendário"
    },
    "preferences": {
      "preferences": "Preferências",
      "my_preferences": "Minhas Preferências",
      "account_preferences": "Preferências da Conta",
      "user_preferences": "Preferências do Usuário",
      "set_preferences": "Defina as Preferências da sua conta",
      "scheduling_options": "Opções de Agendamento",
      "meeting_location": "Local da reunião",
      "zoom": "Zoom",
      "save_preferences": "Salvar Preferências",
      "in_app_preferences": "Preferências do aplicativo",
      "set_start_day": "Defina preferências do aplicativo, como por exemplo, o dia inicial",
      "view_options": "Opções de visualização",
      "week_starts_on": "A semana começa em",
      "sunday": "Domingo",
      "widget_preferences": "Preferências do widget",
      "set_widget_language": "Defina preferências do widget, como por exemplo, o idioma",
      "language_and_region": "Idioma e região",
      "language": "Idioma",
      "english": "Inglês",
      "time_format": "Formato de hora",
      "time_format_value": "Sat Dec 30 1899 13:30:00 GMT+0521 (India Standard Time)",
      "monday": "Segunda-feira",
      "custom_meeting_location": "Local de reunião personalizado"
    },
    "availability": {
      "availability": "Disponibilidade",
      "my_availability": "Minha Disponibilidade",
      "account_availability": "Disponibilidade da Conta",
      "timezone": "Fuso horário",
      "working_hours": "Jornada de trabalho",
      "set_recurring_hours": "Defina sua jornada de trabalho recorrente para reuniões",
      "sunday": "Domingo",
      "monday": "Segunda-feira",
      "tuesday": "Terça-feira",
      "wednesday": "Quarta-feira",
      "thursday": "Quinta-feira",
      "friday": "Sexta-feira",
      "saturday": "Sábado",
      "widget_preferences": "Preferências do widget",
      "set_widget_language": "Defina preferências do widget, como por exemplo, o idioma",
      "language_and_region": "Idioma e região",
      "language": "Idioma",
      "english": "Inglês"
    },
    "connections": {
      "connections": "Conexões",
      "conferencing": "Conferência",
      "my_connections": "Minhas Conexões",
      "account_connections": "Conexões da Conta",
      "connection_preferences": "Preferências de Conexão",
      "manage_calendars_conferencing_channels": "Gerenciar Calendários e canais de Conferência",
      "main_integration_calendar": "Calendário de Integração Principal",
      "event_integration_calendar": "Eventos que forem criados em um calendário do sistema do qual você faz parte também serão criados neste calendário integrado",
      "2way_sync": "Sincronização bidirecional: Criar novos eventos no sistema a partir de novos eventos criados dentro do Calendário de Integração Principal",
      "calendars": "Calendários",
      "google": "Google",
      "outlook": "Outlook",
      "check_for_conflicts": "Verificar Conflitos",
      "choose_accounts_to_connect": "Escolha quais contas você deseja conectar",
      "payments": "Pagamentos",
      "stripe": "Stripe",
      "ads": "Anúncios",
      "local_service_ads": "Anúncios de Serviços Locais",
      "fire_workflow": "Disparar Gatilhos de Fluxo de Trabalho para eventos agendados via sincronização bidirecional",
      "connect_google_outlook": "Conecte sua conta do Google ou Outlook para poder escolher o Calendário principal",
      "execute_automation": "Executar Automação",
      "save": "Salvar",
      "lsa": "Anúncios de Serviços Locais"
    }
  },
  "appointment_modal": {
    "select_a_contact": "Selecionar um Contato",
    "start_time": "Horário de início",
    "end_time": "Horário de término",
    "search_by_name_email_phone_or_company": "Pesquisar por nome, e-mail, telefone ou empresa",
    "add_new": "Adicionar Novo",
    "pick_from_available_contacts": "Ou escolha entre os contatos disponíveis",
    "book_appointment": "Agendar",
    "calendar": "Calendário",
    "repeat": "Repetir",
    "date_and_time": "Data e Hora",
    "close": "Fechar",
    "team_member": "Membro da equipe",
    "recurring_booking": "Agendamento Recorrente",
    "one_slot_booking": "Agendamento Único",
    "event_type": "Tipo de evento",
    "occurrences_booked": "ocorrências serão agendadas",
    "repeats": "Repetições",
    "booking_details": "Detalhes do Agendamento",
    "showing_slots_in_this_timezone": "Mostrando horários deste fuso horário:",
    "update_contact_tz": "Atualizar também o fuso horário do Contato para",
    "date": "Data",
    "slot": "Horário",
    "calendar_default": "Padrão do Calendário",
    "custom": "Personalizado",
    "meeting_notes": "(ex) Notas da reunião para nosso próximo agendamento",
    "out_of_office": "(ex) De recesso por uma semana!",
    "standard": "Padrão",
    "appointment_title": "Título do Agendamento",
    "event_title": "Título do Evento",
    "appt_with_bob": "(ex) Agendamento com o João",
    "vacation": "(ex) Férias",
    "show_notes": "Mostrar Notas",
    "hide_notes": "Ocultar Notas",
    "additional_preferences": "Preferências Adicionais",
    "meeting_location": "Local da reunião",
    "appointment_status": "Status do Agendamento",
    "appt_description": "Descrição do Agendamento",
    "recurring_details": "Detalhes Recorrentes",
    "event_description": "Descrição do Evento",
    "in_contact_timezone": "No fuso horário do contato",
    "vacation_message": "Vai sair de férias? Tirando um tempo livre? Bloqueie os horários do seu calendário para evitar que os clientes agendem compromissos indesejados. Os compromissos existentes ainda estarão no seu calendário",
    "contact": "Contato",
    "delete": "Excluir",
    "cancel": "Cancelar",
    "back": "Voltar",
    "pick_available_contacts": "Ou escolha entre os contatos disponíveis",
    "no_contacts_found": "Nenhum contato encontrado",
    "found": "encontrado",
    "select_one": "Selecione um",
    "tz_tooltip": "Isso importa apenas se você estiver usando Valores Personalizados; como appointment_start_time, appointment_end_time, appointment_timezone, etc... na sua comunicação com seus clientes. Nestes casos os valores mencionados serão convertidos para o horário local deles",
    "meeting_location_tooltip": "O local da reunião será definido conforme configurado no calendário",
    "appt_meeting_location_tooltip": "O usuário pode definir um local da reunião específico para o agendamento",
    "gmeet_tooltip": "Certifique-se de que o respectivo membro da equipe definiu um calendário do Google como seu calendário principal",
    "zoom_tooltip": "Certifique-se de que o respectivo membro da equipe tenha integrado o Zoom",
    "enter_meeting_location": "Digite o local da reunião",
    "save_appointment": "Salvar Agendamento",
    "save_event": "Salvar Evento",
    "search_contacts": "Pesquise por nome, e-mail, telefone ou empresa",
    "recurring_slots": "{recurringSlotsLength}/{count}"
  },
  "popup": {
    "calendar": "Calendário",
    "appointment_owner": "Responsável pelo Agendamento",
    "source": "Fonte",
    "booked_by": "Agendado Por",
    "view_activity_log": "Ver Registro de Atividades",
    "status": "Status",
    "cancel_appointment": "Cancelar Agendamento",
    "google_event": "Evento Google",
    "outlook_event": "Evento Outlook",
    "blocked_slot": "Horário Bloqueado",
    "appointment": "Agendamento",
    "confirmed": "Confirmado",
    "unconfirmed": "Não confirmado",
    "cancelled": "Cancelado",
    "showed": "Compareceu",
    "no_show": "Não Compareceu",
    "invalid": "Inválido"
  },
  "appointment_list": {
    "group_all": "TODOS",
    "group_primary": "PRIMÁRIO",
    "group_user": "USUÁRIOS",
    "group_team": "GRUPOS",
    "group_calendar": "CALENDÁRIOS",
    "all_appointments": "Todos os compromissos",
    "my_calendar": "Meu Calendário",
    "status_all": "Todos",
    "status_confirmed": "Confirmado",
    "status_new": "Novo (ação requerida)",
    "status_show": "Mostrou",
    "status_no_show": "Não compareceu",
    "status_cancelled": "Cancelado",
    "status_invalid": "Inválido",
    "filter_date_added_desc": "Data adicionada (desc)",
    "filter_start_added_desc": "Data de Início (Desc)",
    "filter_start_added_asc": "Data de início crescente a partir de hoje",
    "button_new_appointment": "Nova nomeação",
    "name": "Nome",
    "title": "Título",
    "requested_time": "Tempo Solicitado",
    "date_added": "Data de Adição",
    "calendar": "Calendário",
    "appointment_owner": "Proprietário da nomeação",
    "notes": "Notas",
    "status": "Status",
    "action_view_detail": "Visualizar detalhes",
    "action_view_notes": "Visualizar notas",
    "action_edit": "Editar",
    "action_delete": "Apagar",
    "action_reschedule": "Remarcar",
    "page_previous": "Anterior",
    "page_next": "Próximo",
    "appointment_notes": "Observações da Consulta",
    "close": "Fechar",
    "confirm_delete_header": "Excluir compromisso",
    "confirm_delete_message": "Tem certeza que deseja excluir este compromisso?",
    "confirm_delete_button": "Excluir",
    "confirm_update_header": "Atualizar status do agendamento",
    "confirm_update_confirmed_message": "Você tem certeza de que deseja marcar esta consulta como confirmada?",
    "confirm_update_invalid_message": "Você tem certeza de que deseja marcar este compromisso como inválido?",
    "confirm_update_showed_message": "Tem certeza de que deseja marcar esta consulta como realizada?",
    "confirm_update_noshow_message": "Você tem certeza de que deseja marcar essa consulta como não compareceu?",
    "confirm_update_cancelled_message": "Você tem certeza de que deseja marcar este compromisso como cancelado?",
    "confirm_update_button": "Atualização",
    "disable_menu_warning": "Atualmente, você não possui permissão para visualizar ou editar os detalhes do compromisso, pois o contato associado não está atribuído a você. Por favor, entre em contato com seu administrador para obter assistência adicional",
    "no_contact_warning": "Você não consegue acessar as informações de contato no momento. Isso pode ser porque o contato não está atribuído a você. Por favor, entre em contato com seu administrador para obter assistência",
    "youre_viewing_in": "Currently viewing in",
    "rescheduled": "Remarcado"
  },
  "create_calendar": {
    "calendar_name": "Nome do calendário",
    "calendar_logo": "Logotipo de calendário",
    "select_team_member": "Selecionar membros da equipe",
    "eg_outbound_reach": "(Ex.) Alcance externo",
    "description": "Descrição",
    "amount": "Quantidade",
    "description_placeholder": "Escreva a descrição",
    "meeting_duration": "Duração da reunião",
    "seats_per_class": "Assentos por classe",
    "group": "Grupo",
    "custom_url": "URL personalizada",
    "meeting_invite_title": "Título do convite para reunião",
    "event_color": "Cor do evento",
    "availability_type": "Tipo de disponibilidade",
    "deposit_amount": "Valor do depósito",
    "collect_deposit_amount": "Aceitar pagamento parcial",
    "percentage": "Porcentagem",
    "total_amount": "Valor total",
    "reschedule_preference": "Preferência de remarcação",
    "reschedule_preference_subtext": "Se um contato remarcar um compromisso:",
    "reassign_through_round_robin": "Reatribuir através de round robin",
    "keep_same_assinged_user": "Mantenha o mesmo dono de compromisso",
    "new_appointment_preference": "Nova Preferência de Nomeação",
    "new_appointment_preference_text": "Sempre reservar com o usuário designado de contato",
    "new_appointment_preference_subtext": "Agende novos compromissos com o usuário atribuído ao contato em vez de usar agendamento rotativo"
  },
  "calendar_advanced": {
    "general": {
      "quick_tip": "Dica Rápida",
      "timezone_change_tip": "Fusos horários podem ser alterados a qualquer momento nas preferências de fusos horários",
      "save": "salvar",
      "close": "Fechar",
      "meeting_details": "Detalhes da reunião",
      "availability": "Disponibilidade",
      "forms_and_payment": "Formulários e Pagamentos",
      "notifications_and_additional_options": "Notificações e opções adicionais",
      "connections": "Conexões",
      "customizations": "Personalizações",
      "create": "Criar",
      "edit": "Editar"
    },
    "meeting_details": {
      "meeting_location": "Local da reunião",
      "enter_meeting_location": "Digite o local da reunião",
      "click_to_upload": "Clique para carregar",
      "square": "quadrado",
      "circle": "Círculo",
      "remove_logo": "Remover logotipo",
      "meeting_invite_title_tooltip": "Este é o título do evento do calendário que aparece no Google Calendar, Outlook, Apple Calendar, etc",
      "event_color_tooltip": "Escolha uma cor para este evento de reserva. As cores são sincronizadas com os eventos no Google Calendário",
      "enter_a_name": "Por favor, digite um nome",
      "enter_an_event_title": "Por favor, digite um título para o evento",
      "high_priority": "Alta prioridade",
      "medium_priority": "Média Prioridade",
      "low_priority": "Baixa prioridade",
      "custom": "Personalizado",
      "zoom_not_added": "O usuário ainda não conectou sua conta do Zoom",
      "opt_avail": "Otimizar para disponibilidade",
      "opt_equal": "Otimize para distribuição igual",
      "team_members": "Selecionar membros da equipe",
      "team_member": "Selecione um membro da equipe",
      "meeting_location_required": "Local de encontro é obrigatório",
      "team_member_disabled_recurring_tooltip": "Reuniões recorrentes permitem apenas um membro da equipe. Para adicionar vários membros da equipe, favor desabilitar as reuniões recorrentes.",
      "team_member_disabled_class_booking_tooltip": "Apenas um membro da equipe pode ser adicionado ao calendário de reservas de aula.",
      "enable_contact_assigned_to_setting": "Para habilitar essa configuração, o Formulário deve estar em primeiro lugar na ordem dos widgets. Por favor, reorganize a ordem dos widgets na aba Formulários e Pagamento",
      "reschedule_preference_setting_tooltip": "Escolha a quem a nomeação deve ser atribuída quando um contato remarcar através do widget de agendamento",
      "appointment_preference_tooltip": "Escolha se os novos compromissos devem sempre ser agendados com o usuário atribuído ao contato. Se um contato tiver um usuário atribuído, aquele usuário terá preferência; caso contrário, será uma atribuição em rodízio. Dica: Desative a opção 'Permitir seleção de funcionários' para evitar que os agendadores alterem o membro da equipe no widget de agendamento",
      "add_location": "Adicionar Localização",
      "multiple_locations_neo": "Locais de reunião múltiplos são suportados apenas pelo Neo Widget com o Formulário Padrão",
      "make_owner": "Tornar proprietário",
      "only_one_team_member": "Apenas um membro da equipe é permitido quando várias localizações são adicionadas",
      "only_one_team_member_personal_calendar": "Apenas um membro da equipe é permitido para reservas pessoais",
      "ms_teams_not_added": "O usuário ainda não conectou sua conta do Microsoft Teams"
    },
    "availability": {
      "my_availability": "Minha disponibilidade",
      "set_availability": "Defina sua disponibilidade para o calendário aqui",
      "standard": "Padrão",
      "custom": "Personalizado",
      "to": "Para",
      "copy_to_all": "Copiar para todos",
      "add_time": "Adicionar tempo",
      "recurring_meeting": "Reunião recorrente",
      "recurring_info": "Apenas um usuário por calendário para reserva recorrente",
      "repeat": "Repetir",
      "times_to_repeat": "Vezes para repetir",
      "slots_unavailable": "Se houver slots indisponíveis?",
      "overlap_status": "Status do compromisso para horários sobrepostos",
      "meeting_interval": "Intervalo de reunião",
      "meeting_duration": "Duração da reunião",
      "minimum_scheduling": "Aviso mínimo de programação",
      "date_range": "Intervalo de datas",
      "maximum_bookings_per_day": "Máximo de reservas por dia",
      "maximum_bookings_per_slot": "Reservas máximas por horário",
      "maximum_bookings_per_slot_per_user": "Número máximo de reservas por intervalo de tempo (por usuário)",
      "seats_per_class": "Assentos por turma",
      "pre_buffer_time": "Tempo de pré-buferização",
      "post_buffer_time": "Tempo de buffer de postagem",
      "pre_buffer_tooltip": "Pré-búfer é um tempo adicional que pode ser adicionado antes de um compromisso, permitindo tempo extra para se preparar antes de qualquer reunião",
      "post_buffer_tooltip": "Pós-buffer é o tempo adicional que pode ser adicionado após um compromisso, permitindo mais tempo para finalizar",
      "buffer_time": "Tempo de reserva",
      "daily": "Diariamente",
      "weekly": "Semanal",
      "monthly": "Mensalmente",
      "skip_booking": "Pule a reserva de horários indisponíveis",
      "continue_booking": "Continuar reserva",
      "book_next_available": "Reservar próximo horário disponível",
      "confirmed": "Confirmado",
      "unconfirmed": "Não confirmado",
      "enter_valid_hours": "Por favor, insira um horário válido de funcionamento",
      "enter_valid_avail": "Por favor, insira uma disponibilidade personalizada válida",
      "availability_type_tooltip": "Escolha Disponibilidade Padrão para reservas regulares oferecidas semanalmente. Escolha Personalizado para reservas disponíveis apenas em datas específicas",
      "meeting_interval_tooltip": "O intervalo de reunião reflete o tempo entre os horários de agendamento que serão mostrados no calendário. Para um evento de 30 minutos que deve estar disponível no início de cada hora, o intervalo seria de 1 hora. Para um evento de 30 minutos que deve estar disponível a cada 30 minutos, o intervalo seria de 30 minutos",
      "date_range_tooltip": "Os eventos podem ser agendados nos próximos n dias",
      "maximum_bookings_per_day_tooltip": "Número máximo de compromissos permitidos por dia neste calendário",
      "maximum_bookings_per_slot_tooltip": "Número máximo de compromissos permitidos por horário para o usuário (Se o número de compromissos exceder para o horário dado para o usuário, ele irá trocar para o próximo usuário na fila de rodízio ou marcar o horário como indisponível para o prospecto reservar)",
      "add_days": "Adicionar Dias",
      "month_on_day": "Mensalmente no dia",
      "month_on_last_day": "Mensalmente no último dia",
      "day": "Dia",
      "week": "Semana",
      "month": "Mês",
      "mins": "Minutos",
      "hours": "Horas",
      "days": "Dias",
      "weeks": "Semanas",
      "months": "Meses",
      "meeting_interval_error": "O intervalo da reunião deve ser entre 5 minutos e 12 horas",
      "meeting_duration_error": "A duração da reunião deve ser entre 1 minuto e 12 horas",
      "buffer_time_error": "O tempo de buffer deve ser inferior a 12 horas",
      "subTitle": "Escolha a data para definir horários específicos",
      "heading_1": "Horas Disponíveis Semanalmente",
      "tooltip_1": "Defina sua programação semanal de disponibilidade. Essas são as horas padrão que serão aplicadas de forma consistente em uma base semanal",
      "heading_2": "Quando você está disponível?",
      "unavailable_label": "Indisponível",
      "tooltip_2": "Ajuste sua agenda para datas específicas. Substitua suas horas regulares semanais marcando disponibilidade ou indisponibilidade para as datas selecionadas",
      "date_specific_level": "Adicionar horas específicas de data",
      "specific_hours_button": "Horas específicas de encontro",
      "overlap_error": "Os horários não podem se sobrepor",
      "apply": "Aplicar",
      "copy_times_to": "Copiar vezes para",
      "no_date_specific_heading": "Sem data ou hora específica adicionada",
      "no_date_specific_description": "Você pode adicionar/remover data e horário específicos para a sua disponibilidade",
      "recurring_disabled_conditions_tooltip": "Reuniões recorrentes estão desativadas. Para ativá-las, certifique-se de que o calendário tenha apenas um membro da equipe e que nenhuma hora específica de data seja adicionada",
      "date_specific_hours_disabled_conditions_tooltip": "Para incluir uma data e horário específicos, certifique-se de que as reuniões recorrentes estejam desativadas",
      "recurring_disable_event_calendar_conditions": "Reuniões recorrentes estão desativadas. Para ativá-las e não adicionar horários específicos de datas",
      "service_interval": "Intervalo de serviço",
      "service_interval_tooltip": "O intervalo de reunião determina com que frequência os slots de reserva aparecem no calendário. Por exemplo, para um evento de 30 minutos, um intervalo de 1 hora significa que os slots aparecem a cada hora. Um intervalo de 30 minutos significa que os slots aparecem a cada 30 minutos",
      "service_duration_tooltip": "Isso define a duração do seu compromisso, determinando quanto tempo ele vai durar",
      "minimum_scheduling_tooltip": "Isso define o tempo mínimo necessário para agendar um compromisso por meio do widget de calendário. Por exemplo, se definido para 4 horas, os compromissos não podem ser agendados no período de 4 horas antes do horário de início; as reservas devem ser feitas com pelo menos 4 horas de antecedência",
      "service_duration": "Service duration",
      "look_busy_title": "Finja estar ocupado",
      "look_busy_percentage_description": "Ocultar o número de vagas disponíveis por x%",
      "look_busy_helper_text": "Ocultar uma porcentagem dos seus horários de consulta disponíveis no widget de reserva para parecer mais ocupado e em alta demanda",
      "maximum_bookings_per_slot_tooltip_personal": "Número máximo de consultas permitidas por slot para o usuário"
    },
    "forms_payment": {
      "forms": "Formulários",
      "forms_sub": "Definir preferências do formulário de agendamento de postagens",
      "select_form": "Selecione o formulário",
      "sticky_contacts": "Pré-preencher campos (contatos fixos)",
      "confirmation_page": "Página de confirmação",
      "default": "Padrão",
      "redirect_url": "URL de redirecionamento",
      "thank_you": "Mensagem de agradecimento",
      "enter_url": "Insira o URL",
      "pixel_id": "Identificação do pixel do Facebook (opcional)",
      "auto_confirm": "Autoconfirmar novas reuniões de calendário",
      "configure_payment": "Configure opções de pagamento",
      "accept_payments": "Aceitar pagamentos",
      "test": "Teste",
      "live": "Viver",
      "enable_guests": "Adicionar convidados",
      "add_guest_tooltip": "Adicionar convidado permitirá que seu participante adicione vários convidados ao mesmo compromisso",
      "add_guest_same_form_check": "Envie o mesmo formulário para coletar informações via correio para todos os convidados",
      "payment_tooltip": "O pagamento seria considerado apenas para o participante principal e não para os convidados",
      "default_form": "Padrão (Nome, Sobrenome, E-mail, Telefone, Notas)",
      "select_form_tooltip": "Se você deseja coletar mais informações além do padrão Nome, Sobrenome, Email e Telefone daqueles que fazem reservas neste calendário, crie um formulário personalizado em Sites > Formulários > Criador e, em seguida, selecione-o no menu suspenso abaixo",
      "confirmation_page_tooltip": "Uma vez que alguém faz uma reserva, você pode optar por exibir uma mensagem personalizada na mesma página (Padrão) ou redirecioná-los para uma página de sua escolha (Redirecionar)",
      "add_guest_collect_email": "Com Nome e email",
      "add_guest_count_only": "Contar apenas",
      "custom_email_notification": "Enviar formulário personalizado para os convidados",
      "deposit_amount_error": "O valor do depósito deve ser inferior ao valor total",
      "deposit_percentage_error": "A porcentagem do depósito deve ser menor que 100%",
      "partial_payment_tooltip": "Permitir pagamentos parciais para coletar o valor do depósito dos clientes durante o processo de agendamento",
      "partial_payment_enable_error": "O valor total inserido deve ser maior que 0 para permitir pagamentos parciais",
      "payment_disable_text_integration": "Integre um fornecedor de pagamentos em Pagamentos > Integrações para começar a aceitar pagamentos",
      "payment_disable_text_recurring": "A opção de aceitar pagamento não está disponível para reuniões recorrentes. Desative a opção de reunião recorrente para habilitar pagamentos",
      "date_and_time_selector": "Seletor de Data e Hora",
      "form_selector": "Formulário",
      "order_step": "Passo",
      "widget_order_label": "Ordem do widget",
      "widget_order_tool_tip": "Reorganize a ordem do 'Seletor de Data e Horário' e 'Formulário' no widget do calendário. Simplesmente arraste e reordene de acordo com sua preferência",
      "same_user_assignment_tooltip": "Para habilitar essa configuração, o Formulário deve estar em primeiro lugar no widget. Reorganize o widget para posicionar o Formulário em primeiro lugar e ativar essa configuração",
      "widget_re_order_tooltip": "Reordenar está disponível apenas com o estilo de widget Neo. Altere para Neo para habilitar a reordenação",
      "widget_re_order_disabled_tooltip": "Para rearrumar a ordem dos widgets, por favor desative 'Preferência de novo agendamento (Sempre agendar com o usuário atribuído ao contato)' na seção Configurações Avançadas de Detalhes da Reunião > Membros da Equipe",
      "enable_same_user_assignment": "Atribuir contatos aos respectivos membros da equipe de calendário sempre que uma consulta for marcada",
      "recurring_payment_label": "Pagamento de compromissos recorrentes",
      "first_appointment_only": "Apenas primeira consulta",
      "all_appointments": "Todas as marcações"
    },
    "notifications": {
      "notifications": "Notificações e Opções Adicionais",
      "notifications_sub": "Configurar notificações e opções adicionais",
      "select_notification_type": "Selecione o tipo de notificação",
      "notification_person": "Quem deve receber essa notificação?",
      "contact": "Contato",
      "assigned_user": "Usuário atribuído",
      "emails": "E-mails",
      "alert_email_address": "Endereço de email de alerta",
      "ack_email": "Email de confirmação",
      "send_google_invites": "Permitir ao Google Agenda enviar convites ou atualizações por email aos participantes",
      "send_google_outlook_invites": "Permitir que o Google / Outlook Calendar envie convites e atualize emails aos participantes",
      "assign_user_to_contact": "Atribuir contatos aos respectivos membros da equipe do calendário sempre que uma consulta é agendada",
      "skip_assigning_user_to_contact": "Pular a atribuição de contato se o contato já tiver um usuário atribuído",
      "guest_label_count": "Número de convidados",
      "guest_count_error": "A contagem de convidados deve ser entre 1 e 100",
      "allow_cancellation": "Permitir o Cancelamento da Reunião",
      "allow_reschedule": "Permitir remarcação da reunião",
      "allow_cancellation_tooltip": "Quando ativado, um link de cancelamento será adicionado à seção de notas adicionais e incluído no convite do calendário. Este link expirará como configurado antes do horário de início da reunião, impedindo o agendador de cancelar a reunião",
      "allow_reschedule_tooltip": "Quando ativado, um link de reagendamento será adicionado à seção de notas adicionais e incluído no convite do calendário. Este link expirará conforme configurado antes do horário de início da reunião, impedindo que o reservador reagende a reunião",
      "cancellation_link_expiry": "O link de cancelamento irá expirar",
      "reschedule_link_expiry": "O link de remarcação expirará",
      "before_meeting": "antes da reunião",
      "cancellation_and_reschedule_policy": "Política de Cancelamento e Reagendamento:"
    },
    "connections": {
      "connections_heading": "Sincronização de Calendário Externo",
      "connections_sub": "Sincronizar eventos com calendário externo",
      "link_to_calendar": "Link para calendário",
      "sync_option": "opção de sincronização",
      "execute_automation": "Execute automação",
      "fire_workflow": "Disparadores de Fluxo de Trabalho para Eventos agendados via sincronização de duas vias",
      "reserve_with_google": "Reserve com o Google",
      "connect_lsa": "Conecte-se à sua conta do Local Services Ads",
      "reserve_error": "Atualmente, o Reserve with Google é suportado apenas para os nichos de \"Serviços Domésticos\", \"Imóveis\" e \"Serviços Jurídicos\" (provedores de serviços locais) nos Estados Unidos",
      "one_way": "Sentido único",
      "two_way": "Duas vias",
      "smart": "Inteligente",
      "confirm_remove": "Você tem certeza de que deseja remover o Google Calendar?",
      "read_only_access": "Você não tem permissão para escrever neste calendário. Por favor, selecione um calendário diferente",
      "reserve_with_google_desc": "Este serviço de calendário será listado em reserva com o Google apenas após a integração bem-sucedida",
      "check_integration": "Verifique o status da sua integração"
    },
    "extras": {
      "extras": "Personalizações",
      "extras_sub": "Definir o estilo do widget e outras preferências",
      "calendar_widget_style": "Estilo do widget de calendário",
      "neo": "Neo",
      "classic": "Clássico",
      "insert_custom_code": "Inserir código personalizado",
      "show_seats_per_slot": "Mostrar assentos por slot",
      "allow_reschedule_meeting": "Permitir remarcar a reunião",
      "allow_cancelling_meeting": "Permitir cancelar reunião",
      "additional_notes": "Observações adicionais",
      "cancellation_and_reschedule": "Cancelamento e reagendamento:",
      "allow_staff": "Permitir seleção de equipe durante a reserva",
      "code_block_tooltip": "Scripts de terceiros dentro do bloco HTML podem fornecer funcionalidade poderosa, mas também trazem riscos à privacidade, segurança, desempenho e comportamento da página. Portanto, recomendamos que você revise o conteúdo deste script antes de salvá-lo no calendário"
    }
  },
  "add_guest": {
    "guest_label": "Hóspede(s)",
    "search_placeholder": "Procurar em contatos",
    "add_new": "Adicionar Novo",
    "save": "Salvar",
    "close": "Fechar",
    "name": "Nome",
    "phone": "Telefone",
    "email": "E-mail"
  },
  "languages": {
    "english": "Inglês",
    "dutch": "Holandês",
    "french": "Francês",
    "german": "Alemão",
    "hungarian": "Húngaro",
    "italian": "Italiano",
    "polish": "Polonês",
    "portuguese_brazil": "Português (Brasil)",
    "portuguese_portugal": "Português (Portugal)",
    "spanish": "Espanhol",
    "danish": "Dinamarquês"
  },
  "service_menu_advanced": {
    "new_service_menu_details_header": "Detalhes do Menu de Serviços",
    "select_groups_header": "Selecionar serviços",
    "arrange_groups_header": "Organize seus serviços",
    "new_service_menu": "Novo Cardápio de Serviços",
    "service_menu_sub_title": "Crie um menu de serviços personalizado para seus clientes em apenas alguns cliques",
    "quick_tip": "Você pode adicionar grupos ao menu de serviços",
    "service_menu_name": "Nome do menu de serviços",
    "service_menu_description": "Descrição",
    "service_menu_slug": "URL do serviço",
    "select_groups_calendars": "Selecionar grupos e calendários",
    "select_groups_calendars_desc": "Adicione Serviços ao seu menu de serviços",
    "select_all": "Selecionar Todos",
    "arrange_groups": "Organizar a ordem dos serviços",
    "arrange_groups_desc": "Determine a sequência de serviços no menu de serviços",
    "arrange_services_quicktip": "Para reorganizar calendários dentro do grupo, vá para Grupos > Opções > Reorganizar Calendários",
    "enable_add_guest_desc": "Permite aos clientes incluir hóspedes adicionais ao fazer uma reserva",
    "enable_multiple_service_desc": "Permite que os clientes agendem múltiplos serviços em um único horário",
    "enable_staff_selection_desc": "Permite aos clientes selecionar membros da equipe preferidos ao fazer uma reserva",
    "enable_add_guest": "Permitir Adicionar Convidados",
    "enable_multiple_service": "Permitir Seleção de Múltiplos Serviços",
    "enable_staff_selection": "Habilitar Seleção de Funcionários",
    "additional_options": "Opções adicionais",
    "code_block_tooltip": "Scripts de terceiros dentro do bloco HTML podem fornecer funcionalidades poderosas, mas também trazem riscos à privacidade, segurança, desempenho e comportamento da página. Portanto, recomendamos que você revise o conteúdo desse script antes de salvá-lo no menu de serviços",
    "custom_code_placeholder": "Por favor, insira o código personalizado aqui",
    "insert_custom_code": "Inserir código personalizado",
    "no_service": "Nenhum serviço encontrado",
    "no_service_desc": "Nenhum serviço encontrado. Crie serviços e adicione-os a grupos para criar um menu de serviços",
    "learn_more": "Saber mais",
    "no_service_selected": "Nenhum serviço selecionado",
    "create_service_calendars": "Criar calendários de serviço",
    "alert_description": "Para criar um menu de serviço, é necessário criar calendários de serviço e adicioná-los a grupos",
    "dont_show_again": "Não mostrar isso novamente",
    "service_name_required": "É necessário fornecer o nome do menu de serviços",
    "slug_already_taken": "Esta lesma já está ocupada",
    "slug_required": "Slug é obrigatório",
    "please_enter_name": "Por favor, digite um nome",
    "service_name_placeholder": "Menu Principal",
    "consent_label": "Caixa de seleção de consentimento",
    "consent_input_placeholder": "Adicionar texto de consentimento",
    "consent_tooltip": "Ative essa opção para permitir obter consentimento dos contatos quando eles fornecerem seu número de telefone durante o processo de agendamento de compromissos"
  },
  "widget_configuration": {
    "customise_calendar_widget": "Personalizar widget de calendário",
    "works_with_neo_widget": "Só funciona com o widget Neo",
    "customise_widget_desc": "Customizar a aparência do widget: cor principal, cor de fundo e texto do botão",
    "learn_more": "Saiba mais",
    "primary_settings": "Configurações principais",
    "primary_settings_desc": "Configure a cor primária, cor de fundo e outras preferências de widgets para personalizar o seu widget de calendário",
    "primary_color": "Cor primária",
    "primary_color_desc": "Defina a cor para os botões, datas, intervalos de tempo e ações",
    "background_color": "Cor de fundo",
    "background_color_desc": "Defina a cor de fundo que serve como plano de fundo para o seletor de data e hora e o formulário",
    "button_text": "Texto do botão",
    "button_text_desc": "Personalize o texto exibido no botão ao agendar um compromisso",
    "calendar_title": "Título do calendário",
    "calendar_title_desc": "O nome do seu calendário",
    "calendar_description": "Descrição do calendário",
    "calendar_description_desc": "A descrição inserida",
    "calendar_details": "Detalhes do calendário",
    "calendar_details_desc": "Inclui informações como duração, data e horário, detalhes recorrentes e fuso horário",
    "reset_to_default": "Redefinir para o padrão",
    "preview_widget": "Widget de visualização"
  },
  "eventNotification": {
    "list": {
      "booked": {
        "title": "Notificação de Agendamento Feito (Status: Não Confirmado)",
        "note": "Essa notificação é enviada quando o agendamento não foi confirmado",
        "subTitle": "Notifica quando um agendamento é feito com um status não confirmado"
      },
      "confirmation": {
        "title": "Notificação de Agendamento Marcado (Status: Confirmado)",
        "note": "Esta notificação é enviada quando o compromisso é confirmado",
        "subTitle": "Notifica quando um compromisso é confirmado com sucesso"
      },
      "cancellation": {
        "title": "Notificação de Cancelamento",
        "note": "Esta notificação é enviada quando o agendamento é cancelado, marcado como ausência ou inválido",
        "subTitle": "Alertas quando um compromisso é cancelado"
      },
      "reschedule": {
        "title": "Notificação de reagendamento",
        "note": "Essa notificação é enviada para agendamentos não confirmados, confirmados ou mostrados",
        "subTitle": "Notifica quando um compromisso é reagendado"
      },
      "reminder": {
        "title": "Notificação de lembrete",
        "note": "Essa notificação é enviada para agendamentos não confirmados, confirmados ou exibidos",
        "subTitle": "Envia um lembrete antes da consulta"
      },
      "followup": {
        "title": "Notificação de Acompanhamento",
        "note": "Esta notificação é enviada para agendamentos não confirmados, confirmados ou visualizados",
        "subTitle": "Envia uma mensagem de acompanhamento após o término da consulta"
      }
    },
    "saveToastMsg": "Configurações salvas!",
    "saveToastMsgDesc": "Suas alterações foram salvas com sucesso",
    "email": {
      "noneTemplate": "Nenhum",
      "notificationLabel": "Quem deve receber esta notificação?",
      "sendButtonLabel": "Enviar e-mail de teste",
      "testEmailLabel": "Email de teste",
      "testEmailPlaceholder": "Digite o endereço de e-mail do destinatário para teste",
      "body": "Corpo do e-mail",
      "bodyPlaceholder": "Digite sua mensagem aqui",
      "subject": "Assunto",
      "subjectPlaceholder": "Digite o assunto do e-mail",
      "templateLabel": "Modelo de Email",
      "templatePlaceholder": "Selecione um modelo de e-mail ou comece do zero",
      "alertEmailAddress": "Endereço de e-mail de alerta",
      "addMore": "Adicionar mais",
      "afterAppointmentEnds": "após o término da consulta",
      "beforeAppointmentStarts": "antes do início do compromisso",
      "durationLabel": "Quando você gostaria de acionar a notificação?",
      "disableEmailNotification": "Desativar notificações por e-mail",
      "enableEmailNotification": "Ativar notificações por email",
      "enableSuccess": "Notificação por email ativada!",
      "enableSuccessDesc": "Você agora começará a receber essa notificação",
      "disableSuccess": "Notificação por e-mail desativada!",
      "disableSuccessDesc": "Você não receberá mais esta notificação"
    },
    "inApp": {
      "title": "Configurações de notificação",
      "selectedUser": {
        "label": "Enviar para",
        "helperText": "Esta notificação interna é enviada através da web e aplicativos móveis",
        "placeholder": "Selecionar usuário(s)"
      },
      "enableSuccess": "Notificações no aplicativo ativadas!",
      "enableSuccessDesc": "Você começará a receber essa notificação agora",
      "disableSuccess": "Notificações no aplicativo desativadas!",
      "disableSuccessDesc": "Você não receberá mais essa notificação"
    }
  }
}