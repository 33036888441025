<template>
  <UIModal id="edit-email-notification-modal" :show="showModal" :width="750">
    <template #header>
      <UIModalHeader
        id="edit-email-notification-header"
        class="border-b-2"
        :title="headerTitle"
        @close="closeModal"
      ></UIModalHeader>
    </template>
    <!-- content -->
    <UIModalContent>
      <!-- tabs -->
      <div class="mt-4">
        <UIAlert id="notification-alert" :closable="false">
          <template #content>{{ note }}</template>
        </UIAlert>
      </div>
      <div class="mt-2 flex border-b-2 bg-white px-2">
        <div
          class="w-44 cursor-pointer py-2 text-center text-gray-400"
          :class="{
            'border-b-4 border-blue-600 text-blue-600': selectedTab === 'email',
          }"
          @click="selectedTab = 'email'"
        >
          Email
        </div>
        <!-- <div
          class="w-44 cursor-pointer py-2 text-center text-gray-400"
          :class="{
            'border-b-4 border-blue-600 text-blue-600': selectedTab === 'sms',
          }"
          @click="selectedTab = 'sms'"
        >
          SMS
        </div> -->
        <div
          class="w-44 cursor-pointer py-2 text-center text-gray-400"
          :class="{
            'border-b-4 border-blue-600 text-blue-600': selectedTab === 'inApp',
          }"
          @click="selectedTab = 'inApp'"
        >
          In-App
        </div>
      </div>

      <div v-if="state.isLoading">
        <UISpin
          class="flex h-[100px] items-center justify-center overflow-auto"
        />
      </div>
      <EmailTemplateConfiguratorTab
        v-else-if="selectedTab === 'email'"
        ref="emailConfigurator"
        @update-notification="val => emit('update-notification', val)"
      />
      <InAppConfiguratorTab
        v-else-if="selectedTab === 'inApp'"
        @update-notification="val => emit('update-notification', val)"
      />
    </UIModalContent>
    <!-- footer button -->
    <template #footer>
      <UIModalFooter
        id="modal-footer"
        class="border-t-2 pt-5"
        :disabled="state.saving || state.disableSave"
        :loading="state.saving"
        :positive-text="t('calendar_advanced.general.save')"
        :negative-text="t('calendar_advanced.general.close')"
        @positive-click="save"
        @negative-click="closeModal"
      >
      </UIModalFooter>
    </template>
  </UIModal>
</template>

<script setup lang="ts">
import { EventNotificationService } from '@/class/services/EventNotificationService'
import { useNotify } from '@/components/composition/notification'
import AppState, { getEmailTemplates } from '@/states/app'
import {
  getDefaultNotification,
  getDefaultNotifications,
  eventNotificationState as state,
  updateNotificationState,
} from '@/states/EventNotificationState'
import {
  AltType,
  EmailNotification,
  NotificationChannel,
  ReceiverType,
} from '@/types/notification'
import {
  UIAlert,
  UIModal,
  UIModalFooter,
  UIModalHeader,
  UISpin,
} from '@gohighlevel/ghl-ui'
import { PropType, computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import EmailTemplateConfiguratorTab from './EmailTemplateConfiguratorTab.vue'
import InAppConfiguratorTab from './InAppConfiguratorTab.vue'
const { sendNotification } = useNotify()
const { t } = useI18n()
const selectedTab = ref('email')
const emailConfigurator = ref(null) as any
const emit = defineEmits(['update-notification'])
const emailTitles = {
  booked: {
    title: t('eventNotification.list.booked.title'),
    note: t('eventNotification.list.booked.note'),
  },
  confirmation: {
    title: t('eventNotification.list.confirmation.title'),
    note: t('eventNotification.list.confirmation.note'),
  },
  cancellation: {
    title: t('eventNotification.list.cancellation.title'),
    note: t('eventNotification.list.cancellation.note'),
  },
  reschedule: {
    title: t('eventNotification.list.reschedule.title'),
    note: t('eventNotification.list.reschedule.note'),
  },
  reminder: {
    title: t('eventNotification.list.reminder.title'),
    note: t('eventNotification.list.reminder.note'),
  },
  followup: {
    title: t('eventNotification.list.followup.title'),
    note: t('eventNotification.list.followup.note'),
  },
}

const props = defineProps({
  showModal: Boolean,
  closeModal: {
    type: Function,
    required: true,
  },
  notification: {
    type: Object as PropType<EmailNotification>,
    required: true,
  },
  calendarId: {
    type: String,
    required: true,
  },
})

onMounted(async () => {
  // load email templates
  state.isLoading = true
  state.calendarId = props.calendarId
  state.calendar = AppState.editingCalendar
  state.isEventCalendar = AppState.editingCalendar?.isEventCalendar || false
  state.isCustomFormSelected = !!AppState.editingCalendar?.formId
  await loadEmailTemplate()
  await loadEventNotifications()
  state.isLoading = false
})

const headerTitle = computed(() => {
  return `Edit ${emailTitles[props.notification.type].title}`
})
const note = computed(() => {
  return `${emailTitles[props.notification.type].note}`
})
async function loadEmailTemplate() {
  await getEmailTemplates()
}

async function loadEventNotifications() {
  // get all email notification data
  state.accordions = []
  const defaultDataForAllReceivers = getDefaultNotifications({
    altId: props.calendarId,
    altType: AltType.CALENDAR,
    notificationType: props.notification.type, //confirmation
  })

  const { data } = await EventNotificationService.getEventNotifications({
    altId: props.calendarId,
    altType: AltType.CALENDAR,
    notificationType: props.notification.type,
  }).catch(() => {
    state.isLoading = false
  })

  const emailNotification =
    data?.filter(
      eventNotification =>
        eventNotification.channel === NotificationChannel.EMAIL
    ) || []
  const inAppData =
    data?.find(
      eventNotification =>
        eventNotification.channel === NotificationChannel.IN_APP
    ) || null
  // set email data
  if (emailNotification?.length) {
    defaultDataForAllReceivers.forEach(notification => {
      const found = emailNotification.find(
        eventNotification =>
          eventNotification.receiverType === notification.receiverType
      )
      if (!found) {
        emailNotification.push(
          Object.assign({}, notification, { isActive: false })
        )
      }
    })
    state.eventNotifications = emailNotification
  } else {
    state.eventNotifications = defaultDataForAllReceivers
    // saveEmailNotifications(defaultDataForAllReceivers)
  }
  //set InApp data
  const defaultInAppData = getDefaultNotification({
    altId: props.calendarId,
    altType: AltType.CALENDAR,
    notificationType: props.notification.type, //confirmation
    channel: NotificationChannel.IN_APP,
    receiverType: ReceiverType.ASSIGNED_USER,
  })
  state.inAppNotification = (inAppData || defaultInAppData) as any

  // remove Assigned User notification if it is an event calendar
  state.eventNotifications = state.eventNotifications.filter(
    notification =>
      !(
        state.isEventCalendar &&
        notification.receiverType === ReceiverType.ASSIGNED_USER
      )
  )
  state.eventNotifications = state.eventNotifications.filter(
    ({ receiverType }) =>
      receiverType !== ReceiverType.GUEST ||
      AppState.editCalendarValidationData.enableGuests
  )
  // set accordions initial state
  state.eventNotifications.forEach(notification => {
    state.accordions.push({
      id: notification._id,
      isOpen: false,
      isChecked: false,
    })
  })
  await checkAllNotifications()
}

async function checkAllNotifications() {
  if (
    state.enableNotification &&
    !state.inAppNotification?._id &&
    state.eventNotifications.every(notification => !notification.deleted)
  ) {
    state.eventNotifications.forEach(notification => {
      notification.deleted = !state.enableNotification
    })
    state.inAppNotification.deleted = !state.enableNotification
    await saveEmailNotifications(state.eventNotifications)
    await saveInAppNotifications(state.inAppNotification)
  }
}

async function save() {
  state.saving = true
  try {
    if (selectedTab.value === NotificationChannel.EMAIL) {
      if (emailConfigurator.value) {
        try {
          await emailConfigurator.value.validateAll()
        } catch (error) {
          console.error('Email Configurator Validation Error', error)
          state.saving = false
          return
        }
      }
      const receiverTypes: string[] = Object.values(ReceiverType)
      const payload = receiverTypes
        .map(receiverType => {
          return state[receiverType].state
        })
        .filter(notification => notification)

      await saveEmailNotifications(payload)
    } else if (selectedTab.value === NotificationChannel.IN_APP) {
      const payload = Object.assign(state.inAppNotification)
      await saveInAppNotifications(payload)
    }
  } catch (error) {
    console.error('Save Error', error)
  } finally {
    state.saving = false
  }

  sendNotification({
    type: 'success',
    title: t('eventNotification.saveToastMsg'),
    icon: 'CheckCircleIcon',
  })
}

async function saveInAppNotifications(payload) {
  try {
    if (payload._id) {
      await EventNotificationService.updateEventNotifications(payload)
    } else {
      delete payload._id
      const { data } = await EventNotificationService.createEventNotifications(
        payload
      )
      updateNotificationState(data)
      state.inAppNotification = data[0]
    }
  } catch (error) {
    console.error('InApp Notification Save Error', error)
  }
}

async function saveEmailNotifications(payload: any[]) {
  try {
    const payloadToUpdate = payload.filter(notification => notification?._id)
    const payloadToCreate = payload
      .filter(notification => !notification._id)
      .map(notification => {
        delete notification._id
        return notification
      })
    if (payloadToUpdate.length) {
      const { data } = await EventNotificationService.updateEventNotifications(
        payloadToUpdate
      )
      updateNotificationState(data)
    }
    if (payloadToCreate.length) {
      const { data } = await EventNotificationService.createEventNotifications(
        payloadToCreate
      )
      updateNotificationState(data)
    }
  } catch (error) {
    console.error('Payload update or create Error', error)
    throw error
  }
  return true
}
</script>
