const childUserMenu = (menuString: string) => {
  return {
    label: 'Assigned User',
    value: 'assigned_user',
    children: [
      { label: 'Full Name', value: `{{${menuString}.user.name}}` },
      { label: 'First Name', value: `{{${menuString}.user.first_name}}` },
      { label: 'Last Name', value: `{{${menuString}.user.last_name}}` },
      { label: 'Email', value: `{{${menuString}.user.email}}` },
      { label: 'Phone', value: `{{${menuString}.user.phone}}` },
      {
        label: 'Phone (raw format)',
        value: `{{${menuString}.user.phone_raw}}`,
      },
      {
        label: 'Signature',
        value: `{{${menuString}.user.email_signature}}`,
      },
      // { label: 'Calendar Link', value: `{{${menuString}.user.calendar_link}}` },
      {
        label: 'Twilio Phone',
        value: `{{${menuString}.user.twilio_phone_number}}`,
      },
    ],
  }
}

const userTagOption = {
  label: 'User',
  value: 'user',
  children: [
    { label: 'Full Name', value: '{{user.name}}' },
    { label: 'First Name', value: '{{user.first_name}}' },
    { label: 'Last Name', value: '{{user.last_name}}' },
    { label: 'Email', value: '{{user.email}}' },
    { label: 'Phone', value: '{{user.phone}}' },
    { label: 'Phone (raw format)', value: '{{user.phone_raw}}' },
    { label: 'Signature', value: '{{user.email_signature}}' },
    { label: 'Calendar Link', value: '{{user.calendar_link}}' },
    { label: 'Twilio Phone', value: '{{user.twilio_phone_number}}' },
  ],
}

const appointmentTagOption = {
  label: 'Appointment',
  value: 'appointment',
  children: [
    { label: 'Title', value: '{{appointment.title}}' },
    { label: 'Start Date Time', value: '{{appointment.start_time}}' },
    { label: 'Start Date', value: '{{appointment.only_start_date}}' },
    { label: 'Start Time', value: '{{appointment.only_start_time}}' },
    { label: 'End Date Time', value: '{{appointment.end_time}}' },
    { label: 'End Date', value: '{{appointment.only_end_date}}' },
    { label: 'End Time', value: '{{appointment.only_end_time}}' },
    { label: 'Day of the week', value: '{{appointment.day_of_week}}' },
    { label: 'Month', value: '{{appointment.month}}' },
    { label: 'Name of Month', value: '{{appointment.month_name}}' },
    { label: 'Timezone', value: '{{appointment.timezone}}' },
    {
      label: 'Cancellation Link',
      value: '{{appointment.cancellation_link}}',
    },
    { label: 'Reschedule Link', value: '{{appointment.reschedule_link}}' },
    { label: 'Meeting Location', value: '{{appointment.meeting_location}}' },
    { label: 'Notes', value: '{{appointment.notes}}' },
    {
      label: 'Add to Google Calendar',
      value: '{{appointment.add_to_google_calendar}}',
    },
    {
      label: 'Add to iCal/Outlook',
      value: '{{appointment.add_to_ical_outlook}}',
    },
    {
      label: 'Recurring',
      value: 'recurring',
      children: [
        { label: 'Repeats', value: '{{appointment.recurring.repeats}}' },
        {
          label: 'Times to Repeat',
          value: '{{appointment.recurring.times_to_repeat}}',
        },
      ],
    },
    {
      ...childUserMenu('appointment'),
    },
  ],
}
const membershipParamsMenu = [
  {
    label: 'Email',
    value: '{{membership_contact.email}}',
  },
  {
    label: 'Login URL',
    value: '{{membership_contact.login_url}}',
  },
  {
    label: 'Password',
    value: '{{membership_contact.password}}',
  },
  {
    label: 'Offer Title',
    value: '{{membership_contact.offer_title}}',
  },
]

const calendarOption = {
  label: 'Calendar',
  value: 'calendar',
  children: [{ label: 'Name', value: '{{calendar.name}}' }],
}

export const tagOptions = [
  {
    label: 'Contact',
    value: 'contact',
    children: [
      { label: 'Full Name', value: '{{contact.name}}' },
      { label: 'First Name', value: '{{contact.first_name}}' },
      { label: 'Last Name', value: '{{contact.last_name}}' },
      { label: 'Email', value: '{{contact.email}}' },
      { label: 'Phone', value: '{{contact.phone}}' },
      { label: 'Phone (raw format)', value: '{{contact.phone_raw}}' },
      { label: 'Company Name', value: '{{contact.company_name}}' },
      { label: 'Full Address', value: '{{contact.full_address}}' },
      { label: 'Address 1', value: '{{contact.address1}}' },
      { label: 'City', value: '{{contact.city}}' },
      { label: 'State', value: '{{contact.state}}' },
      { label: 'Country', value: '{{contact.country}}' },
      { label: 'Postal Code', value: '{{contact.postal_code}}' },
      { label: 'Timezone', value: '{{contact.timezone}}' },
      { label: 'Date of birth', value: '{{contact.date_of_birth}}' },
      { label: 'Source', value: '{{contact.source}}' },
      { label: 'Website', value: '{{contact.website}}' },
      { label: 'ID', value: '{{contact.id}}' },
    ],
  },
  userTagOption,
  appointmentTagOption,
  calendarOption,
  {
    label: 'Message',
    value: 'message',
    children: [{ label: 'Message Body', value: '{{message.body}}' }],
  },
  {
    label: 'Account',
    value: 'account',
    children: [
      { label: 'Name', value: '{{location.name}}' },
      { label: 'Full Address', value: '{{location.full_address}}' },
      { label: 'Address Line 1', value: '{{location.address}}' },
      { label: 'City', value: '{{location.city}}' },
      { label: 'State', value: '{{location.state}}' },
      { label: 'Country', value: '{{location.country}}' },
      { label: 'Postal Code', value: '{{location.postal_code}}' },
      { label: 'Email', value: '{{location.email}}' },
      { label: 'Phone', value: '{{location.phone}}' },
      { label: 'Phone (raw format)', value: '{{location.phone_raw}}' },
      { label: 'Website', value: '{{location.website}}' },
      { label: 'Logo URL', value: '{{location.logo_url}}' },
      {
        label: 'Owner',
        value: 'owner',
        children: [
          { label: 'First name', value: '{{location_owner.first_name}}' },
          { label: 'Last name', value: '{{location_owner.last_name}}' },
          { label: 'Email', value: '{{location_owner.email}}' },
        ],
      },
      { label: 'ID', value: '{{location.id}}' },
    ],
  },
  {
    label: 'Right now',
    value: 'right_now',
    children: [
      { label: 'Second', value: '{{right_now.second}}' },
      { label: 'Minute', value: '{{right_now.minute}}' },
      { label: 'Hour 24h format', value: '{{right_now.hour}}' },
      { label: 'Hour AM/PM format', value: '{{right_now.hour_ampm}}' },
      { label: 'Time 24h format', value: '{{right_now.time}}' },
      { label: 'Time AM/PM format', value: '{{right_now.time_ampm}}' },
      { label: 'AM/PM', value: '{{right_now.ampm}}' },
      { label: 'Day', value: '{{right_now.day}}' },
      {
        label: 'Day of the week extended English',
        value: '{{right_now.day_of_week}}',
      },
      { label: 'Month', value: '{{right_now.month}}' },
      { label: 'Name of Month', value: '{{right_now.month_name}}' },
      {
        label: 'Month extended English',
        value: '{{right_now.month_english}}',
      },
      { label: 'Year', value: '{{right_now.year}}' },
      {
        label: 'Date (month/day/year)',
        value: '{{right_now.middle_endian_date}}',
      },
      {
        label: 'Date (day/month/year)',
        value: '{{right_now.little_endian_date}}',
      },
      {
        label: 'Date',
        value: '{{right_now.date}}',
      },
    ],
  },
  {
    label: 'Membership Contact',
    value: 'membership_contact',
    children: membershipParamsMenu,
  },
  {
    label: 'Attribution',
    value: 'attribution',
    children: [
      {
        label: 'First',
        value: 'first',
        children: [
          {
            label: 'Session Source',
            value: '{{contact.attributionSource.sessionSource}}',
          },
          {
            label: 'Campaign',
            value: '{{contact.attributionSource.campaign}}',
          },
          {
            label: 'UTM Source',
            value: '{{contact.attributionSource.utmSource}}',
          },
          {
            label: 'UTM Medium',
            value: '{{contact.attributionSource.utmMedium}}',
          },
          {
            label: 'UTM Content',
            value: '{{contact.attributionSource.utmContent}}',
          },
          {
            label: 'Referrer',
            value: '{{contact.attributionSource.referrer}}',
          },
          {
            label: 'Campaign Id',
            value: '{{contact.attributionSource.campaignId}}',
          },
          {
            label: 'FB ClickId',
            value: '{{contact.attributionSource.fbclid}}',
          },
          {
            label: 'Google ClickId',
            value: '{{contact.attributionSource.gclid}}',
          },
        ],
      },
      {
        label: 'Latest',
        value: 'latest',
        children: [
          {
            label: 'Session Source',
            value: '{{contact.lastAttributionSource.sessionSource}}',
          },
          {
            label: 'Campaign',
            value: '{{contact.lastAttributionSource.campaign}}',
          },
          {
            label: 'UTM Source',
            value: '{{contact.lastAttributionSource.utmSource}}',
          },
          {
            label: 'UTM Medium',
            value: '{{contact.lastAttributionSource.utmMedium}}',
          },
          {
            label: 'UTM Content',
            value: '{{contact.lastAttributionSource.utmContent}}',
          },
          {
            label: 'Referrer',
            value: '{{contact.lastAttributionSource.referrer}}',
          },
          {
            label: 'Campaign Id',
            value: '{{contact.lastAttributionSource.campaignId}}',
          },
          {
            label: 'FB ClickId',
            value: '{{contact.lastAttributionSource.fbclid}}',
          },
          {
            label: 'Google ClickId',
            value: '{{contact.lastAttributionSource.gclid}}',
          },
        ],
      },
    ],
  },
  {
    label: 'Task',
    value: 'task',
    children: [
      { label: 'Title', value: '{{task.title}}' },
      { label: 'Description', value: '{{task.body}}' },
      { label: 'Due Date', value: '{{task.dueDate}}' },
      {
        ...childUserMenu('task'),
      },
    ],
  },

  {
    label: 'Note',
    value: 'note',
    children: [{ label: 'Description', value: '{{note.body}}' }],
  },

  {
    label: 'Shopify',
    value: 'shopify',
    children: [
      {
        label: 'Number',
        value: '{{order.number}}',
      },
      {
        label: 'Created On',
        value: '{{order.created_on}}',
      },
      {
        label: 'Customer',
        value: 'customer',
        children: [
          {
            label: 'First Name',
            value: '{{order.customer.first_name}}',
          },
          {
            label: 'Last Name',
            value: '{{order.customer.last_name}}',
          },
          {
            label: 'Email',
            value: '{{order.customer.email}}',
          },
          {
            label: 'Phone',
            value: '{{order.customer.phone}}',
          },
        ],
      },
      {
        label: 'Products',
        value: 'products',
        children: [
          {
            label: 'IDs',
            value: '{{order.items.ids}}',
          },
          {
            label: 'Titles',
            value: '{{order.items.titles}}',
          },
        ],
      },
      {
        label: 'Total discounts',
        value: '{{order.total_discounts}}',
      },
      {
        label: 'Discount Code',
        value: '{{order.discount_code}}',
      },
      {
        label: 'Total Price',
        value: '{{order.total_price}}',
      },
      {
        label: 'Subtotal Price',
        value: '{{order.subtotal_price}}',
      },
      {
        label: 'Total Shipping Price',
        value: '{{order.total_shipping_price}}',
      },
      {
        label: 'Total Cart Price',
        value: '{{order.total_cart_price}}',
      },
      {
        label: 'Order Status URL',
        value: '{{order.order_status_url}}',
      },
      {
        label: 'Abandoned Checkout URL',
        value: '{{order.abandoned_checkout_url}}',
      },
      {
        label: 'Shipping Address',
        value: '{{order.shipping_address}}',
      },
      {
        label: 'Billing Address',
        value: '{{order.billing_address}}',
      },
      {
        label: 'Has Discount',
        value: '{{order.has_discount}}',
      },
      {
        label: 'Currency Code',
        value: '{{order.currency_code}}',
      },
      {
        label: 'Currency',
        value: '{{order.currency}}',
      },
      {
        label: 'Requires Shipping',
        value: '{{order.requires_shipping}}',
      },
    ],
  },
  {
    label: 'Invoice',
    value: 'invoice',
    children: [
      { label: 'Contact Name', value: '{{invoice.contact_name}}' },
      { label: 'Currency', value: '{{invoice.currency}}' },
      { label: 'Due Date', value: '{{invoice.due_date}}' },
      { label: 'Discount Value', value: '{{invoice.total_discount}}' },
      { label: 'Issue Date', value: '{{invoice.issue_date}}' },
      { label: 'Live Mode', value: '{{invoice.liveMode}}' },
      { label: 'Sender Name', value: '{{invoice.sender_name}}' },
      { label: 'Sender E-mail', value: '{{invoice.sender_email}}' },
      { label: 'Status', value: '{{invoice.status}}' },
      { label: 'Subtotal', value: '{{invoice.subtotal}}' },
      {
        label: 'Total Discount Price',
        value: '{{invoice.total_discount_price}}',
      },
      { label: 'Invoice Number', value: '{{invoice.number}}' },
      { label: 'Total Price', value: '{{invoice.total_price}}' },
      { label: 'URL', value: '{{invoice.url}}' },
    ],
  },
  {
    label: 'Membership',
    value: 'membership',
    children: [
      { label: 'Product Title', value: '{{membership.product.title}}' },
      { label: 'Category Title', value: '{{membership.category.title}}' },
      { label: 'Lesson Title', value: '{{membership.lesson.title}}' },
      { label: 'Lesson URL', value: '{{membership.lesson.url}}' },
      { label: 'Support Email', value: '{{membership.support.email}}' },
      { label: 'Support Phone', value: '{{membership.support.phone}}' },
      { label: 'Site Name', value: '{{membership.support.site_name}}' },
    ],
  },
  {
    label: 'Order',
    value: 'order',
    children: [
      {
        label: 'Order Source',
        value: '{{order.source}}',
      },
      {
        label: 'Currency Symbol',
        value: '{{order.currency_symbol}}',
      },
      {
        label: 'Currency Code',
        value: '{{order.currency_code}}',
      },
      {
        label: 'Customer',
        value: 'customer',
        children: [
          {
            label: 'Id',
            value: '{{order.customer.id}}',
          },
          {
            label: 'First Name',
            value: '{{order.customer.first_name}}',
          },
          {
            label: 'Last Name',
            value: '{{order.customer.last_name}}',
          },
          {
            label: 'Name',
            value: '{{order.customer.name}}',
          },
          {
            label: 'Email',
            value: '{{order.customer.email}}',
          },
          {
            label: 'Phone',
            value: '{{order.customer.phone}}',
          },
          {
            label: 'Full Address',
            value: '{{order.customer.full_address}}',
          },
          {
            label: 'City',
            value: '{{order.customer.city}}',
          },
          {
            label: 'State',
            value: '{{order.customer.state}}',
          },
          {
            label: 'Country',
            value: '{{order.customer.country}}',
          },
          {
            label: 'Postal Code',
            value: '{{order.customer.postal_code}}',
          },
        ],
      },
      { label: 'Order Total', value: '{{order.total_price}}' },
      {
        label: 'Discount Code',
        value: '{{order.discount_code}}',
      },
      { label: 'Has Discount', value: '{{order.has_discount}}' },
      {
        label: 'Total Discount',
        value: '{{order.total_discount}}',
      },
      { label: 'Created on', value: '{{order.created_on}}' },
      {
        label: 'Total Cart Price',
        value: '{{order.total_cart_price}}',
      },
      {
        label: 'Payment Gateway',
        value: '{{order.payment_gateway}}',
      },
    ],
  },
  {
    label: 'Payment',
    value: 'payment',
    children: [
      {
        label: 'Source',
        value: '{{payment.source}}',
      },
      {
        label: 'Currency Symbol',
        value: '{{payment.currency_symbol}}',
      },
      {
        label: 'Currency Code',
        value: '{{payment.currency_code}}',
      },
      {
        label: 'Customer',
        value: 'customer',
        children: [
          {
            label: 'Id',
            value: '{{payment.customer.id}}',
          },
          {
            label: 'First Name',
            value: '{{payment.customer.first_name}}',
          },
          {
            label: 'Last Name',
            value: '{{payment.customer.last_name}}',
          },
          {
            label: 'Name',
            value: '{{payment.customer.name}}',
          },
          {
            label: 'Email',
            value: '{{payment.customer.email}}',
          },
          {
            label: 'Phone',
            value: '{{payment.customer.phone}}',
          },
          {
            label: 'Full Address',
            value: '{{payment.customer.address}}',
          },
          {
            label: 'City',
            value: '{{payment.customer.city}}',
          },
          {
            label: 'State',
            value: '{{payment.customer.state}}',
          },
          {
            label: 'Country',
            value: '{{payment.customer.country}}',
          },
          {
            label: 'Postal Code',
            value: '{{payment.customer.postal_code}}',
          },
        ],
      },
      {
        label: 'Invoice',
        value: 'invoice',
        children: [
          {
            label: 'Name',
            value: '{{payment.invoice.name}}',
          },
          {
            label: 'Number',
            value: '{{payment.invoice.number}}',
          },
          {
            label: 'Issue Date',
            value: '{{payment.invoice.issue_date}}',
          },
          {
            label: 'Due Date',
            value: '{{payment.invoice.due_date}}',
          },
          {
            label: 'URL',
            value: '{{payment.invoice.url}}',
          },
          {
            label: 'Recorded By',
            value: '{{payment.invoice.recorded_by}}',
          },
        ],
      },
      { label: 'Sub Total', value: '{{payment.sub_total_amount}}' },
      {
        label: 'Discount Amount',
        value: '{{payment.discount_amount}}',
      },
      { label: 'Coupon Code', value: '{{payment.coupon_code}}' },
      {
        label: 'Tax Amount',
        value: '{{payment.tax_amount}}',
      },
      { label: 'Created on', value: '{{payment.created_on}}' },
      {
        label: 'Total Amount',
        value: '{{payment.total_amount}}',
      },
      {
        label: 'Transaction Id',
        value: '{{payment.transaction_id}}',
      },
      {
        label: 'Status',
        value: '{{payment.payment_status}}',
      },
      {
        label: 'Gateway',
        value: '{{payment.gateway}}',
      },
      {
        label: 'Card Last 4',
        value: '{{payment.card.last4}}',
      },
      {
        label: 'Card Brand',
        value: '{{payment.card.brand}}',
      },
      {
        label: 'Method',
        value: '{{payment.method}}',
      },
    ],
  },
  {
    label: 'Opportunity',
    value: 'opportunity',
    children: [
      {
        label: 'Pipeline Name',
        value: '{{opportunity.pipeline_name}}',
      },
      {
        label: 'Stage Name',
        value: '{{opportunity.stage_name}}',
      },
      {
        label: 'Status',
        value: '{{opportunity.status}}',
      },
      {
        label: 'Lead Value',
        value: '{{opportunity.lead_value}}',
      },
    ],
  },
]
