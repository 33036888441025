<script setup lang="ts">
import {
  UIForm,
  UIFormItem,
  UIInput,
  UIInputGroup,
  UISelect,
} from '@gohighlevel/ghl-ui'

import { computed, reactive, watch, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import { setErrorInDurationPicker } from '../../states/app'
import { getTooltip } from '../../utils/generalFunctions'

const { t } = useI18n()

const state = reactive({
  isLoading: false,
  isStandardAvailability: true,
  formData: {
    timeUnit: 'mins',
    durationValue: '',
  },
  showCustomRecurrenceModal: false,
  timeUnits: [
    {
      label: t('calendar_advanced.availability.mins'),
      value: 'mins',
    },
    {
      label: t('calendar_advanced.availability.hours'),
      value: 'hours',
    },
    {
      label: t('calendar_advanced.availability.days'),
      value: 'days',
    },
    {
      label: t('calendar_advanced.availability.weeks'),
      value: 'weeks',
    },
    {
      label: t('calendar_advanced.availability.months'),
      value: 'months',
    },
  ],
})

const props = defineProps({
  label: String,
  value: String,
  unit: String,
  disabled: {
    type: Boolean,
    default: false,
  },
  path: {
    type: String,
    default: '',
  },
  tooltip: {
    type: String,
    default: '',
  },
  applyMarginTop: {
    type: Boolean,
    default: true,
  },
})

const emit = defineEmits(['onValueChange'])

const methods = {
  emitValueChange(duration: string, timeUnit: string) {
    emit('onValueChange', {
      duration,
      timeUnit,
    })
  },
}

watch(
  [() => state.formData.durationValue, () => state.formData.timeUnit],
  () => {
    methods.emitValueChange(
      state.formData.durationValue,
      state.formData.timeUnit
    )
  }
)
watchEffect(() => {
  state.formData.durationValue = props.value || ''
  state.formData.timeUnit = props.unit
})

const rules = computed(() => {
  return {
    slotInterval: {
      validator() {
        if (!state.formData.durationValue) {
          setErrorInDurationPicker('slotInterval', true)
          return false
        }

        const slotInterval = parseInt(state.formData.durationValue)

        const slotIntervalInMins =
          state.formData.timeUnit === 'hours' ? slotInterval * 60 : slotInterval

        if (slotIntervalInMins < 5 || slotIntervalInMins > 12 * 60) {
          setErrorInDurationPicker('slotInterval', true)
          return false
        }

        setErrorInDurationPicker('slotInterval', false)
        return true
      },
      trigger: ['input', 'blur'],
      message: t('calendar_advanced.availability.meeting_interval_error'),
    },
    slotDuration: {
      validator() {
        if (!state.formData.durationValue) {
          setErrorInDurationPicker('slotDuration', true)
          return false
        }

        const slotDuration = parseInt(state.formData.durationValue)

        const slotDurationInMins =
          state.formData.timeUnit === 'hours' ? slotDuration * 60 : slotDuration

        if (slotDurationInMins < 1 || slotDurationInMins > 12 * 60) {
          setErrorInDurationPicker('slotDuration', true)
          return false
        }

        setErrorInDurationPicker('slotDuration', false)
        return true
      },
      trigger: ['input', 'blur'],
      message: t('calendar_advanced.availability.meeting_duration_error'),
    },
    slotBuffer: {
      validator() {
        const slotBuffer = parseInt(state.formData.durationValue)

        const slotBufferInMins =
          state.formData.timeUnit === 'hours' ? slotBuffer * 60 : slotBuffer

        if (slotBufferInMins < 0 || slotBufferInMins > 12 * 60) {
          setErrorInDurationPicker('slotBuffer', true)
          return false
        }

        setErrorInDurationPicker('slotBuffer', false)
        return true
      },
      trigger: ['input', 'blur'],
      message: t('calendar_advanced.availability.buffer_time_error'),
    },
    preBuffer: {
      validator() {
        const preBuffer = parseInt(state.formData.durationValue)
        const preBufferInMins =
          state.formData.timeUnit === 'hours' ? preBuffer * 60 : preBuffer
        if (preBufferInMins < 0 || preBufferInMins > 12 * 60) {
          setErrorInDurationPicker('preBuffer', true)
          return false
        }
        setErrorInDurationPicker('preBuffer', false)
        return true
      },
      trigger: ['input', 'blur'],
      message: t('calendar_advanced.availability.buffer_time_error'),
    },
  }
})

const filteredTimeUnits = computed(() => {
  if (
    props.path === 'slotInterval' ||
    props.path === 'slotDuration' ||
    props.path === 'slotBuffer' ||
    props.path === 'preBuffer'
  ) {
    return state.timeUnits.slice(0, 2)
  } else if (props.path === 'allowBookingAfter') {
    return state.timeUnits.slice(1, 5)
  } else if (props.path === 'allowBookingFor') {
    return state.timeUnits.slice(2, 5)
  } else if (
    props.path === 'reschedulePreference' ||
    props.path === 'cancellationPreference'
  ) {
    return state.timeUnits.slice(0, -2)
  }
  return state.timeUnits
})
</script>

<template>
  <UIForm
    id="duration-calendar-form"
    ref="formRef"
    :model="state.formData"
    :rules="rules"
  >
    <UIFormItem
      :label="label"
      class="w-full"
      :class="{ 'mt-4': props.applyMarginTop }"
      :path="path"
      :tooltip="tooltip ? getTooltip(tooltip) : null"
    >
      <UIInputGroup class="w-full" :disabled="disabled">
        <UIInput
          :id="props.path"
          :disabled="disabled"
          :model-value="state.formData.durationValue"
          class="w-96"
          placeholder=""
          @update:model-value="
            state.formData.durationValue = $event.replace(/\D/g, '')
          "
        />
        <UISelect
          :id="props.path + '-unit'"
          :disabled="disabled"
          :value="state.formData.timeUnit"
          class="w-60"
          :options="filteredTimeUnits"
          @update:value="state.formData.timeUnit = $event"
        >
        </UISelect>
      </UIInputGroup>
    </UIFormItem>
  </UIForm>
</template>
