<template>
  <UITooltip>
    <template #trigger>
      <div>
        <CustomVariablePickerV2 @update:selectedValue="appendValueAtCursor" />
      </div>
    </template>
    Custom Values
  </UITooltip>
</template>

<script lang="ts">
import CustomVariablePickerV2 from '@/components/dynamic/CustomVariablePickerV2.vue'
import { UITooltip } from '@gohighlevel/ghl-ui'
import { Editor } from '@tiptap/vue-3'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'RTEControlCustomVariablePicker',
  components: {
    CustomVariablePickerV2,
    UITooltip,
  },
  props: {
    editor: {
      required: true,
      type: Editor,
    },
  },
  setup(props) {
    const appendValueAtCursor = (value: string) => {
      props.editor.chain().focus().insertContent(value).run()
    }

    return {
      appendValueAtCursor,
    }
  },
})
</script>
