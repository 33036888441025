export enum CALENDAR_RESOURCE_TYPES {
  EQUIPMENTS = 'equipments',
  ROOMS = 'rooms'
}
export class CalendarResource {
  private data: {
    id?: string //non mandatory in case we create a new object on client side.
    locationId: string
    name: string
    resourceType: string
    isActive: boolean
    createdAt: Date
    updatedAt: Date
    description?: string
    quantity?: number
    outOfService?: number
    capacity?: number
    deleted?: boolean
    availability?: any
    availabilityExceptions?: any
  }

  constructor(_data) {
    this.data = {
      id: _data.id,
      locationId: _data.locationId,
      name: _data.name,
      resourceType: _data.resourceType,
      isActive: _data.isActive,
      description: _data.description,
      quantity: _data.quantity,
      outOfService: _data.outOfService,
      capacity: _data.capacity,
      deleted: _data.deleted,
      availability: _data.availability,
      availabilityExceptions: _data.availabilityExceptions,
      createdAt: _data.createdAt,
      updatedAt: _data.updatedAt
    }
  }

  get locationId(): string {
    return this.data.locationId
  }

  set locationId(value: string) {
    this.data.locationId = value
  }

  get name(): string {
    return this.data.name
  }

  set name(value: string) {
    this.data.name = value
  }

  get resourceType(): string {
    return this.data.resourceType
  }

  set resourceType(value: string) {
    this.data.resourceType = value
  }

  get isActive(): boolean {
    return this.data.isActive
  }

  set isActive(value: boolean) {
    this.data.isActive = value
  }

  get createdAt(): Date {
    return this.data.createdAt
  }

  get updatedAt(): Date {
    return this.data.updatedAt
  }

  get description(): string | undefined {
    return this.data.description
  }

  set description(value: string | undefined) {
    this.data.description = value
  }

  get quantity(): number | undefined {
    return this.data.quantity
  }

  set quantity(value: number | undefined) {
    this.data.quantity = value
  }

  get outOfService(): number | undefined {
    return this.data.outOfService
  }

  set outOfService(value: number | undefined) {
    this.data.outOfService = value
  }

  get capacity(): number | undefined {
    return this.data.capacity
  }

  set capacity(value: number | undefined) {
    this.data.capacity = value
  }

  get deleted(): boolean | undefined {
    return this.data.deleted
  }

  set deleted(value: boolean | undefined) {
    this.data.deleted = value
  }

  get availability(): any {
    return this.data.availability
  }

  set availability(value: any) {
    this.data.availability = value
  }

  get availabilityExceptions(): any {
    return this.data.availabilityExceptions
  }

  set availabilityExceptions(value: any) {
    this.data.availabilityExceptions = value
  }
}
