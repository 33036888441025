<template>
  <UITooltip>
    <template #trigger>
      <div>
        <UIDropdownTree
          :options="triggerLinkOptions"
          trigger-label=""
          :debug="true"
          :loading="loading"
          @onSelect="appendValueAtCursor"
        >
          <template #trigger="triggerProps">
            <UIButton id="dd" quaternary class="px-1">
              <FlashIcon class="h-5 w-5" />
            </UIButton>
          </template>
        </UIDropdownTree>
      </div>
    </template>
    Trigger Links
  </UITooltip>
</template>

<script lang="ts">
// import { getLinks } from '@/models/Triggers/FilterState'
// import { MenuV2 } from '@/utils/merge_tags'
import { FlashIcon } from '@gohighlevel/ghl-icons/24/outline'
import {
  UIButton,
  UIDropdownTree,
  UIInput,
  UITooltip,
} from '@gohighlevel/ghl-ui'
import { Editor } from '@tiptap/vue-3'
import { defineComponent, onMounted, ref } from 'vue'

export default defineComponent({
  name: 'RTEControlCustomVariablePicker',
  components: {
    // UIInput,
    UIDropdownTree,
    FlashIcon,
    UIButton,
    UITooltip,
  },
  props: {
    editor: {
      required: true,
      type: Editor,
    },
  },
  setup(props) {
    const loading = ref(false)
    const triggerLinkOptions = ref([])

    const appendValueAtCursor = (value: string) => {
      props.editor.chain().focus().insertContent(value).run()
    }

    async function getTriggerLinks() {
      loading.value = true
      // const items = (await getLinks()).map(link => {
      //   return {
      //     label: link.name || link.id || '',
      //     value: `{{trigger_link.${link.id}}}`,
      //   }
      // })
      // triggerLinkOptions.value = items
      loading.value = false
    }

    onMounted(() => {
      getTriggerLinks()
    })

    return {
      loading,
      triggerLinkOptions,
      appendValueAtCursor,
    }
  },
})
</script>
