<script setup lang="ts">
import { UIButton, UIFormItem, UIInput, UIModal } from '@gohighlevel/ghl-ui'

import {
  ArrowNarrowUpRightIcon,
  CalendarCheck02Icon,
  Copy01Icon,
  EyeIcon,
} from '@gohighlevel/ghl-icons/24/outline'

import { computed } from 'vue'
import Calendar from '../../models/calendar'
import AppState from '../../states/app'
import {
  getEmbedCodeForCalendar,
  getPermanentUrlForCalendar,
} from '../../utils/calendarIntegrateFunctions'
import { copyToClipboard, openInNewTab } from '../../utils/generalFunctions'

const props = defineProps({
  show: Boolean,
})

const emit = defineEmits(['onModalClose', 'update:show'])

const editingCalendar = computed(() => {
  return AppState.editingCalendar
})

const methods = {
  handleCancel() {
    emit('onModalClose')
  },
  getEmbedCode() {
    return getEmbedCodeForCalendar(
      new Calendar(editingCalendar.value),
      AppState.baseUrl
    )
  },
  getPermanentLink() {
    return getPermanentUrlForCalendar(
      new Calendar(editingCalendar.value),
      AppState.baseUrl
    )
  },
}
</script>

<template>
  <UIModal
    id="generalConfirmationModal"
    :width="550"
    :show="show"
    @update:show="val => $emit('update:show', val)"
  >
    <template #header>
      <div class="flex flex-col items-center space-y-1 pb-10">
        <div class="rounded-full border-8 border-green-50 bg-green-100 p-2">
          <CalendarCheck02Icon class="h-7 w-7 text-green-500" />
        </div>
        <div class="text-md font-normal">Success</div>
        <div class="text-sm font-light text-gray-400">
          You have successfully configured your personal/team calendar
        </div>
      </div>
    </template>

    <div class="">
      <UIFormItem label="Here's the link:" path="name">
        <div class="flex w-full items-center space-x-4">
          <UIInput
            id="calendar-name-input"
            :model-value="methods.getPermanentLink()"
            :readonly="true"
            placeholder=""
          >
            <template #suffix>
              <div
                class="ml-2 flex cursor-pointer items-center space-x-2 text-blue-600"
                @click="() => copyToClipboard(methods.getPermanentLink())"
              >
                <Copy01Icon class="h-5 w-5 text-blue-600" />
                <div class="">Copy</div>
              </div>
            </template>
          </UIInput>

          <ArrowNarrowUpRightIcon
            class="h-6 w-6 cursor-pointer text-blue-600"
            @click="() => openInNewTab(methods.getPermanentLink())"
          />
        </div>
      </UIFormItem>

      <UIFormItem label="Here's the embed code:" path="name">
        <div class="flex w-full items-center space-x-4">
          <UIInput
            id="calendar-name-input"
            :model-value="methods.getEmbedCode()"
            :readonly="true"
            placeholder=""
          >
            <template #suffix>
              <div
                class="ml-2 flex cursor-pointer items-center space-x-2 text-blue-600"
                @click="() => copyToClipboard(methods.getEmbedCode())"
              >
                <Copy01Icon class="h-5 w-5 text-blue-600" />
                <div class="">Copy</div>
              </div>
            </template>
          </UIInput>
          <ArrowNarrowUpRightIcon
            class="invisible h-6 w-6 cursor-pointer text-blue-600"
            @click="() => openInNewTab(methods.getPermanentLink())"
          />
        </div>
      </UIFormItem>
    </div>

    <template #footer>
      <div class="mt-10 flex w-full justify-end space-x-2">
        <UIButton
          id="create-calendar-button"
          type="default"
          class="relative bottom-1"
          @click="methods.handleCancel"
        >
          Close
        </UIButton>
        <UIButton
          id="create-calendar-button"
          type="primary"
          class="relative bottom-1"
          @click="() => openInNewTab(methods.getPermanentLink())"
        >
          <EyeIcon class="mr-2 h-5 w-5" />
          Preview calendar
        </UIButton>
      </div>
    </template>
  </UIModal>
</template>
