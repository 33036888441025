export default class Location {
  private data: any = {}

  constructor(data: any) {
    this.data = data
  }

  get id(): string {
    return this.data.id
  }

  get name(): string {
    return this.data.name
  }

  get calendarSettings(): any {
    return this.data.calendarSettings
  }

  get language(): string {
    return this.calendarSettings.language
  }

  set language(language: string) {
    this.calendarSettings.language = language
  }

  get startDayOfWeek(): string {
    return this.calendarSettings.startDayOfWeek
  }

  set startDayOfWeek(startDayOfWeek: string) {
    this.calendarSettings.startDayOfWeek = startDayOfWeek
  }

  get appStartDayOfWeek(): string {
    return this.calendarSettings.appStartDayOfWeek
  }

  get timezone(): string {
    return this.calendarSettings.timezone
  }

  set timezone(timezone: string) {
    this.calendarSettings.timezone = timezone
  }

  get use24HourFormat(): boolean {
    return this.calendarSettings.use_24hFormat
  }

  set use24HourFormat(use_24hFormat: boolean) {
    this.calendarSettings.use_24hFormat = use_24hFormat
  }
}
