<script setup lang="ts">
import { useNotify } from '@/components/composition/notification'
import AppState, { setIsAdvancedCalendarPageLoading } from '@/states/app'
import { EmailNotifications } from '@/types/notification'
import { InfoCircleIcon, Tag01Icon } from '@gohighlevel/ghl-icons/24/outline'
import {
  UICheckbox,
  UIDivider,
  UIDropdownTree,
  UIFormItem,
  UIHeader,
  UIInput,
  UISwitch,
  UITooltip,
} from '@gohighlevel/ghl-ui'
import { computed, reactive, ref, watch, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import { CalendarService } from '../../class/services/CalendarService'
import Calendar, { CalendarType } from '../../models/calendar'
import {
  additionalNotesFieldOptions,
  handleCustomValueModification,
} from '../../utils/customValueMapper'
import CalendarNotificationList from './CalendarNotificationList.vue'
import DurationPicker from './DurationPicker.vue'

const defaultNotification = {
  type: 'email',
  shouldSendToContact: false,
  shouldSendToGuest: false,
  shouldSendToUser: false,
  shouldSendToSelectedUsers: false,
  templateId: 'default',
  selectedUsers: '',
}

const { t } = useI18n()
const { sendNotification } = useNotify()

const state = reactive({
  isLoading: false,
  notificationOptions: [
    {
      value: 'email',
      label: t('calendar_advanced.notifications.ack_email'),
    },
  ],
  formData: {
    notifications: [
      {
        ...defaultNotification,
      },
    ],
    notificationStatus: [] as EmailNotifications,
    googleInvitationEmails: false,
    shouldAssignContactToTeamMember: false,
    shouldSkipAssigningContactForExisting: false,
    allowReschedule: true,
    allowCancellation: true,
    notes: '' as any,
    reschedulePreference: {
      expiryTime: '',
      expiryTimeUnit: 'mins',
    },
    cancellationPreference: {
      expiryTime: '',
      expiryTimeUnit: 'mins',
    },
  },
})
const editingCalendar = computed(() => {
  return AppState.editingCalendar
})
defineEmits(['onSave', 'onCancel'])

const methods = {
  async loadData() {
    setIsAdvancedCalendarPageLoading(true)

    state.formData.notifications = editingCalendar.value?.notifications?.length
      ? editingCalendar.value?.notifications
      : [
          {
            ...defaultNotification,
          },
        ]

    state.formData.googleInvitationEmails = Boolean(
      editingCalendar.value?.googleInvitationEmails
    )

    state.formData.shouldAssignContactToTeamMember = Boolean(
      editingCalendar.value?.shouldAssignContactToTeamMember
    )

    state.formData.shouldSkipAssigningContactForExisting = Boolean(
      editingCalendar.value?.shouldSkipAssigningContactForExisting
    )

    state.formData.allowReschedule = Boolean(
      editingCalendar.value?.allowReschedule
    )
    state.formData.allowCancellation = Boolean(
      editingCalendar.value?.allowCancellation
    )

    state.formData.notes = editingCalendar.value?.notes
    if (editingCalendar.value?.reschedulePreference) {
      state.formData.reschedulePreference = {
        expiryTimeUnit: String(
          editingCalendar.value?.reschedulePreference?.expiryTimeUnit
        ),
        expiryTime: String(
          editingCalendar.value?.reschedulePreference?.expiryTime ?? ''
        ),
      }
    }
    if (editingCalendar.value?.cancellationPreference) {
      state.formData.cancellationPreference = {
        expiryTimeUnit: String(
          editingCalendar.value?.cancellationPreference?.expiryTimeUnit
        ),
        expiryTime: String(
          editingCalendar.value?.cancellationPreference?.expiryTime ?? ''
        ),
      }
    }
    state.formData.notificationStatus =
      editingCalendar.value?.notificationStatus || []

    setIsAdvancedCalendarPageLoading(false)
  },
  emitSave() {
    const data = {
      // notifications: state.formData.notifications,
      googleInvitationEmails: state.formData.googleInvitationEmails,
      shouldAssignContactToTeamMember:
        state.formData.shouldAssignContactToTeamMember,
      shouldSkipAssigningContactForExisting:
        state.formData.shouldSkipAssigningContactForExisting,
      allowReschedule: state.formData.allowReschedule,
      allowCancellation: state.formData.allowCancellation,
      notes: state.formData.notes,
      reschedulePreference: {
        expiryTimeUnit: state.formData.reschedulePreference.expiryTimeUnit,
        expiryTime: parseInt(state.formData.reschedulePreference.expiryTime),
      },
      cancellationPreference: {
        expiryTimeUnit: state.formData.cancellationPreference.expiryTimeUnit,
        expiryTime: parseInt(state.formData.cancellationPreference.expiryTime),
      },
    }

    return data
  },
}

function toggleReschedule(value: boolean) {
  state.formData.allowReschedule = value
  if (state.formData.allowReschedule) {
    state.formData.notes = state.formData.notes.replace(
      Calendar.NotesText_Title_RescheduleOrCancellation,
      ''
    )
    if (state.formData.notes.includes(Calendar.NotesText_PhoneEmail)) {
      state.formData.notes = state.formData.notes.replace(
        Calendar.NotesText_PhoneEmail,
        ''
      )
      state.formData.notes =
        `${Calendar.NotesText_PhoneEmail}\n${Calendar.NotesText_Title_RescheduleOrCancellation}\n${Calendar.NotesText_Reschedule}` +
        state.formData.notes
    } else if (state.formData.notes.includes(Calendar.NotesText_Cancellation)) {
      state.formData.notes = state.formData.notes.replace(
        Calendar.NotesText_Cancellation,
        ''
      )
      state.formData.notes =
        state.formData.notes +
        `\n${Calendar.NotesText_Title_RescheduleOrCancellation}\n${Calendar.NotesText_Reschedule}\n\n` +
        Calendar.NotesText_Cancellation
    } else {
      state.formData.notes =
        state.formData.notes +
        `\n\n${Calendar.NotesText_Title_RescheduleOrCancellation}\n${Calendar.NotesText_Reschedule}\n`
    }
  } else {
    state.formData.notes = state.formData.notes.replace(
      Calendar.NotesText_Reschedule,
      ''
    )

    if (!state.formData.notes.includes(Calendar.NotesText_Cancellation)) {
      state.formData.notes = state.formData.notes.replace(
        Calendar.NotesText_Title_RescheduleOrCancellation,
        ''
      )
    }
  }
  removeAdditionalSpaces()
}

function toggleCancellation(value: boolean) {
  state.formData.allowCancellation = value

  if (state.formData.allowCancellation) {
    if (
      !state.formData.notes.includes(
        Calendar.NotesText_Title_RescheduleOrCancellation
      )
    ) {
      state.formData.notes =
        state.formData.notes +
        `\n${Calendar.NotesText_Title_RescheduleOrCancellation}\n${Calendar.NotesText_Cancellation}`
    } else {
      state.formData.notes =
        state.formData.notes + `\n${Calendar.NotesText_Cancellation}`
    }
  } else {
    state.formData.notes = state.formData.notes.replace(
      Calendar.NotesText_Cancellation,
      ''
    )
    state.formData.notes = state.formData.notes.replace(
      Calendar.NotesText_Title_RescheduleOrCancellation,
      ''
    )
  }
  removeAdditionalSpaces()
}

const removeAdditionalSpaces = () => {
  state.formData.notes = state.formData.notes.replace(/\n{3,}/g, '\n')
}

const toggleNotification = async (value: any) => {
  const item = state.formData.notificationStatus.find(
    el => el.type === value.type
  )
  const channel = item?.channels.find(ch => ch.name === value.channel)
  if (channel) {
    channel.isActive = value.status
  }
}

defineExpose({
  methods,
})

const additionalNotesRef = ref()
watchEffect(() => {
  const customValues = handleCustomValueModification(AppState.customValues)
  additionalNotesRef.value = customValues
    ? [...additionalNotesFieldOptions, customValues]
    : additionalNotesFieldOptions
})

watch(
  () => editingCalendar.value,
  () => {
    methods.loadData()
  },
  { immediate: true }
)

const isServiceCalendar = computed(() => {
  return editingCalendar.value?.isServiceCalendar
})
</script>

<template>
  <UIHeader
    id="text-only-header"
    :secondary-title="$t('calendar_advanced.notifications.notifications')"
    :description="$t('calendar_advanced.notifications.notifications_sub')"
  >
  </UIHeader>
  <UIDivider />

  <CalendarNotificationList
    id="calendar-email-notification-list"
    :calendar-id="editingCalendar?.id"
    :notifications="state.formData.notificationStatus"
    @toggle-notification="toggleNotification"
  />
  <UIDivider />
  <div class="">
    <div class="flex flex-col space-y-4">
      <UICheckbox
        id="calendar-notifications-send-google-invites"
        v-model:checked="state.formData.googleInvitationEmails"
      >
        {{
          editingCalendar?.calendarType === CalendarType.EVENT
            ? $t('calendar_advanced.notifications.send_google_invites')
            : $t('calendar_advanced.notifications.send_google_outlook_invites')
        }}
      </UICheckbox>
      <UICheckbox
        v-if="
          editingCalendar?.calendarType === CalendarType.ROUND_ROBIN ||
          editingCalendar?.calendarType === CalendarType.COLLECTIVE ||
          editingCalendar?.calendarType === CalendarType.CLASS_BOOKING ||
          editingCalendar?.calendarType === CalendarType.SERVICE ||
          editingCalendar?.calendarType === CalendarType.PERSONAL
        "
        id="calendar-assign-user-to-contact"
        v-model:checked="state.formData.shouldAssignContactToTeamMember"
      >
        {{
          isServiceCalendar
            ? $t('calendar_advanced.notifications.assign_user_to_staff')
            : $t('calendar_advanced.notifications.assign_user_to_contact')
        }}
      </UICheckbox>

      <UICheckbox
        v-if="state.formData.shouldAssignContactToTeamMember"
        id="skip-assigning-user-to-contact"
        v-model:checked="state.formData.shouldSkipAssigningContactForExisting"
      >
        {{
          $t('calendar_advanced.notifications.skip_assigning_user_to_contact')
        }}
      </UICheckbox>
    </div>

    <UIDivider />

    <UIFormItem
      :label="
        $t('calendar_advanced.notifications.cancellation_and_reschedule_policy')
      "
      class="w-full"
      :show-feedback="false"
    >
      <div id="calendar-reschedule-cancel" class="flex flex-col">
        <div class="flex flex-col items-start pb-1 pt-4">
          <div class="flex flex-row gap-3">
            <div id="allow-reschedule">
              <UISwitch
                v-model:value="state.formData.allowReschedule"
                @update:value="value => toggleReschedule(value)"
              >
              </UISwitch>
            </div>
            <div class="font-normal">
              {{
                isServiceCalendar
                  ? $t('calendar_advanced.extras.allow_reschedule_appointment')
                  : $t('calendar_advanced.notifications.allow_reschedule')
              }}
            </div>
            <UITooltip :placement="'top'">
              <template #trigger>
                <InfoCircleIcon class="w-4 cursor-pointer text-gray-500" />
              </template>
              <p class="max-w-sm">
                {{
                  $t('calendar_advanced.notifications.allow_reschedule_tooltip')
                }}
              </p>
            </UITooltip>
          </div>

          <div
            v-if="state.formData.allowReschedule"
            class="flex w-full flex-row items-center gap-3"
          >
            <div class="font-light text-gray-600">
              {{ $t('calendar_advanced.notifications.reschedule_link_expiry') }}
            </div>
            <DurationPicker
              :apply-margin-top="false"
              class="w-[30%]"
              path="reschedulePreference"
              :value="state.formData.reschedulePreference.expiryTime"
              :unit="state.formData.reschedulePreference.expiryTimeUnit"
              @on-value-change="
                value => {
                  state.formData.reschedulePreference.expiryTime =
                    value.duration
                  state.formData.reschedulePreference.expiryTimeUnit =
                    value.timeUnit
                }
              "
            />
            <div class="font-light text-gray-600">
              {{ $t('calendar_advanced.notifications.before_meeting') }}
            </div>
          </div>
        </div>
        <div class="flex flex-col items-start pb-1 pt-4">
          <div class="flex flex-row gap-3">
            <div id="allow-cancellation">
              <UISwitch
                v-model:value="state.formData.allowCancellation"
                @update:value="value => toggleCancellation(value)"
              >
              </UISwitch>
            </div>
            <div class="font-normal">
              {{
                isServiceCalendar
                  ? $t('calendar_advanced.extras.allow_cancelling_appointment')
                  : $t('calendar_advanced.notifications.allow_cancellation')
              }}
            </div>
            <UITooltip :placement="'top'">
              <template #trigger>
                <InfoCircleIcon class="w-4 cursor-pointer text-gray-500" />
              </template>
              <p class="max-w-sm">
                {{
                  $t(
                    'calendar_advanced.notifications.allow_cancellation_tooltip'
                  )
                }}
              </p>
            </UITooltip>
          </div>

          <div
            v-if="state.formData.allowCancellation"
            class="flex w-full flex-row items-center gap-3"
          >
            <div class="font-light text-gray-600">
              {{
                $t('calendar_advanced.notifications.cancellation_link_expiry')
              }}
            </div>
            <DurationPicker
              :apply-margin-top="false"
              class="w-[30%]"
              path="reschedulePreference"
              :value="state.formData.cancellationPreference.expiryTime"
              :unit="state.formData.cancellationPreference.expiryTimeUnit"
              @on-value-change="
                value => {
                  state.formData.cancellationPreference.expiryTime =
                    value.duration
                  state.formData.cancellationPreference.expiryTimeUnit =
                    value.timeUnit
                }
              "
            />
            <div class="font-light text-gray-600">
              {{ $t('calendar_advanced.notifications.before_meeting') }}
            </div>
          </div>
        </div>
      </div>
    </UIFormItem>

    <UIDivider v-if="state.formData.allowReschedule" class="mt-[0px]" />

    <UIFormItem
      :label="$t('calendar_advanced.extras.additional_notes')"
      class="mt-[20px] w-96"
      :show-feedback="false"
    >
      <UIInput
        id="calendar-additional-notes"
        v-model="state.formData.notes"
        :rows="5"
        type="textarea"
        placeholder="Notes"
        class="relative"
      >
        <template #suffix>
          <UIDropdownTree
            :options="additionalNotesRef"
            :path-separator="'/'"
            :disable-search="false"
            :disabled="undefined"
            :trigger-label="undefined"
            :debug="undefined"
            :placement="undefined"
            :loading="undefined"
            :remote="undefined"
            :search-placeholder="''"
            @on-select="(value: string) => {
    state.formData.notes =
      state.formData.notes +
      ' ' +
      value
  }"
          >
            <!-- eslint-disable-next-line vue/no-unused-vars -->
            <template #trigger="triggerProps">
              <UIButton
                id="dd"
                class="absolute bottom-0 right-4 cursor-pointer"
                quaternary
              >
                <Tag01Icon class="h-5 w-5 lg:-mr-2" />
              </UIButton>
            </template>
          </UIDropdownTree>
        </template>
      </UIInput>
    </UIFormItem>
  </div>
</template>

<style scoped lang="scss">
#calendar-reschedule-cancel {
  .n-divider:not(.n-divider--vertical) {
    margin: 0px 0px 10px 0px;
  }

  .n-form-item-feedback-wrapper {
    display: none;
  }
}
</style>
