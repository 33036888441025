<script setup lang="ts">
import AvailabilityPicker from '@/components/Settings/AvailabilityPicker.vue'
import router from '@/router'
import {
MinusIcon,
PlusIcon,
Settings01Icon,
} from '@gohighlevel/ghl-icons/24/outline'
import {
AllExtensions,
RequiredExtensions,
UIRichTextEditor,
} from '@gohighlevel/ghl-text-editor'
import {
UIButton,
UIForm,
UIFormItem,
UIInput,
UIInputGroup,
UIInputGroupLabel,
UIInputNumber,
UIModal,
UIModalHeader,
UISelect,
UISwitch,
} from '@gohighlevel/ghl-ui'
import { History } from '@tiptap/extension-history'
import { Editor } from '@tiptap/vue-3'
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { CalendarService } from '../../class/services/CalendarService'
import { ConnectionService } from '../../class/services/ConnectionService'
import Calendar, {
  CalendarType,
  defaultNotificationStatus,
  TeamMember,
} from '../../models/calendar'
import AppState, {
getUsersInLocation,
resetCalendarEditValidationData,
setEditingCalendarId,
} from '../../states/app'
import { toggleAdvancedView } from '../../utils/commonModalFunctions'
import { currency } from '../../utils/currencyHelper'
import {
getDefaultTeamMember,
getTimeSpanOptions,
getTooltip,
isPaymentAllowed,
} from '../../utils/generalFunctions'
import TextEditorMenu from '../common/TextEditorMenu.vue'
const { t } = useI18n()

const props = defineProps({
  show: Boolean,
  selectedCalendarType: {
    type: String,
    default: CalendarType.EVENT,
  },
})

const availabilityPickerRef = ref()
const formRef = ref()
const editor = ref<any>(null)
const isInitialLoad = ref(true)

const defaultFormData = {
  name: '',
  officeHours: [],
  meetingLocation: '',
  locationConfigurations: [
    {
      kind: 'custom',
      location: '',
      position: 0,
    },
  ],
  teamMembers: [],
  appoinmentPerSlot: '1',
  description: '',
  slotDuration: '30',
  slug: '',
  slotDurationUnit: 'mins',
  stripe: {
    amount: 0,
    currency: 'USD',
    chargeDescription: '',
  },
}

const state = reactive({
  isSaving: false,
  isSlugValidating: false,
  shouldShowDescription: false,
  shouldAcceptPayment: false,
  zoomConnections: [] as any,
  formData: { ...defaultFormData },
  msTeamsConnections: [] as any,
})

const slotDurationUnitOptions = computed(() => {
  return [
    {
      value: 'mins',
      label: 'Minutes',
    },
    {
      value: 'hours',
      label: 'Hours',
    },
  ]
})

const shouldAllowPayment = computed(() => {
  return AppState.shouldAllowPayment
})

const paymentCurrencyOptions = computed(() => {
  const currencyOptions: string[] = []
  for (const key of Object.keys(currency)) {
    currencyOptions.push(currency[key].code)
  }
  return currencyOptions.map(x => {
    return {
      value: x,
      label: x,
    }
  })
})

const rules = computed(() => {
  return {
    name: {
      validator() {
        if (!state.formData.name?.trim()) {
          return new Error('Please enter a name')
        }

        return true
      },
      message: 'Please enter a name',
      trigger: 'blur',
    },
    slotDuration: {
      validator(_, value) {
        const slotDurationInMins =
          state.formData.slotDurationUnit === 'hours'
            ? Number(value) * 60
            : Number(value)

        if (!value) {
          return new Error('Slot duration is required')
        } else if (!/^\d*$/.test(value)) {
          return new Error('Slot duration should be an integer')
        } else if (slotDurationInMins < 1 || slotDurationInMins > 12 * 60) {
          return new Error(
            'Meeting duration must be between 1 min and 12 hours'
          )
        }
        return true
      },
      trigger: ['blur', 'input'],
    },
    appoinmentPerSlot: {
      validator(_, value) {
        if (props.selectedCalendarType !== CalendarType.CLASS_BOOKING) {
          return true
        }

        if (!value) {
          return new Error('Seats per class is required')
        } else if (!/^\d*$/.test(value)) {
          return new Error('Seats per class should be an integer')
        } else if (Number(value) > 9999 || Number(value) < 0) {
          return new Error('This cannot be greater than 9999 or less than 0')
        }
        return true
      },
      trigger: ['blur', 'input'],
    },
    meetingLocation: {
      validator(_, value) {
        if (props.selectedCalendarType !== CalendarType.CLASS_BOOKING) {
          return true
        }

        if (!value) {
          return new Error('Meeting location is required')
        }
        return true
      },
      trigger: ['blur', 'input'],
    },
    teamMembers: {
      validator() {
        if (props.selectedCalendarType === CalendarType.EVENT) {
          return true
        }

        if (
          props.selectedCalendarType === CalendarType.CLASS_BOOKING ||
          props.selectedCalendarType === CalendarType.PERSONAL
        ) {
          if (state.formData.teamMembers.length > 1) {
            return new Error('Only one team member is allowed')
          }
        }

        if (props.selectedCalendarType === CalendarType.COLLECTIVE) {
          if (state.formData.teamMembers.length < 2) {
            return new Error('At least 2 team members are required')
          }
        }

        if (state.formData.teamMembers.length === 0) {
          return new Error('At least one team member is required')
        }
        return true
      },
      trigger: ['input', 'blur'],
    },
    slug: {
      required: true,
      trigger: ['input'],
      asyncValidator() {
        state.isSlugValidating = true

        return new Promise(async (resolve, reject) => {
          if (!state.formData.slug) {
            state.isSlugValidating = false
            return reject(new Error('Slug is required'))
          }

          const slugRegex = /^[a-z0-9-]+(\/[a-z0-9-]+)*$/

          if (!slugRegex.test(state.formData.slug)) {
            state.isSlugValidating = false
            return reject(new Error('This slug is not valid'))
          }

          try {
            const { data } = await CalendarService.isValidCalendarSlug(
              state.formData.slug
            )
            if (!data.available) {
              return reject(new Error('This slug is already taken'))
            }
          } catch (error) {
            return reject(error)
          }

          state.isSlugValidating = false
          return resolve(true)
        })
      },
    },
    stripeAmount: {
      trigger: ['input', 'blur'],
      validator() {
        if (!state.formData.stripe.amount) {
          return true
        }
        if (state.formData.stripe.amount.toString().match(/^\d+(\.\d{3,})$/)) {
          return new Error(t('calendar_advanced.forms_payment.stripe_error'))
        }
        return true
      },
    },
  }
})

const usersInLocationOptions = computed(() => {
  return (AppState.globalUsers as any)?.users?.map((x: any) => {
    return {
      value: x.id,
      label: x.name,
      disabled:
        (props.selectedCalendarType === CalendarType.CLASS_BOOKING ||
          props.selectedCalendarType === CalendarType.PERSONAL) &&
        state.formData?.teamMembers?.length === 1 &&
        !state.formData?.teamMembers?.some(y => y === x.id),
    }
  })
})

const emit = defineEmits(['onConfirm', 'onModalClose', 'update:show'])

const methods = {
  /**
   * Load data from the server
   */
  async loadData() {
    await Promise.all([
      methods.loadUsers(),
      methods.loadZoomConnections(),
      methods.loadMSTeamsConnections(),
    ])
  },
  async loadZoomConnections() {
    const response = await ConnectionService.getConnections(AppState.locationId)
    const zoomConnections = response?.data?.locationConnections?.zoom || []
    state.zoomConnections = zoomConnections
  },
  async loadMSTeamsConnections() {
    const response = await ConnectionService.getConnections(AppState.locationId)
    const msTeamsConnections =
      response?.data?.locationConnections?.ms_teams || []

    state.msTeamsConnections = msTeamsConnections
  },
  async loadPaymentInfo() {
    await isPaymentAllowed(AppState.locationId)
  },
  async loadUsers() {
    await getUsersInLocation()
  },

  processTeamMemberInfo(teamMemberDetail, userId, index) {
    if (!teamMemberDetail) {
      return getDefaultTeamMember(userId)
    }

    const zoomConnection = state.zoomConnections.find(
      connection => connection.ownerId === userId
    )

    const selectedCustomMeetingLocation =
      teamMemberDetail.locationWiseMeetingLocation &&
      teamMemberDetail.locationWiseMeetingLocation[AppState.locationId]
    const selectedIsZoomAdded =
      teamMemberDetail.locationWiseZoomAdded &&
      teamMemberDetail.locationWiseZoomAdded[AppState.locationId]

    const teamMember = {
      userId: userId,
      priority: 0.5,
      isZoomAdded:
        props.selectedCalendarType === CalendarType.CLASS_BOOKING
          ? 'false'
          : selectedIsZoomAdded || 'false',
      zoomOauthId: zoomConnection?.oauthId || '',
      meetingLocation:
        state.formData.meetingLocation || selectedCustomMeetingLocation,
      selected: true,
    } as TeamMember
    if (props.selectedCalendarType === CalendarType.COLLECTIVE) {
      teamMember.isPrimary = index === 0
      teamMember.useMeetingForCollective = index === 0
    }
    return teamMember
  },

  /**
   * Save data to the server
   */
  async handleSave() {
    try {
      await formRef.value.getForm().validate()
    } catch (error) {
      state.isSaving = false
      return
    }

    state.isSaving = true

    const hours = availabilityPickerRef.value?.methods?.getAvailability()

    state.formData.description = state.shouldShowDescription
      ? state.formData.description
      : ''

    const payload: Partial<Calendar> = {
      name: state.formData.name,
      description: state.formData.description,
      openHours: hours,
      slotDuration:
        state.formData.slotDurationUnit === 'hours'
          ? Number(state.formData.slotDuration) * 60
          : Number(state.formData.slotDuration),
      slotDurationUnit: state.formData.slotDurationUnit,
      widgetSlug: state.formData.slug,
      locationId: AppState.locationId,
      calendarType: props.selectedCalendarType as CalendarType,
      showSeatsPerSlot:
        props.selectedCalendarType === CalendarType.CLASS_BOOKING,
    }

    if (props.selectedCalendarType === CalendarType.EVENT) {
      // payload.meetingLocation = state.formData.meetingLocation
      payload.locationConfigurations = [
        {
          kind: 'custom',
          location: state.formData.meetingLocation,
          position: 0,
        },
      ]
    }

    if (state.shouldAcceptPayment) {
      payload.stripe = {
        amount: Number(state.formData.stripe.amount),
        currency: state.formData.stripe.currency,
        chargeDescription: state.formData.stripe.chargeDescription,
      }
      payload.isLivePaymentMode = true
    } else {
      payload.stripe = {}
    }

    if (
      Array.isArray(state.formData.teamMembers) &&
      state.formData.teamMembers.length > 0
    ) {
      payload.teamMembers = state.formData.teamMembers.map((x: string, ind) => {
        const teamMemberDetail = (AppState.globalUsers as any)?.users.find(
          obj => obj.id === x
        )
        return methods.processTeamMemberInfo(teamMemberDetail, x, ind)
      })
    }

    if (props.selectedCalendarType === CalendarType.CLASS_BOOKING) {
      payload.appoinmentPerSlot = Number(state.formData.appoinmentPerSlot)
    }

    if (props.selectedCalendarType === CalendarType.SERVICE) {
      payload.enableStaffSelection = true
      payload.slotInterval = 15
      payload.slotIntervalUnit = 'mins'
    }

    const { data } = await CalendarService.create(payload)
    state.isSaving = false

    emit('onConfirm', data.calendar)

    methods.resetInputs()

    return data.calendar.id
  },
  resetInputs() {
    state.formData = {
      ...defaultFormData,
      stripe: {
        amount: '0',
        currency: 'USD',
        chargeDescription: '',
      },
    }

    state.shouldShowDescription = false
    state.shouldAcceptPayment = false
    intializeEditor()
  },
  async handleAdvanced() {
    const calendarId = await methods.handleSave()

    if (calendarId) {
      setEditingCalendarId(calendarId)

      toggleAdvancedView()

      router.push({
        name: 'calendar-settings__advanced',
        params: {
          calendarId: calendarId,
        },
        query: {
          isNew: 'true',
        },
      })
    }
  },
  handleCancel() {
    methods.resetInputs()
    emit('onModalClose')
    isInitialLoad.value = true
  },
  async selectDefaultTeamMember() {
    const filteredTeamMember = usersInLocationOptions.value.find(
      x => x.value === AppState.user?.id
    )?.value
    state.formData.teamMembers = filteredTeamMember
      ? ([filteredTeamMember] as any)
      : []
    isInitialLoad.value = false
  },
}

watch(
  () => AppState.locationId,
  () => {
    if (AppState.locationId) {
      methods.loadPaymentInfo()
    }
  }
)

watch(
  () => [usersInLocationOptions.value, state.formData?.teamMembers],

  usersInLocationOptions => {
    if (
      usersInLocationOptions.length > 0 &&
      state.formData?.teamMembers?.length === 0 &&
      props.selectedCalendarType === CalendarType.PERSONAL &&
      isInitialLoad.value
    ) {
      methods.selectDefaultTeamMember()
    }
  }
)

watch(
  () => props.selectedCalendarType,
  newValue => {
    if (newValue === CalendarType.SERVICE) {
      state.formData.slotDuration = '15'
    }
  }
)

const intializeEditor = () => {
  editor.value = new Editor({
    content: state.formData.description || '',
    parseOptions: {
      preserveWhitespace: 'full',
    },
    extensions: [
      History,
      RequiredExtensions,
      AllExtensions.configure({
        placeholder: {
          placeholder: t('create_calendar.description_placeholder'),
          showForAllNodes: false,
        },
        divTag: {
          inline: false,
          preserveWhitespace: false,
          HTMLAttributes: {
            style: 'color:#10182899',
          },
        },
        preTag: {
          inline: false,
          preserveWhitespace: false,
          HTMLAttributes: {
            style: 'color:#10182899',
          },
        },
        textAlignment: { types: ['heading', 'paragraph'] },
        paragraph: {
          HTMLAttributes: {
            style: 'margin:0px;color:#10182899',
          },
        },
        heading: {
          HTMLAttributes: {
            style: 'color:#10182899',
          },
        },
      }),
    ],
    onUpdate({ editor }) {
      state.formData.description = editor.getHTML()
    },
  })
}

onMounted(() => {
  resetCalendarEditValidationData()
  intializeEditor()
  methods.loadData()
})

const isServiceCalendar = computed(() => {
  return props.selectedCalendarType === CalendarType.SERVICE
})

const showTeamMemberSelection = computed(() => {
  return (
    props.selectedCalendarType === CalendarType.ROUND_ROBIN ||
    props.selectedCalendarType === CalendarType.CLASS_BOOKING ||
    props.selectedCalendarType === CalendarType.COLLECTIVE ||
    props.selectedCalendarType === CalendarType.SERVICE ||
    props.selectedCalendarType === CalendarType.PERSONAL
  )
})

const selectTeamMemberLabel = computed(() => {
  return props.selectedCalendarType === CalendarType.SERVICE
    ? t('create_calendar.service_select_team_member')
    : props.selectedCalendarType === CalendarType.ROUND_ROBIN ||
      props.selectedCalendarType === CalendarType.COLLECTIVE
    ? 'Select team members'
    : 'Select team member'
})

const durationOptionForServices = computed(() => {
  const MAX_DURATION = 360
  const SLOT_INTERVAL_FOR_SERVICE_CALENDAR = 15
  return getTimeSpanOptions(
    MAX_DURATION,
    SLOT_INTERVAL_FOR_SERVICE_CALENDAR,
    SLOT_INTERVAL_FOR_SERVICE_CALENDAR
  )
})
</script>

<template>
  <UIModal
    id="generalConfirmationModal"
    :width="680"
    :show="show"
    @update:show="val => $emit('update:show', val)"
  >
    <template #header>
      <UIModalHeader
        id="generalConfirmationModalHeader"
        type="primary"
        :title="t('create_calendar.new_calendar')"
        @close="methods.handleCancel"
      >
      </UIModalHeader>
    </template>

    <div class="w-full">
      <UIForm
        id="create-calendar-form"
        ref="formRef"
        :model="state.formData"
        :rules="rules"
        class="w-full"
      >
        <UIFormItem
          :label="
            isServiceCalendar
              ? $t('create_calendar.service_calendar_name')
              : $t('create_calendar.calendar_name')
          "
          path="name"
        >
          <UIInput
            id="calendar-name-input"
            v-model="state.formData.name"
            :placeholder="$t('create_calendar.eg_outbound_reach')"
          />
        </UIFormItem>

        <UIButton
          id="add-description"
          :text="true"
          class="mb-6 border-none"
          @click="state.shouldShowDescription = !state.shouldShowDescription"
        >
          <PlusIcon v-if="!state.shouldShowDescription" class="mr-2 h-5 w-5" />

          <MinusIcon v-else class="mr-2 h-5 w-5" />

          {{
            state.shouldShowDescription
              ? t('create_calendar.remove_description')
              : t('create_calendar.add_description')
          }}
        </UIButton>

        <UIFormItem
          v-if="state.shouldShowDescription"
          :label="$t('create_calendar.description')"
        >
          <div class="flex w-full flex-col">
            <TextEditorMenu :editor="editor" form-id="#create-calendar-form" />
            <UIRichTextEditor
              :editor="editor"
              container-class="force-border-0 overflow-x-auto custom-editor-styling"
            />
          </div>
        </UIFormItem>

        <UIFormItem
          v-if="showTeamMemberSelection"
          :label="selectTeamMemberLabel"
          path="teamMembers"
        >
          <UISelect
            id="calendar-team-member-select-modal"
            v-model:value="state.formData.teamMembers"
            :multiple="true"
            :filterable="true"
            :options="usersInLocationOptions"
          />
        </UIFormItem>

        <UIFormItem
          :label="$t('create_calendar.custom_url')"
          path="slug"
          :tooltip="getTooltip(t('create_calendar.slug_tooltip'))"
          class="border-y pb-2 pt-4"
        >
          <UIInputGroup>
            <UIInputGroupLabel>/widget/bookings/</UIInputGroupLabel>
            <UIInput
              id="calendarUrl"
              :model-value="state.formData.slug"
              placeholder="my-calendar"
              @update:model-value="state.formData.slug = $event.toLowerCase()"
            />
          </UIInputGroup>
        </UIFormItem>

        <div class="mt-4 flex space-x-6">
          <UIFormItem
            :label="
              selectedCalendarType === CalendarType.SERVICE
                ? $t('calendar_advanced.availability.service_duration')
                : $t('create_calendar.meeting_duration')
            "
            class="w-2/3"
            path="slotDuration"
          >
            <UIInputGroup>
              <UIInput
                id="meeting-duration-input"
                v-model="state.formData.slotDuration"
                placeholder=""
              />
              <UISelect
                id="meeting-duration-select"
                v-model:value="state.formData.slotDurationUnit"
                class="w-60"
                :options="slotDurationUnitOptions"
              >
              </UISelect>
            </UIInputGroup>
          </UIFormItem>

          <UIFormItem
            v-if="selectedCalendarType === CalendarType.CLASS_BOOKING"
            :label="$t('create_calendar.seats_per_class')"
            class="w-1/3"
            path="appoinmentPerSlot"
          >
            <UIInput
              id="appointment-per-slot-input"
              v-model="state.formData.appoinmentPerSlot"
              placeholder=""
            />
          </UIFormItem>
        </div>
        <div class="border-b pb-4">
          <AvailabilityPicker ref="availabilityPickerRef" />
        </div>

        <UIFormItem
          v-if="
            selectedCalendarType === CalendarType.EVENT ||
            selectedCalendarType === CalendarType.CLASS_BOOKING
          "
          label="Meeting location"
          :tooltip="getTooltip(t('create_calendar.meeting_location_tooltip'))"
          path="meetingLocation"
          class="mt-5 w-64"
        >
          <UIInput
            id="calendar-event-title-input"
            v-model="state.formData.meetingLocation"
            placeholder="Enter meeting location"
          />
        </UIFormItem>
        <div v-if="shouldAllowPayment" class="">
          <div class="flex items-center space-x-3 pb-3 pt-4">
            <UISwitch v-model:value="state.shouldAcceptPayment"> </UISwitch>
            <div class="font-normal">Accept payments</div>
          </div>
          <div v-if="state.shouldAcceptPayment" class="">
            <UIFormItem
              :label="$t('create_calendar.amount')"
              class="mt-4 w-full"
              path="stripeAmount"
            >
              <UIInputGroup>
                <UIInputNumber
                  id="calendars-stripe-amount-input"
                  v-model="state.formData.stripe.amount"
                  placeholder=""
                  :min="0"
                  :max="999999.99"
                  :show-button="false"
                  size="large"
                  class="w-full"
                >
                </UIInputNumber>
                <UISelect
                  id="calendar-stripe-amount-currency-select"
                  v-model:value="state.formData.stripe.currency"
                  class="w-32"
                  :options="paymentCurrencyOptions"
                >
                </UISelect>
              </UIInputGroup>
            </UIFormItem>

            <UIFormItem :label="$t('create_calendar.description')">
              <UIInput
                id="calendar-stripe-charge-input"
                v-model="state.formData.stripe.chargeDescription"
                type="textarea"
                :placeholder="$t('create_calendar.payment_placeholder')"
                :rows="4"
              />
            </UIFormItem>
          </div>
        </div>
      </UIForm>
    </div>
    <template #footer>
      <div class="mt-9 flex items-center justify-between">
        <UIButton
          id="advanced-calendar-button"
          :text="true"
          :disabled="state.isSlugValidating || state.isSaving"
          @click="methods.handleAdvanced"
        >
          <Settings01Icon class="mr-2 h-5 w-5" />
          {{ $t('create_calendar.advanced_settings') }}
        </UIButton>
        <div class="flex space-x-2">
          <UIButton
            id="cancel-calendar-button"
            type="default"
            class="relative bottom-1"
            @click="methods.handleCancel"
          >
            {{ $t('create_calendar.cancel') }}
          </UIButton>
          <UIButton
            id="create-calendar-button"
            type="primary"
            class="relative bottom-1"
            :disabled="state.isSlugValidating || state.isSaving"
            @click="methods.handleSave"
          >
            {{ $t('create_calendar.confirm') }}
          </UIButton>
        </div>
      </div>
    </template>
  </UIModal>
</template>

<style lang="scss">
#create-calendar-form {
  .force-border-0 {
    border: 1px solid #d0d5dd !important;
    padding: 15px !important;
    max-height: 200px !important;
    min-height: 100px !important;
  }

  .ProseMirror p {
    margin: 0;
    font-size: 13px !important;
  }

  .ProseMirror ol {
    font-size: 14px !important;
  }

  .v3-search input::placeholder {
    font-size: 1rem;
    text-align: left;
    font-weight: normal;
    font-size: 0.875rem;
  }
}
</style>
