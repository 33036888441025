<template>
  <div
    class="relative focus:outline-none custom-select"
    :tabindex="tabindex"
    @blur="open = false"
  >
    <span
      class="inline-block w-full rounded-md shadow-sm selected"
      :class="{ open: open, 'opacity-50': disabled }"
      @click="!disabled ? (open = !open) : null"
    >
      <div
        style="min-height: 38px"
        class="
          cursor-default
          relative
          w-full
          rounded-md
          border border-gray-300
          bg-white
          pl-3
          pr-10
          py-2
          text-left
          focus:outline-none focus:shadow-outline-blue focus:border-blue-300
          transition
          ease-in-out
          duration-150
          sm:text-sm sm:leading-5
        "
        :class="{
          'border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red':
            error,
        }"
      >
        <div class="flex flex-wrap" v-if="multiple && selectedValue.length">
          <div
            v-for="(item, i) in selectedValue"
            :key="i"
            class="
              h-5
              mb-1
              bg-curious-blue-500
              mr-1
              text-white text-xs
              rounded
              px-1
              flex
              items-center
            "
          >
            {{ item.label }}
            <svg
              v-if="!disabled"
              @click="removeSelectedValue(item, $event)"
              class="w-4 h-4 ml-2 cursor-pointer"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </div>
        </div>

        <input
          type="text"
          class="
            block
            w-full
            shadow-sm
            focus:ring-curious-blue-500 focus:border-curious-blue-500
            sm:text-sm
            border-gray-200
            rounded
          "
          :class="{ 'pointer-events-none': disabled }"
          @input="onContentSearch"
          :value="searchWord"
          v-if="searchable"
          placeholder="Type to search"
        />
        <span
          class="block truncate h-5 outline-none"
          :contenteditable="searchable"
          @input="onContentSearch"
          v-else-if="!multiple && !searchWord"
        >
          {{ textPreview }}
        </span>

        <span
          class="
            absolute
            inset-y-0
            right-0
            flex
            items-center
            pr-2
            pointer-events-none
          "
        >
          <svg
            class="h-5 w-5 text-gray-400"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </span>


      </div>
    </span>

    <div
      class="absolute mt-1 w-full rounded-md bg-white shadow-lg z-10"
      :class="{ selectHide: !open,  'max-h-24 overflow-y-scroll' : shouldLimitHeight }"
    >
      <div
        role="listbox"
        aria-labelledby="listbox-label"
        aria-activedescendant="listbox-item-3"
        class="
          max-h-60
          rounded-md
          py-1
          text-base
          leading-6
          shadow-xs
          overflow-auto
          focus:outline-none
          sm:text-sm sm:leading-5
        "
      >
        <template v-if="searchResults.length">
          <div
            v-for="(item, i) of searchResults"
            :key="i"
            id="listbox-option-0"
            role="option"
            @click="changeValue(item, i)"
            class="
              text-gray-900
              cursor-pointer
              select-none
              relative
              py-2
              pl-3
              pr-9
              w-full
              hover:bg-curious-blue-100
            "
          >
            <span class="font-normal flex items-center space-x-5 truncate">
              <UICheckbox
                :checked="item.checked"
                v-if="multiple"
                class="mr-2"
                size="small"
              />
              {{ item.label }}
            </span>
          </div>
        </template>
        <div v-else class="px-4 py-2">No result found.</div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, ref, toRefs, defineComponent, PropType } from 'vue'
import { UICheckbox } from '@gohighlevel/ghl-canvas'
export interface IOptions {
  value: string
  label: string
  checked?: boolean
}
export default defineComponent({
  name: 'UISelect',
  components: { UICheckbox },
  props: {
    options: {
      type: Array,
      default: [] as PropType<IOptions[]>,
    },
    defaultOpen: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    selected: {
      type: [String, Number, Array] as PropType<string | number | []>,
      default: '',
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    shouldLimitHeight: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const open = ref(props.defaultOpen)
    const selectedVal = props.options.find(
      (x: any) => x.value === props.selected
    ) as object
    const { selected } = toRefs(props)
    const selectedValue = ref<Array<any> | ''>(
      selectedVal ? [{ ...selectedVal, checked: false }] : ''
    )

    if (props.multiple) {
      selectedValue.value = props.options.filter((option: any) =>
        //@ts-ignore
        selected.value.includes(option.value)
      )
    }

    const textPreview = computed(
      () =>
        (selectedValue.value.length && selectedValue.value[0].label) ||
        props.placeholder
    )

    function changeValue(item: IOptions, index: number) {
      if (!props.multiple) {
        selectedValue.value = [item]
        open.value = false
        emit('update:selectedValue', selectedValue.value[0])
      } else {
        searchResults.value[index].checked = !searchResults.value[index].checked
        selectedValue.value = props.options.filter((x: any) => x.checked)
        emit('update:selectedValue', selectedValue.value)
      }
    }

    // search func
    const searchWord = ref()
    const searchResults = ref<IOptions[]>(
      makeMultiSelect(props.options as IOptions[])
    )

    function onContentSearch(e: any) {
      open.value = true
      searchWord.value = e.target.value
      if (!searchWord.value) searchResults.value = props.options as IOptions[]
      const inputValue = searchWord.value.toUpperCase()
      searchResults.value = props.options.filter(
        (x: any) => x.label.toUpperCase().indexOf(inputValue) > -1
      ) as IOptions[]
    }

    function makeMultiSelect(options: IOptions[]) {
      return options.map(x => {
        //@ts-ignore
        if (props.multiple && selected.value.includes(x.value)) {
          x['checked'] = true
        } else {
          x['checked'] = false
        }
        return x
      })
    }

    function removeSelectedValue(item: IOptions, e: any) {
      e.stopPropagation()
      //@ts-ignore
      selectedValue.value = selectedValue.value.filter(
        (x: any) => x.value !== item.value
      )
      const index = props.options.findIndex((x: any) => x.value === item.value)
      if (index > -1) {
        //@ts-ignore
        props.options[index].checked = false
      }
      emit('update:selectedValue', selectedValue.value)
    }
    return {
      textPreview,
      open,
      selectedValue,
      changeValue,
      onContentSearch,
      searchResults,
      removeSelectedValue,
      searchWord,
      error: props.error,
    }
  },
})
</script>

<style scoped>
.custom-select .selected {
  cursor: pointer;
  user-select: none;
}

.custom-select .selected:after {
  position: absolute;
  top: 22px;
  right: 1em;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: #fff transparent transparent transparent;
}

.custom-select .items {
  border-radius: 0px 0px 6px 6px;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
}

.custom-select .items div {
  user-select: none;
}

.selectHide {
  display: none;
}
</style>
