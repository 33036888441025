import AppState from '@/states/app'

export function openCalendarModal(
  calId: string,
  providerId: string,
  calendarType = 'event',
  beingCopyCalendar = false
) {
  AppState.parentApp?.then(parent => {
    parent.emit('open-cal-create-modal', {
      calId,
      providerId,
      beingCopyCalendar,
      calendarType,
    })
  })
}

export function openNewGroupModal() {
  AppState.parentApp?.then(parent => {
    parent.emit('open-team-modal', {
      providerId: undefined,
    })
  })
}

export function openAddToGroupModal(calendarId: string) {
  // showAddToGroupModal.value = true
  // calendarToBeMoved.value = calendarId
}

export function openTeamModal(teamId: string) {
  AppState.parentApp?.then(parent => {
    parent.emit('open-team-modal', {
      providerId: teamId,
    })
  })
}

export function toggleAdvancedView() {
  AppState.parentApp?.then(parent => {
    parent.emit('toggle-advanced-view', {})
  })
}
