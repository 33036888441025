{
  "settings": {
    "calendar": {
      "calendar_settings": "Kalenterin asetukset",
      "group": "Ryhmä",
      "manage_your_calendars_and_groups": "Hallitse kalentereitasi ja ryhmiäsi",
      "groups": "Ryhmät",
      "create_group": "Luo ryhmä",
      "create_calendar": "Luo kalenteri",
      "calendar_name": "Kalenterin nimi",
      "group_duration": "Ryhmin kesto",
      "calendar_type": "Kalenterityyppi",
      "action": "Toiminta",
      "round_robin": "Kierrosrobin",
      "event": "Tapahtuma",
      "group_name": "Ryhmän nimi",
      "calendars": "Kalenterit",
      "date_updated": "Päivityspäivä",
      "status": "Tila",
      "action_dropdown": "Toimintavalikko",
      "edit": "Muokkaa",
      "duplicate": "Kaksoiskappale",
      "copy_embed_code": "Kopioi upotuskoodi",
      "copy_permanent_link": "Kopioi pysyvä linkki",
      "copy_new_slug_link": "Kopioi aikataulun linkki",
      "copy_scheduling_link": "Kopioi aikataulun linkki (vanhentunut)",
      "deactivate_calendar": "Poista kalenteri käytöstä",
      "move_to_group": "Siirry ryhmään",
      "delete_calendar": "Poista kalenteri",
      "delete_group": "Poista",
      "delete_this_group": "Poista tämä ryhmä",
      "activate_calendar": "Aktivoi kalenteri",
      "duration": "Kesto (min)",
      "all": "kaikki",
      "draft": "Luonnos",
      "active": "Aktiivinen",
      "rearrange_calendars": "Järjestä kalenterit uudelleen",
      "deactivate_all_calendars_in_group": "Poista kaikkien kalenterien käyttö ryhmästä",
      "activate_all_calendars_in_group": "Aktivoi kaikki kalenterit ryhmässä",
      "no_groups_found": "Hakusi ei vastannut kalenteriryhmää",
      "no_groups_in_location": "Aloita käyttö ryhmän luomalla uusi kalenteriryhmä",
      "no_calendars_found": "Aloita kalenterin käyttö luomalla uusi kalenteri",
      "no_calendars_found_search": "Hakuasi vastaavia kalentereita ei löytynyt",
      "delete_calendar_message": "Olet poistamassa Kalenteria. Kun painat poista, se poistaa myös kaikki Kalenterin ajanvaraukset",
      "delete_calendar_message_title": "Poistetaanko tämä kalenteri?",
      "no_calendars_found_title": "Ei löytynyt kalentereita",
      "no_groups_in_location_title": "Ryhmiä ei löytynyt",
      "delete_group_confirmation_title": "Poistetaanko tämä ryhmä?",
      "delete_group_confirmation": "Oletko varma, että haluat poistaa valitun ryhmän?",
      "delete_group_confirmation_checkbox_title": "Poista liittyvät kalenterit ja tapaamiset",
      "delete_group_confirmation_checkbox_description": "Valitsemalla tämän vaihtoehdon myös tämän ryhmän kalenterit poistetaan, samoin kuin kaikki näiden kalentereiden tapaamiset",
      "group_status_message_title": "{status} tämä ryhmä?",
      "calendar_status_message_title": "{status} Tarkistaisitko tämän kalenterin tilan?",
      "calendar_status_message": "Olet pian {status}  kalenteria",
      "group_status_message": "Olet juuri {status}  ryhmää",
      "cancel": "Peruuta",
      "new_service_menu": "Nyt service-menu",
      "service_menus": "Palveluvalikko",
      "manage_your_calendars_groups_and_service_menus": "Hallitse kalentereitasi, ryhmiäsi ja palveluvalikkoasi",
      "deactivate_service_menu": "Poista palveluvalikko käytöstä",
      "delete_service_menu": "Poista tämä palveluvalikko",
      "delete_service_menu_confirmation": "Oletko varma, että haluat poistaa tämän palveluvalikon?",
      "service_menu_status_message_title": "{status} tämä palveluvalikko?",
      "service_menu_status_message": "Olet juuri {status} palveluvalikossa",
      "no_service_menus_in_location_title": "Luo räätälöity palvelumenusi",
      "add_new_service_menu": "Lisää uusi palveluvalikko",
      "no_service_menus_in_location": "Aloita luomalla uusi palveluvalikko ja lisää siihen ryhmiä",
      "no_service_menu_found": "Palveluvalikkoa ei löydy",
      "no_service_menu_found_desc": "Hakusi ei vastannut mitään palvelumenuluetteloa",
      "rooms": "Huoneet",
      "equipments": "Varusteet",
      "share_otl": "Jaa saatavuutesi aina ainutlaatuisella linkillä, joka vanhenee varauksen jälkeen, varmistaen hallitun pääsyn",
      "generate_link": "Luo uusi linkki",
      "share_schedule_link": "Aikataulutuslinkki määräytyy tunnisteesta. Säädä tunnistetta, niin aikataulutuslinkki mukautuu automaattisesti muokkaukseen",
      "share_permanent_link": "Ihanteellinen suppiloille, verkkosivujen uudelleenohjauksille tai mainoksille, pysyvä linkki pysyy vakiona, muuttumatta slug-muutoksista",
      "copy": "Kopio",
      "share_embed_code": "Aseta tämä koodi HTML:ään sinne, missä haluat {embedType} -widgettisi näkyvän",
      "scheduling_link": "Aikataululinkki",
      "permanent_link": "Pysyvä linkki",
      "one_time_link": "Yhden kerran linkki",
      "embed_code": "Upotuskoodi",
      "loading": "Ladataan...",
      "round_robin_booking": "Kierrosrobin",
      "round_robin_description": "Jakaa tapaamiset tiimin jäsenten kesken kiertävässä järjestyksessä",
      "round_robin_example": "Esim.: Myyntipuhelut, perehdytystilaisuudet",
      "unassigned_booking": "Tapahtumakalenteri",
      "unassigned_description": "Rasitusten ajoittaminen fyysisiin tapahtumiin ilman isäntäyhdistystä",
      "unassigned_example": "Esim.: Konferenssit, julkiset seminaarit, näyttelyt",
      "class_booking": "Luokan varaus",
      "class_booking_description": "Yksi isäntä tapaa useita osallistujia",
      "class_booking_example": "Esim.: Verkkoseminaarit, ryhmäkoulutus, verkkokurssit",
      "collective_booking": "Kokoontumisvaraus",
      "collective_booking_description": "Useiden isäntien tapaaminen yhden osallistujan kanssa",
      "collective_booking_example": "Esim .: Paneelihaastattelut, komitean arvioinnit",
      "service_booking": "Palvelun varaaminen",
      "service_booking_description": "Saumaton aikataulutus palvelupohjaisille yrityksille",
      "service_booking_example": "Esimerkiksi: Kylpyläajanvaraukset, korjauspalvelut, konsultoinnit",
      "personal_booking": "Henkilökohtainen varaus",
      "personal_booking_description": "Aikatauluttaa yksilölliset tapaamiset tietyn tiimin jäsenen kanssa",
      "personal_booking_example": "Esimerkiksi: Asiakastapaamiset, yksityiskonsultaatiot",
      "personal": "Henkilökohtainen",
      "explore_more_types": "Tutki lisää tyyppejä",
      "scheduling_type": "Aikataulutustyyppi",
      "scheduling_type_description": "Valitse aikataulutyyppi uudelle kalenterillesi"
    },
    "preferences": {
      "preferences": "Asetukset",
      "my_preferences": "mieltymykseni",
      "account_preferences": "Tilin asetukset",
      "user_preferences": "Käyttäjän asetukset",
      "set_preferences": "Aseta mieltymyksesi tilillesi",
      "scheduling_options": "Aikataulutusvaihtoehdot",
      "meeting_location": "Tapaamispaikka",
      "zoom": "Zoom",
      "save_preferences": "Tallenna asetukset",
      "in_app_preferences": "Sovelluksen asetukset",
      "set_start_day": "Aseta asetukset, kuten aloituspäivä sovellukselle",
      "view_options": "Näytä vaihtoehdot",
      "week_starts_on": "Viikko alkaa",
      "sunday": "sunnuntai",
      "widget_preferences": "Pienoisohjelman asetukset",
      "set_widget_language": "Aseta asetukset kuten kieli pienoiskoostomukille",
      "language_and_region": "Kielialue",
      "language": "Kieli",
      "english": "englanti",
      "time_format": "Aikamuoto",
      "time_format_value": "5,625",
      "monday": "Maanantai",
      "custom_meeting_location": "Mukautettu kokouspaikka"
    },
    "availability": {
      "availability": "Saatavuus",
      "my_availability": "Saatavuuteni",
      "account_availability": "Tilien saatavuus",
      "timezone": "Aikavyöhyke",
      "working_hours": "Työaika",
      "set_recurring_hours": "Aseta säännölliset työskentelyajat kokouksille",
      "sunday": "sunnuntai",
      "monday": "maanantai",
      "tuesday": "Tiistai",
      "wednesday": "Keskiviikko",
      "thursday": "torstai",
      "friday": "Perjantai",
      "saturday": "Lauantai",
      "widget_preferences": "Widget-asetukset",
      "set_widget_language": "Aseta asetukset, kuten widgetin kieli",
      "language_and_region": "Kieli ja alue",
      "language": "Kieli",
      "english": "englanti"
    },
    "connections": {
      "connections": "Yhteydet",
      "conferencing": "Puhelin- tai videosiirto",
      "my_connections": "Minun yhteykseni",
      "account_connections": "Tilin yhteydet",
      "connection_preferences": "Yhteysasetukset",
      "manage_calendars_conferencing_channels": "Hallitse kalentereita ja konferenssikanavia",
      "main_integration_calendar": "Pääintegraatiokalenteri",
      "event_integration_calendar": "Järjestelmän kalenteriin luodut tapahtumat, joihin olet osallisena, luodaan myös tälle integroidulle kalenterille",
      "2way_sync": "2-suuntainen synkronointi: Luo järjestelmätapahtumia tapahtumista, jotka on varattu pääintegroidusta kalenterista",
      "calendars": "Kalenterit",
      "google": "Google",
      "outlook": "Näkymä",
      "check_for_conflicts": "Tarkista konfliktit",
      "choose_accounts_to_connect": "Valitse, mitkä tilit haluat yhdistää",
      "payments": "Maksut",
      "stripe": "Raita",
      "ads": "Mainokset",
      "local_service_ads": "Paikalliset palvelu-mainokset",
      "fire_workflow": "Tulipalon työnkulun käynnistimet tapahtumille, jotka on varattu kahden suunnan synkronoinnin kautta",
      "connect_google_outlook": "Ole hyvä ja yhdistä Google- tai Outlook-tilisi, jotta voit valita ensisijaisen kalenterin",
      "execute_automation": "Suorita automaatio",
      "save": "Tallenna",
      "lsa": "Paikalliset palvelumainokset"
    }
  },
  "appointment_modal": {
    "select_a_contact": "Valitse yhteystieto",
    "start_time": "Aloitusaika",
    "end_time": "Loppuaika",
    "search_by_name_email_phone_or_company": "Hae nimellä, sähköpostilla, puhelimella tai yrityksellä",
    "add_new": "Lisää uusi",
    "pick_from_available_contacts": "Tai valitse käytettävissä olevista yhteystiedoista",
    "book_appointment": "Varaa aika",
    "calendar": "Kalenteri",
    "repeat": "Toista",
    "date_and_time": "Päivämäärä ja aika",
    "close": "Sulje",
    "team_member": "Tiimin jäsen",
    "recurring_booking": "Toistuva varaaminen",
    "one_slot_booking": "Yhden paikan varaus",
    "event_type": "Tapahtuman tyyppi",
    "occurrences_booked": "tapahtumat kirjataan",
    "repeats": "Toistot",
    "booking_details": "Varaustiedot",
    "showing_slots_in_this_timezone": "Näytetään aikavyöhykkeen paikat:",
    "update_contact_tz": "Päivitä myös yhteyshenkilön aikavyöhyke",
    "date": "Päivämäärä",
    "slot": "Aikavaraus",
    "calendar_default": "Kalenterioletus",
    "custom": "Mukautettu",
    "meeting_notes": "(Kuten esimerkiksi) Kokousmuistiot tulevaa tapaamistamme varten",
    "out_of_office": "Toimistosta poissa koko viikon!",
    "standard": "Oletusarvo",
    "appointment_title": "Aftale Titel",
    "event_title": "Tapahtuman nimi",
    "appt_with_bob": "(Til.) Tapaaminen Bobin kanssa",
    "vacation": "Loma",
    "show_notes": "Näyttömuistiinpanot",
    "hide_notes": "Piilota muistiinpanot",
    "additional_preferences": "Lisäasetukset",
    "meeting_location": "Kokouspaikka",
    "appointment_status": "Vastaanottoajan tila",
    "appt_description": "Nimityksen kuvaus",
    "recurring_details": "Toistuvat yksityiskohdat",
    "event_description": "Tapahtuman kuvaus",
    "in_contact_timezone": "Aikavyöhykkeessä yhteydessä",
    "vacation_message": "Meneekö lomalle? Otatko hieman vapaata? Estä ajanjakso kalenterissasi välttääksesi asiakkaiden tekemät ajanvaraukset (aiemmin varatut ajat säilyvät edelleen kalenterissasi)",
    "contact": "Yhteystiedot",
    "delete": "Poista",
    "cancel": "Peruuta",
    "back": "Takaisin",
    "pick_available_contacts": "Tai valitse käytettävissä olevista yhteystiedoista",
    "no_contacts_found": "Yhteyshenkilöitä ei löytynyt",
    "found": "löydetty",
    "select_one": "Valitse yksi",
    "tz_tooltip": "Tämä on tärkeää vain, jos käytät mukautettuja arvoja; kuten appointment_start_time, appointment_end_time, appointment_timezone jne. ulkoisessa viestinnässäsi asiakkaiden tai asiakkaiden kanssa. Ja se yksinkertaisesti muuntaa edellä mainitut tiedot paikalliseksi ajaksi",
    "meeting_location_tooltip": "Tapaamispaikka määritellään kalenterissa asetetun mukaisesti",
    "appt_meeting_location_tooltip": "Käyttäjä voi määrittää tapaamispaikan erityiseksi kokousta varten",
    "gmeet_tooltip": "Varmista, että kyseisellä tiimin jäsenellä on Google-kalenteri pääkalenterinaan",
    "zoom_tooltip": "Varmista, että kyseisellä tiimin jäsenellä on integroitu Zoom",
    "enter_meeting_location": "Syötä kokouspaikka",
    "save_appointment": "Tallenna ajanvaraus",
    "save_event": "Tallenna tapahtuma",
    "search_contacts": "Hae nimellä, sähköpostilla, puhelinnumerolla tai yrityksellä",
    "recurring_slots": "{recurringSlotsLength}/{count}"
  },
  "popup": {
    "calendar": "Kalenteri",
    "appointment_owner": "Nimityksen omistaja",
    "source": "Lähde",
    "booked_by": "Varannut henkilö",
    "view_activity_log": "Näytä toimintaloki",
    "status": "Tila",
    "cancel_appointment": "Peruuta aika",
    "google_event": "Google-tapahtuma",
    "outlook_event": "Näkymä-tapahtuma",
    "blocked_slot": "Estetty paikka",
    "appointment": "Tapaaminen",
    "confirmed": "Vahvistettu",
    "unconfirmed": "Vahvistamaton",
    "cancelled": "Peruttu",
    "showed": "Näytti",
    "no_show": "ei saapunut",
    "invalid": "Invalidi"
  },
  "appointment_list": {
    "group_all": "KAIKKI",
    "group_primary": "PÄÄASIALLINEN",
    "group_user": "KÄYTTÄJÄT",
    "group_team": "RYHMÄT",
    "group_calendar": "kalenterit",
    "all_appointments": "Kaikki tapaamiset",
    "my_calendar": "Minun kalenterini",
    "status_all": "Kaikki",
    "status_confirmed": "Vahvistettu",
    "status_new": "Uusi (toiminto vaaditaan)",
    "status_show": "Näytti",
    "status_no_show": "Ei saapunut",
    "status_cancelled": "Peruttu",
    "status_invalid": "Invalidi",
    "filter_date_added_desc": "Lisätty päivämäärä (Laskeva järjestys)",
    "filter_start_added_desc": "Aloituspäivämäärä (Laskeva järjestys)",
    "filter_start_added_asc": "AlkuPäivä Nouseva Tänään",
    "button_new_appointment": "Uusi nimitys",
    "name": "Nimi",
    "title": "Otsikko",
    "requested_time": "Pyydetty aika",
    "date_added": "Lisätty päivämäärä",
    "calendar": "Kalenteri",
    "appointment_owner": "Tapaamisen omistaja",
    "notes": "Muistiinpanot",
    "status": "Tila",
    "action_view_detail": "Näytä yksityiskohdat",
    "action_view_notes": "Näytä muistiinpanot",
    "action_edit": "Muokkaa",
    "action_delete": "Poista",
    "action_reschedule": "Siirtää aikataulua",
    "page_previous": "Edellinen",
    "page_next": "Seuraava",
    "appointment_notes": "Ajanmuistio",
    "close": "Lähellä",
    "confirm_delete_header": "Poista ajanvaraus",
    "confirm_delete_message": "Oletko varma, että haluat poistaa tämän tapaamisen?",
    "confirm_delete_button": "Poista",
    "confirm_update_header": "Päivitä tapaamisen tila",
    "confirm_update_confirmed_message": "Oletko varma, että haluat merkitä tämän ajanvarauksen vahvistetuksi?",
    "confirm_update_invalid_message": "Oletko varma, että haluat merkitä tämän tapaamisen virheelliseksi?",
    "confirm_update_showed_message": "Oletko varma, että haluat merkitä tämän tapaamisen näytetyksi?",
    "confirm_update_noshow_message": "Oletko varma, että haluat merkitä tämän tapaamisen peruutuneeksi?",
    "confirm_update_cancelled_message": "Oletko varma, että haluat merkitä tämän tapaamisen peruutetuksi?",
    "confirm_update_button": "Päivitys",
    "disable_menu_warning": "Tällä hetkellä sinulla ei ole lupaa nähdä tai muokata tapaamisen tietoja, koska kyseiseen yhteyshenkilöön ei ole sinulle nimitettyä tehtävää. Ole hyvä ja ota yhteys ylläpitäjääsi lisäavun saamiseksi.",
    "no_contact_warning": "Et voi tällä hetkellä saada yhteyshenkilön tietoja. Syynä voi olla, että et ole oikeutettu kyseiseen yhteyshenkilöön. Ota yhteyttä ylläpitäjääsi saadaksesi apua",
    "youre_viewing_in": "Currently viewing in",
    "rescheduled": "Uudelleen ajoitettu"
  },
  "create_calendar": {
    "calendar_name": "Kalenterin nimi",
    "calendar_logo": "Kalenterin logo",
    "select_team_member": "Valitse tiimin jäsenet",
    "eg_outbound_reach": "(eg) Lähtevä tavoittavuus",
    "description": "Kuvaus",
    "amount": "Määrä",
    "description_placeholder": "Kirjoita kuvaus",
    "meeting_duration": "Kokouksen kesto",
    "seats_per_class": "Paikkoja luokkaa kohti",
    "group": "Ryhmä",
    "custom_url": "Mukautettu URL",
    "meeting_invite_title": "Tapaamiskutsun otsikko",
    "event_color": "Tapahtuman väri",
    "availability_type": "Saatavuuden tyyppi",
    "deposit_amount": "Talletusmäärä",
    "collect_deposit_amount": "Hyväksy osittainen maksu",
    "percentage": "Prosenttiosuus",
    "total_amount": "Kokonaismäärä",
    "reschedule_preference": "Aikataulun muutospreferenssi",
    "reschedule_preference_subtext": "Jos yhteyshenkilö siirtää tapaamista:",
    "reassign_through_round_robin": "Uudelleenmäärittele kierros-kierrokselta",
    "keep_same_assinged_user": "Pidä saman ajanvarauksen omistaja",
    "new_appointment_preference": "Uusi ajanvarauksen mieltymys",
    "new_appointment_preference_text": "Varaa aina yhteyshenkilön määrittelemän käyttäjän kautta",
    "new_appointment_preference_subtext": "Varaa uusia tapaamisaikoja yhteyshenkilön määrittämän käyttäjän kanssa sen sijaan, että käytettäisiin kiertävä aikataulutusta"
  },
  "calendar_advanced": {
    "general": {
      "quick_tip": "Pikavinkki",
      "timezone_change_tip": "Aikavyöhykkeitä voidaan muuttaa milloin tahansa Aikavyöhykeasetusten alla",
      "save": "Tallenna",
      "close": "sulje",
      "meeting_details": "Kokoustiedot",
      "availability": "Käytettävyys",
      "forms_and_payment": "Lomakkeet ja maksu",
      "notifications_and_additional_options": "Ilmoitukset ja lisäasetukset",
      "connections": "Yhteydet",
      "customizations": "Mukautukset",
      "create": "Luo",
      "edit": "Muokkaa"
    },
    "meeting_details": {
      "meeting_location": "Tapaamispaikka",
      "enter_meeting_location": "Syötä kokouspaikka",
      "click_to_upload": "Napsauta ladataksesi",
      "square": "Neliö",
      "circle": "Ympyrä",
      "remove_logo": "Poista logo",
      "meeting_invite_title_tooltip": "Tämä on kalenteritapahtuman otsikko, joka näkyy Google Kalenterissa, Outlookissa, Apple Kalenterissa jne",
      "event_color_tooltip": "Valitse väri tälle vara",
      "enter_a_name": "Syötäthän nimen",
      "enter_an_event_title": "Syötä tapahtuman otsikko, ole hyvä",
      "high_priority": "Tärkein etusija",
      "medium_priority": "Keskitaso",
      "low_priority": "Pieni prioriteetti",
      "custom": "Mukautettu",
      "zoom_not_added": "Käyttäjä ei ole vielä liittänyt Zoom-tiliään",
      "opt_avail": "Optimoi saatavuuden vuoksi",
      "opt_equal": "Optimoi tasaiselle jakautumiselle",
      "team_members": "Valitse tiimin jäsenet",
      "team_member": "Valitse joukkueen jäsen",
      "meeting_location_required": "Kokouspaikka vaaditaan",
      "team_member_disabled_recurring_tooltip": "Toistuvat kokoukset sallivat vain yhden tiimin jäsenen. Lisätäksesi useita tiimin jäseniä, ota toistuvat kokoukset pois käytöstä.",
      "team_member_disabled_class_booking_tooltip": "Vain yksi tiimin jäsen voi lisätä itsensä luokan varauskalenteriin",
      "enable_contact_assigned_to_setting": "Aktivoidaksesi tämän asetuksen, Lomakkeen tulee olla ensimmäisenä widget-järjestyksessä. Järjestä widgetit uudelleen Lomakkeet & Maksut -välilehdeltä",
      "reschedule_preference_setting_tooltip": "Valitse, kenelle ajanvaraus tulisi määrätä, kun yhteystiedon varaama aika siirretään uudelleen ajanvarauswidgetistä",
      "appointment_preference_tooltip": "Valitse, pitäisikö uudet tapaamiset varata aina yhteyshenkilön määritetyn käyttäjän kanssa. Jos yhteyshenkilöllä on määritetty käyttäjä, kyseinen käyttäjä saa etusijan; muussa tapauksessa se on kiertävä tehtävä. Vinkki: Poista valinta 'Salli henkilökunnan valinta' estääksesi varaajia vaihtamasta henkilökunnan jäsentä varaus-widgetissä",
      "add_location": "Lisää sijainti",
      "multiple_locations_neo": "Useita kokouspaikkoja tuetaan vain Neen välityksellä Widget oletuslomakkeen kanssa",
      "make_owner": "Omistajan tekeminen",
      "only_one_team_member": "Vain yksi tiimin jäsen on sallittu, kun useita sijainteja lisätään",
      "only_one_team_member_personal_calendar": "Vain yksi tiimin jäsen on sallittu henkilökohtaiseen varaamiseen",
      "ms_teams_not_added": "Käyttäjä ei ole vielä yhdistänyt Microsoft Teams -tiliään"
    },
    "availability": {
      "my_availability": "Saavutettavuuteni",
      "set_availability": "Aseta käytettävyytesi kalenteriin täällä",
      "standard": "Standardi",
      "custom": "Räätälöity",
      "to": "Kohti",
      "copy_to_all": "Kopioi kaikille",
      "add_time": "Lisää aikaa",
      "recurring_meeting": "Toistuva kokous",
      "recurring_info": "Vain yksi käyttäjä per kalenteri toistuvalle varaukselle",
      "repeat": "Toista",
      "times_to_repeat": "Toistojen määrä",
      "slots_unavailable": "Jos paikkoja ei ole saatavilla?",
      "overlap_status": "Ajan varaustilanne päällekkäisille aikaväleille",
      "meeting_interval": "Tapaamiskerroissa",
      "meeting_duration": "Kokouksen kesto",
      "minimum_scheduling": "Vähimmäisilmoitusaika",
      "date_range": "Päiväkirjojen väli",
      "maximum_bookings_per_day": "Maksimivaraukset päivässä",
      "maximum_bookings_per_slot": "Maksimi varaukset per aikaväli",
      "maximum_bookings_per_slot_per_user": "Enimmäisvaraukset per aikaväli (käyttäjää kohden)",
      "seats_per_class": "Paikkoja per luokka",
      "pre_buffer_time": "Esipuskurin kesto",
      "post_buffer_time": "Postipuskuri-aika",
      "pre_buffer_tooltip": "Esituffa on lisäaika, joka voidaan lisätä ennen tapaamista mahdollistaen ylimääräisen ajan valmistautua ennen kokousta",
      "post_buffer_tooltip": "Post-Puskuriaika on lisäaika, joka voidaan lisätä tapaamisen jälkeen, antaen ylimääräistä aikaa asiat päättämiseen",
      "buffer_time": "Puskurinaika",
      "daily": "Päivittäinen",
      "weekly": "Viikoittain",
      "monthly": "Kuukausittainen",
      "skip_booking": "Siirrä varaus pois käytettävissä olemattomista ajoista",
      "continue_booking": "Jatka varausta",
      "book_next_available": "Varaa seuraava mahdollinen aika",
      "confirmed": "Vahvistettu",
      "unconfirmed": "Vahvistamaton",
      "enter_valid_hours": "Syötä kelvolliset aukioloajat, ole hyvä",
      "enter_valid_avail": "Syötä kelvollinen mukautettu saatavuus.",
      "availability_type_tooltip": "Valitse vakio saatavuus säännöllisille viikoittaisille varauksille. Valitse räätälöity vaihtoehto vain tietyinä päivinä saatavilla oleville varauksille",
      "meeting_interval_tooltip": "Kokousväli heijastaa aikaa varausten välillä, jotka näkyvät kalenterissa. 30 minuutin tapahtumalle, joka pitäisi olla saatavilla joka tunnin alussa, väli olisi 1 tunti. 30 minuutin tapahtumalle, joka pitäisi olla saatavilla joka 30 minuutin välein, väli olisi 30 minuuttia",
      "date_range_tooltip": "Tapahtumat voidaan aikatauluttaa seuraavien n päivän aikana",
      "maximum_bookings_per_day_tooltip": "Enimmäismäärä varattavia tapaamisia päivittäin tällä kalenterilla",
      "maximum_bookings_per_slot_tooltip": "Enimmäismäärä sallittuja tapaamisia per aikaikkuna käyttäjää kohti (Mikäli tapaamisten määrä ylittää aikaikkunassa sallitun määrän käyttäjälle, siirrytään seuraavaan käyttäjään jonossa pyörivässä järjestyksessä tai aikaikkuna merkitään mahdottomaksi varaamista varten hakijalle)",
      "add_days": "Lisää päiviä",
      "month_on_day": "Kuukausittain päivänä",
      "month_on_last_day": "Kuukausittain viimeisenä päivänä",
      "day": "Päivä",
      "week": "viikko",
      "month": "Kuukausi",
      "mins": "Minuutit",
      "hours": "Tunnit",
      "days": "päivät",
      "weeks": "Viikot",
      "months": "Kuukaudet",
      "meeting_interval_error": "Kokousvälin on oltava 5 minuutin ja 12 tunnin välillä",
      "meeting_duration_error": "Kokouksen keston tulee olla välillä 1 minuutista 12 tuntiin",
      "buffer_time_error": "Puskuriaika on oltava alle 12 tuntia",
      "subTitle": "Valitse päivämäärä asettaaksesi tarkat tunnit",
      "heading_1": "Viikkokäytettävissä olevat tunnit",
      "tooltip_1": "Vahvista viikoittainen käytettävyysaikataulusi. Nämä ovat säännöllisesti joka viikko sovellettavat vakiotyöaikasi",
      "heading_2": "Milloin olet käytettävissä?",
      "unavailable_label": "Ei saatavilla",
      "tooltip_2": "Säädä aikataulusi tiettyjä päiviä varten. Ohita säännölliset viikkotuntisi merkitsemällä saatavuus tai saatavuuden puute valituille päiville",
      "date_specific_level": "Lisää päivämäärää koskevia tunti-ilmoituksia",
      "specific_hours_button": "Päivämääräkohtaiset tunnit",
      "overlap_error": "Aikavälit eivät voi päällekkäin",
      "apply": "Hakea",
      "copy_times_to": " Kopioi ajat",
      "no_date_specific_heading": "Ei lisättyä tiettyä aikaa",
      "no_date_specific_description": "Voit lisätä/poistaa tiettyjä päivämääriä ja aikoja saatavuuteesi",
      "recurring_disabled_conditions_tooltip": "Toistuvat tapaamiset on poistettu käytöstä. Voit ottaa ne käyttöön varmistamalla, että kalenterissa on vain yksi tiimin jäsen ja et ole lisännyt tiettyjä päivämääriä",
      "date_specific_hours_disabled_conditions_tooltip": "Sisällytä päivämääräkohtainen tunti, varmista että toistuvat kokoukset ovat pois päältä",
      "recurring_disable_event_calendar_conditions": "Toistuvat kokoukset on poistettu käytöstä. Aktivoidaksesi ne, älä lisää tiettyä päivämäärää tai aikaa",
      "service_interval": "Service interval",
      "service_interval_tooltip": "Mødeintervallet angiver, hvor ofte booking-slots vises på kalenderen. For eksempel, for et arrangement på 30 minutter, betyder et interval på en time, at der vises slots hver time. Et interval på 30 minutter betyder, at der vises slots hver 30. minut",
      "service_duration_tooltip": "Dette bestemmer længden på din aftale og afgør, hvor længe den varer",
      "minimum_scheduling_tooltip": "Dette angiver den mindste tid, der kræves for at booke en aftale via kalenderwidgeten. For eksempel, hvis den er indstillet til 4 timer, kan aftaler ikke bookes inden for 4 timer før deres starttid; bookinger skal foretages mindst 4 timer i forvejen",
      "service_duration": "Service duration",
      "look_busy_title": "Näytä kiireiseltä",
      "look_busy_percentage_description": "Piilota käytettävissä olevien paikkojen määrä x %",
      "look_busy_helper_text": "Piilota osa käytettävistä ajanvarauspaikoista varausikkunassa näyttääksesi kiireisemmältä ja enemmän kysytymmältä",
      "maximum_bookings_per_slot_tooltip_personal": "Käyttäjälle sallittu aikaväli kohden sallittujen tapaamisten enimmäismäärä"
    },
    "forms_payment": {
      "forms": "Lomakkeet",
      "forms_sub": "Muokkaa aikataulutuslomakkeen asetuksia",
      "select_form": "Valitse muoto",
      "sticky_contacts": "Esitä täytettyjä kenttiä (pysyvät yhteystiedot)",
      "confirmation_page": "Vahvistussivu",
      "default": "Oletus",
      "redirect_url": "Uudelleenohjaus-URL",
      "thank_you": "Kiitosviesti",
      "enter_url": "Syötä URL-osoite",
      "pixel_id": "Facebook-pikselin tunnus (valinnainen)",
      "auto_confirm": "Automaattinen vahvistus uusille kalenteritapaamisille",
      "configure_payment": "Määritä maksuvaihtoehdot",
      "accept_payments": "Hyväksy maksut",
      "test": "Koe",
      "live": "Asua or elää",
      "enable_guests": "Lisää vieraita",
      "add_guest_tooltip": "Lisää vieras mahdollistaa osallistujan lisäämisen samalle tapaamiselle useaan kertaan",
      "add_guest_same_form_check": "Lähetä sama lomake keräämään tietoja postitse kaikille vieraille",
      "payment_tooltip": "Maksua harkitaan vain pääosallistujalle eikä vieraille",
      "default_form": "Oletus (Etunimi, Sukunimi, Sähköposti, Puhelin, Huomautukset)",
      "select_form_tooltip": "Jos haluat kerätä enemmän tietoja kuin standardit Etunimi, Sukunimi, Sähköposti ja Puhelin niiltä, jotka vara",
      "confirmation_page_tooltip": "Kun joku tekee varauksen, voit valita näytetäänkö heille yksilöllinen viesti samalla sivulla (oletusarvo) vai ohjataanko heidät haluamallesi sivulle (ohjaus)",
      "add_guest_collect_email": "Nimellä ja sähköpostilla",
      "add_guest_count_only": "Laske vain",
      "custom_email_notification": "Lähetä muokattu lomake vieraille",
      "deposit_amount_error": "Talletussumman on oltava pienempi kuin kokonaissumma",
      "deposit_percentage_error": "Talletusprosentin on oltava alle 100%",
      "partial_payment_tooltip": "Ota käyttöön osittaiset maksut kerätäksesi asiakkailta ennakkomaksun ajanvarausprosessin aikana",
      "partial_payment_enable_error": "Syötetyn kokonaismäärän on oltava suurempi kuin 0 osittaisen maksun mahdollistamiseksi",
      "payment_disable_text_integration": "Integroi maksuntarjoaja kohdasta Maksut > Integraatiot aloittaaksesi maksujen vastaanottamisen",
      "payment_disable_text_recurring": "Hyväksynnän maksuvaihtoehto ei ole käytettävissä toistuville kokouksille. Kytke toistojen sulkeminen mahdollistaaksesi maksut",
      "date_and_time_selector": "Ajan ja päivämäärän valitsin",
      "form_selector": "Muoto",
      "order_step": "Vaihe",
      "widget_order_label": "Vimpelijärjestys",
      "widget_order_tool_tip": "Järjestä 'Päivä & Aika Valitsin' ja 'Lomake' järjestys kalenteri-widgetissä. Vedä ja järjestä uudelleen mieltymystesi mukaisesti",
      "same_user_assignment_tooltip": "Aktivoidaksesi tämän asetuksen, lomakkeen on oltava ensin widgetissä. Siirrä widgetti niin, että lomake on ensimmäisenä aktivoidaksesi tämän asetuksen",
      "widget_re_order_tooltip": "Järjestelyn uudelleenjärjestely on mahdollista vain Neo-widget-tyylillä. Siirry Neo-tilaan salliaksesi uudelleenjärjestelyn",
      "widget_re_order_disabled_tooltip": "Järjestääksesi pienoisohjelman järjestyksen uudelleen, poista 'Uusi tapaamisen asetus (Varaa aina yhteyden henkilön osoittamalla käyttäjällä)' käytöstä kohdasta Kokoustiedot > Tiimin jäsenet > Lisäasetukset",
      "enable_same_user_assignment": "Määritä yhteystiedot niiden vastaaville kalenteritiimin jäsenille aina kun varaat tapaamisen",
      "recurring_payment_label": "Toistuvat tapaamisten maksut",
      "first_appointment_only": "Vain ensimmäinen tapaaminen",
      "all_appointments": "Kaikki tapaamiset"
    },
    "notifications": {
      "notifications": "Ilmoitukset ja lisäasetukset",
      "notifications_sub": "Määritä ilmoitus ja lisäasetukset",
      "select_notification_type": "Valitse ilmoituksen tyyppi",
      "notification_person": "Kuka tulisi saada tämä ilmoitus?",
      "contact": "Yhteystieto",
      "assigned_user": "Määritetty käyttäjä",
      "emails": "Sähköpostit",
      "alert_email_address": "Hätäsposti osoite",
      "ack_email": "Vahvistussähköposti",
      "send_google_invites": "Salli Google-kalenterin lähettää kutsuja tai päivitysviestejä osallistujille",
      "send_google_outlook_invites": "Salli Googlen / Outlookin kalenterin lähettää kutsu- ja päivitysviestejä osallistujille",
      "assign_user_to_contact": "Jaa yhteystiedot heidän vastaaville kalenteritiimiläisilleen joka kerta, kun ajanvaraus tehdään",
      "skip_assigning_user_to_contact": "Älä määritä yhteyshenkilöä, jos hänellä on jo määritetty käyttäjä",
      "guest_label_count": "Vierasmäärä",
      "guest_count_error": "Vieraiden määrän tulisi olla 1–100",
      "allow_cancellation": "Salli tapaamisen peruutus",
      "allow_reschedule": "Salli kokouksen uudelleen aikatauluttaminen",
      "allow_cancellation_tooltip": "Kun toiminto on käytössä, peruutuslinkki lisätään lisätietojen osioon ja se sisällytetään kalenterikutsuun. Tämä linkki vanhenee ennen kokouksen alkamisaikaa konfiguroidusti, mikä estää varauksen tehneen henkilön peruuttamasta kokousta",
      "allow_reschedule_tooltip": "Kun toiminto on otettu käyttöön, uudelleenajastuslinkki lisätään lisätieto-osioon ja se sisällytetään kalenterikutsuun. Tämä linkki vanhenee ennen kokouksen alkamisaikaa määritetyn ajan, estäen varaajan uudelleen ajoittamasta kokousta",
      "cancellation_link_expiry": "Peruutuslinkki vanhenee",
      "reschedule_link_expiry": "Aikataulun muutoksen linkki vanhenee",
      "before_meeting": "ennen kokousta",
      "cancellation_and_reschedule_policy": "Maksuttomaan peruutukseen ja uudelleenaikataulutukseen liittyvä politiikka:"
    },
    "connections": {
      "connections_heading": "Ulkoisen kalenterin synkronointi",
      "connections_sub": "Synkronoi tapahtumat ulkoisen kalenterin kanssa",
      "link_to_calendar": "Linkki kalenteriin",
      "sync_option": "Synkronointiasetus",
      "execute_automation": "Suorita automaatio",
      "fire_workflow": "Palon työnkulun laukaisimet tapahtumille, jotka on varattu kahdensuuntaisen synkronoinnin kautta",
      "reserve_with_google": "Varaa Googlesta",
      "connect_lsa": "Yhdistä paikallinen perusjäsensivusi Ads-tiliin",
      "reserve_error": "Tällä hetkellä Reserve with Googlea tuetaan vain \"Kotipalvelut\", \"Kiinteistöala\" ja \"Oikeudelliset palvelut\" (paikalliset palveluntarjoajat) yrityssegmenteille Yhdysvalloissa",
      "one_way": "Yksisuuntainen",
      "two_way": "Kaksisuuntainen",
      "smart": "älykäs",
      "confirm_remove": "Oletko varma, että haluat poistaa Googlen kalenterin?",
      "read_only_access": "Sinulla ei ole kirjoitusoikeutta tähän kalenteriin. Valitse toinen kalenteri",
      "reserve_with_google_desc": "Tämä kalenteripalvelu tullaan listaamaan varmistusrekisteriin Googleen vain onnistuneen integroinnin jälkeen",
      "check_integration": "Tarkista integrointitilasi"
    },
    "extras": {
      "extras": "Räätälöinnit",
      "extras_sub": "Aseta pienoisohjelman tyyli ja muut asetukset",
      "calendar_widget_style": "Kalenterin pienoisohjelman tyyli",
      "neo": "Neo",
      "classic": "Klassinen",
      "insert_custom_code": "Lisää mukautettu koodi",
      "show_seats_per_slot": "Näytä istuimet per aikaikkuna",
      "allow_reschedule_meeting": "Salli kokouksen aikataulumuutos",
      "allow_cancelling_meeting": "Salli kokouksen peruuttaminen",
      "additional_notes": "Lisätiedot",
      "cancellation_and_reschedule": "Peruutus ja uudelleenjärjestely:",
      "allow_staff": "Salli henkilöstön valinta varauksen yhteydessä",
      "code_block_tooltip": "Kolmansien osapuolien skriptit HTML-lohkossa voivat tarjota tehokkaita toimintoja, mutta ne myös tuovat riskejä yksityisyydelle, turvallisuudelle, suorituskyvylle ja sivun toiminnalle. Siksi suosittelemme sinua tarkistamaan tämän skriptin sisällön ennen sen tallentamista kalenteriin"
    }
  },
  "add_guest": {
    "guest_label": "Vieras(t)",
    "search_placeholder": "Etsi yhteystiedoista",
    "add_new": "Lisää uusi",
    "save": "Pelasta",
    "close": "Sulje",
    "name": "Nimi",
    "phone": "Puhelin",
    "email": "Sähköposti"
  },
  "languages": {
    "english": "Englanti",
    "dutch": "Hollanti",
    "french": "Ranska",
    "german": "Saksa",
    "hungarian": "Unkari",
    "italian": "Italia",
    "polish": "Puola",
    "portuguese_brazil": "Portugali (Brasilia)",
    "portuguese_portugal": "Portugali (Portugali)",
    "spanish": "Espanja",
    "danish": "Tanska"
  },
  "service_menu_advanced": {
    "new_service_menu_details_header": "Service menuoplysninger",
    "select_groups_header": "Vælg tjenester",
    "arrange_groups_header": "Arranger dine services",
    "new_service_menu": "Nyt service-menu",
    "service_menu_sub_title": "Opret en personlig service-menu til dine kunder med blot et par klik",
    "quick_tip": "Du kan tilføje grupper til service-menuen",
    "service_menu_name": "Service Menu navn",
    "service_menu_description": "Beskrivelse",
    "service_menu_slug": "Service URL: Service-url",
    "select_groups_calendars": "Vælg grupper og kalendere",
    "select_groups_calendars_desc": "Tilføj tjenester til din servicemenu",
    "select_all": "Vælg alle",
    "arrange_groups": "Arrangere rækkefølgen af ​​tjenester",
    "arrange_groups_desc": "Bestem sekvensen af tjenester på tjenestemenuen",
    "arrange_services_quicktip": "For at omarrangere kalendere inden for en gruppe, gå til Grupper > Indstillinger > Omarranger kalendere",
    "enable_add_guest_desc": "Salli asiakkaiden sisällyttää lisää vieraita tehdessään varausta",
    "enable_multiple_service_desc": "Mahdollistaa asiakkaiden varata useita palveluita yhdelle varaukselle..",
    "enable_staff_selection_desc": "Salli asiakkaiden valita mieluisat henkilökunnan jäsenet varatessaan",
    "enable_add_guest": "Salli vieraiden lisäys",
    "enable_multiple_service": "Salli useiden palveluiden valinta",
    "enable_staff_selection": "Ota henkilöstön valinta käyttöön",
    "additional_options": "Lisäasetukset",
    "code_block_tooltip": "Tredjepartsskripter inde i HTML-blokken kan give kraftfuld funktionalitet, men de medfører også risici for privatlivets fred, sikkerhed, ydeevne og sideadfærd. Derfor anbefaler vi, at du gennemgår indholdet af dette skript, før du gemmer det i servicemenuen",
    "custom_code_placeholder": "Venligst indtast brugerdefineret kode her",
    "insert_custom_code": "Indsæt brugerdefineret kode",
    "no_service": "Palvelua ei löytynyt",
    "no_service_desc": "Tänne ei löytynyt palveluja. Luo palveluja ja lisää ne ryhmiin luodaksesi palveluvalikon",
    "learn_more": "Opi lisää",
    "no_service_selected": "Ei valittu palvelua",
    "create_service_calendars": "Luo palvelukalentereita",
    "alert_description": "Jotta luot palveluvalikon, sinun täytyy luoda palvelukalenterit ja lisätä ne ryhmiin",
    "dont_show_again": "Älä näytä tätä uudelleen",
    "service_name_required": "Palveluvalikon nimi vaaditaan",
    "slug_already_taken": "Tämä etana on jo varattu",
    "slug_required": "Etana on pakollinen",
    "please_enter_name": "Ole hyvä ja syötä nimi",
    "service_name_placeholder": "Päävalikko",
    "consent_label": "Hyväksyntäruutu",
    "consent_input_placeholder": "Lisää suostumusteksti",
    "consent_tooltip": "Aktivoi tämä vaihtoehto mahdollistaaksesi suostumuksen saannin yhteystietojen antamisen yhteydessä varaustilanteessa"
  },
  "widget_configuration": {
    "customise_calendar_widget": "Tilpas kalenderwidget",
    "works_with_neo_widget": "Fungerer kun med Neo-widget",
    "customise_widget_desc": "Tilpas widgetens udseende: primær farve, baggrundsfarve og knaptekst",
    "learn_more": "Lær mere",
    "primary_settings": "Primære indstillinger",
    "primary_settings_desc": "Konfigurer primærfarven, baggrundsfarven og andre widget-indstillinger for at tilpasse din kalender-widget",
    "primary_color": "Primærfarve",
    "primary_color_desc": "Definer farven for knapper, datoer, tidsintervaller og handlinger",
    "background_color": "Baggrundsfarve",
    "background_color_desc": "Indstil baggrundsfarven, der fungerer som baggrund for både dato-tidsvælgeren og formularen",
    "button_text": "Knap tekst",
    "button_text_desc": "Tilpas teksten, der vises på knappen ved booking af en aftale",
    "calendar_title": "Kalendertitel",
    "calendar_title_desc": "Navnet på din kalender",
    "calendar_description": "Kalenderbeskrivelse",
    "calendar_description_desc": "Den indtastede beskrivelse",
    "calendar_details": "Kalenderdetaljer",
    "calendar_details_desc": "Inkluderer information såsom varighed, dato og tidspunkt, gentagelsesdetaljer og tidszone",
    "reset_to_default": "Nulstil til standardindstillinger",
    "preview_widget": "Preview Widget - Forhåndsvisningsværktøj"
  },
  "eventNotification": {
    "list": {
      "booked": {
        "title": "Ajanvaraus ilmoitettu (Tila: Vahvistamaton)",
        "note": "Tämä ilmoitus lähetetään, kun tapaaminen on vahvistamaton",
        "subTitle": "Ilmoittaa, kun ajanvaraus on tehty vahvistamattomalla tilalla"
      },
      "confirmation": {
        "title": "Ajanvaraus vahvistettu-ilmoitus",
        "note": "Tämä ilmoitus lähetetään, kun tapaaminen on vahvistettu",
        "subTitle": "Ilmoittaa, kun tapaaminen on varmistettu onnistuneesti"
      },
      "cancellation": {
        "title": "Peruutusilmoitus",
        "note": "Tämä ilmoitus lähetetään, kun ajanvaraus on peruttu, merkitty nollaläsnäoloksi tai virheelliseksi",
        "subTitle": "Ilmoitukset, kun tapaaminen perutaan"
      },
      "reschedule": {
        "title": "Uudelleen aikataulutusilmoitus",
        "note": "Tämä ilmoitus lähetetään vahvistamattomista, vahvistetuista tai näytetyistä tapaamisista",
        "subTitle": "Ilmoittaa, kun ajanvarausta on siirretty uudelleen"
      },
      "reminder": {
        "title": "Muistutusilmoitus",
        "note": "Tämä ilmoitus lähetetään vahvistamattomille, vahvistetuille tai näytetyille tapaamisille",
        "subTitle": "Lähettää muistutuksen ennen tapaamista"
      },
      "followup": {
        "title": "Jälkitiedotus",
        "note": "Tämä ilmoitus lähetetään vahvistamattomista, vahvistetuista tai näytetyistä tapaamisista",
        "subTitle": "Lähettää jälkikäteisviestin tapaamisen jälkeen"
      }
    },
    "saveToastMsg": "Asetukset tallennettu!",
    "saveToastMsgDesc": "Muutoksesi on tallennettu onnistuneesti",
    "email": {
      "noneTemplate": "Ei yhtään",
      "notificationLabel": "Kuka tulisi saada tämä ilmoitus?",
      "sendButtonLabel": "Lähetä testisähköposti",
      "testEmailLabel": "Testisähköposti",
      "testEmailPlaceholder": "Syötä vastaanottajan sähköpostiosoite testausta varten",
      "body": "Sähköpostiviesti",
      "bodyPlaceholder": "Kirjoita viestisi tähän",
      "subject": "Aihe",
      "subjectPlaceholder": "Kirjoita sähköpostin aihe",
      "templateLabel": "Sähköpostipohja",
      "templatePlaceholder": "Valitse sähköpostipohja tai aloita alusta",
      "alertEmailAddress": "Hälytys sähköpostiosoite",
      "addMore": "Lisää lisää",
      "afterAppointmentEnds": "sen jälkeen kun tapaaminen päättyy",
      "beforeAppointmentStarts": "ennen tapaamisen alkua",
      "durationLabel": "Milloin haluaisit laukaista ilmoituksen?",
      "disableEmailNotification": "Poista sähköposti-ilmoitukset",
      "enableEmailNotification": "Ota sähköposti-ilmoitukset käyttöön",
      "enableSuccess": "Sähköposti-ilmoitukset käytössä!",
      "enableSuccessDesc": "Alat nyt vastaanottamaan tämän ilmoituksen",
      "disableSuccess": "Sähköposti-ilmoitukset on poistettu käytöstä!",
      "disableSuccessDesc": "Et enää saa tätä ilmoitusta"
    },
    "inApp": {
      "title": "Ilmoitusasetukset",
      "selectedUser": {
        "label": "Lähetä osoitteeseen",
        "helperText": "Tämä sisäinen ilmoitus lähetetään verkon ja mobiilisovellusten kautta",
        "placeholder": "Valitse käyttäjä(t)"
      },
      "enableSuccess": "Sovellusilmoitukset otettu käyttöön!",
      "enableSuccessDesc": "Nyt saat tämän ilmoituksen",
      "disableSuccess": "Sovelluksen ilmoitukset poistettu käytöstä!",
      "disableSuccessDesc": "Et enää vastaanota tätä ilmoitusta"
    }
  }
}