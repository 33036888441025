import Team from '@/models/team'
import { drop } from 'lodash'
import { ref } from 'vue'
import AppState from '../../states/app'

export class GroupList {
  filters = ref({
    searchText: '',
    page: 1,
  })

  limit = 10

  constructor() {
    //
  }

  get offet() {
    return (this.filters?.value.page - 1) * this.limit
  }

  get teamsList(): Team[] {
    return drop(this.filterdTeams, this.offet)
      .slice(0, this.limit)
      .map(team => {
        team.nosOfCalendars =
          AppState.globalCalendars?.calendars?.filter(
            calendar => team.id === calendar.providerId
          )?.length || 0

        return team
      })
  }

  get filterdTeams() {
    return this.allTeams.filter(team => {
      return (
        this.filters.value.searchText === '' ||
        team.name
          ?.toLowerCase()
          .includes(this.filters.value.searchText?.toLowerCase())
      )
    })
  }

  get allTeams(): Team[] {
    return AppState.globalTeams?.teams || []
  }

  get nosTotalPages() {
    return Math.ceil(this.filterdTeams.length / this.limit)
  }
}
