<script setup lang="ts">
import AccountPreferencesPane from '@/components/Settings/AccountPreferences.vue'
import UserPreferencesPane from '@/components/Settings/UserPreferences.vue'
import AppState from '@/states/app'
import { UIHeader, UITabPane, UITabs } from '@gohighlevel/ghl-ui'
import { computed, ref, watchEffect } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()
const tabType = ref(route.query?.tab || 'my-preference')

const isUserPartOfLocation = computed(() => {
  return !!AppState?.isUserPartOfLocation
})
watchEffect(() => {
  if (!isUserPartOfLocation.value) {
    tabType.value = 'account-preference'
  }
})
</script>

<template>
  <div class="p-8">
    <UIHeader
      id="calendar-preferences-settings-headers"
      :title="$t('settings.preferences.preferences')"
    />

    <UITabs v-model:value="tabType" type="line">
      <UITabPane
        v-if="isUserPartOfLocation"
        name="my-preference"
        :tab="$t('settings.preferences.my_preferences')"
        class="mt-6"
      >
        <UserPreferencesPane />
      </UITabPane>

      <UITabPane
        name="account-preference"
        :tab="$t('settings.preferences.account_preferences')"
        class="mt-6"
      >
        <AccountPreferencesPane />
      </UITabPane>
    </UITabs>
  </div>
</template>
