<template>
  <div>
    <!-- accordion -->
    <div
      :id="'expand-customization-accordion' + id"
      class="flex w-full cursor-pointer flex-row items-center justify-between gap-2 border px-5 transition-all"
      :class="{ 'border-b-[1px]': state.accordions[props.index].isOpen }"
    >
      <UICheckbox
        :id="'notification-receiver-checkbox-1' + id"
        v-model:checked="eventNotification.state.isActive"
        class="pb-5 pt-6 text-sm"
        @change="updateEmailNotification(eventNotification.state)"
      >
        {{ emailReceiverLabels[notification.receiverType] }}
      </UICheckbox>
      <div
        v-if="eventNotification.state.isActive"
        class="flex w-2/3 justify-end pb-5 pt-6"
        @click="handleAccordionClick"
      >
        <ChevronDownIcon
          v-if="!state.accordions[props.index].isOpen"
          class="h-5 w-5"
        />
        <ChevronUpIcon v-else class="h-5 w-5" />
      </div>
    </div>
    <!-- Notification configurator -->
    <div
      v-if="state.accordions[props.index].isOpen"
      class="flex w-full flex-col border border-t-0 bg-gray-25"
    >
      <div class="p-6">
        <!-- duration picker -->
        <div
          v-if="showDurationPicker"
          :id="'notification-duration-picker' + id"
          class="py-2"
        >
          <label class="font-medium text-gray-700">
            {{ t('eventNotification.email.durationLabel') }}
          </label>
          <div
            v-for="(d, i) in isFollowupNotifications
              ? eventNotification.state.afterTime
              : eventNotification.state.beforeTime"
            :key="'duration-picker-' + i"
            class="flex flex-row items-center space-x-3"
            :class="{ '!-mb-10 !-mt-5': i === 0, '!-my-10': i > 0 && i < 2 }"
          >
            <DurationPicker
              :id="'before-time-picker' + i + id"
              class="w-1/2"
              :value="d.timeOffset"
              :unit="d.unit === 'minutes' ? 'mins' : d.unit"
              @on-value-change="
                value => {
                  d.timeOffset = value.duration
                  d.unit =
                    value.timeUnit === 'mins' ? 'minutes' : value.timeUnit
                }
              "
            />

            <span class="mt-5 flex items-center">{{
              isFollowupNotifications
                ? t('eventNotification.email.afterAppointmentEnds')
                : t('eventNotification.email.beforeAppointmentStarts')
            }}</span>
            <UIButton
              v-if="i > 0"
              id="remove-availability-hour"
              class="m-x-4 mt-4 text-red-600"
              :text="true"
              @click="removeHour(i)"
            >
              <Trash02Icon class="w-4 items-center"></Trash02Icon>
            </UIButton>
          </div>
          <div
            v-if="shouldEnableAddMore"
            class="!mt-4 w-[15%] py-4"
            @click="addHour"
          >
            <a
              use="outline"
              class="mt-5 cursor-pointer bg-white text-blue-500"
              size="large"
            >
              <PlusIcon class="mr-1 w-5" />
              {{ t('eventNotification.email.addMore') }}
            </a>
          </div>
        </div>
        <!-- email template selector -->
        <UIFormItem :label="t('eventNotification.email.templateLabel')">
          <UISelect
            :id="'email-template-selector' + id"
            :options="templateOptions"
            :placeholder="t('eventNotification.email.templatePlaceholder')"
            :value="
              eventNotification.actionTemplate.value?.attributes.template_id
            "
            :render-option="optionDescriptionRenderer"
            filterable
            @update:value="onSelectTemplate"
          />
        </UIFormItem>
        <!-- Email template preview -->
        <EditEmailTemplate
          v-if="
            eventNotification.actionTemplate.value?.attributes
              .templatesource === 'email-builder'
          "
          class="mb-4"
          :template-id="
            eventNotification.actionTemplate.value.attributes.template_id
          "
          :created-at="
            eventNotification.actionTemplate.value.attributes.createdAt
          "
          :updated-at="
            eventNotification.actionTemplate.value.attributes.updatedAt
          "
          :preview-url="
            eventNotification.actionTemplate.value.attributes.previewUrl
          "
        />
        <!-- Email editor -->
        <div
          v-if="
            eventNotification.actionTemplate.value?.attributes?.template_id ===
            undefined
          "
        >
          <UIForm
            ref="emailFormRef"
            :rules="bodySubjectRules"
            :model="eventNotification.state"
          >
            <!-- email subject -->
            <UIFormItem
              :label="t('eventNotification.email.subject')"
              required="true"
              path="subject"
            >
              <UIInput
                id="email-notification-subject"
                v-model="eventNotification.state.subject"
                :rows="5"
                type="input"
                :placeholder="t('eventNotification.email.subjectPlaceholder')"
              />
            </UIFormItem>
            <!-- email body -->
            <UIFormItem
              id="notification-email-body"
              :label="t('eventNotification.email.body')"
              required="true"
              path="body"
            >
              <UIContentWrap
                :id="id"
                full-screen
                class="mb-2 rounded-md border border-gray-300"
              >
                <RichTextEditor
                  :id="'content-editor' + id"
                  :default-value="eventNotification.state.body"
                  :all-extensions="true"
                  :toolbar-options="toolbarOptions"
                  :placeholder="t('eventNotification.email.bodyPlaceholder')"
                  @editor-mounted="handleEditorMounted"
                  @update="updateText"
                />
              </UIContentWrap>
            </UIFormItem>
          </UIForm>
        </div>
        <UIForm v-if="eventNotification.state.receiverType === 'emails'">
          <UIFormItem
            id="calendar-alert-emails-2"
            :label="t('eventNotification.email.alertEmailAddress')"
            class="w-96"
          >
            <UIInput
              id="calendar-alert-emails-input"
              v-model="eventNotification.state.additionalEmailIds"
              :rows="4"
              type="textarea"
            />
          </UIFormItem>
        </UIForm>
        <!-- Test emails -->
        <UIForm
          id="test-email-form"
          ref="testEmailFormRef"
          :rules="rules"
          :model="eventNotification"
          class="flex flex-row items-center justify-between space-x-4"
        >
          <!-- <UIFormItem :label="'Test Email'" path="fromAddress">
            <UIInput
              :id="'from-address' + id"
              v-model="eventNotification.fromAddress.value"
              type="email"
              :rows="5"
              placeholder="From Address"
            />
          </UIFormItem> -->
          <UIFormItem
            id="to-address"
            :label="t('eventNotification.email.testEmailLabel')"
            path="toAddress"
            class="w-full"
          >
            <UIInput
              :id="'to-address' + id"
              v-model="eventNotification.toAddress.value"
              type="email"
              :rows="5"
              :placeholder="t('eventNotification.email.testEmailPlaceholder')"
            />
          </UIFormItem>
          <UIButton
            :id="'send-test-email' + id"
            type="default"
            :disabled="!isEnabledTestEmailButton || isSendingEmail"
            @click="sendTestEmail"
            >{{ t('eventNotification.email.sendButtonLabel') }}</UIButton
          >
        </UIForm>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, PropType, ref, watch } from 'vue'
import { NotificationConfig } from '@/types/notification'
import {
  UIContentWrap,
  UICheckbox,
  UIFormItem,
  UIInput,
  UISelect,
  UIButton,
  UIForm,
} from '@gohighlevel/ghl-ui'
import DurationPicker from '../DurationPicker.vue'
import {
  ChevronDownIcon,
  ChevronUpIcon,
  PlusIcon,
  Trash02Icon,
} from '@gohighlevel/ghl-icons/24/outline'
import { Editor } from '@tiptap/vue-3'
import { useI18n } from 'vue-i18n'
import RichTextEditor from '../../text-editor/index.vue'
import { NotificationType } from '@/types/notification'
import EditEmailTemplate from './EditEmailTemplate.vue'
import { getEmailTemplates, ISelectOption } from '@/states/app'
import Email from '@/models/email'
import { validateEmail } from '@/utils/validation'
import { optionDescriptionRenderer } from '@/utils/option-description-renderer'
import { EventNotificationService } from '@/class/services/EventNotificationService'
import EmailService from '@/class/services/EmailService'
import {
  EventNotification,
  eventNotificationState as state,
} from '@/states/EventNotificationState'
const { t } = useI18n()
const testEmailFormRef = ref()
const emailFormRef = ref()
const isSendingEmail = ref(false)

defineExpose({
  validateForm,
})

const emailReceiverLabels = {
  contact: 'Contact',
  guest: 'Guest',
  assignedUser: 'Assigned User',
  emails: 'Additional Emails',
}

const props = defineProps({
  notification: {
    type: Object as PropType<NotificationConfig>,
    required: true,
  },
  toolbarOptions: {
    type: Array,
    default: () => [
      'customVariablePicker',
      'styleBasic',
      'styleAdvanced',
      'emoji',
      'sourceCode',
      'textWrap',
    ],
  },
  showStatsNotification: {
    type: Boolean,
    default: false,
  },
  template: {
    type: Object as PropType<Email>,
    default: new Email(),
  },
  index: {
    type: Number,
    required: true,
  },
  isNotificationsEnabled: {
    type: Boolean,
    default: true,
  },
})

watch(
  () => props.isNotificationsEnabled,
  () => {
    if (!props.isNotificationsEnabled) {
      state.accordions[props.index].isOpen = false
    }
  }
)

const templateOptions = ref([] as ISelectOption[])

const eventNotification = new EventNotification(props.notification)
state[props.notification.receiverType] = eventNotification
onMounted(async () => {
  eventNotification.state.isActive = props.notification.isActive
  await init()
})

const showDurationPicker = computed(() => {
  return [NotificationType.REMINDER, NotificationType.FOLLOWUP].includes(
    props.notification.notificationType
  )
})
const isFollowupNotifications = computed(() => {
  return props.notification.notificationType === NotificationType.FOLLOWUP
})

const isEnabledTestEmailButton = computed(() => {
  return eventNotification.toAddress.value
})
const id = computed(() => {
  return `-${props.notification._id}-${props.notification.receiverType}`
})

const shouldEnableAddMore = computed(() => {
  return isFollowupNotifications.value
    ? eventNotification.state.afterTime &&
        eventNotification.state.afterTime?.length < 3
    : eventNotification.state.beforeTime &&
        eventNotification.state.beforeTime?.length < 3
})

const rules = {
  /*  fromAddress: {
    required: false,
    trigger: ['input', 'blur'],
    validator(_, value) {
      if (!validateEmail(value?.value)) {
        return new Error('The email is invalid')
      }
      return true
    },
  }, */
  toAddress: {
    required: false,
    trigger: ['input', 'blur'],
    validator(_, value) {
      if (!validateEmail(value?.value)) {
        return new Error('The email is invalid')
      }
      return true
    },
  },
}
const bodySubjectRules = {
  subject: {
    required: true,
    trigger: ['input', 'blur'],
    message: 'The subject is required',
  },
  body: {
    required: true,
    trigger: ['input', 'blur'],
    validator(rule, value) {
      if (!value || value === '<p style="margin:0px; line-height: 1"></p>') {
        return new Error('The body is required')
      }
      return true
    },
  },
}

async function init() {
  const templates = await getEmailTemplates()
  templateOptions.value = templates
  if (templateOptions.value[0]?.value !== 'none') {
    templateOptions.value.splice(0, 0, {
      label: t('eventNotification.email.noneTemplate'),
      value: 'none',
    })
  }
  onSelectTemplate(eventNotification.state.templateId)
  if (
    eventNotification.state.beforeTime?.length === 0 &&
    !isFollowupNotifications.value
  ) {
    eventNotification.state.beforeTime = [{ timeOffset: 10, unit: 'minutes' }]
  }
  if (
    eventNotification.state.afterTime?.length === 0 &&
    isFollowupNotifications.value
  ) {
    eventNotification.state.afterTime = [{ timeOffset: 1, unit: 'hours' }]
  }
}

function updateText(data: any, editor: any) {
  if (eventNotification.actionTemplate.value) {
    eventNotification.actionTemplate.value.attributes.html = data.HTML
  }
  eventNotification.state.body = data.HTML
}

function handleEditorMounted({ editor }: { editor: Editor }) {
  editor.commands.setContent(eventNotification.state.body || '', undefined, {
    preserveWhitespace: 'full',
  })
  editor.commands.resetAttributes('paragraph', 'HTMLAttributes')
}

function onSelectTemplate(value: string) {
  // find template in templateOptions
  // console.log('onSelectTemplate', value)
  const template = templateOptions.value.find(
    template => template.value === value
  )
  // console.log('template', template)
  if (template && eventNotification.actionTemplate.value) {
    eventNotification.actionTemplate.value.selectTemplate(template)
    eventNotification.state.templateId =
      eventNotification.actionTemplate.value.attributes.template_id || ''
  }
}

function addHour() {
  if (isFollowupNotifications.value) {
    eventNotification.state.afterTime?.push({
      timeOffset: 10,
      unit: 'minutes',
    })
  } else {
    eventNotification.state.beforeTime?.push({
      timeOffset: 10,
      unit: 'minutes',
    })
  }
}
function removeHour(i: number) {
  if (isFollowupNotifications.value) {
    eventNotification.state.afterTime?.splice(i, 1)
  } else {
    eventNotification.state.beforeTime?.splice(i, 1)
  }
}

async function updateEmailNotification(data: NotificationConfig) {
  const { _id, isActive } = data
  if (!_id) {
    return
  }
  handleAccordionOnCheck(isActive)
  try {
    await EventNotificationService.updateEventNotifications({ _id, isActive })
  } catch (error) {
    console.error('Error in update status', error)
  }
}
async function sendTestEmail() {
  try {
    await testEmailFormRef.value.getForm().validate()
  } catch (error) {
    console.error('Invalid emails')
    return
  }
  if (!eventNotification.actionTemplate.value) {
    return
  }

  try {
    isSendingEmail.value = true

    const { toAddress, fromAddress } = eventNotification
    const { subject } = eventNotification.state
    eventNotification.actionTemplate.value.attributes.from_email = ''
    eventNotification.actionTemplate.value.attributes.to = toAddress.value || ''
    eventNotification.actionTemplate.value.attributes.subject = subject
    const senderAddress = { from_email: fromAddress.value || '' }
    const attributes = {
      ...eventNotification.actionTemplate.value.attributes,
      ...{ testEmails: [toAddress.value || ''], attachments: [] },
    }
    const emailService = new EmailService(attributes, senderAddress)
    await emailService.sendTestEmail()
  } catch (error) {
    console.error('Error in sending test email', error)
  } finally {
    isSendingEmail.value = false
  }
}

function handleAccordionClick() {
  if (!props.isNotificationsEnabled || !eventNotification.state.isActive) {
    // do not open accordion if notification is disabled
    return
  }

  if (!state.accordions[props.index].isOpen) {
    openAccordion(props.index)
  } else {
    closeAccordion(props.index)
  }
}

async function handleAccordionOnCheck(isChecked: boolean) {
  state.accordions[props.index].isChecked = isChecked
  const accordion = state.accordions[props.index]
  if (accordion.isChecked) {
    openAccordion(props.index)
  } else {
    closeAccordion(props.index)
  }
}

function openAccordion(index) {
  if (index === null) {
    return
  }
  if (
    state.lastOpenedAccordion !== null &&
    state.lastOpenedAccordion !== index
  ) {
    state.accordions[state.lastOpenedAccordion].isOpen = false
  }
  state.accordions[index].isOpen = true
  state.lastOpenedAccordion = index
}
function closeAccordion(index) {
  if (index === null) {
    return
  }
  // Close the accordion
  state.accordions[index].isOpen = false
  // If it's the last opened accordion, reset the last opened index
  if (state.lastOpenedAccordion === index) {
    state.lastOpenedAccordion = null
  }
}
function validateForm() {
  return new Promise((resolve, reject) => {
    if (!eventNotification.actionTemplate.value?.attributes.template_id) {
      const validationPromise = emailFormRef.value?.getForm().validate()
      if (validationPromise) {
        validationPromise.then(resolve).catch(reject)
      } else {
        resolve(1)
      }
    } else {
      resolve(1)
    }
  })
}
</script>
