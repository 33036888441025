<script setup lang="ts">
import AppState, {
  fetchLocationDetails,
  setConnections
} from '@/states/app'
import {
  UITextLgMedium,
  UITextSmNormal
} from '@gohighlevel/ghl-ui'


import { computed, onMounted, ref } from 'vue'

import GmailLogo from '@/assets/gmail-logo.png'
import GoogleLogo from '@/assets/google-logo.svg'
import StripeLogo from '@/assets/stripe-logo.png'
import { ConnectionService } from '@/class/services/ConnectionService'
import ConnectAccountCard from './ConnectAccountCard.vue'
import OrganicBooking from './OrganicBooking.vue'

const isOrganicBookingEnabled = computed(() => AppState.isOrganicBookingEnabled)
onMounted(async () => {
  const { data: connectionsData } = await ConnectionService.getConnections(
    AppState.locationId
  )
  await fetchLocationDetails(AppState.locationId)
  setConnections(connectionsData)
})

const selectedAccount = ref('stripe')

const locationConnections = computed(() => {
  return AppState?.connections?.locationConnections
})

const isLoading = ref(false)
</script>
<template>
  <div class="max-w-5xl">
    <div class="flex bg-white p-6 py-7 shadow-lg">
      <div class="w-1/2 space-y-10 border-r pr-10">
        <div class="">
          <UITextLgMedium>
            {{ $t('settings.connections.connection_preferences') }}
          </UITextLgMedium>
          <UITextSmNormal class="text-gray-500">
            {{ $t('settings.connections.choose_accounts_to_connect') }}
          </UITextSmNormal>
        </div>

        <div class="space-y-6">
          <div class="space-y-4">
            <UITextLgMedium>
              {{ $t('settings.connections.payments') }}
            </UITextLgMedium>

            <div
              class="flex h-16 cursor-pointer items-center space-x-3 rounded-md border border-gray-200 px-4 hover:border-blue-600 hover:bg-blue-100"
              :class="{
                'border-blue-600 bg-blue-100': selectedAccount === 'stripe',
              }" @click="selectedAccount = 'stripe'">
              <img :src="StripeLogo" class="w-9" />
              <div class="text-sm font-medium text-gray-700">Stripe</div>
            </div>
          </div>

          <div class="space-y-4">
            <UITextLgMedium> Ads </UITextLgMedium>

            <div
              class="flex h-16 cursor-pointer items-center space-x-3 rounded-md border border-gray-200 px-4 hover:border-blue-600 hover:bg-blue-100"
              :class="{
                'border-blue-600 bg-blue-100': selectedAccount === 'lsa',
              }" @click="selectedAccount = 'lsa'">
              <img :src="GmailLogo" class="w-9" />
              <div class="text-sm font-medium text-gray-700">
                Local Service Ads
              </div>
            </div>
          </div>
          <div v-if="isOrganicBookingEnabled" class="space-y-4">
            <div class="flex flex-row justify-between">
              <div class="flex flex-row">
                <UITextLgMedium> Organic Booking Redirect </UITextLgMedium>
                <span class="hl_beta_badge">Beta</span>
              </div>
              <a href="https://doc.clickup.com/8631005/d/h/87cpx-121087/b8f5a98078c1453" target="_blank"
                rel="noopener noreferrer" class="py-1 text-sm font-bold text-blue-700">Learn more</a>
            </div>
            <div
              class="flex h-16 cursor-pointer items-center rounded-md border border-gray-200 hover:border-blue-600 hover:bg-blue-100"
              :class="{
                'border-blue-600 bg-blue-100':
                  selectedAccount === 'google-organic',
              }" @click="selectedAccount = 'google-organic'">
              <img :src="GoogleLogo" class="w-14" />
              <div class="text-sm font-medium text-gray-700">
                Google Organic Booking
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="px-6 py-7">
        <ConnectAccountCard v-if="selectedAccount === 'stripe'" :logo="StripeLogo" connection-name="Stripe" />

        <ConnectAccountCard v-else-if="selectedAccount === 'lsa'" :logo="GmailLogo"
          :connection-name="$t('settings.connections.lsa')" />
        <OrganicBooking v-else-if="selectedAccount === 'google-organic'" />
      </div>
    </div>
  </div>
</template>
<style scoped>
.hl_beta_badge {
  display: block;
  padding: 4px 10px;
  line-height: 15px;
  font-size: 12px;
  margin: 3px 10px 0 5px;
  border-radius: 16px;
  background: #eff4ff;
  color: #004eeb;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
</style>
