import config from '@/config'
import { requests } from './BaseService'

export const CalendarGroupService = {
  getAll: (locationId: string) =>
    requests().get(`${config.calendarApiUrl}/calendars/groups`, {
      params: {
        locationId,
      },
    }),
  create: (body: any) =>
    requests().post(`${config.calendarApiUrl}/calendars/groups`, body),
  edit: (groupId: string, body: any) =>
    requests().put(
      `${config.calendarApiUrl}/calendars/groups/${groupId}`,
      body
    ),
  validateSlug: (locationId: string, slug: string) =>
    requests().post(`${config.calendarApiUrl}/calendars/groups/validate-slug`, {
      locationId,
      slug,
    }),
  updateStatus: (groupId: string, isActive: boolean) =>
    requests().put(
      `${config.calendarApiUrl}/calendars/groups/${groupId}/status`,
      {
        isActive,
      }
    ),
  delete: (id: string, deleteCalendar?: boolean) =>
    requests().delete(
      `${config.calendarApiUrl}/calendars/groups/${id}?deleteCalendar=${deleteCalendar}`
    ),
}
