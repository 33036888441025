import config from '@/config'
import AppState from '@/states/app'
import axios, { AxiosRequestConfig } from 'axios'

const getHeader = () => {
  return {
    channel: 'APP',
    source: 'WEB_USER',
    version: '2021-07-28',
  }
}

export const requests = (host?: string, headers?: Record<string, string>) => {
  const baseUrl = host || config.restApiUrl
  const axiosInstance = axios.create({
    baseURL: baseUrl,
    headers: { ...getHeader(), ...headers },
  })

  axiosInstance.interceptors.request.use(
    async (requestConfig: AxiosRequestConfig) => {
      try {
        requestConfig.headers['token-id'] = AppState.tokenId
      } catch (e) { }
      return requestConfig
    }
  )

  axiosInstance.interceptors.response.use(
    function handleSuccess(res) {
      return res
    },
    async function handleError(error) {
      if (AppState.depth > 10) {
        AppState.depth = 0
        return Promise.reject(error)
      }

      if (
        error.config &&
        error.response &&
        error.response.status === 401 &&
        error.response.config?.url?.includes('contacts') &&
        error.response.data?.message === 'Permission denied.'
      ) {
        return Promise.reject(error)
      }

      if (error.config && error.response && error.response.status === 401) {
        if (AppState.parentApp) {
          AppState.parentApp.then((parent: any) => {
            parent.emit('getRefreshedTokenId')
          })
        }

        await wait(2 ** AppState.depth * 10) // exponential backoff time. -> wait for token
        AppState.depth++
        return axios.request(error.config)
      }

      return Promise.reject(error)
    }
  )
  return axiosInstance
}

const wait = (ms: number) => new Promise(res => setTimeout(res, ms))
