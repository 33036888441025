<script setup lang="ts">
import AppState from '@/states/app'
import {
  UIForm,
  UIFormItem,
  UIInput,
  UIInputNumber,
  UIModal,
  UIModalContent,
  UIModalFooter,
  UIModalHeader,
  UISelect,
  useNotification
} from '@gohighlevel/ghl-ui'
import pickBy from 'lodash/pickBy'
import uniq from 'lodash/uniq'
import { computed, ref, watch } from 'vue'
import { ResourceList } from '../../class/pages/ResourceList'
import { CalendarResourceService } from '../../class/services/CalendarResourceService'
import { CalendarType } from '../../models/calendar'
import { CALENDAR_RESOURCE_TYPES } from '../../models/calendar_resource'
import { getTooltip } from '../../utils/generalFunctions'

const emit = defineEmits([
  'closeModal',
  'createdRecord',
  'uploadCSV',
  'update:show',
])
const roomList = new ResourceList(CALENDAR_RESOURCE_TYPES.ROOMS)
const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  editPayload: {
    type: Object,
    default: null,
  },
})

watch(
  () => props.show,
  newVal => {
    saving.value = false
    if (newVal) {
      formValue.value = {
        name: props.editPayload?.name,
        description: props.editPayload?.description,
        capacity: props.editPayload?.capacity,
        resourceType: CALENDAR_RESOURCE_TYPES.ROOMS,
      }
      selectedCalendars.value = props.editPayload?.calendarIds.map(e => e).filter(e => !roomList?.isCalendarDeleted(e)) || []
    }
  }
)

watch(
  () => props.editPayload,
  newVal => {
    selectedCalendars.value = newVal?.calendarIds.map(e => e).filter(e => !roomList?.isCalendarDeleted(e)) || []
  }
)

const rules = {
  name: {
    required: true,
    message: 'Room name is required',
    trigger: ['input', 'blur'],
  },
  capacity: {
    required: true,
    validator(_, value) {
      if (!value && value != 0) {
        return new Error(`Capacity is mandatory`)
      }
      const capacity = parseInt(value)
      if (capacity === 0) {
        return new Error(`Capacity cannot be zero`)
      }
      if (isNaN(capacity)) {
        throw new Error(`Capacity should be be a number`)
      }
      if (capacity < 0) {
        return new Error(`Capacity cannot be negative`)
      }
      if (capacity && parseFloat(value) !== capacity) {
        return new Error(`Capacity should be a whole number`)
      }
      return true
    },
    trigger: ['input', 'blur'],
  },
}

const formValue = ref({
  name: '',
  description: '',
  capacity: 1,
  resourceType: CALENDAR_RESOURCE_TYPES.ROOMS,
})

const saving = ref(false)
const selectedCalendars = ref([])
const formRef = ref()

const calendars = computed(() => {
  return (AppState.globalCalendars as any)?.calendars
    ?.filter(calendar => calendar.calendarType === CalendarType.SERVICE && !calendar.deleted)
    .map((cal) => {
      return {
        value: cal.id,
        label: cal.name
      }
    })
})

function sanitizeObject(obj) {
  return pickBy(
    obj,
    value => value !== null && value !== undefined && value !== ''
  )
}

async function createRoom(values) {
  try {
    saving.value = true
    const room = sanitizeObject(values)
    if (props.editPayload) {
      const toDeleteCalendarIds = props.editPayload?.calendarIds?.filter(e => {
        const _selectedCalendars: any =
          selectedCalendars.value?.map(i => i) || []
        return !_selectedCalendars.includes(e)
      })
      const roomsObj = { ...room }
      if (selectedCalendars?.value?.length) {
        const existingCalendarIds = props.editPayload?.calendarIds || []
        roomsObj.calendarIds = selectedCalendars?.value?.filter(calendarId => {
          return !existingCalendarIds?.includes(calendarId)
        })
      }
      if (toDeleteCalendarIds?.length) {
        roomsObj.toDeleteCalendarIds = toDeleteCalendarIds
      }
      roomsObj.description = roomsObj?.description || ''
      await CalendarResourceService.update(
        props.editPayload._id,
        roomsObj,
        CALENDAR_RESOURCE_TYPES.ROOMS
      )
    } else {
      const roomsObj: any = { ...room, locationId: AppState.locationId }
      if (selectedCalendars?.value?.length) {
        roomsObj.calendarIds = selectedCalendars?.value?.map(e => e)
      }
      await CalendarResourceService.create(
        roomsObj,
        CALENDAR_RESOURCE_TYPES.ROOMS
      )
    }
    saving.value = false
    emit('createdRecord')
  } catch (err) {
    saving.value = false
    useNotification().error({
      duration: 1.5 * 1000,
      content: 'Something went wrong. Please try again.',
    })
  }
}

function handleValidateClick(e) {
  formRef.value
    .getForm()
    .validate()
    .then(async value => {
      await createRoom(formValue.value)
      Object.keys(formValue.value).forEach(key => {
        formValue.value[key] = ''
      })
    })
    .catch(e => console.error('error', e))
}

function selectCalendar(checked, calendarId) {
  if (checked) {
    const _calendarIds: any = selectedCalendars.value.map(e => e)
    _calendarIds.push(calendarId)
    selectedCalendars.value = uniq(_calendarIds)
  } else {
    const _calendarIds = selectedCalendars.value
      .map(e => e)
      .filter(e => e !== calendarId)
    selectedCalendars.value = uniq(_calendarIds)
  }
}

function isCalendarSelected(calendarId) {
  return (selectedCalendars.value?.map(e => e) as any)?.includes(calendarId)
}

</script>
<template>
  <UIModal :show="show" :width="585" @update:show="val => $emit('update:show', val)">
    <template #header>
      <UIModalHeader id="calendarGroupCreateModalHeader" type="success" :title="$t('settings.calendar.add_room')"
        @close="$emit('closeModal')" />
    </template>
    <UIForm id="calendarGroupCreateForm" ref="formRef" :model="formValue" :rules="rules"
      class="h-full w-full max-w-7xl sm:max-w-7xl">
      <p class="m-0 p-0 text-sm">
        {{ $t('settings.calendar.rooms_modal_description') }}
      </p>

      <UIModalContent>
        <p class="w-full border border-solid border-gray-200"></p>

        <div class="mt-6 grid">
          <div class="col-span-2">
            <UIFormItem :label="$t('settings.calendar.rooms_name')" path="name">
              <UIInput id="calendarProviderName" v-model="formValue.name"
                :placeholder="$t('settings.calendar.rooms_name')" />
            </UIFormItem>
          </div>
          <div class="col-span-2">
            <UIFormItem :label="$t('settings.calendar.rooms_description')" path="description">
              <UIInput id="calendarProviderDescription" v-model="formValue.description" type="textarea"
                :placeholder="$t('settings.calendar.rooms_description')" />
            </UIFormItem>
          </div>
          <div class="col-span-2">
            <UIFormItem :label="$t('settings.calendar.rooms_capacity')" path="capacity"
              :tooltip="getTooltip(`Specify maximum number of appointments that can be scheduled in the room simultaneously`)">
              <UIInputNumber id="calendarProviderCapacity" v-model="formValue.capacity"
                :placeholder="$t('settings.calendar.rooms_capacity')" />
            </UIFormItem>
          </div>
          <div class="col-span-2">
            <UIFormItem :label="$t('settings.calendar.select_calendar_rooms_title')">
              <div class="flex-col flex  w-full">
                <div class="mb-3 text-xs text-gray-500">
                  {{ $t('settings.calendar.select_service_calendar_description_rooms') }}
                </div>
                <UISelect id="roomCalendars" v-model:value="selectedCalendars" :multiple="true" :filterable="true"
                  :options="calendars" />
              </div>
            </UIFormItem>
          </div>
        </div>
      </UIModalContent>
    </UIForm>
    <template #footer>
      <UIModalFooter id="affiliateCreateModalFooter" :positive-text="props.editPayload
        ? $t('settings.calendar.save')
        : $t('settings.calendar.create')" :negative-text="$t('settings.calendar.cancel')" :loading="saving"
        :disabled="saving" @positive-click="handleValidateClick" @negative-click="$emit('closeModal')">
      </UIModalFooter>
    </template>
  </UIModal>
</template>
