<template>
  <label class="font-semibold text-gray-700">Embed Code</label>
  <div class="py-2 text-sm text-gray-500">
    Place this code in your HTML where you want your calendar widget to appear.
  </div>
  <div class="code-block-font my-4 rounded-lg bg-blue-50 p-6 text-green-700">
    {{ embedCodeForCalendar }}
  </div>
  <UIButton
    id="copyEmbedCodeButton"
    class="float-right mt-4 w-1/5"
    type="primary"
    size="small"
    @click="copyScript"
    ><Copy01Icon class="mr-2 h-4 w-4" /> Copy
  </UIButton>
</template>

<script setup lang="ts">
import { UIButton, useNotification } from '@gohighlevel/ghl-ui'
import { Copy01Icon } from '@gohighlevel/ghl-icons/24/outline'
import { Prop, computed } from 'vue'
import { copyToClipboard } from '../../../utils/generalFunctions'
import Calendar, { WidgetType } from '../../../models/calendar'
import AppState from '../../../states/app'
const notification = useNotification()

const props = defineProps({
  calendar: {} as Prop<Calendar>,
})

const embedCodeForCalendar = computed(() => {
  const embedScript =
    props.calendar?.widgetType === WidgetType.CLASSIC
      ? 'embed.js'
      : 'form_embed.js'

  const uniqueId = `${
    props.calendar?.providerId ? props.calendar?.providerId : props.calendar?.id
  }_${new Date().getTime()}`

  const calendarId =
    props.calendar?.widgetType === WidgetType.CLASSIC
      ? 'msgsndr-calendar'
      : uniqueId
  return `<iframe src="${AppState?.baseUrl}/widget/booking/${props.calendar?.id}" style="width: 100%;border:none;overflow: hidden;" scrolling="no" id="${calendarId}"><\/iframe><br><script src="${AppState.embedUrl}/${embedScript}" type="text/javascript"><\/script>`
})

const copyScript = () => {
  copyToClipboard(embedCodeForCalendar.value)
  notification.info({
    content: 'Copied to clipboard',
    duration: 1 * 1000,
  })
}
</script>

<style scoped>
.code-block-font {
  font-family: 'Roboto Mono';
}
</style>
