import User from '@/models/user'
import AppState from '@/states/app'
import axios from 'axios'
import moment from 'moment'
import { reactive } from 'vue'
import MyAvailabilityDay from './MyAvailabilityDay'

export class MyAvailability {
  static OFFICE_DAYS = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]
  isReady = false
  isProcessing = false
  selectedUserTimezone = ''
  dayWiseOpenMap = {
    Sunday: false,
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
  }

  availableDays = reactive([] as MyAvailabilityDay[])
  basicDay = new MyAvailabilityDay('Sunday')

  constructor(selectedUserTimezone: string) {
    if (selectedUserTimezone) {
      this.selectedUserTimezone = selectedUserTimezone
    }
  }

  setOpenHoursDataFromDatabase(
    locationWiseOpenHours,
    setNonAvailableDays = true
  ) {
    const days = [0, 1, 2, 3, 4, 5, 6]
    function fillMissingDays(arr) {
      const result = [] as any[]
      days.forEach(value => {
        const exists = arr.some(item => item?.daysOfTheWeek[0] === value) // TODO : remvove null check
        if (setNonAvailableDays && !exists) {
          result.push({
            daysOfTheWeek: [value],
            hours: [],
          })
        }
      })

      return [...locationWiseOpenHours, ...result]
    }

    const newLocationWiseOpenHours = fillMissingDays(locationWiseOpenHours)

    this.availableDays.splice(0)

    if (newLocationWiseOpenHours) {
      newLocationWiseOpenHours.forEach(dayOpenHour => {
        if (!dayOpenHour?.daysOfTheWeek || !dayOpenHour?.daysOfTheWeek.length) {
          return
        }

        const dayOfWeek = dayOpenHour?.daysOfTheWeek[0]
        this.dayWiseOpenMap[MyAvailability.OFFICE_DAYS[dayOfWeek]] = true
        this.availableDays.push(
          new MyAvailabilityDay(
            MyAvailability.OFFICE_DAYS[dayOfWeek],
            dayOpenHour.hours?.map(x => {
              return {
                startTime: `${x.openHour}:${x.openMinute}`,
                endTime: `${x.closeHour}:${x.closeMinute}`,
              }
            })
          )
        )
      })
    }

    this.isReady = true
  }

  setCustomAvailabilityDataFromDatabase(customAvailability) {
    this.availableDays.splice(0)

    if (customAvailability) {
      customAvailability.forEach(dayOpenHour => {
        this.availableDays.push(
          new MyAvailabilityDay(
            moment(dayOpenHour.date).tz('UTC').format('MMMM D, YYYY'),
            dayOpenHour.hours?.map(x => {
              return {
                startTime: `${x.openHour}:${x.openMinute}`,
                endTime: `${x.closeHour}:${x.closeMinute}`,
              }
            }),
            dayOpenHour._id
          )
        )
      })
    }

    this.isReady = true
  }

  getAvailableDaysCount() {
    return this.availableDays.filter(item => item.hours.length !== 0).length
  }

  addAvailabilityDay(day) {
    if (this.availableDays.find(item => item.day === day)) {
      return
    }
    this.availableDays.push(new MyAvailabilityDay(day))
    this.availableDays.sort((a, b) => a.dayOfWeek - b.dayOfWeek)
  }

  removeAvailabilityDay(day) {
    const index = this.availableDays.findIndex(x => x.day === day)
    if (index !== -1) {
      this.availableDays.splice(index, 1)
    }
  }
  updateAvailabilityDay(day, checked) {
    const dayIndex = this.availableDays.findIndex(x => x.day === day.day)
    if (dayIndex !== -1) {
      if (checked) {
        this.availableDays[
          dayIndex
        ].addNewAvailabilityHourWithStartTimeAndEndTime('8:0', '17:0')
      } else {
        this.availableDays[dayIndex].removeAllAvailabilityHour()
      }
    }
  }
  copyAvailabilieToAllDays(availabilityDay: MyAvailabilityDay) {
    for (let index = 0; index < this.availableDays.length; index++) {
      this.availableDays[index].hours = JSON.parse(
        JSON.stringify(availabilityDay.hours)
      )
    }
  }

  copyAvailabilieToOneDay(dayName: string) {
    const dayIndex = this.availableDays.findIndex(day => day.day === dayName)
    if (dayIndex !== -1) {
      this.availableDays[dayIndex].hours = JSON.parse(
        JSON.stringify(this.availableDays[dayIndex].hours)
      )
    }
  }

  copyFromOneToMany(dayName: string, targetDays: string[]) {
    const sourceDayIndex = this.availableDays.findIndex(
      day => day.day === dayName
    )
    if (sourceDayIndex !== -1) {
      targetDays.forEach(targetDayName => {
        const targetDayIndex = this.availableDays.findIndex(
          day => day.day === targetDayName
        )
        if (targetDayIndex !== -1) {
          this.availableDays[targetDayIndex].hours = JSON.parse(
            JSON.stringify(this.availableDays[sourceDayIndex].hours)
          )
        }
      })
    }
  }

  get hasAnyInvalidHours() {
    return this.availableDays.findIndex(x => x.hasAnyErrorHours) > -1
  }
  get hasDuplicateSlot() {
    return (
      this.availableDays.findIndex(x => x.getOverlappingHours().length > 0) > -1
    )
  }
  async saveMyAvailability() {
    if (!this.isReady) {
      return
    }

    this.isProcessing = true

    const locationId = AppState.locationId
    const loggedInUserDetail = AppState?.loggedInUserDetail as User

    let openHours = this.availableDays.map(day =>
      day.serializeAsPerDatabaseFormat()
    )

    const locationWiseTimezone = {
      ...loggedInUserDetail.locationWiseTimezone,
      [AppState.locationId]: this.selectedUserTimezone,
    }

    const otherLocationKeys = Object.keys(
      loggedInUserDetail.locationWiseOpenHours
    )

    const serializedOtherLocationWiseOpenHours = {}

    otherLocationKeys.forEach(key => {
      serializedOtherLocationWiseOpenHours[key] =
        loggedInUserDetail.locationWiseOpenHours[key]?.map(day => {
          return {
            days_of_the_week: day?.daysOfTheWeek,
            hours: day.hours?.map(x => {
              return {
                open_hour: x.openHour,
                open_minute: x.openMinute,
                close_hour: x.closeHour,
                close_minute: x.closeMinute,
              }
            }),
          }
        })
    })

    openHours = openHours.filter(item => item !== null && item !== undefined)

    const locationWiseOpenHours = {
      ...serializedOtherLocationWiseOpenHours,
      [locationId]: openHours,
    }

    const { data } = await axios.put(`/users/${AppState.user?.id}`, {
      locationWiseOpenHours,
      locationWiseTimezone,
    })

    this.isProcessing = false
  }
}
