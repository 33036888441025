<template>
  <GeneralConfirmation v-model:show="showDeactiveConfirmationModal" :disabled-confirm="isProcessing"
    header-title="Are you sure?" @onConfirm="confirmDisconnect" @onModalClose="closeModal">
    <template #message>
      <p class="text-sm leading-5 text-gray-500">
        {{ $t('settings.connections.connection_remove_confirmation') }}
      </p>
    </template>
  </GeneralConfirmation>

  <UISpin v-if="isProcessing" :show="true"> </UISpin>

  <div v-else class="">
    <div v-if="accountName" class="flex">
      <div class="flex w-full justify-between">
        <div class="flex items-center space-x-4">
          <div class="font-semibold">
            {{ accountName }}
          </div>
          <div class="flex items-center space-x-2 rounded-2xl bg-green-100 px-2">
            <div class="h-1.5 w-1.5 rounded-full bg-green-600"></div>
            <div class="text-sm font-medium text-green-600">
              {{ $t('settings.connections.active') }}
            </div>
          </div>
        </div>

        <div class="cursor-pointer rounded-lg border-2 border-gray-200 px-2 py-2"
          @click="() => handleRemoveIntegration()">
          <Trash01Icon class="h-4 w-4" />
        </div>
      </div>
    </div>

    <div v-else class="flex w-96 items-center justify-between">
      <div class="flex items-center space-x-3">
        <img :src="logo" class="w-9" />
        <div class="">
          <div class="font-medium">{{ connectionName }}</div>
          <div class="text-sm text-gray-400">
            {{ accountName }}
          </div>
        </div>
      </div>

      <button class="text-sm font-medium uppercase text-blue-500" @click="connect">
        {{ $t('settings.connections.connect') }}
      </button>
    </div>
  </div>

  <UIModal v-model:show="iCloudModal.open" :width="400">
    <template #header>
      <UIModalHeader id="icloud-modal-header" type="primary" :title="$t('settings.connections.icloud.title')"
        :description="$t('settings.connections.icloud.description')" @close="iCloudModal.open = false">
      </UIModalHeader>
      <a href="https://doc.clickup.com/8631005/d/h/87cpx-120807/f87684434b9b4bc" target="_blank" rel="noopener noreferrer"
        class="font-normal text-sm text-blue-700">Learn more</a>
    </template>
    <UIModalContent>
      <UIForm :model="iCloudFormValue" :rules="iCloudFormRules" ref="iCloudFormRef" id="icloud-form">
        <UIFormItem :label="$t('settings.connections.icloud.email')" path="email">
          <UIInput v-model="iCloudFormValue.email" placeholder="johndoe@icloud.com" id="icloud-email" />
        </UIFormItem>
        <UIFormItem :label="$t('settings.connections.icloud.password')" path="password">
          <UIInput type="password" v-model="iCloudFormValue.password" placeholder="password" id="icloud-password"
            show-password-on="click" />
        </UIFormItem>
      </UIForm>
    </UIModalContent>
    <template #footer>
      <UIModalFooter id="icloud-modal-footer" :positive-text="$t('settings.connections.icloud.connect')"
        :negative-text="$t('settings.connections.icloud.cancel')" type="primary" :loading="iCloudModal.loading"
        @negative-click="closeICloudModal" @positive-click="connectICloud">
      </UIModalFooter>
    </template>
  </UIModal>
</template>

<script setup lang="ts">
import { ConnectionService } from '@/class/services/ConnectionService'
import GeneralConfirmation from '@/components/common/GeneralConfirmation.vue'
import config from '@/config'
import AppState, {
  setConnectedCalendars,
  setConnections,
  setUserCalendars,
} from '@/states/app'
import { validateEmail } from '@/utils/validation'
import { Trash01Icon } from '@gohighlevel/ghl-icons/24/outline'
import { ref } from 'vue'

import {
  UIForm,
  UIFormItem,
  UIInput,
  UIModal,
  UIModalContent,
  UIModalFooter,
  UIModalHeader,
  UISpin,
  useNotification,
} from '@gohighlevel/ghl-ui'

const props = defineProps({
  logo: String,
  connectionName: String,
  accountName: String,
  oauthId: String,
})

const notification = useNotification()

const showDeactiveConfirmationModal = ref(false)

const iCloudModal = ref<{
  open: boolean;
  loading: boolean;
}>({
  open: false,
  loading: false
})

const iCloudFormValue = ref<{
  email: string;
  password: string;
}>({
  email: '',
  password: ''
})

const iCloudFormRules = {
  email: {
    required: true,
    validator(_, value) {
      if (!value) {
        return new Error('A valid iCloud email address is required')
      } else if (value && !validateEmail(value)) {
        return new Error('Please enter a valid iCloud email address')
      }
      return true
    },
    trigger: ['input', 'blur'],
  },
  password: {
    required: true,
    message: 'Please enter iCloud password',
    trigger: ['input', 'blur'],
  }
}

const isProcessing = ref(false)
const oauthIdToBeRemoved = ref('')

const closeModal = () => {
  showDeactiveConfirmationModal.value = false
}

const handleRemoveIntegration = () => {
  oauthIdToBeRemoved.value = props.oauthId || ''
  showDeactiveConfirmationModal.value = true
}

const refreshConnections = async () => {
  const { data } = await ConnectionService.getConnections(AppState.locationId)
  setConnections(data)

  const { data: connectedCalendasData } =
    await ConnectionService.getConnectedCalendars(AppState.locationId)

  setConnectedCalendars(connectedCalendasData.thirdPartyCalendars)
  setUserCalendars(connectedCalendasData.userCalendars)
  isProcessing.value = false
  closeModal()
}

const confirmDisconnect = async () => {
  isProcessing.value = true
  await ConnectionService.removeIntegration(
    AppState.locationId,
    oauthIdToBeRemoved.value
  )
  refreshConnections()
}

const connect = () => {
  let connectionUrl = ''

  if (props.connectionName === 'Outlook') {
    connectionUrl = `${config.baseUrl}/api/outlook/start_oauth?location_id=${AppState.locationId}&user_id=${AppState.user?.id}`
  } else if (props.connectionName === 'iCloud') {
    resetICloudForm()
    iCloudModal.value.open = true;
  } else if (props.connectionName === 'Zoom') {
    connectionUrl = `${config.baseUrl}/api/zoom/start_oauth?location_id=${AppState.locationId}&user_id=${AppState.user?.id}`
  } else if (
    props.connectionName === 'Local Service Ads' ||
    props.connectionName === 'Google'
  ) {
    AppState.parentApp?.then(parent => {
      parent.emit('go-to-integrations-page', {
        providerId: undefined,
      })
    })

    return
  } else if (props.connectionName === 'Stripe') {
    AppState.parentApp?.then(parent => {
      parent.emit('go-to-payments-integrations-page', {
        providerId: undefined,
      })
    })

    return
  }

  if (!iCloudModal.value.open) {
    const win = window.open(
      connectionUrl,
      'MyWindow',
      'toolbar=no, menubar=no,scrollbars=no,resizable=no,location=no,directories=no,status=no'
    )
    const timer = setInterval(async () => {
      if (win?.closed) {
        isProcessing.value = true
        clearInterval(timer)
        refreshConnections()
      }
    }, 1000)
  }

}

const resetICloudForm = () => {
  iCloudFormValue.value.email = ''
  iCloudFormValue.value.password = ''
}

const closeICloudModal = () => {
  iCloudModal.value.open = false
}

const connectICloud = async () => {
  try {
    iCloudModal.value.loading = true
    await ConnectionService.integrateICloud({
      locationId: AppState.locationId,
      userId: AppState.user?.id as string,
      email: iCloudFormValue.value.email,
      password: iCloudFormValue.value.password,
    })
    refreshConnections();
    iCloudModal.value.open = false
  } catch (error: any) {
    console.error("Error in iCloud integration", error);
    notification.error({
      title: "Error while integrating iCloud account",
      description:
        error.response.data.message || error.message || "Error while integrating iCloud account",
      duration: 10000,
    });
  } finally {
    iCloudModal.value.loading = false
  }
};
</script>
