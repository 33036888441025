{
  "settings": {
    "calendar": {
      "calendar_settings": "Definições do Calendário",
      "group": "Grupo",
      "manage_your_calendars_and_groups": "Gerir os seus calendários e grupos",
      "groups": "Grupos",
      "create_group": "Criar Grupo",
      "create_calendar": "Criar Calendário",
      "calendar_name": "Nome do Calendário",
      "group_duration": "Duração do Grupo",
      "calendar_type": "Tipo de Calendário",
      "action": "Acção",
      "round_robin": "Round robin",
      "event": "Evento",
      "group_name": "Nome do Grupo",
      "calendars": "calendários",
      "date_updated": "Data Actualizada",
      "status": "Estado",
      "action_dropdown": "Acção dropdown",
      "edit": "Editar",
      "duplicate": "Duplicar",
      "copy_embed_code": "Copiar código embebido",
      "copy_permanent_link": "Copiar link permanente",
      "copy_new_slug_link": "Copiar o link de agendamento",
      "copy_scheduling_link": "Copiar o link de agendamento (Legacy Deprecated)",
      "deactivate_calendar": "Desativar Calendário",
      "move_to_group": "Mover para o Grupo",
      "delete_calendar": "Eliminar Calendário",
      "delete_group": "Eliminar",
      "delete_this_group": "Eliminar este Grupo",
      "activate_calendar": "Activar Calendário",
      "duration": "Duração (min.)",
      "all": "Todos",
      "draft": "Rascunho",
      "active": "Activo",
      "rearrange_calendars": "Organizar Calendários",
      "deactivate_all_calendars_in_group": "Desativar todos os calendários no grupo",
      "activate_all_calendars_in_group": "Activar todos os calendários no grupo",
      "no_groups_found": "A sua pesquisa não coincidiu com nenhum grupo de calendários",
      "no_groups_in_location": "Comece a usar o grupo criando um novo grupo de calendário",
      "no_calendars_found": "Comece a usar o calendário criando um novo calendário",
      "no_calendars_found_search": "A sua pesquisa não coincidiu com nenhum calendário",
      "delete_calendar_message": "Está Prestes a eliminar o calendário, quando carregar eliminar também vai eliminar os seus agendamentos",
      "delete_calendar_message_title": "Eliminar este Calendário?",
      "no_calendars_found_title": "Não foram encontrados calendários",
      "no_groups_in_location_title": "Não foram encontrados grupos",
      "delete_group_confirmation_title": "eliminar este grupo?",
      "delete_group_confirmation": "Tem a certeza de que deseja eliminar o grupo selecionado?",
      "delete_group_confirmation_checkbox_title": "Eliminar calendários e compromissos associados",
      "delete_group_confirmation_checkbox_description": "Ao selecionar esta opção, também serão eliminados os calendários deste grupo, juntamente com todas as marcações nesses calendários",
      "group_status_message_title": "{status} este grupo?",
      "calendar_status_message_title": "{status} este calendário?",
      "calendar_status_message": "Está prestes a {status} calendário",
      "group_status_message": "Está prestes a {status} grupo",
      "cancel": "Cancelar",
      "new_service_menu": "Novo Menu de Serviço",
      "service_menus": "Menu de Serviço",
      "manage_your_calendars_groups_and_service_menus": "Gerencie seus calendários, grupos e menu de serviços",
      "deactivate_service_menu": "Desativar o Menu de Serviço",
      "delete_service_menu": "Apague este Menu de Serviço",
      "delete_service_menu_confirmation": "Tem a certeza de que deseja apagar este menu de serviços?",
      "service_menu_status_message_title": "{status} este menu de serviço?",
      "service_menu_status_message": "Está prestes a {status} o Menu de Serviço",
      "no_service_menus_in_location_title": "Crie o seu menu de serviços personalizado",
      "add_new_service_menu": "Adicionar Novo Menu de Serviços",
      "no_service_menus_in_location": "Comece por criar um novo menu de serviços e adicionar Grupos a ele",
      "no_service_menu_found": "Nenhum menu de serviços encontrado",
      "no_service_menu_found_desc": "A sua pesquisa não correspondeu a nenhum menu de serviços",
      "rooms": "Alojamento",
      "equipments": "Equipamentos",
      "share_otl": "Partilhe a sua disponibilidade sempre com um link único que expira após uma reserva, garantindo acesso controlado",
      "generate_link": "Gerar novo link",
      "share_schedule_link": "O link de agendamento é determinado pelo slug. Ajuste o slug, e o link de agendamento adapta-se automaticamente à modificação",
      "share_permanent_link": "Ideal para funis, redirecionamentos de sites ou anúncios, o link permanente permanece constante, não afetado por mudanças no slug",
      "copy": "Copiar",
      "share_embed_code": "Coloque este código no seu HTML onde deseja que o widget do/a {embedType} apareça",
      "scheduling_link": "Link de agendamento",
      "permanent_link": "Link Permanente",
      "one_time_link": "Link Único",
      "embed_code": "Código de incorporação",
      "loading": "A carregar...",
      "round_robin_booking": "Round Robin",
      "round_robin_description": "Distribui agendamentos entre os membros da equipa numa ordem rotativa",
      "round_robin_example": "Por exemplo: Chamadas de vendas, sessões de integração",
      "unassigned_booking": "Calendário de Eventos",
      "unassigned_description": "Para agendar eventos físicos sem associação de anfitrião",
      "unassigned_example": "Por exemplo: Conferências, seminários públicos, exposições",
      "class_booking": "Marcação de aulas",
      "class_booking_description": "Um anfitrião encontra-se com vários participantes",
      "class_booking_example": "Por exemplo: Webinars, formação em grupo, aulas online",
      "collective_booking": "Reserva Coletiva",
      "collective_booking_description": "Vários anfitriões reúnem-se com um participante",
      "collective_booking_example": "Por exemplo: Entrevistas em painel, revisões em comissão",
      "service_booking": "Reserva de Serviço",
      "service_booking_description": "Agendamento contínuo para empresas de serviços",
      "service_booking_example": "Por exemplo: Marcações de spa, serviços de reparação, consultas",
      "personal_booking": "Reserva pessoal",
      "personal_booking_description": "Agendar reuniões individuais com um membro específico da equipa",
      "personal_booking_example": "Por exemplo: Reuniões com clientes, consultas privadas",
      "personal": "Pessoal",
      "explore_more_types": "Explorar mais tipos",
      "scheduling_type": "Tipo de agendamento",
      "scheduling_type_description": "Escolha um tipo de agendamento para o seu novo calendário"
    },
    "preferences": {
      "preferences": "Preferências",
      "my_preferences": "A minha preferência",
      "account_preferences": "Preferências de conta",
      "user_preferences": "Preferências de usuário",
      "set_preferences": "Ajuste as preferências para a sua conta",
      "scheduling_options": "Opcções de agendamento",
      "meeting_location": "localização da reunião",
      "zoom": "Zoom",
      "save_preferences": "Salvar preferências",
      "in_app_preferences": "Preferências in App",
      "set_start_day": "Ajuste as preferências como o dia de começo da app",
      "view_options": "Ver opcções",
      "week_starts_on": "a semana começa em",
      "sunday": "Domingo",
      "widget_preferences": "Preferências do widget",
      "set_widget_language": "Ajuste as preferências como a linguagem para o widget",
      "language_and_region": "Linguagem e região",
      "language": "linguagem",
      "english": "Inglês",
      "time_format": "Formato da hora",
      "time_format_value": "Sat Dec 30 1899 13:30:00 GMT+0521 (India Standard Time)",
      "monday": "Segunda-feira",
      "custom_meeting_location": "Localização personalizável da reunião"
    },
    "availability": {
      "availability": "disponíbilidade",
      "my_availability": "a minha disponibilidade",
      "account_availability": "disponíbilidade da conta",
      "timezone": "timezone",
      "working_hours": "Horas de trabalho",
      "set_recurring_hours": "ajuste as suas horas de trabalho para a reunião",
      "sunday": "Domingo",
      "monday": "Segunda-feira",
      "tuesday": "Terça-feira",
      "wednesday": "Quarta-feira",
      "thursday": "Quinta-feira",
      "friday": "Sexta-feira",
      "saturday": "Sábado",
      "widget_preferences": "Preferencias do widget",
      "set_widget_language": "Ajuste as preferências como a linguagem para o widget",
      "language_and_region": "Linguagem e região",
      "language": "Linguagem",
      "english": "Inglês"
    },
    "connections": {
      "connections": "Conecções",
      "conferencing": "Conferenciando",
      "my_connections": "As minhas conecções",
      "account_connections": "conecções de conta",
      "connection_preferences": "Preferências de conecção",
      "manage_calendars_conferencing_channels": "Gerenciar calendários e canais de conferência",
      "main_integration_calendar": "Integração principal de calendário",
      "event_integration_calendar": "Os eventos criados num calendário de sistema de que faz parte irá também ser criado neste calendário integrado",
      "2way_sync": "Sinc 2-caminhos: Criar eventos de sistema de eventos agendados no calendário principal intergado",
      "calendars": "Calendários",
      "google": "Google",
      "outlook": "Outlook",
      "check_for_conflicts": "Verificar por conflitos",
      "choose_accounts_to_connect": "Escolha as contas que pretende conectar",
      "payments": "Pagamentos",
      "stripe": "Stripe",
      "ads": "Anúncios",
      "local_service_ads": "Anúncios serviço local",
      "fire_workflow": "Disparar gatilhos de workflow para eventos agendados por Sinc. 2-caminhos",
      "connect_google_outlook": "Por favor conecte a sua conta Google ou outlook para escolher o calendário principal",
      "execute_automation": "Executar automação",
      "save": "Salvar",
      "lsa": "Anúncios serviço local"
    }
  },
  "appointment_modal": {
    "select_a_contact": "Selecione um contato",
    "start_time": "Tempo de início",
    "end_time": "Tempo de fim",
    "search_by_name_email_phone_or_company": "Procurar por nome, email, telefone ou empresa",
    "add_new": "Adicionar novo",
    "pick_from_available_contacts": "Ou escolha dos contatos disponíveis",
    "book_appointment": "Agendar reunião",
    "calendar": "Calendários",
    "repeat": "Repetir",
    "date_and_time": "Data e hora",
    "close": "Fechar",
    "team_member": "Membro de equipa",
    "recurring_booking": "Agendamento recorrente",
    "one_slot_booking": "Agendamento Único",
    "event_type": "Tipo de evento",
    "occurrences_booked": "As ocorrências serão agendadas",
    "repeats": "repetições",
    "booking_details": "Detalhes de agendamento",
    "showing_slots_in_this_timezone": "A mostrar os espaços nesta timezone",
    "update_contact_tz": "Também atualizar o fuso horário de contato para",
    "date": "Data",
    "slot": "Slot",
    "calendar_default": "Calendário Padrão",
    "custom": "Personalizado",
    "meeting_notes": "Notas da reunião para a nossa próxima consulta",
    "out_of_office": "(Ex.) Fora do escritório durante a semana!",
    "standard": "Padrão",
    "appointment_title": "Título da nomeação",
    "event_title": "Título do Evento",
    "appt_with_bob": "(Ex.) Encontro com o Bob",
    "vacation": "Férias",
    "show_notes": "Notas do Show",
    "hide_notes": "Ocultar notas",
    "additional_preferences": "Preferências Adicionais",
    "meeting_location": "Local da Reunião",
    "appointment_status": "Estado da nomeação",
    "appt_description": "Descrição da nomeação",
    "recurring_details": "Detalhes Recorrentes",
    "event_description": "Descrição do evento",
    "in_contact_timezone": "No fuso horário do contato",
    "vacation_message": "Vai de férias? Aproveitar um tempo livre? Bloqueie tempo no seu calendário para evitar que os clientes marquem compromissos (os compromissos existentes ainda permanecerão no seu calendário)",
    "contact": "Contacto",
    "delete": "Eliminar",
    "cancel": "Cancelar",
    "back": "Voltar",
    "pick_available_contacts": "Ou escolha entre os contactos disponíveis",
    "no_contacts_found": "Nenhum contacto encontrado",
    "found": "encontrado",
    "select_one": "Selecione um",
    "tz_tooltip": "Isto só é relevante se estiver a utilizar Valores Personalizados, como hora de início da consulta, hora de fim da consulta, fuso horário da consulta, etc., na sua comunicação com os seus clientes. E o que faz é simplesmente converter os detalhes mencionados para a hora local",
    "meeting_location_tooltip": "O local da reunião será definido conforme configurado no calendário",
    "appt_meeting_location_tooltip": "O utilizador pode definir o local da reunião específico para o compromisso",
    "gmeet_tooltip": "Por favor, certifique-se de que o membro da equipa em questão tem um calendário do Google definido como o seu calendário principal",
    "zoom_tooltip": "Por favor, certifique-se de que o membro da equipa em questão tem o Zoom integrado",
    "enter_meeting_location": "Introduza o local da reunião",
    "save_appointment": "Salvar compromisso",
    "save_event": "Guardar Evento",
    "search_contacts": "Pesquisar por nome, email, telefone ou empresa",
    "recurring_slots": "{recurringSlotsLength}/{count}"
  },
  "popup": {
    "calendar": "Calendário",
    "appointment_owner": "Proprietário da nomeação",
    "source": "Fonte",
    "booked_by": "Reservado por",
    "view_activity_log": "Ver registo de atividade",
    "status": "Estado",
    "cancel_appointment": "Cancelar consulta",
    "google_event": "Evento Google",
    "outlook_event": "Evento do Outlook",
    "blocked_slot": "Slot bloqueado",
    "appointment": "Nomeação",
    "confirmed": "Confirmado",
    "unconfirmed": "Não confirmado",
    "cancelled": "Cancelado",
    "showed": "Mostrou",
    "no_show": "Falta de Comparência",
    "invalid": "Inválido"
  },
  "appointment_list": {
    "group_all": "TODOS",
    "group_primary": "PRIMÁRIA",
    "group_user": "Utilizadores",
    "group_team": "GRUPOS",
    "group_calendar": "CALENDÁRIOS",
    "all_appointments": "Todas as consultas",
    "my_calendar": "O Meu Calendário",
    "status_all": "Todos",
    "status_confirmed": "Confirmado",
    "status_new": "Novo (Ação Necessária)",
    "status_show": "Mostrou",
    "status_no_show": "Não Comparecimento",
    "status_cancelled": "Cancelado",
    "status_invalid": "Inválido",
    "filter_date_added_desc": "Data de Adição (Desc)",
    "filter_start_added_desc": "Data de início (Desc)",
    "filter_start_added_asc": "Data de início ascendente a partir de hoje",
    "button_new_appointment": "Nova Nomeação",
    "name": "Nome",
    "title": "Título",
    "requested_time": "Tempo solicitado",
    "date_added": "Data de Adição",
    "calendar": "Calendário",
    "appointment_owner": "Proprietário da nomeação",
    "notes": "Notas",
    "status": "Estado",
    "action_view_detail": "Ver detalhes",
    "action_view_notes": "Ver Notas",
    "action_edit": "Editar",
    "action_delete": "Apagar",
    "action_reschedule": "Reagendar",
    "page_previous": "Anterior",
    "page_next": "Próximo",
    "appointment_notes": "Observações da consulta",
    "close": "Fechar",
    "confirm_delete_header": "Eliminar Agendamento",
    "confirm_delete_message": "Tem a certeza de que deseja eliminar esta consulta?",
    "confirm_delete_button": "Apagar",
    "confirm_update_header": "Atualizar Estado do Agendamento",
    "confirm_update_confirmed_message": "Tem a certeza de que deseja marcar esta consulta como confirmada?",
    "confirm_update_invalid_message": "Tem a certeza que deseja marcar esta consulta como inválida?",
    "confirm_update_showed_message": "Tem a certeza de que deseja marcar esta consulta como realizada?",
    "confirm_update_noshow_message": "Tem a certeza de que deseja marcar esta consulta como não comparecida?",
    "confirm_update_cancelled_message": "Tem a certeza de que deseja marcar esta consulta como cancelada?",
    "confirm_update_button": "Atualização",
    "disable_menu_warning": "Atualmente, não tem permissão para visualizar ou editar os detalhes do compromisso, pois o contato associado não está atribuído a si. Por favor, entre em contato com o administrador para obter mais assistência",
    "no_contact_warning": "Neste momento não consegue aceder às informações de contacto. Isto poderá ser porque o contacto não lhe foi atribuído. Por favor, contacte o seu administrador para obter assistência",
    "youre_viewing_in": "Currently viewing in",
    "rescheduled": "Reagendado"
  },
  "create_calendar": {
    "calendar_name": "Nome do calendário",
    "calendar_logo": "Logotipo de calendário",
    "select_team_member": "Selecionar membros da equipe",
    "eg_outbound_reach": "(eg) Alcance externo",
    "description": "Descrição",
    "amount": "Quantidade",
    "description_placeholder": "Escreva uma descrição",
    "meeting_duration": "Duração da reunião",
    "seats_per_class": "Lugares por classe",
    "group": "grupo",
    "custom_url": "URL personalizado",
    "meeting_invite_title": "Título do convite para reunião",
    "event_color": "Cor do evento",
    "availability_type": "Tipo de disponibilidade",
    "deposit_amount": "Montante do depósito",
    "collect_deposit_amount": "Aceitar pagamento parcial",
    "percentage": "Percentagem",
    "total_amount": "Valor Total",
    "reschedule_preference": "Preferência de Reagendamento",
    "reschedule_preference_subtext": "Se um contacto remarca uma consulta:",
    "reassign_through_round_robin": "Reatribuir através de rodízio",
    "keep_same_assinged_user": "Mantenha o mesmo proprietário da consulta",
    "new_appointment_preference": "Nova Preferência de Agendamento",
    "new_appointment_preference_text": "Sempre reservar com o utilizador atribuído ao contacto",
    "new_appointment_preference_subtext": "Agendar novas consultas com o utilizador atribuído ao contacto em vez de utilizar agendamento rotativo"
  },
  "calendar_advanced": {
    "general": {
      "quick_tip": "Dica Rápida",
      "timezone_change_tip": "Os fusos horários podem ser alterados a qualquer momento nas preferências do fuso horário",
      "save": "Salvar",
      "close": "Fechar",
      "meeting_details": "Detalhes de Reunião",
      "availability": "Disponibilidade",
      "forms_and_payment": "Formulários e Pagamento",
      "notifications_and_additional_options": "Notificações e Opções Adicionais",
      "connections": "Ligações",
      "customizations": "Personalizações",
      "create": "Criar",
      "edit": "Editar"
    },
    "meeting_details": {
      "meeting_location": "Local de reunião",
      "enter_meeting_location": "Digite a localização da reunião",
      "click_to_upload": "Carregar",
      "square": "Quadrado",
      "circle": "Círculo",
      "remove_logo": "Remover logotipo",
      "meeting_invite_title_tooltip": "Este é o título para o evento do calendário que aparece no Google Calendar, Outlook, Apple Calendar, etc",
      "event_color_tooltip": "Escolha uma cor para este evento de reserva. As cores são sincronizadas com os eventos no Google Calendário",
      "enter_a_name": "Por favor, insira um nome",
      "enter_an_event_title": "Por favor insira o título do evento",
      "high_priority": "Alta Prioridade",
      "medium_priority": "Média Prioridade",
      "low_priority": "Baixa prioridade",
      "custom": "Personalizado",
      "zoom_not_added": "O utilizador ainda não ligou a sua conta do Zoom",
      "opt_avail": "Otimizar para disponibilidade",
      "opt_equal": "Otimizar para distribuição igual",
      "team_members": "Selecione membros da equipe",
      "team_member": "Membro da Equipa Selecionado",
      "meeting_location_required": "É necessário o local da reunião",
      "team_member_disabled_recurring_tooltip": "Reuniões recorrentes permitem apenas um membro da equipa. Para adicionar vários membros da equipa, por favor desative as reuniões recorrentes.",
      "team_member_disabled_class_booking_tooltip": "Apenas um membro da equipa pode ser adicionado ao calendário de marcações de uma aula.",
      "enable_contact_assigned_to_setting": "Para ativar esta definição, o Formulário deve estar em primeiro lugar na ordem dos widgets. Por favor, reorganize a ordem dos widgets na guia Formulários e Pagamentos",
      "reschedule_preference_setting_tooltip": "Escolha a quem a nomeação deve ser atribuída quando um contacto remarcar a partir do widget de reserva",
      "appointment_preference_tooltip": "Escolha se novos compromissos devem ser sempre agendados com o usuário atribuído ao contato. Se um contato tiver um usuário atribuído, esse usuário terá preferência; caso contrário, será feita uma atribuição circular. Dica: Desative a opção 'Permitir Seleção de Equipe' para evitar que os agendadores alterem o membro da equipe no widget de agendamento",
      "add_location": "Adicionar Localização",
      "multiple_locations_neo": "A opção de ter múltiplos locais de reunião é apenas suportada pelo Neo Widget com o Formulário Padrão",
      "make_owner": "Tornar proprietário",
      "only_one_team_member": "Apenas um membro da equipa é permitido quando são adicionadas várias localizações",
      "only_one_team_member_personal_calendar": "Apenas um membro da equipa é permitido para reservas pessoais",
      "ms_teams_not_added": "O utilizador ainda não ligou a sua conta do Microsoft Teams"
    },
    "availability": {
      "my_availability": "A minha disponibilidade",
      "set_availability": "Defina a sua disponibilidade para o calendário aqui",
      "standard": "Padrão",
      "custom": "Personalizado",
      "to": "Para",
      "copy_to_all": "Enviar para todos",
      "add_time": "Adicionar tempo",
      "recurring_meeting": "Reunião recorrente",
      "recurring_info": "Apenas um utilizador por agenda para reserva recorrente",
      "repeat": "Repetir",
      "times_to_repeat": "Vezes para repetir",
      "slots_unavailable": "Se não houver slots disponíveis?",
      "overlap_status": "Estado da marcação para horários sobrepostos",
      "meeting_interval": "Intervalo de reunião",
      "meeting_duration": "Duração da reunião",
      "minimum_scheduling": "Aviso mínimo de agendamento",
      "date_range": "Intervalo de datas",
      "maximum_bookings_per_day": "Número máximo de reservas por dia",
      "maximum_bookings_per_slot": "Número máximo de reservas por horário",
      "maximum_bookings_per_slot_per_user": "Reservas máximas por slot (por utilizador)",
      "seats_per_class": "Lugares por classe",
      "pre_buffer_time": "Tempo de pré-buffer",
      "post_buffer_time": "Tempo de buffer de postagem",
      "pre_buffer_tooltip": "O Pré-Buffer é o tempo adicional que pode ser acrescentado antes de um compromisso, permitindo mais tempo para se preparar antes de qualquer reunião",
      "post_buffer_tooltip": "O \"Post-Buffer\" é o tempo adicional que pode ser adicionado após uma consulta, permitindo ter mais tempo para concluir",
      "buffer_time": "Tempo de margem",
      "daily": "Diário",
      "weekly": "Semanalmente",
      "monthly": "Mensalmente",
      "skip_booking": "Ignorar reservas de slots indisponíveis",
      "continue_booking": "Continuar reserva",
      "book_next_available": "Agende o próximo horário disponível",
      "confirmed": "Confirmado",
      "unconfirmed": "Não confirmado",
      "enter_valid_hours": "Por favor, insira um horário válido de funcionamento",
      "enter_valid_avail": "Por favor, insira uma disponibilidade personalizada válida",
      "availability_type_tooltip": "Escolha Disponibilidade Padrão para reservas regulares oferecidas semanalmente. Escolha Personalizado para reservas disponíveis apenas em datas específicas",
      "meeting_interval_tooltip": "O intervalo de reunião reflete o tempo entre os horários de reserva que serão exibidos no calendário. Para um evento de 30 minutos que deve estar disponível no início de cada hora, o intervalo seria de 1 hora. Para um evento de 30 minutos que deve estar disponível a cada 30 minutos, o intervalo seria de 30 minutos",
      "date_range_tooltip": "Os eventos podem ser marcados nos próximos n dias",
      "maximum_bookings_per_day_tooltip": "Número máximo de consultas permitidas por dia neste calendário",
      "maximum_bookings_per_slot_tooltip": "Número máximo de compromissos permitidos por slot para o utilizador (se o número de compromissos exceder para o slot atribuído ao utilizador, então passa para o próximo utilizador na fila round-robin ou marca o slot como indisponível para a perspetiva marcar)",
      "add_days": "Adicionar Dias",
      "month_on_day": "Mensalmente no dia",
      "month_on_last_day": "Mensalmente no último dia",
      "day": "dia",
      "week": "Semana",
      "month": "Mês",
      "mins": "Minutos",
      "hours": "Horas",
      "days": "Dias",
      "weeks": "Semanas",
      "months": "Meses",
      "meeting_interval_error": "O intervalo da reunião deve ser entre 5 minutos e 12 horas",
      "meeting_duration_error": "A duração da reunião deve ser entre 1 minuto e 12 horas",
      "buffer_time_error": "O tempo de reserva deve ser inferior a 12 horas",
      "subTitle": "Escolha a data para definir horas específicas",
      "heading_1": "Horas disponíveis semanais",
      "tooltip_1": "Defina o seu horário de disponibilidade semanal. Estas são as suas horas padrão que serão aplicadas de forma consistente numa base semanal",
      "heading_2": "Quando estás disponível?",
      "unavailable_label": "Não disponível",
      "tooltip_2": "Ajuste o seu horário para datas específicas. Sobrescreva as suas horas regulares semanais marcando a disponibilidade ou indisponibilidade para as datas selecionadas",
      "date_specific_level": "Adicionar horas específicas de data",
      "specific_hours_button": "Horário Específico de Data",
      "overlap_error": "Horários não podem sobrepor-se",
      "apply": "Aplicar",
      "copy_times_to": "Copiar horários para",
      "no_date_specific_heading": "Sem hora específica definida",
      "no_date_specific_description": "Você pode adicionar/remover uma data e hora específicas à sua disponibilidade",
      "recurring_disabled_conditions_tooltip": "As reuniões recorrentes estão desativadas. Para ativá-las, certifique-se de que o calendário tem apenas um membro da equipa e que não foram adicionadas horas específicas de data",
      "date_specific_hours_disabled_conditions_tooltip": "Para incluir uma hora específica, por favor certifique-se de desativar as reuniões recorrentes",
      "recurring_disable_event_calendar_conditions": "As reuniões recorrentes estão desativadas. Para ativá-las e não adicionar horas específicas de data",
      "service_interval": "Intervalo de serviço",
      "service_interval_tooltip": "O intervalo de reunião determina com que frequência as vagas de reserva aparecem no calendário. Por exemplo, para um evento de 30 minutos, um intervalo de 1 hora significa que as vagas aparecem a cada hora. Um intervalo de 30 minutos significa que as vagas aparecem a cada 30 minutos",
      "service_duration_tooltip": "Isso estabelece a duração do seu compromisso, determinando quanto tempo ele durará",
      "minimum_scheduling_tooltip": "Isto define o tempo mínimo necessário para agendar um compromisso através do calendário. Por exemplo, se definido para 4 horas, os compromissos não podem ser marcados dentro das 4 horas anteriores ao horário de início; as marcações devem ser feitas com pelo menos 4 horas de antecedência",
      "service_duration": "Service duration",
      "look_busy_title": "Finge estar ocupado",
      "look_busy_percentage_description": "Ocultar o número de vagas disponíveis por x%",
      "look_busy_helper_text": "Ocultar uma percentagem dos seus horários disponíveis na ferramenta de reserva para parecer mais ocupado e em alta procura",
      "maximum_bookings_per_slot_tooltip_personal": "Número máximo de consultas permitidas por slot para o utilizador"
    },
    "forms_payment": {
      "forms": "Formulários",
      "forms_sub": "Defina as preferências do formulário de agendamento de postagens",
      "select_form": "Selecionar formulário",
      "sticky_contacts": "Pré-preencher campos (contatos fixos)",
      "confirmation_page": "Página de confirmação",
      "default": "Padrão",
      "redirect_url": "URL de Redirecionamento",
      "thank_you": "Mensagem de agradecimento",
      "enter_url": "Introduza o URL",
      "pixel_id": "Identificação do pixel do Facebook (opcional)",
      "auto_confirm": "Confirmar automaticamente as novas reuniões do calendário",
      "configure_payment": "Configure as opções de pagamento",
      "accept_payments": "Aceitar pagamentos",
      "test": "Teste",
      "live": "Viver",
      "enable_guests": "Adicionar convidados",
      "add_guest_tooltip": "Adicionar convidado permitirá que o participante adicione vários participantes ao mesmo compromisso",
      "add_guest_same_form_check": "Enviar o mesmo formulário para recolher informações por correio a todos os convidados",
      "payment_tooltip": "O pagamento apenas seria considerado para o participante principal e não para os convidados",
      "default_form": "Predefinição (Primeiro nome, Último nome, Email, Telefone, Notas)",
      "select_form_tooltip": "Se pretender recolher mais informações do que os campos padrão Primeiro Nome, Último Nome, Email e Telefone daqueles que fazem reserva neste calendário, crie um formulário personalizado em Sites > Formulários > Criador e selecione o formulário através do menu suspenso abaixo",
      "confirmation_page_tooltip": "Uma vez que alguém faz uma reserva, você pode optar por mostrar-lhes uma mensagem personalizada na mesma página (Padrão) ou redirecioná-los para uma página de sua escolha (Redirecionar)",
      "add_guest_collect_email": "Com nome e email",
      "add_guest_count_only": "Apenas conte",
      "custom_email_notification": "Enviar formulário personalizado aos convidados",
      "deposit_amount_error": "O valor do depósito deve ser inferior ao valor total",
      "deposit_percentage_error": "A percentagem do depósito deve ser inferior a 100%",
      "partial_payment_tooltip": "Permitir pagamentos parciais para recolher o montante do depósito dos clientes durante o processo de marcação de consulta",
      "partial_payment_enable_error": "O montante total inserido deve ser superior a 0 para permitir pagamentos parciais",
      "payment_disable_text_integration": "Integrar um fornecedor de pagamentos em Pagamentos > Integrações para começar a aceitar pagamentos",
      "payment_disable_text_recurring": "Opção de pagamento não disponível para reuniões recorrentes. Desative a recorrência para ativar os pagamentos",
      "date_and_time_selector": "Seletor de Data e Hora",
      "form_selector": "Formulário",
      "order_step": "Passo",
      "widget_order_label": "Ordenação de widgets",
      "widget_order_tool_tip": "Rearranjar a ordem do 'Seletor de Data e Hora' e do 'Formulário' no widget do calendário. Basta arrastar e reordenar de acordo com sua preferência",
      "same_user_assignment_tooltip": "Para ativar esta configuração, o Formulário deve estar em primeiro lugar no widget. Reorganize o widget para posicionar o Formulário em primeiro lugar e ativar esta configuração",
      "widget_re_order_tooltip": "A reordenação só está disponível com o estilo de widget Neo. Mude para o Neo para ativar a reordenação",
      "widget_re_order_disabled_tooltip": "Para reorganizar a ordem dos widgets, por favor desative 'Preferência de Novo Agendamento (Sempre marcar com o utilizador atribuído ao contacto)' em Detalhes da Reunião > Membros da Equipa > Configurações Avançadas",
      "enable_same_user_assignment": "Atribuir contatos aos respetivos membros da equipa do calendário sempre que for marcado um compromisso",
      "recurring_payment_label": "Pagamento de compromissos recorrentes",
      "first_appointment_only": "Apenas por marcação",
      "all_appointments": "Todos os compromissos"
    },
    "notifications": {
      "notifications": "Notificações e Opções Adicionais",
      "notifications_sub": "Configure notificações e opções adicionais",
      "select_notification_type": "Selecione o tipo de notificação",
      "notification_person": "Quem deve receber esta notificação?",
      "contact": "Contacto",
      "assigned_user": "Utilizador atribuído",
      "emails": "Emails",
      "alert_email_address": "Endereço de email de alerta",
      "ack_email": "Email de reconhecimento",
      "send_google_invites": "Permitir que o Google Agenda envie convites ou atualizações por email aos participantes",
      "send_google_outlook_invites": "Permitir que o Google / Outlook Calendar envie convites e atualizações por email aos participantes",
      "assign_user_to_contact": "Atribuir contatos aos respetivos membros da equipe do calendário sempre que uma consulta for marcada",
      "skip_assigning_user_to_contact": "Ignorar a atribuição de contato se o contato já tiver um usuário atribuído",
      "guest_label_count": "Número de convidado(s)",
      "guest_count_error": "O número de convidados deve estar entre 1 e 100",
      "allow_cancellation": "Permitir o cancelamento da reunião",
      "allow_reschedule": "Permitir o reagendamento da reunião",
      "allow_cancellation_tooltip": "Quando ativado, será adicionado um link de cancelamento à secção de notas adicionais e incluído no convite do calendário. Este link expirará conforme configurado antes da hora de início da reunião, impedindo o organizador de cancelar a reunião",
      "allow_reschedule_tooltip": "Quando ativada, um link de remarcação será adicionado à seção de notas adicionais e incluído no convite do calendário. Este link expirará conforme configurado antes do horário de início da reunião, impedindo que o agendador remarque a reunião",
      "cancellation_link_expiry": "O link de cancelamento expirará",
      "reschedule_link_expiry": "O link de reagendamento irá expirar",
      "before_meeting": "antes da reunião",
      "cancellation_and_reschedule_policy": "Política de Cancelamento e Reagendamento:"
    },
    "connections": {
      "connections_heading": "Sincronização do Calendário Externo",
      "connections_sub": "Sincronizar eventos com calendário externo",
      "link_to_calendar": "Link para o calendário",
      "sync_option": "Opção de Sincronização",
      "execute_automation": "Automatização executada",
      "fire_workflow": "Acionadores do fluxo de trabalho por notificações de eventos agendados através de sincronização bidirecional",
      "reserve_with_google": "Reservar com o Google",
      "connect_lsa": "Conecte-se à sua conta de Anúncios de Serviços Locais",
      "reserve_error": "Atualmente, a Reserva com o Google só é suportada para os setores de \"Serviços Domésticos\", \"Imobiliária\" e \"Serviços Jurídicos\" (provedores de serviços locais) nos Estados Unidos",
      "one_way": "Sentido único",
      "two_way": "Duas Vias",
      "smart": "Inteligente",
      "confirm_remove": "Tem a certeza de que deseja remover o Google Agenda?",
      "read_only_access": "Você não tem permissão para escrever neste calendário. Por favor, selecione um calendário diferente",
      "reserve_with_google_desc": "Este serviço de calendário será listado como uma reserva apenas após a integração bem-sucedida com o Google",
      "check_integration": "Verifique o estado da sua integração"
    },
    "extras": {
      "extras": "Personalizações",
      "extras_sub": "Definir o estilo do widget e outras preferências",
      "calendar_widget_style": "Estilo de widget de calendário",
      "neo": "Neó",
      "classic": "Clássico",
      "insert_custom_code": "Inserir código personalizado",
      "show_seats_per_slot": "Mostrar lugares por sessão",
      "allow_reschedule_meeting": "Permitir reagendar a reunião",
      "allow_cancelling_meeting": "Permitir cancelar a reunião",
      "additional_notes": "Observações adicionais",
      "cancellation_and_reschedule": "Cancelamento e remarcação:",
      "allow_staff": "Permitir seleção de equipe durante a reserva",
      "code_block_tooltip": "Os scripts de terceiros dentro do bloco HTML podem fornecer funcionalidades poderosas, mas também apresentam riscos à privacidade, segurança, desempenho e comportamento da página. Portanto, recomendamos que você revise o conteúdo deste script antes de salvá-lo no calendário"
    }
  },
  "add_guest": {
    "guest_label": "Hóspede(s)",
    "search_placeholder": "Procurar a partir dos contactos",
    "add_new": "Adicionar Novo",
    "save": "Guardar",
    "close": "Fechar",
    "name": "Nome",
    "phone": "telefone",
    "email": "Email"
  },
  "languages": {
    "english": "Inglês",
    "dutch": "Holandês",
    "french": "Francês",
    "german": "Alemão",
    "hungarian": "Húngaro",
    "italian": "Italiano",
    "polish": "Polaco",
    "portuguese_brazil": "Português (Brasil)",
    "portuguese_portugal": "Português (Portugal)",
    "spanish": "Espanhol",
    "danish": "Dinamarquês"
  },
  "service_menu_advanced": {
    "new_service_menu_details_header": "Detalhes do Menu de Serviços",
    "select_groups_header": "Serviços seleccionados",
    "arrange_groups_header": "Organize os seus serviços",
    "new_service_menu": "Novo Menu de Serviço",
    "service_menu_sub_title": "Crie um menu de serviços personalizado para os seus clientes com apenas alguns cliques",
    "quick_tip": "Você pode adicionar grupos ao menu de serviços",
    "service_menu_name": "Nome do menu de serviços",
    "service_menu_description": "Descrição",
    "service_menu_slug": "URL de serviço",
    "select_groups_calendars": "Selecione grupos e calendários",
    "select_groups_calendars_desc": "Adicione Serviços ao seu menu de serviços",
    "select_all": "Selecionar Todos",
    "arrange_groups": "Organizar ordem dos serviços",
    "arrange_groups_desc": "Determine a sequência de serviços no menu de serviços",
    "arrange_services_quicktip": "Para reorganizar calendários dentro do grupo, vá em Grupos > Opções > Reorganizar Calendários",
    "enable_add_guest_desc": "Permite que os clientes incluam hóspedes adicionais ao fazer uma reserva",
    "enable_multiple_service_desc": "Permite aos clientes agendar vários serviços numa única marcação",
    "enable_staff_selection_desc": "Permite aos clientes selecionar membros do staff preferidos ao fazer uma reserva",
    "enable_add_guest": "Permitir Adicionar Convidados",
    "enable_multiple_service": "Permitir seleção de vários serviços",
    "enable_staff_selection": "Ativar Seleção de Funcionários",
    "additional_options": "Opções Adicionais",
    "code_block_tooltip": "Os scripts de terceiros dentro do bloco HTML podem fornecer funcionalidades poderosas, mas também trazem riscos para a privacidade, segurança, desempenho e comportamento da página. Por isso, recomendamos que você revise o conteúdo deste script antes de salvá-lo no menu de serviços.",
    "custom_code_placeholder": "Por favor, insira o código personalizado aqui",
    "insert_custom_code": "Inserir código personalizado",
    "no_service": "Serviço não encontrado",
    "no_service_desc": "Não foram encontrados serviços. Crie serviços e adicione-os a grupos para criar um menu de serviços",
    "learn_more": "Saber Mais",
    "no_service_selected": "Nenhum serviço selecionado",
    "create_service_calendars": "Criar calendários de serviço",
    "alert_description": "Para criar um menu de serviço, é necessário criar calendários de serviço e adicioná-los a grupos",
    "dont_show_again": "Não mostrar isto novamente",
    "service_name_required": "É necessário um nome para o menu de serviços",
    "slug_already_taken": "Esta lesma já está ocupada",
    "slug_required": "É necessário preencher o campo do Slug",
    "please_enter_name": "Por favor, digite um nome",
    "service_name_placeholder": "Menu Principal",
    "consent_label": "Caixa de seleção de consentimento",
    "consent_input_placeholder": "Adicione texto de consentimento",
    "consent_tooltip": "Ative esta opção para permitir obter o consentimento dos contatos quando fornecerem o número de telefone durante o processo de reserva de consulta"
  },
  "widget_configuration": {
    "customise_calendar_widget": "Personalizar o widget de calendário",
    "works_with_neo_widget": "Apenas funciona com o widget Neo",
    "customise_widget_desc": "Personalizar a aparência do widget: cor principal, cor de fundo e texto do botão.",
    "learn_more": "Saber mais",
    "primary_settings": "Definições primárias",
    "primary_settings_desc": "Configure a cor primária, a cor de fundo e outras preferências de widget para personalizar o seu widget de calendário",
    "primary_color": "Cor primária",
    "primary_color_desc": "Defina a cor para botões, datas, horários e ações",
    "background_color": "Cor de fundo",
    "background_color_desc": "Defina a cor de fundo que serve de pano de fundo tanto para o seletor de data e hora quanto para o formulário",
    "button_text": "Texto do botão",
    "button_text_desc": "Personalize o texto exibido no botão ao agendar um compromisso",
    "calendar_title": "Título do calendário",
    "calendar_title_desc": "O nome do seu calendário",
    "calendar_description": "Descrição do Calendário",
    "calendar_description_desc": "A descrição inserida",
    "calendar_details": "Detalhes do calendário",
    "calendar_details_desc": "Inclui informações como duração, data e hora, detalhes de recorrência e fuso horário",
    "reset_to_default": "Redefinir para o padrão",
    "preview_widget": "Pré-visualização da Widget"
  },
  "eventNotification": {
    "list": {
      "booked": {
        "title": "Notificação de Agendamento Feito (Estado: Não Confirmado)",
        "note": "Esta notificação é enviada quando a consulta não está confirmada",
        "subTitle": "Notifica quando é marcada uma consulta com um estado não confirmado"
      },
      "confirmation": {
        "title": "Notificação de Marcação Agendada (Estado: Confirmado)",
        "note": "Esta notificação é enviada quando a marcação é confirmada",
        "subTitle": "Notifica quando um agendamento é confirmado com sucesso"
      },
      "cancellation": {
        "title": "Notificação de Cancelamento",
        "note": "Esta notificação é enviada quando a consulta é cancelada, marcada como falta, ou inválida",
        "subTitle": "Alertas quando uma consulta é cancelada"
      },
      "reschedule": {
        "title": "Notificação de Reagendamento",
        "note": "Esta notificação é enviada para marcações não confirmadas, confirmadas ou apresentadas",
        "subTitle": "Notifica quando um compromisso é remarcado"
      },
      "reminder": {
        "title": "Notificação de lembrete",
        "note": "Esta notificação é enviada para marcações não confirmadas, confirmadas ou apresentadas",
        "subTitle": "Envia um lembrete antes da consulta"
      },
      "followup": {
        "title": "Notificação de Acompanhamento",
        "note": "Esta notificação é enviada para marcações não confirmadas, confirmadas ou apresentadas",
        "subTitle": "Envia uma mensagem de acompanhamento após a conclusão da consulta"
      }
    },
    "saveToastMsg": "Definições Guardadas!",
    "saveToastMsgDesc": "Suas alterações foram salvas com sucesso",
    "email": {
      "noneTemplate": "Nenhum",
      "notificationLabel": "Quem deve receber esta notificação?",
      "sendButtonLabel": "Enviar Email de Teste",
      "testEmailLabel": "Email de teste",
      "testEmailPlaceholder": "Introduza o endereço de email do destinatário para testar",
      "body": "Corpo do Email",
      "bodyPlaceholder": "Digite sua mensagem aqui",
      "subject": "Assunto",
      "subjectPlaceholder": "Introduza o assunto do email",
      "templateLabel": "Template de Email",
      "templatePlaceholder": "Selecione um modelo de email ou comece do zero",
      "alertEmailAddress": "Endereço de email de alerta",
      "addMore": "Adicionar mais",
      "afterAppointmentEnds": "após o término da consulta",
      "beforeAppointmentStarts": "Antes do início da consulta",
      "durationLabel": "Quando gostaria de ativar a notificação?",
      "disableEmailNotification": "Desativar Notificações por Email",
      "enableEmailNotification": "Ativar Notificações por Email",
      "enableSuccess": "Notificação de Email Ativada!",
      "enableSuccessDesc": "Agora começará a receber esta notificação",
      "disableSuccess": "Notificação por Email Desativada!",
      "disableSuccessDesc": "Você não irá mais receber esta notificação"
    },
    "inApp": {
      "title": "Definições de Notificações",
      "selectedUser": {
        "label": "Enviar para",
        "helperText": "Esta notificação interna é enviada através da web e das aplicações móveis",
        "placeholder": "Selecionar Utilizador(es)"
      },
      "enableSuccess": "Notificações no aplicativo ativadas!",
      "enableSuccessDesc": "Agora começará a receber esta notificação",
      "disableSuccess": "Notificações na aplicação desativadas!",
      "disableSuccessDesc": "Você deixará de receber esta notificação"
    }
  }
}