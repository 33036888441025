import Calendar, { WidgetType } from '../models/calendar'

export const getPermanentUrlForCalendar = (
  calendar: Calendar,
  baseUrl: string
) => {
  return `${baseUrl}/widget/booking/${calendar.id}`
}

export const getEmbedCodeForCalendar = (
  calendar: Calendar,
  baseUrl: string
) => {
  const embedScript =
    calendar.widgetType === WidgetType.CLASSIC ? 'embed.js' : 'form_embed.js'

  const uniqueId = `${
    calendar.providerId ? calendar.providerId : calendar.id
  }_${new Date().getTime()}`

  const embedBaseUrl = `${baseUrl}/widget/booking/${calendar.id}`

  const calendarId =
    calendar.widgetType === WidgetType.CLASSIC ? 'msgsndr-calendar' : uniqueId

  return `<iframe src="${embedBaseUrl}" style="width: 100%;border:none;overflow: hidden;" scrolling="no" id="${calendarId}"><\/iframe><br><script src="${baseUrl}/js/${embedScript}" type="text/javascript"><\/script>`
}
