import { requests } from './BaseService'

export const EventNotificationService = {
  getEventNotifications: (query: any): Promise<any> =>
    requests().get(`calendars/event-notifications/list`, {
      params: query,
    }),
  updateEventNotifications: (payload: any): Promise<any> => {
    return requests().put(`calendars/event-notifications/bulk-update`, payload)
  },
  createEventNotifications: (payload: any): Promise<any> => {
    return requests().post(`calendars/event-notifications`, payload)
  },
}
