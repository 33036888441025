import moment from 'moment-timezone'
import { MyAvailability } from './MyAvailability'

interface AvailabilityHour {
  startTime?: string
  endTime?: string
}

export default class MyAvailabilityDay {
  day: string

  id: string
  hours = [] as AvailabilityHour[]
  overlapIndex = 0
  constructor(day, hours?: AvailabilityHour[], id?: string) {
    this.day = day
    this.id = id || ''
    this.hours = hours || [
      {
        startTime: '8:0',
        endTime: '17:0',
      } as {
        startTime?: string
        endTime?: string
      },
    ]
  }

  addNewAvailabilityHour() {
    this.hours.push({
      startTime: null,
      endTime: null,
    })
  }

  addNewAvailabilityHourWithStartTimeAndEndTime(startTime, endTime) {
    if ((!startTime && !endTime) || this.hours.length === 0) {
      this.hours.push({
        startTime: '8:0',
        endTime: '17:0',
      })
    } else {
      this.hours.push({
        startTime: moment(endTime, 'H:m')
          .set({
            hour: moment(endTime, 'H:m').hour() + 1,
          })
          .format('H:m'),
        endTime: moment(endTime, 'H:m')
          .set({
            hour: moment(endTime, 'H:m').hour() + 2,
          })
          .format('H:m'),
      })
    }
    const overlapIndexes = this.getOverlappingHours()
    this.overlapIndex = overlapIndexes.length
  }

  getLastHour() {
    if (this.hours.length === 0) {
      return {
        startTime: null,
        endTime: null,
      }
    }
    return this.hours[this.hours.length - 1]
  }

  removeAvailabilityHour(index) {
    this.hours.splice(index, 1)
    const overlapIndexes = this.getOverlappingHours()
    this.overlapIndex = overlapIndexes.length
  }

  removeAllAvailabilityHour() {
    this.hours.splice(0)
  }

  getOverlappingHours() {
    const overlaps = [] as number[]

    if (this.hours.length <= 1) {
      return overlaps
    }

    for (let i = 0; i < this.hours.length - 1; i++) {
      for (let j = i + 1; j < this.hours.length; j++) {
        const startTime1 = moment(this.hours[i].startTime, 'H:mm')
        const endTime1 = moment(this.hours[i].endTime, 'H:mm')
        const startTime2 = moment(this.hours[j].startTime, 'H:mm')
        const endTime2 = moment(this.hours[j].endTime, 'H:mm')

        if (
          startTime1.isBetween(startTime2, endTime2) ||
          endTime1.isBetween(startTime2, endTime2) ||
          startTime2.isBetween(startTime1, endTime1) ||
          endTime2.isBetween(startTime1, endTime1)
        ) {
          overlaps.push(i)
          overlaps.push(j)
        }
      }
    }

    return [...new Set(overlaps)]
  }
  isOverlapIndex(hourIndex: number) {
    const overlapIndexes = this.getOverlappingHours()
    return Math.max(...overlapIndexes) === hourIndex
  }

  get dayOfWeek(): number {
    return MyAvailability.OFFICE_DAYS.findIndex(x => x === this.day)
  }

  get serializeHours() {
    return this.hours
      .map(hour => {
        if (!hour.startTime || !hour.endTime) {
          return
        }

        const startTimeSplit = hour.startTime.split(':')
        const endTimeSplit = hour.endTime.split(':')

        return {
          open_hour: parseInt(startTimeSplit[0]),
          open_minute: parseInt(startTimeSplit[1]),
          close_hour: parseInt(endTimeSplit[0]),
          close_minute: parseInt(endTimeSplit[1]),
        }
      })
      .filter(x => x)
  }

  get serializeHoursAsPerAPIFormat() {
    return this.hours
      .map(hour => {
        if (!hour.startTime || !hour.endTime) {
          return
        }

        const startTimeSplit = hour.startTime.split(':')
        const endTimeSplit = hour.endTime.split(':')

        return {
          openHour: parseInt(startTimeSplit[0]),
          openMinute: parseInt(startTimeSplit[1]),
          closeHour: parseInt(endTimeSplit[0]),
          closeMinute: parseInt(endTimeSplit[1]),
        }
      })
      .filter(x => x)
  }

  serializeAsPerDatabaseFormat() {
    if (this.serializeHours.length) {
      return {
        days_of_the_week: [this.dayOfWeek],
        hours: this.serializeHours,
      }
    }
  }

  serializeAsPerAPIFormat() {
    if (this.serializeHours.length) {
      return {
        daysOfTheWeek: [this.dayOfWeek],
        hours: this.serializeHoursAsPerAPIFormat,
      }
    }
  }

  serializeCustomAvailabilityAsPerAPIFormat() {
    if (this.serializeHours.length) {
      return {
        date: moment(this.day, 'MMMM D, YYYY')
          .locale('en')
          .format('YYYY-MM-DD'),
        hours: this.serializeHoursAsPerAPIFormat,
        id: this.id,
      }
    }

    return {
      date: moment(this.day, 'MMMM D, YYYY').locale('en').format('YYYY-MM-DD'),
      hours: [],
      id: this.id,
    }
  }

  get hasAnyErrorHours() {
    return this.hoursErrorMapping.findIndex(x => x) > -1
  }

  get hoursErrorMapping() {
    return this.hours.map(hour => {
      return this.isInvalidStartEndTime(hour) === true
    })
  }

  isInvalidStartEndTime(hours) {
    if (!hours?.startTime || !hours?.endTime) {
      return true
    }
    const midnight = moment('00:00:00', 'HH:mm:ss')
    const startDateTime = moment(hours.startTime, 'hh:mm:ss A')
    const endDateTime = moment(hours.endTime, 'hh:mm:ss A')
    /**
     * 1. All day:- start time end time selected 12:00 AM to 12:00 AM
     * 2. To Support
     * - 12:00 PM to 12:00 AM [Night]
     * - 08:00 AM [Morning] to 12:00 AM [Night]
     *  [ startDateTime.isSame(midnight) should be removed ]
     */
    if (startDateTime.isSame(midnight) && endDateTime.isSame(midnight)) {
      return false // start time end time selected as 12:00 AM
    }

    return startDateTime.isAfter(endDateTime)
  }
}
