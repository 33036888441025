import ServiceMenu from '@/models/service_menu'
import { drop } from 'lodash'
import { ref } from 'vue'
import AppState from '../../states/app'

export class ServiceMenuList {
  filters = ref({
    searchText: '',
    page: 1,
  })

  limit = 10

  constructor() {
    //
  }

  get offet() {
    return (this.filters?.value.page - 1) * this.limit
  }

  get serviceMenusList(): ServiceMenu[] {
    return drop(this.filteredServiceMenus, this.offet)
      .slice(0, this.limit)
      .map(serviceMenu => {
        return serviceMenu
      })
  }

  get filteredServiceMenus() {
    return this.allServiceMenus.filter(team => {
      return (
        this.filters.value.searchText === '' ||
        team.name
          ?.toLowerCase()
          .includes(this.filters.value.searchText?.toLowerCase())
      )
    })
  }

  get allServiceMenus(): ServiceMenu[] {
    return AppState.globalServiceMenus?.serviceMenus || []
  }

  get nosTotalPages() {
    return Math.ceil(this.filteredServiceMenus.length / this.limit)
  }
}
