import AdvancedCalendarSetup from '@/pages/AdvancedCalendarSetup.vue'
import Availability from '@/pages/Availability.vue'
import CalendarPreferences from '@/pages/CalendarPreferences.vue'
import Connections from '@/pages/Connections.vue'
import CalendarSettings from '@/pages/NewCalendarSettings.vue'
import { createRouter, createWebHistory } from 'vue-router'
import ServiceMenuSetup from '../pages/ServiceMenuSetup.vue'

const routes = [
  {
    path: '/appt-modal',
    name: 'appt-modal',
    component: () =>
      import('../components/AppointmentModal/AppointmentModal.vue'),
    children: [
      {
        path: '/appt-modal/select-contact',
        name: 'select-contact',
        component: () =>
          import('../components/AppointmentModal/SelectContact.vue'),
      },

      {
        path: '/appt-modal/create-contact',
        name: 'create-contact',
        component: () =>
          import('../components/AppointmentModal/CreateNewContact.vue'),
      },
      {
        path: '/appt-modal/create-appt',
        name: 'create-appt',
        component: () =>
          import('../components/AppointmentModal/CreateAppointment.vue'),
      },
    ],
  },
  {
    path: '/popover',
    name: 'popover',
    component: () => import('../components/Popover/Popover.vue'),
  },
  {
    path: '/v2/location/:locationId/settings/calendars',
    name: 'calendar-settings__default',
    component: CalendarSettings,
  },
  {
    path: '/v2/location/:locationId/settings/calendars/preferences',
    name: 'calendar-settings__preferences',
    component: CalendarPreferences,
  },
  {
    path: '/v2/location/:locationId/settings/calendars/availability',
    name: 'calendar-settings__availability',
    component: Availability,
  },
  {
    path: '/v2/location/:locationId/settings/calendars/connections',
    name: 'calendar-settings__connections',
    component: Connections,
  },
  {
    path: '/calendars-advanced/:calendarId',
    name: 'calendar-settings__advanced',
    component: AdvancedCalendarSetup,
  },
  {
    path: '/activity-log',
    name: 'activity-log',
    component: () => import('../components/ActivityLog/ActivityLog.vue'),
  },
  {
    path: '/v2/location/:locationId/calendars/appointments',
    name: 'appointments-list',
    component: () =>
      import('../components/Appointment/AppointmentListTable.vue'),
  },
  {
    path: '/service-menu-advanced/:serviceMenuId',
    name: 'service-menu__advanced',
    component: ServiceMenuSetup,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
