<script setup lang="ts">
import { CalendarServiceMenuService } from '@/class/services/CalendarServiceMenuService'
import DeleteConfirmation from '@/components/common/DeleteConfirmation.vue'
import GeneralConfirmation from '@/components/common/GeneralConfirmation.vue'
import router from '@/router'
import AppState, { getTargetTimezone } from '@/states/app'
import { toggleAdvancedView } from '@/utils/commonModalFunctions'
import { copyToClipboard } from '@/utils/generalFunctions'
import {
  AlertHexagonIcon,
  CalendarCheck01Icon,
  Code01Icon,
  Link01Icon,
  Pencil02Icon,
  PlusIcon,
  Trash01Icon,
} from '@gohighlevel/ghl-icons/24/outline'
import {
  UIActionDropdown,
  UIEmpty,
  UITable,
  renderIcon,
  useNotification,
} from '@gohighlevel/ghl-ui'
import moment from 'moment-timezone'
import { h, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { ServiceMenuList } from '../../class/pages/ServiceMenuList'
import { getServiceMenusInLocation } from '../../states/app'

const { t } = useI18n()

// Define reactive variables
const selectedServiceMenu = ref()
const showDeleteConfirmationModal = ref(false)
const showDeactivateConfirmationModal = ref(false)
const isProcessing = ref(false)
const serviceMenuList = new ServiceMenuList()
const notification = useNotification()

// Define options for table actions
const tableActionOptionsforActiveServiceMenus = [
  {
    label: t('settings.calendar.edit'),
    key: 'edit',
    icon: renderIcon(Pencil02Icon),
  },
  {
    label: t('settings.calendar.copy_embed_code'),
    key: 'copy_embed_code',
    icon: renderIcon(Code01Icon),
  },
  {
    label: t('settings.calendar.copy_permanent_link'),
    key: 'copy_permanent_link',
    icon: renderIcon(Link01Icon),
  },
  {
    label: t('settings.calendar.copy_new_slug_link'),
    key: 'copy_scheduling_link_new',
    icon: renderIcon(Link01Icon),
  },
  {
    label: t('settings.calendar.deactivate_service_menu'),
    key: 'toggle_service_menu',
    icon: renderIcon(AlertHexagonIcon),
  },
  {
    label: t('settings.calendar.delete_group'),
    key: 'delete_service_menu',
    icon: renderIcon(Trash01Icon),
  },
]

// Create a modified list of actions for disabled service menus
const tableActionsOptionsForDisabledServiceMenu =
  tableActionOptionsforActiveServiceMenus.filter(x => x)

tableActionsOptionsForDisabledServiceMenu[4] = {
  label: 'Activate Service Menu',
  key: 'toggle_service_menu',
  icon: renderIcon(AlertHexagonIcon),
}

const targetTimezone = getTargetTimezone()

// Define table columns
const columns = [
  {
    title: t('service_menu_advanced.service_menu_name'),
    key: 'name',
  },
  {
    title: t('settings.calendar.date_updated'),
    key: 'dateUpdated',
    render(serviceMenu) {
      // Render date and time in a specific format
      return h('div', [
        h(
          'h5',
          {
            class: 'm-0 text-sm font-normal	',
          },
          moment(serviceMenu.dateUpdated)
            .tz(targetTimezone)
            .format('MMM DD YYYY')
        ),
        h(
          'h6',
          {
            class: 'm-0 text-xs font-normal text-gray-300',
          },
          moment(serviceMenu.dateUpdated).tz(targetTimezone).format('hh mm A')
        ),
      ])
    },
  },
  {
    title: t('settings.calendar.status'),
    key: 'isActive',
    render(serviceMenu) {
      // Render the status (active or draft) based on isActive property
      return h(
        'span',
        {
          class: 'inline-flex rounded-full px-2 text-xs leading-5',
        },
        serviceMenu.isActive
          ? t('settings.calendar.active')
          : t('settings.calendar.draft')
      )
    },
  },
  {
    title: t('settings.calendar.action_dropdown'),
    key: 'actions',
    render(row) {
      // Render the action dropdown menu for each row
      return h(
        UIActionDropdown,
        {
          options: row.isActive
            ? tableActionOptionsforActiveServiceMenus
            : tableActionsOptionsForDisabledServiceMenu,
          onSelect: val => dropDownActionChange(val, row),
          id: 'service-menu-' + row.id,
        },
        {}
      )
    },
  },
]

// Define the action to be taken when a dropdown item is selected
const dropDownActionChange = (action, team) => {
  if (action === 'edit') {
    toggleAdvancedView()

    // Navigate to the service menu advanced edit page
    router.push({
      name: 'service-menu__advanced',
      params: {
        serviceMenuId: team.id,
      },
    })
  } else if (action === 'copy_embed_code') {
    // Copy embed code to clipboard and show a notification
    const embedUrl = getEmbedCodeForTeam(team.id)

    if (embedUrl) {
      copyToClipboard(embedUrl)
      notification.info({
        content: 'Copied to clipboard',
        duration: 1 * 1000,
      })
    }
  } else if (
    action === 'copy_scheduling_link' ||
    action === 'copy_scheduling_link_new' ||
    action === 'copy_permanent_link'
  ) {
    let url

    if (action === 'copy_permanent_link') {
      url = getPermanentUrlForServiceMenu(team.id)
    } else if (action === 'copy_scheduling_link_new') {
      url = getWidgetUrlForServiceMenu(team.id)
    }

    if (url) {
      copyToClipboard(url)
      notification.info({
        content: 'Copied to clipboard',
        duration: 1 * 1000,
      })
    }
  } else if (action === 'delete_service_menu') {
    selectedServiceMenu.value = team
    showDeleteConfirmationModal.value = true
  } else if (action === 'toggle_service_menu') {
    selectedServiceMenu.value = team
    showDeactivateConfirmationModal.value = true
  }
}

// Function to disable or activate a service menu
const disableServiceMenu = async () => {
  if (!selectedServiceMenu.value) {
    return
  }
  isProcessing.value = true
  await CalendarServiceMenuService.updateStatus(
    selectedServiceMenu.value.id,
    !selectedServiceMenu.value.isActive
  )
  await getServiceMenusInLocation()
  closeModal()
}

// Function to get the widget URL for a service menu
const getWidgetUrlForServiceMenu = (serviceMenuId: string) => {
  const serviceMenu = serviceMenuList.allServiceMenus.find(
    serviceMenu => serviceMenu.id === serviceMenuId
  )
  if (serviceMenu) {
    return `${AppState.baseUrl}/widget/service-menus/${serviceMenu.slug}`
  }
}

// Function to get the permanent URL for a service menu
const getPermanentUrlForServiceMenu = (serviceMenuId: string) => {
  return `${AppState.baseUrl}/widget/service-menu/${serviceMenuId}`
}

// Function to get the embed code for a team
const getEmbedCodeForTeam = (serviceMenuId: string) => {
  const team = serviceMenuList.allServiceMenus.find(
    serviceMenu => serviceMenu.id === serviceMenuId
  )

  if (team) {
    const uniqueId = `${serviceMenuId}_${new Date().getTime()}`

    return `<iframe src="${getPermanentUrlForServiceMenu(
      serviceMenuId
    )}" style="width: 100%;border:none;overflow: hidden;" scrolling="no" id="${uniqueId}"><\/iframe><br><script src="${
      AppState.embedUrl
    }/form_embed.js" type="text/javascript"><\/script>`
  }
}

// Function to close modals and reset values
const closeModal = () => {
  selectedServiceMenu.value = {}
  showDeleteConfirmationModal.value = false
  showDeactivateConfirmationModal.value = false
  isProcessing.value = false
}

// Function to delete a service menu
const deleteServiceMenu = async () => {
  if (!selectedServiceMenu.value) {
    return
  }

  isProcessing.value = true
  await CalendarServiceMenuService.delete(selectedServiceMenu.value.id)

  await getServiceMenusInLocation()
  closeModal()
}

// Function to open the service menu advanced edit page for creating a new menu
const openServiceMenu = () => {
  router.push({
    name: 'service-menu__advanced',
    params: {
      serviceMenuId: 'create',
    },
  })
}
</script>

<template>
  <div class="service-menus-list-table">
    <UITable
      id="service-menus-list-table"
      v-model:searchText="serviceMenuList.filters.value.searchText"
      :search-input-placeholder="$t('service_menu_advanced.service_menu_name')"
      :columns="columns"
      :data="serviceMenuList.serviceMenusList"
      :page-count="serviceMenuList.nosTotalPages"
      :loading="AppState.isLoading || AppState.loadingAssets.serviceMenus"
      @update:page="x => (serviceMenuList.filters.value.page = x)"
    >
      <template #empty>
        <UIEmpty
          v-if="serviceMenuList.allServiceMenus.length === 0"
          :title="$t('settings.calendar.no_service_menus_in_location_title')"
          :description="$t('settings.calendar.no_service_menus_in_location')"
          :positive-text="$t('settings.calendar.add_new_service_menu')"
          :icon="CalendarCheck01Icon"
          :positive-icon="PlusIcon"
          @positiveClick="openServiceMenu"
        >
        </UIEmpty>

        <UIEmpty
          v-else
          :title="$t('settings.calendar.no_service_menu_found')"
          :description="$t('settings.calendar.no_service_menu_found_desc')"
        >
        </UIEmpty>
      </template>
    </UITable>

    <DeleteConfirmation
      v-model:show="showDeleteConfirmationModal"
      :disabled-confirm="isProcessing"
      :header-title="$t('settings.calendar.delete_service_menu')"
      @onConfirm="deleteServiceMenu"
      @onModalClose="closeModal"
    >
      <template #message>
        <p class="text-sm leading-5 text-gray-500">
          {{ $t('settings.calendar.delete_service_menu_confirmation') }}
        </p>
      </template>
    </DeleteConfirmation>

    <GeneralConfirmation
      v-model:show="showDeactivateConfirmationModal"
      :disabled-confirm="isProcessing"
      :header-title="
        $t('settings.calendar.service_menu_status_message_title', {
          status: selectedServiceMenu?.isActive ? 'Deactivate' : 'Activate',
        })
      "
      @onConfirm="disableServiceMenu"
      @onModalClose="closeModal"
    >
      <template #message>
        <p class="text-sm leading-5 text-gray-500">
          {{
            t('settings.calendar.service_menu_status_message', {
              status: selectedServiceMenu?.isActive ? 'deactivate' : 'activate',
            })
          }}
        </p>
      </template>
    </GeneralConfirmation>
  </div>
</template>

<style scoped>
.service-menus-list-table .hl-empty {
  max-width: 420px;
}
</style>
