<script lang="ts">
import {
  AllExtensions,
  HardBreak,
  RequiredExtensions,
  UIRichTextEditor,
} from '@gohighlevel/ghl-text-editor'
import { UIContentWrap } from '@gohighlevel/ghl-ui'
import { CharacterCount } from '@tiptap/extension-character-count'
import { History } from '@tiptap/extension-history'
import { Editor, Extension } from '@tiptap/vue-3'
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import StyleMenu from './StyleMenu.vue'

export default defineComponent({
  name: 'RichTextEditor',
  components: {
    StyleMenu,
    UIRichTextEditor,
    UIContentWrap,
  },
  props: {
    defaultValue: {
      type: String,
      default: '',
    },
    toolbarOptions: {
      type: Array,
      default: () => [
        'customVariablePicker',
        'styleBasic',
        'styleAdvanced',
        'image',
        'link',
        'emoji',
        'sourceCode',
        'textWrap',
      ],
    },
    placeholder: {
      type: String,
      default: '',
    },
    allExtensions: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update', 'editorMounted'],
  setup(props, { emit }) {
    const { locale } = useI18n()

    const extensions: Extension[] = [History, CharacterCount.configure()]

    if (props.allExtensions) {
      extensions.push(
        AllExtensions.configure({
          image: { inline: true },
          placeholder: {
            placeholder: props.placeholder
              ? props.placeholder
              : 'Type a message',
            showForAllNodes: false,
          },
          divTag: {
            inline: false,
            preserveWhitespace: false,
          },
          preTag: {
            inline: false,
            preserveWhitespace: false,
          },
          textAlignment: { types: ['heading', 'paragraph'] },
          paragraph: {
            HTMLAttributes: {
              style: 'margin:0px',
            },
          },
        })
      )
    } else {
      extensions.push(RequiredExtensions)
      extensions.push(HardBreak as any)
    }

    const editor = new Editor({
      content: props?.defaultValue || '',
      parseOptions: {
        preserveWhitespace: 'full',
      },
      extensions,
      onUpdate({ editor }) {
        emit(
          'update',
          {
            content: editor.getText(),
            HTML: editor.getHTML(),
          },
          editor
        )
      },
      onCreate({ editor }) {
        emit(
          'editorMounted',
          {
            editor,
          },
          editor
        )
      },
    })
    return {
      editor,
      locale,
      props,
    }
  },
})
</script>

<template>
  <UIContentWrap :locale="locale" full-screen class="border-gray-300">
    <StyleMenu :editor="editor" :toolbar-options="toolbarOptions" />
    <UIRichTextEditor
      :editor="editor"
      container-class="text-black ghl-notification-text-editor"
    >
    </UIRichTextEditor>
    <div v-if="editor" class="border-t border-gray-300 px-4 py-2 text-right">
      {{ editor.storage.characterCount.characters() }} characters |
      {{ editor.storage.characterCount.words() }} words
    </div>
  </UIContentWrap>
</template>

<!-- ! IF this is scoped, styles don't apply to the text editor -->
<style>
.ghl-notification-text-editor {
  border: 0 !important;
  padding: 0 !important;
  @apply rounded-none !important;
}

.ghl-notification-text-editor .editor-wrapper {
  padding: 8px;
  @apply border-r-0 !important;
  /* @apply border-x-0; */
  @apply border border-transparent;
  transition: background-color 0.3s var(--n-bezier);
}

.ghl-notification-text-editor .ProseMirror {
  font-size: 14px;
}

.ghl-notification-text-editor .ProseMirror .is-empty {
  font-size: 14px;
}

.ghl-notification-text-editor .ProseMirror {
  @apply h-48;
  @apply overflow-y-auto;
  @apply overflow-x-auto;
  resize: vertical;
}

.ghl-notification-text-editor .ProseMirror p {
  margin: 0;
}
</style>
