import { CalendarType } from '../models/calendar'

const quickTips = {
  'meeting-details': [
    {
      content:
        "To include both the contact's name and the appointment user's name in the meeting title, use the following format: {{contact.name}} X {{appointment.user.name}}",
    },
    {
      content:
        'To ensure uninterrupted access, add a custom meeting URL as a backup option in case there are any issues with Zoom or Gmeet integrations.',
    },
    {
      content:
        "To enable Gmeet functionality, make sure the team member's Google Calendar is set as their primary calendar in the settings.",
      includeCalendarType: [CalendarType.ROUND_ROBIN],
    },
  ],
  availability: [
    {
      content:
        'To make the calendar available 24 hours, set the time range from 12 AM to 12 AM.',
      excludeCalendarType: [CalendarType.SERVICE],
    },
    {
      content:
        'Time zones can be changed anytime under Business Profile >> Time Zone preferences',
      excludeCalendarType: [CalendarType.SERVICE],
    },
    {
      content:
        'Set your Service Calendar availability, with a default 15-minute slot interval between appointments.',
      includeCalendarType: [CalendarType.SERVICE],
    },
  ],
  forms: [
    {
      content: `Easily create custom forms in the Marketing section under ""Forms"" and seamlessly integrate them into calendars for organized data collection.`,
    },
    {
      content: `When you add a custom form, the sticky contacts settings linked to the form will be applied automatically.`,
    },
    {
      content: `You have the option to include custom values and fields of contacts in both the form message and the form URL.`,
    },
  ],
  notifications: [
    {
      content: `For advanced notifications, use Workflows or Appointment Booked Triggers.`,
    },
  ],
  customizations: [
    {
      content:
        'To change the language settings of widget follow these steps Settings >> Calendars >> Preferences >> Account Preferences',
    },
  ],
  equipments: [
    {
      content:
        "A 'room' can represent any location where services are provided. This could be physical rooms and/or dedicated stations. Eg: Pedicure Station",
    },
    {
      content: `Think of 'equipment' as any tool or device that businesses use to fulfill appointments. This can include machines, devices, or even chairs.`,
    },
  ],
}

export const getQuickTip = (key: string, calendarType: string) => {
  if (!quickTips[key]) {
    key = 'meeting-details'
  }

  const availableTips = quickTips[key].filter(tip => {
    if (tip.includeCalendarType) {
      if (tip.includeCalendarType.includes(calendarType)) {
        return true
      }
      return false
    }
    if (tip.excludeCalendarType) {
      if (!tip.excludeCalendarType.includes(calendarType)) {
        return true
      }
      return false
    }
    return true
  })

  const quickTipsLength = availableTips.length
  const randomIndex = Math.floor(Math.random() * quickTipsLength)
  return availableTips[randomIndex]?.content
}
