<script setup lang="ts">
import ConnectionsPage from '@/components/CalendarSetup/ConnectionsPage.vue'
import ExtrasPage from '@/components/CalendarSetup/ExtrasPage.vue'
import FormsPage from '@/components/CalendarSetup/FormsPage.vue'
import MeetingDetailsPage from '@/components/CalendarSetup/MeetingDetailsPage.vue'
import NewAvailabilityPage from '@/components/CalendarSetup/NewAvailabilityPage.vue'
import NotificationsPage from '@/components/CalendarSetup/NotificationsPage.vue'
import NotificationsPageV2 from '@/components/CalendarSetup/NotificationsPageV2.vue'
import OldAvailabilityPage from '@/components/CalendarSetup/OldAvailabilityPage.vue'
import { useI18n } from 'vue-i18n'

import NavLink from '@/components/CalendarSetup/NavLink.vue'

import { Copy04Icon } from '@gohighlevel/ghl-icons/24/outline'
import { UIButton, useNotification } from '@gohighlevel/ghl-ui'
import { LightBulbIcon } from '@heroicons/vue/outline'
import _ from 'lodash'
import { computed, reactive, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useToast } from 'vue-toastification'
import { CalendarResourceService } from '../class/services/CalendarResourceService'
import { CalendarService } from '../class/services/CalendarService'
import RoomsAndEquipmentsPage from '../components/CalendarSetup/RoomsAndEquipmentsPage.vue'
import Calendar, { CalendarType } from '../models/calendar'
import { CALENDAR_RESOURCE_TYPES } from '../models/calendar_resource'
import router from '../router'
import AppState, {
  getCustomValues,
  getEditingCalendar,
  getEquipmentsInLocation,
  getMerchantInLocation,
  getRoomsInLocation,
  getTeamsInLocation,
  isEquipmentEnabled,
  isRoomEnabled,
  setIsAdvancedCalendarPageLoading,
} from '../states/app'
import { toggleAdvancedView } from '../utils/commonModalFunctions'
import { copyToClipboard } from '../utils/generalFunctions'
import { getQuickTip } from '../utils/quickTips'

const toast = useToast()

const meetingDetailsPage = ref()
const availabilityPage = ref()
const formPage = ref()
const notificationsPage = ref()
const extrasPage = ref()
const connectionsPage = ref()
const roomsPage = ref()
const showCalendarResourcesTab = ref(false)
const route = useRoute()

const { t } = useI18n()

const state = reactive({
  isLoading: false,
  isSaving: false,
  currentStep: 0,
  formData: {
    name: '',
    officeHours: [],
    eventColor: '#D50000',
    teamMembers: [],
    group: '',
    appoinmentPerSlot: '1',
    description: '',
    slotDuration: '30',
    slug: '',
    fileList: [],
    slotDurationUnit: 'mins',
    stripe: {
      amount: '0',
      currency: 'USD',
      chargeDescription: '',
    },
  },
})

const editingCalendar = computed(() => {
  return AppState.editingCalendar as Calendar
})

const isDateOverrideEnabled = computed(() => AppState.isDateOverrideEnabled)

const methods = {
  async loadData() {
    setIsAdvancedCalendarPageLoading(true)
    await Promise.all([
      methods.loadCalendar(),
      methods.loadGroups(),
      getMerchantInLocation(),
      getCustomValues(),
    ])
    setIsAdvancedCalendarPageLoading(false)
  },
  async loadCalendar() {
    const calendarId = route.params.calendarId as string
    getEditingCalendar(calendarId)
  },
  async loadGroups() {
    await getTeamsInLocation()
  },
  handleCancel() {
    AppState.editingCalendar = null
    toggleAdvancedView()

    router.push({
      name: 'calendar-settings__default',
      params: {
        locationId: AppState.locationId,
      },
    })
  },
  async handleSave() {
    state.isSaving = true

    const meetingDetailsPageData =
      await meetingDetailsPage.value.methods.emitSave()

    const availabilityPageData = await availabilityPage.value.methods.emitSave()

    const formPageData = await formPage.value.methods.emitSave()

    const notificationsPageData =
      await notificationsPage.value.methods.emitSave()

    const connectionsPageData = await connectionsPage.value.methods.emitSave()

    const extrasPageData = await extrasPage.value.methods.emitSave()

    const roomsPageData = showCalendarResourcesTab.value
      ? await roomsPage.value.methods.emitSave()
      : null

    if (!meetingDetailsPageData) {
      state.currentStep = 0
      state.isSaving = false
      return
    } else if (!availabilityPageData) {
      state.currentStep = 1
      state.isSaving = false
      return
    } else if (!formPageData) {
      state.currentStep = 2
      state.isSaving = false
      return
    } else if (!notificationsPageData) {
      state.currentStep = 3
      state.isSaving = false
      return
    } else if (!connectionsPageData) {
      state.currentStep = 4
      state.isSaving = false
      return
    } else if (!extrasPageData) {
      state.currentStep = 5
      state.isSaving = false
      return
    } else if (!roomsPageData && showCalendarResourcesTab.value) {
      state.currentStep = 6
      state.isSaving = false
    }

    const payload = {
      ...meetingDetailsPageData,
      ...availabilityPageData,
      ...formPageData,
      ...notificationsPageData,
      ...connectionsPageData,
      ...extrasPageData,
    }
    if (formPageData.widgetConfig?.pageOrder) {
      payload.widgetConfig.pageOrder = formPageData.widgetConfig?.pageOrder
    }
    if (roomsPageData && showCalendarResourcesTab.value) {
      await methods.updateRoomsAndEquipments(roomsPageData)
    }

    if (editingCalendar.value.calendarType !== CalendarType.EVENT) {
      if (payload.linkedCalendars?.google?.id) {
        CalendarService.unlinkGoogleCalendar(editingCalendar.value.id as string)
      }
      payload.linkedCalendars = {}
    }

    if (editingCalendar.value?.isServiceCalendar) {
      payload.appoinmentPerSlot = meetingDetailsPageData.teamMembers?.length
    }
    if (
      connectionsPageData?.linkedCalendars?.google?.id &&
      editingCalendar.value.linkedCalendars?.google?.id &&
      editingCalendar.value.linkedCalendars?.google?.id !==
        connectionsPageData?.linkedCalendars?.google?.id
    ) {
      await CalendarService.unlinkGoogleCalendar(
        editingCalendar.value.id as string
      )
    }

    await methods.updateCalendar(payload, state.currentStep)
    if (
      !connectionsPageData?.linkedCalendars?.google?.id &&
      editingCalendar.value.linkedCalendars?.google?.id
    ) {
      await CalendarService.unlinkGoogleCalendar(
        editingCalendar.value.id as string
      )
    } else if (
      connectionsPageData?.linkedCalendars?.google?.id &&
      editingCalendar.value.linkedCalendars?.google?.id !==
        connectionsPageData?.linkedCalendars?.google?.id
    ) {
      CalendarService.linkGoogleCalendar(editingCalendar.value.id as string)
    }
  },
  async updateCalendar(data, currentStep) {
    try {
      // remove 'availabilityType' as its no longer needed delete delete
      delete data.availabilityType

      await CalendarService.update(editingCalendar.value.id as string, data)
      if (data?.reserveService && AppState.merchant?.merchant_id) {
        const servicePayload = {
          calender_id: editingCalendar.value.id,
          merchant_id: AppState.merchant?.merchant_id,
          home_service_data: {
            category_type: data.homeServiceData?.categoryType,
            job_type: data.homeServiceData?.jobType,
          },
        } as any
        if (data?.price && data.price.priceMicros >= 0) {
          servicePayload.price = {
            currency_code: data.price?.currencyCode || 'USD',
            price_micros: data.price?.priceMicros || 0,
          }
        }
        await CalendarService.createService(servicePayload)
      } else {
        await CalendarService.deleteReserveService(editingCalendar.value.id)
      }
      if (state.currentStep === currentStep) {
        debouncedCalendarRefresh()
      }

      // if (state.currentStep === 5) {
      //   toggleAdvancedView()

      //   router.push({
      //     name: 'calendar-settings__default',
      //     params: {
      //       locationId: AppState.locationId,
      //     },
      //   })
      // }

      // state.isSaving = false
      // state.currentStep += 1
    } catch (error: any) {
      toast.error(error?.response?.data?.message || 'Something went wrong')
      state.isSaving = false
    }
  },
  async refreshCalendar() {
    await getEditingCalendar(editingCalendar.value.id)

    if (
      (state.currentStep === 6 && showCalendarResourcesTab.value) ||
      (state.currentStep === 5 && !showCalendarResourcesTab.value)
    ) {
      AppState.editingCalendar = null
      toggleAdvancedView()

      router.push({
        name: 'calendar-settings__default',
        params: {
          locationId: AppState.locationId,
        },
      })
    }

    state.isSaving = false

    if (
      state.currentStep === 3 &&
      !AppState.editCalendarValidationData.haveMerchant &&
      editingCalendar.value?.calendarType !== CalendarType.EVENT
    ) {
      state.currentStep += 2
    } else {
      state.currentStep += 1
    }
  },
  async updateRoomsAndEquipments(roomsAndEquipments) {
    const calendarId = editingCalendar.value.id
    const {
      selectedEquipments,
      selectedRooms,
      toRemoveEquipments,
      toRemoveRooms,
    } = roomsAndEquipments
    const allRooms: any[] = AppState.globalRooms
    const allEquipments: any[] = AppState.globalEquipments
    const roomsToUpdate: any[] = [],
      equipmentsToUpdate: any[] = []
    for (const room of allRooms) {
      if (
        !room?.calendarIds?.includes(calendarId) &&
        selectedRooms?.includes(room._id)
      ) {
        roomsToUpdate.push(room._id)
      }
    }
    const promises: any[] = []
    for (const toUpdateRoom of roomsToUpdate) {
      promises.push(
        CalendarResourceService.update(
          toUpdateRoom,
          { calendarIds: [calendarId] },
          CALENDAR_RESOURCE_TYPES.ROOMS
        )
      )
    }
    for (const removeRoom of toRemoveRooms) {
      if (
        allRooms
          ?.find((e: any) => e._id === removeRoom)
          ?.calendarIds?.includes(calendarId)
      ) {
        promises.push(
          CalendarResourceService.update(
            removeRoom,
            { toDeleteCalendarIds: [calendarId] },
            CALENDAR_RESOURCE_TYPES.ROOMS
          )
        )
      }
    }
    for (const equipment of allEquipments) {
      if (
        !equipment?.calendarIds?.includes(calendarId) &&
        selectedEquipments?.includes(equipment._id)
      ) {
        equipmentsToUpdate.push(equipment._id)
      }
    }
    for (const toUpdateEquipment of equipmentsToUpdate) {
      promises.push(
        CalendarResourceService.update(
          toUpdateEquipment,
          { calendarIds: [calendarId] },
          CALENDAR_RESOURCE_TYPES.EQUIPMENTS
        )
      )
    }
    for (const removeEquipment of toRemoveEquipments) {
      promises.push(
        CalendarResourceService.update(
          removeEquipment,
          { toDeleteCalendarIds: [calendarId] },
          CALENDAR_RESOURCE_TYPES.EQUIPMENTS
        )
      )
    }
    await Promise.all(promises)
    await Promise.all([getRoomsInLocation(), getEquipmentsInLocation()])
  },
}

const isServiceCalendar = computed(() => {
  return editingCalendar.value?.isServiceCalendar
})

const steps = computed(() => {
  return [
    {
      title: isServiceCalendar.value
        ? t('calendar_advanced.general.service_details')
        : t('calendar_advanced.general.meeting_details'),
      id: 'meeting-details',
    },
    {
      title: t('calendar_advanced.general.availability'),
      id: 'availability',
    },
    {
      title: t('calendar_advanced.general.forms_and_payment'),
      id: 'forms',
    },
    {
      title: t(
        'calendar_advanced.general.notifications_and_additional_options'
      ),
      id: 'notifications',
    },
    {
      title: t('calendar_advanced.general.connections'),
      id: 'connections',
    },
    {
      title: t('calendar_advanced.general.customizations'),
      id: 'customizations',
    },
  ]
})

watch(
  () => editingCalendar.value,
  newValue => {
    if (
      (isRoomEnabled() || isEquipmentEnabled()) &&
      newValue?.isServiceCalendar
    ) {
      steps.value.push({
        title: t('calendar_advanced.general.equipments'),
        id: 'equipments',
      })
      showCalendarResourcesTab.value = true
    }
  }
)
const notification = useNotification()
function copyCalendarId(id) {
  copyToClipboard(id)
  notification.info({
    content: 'Copied to clipboard',
    duration: 1 * 1000,
  })
}

methods.loadData()
const debouncedCalendarRefresh = _.debounce(methods.refreshCalendar, 2000)
</script>

<template>
  <div class="flex w-full flex-col justify-center">
    <div
      class="mb-7 flex w-full items-center justify-between rounded-sm px-5 py-3 text-sm shadow-md"
    >
      <div class="pl-3 text-2xl">
        {{
          route.query.isNew
            ? $t('calendar_advanced.general.create')
            : $t('calendar_advanced.general.edit')
        }}
        -
        {{ editingCalendar?.name }}
        <div class="text-sm font-normal text-gray-400">
          <UIButton
            id=""
            class="w-3/4 cursor-pointer text-gray-700"
            :text="true"
            @click="copyCalendarId(editingCalendar.id)"
          >
            <div class="flex flex-row items-center gap-1">
              Calendar Id: {{ editingCalendar?.id }}
              <Copy04Icon class="h-3 w-3" />
            </div>
          </UIButton>
        </div>
      </div>

      <div class="flex space-x-3">
        <UIButton
          id="create-calendar-close"
          type="default"
          @click="methods.handleCancel"
        >
          {{ $t('calendar_advanced.general.close') }}
        </UIButton>
        <UIButton
          id="create-calendar-save"
          type="primary"
          :loading="state.isSaving"
          @click="methods.handleSave"
        >
          {{ $t('calendar_advanced.general.save') }}
        </UIButton>
      </div>
    </div>

    <div class="flex w-full justify-center space-x-14 p-3">
      <div class="flex w-40 flex-col space-y-3">
        <NavLink
          v-for="(step, ind) in steps"
          :key="step.title"
          :class="{
            hidden:
              step.id === 'connections' &&
              !AppState.editCalendarValidationData?.haveMerchant &&
              editingCalendar?.calendarType !== CalendarType.EVENT,
          }"
          :is-active="state.currentStep === ind"
          :text="step.title"
          @click="() => (state.currentStep = ind)"
        />

        <div class="flex flex-col space-y-2 pt-10">
          <div class="flex items-center space-x-2">
            <LightBulbIcon class="h-6 w-6 text-gray-400" />
            <div class="text-md font-medium text-gray-400">
              {{ $t('calendar_advanced.general.quick_tip') }}
            </div>
          </div>
          <div class="pl-1 text-xs text-gray-400">
            {{
              getQuickTip(
                steps[state.currentStep]?.id,
                editingCalendar?.calendarType
              )
            }}
          </div>
        </div>
      </div>

      <div
        class="max-h-[calc(100vh-10rem)] max-w-4xl flex-grow overflow-y-auto rounded-xl border bg-white p-10 shadow-md"
        :class="{
          'pointer-events-none blur-md filter':
            AppState.isAdvancedCalendarPageLoading,
        }"
      >
        <div
          :class="{
            hidden: state.currentStep !== 0,
          }"
        >
          <MeetingDetailsPage
            ref="meetingDetailsPage"
            @on-save="data => methods.updateCalendar(data, 0)"
          />
        </div>

        <div
          :class="{
            hidden: state.currentStep !== 1,
          }"
        >
          <NewAvailabilityPage
            v-if="isDateOverrideEnabled"
            ref="availabilityPage"
            @on-save="data => methods.updateCalendar(data, 1)"
          />
          <OldAvailabilityPage
            v-else
            ref="availabilityPage"
            @on-save="data => methods.updateCalendar(data, 1)"
          />
        </div>

        <div
          :class="{
            hidden: state.currentStep !== 2,
          }"
        >
          <FormsPage
            ref="formPage"
            @on-save="data => methods.updateCalendar(data, 2)"
          />
        </div>

        <div
          :class="{
            hidden: state.currentStep !== 3,
          }"
        >
          <NotificationsPageV2
            v-if="AppState.isNewNotificationsEnabled"
            ref="notificationsPage"
            @on-save="data => methods.updateCalendar(data, 3)"
          />
          <NotificationsPage
            v-else
            ref="notificationsPage"
            @on-save="data => methods.updateCalendar(data, 3)"
          />
        </div>

        <div
          :class="{
            hidden: state.currentStep !== 4,
          }"
        >
          <ConnectionsPage
            ref="connectionsPage"
            @on-save="data => methods.updateCalendar(data, 4)"
          />
        </div>

        <div
          :class="{
            hidden: state.currentStep !== 5,
          }"
        >
          <ExtrasPage
            ref="extrasPage"
            @on-save="data => methods.updateCalendar(data, 5)"
          />
        </div>

        <div
          v-if="showCalendarResourcesTab"
          :class="{ hidden: state.currentStep !== 6 }"
        >
          <RoomsAndEquipmentsPage
            ref="roomsPage"
            @on-save="data => methods.updateCalendar(data, 6)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
