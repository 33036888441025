interface Menu {
  groupId?: string
  calendarsIds?: string[]
  sortOrder?: number
}

export default class ServiceMenu {
  private data: any = {}

  constructor(data: any) {
    this.data = data
  }

  get id(): string {
    return this.data.id
  }

  get name(): string {
    return this.data.name
  }

  get description(): string {
    return this.data.description
  }

  get dateUpdated(): string {
    return this.data.dateUpdated
  }

  get slug(): string {
    return this.data.slug
  }

  get menu(): Menu[] {
    return this.data.menu
  }

  get formSubmitType(): string {
    return this.data.formSubmitType
  }

  get formSubmitRedirectUrl(): string {
    return this.data.formSubmitRedirectUrl
  }

  get formSubmitThanksMessage(): string {
    return this.data?.formSubmitThanksMessage
  }
  get enableConsentCheck(): string {
    return this.data?.enableConsentCheck
  }

  get consentLabel(): string {
    return this.data.consentLabel
  }

  get enableAddGuests() {
    return this.data?.enableAddGuests ?? true
  }
  get enableAddMultipleServices() {
    return this.data?.enableAddMultipleServices ?? true
  }

  get enableStaffSelection() {
    return this.data?.enableStaffSelection ?? true
  }

  get formId(): string {
    return this.data?.formId
  }

  get codeBlock(): string {
    return this.data.codeBlock
  }
}
