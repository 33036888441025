<script setup lang="ts">
import AppState from '@/states/app'
import {
  UIForm,
  UIFormItem,
  UIInput,
  UIInputNumber,
  UIModal,
  UIModalContent,
  UIModalFooter,
  UIModalHeader,
  UISelect,
  useNotification
} from '@gohighlevel/ghl-ui'
import pickBy from 'lodash/pickBy'

import { computed, ref, watch } from 'vue'
import { ResourceList } from '../../class/pages/ResourceList'
import { CalendarResourceService } from '../../class/services/CalendarResourceService'
import { CalendarType } from '../../models/calendar'
import { CALENDAR_RESOURCE_TYPES } from '../../models/calendar_resource'
import { getTooltip } from '../../utils/generalFunctions'

const emit = defineEmits([
  'closeModal',
  'createdRecord',
  'uploadCSV',
  'update:show',
])

const equipmentList = new ResourceList(CALENDAR_RESOURCE_TYPES.EQUIPMENTS)

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  editPayload: {
    type: Object,
    default: null,
  },
})

watch(
  () => props.show,
  newVal => {
    saving.value = false
    if (newVal) {
      formValue.value = {
        name: props.editPayload?.name,
        description: props.editPayload?.description,
        quantity: props.editPayload?.quantity,
        outOfService: props.editPayload?.outOfService,
        resourceType: CALENDAR_RESOURCE_TYPES.EQUIPMENTS,
      }
      selectedCalendars.value = props.editPayload?.calendarIds.map(e => e).filter(e => !equipmentList?.isCalendarDeleted(e)) || []
    }
  }
)

watch(
  () => props.editPayload,
  newVal => {
    selectedCalendars.value = newVal?.calendarIds.map(e => e).filter(e => !equipmentList?.isCalendarDeleted(e)) || []
  }
)

const rules = {
  name: {
    required: true,
    message: 'Equipment name is required',
    trigger: ['input', 'blur'],
  },
  quantity: {
    required: true,
    validator(rule, value) {
      const quantity = parseInt(value)
      if (!value && quantity !== 0) {
        return new Error(`Quantity is mandatory`)
      }
      if (quantity === 0) {
        return new Error(`Quantity cannot be zero`)
      }
      if (isNaN(quantity)) {
        throw new Error(`Quantity should be be a number`)
      }
      if (quantity < 0) {
        return new Error(`Quantity cannot be negative`)
      }
      if (quantity < formValue.value.outOfService) {
        return new Error(`Quantity cannot be less than out of service equipments`)
      }
      if (quantity && parseFloat(value) !== quantity) {
        return new Error(`Quantity should be a whole number`)
      }
      return true
    },
    trigger: ['input', 'blur'],
  },
  outOfService: {
    validator(_, value) {
      const outOfService = parseInt(value || 0)
      if (isNaN(outOfService)) {
        throw new Error(`Out of service value should be be a number`)
      }
      if (outOfService < 0) {
        return new Error(`Out of service cannot be negative`)
      }
      if (outOfService > formValue.value.quantity || 0) {
        return new Error(`Out of service equipments cannot be more than actual equipment Quantity`)
      }
      if (outOfService && parseFloat(value) !== outOfService) {
        return new Error(`Out of service should be a whole number`)
      }
      return true
    },
    trigger: ['input', 'blur'],
  }
}

const formValue = ref({
  name: '',
  description: '',
  quantity: 1,
  outOfService: 0,
  resourceType: CALENDAR_RESOURCE_TYPES.EQUIPMENTS,
})

const saving = ref(false)
const formRef = ref()
const selectedCalendars = ref([])


const calendars = computed(() => {
  return (AppState.globalCalendars as any)?.calendars
    ?.filter(calendar => calendar.calendarType === CalendarType.SERVICE && !calendar.deleted)
    .map((cal) => {
      return {
        value: cal.id,
        label: cal.name
      }
    })
})

function sanitizeObject(obj) {
  return pickBy(
    obj,
    value => value !== null && value !== undefined && value !== ''
  )
}

async function createEquipment(values) {
  try {
    saving.value = true
    const equipment = sanitizeObject(values)
    if (props.editPayload) {
      const toDeleteCalendarIds = props.editPayload?.calendarIds?.filter(e => {
        const _selectedCalendars: any = selectedCalendars.value?.map(i => i) || []
        return !_selectedCalendars.includes(e)
      })
      const equipmentObj = { ...equipment }
      if (selectedCalendars?.value?.length) {
        const existingCalendarIds =
          props.editPayload?.calendarIds || []
        equipmentObj.calendarIds =
          selectedCalendars?.value?.filter(calendarId => {
            return !existingCalendarIds?.includes(calendarId)
          })
      }
      equipmentObj.description = equipmentObj?.description || ''
      equipmentObj.outOfService = equipmentObj?.outOfService || 0
      if (toDeleteCalendarIds?.length) {
        equipmentObj.toDeleteCalendarIds = toDeleteCalendarIds
      }

      await CalendarResourceService.update(
        props.editPayload._id,
        equipmentObj,

        CALENDAR_RESOURCE_TYPES.EQUIPMENTS
      )
    } else {
      const _equipment: any = { ...equipment, locationId: AppState.locationId }
      if (selectedCalendars.value?.length) {
        _equipment.calendarIds =
          selectedCalendars?.value?.map(e => e)

      }
      await CalendarResourceService.create(
        _equipment,
        CALENDAR_RESOURCE_TYPES.EQUIPMENTS
      )
    }
    saving.value = false
    emit('createdRecord')
  } catch (err) {
    saving.value = false
    useNotification().error({
      duration: 1.5 * 1000,
      content: 'Something went wrong. Please try again.',
    })
  }
}

function handleValidateClick(e) {
  formRef.value
    .getForm()
    .validate()
    .then(async (value) => {
      await createEquipment(formValue.value)
      Object.keys(formValue.value).forEach(key => {
        formValue.value[key] = ''
      })
    })
    .catch(e => console.error('error', e))
}

</script>
<template>
  <UIModal :show="show" :width="585" @update:show="val => $emit('update:show', val)">
    <template #header>
      <UIModalHeader id="calendarGroupCreateModalHeader" type="success" :title="$t('settings.calendar.add_equipment')"
        @close="$emit('closeModal')" />
    </template>
    <UIForm id="calendarGroupCreateForm" ref="formRef" :model="formValue" :rules="rules"
      class="h-full w-full max-w-7xl sm:max-w-7xl">
      <p class="m-0 p-0 text-sm">
        {{ $t('settings.calendar.equipment_modal_description') }}
      </p>

      <UIModalContent>
        <p class="w-full border border-solid border-gray-200"></p>
        <div class="mt-6 grid grid-cols-2 gap-x-4">

          <div class="col-span-2">
            <UIFormItem :label="$t('settings.calendar.equipment_name')" path="name">
              <UIInput id="equipmentName" v-model="formValue.name"
                :placeholder="$t('settings.calendar.equipment_name')" />
            </UIFormItem>
          </div>

          <div class="col-span-2">
            <UIFormItem :label="$t('settings.calendar.equipment_description')" path="description">
              <UIInput id="equipmentDescription" v-model="formValue.description" type="textarea"
                :placeholder="$t('settings.calendar.equipment_description')" />
            </UIFormItem>
          </div>

          <div>
            <UIFormItem :label="$t('settings.calendar.equipment_quantity')" path="quantity"
              :tooltip="getTooltip(`Enter the total number of available equipment units`)">
              <UIInputNumber id="equipmentCapacity" v-model="formValue.quantity"
                :placeholder="$t('settings.calendar.equipment_quantity')" />
            </UIFormItem>
          </div>

          <div>
            <UIFormItem :label="$t('settings.calendar.equipment_outOfService')" path="outOfService"
              :tooltip="getTooltip(`Enter the number of equipment units that are currently non-functional, in need of repair, or out of service`)">
              <UIInputNumber id="equipmentOutOfService" v-model="formValue.outOfService"
                :placeholder="$t('settings.calendar.equipment_outOfService')" />
            </UIFormItem>
          </div>

          <div class="col-span-2">
            <UIFormItem :label="$t('settings.calendar.select_calendar_rooms_title')">
              <div class="flex-col flex  w-full">
                <div class="mb-3 text-xs text-gray-500">
                  {{ $t('settings.calendar.select_service_calendar_description_equipments') }}
                </div>
                <UISelect id="equipmentCalendars" v-model:value="selectedCalendars" :multiple="true" :filterable="true"
                  :options="calendars" />
              </div>
            </UIFormItem>
          </div>
        </div>
      </UIModalContent>
    </UIForm>
    <template #footer>
      <UIModalFooter id="affiliateCreateModalFooter" :positive-text="props.editPayload
        ? $t('settings.calendar.save')
        : $t('settings.calendar.create')" :negative-text="$t('settings.calendar.cancel')" :loading="saving"
        :disabled="saving" @positive-click="handleValidateClick" @negative-click="$emit('closeModal')">
      </UIModalFooter>
    </template>
  </UIModal>
</template>
