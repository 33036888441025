<script setup lang="ts">
import AccountConnections from '@/components/Settings/AccountConnections.vue'
import MyConnections from '@/components/Settings/MyConnections.vue'
import AppState from '@/states/app'
import { UIHeader, UITabPane, UITabs } from '@gohighlevel/ghl-ui'
import { computed, ref, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
const route = useRoute()

const tabType = ref(route.query.tab || 'my-connections')

const isUserPartOfLocation = computed(() => {
  return !!AppState?.isUserPartOfLocation
})

watchEffect(() => {
  if (!isUserPartOfLocation.value) {
    tabType.value = 'account-connections'
  }
})
</script>

<template>
  <div v-if="!AppState.isNewCalIntegrationEnabled" class="p-8">
    <UIHeader
      id="calendar-preferences-settings-headers"
      :title="$t('settings.connections.connections')"
    />

    <UITabs v-model:value="tabType" type="line">
      <UITabPane
        v-if="isUserPartOfLocation"
        name="my-connections"
        :tab="$t('settings.connections.my_connections')"
        class="mt-6"
      >
        <MyConnections />
      </UITabPane>

      <UITabPane
        name="account-connections"
        :tab="$t('settings.connections.account_connections')"
        class="mt-6"
      >
        <AccountConnections />
      </UITabPane>
    </UITabs>
  </div>
</template>
