<template>
  <div>
    <div class="mt-3 flex space-x-3 py-2">
      <UISwitch
        v-model:value="isNotificationsEnabled"
        class="w-1/3"
        size="medium"
        @update:value="
          value => {
            toggleNotification(value)
          }
        "
      >
      </UISwitch>
      <div class="items-center text-sm font-medium text-gray-700">
        {{
          isNotificationsEnabled
            ? t('eventNotification.email.disableEmailNotification')
            : t('eventNotification.email.enableEmailNotification')
        }}
      </div>
    </div>
    <div class="my-2 text-sm font-medium text-gray-500">
      {{ t('eventNotification.email.notificationLabel') }}
    </div>

    <!-- Render all emails template of NotificationType - confirmation,reminder and others -->
    <UITooltip :trigger="!isNotificationsEnabled ? 'hover' : 'manual'">
      <template #trigger>
        <div
          :class="{ 'disabled-div hover:bg-gray-300': !isNotificationsEnabled }"
        >
          <div
            v-for="(emailNotification, i) in state.eventNotifications"
            :key="'email-notification-' + i"
          >
            <!-- Render a single email configurator window -->
            <EmailTemplateConfigurator
              ref="emailTemplateConfigurator"
              :index="i"
              :class="{
                'rounded-t-lg border': i === 0,
                'rounded-b-lg border':
                  i === state.eventNotifications.length - 1,
              }"
              :notification="emailNotification"
              :is-notifications-enabled="isNotificationsEnabled"
            />
          </div>
        </div>
      </template>
      <div>{{ t('eventNotification.tooltip') }}</div>
    </UITooltip>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import {
  getEventNotificationState,
  eventNotificationState as state,
  updateNotificationState,
} from '@/states/EventNotificationState'
import EmailTemplateConfigurator from './EmailTemplateConfigurator.vue'
import { EventNotificationService } from '@/class/services/EventNotificationService'
import { UISwitch, UITooltip } from '@gohighlevel/ghl-ui'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
import { useNotify } from '@/components/composition/notification'
const { sendNotification } = useNotify()

const isNotificationsEnabled = ref(true)
const emailTemplateConfigurator = ref(null) as any
defineExpose({ validateAll })
const emit = defineEmits(['update-notification'])

isNotificationsEnabled.value = getEventNotificationState('email')
/**
 *
 * @param value boolean
 * enable/disable all the email notification under this
 */
async function toggleNotification(value) {
  const payload = state.eventNotifications.map(notification => {
    return { ...notification, ...{ deleted: !value } }
  })
  state.disableSave = true
  try {
    isNotificationsEnabled.value = value

    const payloadToUpdate = payload.filter(notification => notification._id)
    const payloadToCreate = payload
      .filter(notification => !notification._id)
      .map((notification: any) => {
        delete notification._id
        return notification
      })
    if (payloadToUpdate.length) {
      const { data } = await EventNotificationService.updateEventNotifications(
        payloadToUpdate
      )
      updateNotificationState(data)
    }
    if (payloadToCreate.length) {
      const { data } = await EventNotificationService.createEventNotifications(
        payloadToCreate
      )
      updateNotificationState(data)
    }
  } catch (error) {
    sendNotification({
      type: 'error',
      title: 'failed to update email notification',
      icon: 'AlertCircleIcon',
    })
    return
  } finally {
    state.disableSave = false
  }
  //update calendar notification if enabled
  handleOuterNotification(value, payload[0].notificationType)
  sendNotification({
    type: 'success',
    title: value
      ? t('eventNotification.email.enableSuccess')
      : t('eventNotification.email.disableSuccess'),
    icon: 'CheckCircleIcon',
  })
}

function handleOuterNotification(value, type) {
  emit('update-notification', {
    channel: 'email',
    type: type,
    status: value,
  })
}

function validateAll() {
  if (emailTemplateConfigurator.value) {
    return Promise.all(
      emailTemplateConfigurator.value.map(formComponent => {
        if (formComponent && formComponent.validateForm) {
          return formComponent.validateForm()
        }
      })
    )
  }
  return Promise.resolve()
}
</script>

<style scoped>
.disabled-div {
  position: relative;
  user-select: none;
  opacity: 0.5;
}
</style>
