import config from '@/config'
import { requests } from './BaseService'

export const formService = {
  getAll: (locationId: string, skip: number) =>
    requests().get(`${config.restApiUrl}/forms/`, {
      params: {
        locationId,
        skip,
        limit: 50,
      },
    }),
}
