import config from '@/config'
import { requests } from './BaseService'
const calendarResourceUrl = `${config.calendarApiUrl}/calendars/resources`

export const CalendarResourceService = {
  create: (body: any, resourceType: string) => requests().post(`${calendarResourceUrl}/${resourceType}`, body),
  update: (id: string, body: any, resourceType: string) => requests().put(`${calendarResourceUrl}/${resourceType}/${id}`, body),
  get: (id: string, resourceType: string) => requests().get(`${calendarResourceUrl}/${resourceType}/${id}`),
  delete: (id: string, resourceType: string) => requests().delete(`${calendarResourceUrl}/${resourceType}/${id}`),
  find: (locationId: string, resourceType: string) => requests().get(`${calendarResourceUrl}/${resourceType}`, {
    params: {
      locationId
    },
  }),
}