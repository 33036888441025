import HandlebarValidator from './handlebar-validator'
export const validateEmail = str => {
  const reg =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return reg.test(String(str).toLowerCase())
}

const types = ['physical', 'inbound_call', 'outbound_call', 'custom']
export function hasDuplicateKeyValue(locationConf) {
  if (!locationConf) {
    return false
  }
  if (!locationConf.length) {
    return false
  }
  if (!Array.isArray(locationConf)) {
    return false
  }

  const arr = locationConf
    .filter(item => types.includes(item.kind))
    .map(item => {
      return {
        key: item.kind,
        value: item.location,
      }
    })

  const seen = {}
  for (const obj of arr) {
    const objStr = JSON.stringify(obj)
    if (seen[objStr]) {
      return true
    }
    seen[objStr] = true
  }
  return false
}

export function hasEmptyKeys(arr) {
  if (!arr) {
    return false
  }
  if (!arr.length) {
    return false
  }
  if (!Array.isArray(arr)) {
    return false
  }
  if (arr.length === 1) {
    return false
  }

  return arr
    .filter(item => types.includes(item.kind))
    .some(item => {
      return !item.location
    })
}

export function isValidEmail(
  email: string | undefined,
  allowMultiple = true
): boolean {
  if (!email) {
    return false
  }

  // check for multiple emails
  if (allowMultiple && email.includes(',')) {
    let emailArray = email.split(',')
    emailArray = emailArray.map(email => email.trim()) // trim leading and trailing spaces.

    return emailArray.reduce((finalResult, currentEmail): boolean => {
      return finalResult && isValidEmail(currentEmail)
    }, true)
  }

  if (email.includes('{{')) {
    return true
  } // for custom variable

  const reg =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

  if (!reg.test(email)) {
    return false
  }

  return true
}
export function isValidHandleBar(body: string): boolean {
  return HandlebarValidator.isValidHandlebarString(body)
}

/**
 * Checks if the given input field is within limits.
 * @param field
 */
export function isWithinLimits(
  field: string,
  low = 0,
  high = 50,
  countWords = false
) {
  if (!field) {
    return false
  }
  if (!countWords) {
    if (field.length > low && field.length <= high) {
      return true
    }
    return false
  }
  const avgWordLength = 7
  // total words separted by whitespace character
  const totalWords = field.trim().split(/\s+/).length

  // this is to prevent user from enter very long strings without whiteSpaces
  const totalWordsByAvgLength = Math.round(field.trim().length / avgWordLength)

  return totalWords > low && totalWords <= high && totalWordsByAvgLength <= high
}
