import { CheckIcon } from '@gohighlevel/ghl-icons/24/outline'
import {
  SelectBaseOption,
  UITextSmNormal,
  UITextXsRegular,
} from '@gohighlevel/ghl-ui'
import { VNode, h } from 'vue'

/**
 * A Vnode rendering function that renders description along with lables
 * pass it as a `render-option` to UISelect component from ghl-ui library
 * or as a `render` function in options array.
 * @param info
 * @returns
 */
export const optionDescriptionRenderer = (info: {
  node: VNode
  option: SelectBaseOption<string, string>
  selected: boolean
}) => {
  const { option, node, selected } = info

  if (Array.isArray(node.children)) {
    node.children = [
      h('div', { class: 'z-50 w-full flex flex-col py-2' }, [
        h(
          UITextSmNormal,
          selected
            ? { class: 'text-primary-500' }
            : option.disabled
              ? { class: 'text-gray-400' }
              : { class: 'text-gray-700' },
          () => option.label
        ),
        h(
          UITextXsRegular,
          { class: 'text-gray-400' },
          () => option.description
        ),
      ]),
    ]
    if (selected) {
      node.children.push(
        h(
          CheckIcon,
          {
            class: 'w-4 h-4 text-primary-500 z-50 absolute top-2 right-4',
          },
          () => []
        )
      )
    }
  }
  return node
}
