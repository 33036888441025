<script setup lang="ts">
import AppState, {
  fetchConnectedCalendars,
  setConnectedCalendars,
  setConnections,
  setUserCalendars
} from '@/states/app'
import {
  UIButton,
  UICheckbox,
  UISelect,
  UISpin,
  UITextLgMedium,
  UITextSmNormal,
} from '@gohighlevel/ghl-ui'

import { UIToggle } from '@gohighlevel/ghl-canvas'

import { computed, onMounted, ref, watch } from 'vue'

import ConflictCalendarsSection from './ConflictCalendarsSection.vue'
import ConnectAccountCard from './ConnectAccountCard.vue'

import { default as GmailLogo, default as GoogleLogo } from '@/assets/gmail-logo.png'
import ICloudLogo from '@/assets/icloud-logo.png'
import OutlookLogo from '@/assets/outlook-logo.png'
import ZoomLogo from '@/assets/zoom-logo.png'
import { ConnectionService } from '@/class/services/ConnectionService'
import Calendar from '@/models/calendar'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const userCalendars = computed(() => {
  return AppState?.userCalendars
})
const connectedCalendars = computed(() => {
  return AppState?.connectedCalendars
})

const primaryCalendar = computed(() => {
  const primaryCalendar = userCalendars.value.find(
    c => !c.isCheckForConflictsCalendar
  )
  return primaryCalendar
})
const formValue = ref({
  googleCalendarId: '',
  defaultCalendarServiceId: '',
  canTriggerRun: false,
})

const loadData = async () => {
  if (!AppState.locationId) {
    return
  }
  isLoading.value = true

  const { data: connectionsData } = await ConnectionService.getConnections(
    AppState.locationId
  )
  setConnections(connectionsData)

  const { data: connectedCalendasData } =
    await ConnectionService.getConnectedCalendars(AppState.locationId)

  setConnectedCalendars(connectedCalendasData.thirdPartyCalendars)
  setUserCalendars(connectedCalendasData.userCalendars)

  isLoading.value = false
}

onMounted(() => {
  loadData()
})

watch(
  () => AppState.locationId,
  async () => {
    await loadData()
  }
)

watch(() => formValue.value.googleCalendarId, () => {
  if (!allowTwoWaySync.value) {
    acceptEventsAsAppointments.value = false
    formValue.value.defaultCalendarServiceId = ''
    formValue.value.canTriggerRun = false
  }
})

const teamCalendarOptions = computed(() => {
  const calendars = (AppState?.globalCalendars?.calendars ||
    []) as unknown as Calendar[]

  return calendars
    .filter(
      calendar =>
        calendar.isActive &&
        calendar.teamMembers?.some(tm => tm.userId === AppState?.user?.id)
    )
    .map(cal => ({
      label: cal.name,
      value: cal.id,
    }))
})

watch([() => primaryCalendar.value, () => teamCalendarOptions.value], () => {
  formValue.value.googleCalendarId = ''
  formValue.value.defaultCalendarServiceId = ''
  formValue.value.canTriggerRun = false
  if (primaryCalendar.value?.googleCalendarId) {
    formValue.value.googleCalendarId = primaryCalendar.value.googleCalendarId

    if (
      primaryCalendar.value.defaultCalendarServiceId &&
      teamCalendarOptions.value.find(
        c => c.value === primaryCalendar.value?.defaultCalendarServiceId
      )
    ) {
      formValue.value.defaultCalendarServiceId =
        primaryCalendar.value.defaultCalendarServiceId
      acceptEventsAsAppointments.value = true
    }

    formValue.value.canTriggerRun = Boolean(primaryCalendar.value.canTriggerRun)
  }
})

// watch(
//   () => acceptEventsAsAppointments.value,
//   () => {
//     if (!acceptEventsAsAppointments.value) {
//       formValue.value.defaultCalendarServiceId = ''
//     } else {
//       formValue.value.defaultCalendarServiceId =
//         teamCalendarOptions.value[0].value
//     }
//   }
// )

const primaryCalendarOptions = computed(() => {
  const masterOptions: any = []

  const googleAccounts = Object.keys(connectedCalendars.value.google)
  const outlookAccounts = Object.keys(connectedCalendars.value.outlook)
  const icloudAccounts = Object.keys(connectedCalendars.value.icloud)

  googleAccounts.forEach(accountName => {
    const calendars = connectedCalendars.value.google[accountName]

    masterOptions.push({
      type: 'group',
      label: `${accountName} (Google)`,
      key: `${accountName} (Google)`,
      description: 'Google',
      children: calendars.map(calendar => {
        return {
          type: 'google',
          oauthId: calendar.oauthId,
          title: calendar.summary,
          label: calendar.summary,
          value: calendar.id,
          disabled:
            !calendar.canEdit ||
            userCalendars.value
              .filter(x => x.isCheckForConflictsCalendar)
              .find(x => x.googleCalendarId === calendar.id),
          description: !calendar.canEdit
            ? t('settings.connections.no_write_access')
            : '',
        }
      }),
    })
  })

  icloudAccounts.forEach(accountName => {
    const calendars = connectedCalendars.value.icloud[accountName]

    masterOptions.push({
      type: 'group',
      label: `${accountName} (iCloud)`,
      key: `${accountName} (iCloud)`,
      description: 'iCloud',
      children: calendars.map(calendar => {
        return {
          type: 'icloud',
          oauthId: calendar.oauthId,
          title: calendar.summary,
          label: calendar.summary,
          value: calendar.id,
          disabled:
            !calendar.canEdit ||
            userCalendars.value
              .filter(x => x.isCheckForConflictsCalendar)
              .find(x => x.googleCalendarId === calendar.id),
          description: !calendar.canEdit
            ? t('settings.connections.no_write_access')
            : '',
        }
      }),
    })
  })

  outlookAccounts.forEach(accountName => {
    const calendars = connectedCalendars.value.outlook[accountName]

    masterOptions.push({
      type: 'group',
      label: `${accountName} (Outlook)`,
      key: `${accountName} (Outlook)`,
      description: 'Outlook',
      children: calendars.map(calendar => {
        return {
          type: 'outlook',
          oauthId: calendar.oauthId,
          title: calendar.summary,
          label: calendar.summary,
          value: calendar.id,
          disabled:
            !calendar.canEdit ||
            userCalendars.value
              .filter(x => x.isCheckForConflictsCalendar)
              .find(x => x.googleCalendarId === calendar.id),
          description: !calendar.canEdit
            ? t('settings.connections.no_write_access')
            : '',
        }
      }),
    })
  })

  return masterOptions
})

const savePrimaryCalendarChanged = async () => {
  isPrimaryCalSettingsSaving.value = true

  let allCalendars: any = []

  primaryCalendarOptions.value.forEach((masterOption: any) => {
    allCalendars = [...allCalendars, ...masterOption.children]
  })

  const previousThirdPartyCalendarId = primaryCalendar.value?.googleCalendarId

  if (previousThirdPartyCalendarId) {
    try {
      const { data } = await ConnectionService.deleteConnectedCalendar(
        false,
        primaryCalendar.value?.id
      )
    } catch (error) {
      console.error('error', error)
    }
  }

  const targetCalendar = allCalendars.find(
    (c: any) => c.value === formValue.value.googleCalendarId
  )
  const {
    data: { userCalendarId },
  } = await ConnectionService.addConnectedCalendar({
    thirdPartyCalendarId: targetCalendar.value,
    thirdPartyCalendarName: targetCalendar.title,
    type: targetCalendar.type,
    oauthId: targetCalendar.oauthId,
    isConflictCalendar: false,
    locationId: AppState.locationId,
    userCalendarId: primaryCalendar.value?.id,
    userId: AppState.user?.id,
  })

  if (acceptEventsAsAppointments.value) {
    await ConnectionService.updatePrimaryCalSettings({
      userCalendarId: userCalendarId,
      defaultCalendarServiceId: formValue.value.defaultCalendarServiceId,
      canTriggerRun: formValue.value.canTriggerRun,
    })
  } else {
    await ConnectionService.updatePrimaryCalSettings({
      userCalendarId: userCalendarId,
      defaultCalendarServiceId: '',
      canTriggerRun: false,
    })
  }

  await fetchConnectedCalendars(AppState?.locationId)

  isPrimaryCalSettingsSaving.value = false
}
const handleDefaultCalendarServiceChanged = (isChecked: boolean) => {
  if (!primaryCalendar.value?.id) {
    return
  }

  if (!isChecked) {
    acceptEventsAsAppointments.value = false
  } else {
    acceptEventsAsAppointments.value = true
  }
}

const isNoThirdPartyAccounts = computed(() => {
  return (
    Object.keys(connectedCalendars.value.google).length === 0 &&
    Object.keys(connectedCalendars.value.outlook).length === 0 &&
    Object.keys(connectedCalendars.value.icloud).length === 0
  )
})
const loggedInUserConnections = computed(() => {
  return AppState?.connections?.loggedInUserConnections
})

const allowTwoWaySync = computed(() => {
  const isPrimarySelected = !!formValue.value.googleCalendarId
  const icloudCalendars = primaryCalendarOptions.value
    .map((opt) => opt.children)
    .flat()
    .filter((cal) => cal.type === 'icloud')
  const isICloudCalendar = icloudCalendars.some((c) => c.value === formValue.value.googleCalendarId)
  return isPrimarySelected && !isICloudCalendar;
})

const selectedAccount = ref('google')

const isLoading = ref(false)
const isPrimaryCalSettingsSaving = ref(false)
const acceptEventsAsAppointments = ref(false)
const selectedTeamCalendar = ref('')
</script>
<template>
  <div class="max-w-5xl">
    <div class="border-b pb-7">
      <UITextLgMedium>
        {{ $t('settings.connections.connection_preferences') }}
      </UITextLgMedium>
      <UITextSmNormal class="text-gray-500">
        {{ $t('settings.connections.manage_calendars_conferencing_channels') }}
      </UITextSmNormal>
    </div>

    <div class="grid grid-flow-col items-start pt-7">
      <div class="flex flex-col pr-20">
        <div class="text-sm font-medium text-gray-700">
          {{ $t('settings.connections.main_integration_calendar') }}
        </div>
        <div class="text-gray-500">
          {{ $t('settings.connections.event_integration_calendar') }}
        </div>
      </div>
      <UISpin v-if="isLoading" :show="true"> </UISpin>
      <div v-else class="">
        <div v-if="!isNoThirdPartyAccounts" class="space-y-6">
          <UISelect id="select-primary-cal-dropdown" v-model:value="formValue.googleCalendarId"
            :options="primaryCalendarOptions" placeholder="Select one" :filterable="true" :loading="isLoading">
          </UISelect>

          <div class="flex items-center space-x-2" v-show="allowTwoWaySync">
            <UIToggle :checked="acceptEventsAsAppointments" :disabled="!primaryCalendar?.googleCalendarId"
              @update:checked="handleDefaultCalendarServiceChanged"></UIToggle>
            <div class="text-sm font-medium text-gray-700">
              {{ $t('settings.connections.2way_sync') }}
            </div>
          </div>

          <div v-if="acceptEventsAsAppointments" class="space-y-6">
            <UISelect id="select-primary-cal-dropdown" v-model:value="formValue.defaultCalendarServiceId"
              :options="teamCalendarOptions" placeholder="Select one" :filterable="true" :loading="isLoading">
            </UISelect>

            <div class="">
              <UICheckbox id="can-trigger-run" v-model:checked="formValue.canTriggerRun"
                class="text-sm font-medium text-gray-700">
                {{ $t('settings.connections.execute_automation') }}
              </UICheckbox>

              <div class="ml-6 text-gray-500">
                {{ $t('settings.connections.fire_workflow') }}
              </div>
            </div>
          </div>
        </div>
        <div v-else class="w-2/3 px-20">
          <div class="text-gray-400">
            {{ $t('settings.connections.connect_google_outlook') }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="!isLoading && !isNoThirdPartyAccounts" class="mt-20 flex justify-end">
      <UIButton id="" type="primary" :loading="isPrimaryCalSettingsSaving" @click="savePrimaryCalendarChanged">
        {{ $t('settings.connections.save') }}
      </UIButton>
    </div>

    <div class="mt-10 flex bg-white p-6 py-7 shadow-lg">
      <div class="w-1/2 space-y-10 border-r pr-10">
        <div class="space-y-6">
          <div class="space-y-4">
            <UITextLgMedium>
              {{ $t('settings.connections.calendars') }}
            </UITextLgMedium>

            <div
              class="flex h-16 cursor-pointer cursor-pointer items-center space-x-3 rounded-md border border-gray-200 px-4 hover:border-blue-600 hover:bg-blue-100"
              :class="{
                'border-blue-600 bg-blue-100': selectedAccount === 'google',
              }" @click="selectedAccount = 'google'">
              <img :src="GmailLogo" class="w-9" />
              <div class="text-sm font-medium text-gray-700">Google</div>
            </div>

            <div
              class="flex h-16 cursor-pointer items-center space-x-3 rounded-md border border-gray-200 px-4 hover:border-blue-600 hover:bg-blue-100"
              :class="{
                'border-blue-600 bg-blue-100': selectedAccount === 'outlook',
              }" @click="selectedAccount = 'outlook'">
              <img :src="OutlookLogo" class="w-9" />
              <div class="text-sm font-medium text-gray-700">Outlook</div>
            </div>

            <div v-if="AppState.isICloudIntegrationEnabled"
              class="flex h-16 cursor-pointer items-center space-x-3 rounded-md border border-gray-200 px-4 hover:border-blue-600 hover:bg-blue-100"
              :class="{
                'border-blue-600 bg-blue-100': selectedAccount === 'icloud',
              }" @click="selectedAccount = 'icloud'">
              <img :src="ICloudLogo" class="w-9" />
              <div class="text-sm font-medium text-gray-700">iCloud</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="Object.keys(connectedCalendars[selectedAccount] || {}).length > 0" class="w-1/2 space-y-4 p-6">
        <ConflictCalendarsSection :selected-conflict-account="selectedAccount" />
      </div>

      <div v-else class="w-96 px-6 py-7">
        <ConnectAccountCard v-if="selectedAccount === 'outlook'" :logo="OutlookLogo" connection-name="Outlook"
          :account-name="loggedInUserConnections?.outlook?.zoomData?.userPrincipalName
            " :oauth-id="loggedInUserConnections?.outlook?.oauthId" />

        <ConnectAccountCard v-if="selectedAccount === 'google'" :logo="GoogleLogo" connection-name="Google"
          account-name="" oauth-id="" />

        <ConnectAccountCard v-if="AppState.isICloudIntegrationEnabled && selectedAccount === 'icloud'" :logo="ICloudLogo"
          connection-name="iCloud" account-name="" oauth-id="" />
      </div>
    </div>

    <div class="mt-10 flex bg-white p-6 py-7 shadow-lg">
      <div class="w-1/2 space-y-10 border-r pr-10">
        <div class="space-y-4">
          <UITextLgMedium>
            {{ $t('settings.connections.conferencing') }}
          </UITextLgMedium>

          <div
            class="flex h-16 cursor-pointer items-center space-x-3 rounded-md border border-blue-600 bg-blue-100 px-4 hover:border-blue-600 hover:bg-blue-100">
            <img :src="ZoomLogo" class="w-9" />
            <div class="text-sm font-medium text-gray-700">Zoom</div>
          </div>
        </div>
      </div>

      <div class="w-1/2 px-6 py-7">
        <ConnectAccountCard :logo="ZoomLogo" connection-name="Zoom"
          :account-name="loggedInUserConnections?.zoom?.zoomData?.email"
          :oauth-id="loggedInUserConnections?.zoom?.oauthId" />
      </div>
    </div>
  </div>
</template>
