<script setup lang="ts">
import { PlusIcon, Trash01Icon } from '@gohighlevel/ghl-icons/24/outline'
import { UIButton, UIDatepicker, UITimePicker } from '@gohighlevel/ghl-ui'
import moment from 'moment'
import { computed, ref, watch } from 'vue'
import { MyAvailability } from '../../class/pages/MyAvailability'
import AppState from '../../states/app'
const myAvailability = ref(new MyAvailability(''))

const editingCalendar = computed(() => {
  return AppState.editingCalendar
})

const methods = {
  loadData() {
    myAvailability.value.setCustomAvailabilityDataFromDatabase(
      editingCalendar.value?.availabilities || []
    )

    if (myAvailability.value.availableDays.length === 0) {
      methods.addCustomAvailabilityDay()
    }
  },
  dateDisabled(ts: number) {
    const date = moment(ts).tz(AppState.locationTimezone)

    if (date.isBefore(moment().tz(AppState.locationTimezone), 'day')) {
      return true
    }

    return false
  },
  getAvailability() {
    const openHours = myAvailability.value.availableDays.map(day =>
      day.serializeCustomAvailabilityAsPerAPIFormat()
    )

    const existingOpenHourIds = editingCalendar.value?.availabilities
      .map(x => x._id)
      .filter(x => x)
    const newOpenHourIds = openHours.map(x => x!.id).filter(x => x)

    const idsToDelete = existingOpenHourIds.filter(
      x => !newOpenHourIds.includes(x)
    )

    idsToDelete.forEach(id => {
      const openHour = editingCalendar.value?.availabilities.find(
        x => x._id === id
      )

      openHours.push({
        date: openHour?.date,
        hours: openHour?.hours,
        id: openHour?._id,
        deleted: true,
      })
    })

    return openHours
  },
  addCustomAvailabilityDay() {
    let day = ''

    const days = myAvailability.value.availableDays.map(x =>
      moment(x.day, 'MMMM D, YYYY').tz(AppState.locationTimezone)
    )

    if (
      days.length > 0 &&
      days[days.length - 1].isSameOrAfter(
        moment().tz(AppState.locationTimezone),
        'day'
      )
    ) {
      day = days[days.length - 1].add(1, 'days').format('MMMM D, YYYY')
    } else {
      day = moment()
        .tz(AppState.locationTimezone)

        .format('MMMM D, YYYY')
    }

    myAvailability.value.addAvailabilityDay(day)
  },
  hasErrors() {
    return myAvailability.value.hasAnyInvalidHours
  },
}

watch(
  () => editingCalendar.value,
  () => {
    methods.loadData()
  },
  { immediate: true }
)

defineExpose({
  methods,
})
</script>

<template>
  <div>
    <div class="space-y-4">
      <div
        v-for="(availableDay, index) in myAvailability.availableDays"
        :key="index"
        class="flex items-baseline space-x-10"
      >
        <div class="flex space-x-5">
          <UIDatepicker
            id="custom"
            v-model:formatted-value="availableDay.day"
            :is-date-disabled="methods.dateDisabled"
            format="MMMM d, yyyy"
            class="w-80"
          />
          <div class="space-y-5">
            <div
              v-for="(availableDayHour, hourIndex) in availableDay.hours"
              :key="hourIndex"
              class="flex items-center space-x-4"
            >
              <UITimePicker
                v-model:formatted-value="availableDayHour.startTime"
                value-format="H:m"
                :minutes="5"
                format="hh:mm a"
                use-12-hours
                :actions="['confirm']"
                :status="
                  availableDay.hoursErrorMapping[hourIndex] ||
                  !availableDayHour.startTime
                    ? 'error'
                    : 'success'
                "
              />

              <UITimePicker
                v-model:formatted-value="availableDayHour.endTime"
                value-format="H:m"
                :minutes="5"
                format="hh:mm a"
                use-12-hours
                :actions="['confirm']"
                :status="
                  availableDay.hoursErrorMapping[hourIndex] ||
                  !availableDayHour.endTime
                    ? 'error'
                    : 'success'
                "
              />

              <UIButton
                v-if="hourIndex > 0"
                id=""
                class="ml-4 text-red-600"
                :text="true"
                @click="availableDay.removeAvailabilityHour(hourIndex)"
              >
                <Trash01Icon class="w-4 w-4"></Trash01Icon>
              </UIButton>

              <UIButton
                v-else
                id=""
                class="ml-4 text-red-600"
                :text="true"
                @click="myAvailability.removeAvailabilityDay(availableDay.day)"
              >
                <Trash01Icon class="w-4 w-4"></Trash01Icon>
              </UIButton>

              <!-- <UIButton
                v-if="index === 0 && hourIndex === 0"
                id=""
                class="ml-4"
                @click="
                  x => myAvailability.copyAvailabilieToAllDays(availableDay)
                "
              >
                <Copy01Icon class="mr-2 w-4 w-4"></Copy01Icon>
                Copy to all
              </UIButton> -->
            </div>

            <UIButton
              id=""
              :text="true"
              class="mt-2"
              @click="x => availableDay.addNewAvailabilityHour()"
            >
              <PlusIcon class="mr-2 h-4 w-4" />
              {{ $t('calendar_advanced.availability.add_time') }}
            </UIButton>
          </div>
        </div>
      </div>
    </div>

    <UIButton
      id="add-days-button"
      class="mb-4"
      :text="true"
      @click="methods.addCustomAvailabilityDay"
    >
      <PlusIcon class="mr-2 h-4 w-4" />
      {{ $t('calendar_advanced.availability.add_days') }}
    </UIButton>
  </div>
</template>
