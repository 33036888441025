export interface LSACategory {
  vertical: string
  job_type: string
  local: string
  job_type_id: string
}
export const LSA_NICHE_OPTIONS = ['home_services', 'real_estate', 'legal']
export const CATEGORY = [
  { key: 'Accounting', value: 'accountant' },
  { key: 'Acupuncture', value: 'acupuncturist' },
  { key: 'Air Duct Cleaning', value: 'air_duct_cleaner' },
  { key: 'Alcoholism Treatment Services', value: 'alcohol_treatment' },
  { key: 'Animal Rescue Services', value: 'animal_shelter' },
  { key: 'Appliance Repair', value: 'appliance_repair' },
  { key: 'Architecture', value: 'architect' },
  { key: 'Assisted Living Services', value: 'assisted_living' },
  { key: 'Babysitting', value: 'babysitter' },
  { key: 'Bankruptcy Law', value: 'bankruptcy_lawyer' },
  { key: 'Beauty School', value: 'beauty_school' },
  { key: 'Business Law', value: 'business_lawyer' },
  { key: 'Auto Services', value: 'car_repair' },
  { key: 'Carpentry', value: 'carpenter' },
  { key: 'Carpet Cleaning', value: 'carpet_cleaning' },
  { key: 'Child Care Services', value: 'child_care' },
  { key: 'Chiropractic Services', value: 'chiropractor' },
  { key: 'House Cleaning', value: 'cleaning_service' },
  { key: 'Contract Law', value: 'contract_lawyer' },
  { key: 'Countertop Services', value: 'countertop_pro' },
  { key: 'Criminal Law', value: 'criminal_lawyer' },
  { key: 'Dance Instruction', value: 'dance_instructor' },
  { key: 'Disability Law', value: 'disability_lawyer' },
  { key: 'Driving Instruction', value: 'driving_instructor' },
  { key: 'DUI Law', value: 'dui_lawyer' },
  { key: 'Electrical', value: 'electrician' },
  { key: 'Energy Efficiency Services', value: 'energy_efficiency_pro' },
  { key: 'Estate Law', value: 'estate_lawyer' },
  { key: 'Event Planning', value: 'event_planner' },
  { key: 'Family Law', value: 'family_lawyer' },
  { key: 'Fencing', value: 'fencing_pro' },
  { key: 'Financial Planning', value: 'financial_planner' },
  { key: 'First Aid Training', value: 'first_aid_trainer' },
  { key: 'Flooring', value: 'flooring_pro' },
  { key: 'Foundations', value: 'foundation_pro' },
  { key: 'Funeral Services', value: 'funeral_home' },
  { key: 'Garage Door', value: 'garage_door_pro' },
  { key: 'Home Improvement', value: 'general_contractor' },
  { key: 'Handyman', value: 'handyman' },
  { key: 'Home Inspection', value: 'home_inspector' },
  { key: 'Home Security Services', value: 'home_security' },
  { key: 'Home Theater Services', value: 'home_theater' },
  { key: 'HVAC', value: 'hvac' },
  { key: 'Immigration Law', value: 'immigration_lawyer' },
  { key: 'Interior Design', value: 'interior_designer' },
  { key: 'Intellectual Property Law', value: 'ip_lawyer' },
  { key: 'Junk Removal', value: 'junk_removal' },
  { key: 'Labor Law', value: 'labor_lawyer' },
  { key: 'Landscaping', value: 'landscaper' },
  { key: 'Language Instruction', value: 'language_instructor' },
  { key: 'Lawn Care', value: 'lawn_care' },
  { key: 'Litigation Law', value: 'litigation_lawyer' },
  { key: 'Locksmith', value: 'locksmith' },
  { key: 'Malpractice Law', value: 'malpractice_lawyer' },
  { key: 'Massage Services', value: 'massage_center' },
  { key: 'Massage School', value: 'massage_school' },
  { key: 'Moving', value: 'mover' },
  { key: 'Nursing Home Service', value: 'nursing_home' },
  { key: 'Painting', value: 'painter' },
  { key: 'Personal Injury Law', value: 'personal_injury_lawyer' },
  { key: 'Personal Training', value: 'personal_trainer' },
  { key: 'Pest Control', value: 'pest_control' },
  { key: 'Pet Adoption Services', value: 'pet_adoption' },
  { key: 'Pet Boarding', value: 'pet_boarding' },
  { key: 'Pet Grooming', value: 'pet_grooming' },
  { key: 'Pet Training', value: 'pet_trainer' },
  { key: 'Photography', value: 'photographer' },
  { key: 'Plumbing', value: 'plumber' },
  { key: 'Pool Cleaning', value: 'pool_cleaner' },
  { key: 'Pool Contracting', value: 'pool_contractor' },
  { key: 'Preschooling', value: 'preschool' },
  { key: 'Psychology Services', value: 'psychologist' },
  { key: 'Real Estate', value: 'real_estate_agent' },
  { key: 'Real Estate Law', value: 'real_estate_lawyer' },
  { key: 'Rehabilitation Services', value: 'rehab' },
  { key: 'Roofing', value: 'roofer' },
  { key: 'Sewage System Services', value: 'sewage_pro' },
  { key: 'Siding', value: 'siding_pro' },
  { key: 'Smart Home Services', value: 'smart_home' },
  { key: 'Snow Removal Services', value: 'snow_removal' },
  { key: 'Spa Services', value: 'spa' },
  { key: 'Tax Law', value: 'tax_lawyer' },
  { key: 'Tax Services', value: 'tax_specialist' },
  { key: 'Therapy', value: 'therapist' },
  { key: 'Roadside Assistance', value: 'towing_pro' },
  { key: 'Traffic Law', value: 'traffic_lawyer' },
  { key: 'Tree Services', value: 'tree_service' },
  { key: 'Tutoring', value: 'tutor' },
  { key: 'Upholstery Cleaning', value: 'upholstery_cleaner' },
  { key: 'Veterinary Services', value: 'veterinarian' },
  { key: 'Video Services', value: 'videographer' },
  { key: 'Water Damage Services', value: 'water_damage' },
  { key: 'Weight Loss Services', value: 'weight_loss_service' },
  { key: 'Window Cleaning', value: 'window_cleaner' },
  { key: 'Window Services', value: 'window_repair' },
  { key: 'Auto Glass', value: 'windshield_repair_pro' },
  { key: 'Yoga', value: 'yoga_instructor' },
]
export const LSA: { [key: string]: Array<LSACategory> } = {
  accountant: [
    {
      vertical: 'accountant',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'accountant_other',
    },
    {
      vertical: 'accountant',
      job_type: 'Bankruptcy Tax Litigation',
      local: 'en-US',
      job_type_id: 'bankruptcy_tax_litigation',
    },
    {
      vertical: 'accountant',
      job_type: 'Bookkeeping',
      local: 'en-US',
      job_type_id: 'bookkeeping',
    },
    {
      vertical: 'accountant',
      job_type: 'Business Consulting',
      local: 'en-US',
      job_type_id: 'business_consulting',
    },
    {
      vertical: 'accountant',
      job_type: 'Debt Collection Relief',
      local: 'en-US',
      job_type_id: 'debt_collection_relief',
    },
    {
      vertical: 'accountant',
      job_type: 'IRS Representation & Resolution',
      local: 'en-US',
      job_type_id: 'irs_representation_resolution',
    },
    {
      vertical: 'accountant',
      job_type: 'Payroll',
      local: 'en-US',
      job_type_id: 'payroll',
    },
    {
      vertical: 'accountant',
      job_type: 'Tax Planning Business',
      local: 'en-US',
      job_type_id: 'tax_planning_business',
    },
    {
      vertical: 'accountant',
      job_type: 'Tax Planning Individual',
      local: 'en-US',
      job_type_id: 'tax_planning_individual',
    },
    {
      vertical: 'accountant',
      job_type: 'Tax Return Business',
      local: 'en-US',
      job_type_id: 'tax_return_business',
    },
    {
      vertical: 'accountant',
      job_type: 'Tax Return Individual',
      local: 'en-US',
      job_type_id: 'tax_return_individual',
    },
    {
      vertical: 'accountant',
      job_type: 'Trusts Estates Accounting',
      local: 'en-US',
      job_type_id: 'trusts_estates_accounting',
    },
  ],
  acupuncturist: [
    {
      vertical: 'acupuncturist',
      job_type: 'Acupressure',
      local: 'en-US',
      job_type_id: 'acupressure',
    },
    {
      vertical: 'acupuncturist',
      job_type: 'Acupuncture',
      local: 'en-US',
      job_type_id: 'acupuncture',
    },
    {
      vertical: 'acupuncturist',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'acupuncturist_other',
    },
    {
      vertical: 'acupuncturist',
      job_type: 'Cupping',
      local: 'en-US',
      job_type_id: 'cupping',
    },
    {
      vertical: 'acupuncturist',
      job_type: 'Gua Sha',
      local: 'en-US',
      job_type_id: 'gua_sha',
    },
    {
      vertical: 'acupuncturist',
      job_type: 'Heat Therapy',
      local: 'en-US',
      job_type_id: 'heat_therapy',
    },
    {
      vertical: 'acupuncturist',
      job_type: 'Herbal Therapy',
      local: 'en-US',
      job_type_id: 'herbal_therapy',
    },
    {
      vertical: 'acupuncturist',
      job_type: 'Infrared Therapy',
      local: 'en-US',
      job_type_id: 'infrared_therapy',
    },
    {
      vertical: 'acupuncturist',
      job_type: 'Moxibustion',
      local: 'en-US',
      job_type_id: 'moxibustion',
    },
    {
      vertical: 'acupuncturist',
      job_type: 'Qigong',
      local: 'en-US',
      job_type_id: 'qigong',
    },
    {
      vertical: 'acupuncturist',
      job_type: 'Shiatsu',
      local: 'en-US',
      job_type_id: 'shiatsu',
    },
    {
      vertical: 'acupuncturist',
      job_type: 'Tui Na',
      local: 'en-US',
      job_type_id: 'tui_na',
    },
  ],
  air_duct_cleaner: [
    {
      vertical: 'air_duct_cleaner',
      job_type: 'Air Duct Cleaning',
      local: 'en-US',
      job_type_id: 'air_duct_cleaning',
    },
    {
      vertical: 'air_duct_cleaner',
      job_type: 'Filter Replacement',
      local: 'en-US',
      job_type_id: 'air_duct_filter_replacement',
    },
    {
      vertical: 'air_duct_cleaner',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'air_duct_other',
    },
    {
      vertical: 'air_duct_cleaner',
      job_type: 'Dryer Vent Cleaning',
      local: 'en-US',
      job_type_id: 'dryer_vent_cleaning',
    },
    {
      vertical: 'air_duct_cleaner',
      job_type: 'Furnace Cleaning',
      local: 'en-US',
      job_type_id: 'furnace_cleaning',
    },
    {
      vertical: 'air_duct_cleaner',
      job_type: 'Indoor Air Quality Testing',
      local: 'en-US',
      job_type_id: 'indoor_air_quality_testing',
    },
  ],
  alcohol_treatment: [
    {
      vertical: 'alcohol_treatment',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'alcohol_treatment_other',
    },
  ],
  animal_shelter: [
    {
      vertical: 'animal_shelter',
      job_type: 'Cat Adoption',
      local: 'en-US',
      job_type_id: 'animal_shelter_cat_adoption',
    },
    {
      vertical: 'animal_shelter',
      job_type: 'Dog Adoption',
      local: 'en-US',
      job_type_id: 'animal_shelter_dog_adoption',
    },
    {
      vertical: 'animal_shelter',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'animal_shelter_other',
    },
    {
      vertical: 'animal_shelter',
      job_type: 'Other Animal Adoption',
      local: 'en-US',
      job_type_id: 'animal_shelter_other_animal_adoption',
    },
    {
      vertical: 'animal_shelter',
      job_type: 'Other Veterinary Services',
      local: 'en-US',
      job_type_id: 'animal_shelter_other_veterinary_services',
    },
    {
      vertical: 'animal_shelter',
      job_type: 'Spay Or Neuter',
      local: 'en-US',
      job_type_id: 'animal_shelter_spay_neuter',
    },
    {
      vertical: 'animal_shelter',
      job_type: 'Vaccinations',
      local: 'en-US',
      job_type_id: 'animal_shelter_vaccinations',
    },
    {
      vertical: 'animal_shelter',
      job_type: 'Lost, Stray, Or Found Animals',
      local: 'en-US',
      job_type_id: 'lost_stray_found_animals',
    },
    {
      vertical: 'animal_shelter',
      job_type: 'Pet License',
      local: 'en-US',
      job_type_id: 'pet_license',
    },
    {
      vertical: 'animal_shelter',
      job_type: 'Pet Surrender',
      local: 'en-US',
      job_type_id: 'pet_surrender',
    },
  ],
  appliance_repair: [
    {
      vertical: 'appliance_repair',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'appliance_other',
    },
    {
      vertical: 'appliance_repair',
      job_type: 'Repair Dishwasher',
      local: 'en-US',
      job_type_id: 'repair_dishwasher',
    },
    {
      vertical: 'appliance_repair',
      job_type: 'Repair Dryer',
      local: 'en-US',
      job_type_id: 'repair_dryer',
    },
    {
      vertical: 'appliance_repair',
      job_type: 'Repair Freezer',
      local: 'en-US',
      job_type_id: 'repair_freezer',
    },
    {
      vertical: 'appliance_repair',
      job_type: 'Repair Microwave',
      local: 'en-US',
      job_type_id: 'repair_microwave',
    },
    {
      vertical: 'appliance_repair',
      job_type: 'Repair Oven',
      local: 'en-US',
      job_type_id: 'repair_oven',
    },
    {
      vertical: 'appliance_repair',
      job_type: 'Repair Refrigerator',
      local: 'en-US',
      job_type_id: 'repair_refrigerator',
    },
    {
      vertical: 'appliance_repair',
      job_type: 'Repair Refrigerator / Freezer',
      local: 'en-US',
      job_type_id: 'repair_refrigerator_freezer',
    },
    {
      vertical: 'appliance_repair',
      job_type: 'Repair Stove / Cooktop',
      local: 'en-US',
      job_type_id: 'repair_stove_cooktop',
    },
    {
      vertical: 'appliance_repair',
      job_type: 'Repair Stove / Cooktop / Oven',
      local: 'en-US',
      job_type_id: 'repair_stove_cooktop_oven',
    },
    {
      vertical: 'appliance_repair',
      job_type: 'Repair Washer',
      local: 'en-US',
      job_type_id: 'repair_washer',
    },
    {
      vertical: 'appliance_repair',
      job_type: 'Repair Washer / Dryer',
      local: 'en-US',
      job_type_id: 'repair_washer_dryer',
    },
  ],
  architect: [
    {
      vertical: 'architect',
      job_type: '3D Architectural Rendering',
      local: 'en-US',
      job_type_id: '3d_architectural_rendering',
    },
    {
      vertical: 'architect',
      job_type: 'Adaptive Reuse & Historical Renovation',
      local: 'en-US',
      job_type_id: 'adaptive_reuse_historical_renovation',
    },
    {
      vertical: 'architect',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'architect_other',
    },
    {
      vertical: 'architect',
      job_type: 'Architectural Designing',
      local: 'en-US',
      job_type_id: 'architectural_designing',
    },
    {
      vertical: 'architect',
      job_type: 'Architectural Engineering',
      local: 'en-US',
      job_type_id: 'architectural_engineering',
    },
    {
      vertical: 'architect',
      job_type: 'Code Analysis And Permits',
      local: 'en-US',
      job_type_id: 'building_code_analysis_permit_coordination',
    },
    {
      vertical: 'architect',
      job_type: 'Consulting',
      local: 'en-US',
      job_type_id: 'consulting',
    },
    {
      vertical: 'architect',
      job_type: 'Home Building & Improvement',
      local: 'en-US',
      job_type_id: 'home_building_improvement',
    },
    {
      vertical: 'architect',
      job_type: 'Interior Design',
      local: 'en-US',
      job_type_id: 'interior_design_architect',
    },
    {
      vertical: 'architect',
      job_type: 'Planning & Zoning Approval',
      local: 'en-US',
      job_type_id: 'planning_zoning_approval_assistance',
    },
    {
      vertical: 'architect',
      job_type: 'Project Management',
      local: 'en-US',
      job_type_id: 'project_managing_architect',
    },
    {
      vertical: 'architect',
      job_type: 'Site Master Planning',
      local: 'en-US',
      job_type_id: 'site_master_planning',
    },
  ],
  assisted_living: [
    {
      vertical: 'assisted_living',
      job_type: 'Housekeeping And Laundry',
      local: 'en-US',
      job_type_id: 'assisted_living_housekeeping_and_laundry',
    },
    {
      vertical: 'assisted_living',
      job_type: 'Meal Services',
      local: 'en-US',
      job_type_id: 'assisted_living_meal_services',
    },
    {
      vertical: 'assisted_living',
      job_type: 'Memory Care',
      local: 'en-US',
      job_type_id: 'assisted_living_memory_care',
    },
    {
      vertical: 'assisted_living',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'assisted_living_other',
    },
    {
      vertical: 'assisted_living',
      job_type: 'Rehabilitation Services',
      local: 'en-US',
      job_type_id: 'assisted_living_rehabilitation_services',
    },
    {
      vertical: 'assisted_living',
      job_type: 'Transportation',
      local: 'en-US',
      job_type_id: 'assisted_living_transportation',
    },
    {
      vertical: 'assisted_living',
      job_type: 'Wellness Programs',
      local: 'en-US',
      job_type_id: 'assisted_living_wellness_programs',
    },
    {
      vertical: 'assisted_living',
      job_type: 'Daily Living Assistance',
      local: 'en-US',
      job_type_id: 'daily_living_assistance',
    },
    {
      vertical: 'assisted_living',
      job_type: 'Medication Management',
      local: 'en-US',
      job_type_id: 'medication_management',
    },
    {
      vertical: 'assisted_living',
      job_type: 'Short Term Stays',
      local: 'en-US',
      job_type_id: 'short_term_stays',
    },
    {
      vertical: 'assisted_living',
      job_type: 'Recreational Activites',
      local: 'en-US',
      job_type_id: 'social_and_recreational_activities',
    },
  ],
  babysitter: [
    {
      vertical: 'babysitter',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'babysitter_other',
    },
    {
      vertical: 'babysitter',
      job_type: 'Event Babysitting',
      local: 'en-US',
      job_type_id: 'event_babysitting',
    },
    {
      vertical: 'babysitter',
      job_type: 'Homework Assistance',
      local: 'en-US',
      job_type_id: 'homework_assistance',
    },
    {
      vertical: 'babysitter',
      job_type: 'Housekeeping And Laundry',
      local: 'en-US',
      job_type_id: 'housekeeping_laundry',
    },
    {
      vertical: 'babysitter',
      job_type: 'Meal Preparation',
      local: 'en-US',
      job_type_id: 'meal_preparation',
    },
    {
      vertical: 'babysitter',
      job_type: 'Nanny Services',
      local: 'en-US',
      job_type_id: 'nanny_services',
    },
    {
      vertical: 'babysitter',
      job_type: 'Overnight Babysitting',
      local: 'en-US',
      job_type_id: 'overnight_babysitting',
    },
    {
      vertical: 'babysitter',
      job_type: 'Pick Up And Drop Off',
      local: 'en-US',
      job_type_id: 'pick_up_and_drop_off',
    },
    {
      vertical: 'babysitter',
      job_type: 'Shopping And Errand',
      local: 'en-US',
      job_type_id: 'shopping_errand',
    },
    {
      vertical: 'babysitter',
      job_type: 'Special Needs Care',
      local: 'en-US',
      job_type_id: 'special_needs_care',
    },
    {
      vertical: 'babysitter',
      job_type: 'Virtual Babysitting',
      local: 'en-US',
      job_type_id: 'virtual_babysitting',
    },
  ],
  bankruptcy_lawyer: [
    {
      vertical: 'bankruptcy_lawyer',
      job_type: 'Agriculture Bankruptcy',
      local: 'en-US',
      job_type_id: 'agriculture_bankruptcy',
    },
    {
      vertical: 'bankruptcy_lawyer',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'bankruptcy_lawyer_other',
    },
    {
      vertical: 'bankruptcy_lawyer',
      job_type: 'Bankruptcy Litigation',
      local: 'en-US',
      job_type_id: 'bankruptcy_litigation',
    },
    {
      vertical: 'bankruptcy_lawyer',
      job_type: 'Chapter 11 Bankruptcy',
      local: 'en-US',
      job_type_id: 'chapter_11_bankruptcy',
    },
    {
      vertical: 'bankruptcy_lawyer',
      job_type: 'Chapter 13 Bankruptcy',
      local: 'en-US',
      job_type_id: 'chapter_13_bankruptcy',
    },
    {
      vertical: 'bankruptcy_lawyer',
      job_type: 'Chapter 7 Bankruptcy',
      local: 'en-US',
      job_type_id: 'chapter_7_bankruptcy',
    },
    {
      vertical: 'bankruptcy_lawyer',
      job_type: 'Corporate Bankruptcy',
      local: 'en-US',
      job_type_id: 'corporate_bankruptcy',
    },
    {
      vertical: 'bankruptcy_lawyer',
      job_type: 'Creditor Harassment',
      local: 'en-US',
      job_type_id: 'creditor_harassment',
    },
    {
      vertical: 'bankruptcy_lawyer',
      job_type: 'Debt Settlement',
      local: 'en-US',
      job_type_id: 'debt_settlement',
    },
    {
      vertical: 'bankruptcy_lawyer',
      job_type: 'Foreclosure',
      local: 'en-US',
      job_type_id: 'foreclosure_bankruptcy_lawyer',
    },
    {
      vertical: 'bankruptcy_lawyer',
      job_type: 'Personal Bankruptcy',
      local: 'en-US',
      job_type_id: 'personal_bankruptcy',
    },
    {
      vertical: 'bankruptcy_lawyer',
      job_type: 'Repossession',
      local: 'en-US',
      job_type_id: 'repossession',
    },
    {
      vertical: 'bankruptcy_lawyer',
      job_type: 'Wage Garnishment',
      local: 'en-US',
      job_type_id: 'wage_garnishment',
    },
  ],
  beauty_school: [
    {
      vertical: 'beauty_school',
      job_type: 'Barbering',
      local: 'en-US',
      job_type_id: 'beauty_school_barbering',
    },
    {
      vertical: 'beauty_school',
      job_type: 'Business Management',
      local: 'en-US',
      job_type_id: 'beauty_school_business_management',
    },
    {
      vertical: 'beauty_school',
      job_type: 'Cosmetology',
      local: 'en-US',
      job_type_id: 'beauty_school_cosmetology',
    },
    {
      vertical: 'beauty_school',
      job_type: 'Esthetics',
      local: 'en-US',
      job_type_id: 'beauty_school_esthetics',
    },
    {
      vertical: 'beauty_school',
      job_type: 'Hair Cutting',
      local: 'en-US',
      job_type_id: 'beauty_school_hair_cutting',
    },
    {
      vertical: 'beauty_school',
      job_type: 'Hair Design',
      local: 'en-US',
      job_type_id: 'beauty_school_hair_design',
    },
    {
      vertical: 'beauty_school',
      job_type: 'Makeup',
      local: 'en-US',
      job_type_id: 'beauty_school_makeup',
    },
    {
      vertical: 'beauty_school',
      job_type: 'Massage Therapy',
      local: 'en-US',
      job_type_id: 'beauty_school_massage_therapy',
    },
    {
      vertical: 'beauty_school',
      job_type: 'Nail Technology',
      local: 'en-US',
      job_type_id: 'beauty_school_nail_technology',
    },
    {
      vertical: 'beauty_school',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'beauty_school_other',
    },
    {
      vertical: 'beauty_school',
      job_type: 'Retail Training',
      local: 'en-US',
      job_type_id: 'beauty_school_retail_training',
    },
  ],
  business_lawyer: [
    {
      vertical: 'business_lawyer',
      job_type: 'Banking & Finance',
      local: 'en-US',
      job_type_id: 'banking_finance_law',
    },
    {
      vertical: 'business_lawyer',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'business_lawyer_other',
    },
    {
      vertical: 'business_lawyer',
      job_type: 'Business Licenses & Permits',
      local: 'en-US',
      job_type_id: 'business_licenses_permits',
    },
    {
      vertical: 'business_lawyer',
      job_type: 'Business Litigation',
      local: 'en-US',
      job_type_id: 'business_litigation',
    },
    {
      vertical: 'business_lawyer',
      job_type: 'Business Taxes',
      local: 'en-US',
      job_type_id: 'business_taxes',
    },
    {
      vertical: 'business_lawyer',
      job_type: 'Business Transactions',
      local: 'en-US',
      job_type_id: 'business_transactions_business_lawyer',
    },
    {
      vertical: 'business_lawyer',
      job_type: 'Commercial Bankruptcy',
      local: 'en-US',
      job_type_id: 'commercial_bankruptcy',
    },
    {
      vertical: 'business_lawyer',
      job_type: 'Commercial Contracts',
      local: 'en-US',
      job_type_id: 'commercial_contracts_business_lawyer',
    },
    {
      vertical: 'business_lawyer',
      job_type: 'Employment Law',
      local: 'en-US',
      job_type_id: 'employment_law_business_lawyer',
    },
    {
      vertical: 'business_lawyer',
      job_type: 'Incorporation & Restructuring',
      local: 'en-US',
      job_type_id: 'incorporation_restructuring',
    },
    {
      vertical: 'business_lawyer',
      job_type: 'Insurance Law',
      local: 'en-US',
      job_type_id: 'insurance_law',
    },
    {
      vertical: 'business_lawyer',
      job_type: 'Intellectual Property Law',
      local: 'en-US',
      job_type_id: 'intellectual_property_law',
    },
    {
      vertical: 'business_lawyer',
      job_type: 'Mergers & Acquisitions',
      local: 'en-US',
      job_type_id: 'mergers_acquisitions_business_lawyer',
    },
    {
      vertical: 'business_lawyer',
      job_type: 'Partnership Agreements',
      local: 'en-US',
      job_type_id: 'partnership_agreements_business_lawyer',
    },
    {
      vertical: 'business_lawyer',
      job_type: 'Patents, Copyrights, & Trademarks',
      local: 'en-US',
      job_type_id: 'patents_copyrights_trademarks',
    },
    {
      vertical: 'business_lawyer',
      job_type: 'Securities Law',
      local: 'en-US',
      job_type_id: 'securities_law',
    },
    {
      vertical: 'business_lawyer',
      job_type: 'Shareholder Disputes',
      local: 'en-US',
      job_type_id: 'shareholder_disputes',
    },
  ],
  car_repair: [
    {
      vertical: 'car_repair',
      job_type: 'Air & Cabin Filter',
      local: 'en-US',
      job_type_id: 'air_and_cabin_filter',
    },
    {
      vertical: 'car_repair',
      job_type: 'Battery',
      local: 'en-US',
      job_type_id: 'battery',
    },
    {
      vertical: 'car_repair',
      job_type: 'Body & Trim',
      local: 'en-US',
      job_type_id: 'body_and_trim',
    },
    {
      vertical: 'car_repair',
      job_type: 'Brakes',
      local: 'en-US',
      job_type_id: 'brakes',
    },
    {
      vertical: 'car_repair',
      job_type: 'Air Conditioning',
      local: 'en-US',
      job_type_id: 'car_air_conditioning',
    },
    {
      vertical: 'car_repair',
      job_type: 'Electrical',
      local: 'en-US',
      job_type_id: 'car_electrical',
    },
    {
      vertical: 'car_repair',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'car_repair_other',
    },
    {
      vertical: 'car_repair',
      job_type: 'Check Engine Light Diagnostic',
      local: 'en-US',
      job_type_id: 'check_engine_light_diagnostic',
    },
    {
      vertical: 'car_repair',
      job_type: 'Exhaust',
      local: 'en-US',
      job_type_id: 'exhaust',
    },
    {
      vertical: 'car_repair',
      job_type: 'Oil Change',
      local: 'en-US',
      job_type_id: 'oil_change',
    },
    {
      vertical: 'car_repair',
      job_type: 'Steering / Suspension',
      local: 'en-US',
      job_type_id: 'steering_suspension',
    },
    {
      vertical: 'car_repair',
      job_type: 'Tires',
      local: 'en-US',
      job_type_id: 'tires',
    },
    {
      vertical: 'car_repair',
      job_type: 'Transmission',
      local: 'en-US',
      job_type_id: 'transmission',
    },
  ],
  carpenter: [
    {
      vertical: 'carpenter',
      job_type: 'Cabinetry',
      local: 'en-US',
      job_type_id: 'cabinetry',
    },
    {
      vertical: 'carpenter',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'carpenter_other',
    },
    {
      vertical: 'carpenter',
      job_type: 'Decking',
      local: 'en-US',
      job_type_id: 'decking',
    },
    {
      vertical: 'carpenter',
      job_type: 'Drywalling',
      local: 'en-US',
      job_type_id: 'drywalling',
    },
    {
      vertical: 'carpenter',
      job_type: 'Fencing',
      local: 'en-US',
      job_type_id: 'fencing',
    },
    {
      vertical: 'carpenter',
      job_type: 'General Installations',
      local: 'en-US',
      job_type_id: 'general_installations',
    },
    {
      vertical: 'carpenter',
      job_type: 'General Repairs & Maintenance',
      local: 'en-US',
      job_type_id: 'general_repairs_maintenance',
    },
    {
      vertical: 'carpenter',
      job_type: 'Remodeling',
      local: 'en-US',
      job_type_id: 'remodeling',
    },
    {
      vertical: 'carpenter',
      job_type: 'Shelving & Storage',
      local: 'en-US',
      job_type_id: 'shelving_storage',
    },
  ],
  carpet_cleaning: [
    {
      vertical: 'carpet_cleaning',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'carpet_cleaning_other',
    },
    {
      vertical: 'carpet_cleaning',
      job_type: 'Clean Area Rugs',
      local: 'en-US',
      job_type_id: 'clean_area_rugs',
    },
    {
      vertical: 'carpet_cleaning',
      job_type: 'Clean Carpet',
      local: 'en-US',
      job_type_id: 'clean_carpet',
    },
    {
      vertical: 'carpet_cleaning',
      job_type: 'Clean Drapes & Curtains',
      local: 'en-US',
      job_type_id: 'clean_drapes_and_curtains',
    },
    {
      vertical: 'carpet_cleaning',
      job_type: 'Clean Upholstery',
      local: 'en-US',
      job_type_id: 'clean_upholstery',
    },
    {
      vertical: 'carpet_cleaning',
      job_type: 'Remove Pet Stains & Odors',
      local: 'en-US',
      job_type_id: 'remove_pet_stains_and_odors',
    },
  ],
  child_care: [
    {
      vertical: 'child_care',
      job_type: 'After School Care',
      local: 'en-US',
      job_type_id: 'after_school_care',
    },
    {
      vertical: 'child_care',
      job_type: 'All Hours Daycare',
      local: 'en-US',
      job_type_id: 'all_hours_daycare',
    },
    {
      vertical: 'child_care',
      job_type: 'Bilingual Daycare',
      local: 'en-US',
      job_type_id: 'bilingual_daycare',
    },
    {
      vertical: 'child_care',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'child_care_other',
    },
    {
      vertical: 'child_care',
      job_type: 'Drop-in Daycare',
      local: 'en-US',
      job_type_id: 'drop_in_daycare',
    },
    {
      vertical: 'child_care',
      job_type: 'Full-time Daycare',
      local: 'en-US',
      job_type_id: 'full_time_daycare',
    },
    {
      vertical: 'child_care',
      job_type: 'In-home Daycare',
      local: 'en-US',
      job_type_id: 'in_home_daycare',
    },
    {
      vertical: 'child_care',
      job_type: 'Infant Programs',
      local: 'en-US',
      job_type_id: 'infant_programs',
    },
    {
      vertical: 'child_care',
      job_type: 'Part-time Daycare',
      local: 'en-US',
      job_type_id: 'part_time_daycare',
    },
    {
      vertical: 'child_care',
      job_type: 'Pre-kindergarten Programs',
      local: 'en-US',
      job_type_id: 'pre_kindergarten_programs',
    },
    {
      vertical: 'child_care',
      job_type: 'Preschool Programs',
      local: 'en-US',
      job_type_id: 'preschool_programs',
    },
    {
      vertical: 'child_care',
      job_type: 'School-age Programs',
      local: 'en-US',
      job_type_id: 'school_age_programs',
    },
    {
      vertical: 'child_care',
      job_type: 'Special Needs Daycare',
      local: 'en-US',
      job_type_id: 'special_needs_daycare',
    },
    {
      vertical: 'child_care',
      job_type: 'Summer Daycare',
      local: 'en-US',
      job_type_id: 'summer_daycare',
    },
    {
      vertical: 'child_care',
      job_type: 'Toddler Programs',
      local: 'en-US',
      job_type_id: 'toddler_programs',
    },
    {
      vertical: 'child_care',
      job_type: 'Weekend Daycare',
      local: 'en-US',
      job_type_id: 'weekend_daycare',
    },
  ],
  chiropractor: [
    {
      vertical: 'chiropractor',
      job_type: 'Acupuncture',
      local: 'en-US',
      job_type_id: 'chiropractor_acupuncture',
    },
    {
      vertical: 'chiropractor',
      job_type: 'Chiropractic',
      local: 'en-US',
      job_type_id: 'chiropractor_chiropractic',
    },
    {
      vertical: 'chiropractor',
      job_type: 'Massage Therapy',
      local: 'en-US',
      job_type_id: 'chiropractor_massage_therapy',
    },
    {
      vertical: 'chiropractor',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'chiropractor_other',
    },
    {
      vertical: 'chiropractor',
      job_type: 'Diagnosis',
      local: 'en-US',
      job_type_id: 'diagnosis',
    },
    {
      vertical: 'chiropractor',
      job_type: 'Eastern Medicine',
      local: 'en-US',
      job_type_id: 'eastern_medicine',
    },
    {
      vertical: 'chiropractor',
      job_type: 'Exercise Counseling',
      local: 'en-US',
      job_type_id: 'exercise_counseling',
    },
    {
      vertical: 'chiropractor',
      job_type: 'Pain Management',
      local: 'en-US',
      job_type_id: 'pain_management',
    },
    {
      vertical: 'chiropractor',
      job_type: 'Physical Therapy',
      local: 'en-US',
      job_type_id: 'physical_therapy',
    },
    {
      vertical: 'chiropractor',
      job_type: 'Spinal Adjustments',
      local: 'en-US',
      job_type_id: 'spinal_adjustments',
    },
    {
      vertical: 'chiropractor',
      job_type: 'Sports Medicine',
      local: 'en-US',
      job_type_id: 'sports_medicine',
    },
  ],
  cleaning_service: [
    {
      vertical: 'cleaning_service',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'cleaner_other',
    },
    {
      vertical: 'cleaning_service',
      job_type: 'Deep Clean',
      local: 'en-US',
      job_type_id: 'deep_clean',
    },
    {
      vertical: 'cleaning_service',
      job_type: 'Moving Clean',
      local: 'en-US',
      job_type_id: 'moving_clean',
    },
    {
      vertical: 'cleaning_service',
      job_type: 'Office Clean',
      local: 'en-US',
      job_type_id: 'office_clean',
    },
    {
      vertical: 'cleaning_service',
      job_type: 'Standard Clean',
      local: 'en-US',
      job_type_id: 'standard_clean',
    },
  ],
  contract_lawyer: [
    {
      vertical: 'contract_lawyer',
      job_type: 'Assignment Of Contract',
      local: 'en-US',
      job_type_id: 'assignment_of_contract',
    },
    {
      vertical: 'contract_lawyer',
      job_type: 'Business Transactions',
      local: 'en-US',
      job_type_id: 'business_transactions_contract_lawyer',
    },
    {
      vertical: 'contract_lawyer',
      job_type: 'Commercial Contracts',
      local: 'en-US',
      job_type_id: 'commercial_contracts_contract_lawyer',
    },
    {
      vertical: 'contract_lawyer',
      job_type: 'Contract Dispute',
      local: 'en-US',
      job_type_id: 'contract_dispute',
    },
    {
      vertical: 'contract_lawyer',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'contract_lawyer_other',
    },
    {
      vertical: 'contract_lawyer',
      job_type: 'Contract Negotiation',
      local: 'en-US',
      job_type_id: 'contract_negotiation',
    },
    {
      vertical: 'contract_lawyer',
      job_type: 'Contract Review',
      local: 'en-US',
      job_type_id: 'contract_review',
    },
    {
      vertical: 'contract_lawyer',
      job_type: 'Employment Contracts',
      local: 'en-US',
      job_type_id: 'employment_contracts_contract_lawyer',
    },
    {
      vertical: 'contract_lawyer',
      job_type: 'Franchise Agreements',
      local: 'en-US',
      job_type_id: 'franchise_agreements',
    },
    {
      vertical: 'contract_lawyer',
      job_type: 'Mergers & Acquisitions',
      local: 'en-US',
      job_type_id: 'mergers_acquisitions_contract_lawyer',
    },
    {
      vertical: 'contract_lawyer',
      job_type: 'Non-disclosure Agreements',
      local: 'en-US',
      job_type_id: 'non_disclosure_agreements_contract_lawyer',
    },
    {
      vertical: 'contract_lawyer',
      job_type: 'Partnership Agreements',
      local: 'en-US',
      job_type_id: 'partnership_agreements_contract_lawyer',
    },
    {
      vertical: 'contract_lawyer',
      job_type: 'Power Of Attorney',
      local: 'en-US',
      job_type_id: 'power_of_attorney_contract_lawyer',
    },
    {
      vertical: 'contract_lawyer',
      job_type: 'Security Agreements',
      local: 'en-US',
      job_type_id: 'security_agreements',
    },
    {
      vertical: 'contract_lawyer',
      job_type: 'Warranties',
      local: 'en-US',
      job_type_id: 'warranties',
    },
  ],
  countertop_pro: [
    {
      vertical: 'countertop_pro',
      job_type: 'Bathroom Countertops',
      local: 'en-US',
      job_type_id: 'bathroom_countertops',
    },
    {
      vertical: 'countertop_pro',
      job_type: 'Bathroom Wall Panels',
      local: 'en-US',
      job_type_id: 'bathroom_wall_panels',
    },
    {
      vertical: 'countertop_pro',
      job_type: 'Countertop Design',
      local: 'en-US',
      job_type_id: 'countertop_design',
    },
    {
      vertical: 'countertop_pro',
      job_type: 'Countertop Installation',
      local: 'en-US',
      job_type_id: 'countertop_installation',
    },
    {
      vertical: 'countertop_pro',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'countertop_pro_other',
    },
    {
      vertical: 'countertop_pro',
      job_type: 'Fireplace Facade',
      local: 'en-US',
      job_type_id: 'fireplace_facade',
    },
    {
      vertical: 'countertop_pro',
      job_type: 'Granite Countertops',
      local: 'en-US',
      job_type_id: 'granite_countertops',
    },
    {
      vertical: 'countertop_pro',
      job_type: 'Kitchen Countertops',
      local: 'en-US',
      job_type_id: 'kitchen_countertops',
    },
    {
      vertical: 'countertop_pro',
      job_type: 'Kitchen Wall Panels',
      local: 'en-US',
      job_type_id: 'kitchen_wall_panels',
    },
    {
      vertical: 'countertop_pro',
      job_type: 'Laminate Countertops',
      local: 'en-US',
      job_type_id: 'laminate_countertops',
    },
    {
      vertical: 'countertop_pro',
      job_type: 'Marble Countertops',
      local: 'en-US',
      job_type_id: 'marble_countertops',
    },
    {
      vertical: 'countertop_pro',
      job_type: 'Quartz Countertops',
      local: 'en-US',
      job_type_id: 'quartz_countertops',
    },
    {
      vertical: 'countertop_pro',
      job_type: 'Remodeling',
      local: 'en-US',
      job_type_id: 'remodeling_countertop_pro',
    },
    {
      vertical: 'countertop_pro',
      job_type: 'Repair & Maintenance',
      local: 'en-US',
      job_type_id: 'repair_maintenance_countertop_pro',
    },
    {
      vertical: 'countertop_pro',
      job_type: 'Stone Slab Countertops',
      local: 'en-US',
      job_type_id: 'stone_slab_countertops',
    },
    {
      vertical: 'countertop_pro',
      job_type: 'Tile Countertops',
      local: 'en-US',
      job_type_id: 'tile_countertops',
    },
  ],
  criminal_lawyer: [
    {
      vertical: 'criminal_lawyer',
      job_type: 'Corporate Representation',
      local: 'en-US',
      job_type_id: 'corporate_representation',
    },
    {
      vertical: 'criminal_lawyer',
      job_type: 'Criminal Immigration Defense',
      local: 'en-US',
      job_type_id: 'criminal_immigration_defense',
    },
    {
      vertical: 'criminal_lawyer',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'criminal_lawyer_other',
    },
    {
      vertical: 'criminal_lawyer',
      job_type: 'Domestic Violence',
      local: 'en-US',
      job_type_id: 'domestic_violence',
    },
    {
      vertical: 'criminal_lawyer',
      job_type: 'Drug Possession',
      local: 'en-US',
      job_type_id: 'drug_possession',
    },
    {
      vertical: 'criminal_lawyer',
      job_type: 'DUIs & Reckless Driving',
      local: 'en-US',
      job_type_id: 'duis_reckless_driving',
    },
    {
      vertical: 'criminal_lawyer',
      job_type: 'Expungement',
      local: 'en-US',
      job_type_id: 'expungement',
    },
    {
      vertical: 'criminal_lawyer',
      job_type: 'Federal Crimes',
      local: 'en-US',
      job_type_id: 'federal_crimes',
    },
    {
      vertical: 'criminal_lawyer',
      job_type: 'Felonies',
      local: 'en-US',
      job_type_id: 'felonies',
    },
    {
      vertical: 'criminal_lawyer',
      job_type: 'Petty Crimes & Misdemeanors',
      local: 'en-US',
      job_type_id: 'petty_crimes_misdemeanors',
    },
    {
      vertical: 'criminal_lawyer',
      job_type: 'Restraining Orders',
      local: 'en-US',
      job_type_id: 'restraining_orders',
    },
    {
      vertical: 'criminal_lawyer',
      job_type: 'Sex Offenses',
      local: 'en-US',
      job_type_id: 'sex_offenses',
    },
    {
      vertical: 'criminal_lawyer',
      job_type: 'Three-strikes Law',
      local: 'en-US',
      job_type_id: 'three_strikes_law',
    },
  ],
  dance_instructor: [
    {
      vertical: 'dance_instructor',
      job_type: 'African',
      local: 'en-US',
      job_type_id: 'dance_instructor_african',
    },
    {
      vertical: 'dance_instructor',
      job_type: 'Ballet',
      local: 'en-US',
      job_type_id: 'dance_instructor_ballet',
    },
    {
      vertical: 'dance_instructor',
      job_type: 'Ballroom',
      local: 'en-US',
      job_type_id: 'dance_instructor_ballroom',
    },
    {
      vertical: 'dance_instructor',
      job_type: 'Belly',
      local: 'en-US',
      job_type_id: 'dance_instructor_belly',
    },
    {
      vertical: 'dance_instructor',
      job_type: 'Bollywood',
      local: 'en-US',
      job_type_id: 'dance_instructor_bollywood',
    },
    {
      vertical: 'dance_instructor',
      job_type: 'Contemporary',
      local: 'en-US',
      job_type_id: 'dance_instructor_contemporary',
    },
    {
      vertical: 'dance_instructor',
      job_type: 'Folk And Traditional',
      local: 'en-US',
      job_type_id: 'dance_instructor_folk_traditional',
    },
    {
      vertical: 'dance_instructor',
      job_type: 'Hip Hop',
      local: 'en-US',
      job_type_id: 'dance_instructor_hip_hop',
    },
    {
      vertical: 'dance_instructor',
      job_type: 'Jazz',
      local: 'en-US',
      job_type_id: 'dance_instructor_jazz',
    },
    {
      vertical: 'dance_instructor',
      job_type: "Kids' Classes",
      local: 'en-US',
      job_type_id: 'dance_instructor_kids_classes',
    },
    {
      vertical: 'dance_instructor',
      job_type: 'Latin',
      local: 'en-US',
      job_type_id: 'dance_instructor_latin',
    },
    {
      vertical: 'dance_instructor',
      job_type: 'Modern',
      local: 'en-US',
      job_type_id: 'dance_instructor_modern',
    },
    {
      vertical: 'dance_instructor',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'dance_instructor_other',
    },
    {
      vertical: 'dance_instructor',
      job_type: 'Tap',
      local: 'en-US',
      job_type_id: 'dance_instructor_tap',
    },
    {
      vertical: 'dance_instructor',
      job_type: 'Wedding',
      local: 'en-US',
      job_type_id: 'dance_instructor_wedding',
    },
  ],
  disability_lawyer: [
    {
      vertical: 'disability_lawyer',
      job_type: 'Disability Hearings',
      local: 'en-US',
      job_type_id: 'disability_hearings',
    },
    {
      vertical: 'disability_lawyer',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'disability_lawyer_other',
    },
    {
      vertical: 'disability_lawyer',
      job_type: 'Long-term Disability',
      local: 'en-US',
      job_type_id: 'long_term_disability',
    },
    {
      vertical: 'disability_lawyer',
      job_type: 'Short-term Disability',
      local: 'en-US',
      job_type_id: 'short_term_disability',
    },
    {
      vertical: 'disability_lawyer',
      job_type: 'Social Security Disability Insurance',
      local: 'en-US',
      job_type_id: 'social_security_disability_insurance',
    },
    {
      vertical: 'disability_lawyer',
      job_type: 'SSA Claim Reconsideration',
      local: 'en-US',
      job_type_id: 'ssa_claim_reconsideration',
    },
    {
      vertical: 'disability_lawyer',
      job_type: 'SSA Disability Appeals',
      local: 'en-US',
      job_type_id: 'ssa_disability_appeals',
    },
    {
      vertical: 'disability_lawyer',
      job_type: 'SSA Disability Applications',
      local: 'en-US',
      job_type_id: 'ssa_disability_applications',
    },
    {
      vertical: 'disability_lawyer',
      job_type: 'SSA Disability Benefits',
      local: 'en-US',
      job_type_id: 'ssa_disability_benefits',
    },
    {
      vertical: 'disability_lawyer',
      job_type: 'SSA Family Benefits',
      local: 'en-US',
      job_type_id: 'ssa_family_benefits',
    },
    {
      vertical: 'disability_lawyer',
      job_type: 'SSA Impairment Listings',
      local: 'en-US',
      job_type_id: 'ssa_impairment_listings',
    },
    {
      vertical: 'disability_lawyer',
      job_type: 'Supplemental Security Income',
      local: 'en-US',
      job_type_id: 'supplemental_security_income',
    },
    {
      vertical: 'disability_lawyer',
      job_type: 'Veterans Disability Benefits',
      local: 'en-US',
      job_type_id: 'veterans_disability_benefits',
    },
    {
      vertical: 'disability_lawyer',
      job_type: "Workers' Compensation",
      local: 'en-US',
      job_type_id: 'workers_compensation_disability_lawyer',
    },
  ],
  driving_instructor: [
    {
      vertical: 'driving_instructor',
      job_type: 'Behind The Wheel Lessons',
      local: 'en-US',
      job_type_id: 'behind_the_wheel_lessons',
    },
    {
      vertical: 'driving_instructor',
      job_type: 'Commercial License Training',
      local: 'en-US',
      job_type_id: 'commercial_license_training',
    },
    {
      vertical: 'driving_instructor',
      job_type: 'Defensive Driving Lessons',
      local: 'en-US',
      job_type_id: 'defensive_driving_lessons',
    },
    {
      vertical: 'driving_instructor',
      job_type: 'Drivers Education Classes',
      local: 'en-US',
      job_type_id: 'drivers_education_classes',
    },
    {
      vertical: 'driving_instructor',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'driving_instructor_other',
    },
    {
      vertical: 'driving_instructor',
      job_type: 'Manual Transmission Lessons',
      local: 'en-US',
      job_type_id: 'manual_transmission_lessons',
    },
    {
      vertical: 'driving_instructor',
      job_type: 'Motorcycle Lessons',
      local: 'en-US',
      job_type_id: 'motorcycle_lessons',
    },
    {
      vertical: 'driving_instructor',
      job_type: 'Traffic School',
      local: 'en-US',
      job_type_id: 'traffic_school',
    },
  ],
  dui_lawyer: [
    {
      vertical: 'dui_lawyer',
      job_type: 'DUI/DWI',
      local: 'en-US',
      job_type_id: 'dui_dwi_dui_lawyer',
    },
    {
      vertical: 'dui_lawyer',
      job_type: 'DUI With Injury',
      local: 'en-US',
      job_type_id: 'dui_injuries_dui_lawyer',
    },
    {
      vertical: 'dui_lawyer',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'dui_lawyer_other',
    },
    {
      vertical: 'dui_lawyer',
      job_type: 'DUI With Accident',
      local: 'en-US',
      job_type_id: 'dui_with_accident',
    },
    {
      vertical: 'dui_lawyer',
      job_type: 'Felony DUI',
      local: 'en-US',
      job_type_id: 'felony_dui',
    },
    {
      vertical: 'dui_lawyer',
      job_type: 'Invalid Tests',
      local: 'en-US',
      job_type_id: 'invalid_tests',
    },
    {
      vertical: 'dui_lawyer',
      job_type: 'Multiple Offenses',
      local: 'en-US',
      job_type_id: 'multiple_offenses',
    },
    {
      vertical: 'dui_lawyer',
      job_type: 'Reckless Driving',
      local: 'en-US',
      job_type_id: 'reckless_driving_dui_lawyer',
    },
    {
      vertical: 'dui_lawyer',
      job_type: 'Underage DUI',
      local: 'en-US',
      job_type_id: 'underage_dui',
    },
  ],
  electrician: [
    {
      vertical: 'electrician',
      job_type: 'Install Electric Car Charger',
      local: 'en-US',
      job_type_id: 'electric_car_charger',
    },
    {
      vertical: 'electrician',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'electrician_other',
    },
    {
      vertical: 'electrician',
      job_type: 'Remodeling',
      local: 'en-US',
      job_type_id: 'electrician_remodeling',
    },
    {
      vertical: 'electrician',
      job_type: 'Install Fan',
      local: 'en-US',
      job_type_id: 'install_fan',
    },
    {
      vertical: 'electrician',
      job_type: 'Install Ground Wire',
      local: 'en-US',
      job_type_id: 'install_ground_wire',
    },
    {
      vertical: 'electrician',
      job_type: 'Install Light Fixtures',
      local: 'en-US',
      job_type_id: 'install_light_fixtures',
    },
    {
      vertical: 'electrician',
      job_type: 'Install Outdoor Lighting',
      local: 'en-US',
      job_type_id: 'install_outdoor_lighting',
    },
    {
      vertical: 'electrician',
      job_type: 'Install Outlets Or Switches',
      local: 'en-US',
      job_type_id: 'install_outlets_switches',
    },
    {
      vertical: 'electrician',
      job_type: 'Install Security System',
      local: 'en-US',
      job_type_id: 'install_security_system',
    },
    {
      vertical: 'electrician',
      job_type: 'Relocate Outlets Or Switches',
      local: 'en-US',
      job_type_id: 'relocate_outlets_switches',
    },
    {
      vertical: 'electrician',
      job_type: 'Repair Fan',
      local: 'en-US',
      job_type_id: 'repair_fan',
    },
    {
      vertical: 'electrician',
      job_type: 'Repair Light Fixtures',
      local: 'en-US',
      job_type_id: 'repair_light_fixtures',
    },
    {
      vertical: 'electrician',
      job_type: 'Repair Outlets Or Switches',
      local: 'en-US',
      job_type_id: 'repair_outlets_switches',
    },
    {
      vertical: 'electrician',
      job_type: 'Repair Panel',
      local: 'en-US',
      job_type_id: 'repair_panel',
    },
    {
      vertical: 'electrician',
      job_type: 'Replace Or Upgrade Panel',
      local: 'en-US',
      job_type_id: 'replace_upgrade_panel',
    },
    {
      vertical: 'electrician',
      job_type: 'Restore Power',
      local: 'en-US',
      job_type_id: 'restore_power',
    },
  ],
  energy_efficiency_pro: [
    {
      vertical: 'energy_efficiency_pro',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'energy_efficiency_pro_other',
    },
  ],
  estate_lawyer: [
    {
      vertical: 'estate_lawyer',
      job_type: 'Charitable Giving',
      local: 'en-US',
      job_type_id: 'charitable_giving',
    },
    {
      vertical: 'estate_lawyer',
      job_type: 'Contests / Litigation',
      local: 'en-US',
      job_type_id: 'contests_litigation',
    },
    {
      vertical: 'estate_lawyer',
      job_type: 'Deeds',
      local: 'en-US',
      job_type_id: 'deeds',
    },
    {
      vertical: 'estate_lawyer',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'estate_lawyer_other',
    },
    {
      vertical: 'estate_lawyer',
      job_type: 'Estate Planning',
      local: 'en-US',
      job_type_id: 'estate_planning_lawyer',
    },
    {
      vertical: 'estate_lawyer',
      job_type: 'Guardianship',
      local: 'en-US',
      job_type_id: 'guardianship_estate_lawyer',
    },
    {
      vertical: 'estate_lawyer',
      job_type: 'Medical Power Of Attorney',
      local: 'en-US',
      job_type_id: 'medical_power_of_attorney',
    },
    {
      vertical: 'estate_lawyer',
      job_type: 'Power Of Attorney',
      local: 'en-US',
      job_type_id: 'power_of_attorney_estate_lawyer',
    },
    {
      vertical: 'estate_lawyer',
      job_type: 'Probate Estate Lawyer',
      local: 'en-US',
      job_type_id: 'probate_estate_lawyer',
    },
    {
      vertical: 'estate_lawyer',
      job_type: 'Trusts',
      local: 'en-US',
      job_type_id: 'trusts',
    },
    {
      vertical: 'estate_lawyer',
      job_type: 'Wills',
      local: 'en-US',
      job_type_id: 'wills',
    },
  ],
  event_planner: [
    {
      vertical: 'event_planner',
      job_type: 'Anniversary',
      local: 'en-US',
      job_type_id: 'anniversary_planner',
    },
    {
      vertical: 'event_planner',
      job_type: 'Baby Shower',
      local: 'en-US',
      job_type_id: 'baby_shower_planner',
    },
    {
      vertical: 'event_planner',
      job_type: 'Bar And Bat Mitzvah',
      local: 'en-US',
      job_type_id: 'bar_and_bat_mitzvah_planner',
    },
    {
      vertical: 'event_planner',
      job_type: 'Birthday Party',
      local: 'en-US',
      job_type_id: 'birthday_party',
    },
    {
      vertical: 'event_planner',
      job_type: "Children's Party",
      local: 'en-US',
      job_type_id: 'children_party',
    },
    {
      vertical: 'event_planner',
      job_type: 'Corporate And Conference',
      local: 'en-US',
      job_type_id: 'corporate_and_conference_planner',
    },
    {
      vertical: 'event_planner',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'event_planner_other',
    },
    {
      vertical: 'event_planner',
      job_type: 'Quinceanera',
      local: 'en-US',
      job_type_id: 'quinceanera_planner',
    },
    {
      vertical: 'event_planner',
      job_type: 'Religious Event',
      local: 'en-US',
      job_type_id: 'religious_event',
    },
    {
      vertical: 'event_planner',
      job_type: 'Retirement Party',
      local: 'en-US',
      job_type_id: 'retirement_party',
    },
    {
      vertical: 'event_planner',
      job_type: 'School Event',
      local: 'en-US',
      job_type_id: 'school_event',
    },
    {
      vertical: 'event_planner',
      job_type: 'Theme Party',
      local: 'en-US',
      job_type_id: 'theme_party',
    },
    {
      vertical: 'event_planner',
      job_type: 'Wedding And Engagement',
      local: 'en-US',
      job_type_id: 'wedding_and_engagement_planner',
    },
  ],
  family_lawyer: [
    {
      vertical: 'family_lawyer',
      job_type: 'Adoption',
      local: 'en-US',
      job_type_id: 'adoption',
    },
    {
      vertical: 'family_lawyer',
      job_type: 'Child Support',
      local: 'en-US',
      job_type_id: 'child_support',
    },
    {
      vertical: 'family_lawyer',
      job_type: 'Contested Divorce',
      local: 'en-US',
      job_type_id: 'contested_divorce',
    },
    {
      vertical: 'family_lawyer',
      job_type: 'Debt Division',
      local: 'en-US',
      job_type_id: 'debt_division',
    },
    {
      vertical: 'family_lawyer',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'family_lawyer_other',
    },
    {
      vertical: 'family_lawyer',
      job_type: 'Guardianship',
      local: 'en-US',
      job_type_id: 'guardianship_family_lawyer',
    },
    {
      vertical: 'family_lawyer',
      job_type: 'Mediation',
      local: 'en-US',
      job_type_id: 'mediation_family_lawyer',
    },
    {
      vertical: 'family_lawyer',
      job_type: 'Modification Of Orders',
      local: 'en-US',
      job_type_id: 'modifications_of_orders',
    },
    {
      vertical: 'family_lawyer',
      job_type: 'Parent Timesharing',
      local: 'en-US',
      job_type_id: 'parent_timesharing',
    },
    {
      vertical: 'family_lawyer',
      job_type: 'Paternity',
      local: 'en-US',
      job_type_id: 'paternity',
    },
    {
      vertical: 'family_lawyer',
      job_type: 'Prenups & Marital Agreements',
      local: 'en-US',
      job_type_id: 'prenuptial_postnuptial_agreements',
    },
    {
      vertical: 'family_lawyer',
      job_type: 'Probate',
      local: 'en-US',
      job_type_id: 'probate_family_lawyer',
    },
    {
      vertical: 'family_lawyer',
      job_type: 'Property Division',
      local: 'en-US',
      job_type_id: 'property_divisions',
    },
    {
      vertical: 'family_lawyer',
      job_type: 'Restraining Orders',
      local: 'en-US',
      job_type_id: 'protective_orders_domestic_violence_issues',
    },
    {
      vertical: 'family_lawyer',
      job_type: 'Spousal Support & Alimony',
      local: 'en-US',
      job_type_id: 'spousal_support_alimony',
    },
    {
      vertical: 'family_lawyer',
      job_type: 'Uncontested Divorce',
      local: 'en-US',
      job_type_id: 'uncontested_divorce',
    },
  ],
  fencing_pro: [
    {
      vertical: 'fencing_pro',
      job_type: 'Custom Construction',
      local: 'en-US',
      job_type_id: 'custom_construction',
    },
    {
      vertical: 'fencing_pro',
      job_type: 'Fence Design',
      local: 'en-US',
      job_type_id: 'fence_design',
    },
    {
      vertical: 'fencing_pro',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'fencing_other',
    },
    {
      vertical: 'fencing_pro',
      job_type: 'Fence Installation',
      local: 'en-US',
      job_type_id: 'installation_fencing_pro',
    },
    {
      vertical: 'fencing_pro',
      job_type: 'Repairs & Maintenance',
      local: 'en-US',
      job_type_id: 'repairs_maintenance_fencing_pro',
    },
  ],
  financial_planner: [
    {
      vertical: 'financial_planner',
      job_type: 'Education Savings',
      local: 'en-US',
      job_type_id: 'education_savings',
    },
    {
      vertical: 'financial_planner',
      job_type: 'Estate Planning',
      local: 'en-US',
      job_type_id: 'estate_financial_planning',
    },
    {
      vertical: 'financial_planner',
      job_type: 'Financial Planning',
      local: 'en-US',
      job_type_id: 'financial_planning',
    },
    {
      vertical: 'financial_planner',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'financial_planning_other',
    },
    {
      vertical: 'financial_planner',
      job_type: 'Home Purchase',
      local: 'en-US',
      job_type_id: 'home_purchase',
    },
    {
      vertical: 'financial_planner',
      job_type: 'Insurance',
      local: 'en-US',
      job_type_id: 'insurance',
    },
    {
      vertical: 'financial_planner',
      job_type: 'Investment Advising',
      local: 'en-US',
      job_type_id: 'investment_advising',
    },
    {
      vertical: 'financial_planner',
      job_type: 'Retirement',
      local: 'en-US',
      job_type_id: 'retirement_savings',
    },
    {
      vertical: 'financial_planner',
      job_type: 'Tax Planning',
      local: 'en-US',
      job_type_id: 'tax_planning',
    },
  ],
  first_aid_trainer: [
    {
      vertical: 'first_aid_trainer',
      job_type: 'Advanced Cardiac Life Support',
      local: 'en-US',
      job_type_id: 'advanced_cardiac_life_support',
    },
    {
      vertical: 'first_aid_trainer',
      job_type: 'AED - Defibrillator',
      local: 'en-US',
      job_type_id: 'aed_defibrillator',
    },
    {
      vertical: 'first_aid_trainer',
      job_type: 'Basic Life Support',
      local: 'en-US',
      job_type_id: 'basic_life_support',
    },
    {
      vertical: 'first_aid_trainer',
      job_type: 'CPR',
      local: 'en-US',
      job_type_id: 'cpr',
    },
    {
      vertical: 'first_aid_trainer',
      job_type: 'First Aid',
      local: 'en-US',
      job_type_id: 'first_aid',
    },
    {
      vertical: 'first_aid_trainer',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'first_aid_trainer_other',
    },
    {
      vertical: 'first_aid_trainer',
      job_type: 'Pediatric Advanced Life Support',
      local: 'en-US',
      job_type_id: 'pediatric_advanced_life_support',
    },
    {
      vertical: 'first_aid_trainer',
      job_type: 'Pediatric CPR And First Aid',
      local: 'en-US',
      job_type_id: 'pediatric_cpr_first_aid',
    },
    {
      vertical: 'first_aid_trainer',
      job_type: 'Pet CPR And First Aid',
      local: 'en-US',
      job_type_id: 'pet_cpr_first_aid',
    },
    {
      vertical: 'first_aid_trainer',
      job_type: 'Wilderness First Aid',
      local: 'en-US',
      job_type_id: 'wilderness_first_aid',
    },
  ],
  flooring_pro: [
    {
      vertical: 'flooring_pro',
      job_type: 'Baseboards',
      local: 'en-US',
      job_type_id: 'baseboards',
    },
    {
      vertical: 'flooring_pro',
      job_type: 'Carpet',
      local: 'en-US',
      job_type_id: 'carpet',
    },
    {
      vertical: 'flooring_pro',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'flooring_other',
    },
    {
      vertical: 'flooring_pro',
      job_type: 'Hardwood',
      local: 'en-US',
      job_type_id: 'hardwood',
    },
    {
      vertical: 'flooring_pro',
      job_type: 'Installing Floors',
      local: 'en-US',
      job_type_id: 'installation_flooring_pro',
    },
    {
      vertical: 'flooring_pro',
      job_type: 'Marble',
      local: 'en-US',
      job_type_id: 'marble',
    },
    {
      vertical: 'flooring_pro',
      job_type: 'Floor Polishing',
      local: 'en-US',
      job_type_id: 'polishing',
    },
    {
      vertical: 'flooring_pro',
      job_type: 'Floor Refinishing',
      local: 'en-US',
      job_type_id: 'refinishing',
    },
    {
      vertical: 'flooring_pro',
      job_type: 'Floor Repair & Maintenance',
      local: 'en-US',
      job_type_id: 'repair_maintenance_flooring_pro',
    },
    {
      vertical: 'flooring_pro',
      job_type: 'Stair Flooring',
      local: 'en-US',
      job_type_id: 'stair_flooring',
    },
    {
      vertical: 'flooring_pro',
      job_type: 'Tile',
      local: 'en-US',
      job_type_id: 'tile',
    },
    {
      vertical: 'flooring_pro',
      job_type: 'Wood Floor Sanding',
      local: 'en-US',
      job_type_id: 'wood_floor_sanding',
    },
  ],
  foundation_pro: [
    {
      vertical: 'foundation_pro',
      job_type: 'Basement Waterproofing',
      local: 'en-US',
      job_type_id: 'basement_waterproofing',
    },
    {
      vertical: 'foundation_pro',
      job_type: 'Crawl Space Foundation',
      local: 'en-US',
      job_type_id: 'crawl_spaces',
    },
    {
      vertical: 'foundation_pro',
      job_type: 'Foundation Drainage',
      local: 'en-US',
      job_type_id: 'foundation_drainage',
    },
    {
      vertical: 'foundation_pro',
      job_type: 'Foundation Installation',
      local: 'en-US',
      job_type_id: 'foundation_installation',
    },
    {
      vertical: 'foundation_pro',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'foundation_pro_other',
    },
    {
      vertical: 'foundation_pro',
      job_type: 'Foundation Raising',
      local: 'en-US',
      job_type_id: 'foundation_raising',
    },
    {
      vertical: 'foundation_pro',
      job_type: 'Repairs & Maintenance',
      local: 'en-US',
      job_type_id: 'repairs_maintenance_foundation_pro',
    },
    {
      vertical: 'foundation_pro',
      job_type: 'Slab Foundation',
      local: 'en-US',
      job_type_id: 'slab_foundation',
    },
    {
      vertical: 'foundation_pro',
      job_type: 'Steel Pier Pilings',
      local: 'en-US',
      job_type_id: 'steel_pier_pilings',
    },
    {
      vertical: 'foundation_pro',
      job_type: 'Water & Moisture Control',
      local: 'en-US',
      job_type_id: 'water_moisture_control',
    },
  ],
  funeral_home: [
    {
      vertical: 'funeral_home',
      job_type: 'Burials',
      local: 'en-US',
      job_type_id: 'burials',
    },
    {
      vertical: 'funeral_home',
      job_type: 'Caskets',
      local: 'en-US',
      job_type_id: 'caskets',
    },
    {
      vertical: 'funeral_home',
      job_type: 'Cremation',
      local: 'en-US',
      job_type_id: 'cremation',
    },
    {
      vertical: 'funeral_home',
      job_type: 'Flowers',
      local: 'en-US',
      job_type_id: 'funeral_home_flowers',
    },
    {
      vertical: 'funeral_home',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'funeral_home_other',
    },
    {
      vertical: 'funeral_home',
      job_type: 'Transportation And Transfers',
      local: 'en-US',
      job_type_id: 'funeral_home_transportation_transfers',
    },
    {
      vertical: 'funeral_home',
      job_type: 'Veteran Services',
      local: 'en-US',
      job_type_id: 'funeral_home_veteran_services',
    },
    {
      vertical: 'funeral_home',
      job_type: 'Funerals',
      local: 'en-US',
      job_type_id: 'funerals',
    },
    {
      vertical: 'funeral_home',
      job_type: 'Gravestones',
      local: 'en-US',
      job_type_id: 'gravestones',
    },
    {
      vertical: 'funeral_home',
      job_type: 'Grief Services',
      local: 'en-US',
      job_type_id: 'grief_services',
    },
    {
      vertical: 'funeral_home',
      job_type: 'Memorials',
      local: 'en-US',
      job_type_id: 'memorials',
    },
    {
      vertical: 'funeral_home',
      job_type: 'Urns',
      local: 'en-US',
      job_type_id: 'urns',
    },
  ],
  garage_door_pro: [
    {
      vertical: 'garage_door_pro',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'garage_door_other',
    },
    {
      vertical: 'garage_door_pro',
      job_type: 'General Maintenance',
      local: 'en-US',
      job_type_id: 'general_maintenance',
    },
    {
      vertical: 'garage_door_pro',
      job_type: 'Install Garage Door',
      local: 'en-US',
      job_type_id: 'install_garage_door',
    },
    {
      vertical: 'garage_door_pro',
      job_type: 'Install Opener',
      local: 'en-US',
      job_type_id: 'install_opener',
    },
    {
      vertical: 'garage_door_pro',
      job_type: 'Repair Cables',
      local: 'en-US',
      job_type_id: 'repair_cables',
    },
    {
      vertical: 'garage_door_pro',
      job_type: 'Repair Garage Door',
      local: 'en-US',
      job_type_id: 'repair_garage_door',
    },
    {
      vertical: 'garage_door_pro',
      job_type: 'Repair Gate',
      local: 'en-US',
      job_type_id: 'repair_gate',
    },
    {
      vertical: 'garage_door_pro',
      job_type: 'Repair Rollers And Tracks',
      local: 'en-US',
      job_type_id: 'repair_rollers_tracks',
    },
    {
      vertical: 'garage_door_pro',
      job_type: 'Replace Door Panels',
      local: 'en-US',
      job_type_id: 'replace_door_panels',
    },
    {
      vertical: 'garage_door_pro',
      job_type: 'Replace Opener',
      local: 'en-US',
      job_type_id: 'replace_opener',
    },
    {
      vertical: 'garage_door_pro',
      job_type: 'Replace Springs',
      local: 'en-US',
      job_type_id: 'replace_springs',
    },
  ],
  general_contractor: [
    {
      vertical: 'general_contractor',
      job_type: 'Accessory Buildings',
      local: 'en-US',
      job_type_id: 'accessory_buildings',
    },
    {
      vertical: 'general_contractor',
      job_type: 'Bathroom Remodel',
      local: 'en-US',
      job_type_id: 'bathroom_remodel',
    },
    {
      vertical: 'general_contractor',
      job_type: 'Decks And Patio',
      local: 'en-US',
      job_type_id: 'decks_patio',
    },
    {
      vertical: 'general_contractor',
      job_type: 'Exterior Finishing',
      local: 'en-US',
      job_type_id: 'exterior_finishing',
    },
    {
      vertical: 'general_contractor',
      job_type: 'Foundations And Concrete',
      local: 'en-US',
      job_type_id: 'foundations_concrete',
    },
    {
      vertical: 'general_contractor',
      job_type: 'Commercial Projects',
      local: 'en-US',
      job_type_id: 'general_contractor_commercial_projects',
    },
    {
      vertical: 'general_contractor',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'general_contractor_other',
    },
    {
      vertical: 'general_contractor',
      job_type: 'Home Addition',
      local: 'en-US',
      job_type_id: 'home_addition',
    },
    {
      vertical: 'general_contractor',
      job_type: 'Home Building',
      local: 'en-US',
      job_type_id: 'home_building',
    },
    {
      vertical: 'general_contractor',
      job_type: 'Home Remodel And Renovation',
      local: 'en-US',
      job_type_id: 'home_remodel_renovation',
    },
    {
      vertical: 'general_contractor',
      job_type: 'Interior Finishing',
      local: 'en-US',
      job_type_id: 'interior_finishing',
    },
    {
      vertical: 'general_contractor',
      job_type: 'Kitchen Remodel',
      local: 'en-US',
      job_type_id: 'kitchen_remodel',
    },
    {
      vertical: 'general_contractor',
      job_type: 'Roofing',
      local: 'en-US',
      job_type_id: 'roofing',
    },
  ],
  handyman: [
    {
      vertical: 'handyman',
      job_type: 'Assemble Furniture',
      local: 'en-US',
      job_type_id: 'assemble_furniture',
    },
    {
      vertical: 'handyman',
      job_type: 'Clean Gutters',
      local: 'en-US',
      job_type_id: 'clean_gutters',
    },
    {
      vertical: 'handyman',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'handyman_other',
    },
    {
      vertical: 'handyman',
      job_type: 'Install Drywall',
      local: 'en-US',
      job_type_id: 'install_drywall',
    },
    {
      vertical: 'handyman',
      job_type: 'Install Fan',
      local: 'en-US',
      job_type_id: 'install_fan',
    },
    {
      vertical: 'handyman',
      job_type: 'Install Flooring',
      local: 'en-US',
      job_type_id: 'install_flooring',
    },
    {
      vertical: 'handyman',
      job_type: 'Install Tile',
      local: 'en-US',
      job_type_id: 'install_tile',
    },
    {
      vertical: 'handyman',
      job_type: 'Install Water Fixtures',
      local: 'en-US',
      job_type_id: 'install_water_fixtures',
    },
    {
      vertical: 'handyman',
      job_type: 'Mount TV',
      local: 'en-US',
      job_type_id: 'mount_tv',
    },
    {
      vertical: 'handyman',
      job_type: 'Paint Indoors',
      local: 'en-US',
      job_type_id: 'paint_indoors',
    },
    {
      vertical: 'handyman',
      job_type: 'Paint Outdoors',
      local: 'en-US',
      job_type_id: 'paint_outdoors',
    },
    {
      vertical: 'handyman',
      job_type: 'Repair Drywall',
      local: 'en-US',
      job_type_id: 'repair_drywall',
    },
    {
      vertical: 'handyman',
      job_type: 'Repair Fan',
      local: 'en-US',
      job_type_id: 'repair_fan',
    },
    {
      vertical: 'handyman',
      job_type: 'Repair Flooring',
      local: 'en-US',
      job_type_id: 'repair_flooring',
    },
    {
      vertical: 'handyman',
      job_type: 'Repair Water Fixtures',
      local: 'en-US',
      job_type_id: 'repair_water_fixtures',
    },
    {
      vertical: 'handyman',
      job_type: 'Replace Tile',
      local: 'en-US',
      job_type_id: 'replace_tile',
    },
  ],
  home_inspector: [
    {
      vertical: 'home_inspector',
      job_type: 'Buyer Seller Inspection',
      local: 'en-US',
      job_type_id: 'buyer_seller_inspection',
    },
    {
      vertical: 'home_inspector',
      job_type: 'Commercial Properties',
      local: 'en-US',
      job_type_id: 'commercial_properties',
    },
    {
      vertical: 'home_inspector',
      job_type: 'Fire Safety',
      local: 'en-US',
      job_type_id: 'fire_safety',
    },
    {
      vertical: 'home_inspector',
      job_type: 'Fireplace And Chimney',
      local: 'en-US',
      job_type_id: 'fireplace_chimney',
    },
    {
      vertical: 'home_inspector',
      job_type: 'Full Home Inspection',
      local: 'en-US',
      job_type_id: 'full_home_inspection',
    },
    {
      vertical: 'home_inspector',
      job_type: 'Electrical',
      local: 'en-US',
      job_type_id: 'home_inspector_electrical',
    },
    {
      vertical: 'home_inspector',
      job_type: 'Foundation',
      local: 'en-US',
      job_type_id: 'home_inspector_foundation',
    },
    {
      vertical: 'home_inspector',
      job_type: 'HVAC',
      local: 'en-US',
      job_type_id: 'home_inspector_hvac',
    },
    {
      vertical: 'home_inspector',
      job_type: 'Mold',
      local: 'en-US',
      job_type_id: 'home_inspector_mold',
    },
    {
      vertical: 'home_inspector',
      job_type: 'New Construction',
      local: 'en-US',
      job_type_id: 'home_inspector_new_construction',
    },
    {
      vertical: 'home_inspector',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'home_inspector_other',
    },
    {
      vertical: 'home_inspector',
      job_type: 'Plumbing',
      local: 'en-US',
      job_type_id: 'home_inspector_plumbing',
    },
    {
      vertical: 'home_inspector',
      job_type: 'Roof',
      local: 'en-US',
      job_type_id: 'home_inspector_roof',
    },
    {
      vertical: 'home_inspector',
      job_type: 'Structural',
      local: 'en-US',
      job_type_id: 'home_inspector_structural',
    },
    {
      vertical: 'home_inspector',
      job_type: 'Home Warranty',
      local: 'en-US',
      job_type_id: 'home_warranty',
    },
    {
      vertical: 'home_inspector',
      job_type: 'Water Testing',
      local: 'en-US',
      job_type_id: 'water_testing',
    },
    {
      vertical: 'home_inspector',
      job_type: 'Well Or Septic System',
      local: 'en-US',
      job_type_id: 'well_or_septic_system',
    },
    {
      vertical: 'home_inspector',
      job_type: 'Wood And Insects',
      local: 'en-US',
      job_type_id: 'wood_insects',
    },
  ],
  home_security: [
    {
      vertical: 'home_security',
      job_type: 'Access Control',
      local: 'en-US',
      job_type_id: 'access_control',
    },
    {
      vertical: 'home_security',
      job_type: 'Alarm Systems',
      local: 'en-US',
      job_type_id: 'alarm_systems',
    },
    {
      vertical: 'home_security',
      job_type: 'Commercial Security Systems',
      local: 'en-US',
      job_type_id: 'commercial_security_systems',
    },
    {
      vertical: 'home_security',
      job_type: 'Fire And Smoke Detection',
      local: 'en-US',
      job_type_id: 'fire_smoke_detection',
    },
    {
      vertical: 'home_security',
      job_type: 'Doors',
      local: 'en-US',
      job_type_id: 'home_security_doors',
    },
    {
      vertical: 'home_security',
      job_type: 'Locks',
      local: 'en-US',
      job_type_id: 'home_security_locks',
    },
    {
      vertical: 'home_security',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'home_security_other',
    },
    {
      vertical: 'home_security',
      job_type: 'Windows',
      local: 'en-US',
      job_type_id: 'home_security_windows',
    },
    {
      vertical: 'home_security',
      job_type: 'Monitoring Services',
      local: 'en-US',
      job_type_id: 'monitoring_services',
    },
    {
      vertical: 'home_security',
      job_type: 'Residential Security Systems',
      local: 'en-US',
      job_type_id: 'residential_security_systems',
    },
    {
      vertical: 'home_security',
      job_type: 'Smart Home Integration',
      local: 'en-US',
      job_type_id: 'smart_home_integration',
    },
    {
      vertical: 'home_security',
      job_type: 'Video Surveillance',
      local: 'en-US',
      job_type_id: 'video_surveillance',
    },
  ],
  home_theater: [
    {
      vertical: 'home_theater',
      job_type: 'Audio Video Installation',
      local: 'en-US',
      job_type_id: 'audio_video_installation',
    },
    {
      vertical: 'home_theater',
      job_type: 'Commercial Projects',
      local: 'en-US',
      job_type_id: 'commercial_projects',
    },
    {
      vertical: 'home_theater',
      job_type: 'Home Theater Design',
      local: 'en-US',
      job_type_id: 'home_theater_design',
    },
    {
      vertical: 'home_theater',
      job_type: 'Lighting',
      local: 'en-US',
      job_type_id: 'home_theater_lighting',
    },
    {
      vertical: 'home_theater',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'home_theater_other',
    },
    {
      vertical: 'home_theater',
      job_type: 'Smart Home Integration',
      local: 'en-US',
      job_type_id: 'home_theater_smart_home_integration',
    },
    {
      vertical: 'home_theater',
      job_type: 'Install TV',
      local: 'en-US',
      job_type_id: 'install_tv',
    },
    {
      vertical: 'home_theater',
      job_type: 'Network And IT Services',
      local: 'en-US',
      job_type_id: 'network_it_services',
    },
    {
      vertical: 'home_theater',
      job_type: 'Outdoor Installations',
      local: 'en-US',
      job_type_id: 'outdoor_installations',
    },
    {
      vertical: 'home_theater',
      job_type: 'Soundproofing',
      local: 'en-US',
      job_type_id: 'soundproofing',
    },
    {
      vertical: 'home_theater',
      job_type: 'Whole House Audio Video',
      local: 'en-US',
      job_type_id: 'whole_house_audio_video',
    },
  ],
  hvac: [
    {
      vertical: 'hvac',
      job_type: 'AC Maintenance',
      local: 'en-US',
      job_type_id: 'ac_maintenance',
    },
    {
      vertical: 'hvac',
      job_type: 'Clean Ducts & Vents',
      local: 'en-US',
      job_type_id: 'clean_ducts_vents',
    },
    {
      vertical: 'hvac',
      job_type: 'Ductless Heating And AC Services',
      local: 'en-US',
      job_type_id: 'ductless_heating_and_ac_services',
    },
    {
      vertical: 'hvac',
      job_type: 'Heating Maintenance',
      local: 'en-US',
      job_type_id: 'heating_maintenance',
    },
    {
      vertical: 'hvac',
      job_type: 'HVAC Maintenance',
      local: 'en-US',
      job_type_id: 'hvac_maintenance',
    },
    {
      vertical: 'hvac',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'hvac_other',
    },
    {
      vertical: 'hvac',
      job_type: 'Install AC',
      local: 'en-US',
      job_type_id: 'install_ac',
    },
    {
      vertical: 'hvac',
      job_type: 'Install Ducts & Vents',
      local: 'en-US',
      job_type_id: 'install_ducts_vents',
    },
    {
      vertical: 'hvac',
      job_type: 'Install Heating System',
      local: 'en-US',
      job_type_id: 'install_heating_system',
    },
    {
      vertical: 'hvac',
      job_type: 'Install Thermostat',
      local: 'en-US',
      job_type_id: 'install_thermostat',
    },
    {
      vertical: 'hvac',
      job_type: 'Repair AC',
      local: 'en-US',
      job_type_id: 'repair_ac',
    },
    {
      vertical: 'hvac',
      job_type: 'Repair Ducts & Vents',
      local: 'en-US',
      job_type_id: 'repair_ducts_vents',
    },
    {
      vertical: 'hvac',
      job_type: 'Repair Heating System',
      local: 'en-US',
      job_type_id: 'repair_heating_system',
    },
    {
      vertical: 'hvac',
      job_type: 'Repair HVAC',
      local: 'en-US',
      job_type_id: 'repair_hvac',
    },
    {
      vertical: 'hvac',
      job_type: 'Repair Thermostat',
      local: 'en-US',
      job_type_id: 'repair_thermostat',
    },
  ],
  immigration_lawyer: [
    {
      vertical: 'immigration_lawyer',
      job_type: 'Asylum',
      local: 'en-US',
      job_type_id: 'asylum',
    },
    {
      vertical: 'immigration_lawyer',
      job_type: 'Citizenship',
      local: 'en-US',
      job_type_id: 'citizenship',
    },
    {
      vertical: 'immigration_lawyer',
      job_type: 'Deportation',
      local: 'en-US',
      job_type_id: 'deportation',
    },
    {
      vertical: 'immigration_lawyer',
      job_type: 'Family-based Immigration',
      local: 'en-US',
      job_type_id: 'family_based_immigration',
    },
    {
      vertical: 'immigration_lawyer',
      job_type: 'Green Card',
      local: 'en-US',
      job_type_id: 'green_card',
    },
    {
      vertical: 'immigration_lawyer',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'immigration_lawyer_other',
    },
    {
      vertical: 'immigration_lawyer',
      job_type: 'Investment Visa',
      local: 'en-US',
      job_type_id: 'investment_visa',
    },
    {
      vertical: 'immigration_lawyer',
      job_type: 'Outbound Work Permits',
      local: 'en-US',
      job_type_id: 'outbound_work_permits',
    },
    {
      vertical: 'immigration_lawyer',
      job_type: 'Student Visa',
      local: 'en-US',
      job_type_id: 'student_visa',
    },
    {
      vertical: 'immigration_lawyer',
      job_type: 'Visitor Visa',
      local: 'en-US',
      job_type_id: 'visitor_visa',
    },
    {
      vertical: 'immigration_lawyer',
      job_type: 'Work Visa',
      local: 'en-US',
      job_type_id: 'work_visa',
    },
  ],
  interior_designer: [
    {
      vertical: 'interior_designer',
      job_type: 'Appliance Selection',
      local: 'en-US',
      job_type_id: 'appliance_selection',
    },
    {
      vertical: 'interior_designer',
      job_type: 'Cabinetry & Hardware Design',
      local: 'en-US',
      job_type_id: 'cabinetry_molding_hardware_design',
    },
    {
      vertical: 'interior_designer',
      job_type: 'Decorating',
      local: 'en-US',
      job_type_id: 'decorating',
    },
    {
      vertical: 'interior_designer',
      job_type: 'Door Design',
      local: 'en-US',
      job_type_id: 'door_design',
    },
    {
      vertical: 'interior_designer',
      job_type: 'Exterior Building Design',
      local: 'en-US',
      job_type_id: 'exterior_building_design',
    },
    {
      vertical: 'interior_designer',
      job_type: 'Flooring Selection',
      local: 'en-US',
      job_type_id: 'flooring_selection',
    },
    {
      vertical: 'interior_designer',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'interior_designer_other',
    },
    {
      vertical: 'interior_designer',
      job_type: 'Landscaping Design',
      local: 'en-US',
      job_type_id: 'landscaping_design',
    },
    {
      vertical: 'interior_designer',
      job_type: 'Lighting Design',
      local: 'en-US',
      job_type_id: 'lighting_design',
    },
    {
      vertical: 'interior_designer',
      job_type: 'Multifamily Housing Design',
      local: 'en-US',
      job_type_id: 'multifamily_housing_design',
    },
    {
      vertical: 'interior_designer',
      job_type: 'Room Planning',
      local: 'en-US',
      job_type_id: 'room_planning',
    },
    {
      vertical: 'interior_designer',
      job_type: 'Stone, Brick, & Stucco Design',
      local: 'en-US',
      job_type_id: 'stone_brick_stucco_design',
    },
    {
      vertical: 'interior_designer',
      job_type: 'Window Design',
      local: 'en-US',
      job_type_id: 'window_design',
    },
  ],
  ip_lawyer: [
    {
      vertical: 'ip_lawyer',
      job_type: 'Advertisements',
      local: 'en-US',
      job_type_id: 'advertisements',
    },
    {
      vertical: 'ip_lawyer',
      job_type: 'Copyright',
      local: 'en-US',
      job_type_id: 'copyright',
    },
    {
      vertical: 'ip_lawyer',
      job_type: 'Export Control',
      local: 'en-US',
      job_type_id: 'export_control',
    },
    {
      vertical: 'ip_lawyer',
      job_type: 'Franchises',
      local: 'en-US',
      job_type_id: 'franchises',
    },
    {
      vertical: 'ip_lawyer',
      job_type: 'International IP',
      local: 'en-US',
      job_type_id: 'international_ip',
    },
    {
      vertical: 'ip_lawyer',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'ip_lawyer_other',
    },
    {
      vertical: 'ip_lawyer',
      job_type: 'IP Strategy',
      local: 'en-US',
      job_type_id: 'ip_strategy',
    },
    {
      vertical: 'ip_lawyer',
      job_type: 'IP Transactions',
      local: 'en-US',
      job_type_id: 'ip_transactions',
    },
    {
      vertical: 'ip_lawyer',
      job_type: 'Licensing',
      local: 'en-US',
      job_type_id: 'licensing',
    },
    {
      vertical: 'ip_lawyer',
      job_type: 'Litigation',
      local: 'en-US',
      job_type_id: 'litigation_ip_lawyer',
    },
    {
      vertical: 'ip_lawyer',
      job_type: 'Non-disclosure Agreements',
      local: 'en-US',
      job_type_id: 'non_disclosure_agreements_ip_lawyer',
    },
    {
      vertical: 'ip_lawyer',
      job_type: 'Patents',
      local: 'en-US',
      job_type_id: 'patents',
    },
    {
      vertical: 'ip_lawyer',
      job_type: 'Technology Transfer',
      local: 'en-US',
      job_type_id: 'technology_transfer',
    },
    {
      vertical: 'ip_lawyer',
      job_type: 'Trade Secrets',
      local: 'en-US',
      job_type_id: 'trade_secrets',
    },
    {
      vertical: 'ip_lawyer',
      job_type: 'Trademarks',
      local: 'en-US',
      job_type_id: 'trademarks',
    },
  ],
  junk_removal: [
    {
      vertical: 'junk_removal',
      job_type: 'Appliance Removal',
      local: 'en-US',
      job_type_id: 'appliance_removal',
    },
    {
      vertical: 'junk_removal',
      job_type: 'Construction Waste Removal',
      local: 'en-US',
      job_type_id: 'construction_waste_removal',
    },
    {
      vertical: 'junk_removal',
      job_type: 'Donation Pickup',
      local: 'en-US',
      job_type_id: 'donation_pickup',
    },
    {
      vertical: 'junk_removal',
      job_type: 'Electronic Waste Removal',
      local: 'en-US',
      job_type_id: 'electronic_waste_removal',
    },
    {
      vertical: 'junk_removal',
      job_type: 'Furniture Removal',
      local: 'en-US',
      job_type_id: 'furniture_removal',
    },
    {
      vertical: 'junk_removal',
      job_type: 'General Junk Removal',
      local: 'en-US',
      job_type_id: 'general_junk_removal',
    },
    {
      vertical: 'junk_removal',
      job_type: 'Hazardous Waste Removal',
      local: 'en-US',
      job_type_id: 'hazardous_waste_removal',
    },
    {
      vertical: 'junk_removal',
      job_type: 'House/garage Cleanout',
      local: 'en-US',
      job_type_id: 'house_garage_cleanout',
    },
    {
      vertical: 'junk_removal',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'junk_removal_other',
    },
    {
      vertical: 'junk_removal',
      job_type: 'Yard Waste Removal',
      local: 'en-US',
      job_type_id: 'yard_waste_removal',
    },
  ],
  labor_lawyer: [
    {
      vertical: 'labor_lawyer',
      job_type: 'Employment Contracts',
      local: 'en-US',
      job_type_id: 'employment_contracts_labor_lawyer',
    },
    {
      vertical: 'labor_lawyer',
      job_type: 'Employment Law',
      local: 'en-US',
      job_type_id: 'employment_law_labor_lawyer',
    },
    {
      vertical: 'labor_lawyer',
      job_type: 'Exempt Employee Status',
      local: 'en-US',
      job_type_id: 'exempt_employee_status',
    },
    {
      vertical: 'labor_lawyer',
      job_type: 'Labor Law',
      local: 'en-US',
      job_type_id: 'labor_law',
    },
    {
      vertical: 'labor_lawyer',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'labor_lawyer_other',
    },
    {
      vertical: 'labor_lawyer',
      job_type: 'Labor Negotiations',
      local: 'en-US',
      job_type_id: 'labor_negotiations',
    },
    {
      vertical: 'labor_lawyer',
      job_type: 'Privacy Rights',
      local: 'en-US',
      job_type_id: 'privacy_rights',
    },
    {
      vertical: 'labor_lawyer',
      job_type: 'Sexual Harassment',
      local: 'en-US',
      job_type_id: 'sexual_harassment',
    },
    {
      vertical: 'labor_lawyer',
      job_type: 'Unpaid Overtime',
      local: 'en-US',
      job_type_id: 'unpaid_overtime',
    },
    {
      vertical: 'labor_lawyer',
      job_type: 'Whistle Blowing',
      local: 'en-US',
      job_type_id: 'whistle_blowing',
    },
    {
      vertical: 'labor_lawyer',
      job_type: "Workers' Compensation",
      local: 'en-US',
      job_type_id: 'workers_compensation_labor_lawyer',
    },
    {
      vertical: 'labor_lawyer',
      job_type: 'Wrongful Termination',
      local: 'en-US',
      job_type_id: 'wrongful_termination',
    },
  ],
  landscaper: [
    {
      vertical: 'landscaper',
      job_type: 'Artificial Turf Installation',
      local: 'en-US',
      job_type_id: 'artificial_turf_installation',
    },
    {
      vertical: 'landscaper',
      job_type: 'Concrete Masonry',
      local: 'en-US',
      job_type_id: 'concrete_masonry',
    },
    {
      vertical: 'landscaper',
      job_type: 'Driveway Landscaping',
      local: 'en-US',
      job_type_id: 'driveway_landscaping',
    },
    {
      vertical: 'landscaper',
      job_type: 'Garden Decorations',
      local: 'en-US',
      job_type_id: 'garden_decorations',
    },
    {
      vertical: 'landscaper',
      job_type: 'Grading & Resloping',
      local: 'en-US',
      job_type_id: 'grading_resloping',
    },
    {
      vertical: 'landscaper',
      job_type: 'Hardscapes',
      local: 'en-US',
      job_type_id: 'hardscapes',
    },
    {
      vertical: 'landscaper',
      job_type: 'Landscape Design',
      local: 'en-US',
      job_type_id: 'landscape_design',
    },
    {
      vertical: 'landscaper',
      job_type: 'Landscape Installations',
      local: 'en-US',
      job_type_id: 'landscape_installations',
    },
    {
      vertical: 'landscaper',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'landscaper_other',
    },
    {
      vertical: 'landscaper',
      job_type: 'Outdoor Steps',
      local: 'en-US',
      job_type_id: 'outdoor_steps',
    },
    {
      vertical: 'landscaper',
      job_type: 'Outdoor Water Features',
      local: 'en-US',
      job_type_id: 'outdoor_water_feature',
    },
    {
      vertical: 'landscaper',
      job_type: 'Driveway Or Walkway Paving',
      local: 'en-US',
      job_type_id: 'paving_driveway_walkway',
    },
    {
      vertical: 'landscaper',
      job_type: 'Retaining Walls',
      local: 'en-US',
      job_type_id: 'retaining_walls',
    },
    {
      vertical: 'landscaper',
      job_type: 'Walkway Landscaping',
      local: 'en-US',
      job_type_id: 'sidewalk_walkway_landscaping',
    },
    {
      vertical: 'landscaper',
      job_type: 'Sod & Seeding',
      local: 'en-US',
      job_type_id: 'sod_seeding',
    },
    {
      vertical: 'landscaper',
      job_type: 'Stone Masonry',
      local: 'en-US',
      job_type_id: 'stone_masonry',
    },
  ],
  language_instructor: [
    {
      vertical: 'language_instructor',
      job_type: 'Arabic',
      local: 'en-US',
      job_type_id: 'arabic',
    },
    {
      vertical: 'language_instructor',
      job_type: 'English',
      local: 'en-US',
      job_type_id: 'english',
    },
    {
      vertical: 'language_instructor',
      job_type: 'French',
      local: 'en-US',
      job_type_id: 'french',
    },
    {
      vertical: 'language_instructor',
      job_type: 'German',
      local: 'en-US',
      job_type_id: 'german',
    },
    {
      vertical: 'language_instructor',
      job_type: 'Hebrew',
      local: 'en-US',
      job_type_id: 'hebrew',
    },
    {
      vertical: 'language_instructor',
      job_type: 'Italian',
      local: 'en-US',
      job_type_id: 'italian',
    },
    {
      vertical: 'language_instructor',
      job_type: 'Japanese',
      local: 'en-US',
      job_type_id: 'japanese',
    },
    {
      vertical: 'language_instructor',
      job_type: 'Korean',
      local: 'en-US',
      job_type_id: 'korean',
    },
    {
      vertical: 'language_instructor',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'language_instructor_other',
    },
    {
      vertical: 'language_instructor',
      job_type: 'Mandarin',
      local: 'en-US',
      job_type_id: 'mandarin',
    },
    {
      vertical: 'language_instructor',
      job_type: 'Portuguese',
      local: 'en-US',
      job_type_id: 'portuguese',
    },
    {
      vertical: 'language_instructor',
      job_type: 'Russian',
      local: 'en-US',
      job_type_id: 'russian',
    },
    {
      vertical: 'language_instructor',
      job_type: 'Sign Language',
      local: 'en-US',
      job_type_id: 'sign_language',
    },
    {
      vertical: 'language_instructor',
      job_type: 'Spanish',
      local: 'en-US',
      job_type_id: 'spanish',
    },
  ],
  lawn_care: [
    {
      vertical: 'lawn_care',
      job_type: 'Irrigation System Repair / Maintenance',
      local: 'en-US',
      job_type_id: 'irrigation_system_repair_maintenance',
    },
    {
      vertical: 'lawn_care',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'lawn_care_other',
    },
    {
      vertical: 'lawn_care',
      job_type: 'Lawn Mowing / Maintenance',
      local: 'en-US',
      job_type_id: 'lawn_mowing_maintenance',
    },
    {
      vertical: 'lawn_care',
      job_type: 'Lawn Pest Control',
      local: 'en-US',
      job_type_id: 'lawn_pest_control',
    },
    {
      vertical: 'lawn_care',
      job_type: 'Mulching',
      local: 'en-US',
      job_type_id: 'mulching',
    },
    {
      vertical: 'lawn_care',
      job_type: 'Seeding',
      local: 'en-US',
      job_type_id: 'seeding',
    },
    {
      vertical: 'lawn_care',
      job_type: 'Sod Installation',
      local: 'en-US',
      job_type_id: 'sod_installation',
    },
    {
      vertical: 'lawn_care',
      job_type: 'Weed Control',
      local: 'en-US',
      job_type_id: 'weed_control',
    },
    {
      vertical: 'lawn_care',
      job_type: 'Yard Cleanup',
      local: 'en-US',
      job_type_id: 'yard_cleanup',
    },
  ],
  litigation_lawyer: [
    {
      vertical: 'litigation_lawyer',
      job_type: 'Appeals',
      local: 'en-US',
      job_type_id: 'appeals',
    },
    {
      vertical: 'litigation_lawyer',
      job_type: 'Case Assessment',
      local: 'en-US',
      job_type_id: 'case_assessment_litigation_lawyer',
    },
    {
      vertical: 'litigation_lawyer',
      job_type: 'Civil Litigation',
      local: 'en-US',
      job_type_id: 'civil_litigation',
    },
    {
      vertical: 'litigation_lawyer',
      job_type: 'Commercial Litigation',
      local: 'en-US',
      job_type_id: 'commercial_litigation',
    },
    {
      vertical: 'litigation_lawyer',
      job_type: 'Contracts And Agreements',
      local: 'en-US',
      job_type_id: 'contracts_and_agreements',
    },
    {
      vertical: 'litigation_lawyer',
      job_type: 'Criminal Litigation',
      local: 'en-US',
      job_type_id: 'criminal_litigation',
    },
    {
      vertical: 'litigation_lawyer',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'litigation_lawyer_other',
    },
    {
      vertical: 'litigation_lawyer',
      job_type: 'Mediation',
      local: 'en-US',
      job_type_id: 'mediation_litigation_lawyer',
    },
    {
      vertical: 'litigation_lawyer',
      job_type: 'Settlements',
      local: 'en-US',
      job_type_id: 'settlements',
    },
  ],
  locksmith: [
    {
      vertical: 'locksmith',
      job_type: 'Building Lockout',
      local: 'en-US',
      job_type_id: 'building_lockout',
    },
    {
      vertical: 'locksmith',
      job_type: 'Copy Building Keys',
      local: 'en-US',
      job_type_id: 'copy_building_key',
    },
    {
      vertical: 'locksmith',
      job_type: 'Copy Vehicle Key',
      local: 'en-US',
      job_type_id: 'copy_vehicle_key',
    },
    {
      vertical: 'locksmith',
      job_type: 'Install Hardware',
      local: 'en-US',
      job_type_id: 'install_hardware',
    },
    {
      vertical: 'locksmith',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'locksmith_other',
    },
    {
      vertical: 'locksmith',
      job_type: 'New Key Fob',
      local: 'en-US',
      job_type_id: 'new_key_fob',
    },
    {
      vertical: 'locksmith',
      job_type: 'Re-key Lock',
      local: 'en-US',
      job_type_id: 'rekey_lock',
    },
    {
      vertical: 'locksmith',
      job_type: 'Repair Hardware',
      local: 'en-US',
      job_type_id: 'repair_hardware',
    },
    {
      vertical: 'locksmith',
      job_type: 'Vehicle Lockout',
      local: 'en-US',
      job_type_id: 'vehicle_lockout',
    },
  ],
  malpractice_lawyer: [
    {
      vertical: 'malpractice_lawyer',
      job_type: 'Consent Issues',
      local: 'en-US',
      job_type_id: 'consent_issues',
    },
    {
      vertical: 'malpractice_lawyer',
      job_type: 'Diagnosis Errors',
      local: 'en-US',
      job_type_id: 'diagnosis_errors',
    },
    {
      vertical: 'malpractice_lawyer',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'malpractice_lawyer_other',
    },
    {
      vertical: 'malpractice_lawyer',
      job_type: 'Medical Negligence',
      local: 'en-US',
      job_type_id: 'medical_negligence',
    },
    {
      vertical: 'malpractice_lawyer',
      job_type: 'Treatment Errors',
      local: 'en-US',
      job_type_id: 'treatment_errors',
    },
  ],
  massage_center: [
    {
      vertical: 'massage_center',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'massage_center_other',
    },
  ],
  massage_school: [
    {
      vertical: 'massage_school',
      job_type: 'Advanced Neuromuscular Therapy',
      local: 'en-US',
      job_type_id: 'massage_school_advanced_neuromuscular_therapy',
    },
    {
      vertical: 'massage_school',
      job_type: 'Business And Ethics',
      local: 'en-US',
      job_type_id: 'massage_school_business_and_ethics',
    },
    {
      vertical: 'massage_school',
      job_type: 'Clinical Therapies',
      local: 'en-US',
      job_type_id: 'massage_school_clinical_therapies',
    },
    {
      vertical: 'massage_school',
      job_type: 'Continuing Education',
      local: 'en-US',
      job_type_id: 'massage_school_continuing_education',
    },
    {
      vertical: 'massage_school',
      job_type: 'Deep Tissue Massage',
      local: 'en-US',
      job_type_id: 'massage_school_deep_tissue_massage',
    },
    {
      vertical: 'massage_school',
      job_type: 'Energetics',
      local: 'en-US',
      job_type_id: 'massage_school_energetics',
    },
    {
      vertical: 'massage_school',
      job_type: 'Hydrotherapy',
      local: 'en-US',
      job_type_id: 'massage_school_hydrotherapy',
    },
    {
      vertical: 'massage_school',
      job_type: 'Lymphatic Massage',
      local: 'en-US',
      job_type_id: 'massage_school_lymphatic_massage',
    },
    {
      vertical: 'massage_school',
      job_type: 'Online Massage Therapy',
      local: 'en-US',
      job_type_id: 'massage_school_online_massage_therapy',
    },
    {
      vertical: 'massage_school',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'massage_school_other',
    },
    {
      vertical: 'massage_school',
      job_type: 'Prenatal Massage',
      local: 'en-US',
      job_type_id: 'massage_school_prenatal_massage',
    },
    {
      vertical: 'massage_school',
      job_type: 'Sports Massage',
      local: 'en-US',
      job_type_id: 'massage_school_sports_massage',
    },
    {
      vertical: 'massage_school',
      job_type: 'Student Massage Clinic',
      local: 'en-US',
      job_type_id: 'massage_school_student_massage_clinic',
    },
    {
      vertical: 'massage_school',
      job_type: 'Swedish Massage',
      local: 'en-US',
      job_type_id: 'massage_school_swedish_massage',
    },
    {
      vertical: 'massage_school',
      job_type: 'Massage Theory',
      local: 'en-US',
      job_type_id: 'massage_theory',
    },
    {
      vertical: 'massage_school',
      job_type: 'Massage Therapy Training',
      local: 'en-US',
      job_type_id: 'massage_therapy_training',
    },
  ],
  mover: [
    {
      vertical: 'mover',
      job_type: 'In-state Move',
      local: 'en-US',
      job_type_id: 'in_state_move',
    },
    {
      vertical: 'mover',
      job_type: 'Local Move',
      local: 'en-US',
      job_type_id: 'local_move',
    },
    {
      vertical: 'mover',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'mover_other',
    },
    {
      vertical: 'mover',
      job_type: 'Junk Removal',
      local: 'en-US',
      job_type_id: 'moving_junk_removal',
    },
    {
      vertical: 'mover',
      job_type: 'Storage',
      local: 'en-US',
      job_type_id: 'moving_storage',
    },
    {
      vertical: 'mover',
      job_type: 'Out-of-state Move',
      local: 'en-US',
      job_type_id: 'out_of_state_move',
    },
    {
      vertical: 'mover',
      job_type: 'Oversized Item Move',
      local: 'en-US',
      job_type_id: 'oversized_item_move',
    },
    {
      vertical: 'mover',
      job_type: 'Packing / Unpacking',
      local: 'en-US',
      job_type_id: 'packing_unpacking',
    },
    {
      vertical: 'mover',
      job_type: 'Senior Move',
      local: 'en-US',
      job_type_id: 'senior_move',
    },
    {
      vertical: 'mover',
      job_type: 'Vehicle Transport',
      local: 'en-US',
      job_type_id: 'vehicle_transport',
    },
  ],
  nursing_home: [
    {
      vertical: 'nursing_home',
      job_type: 'Cardiac Care',
      local: 'en-US',
      job_type_id: 'cardiac_care',
    },
    {
      vertical: 'nursing_home',
      job_type: 'General Medical Care',
      local: 'en-US',
      job_type_id: 'general_medical_care',
    },
    {
      vertical: 'nursing_home',
      job_type: 'Hospice',
      local: 'en-US',
      job_type_id: 'hospice',
    },
    {
      vertical: 'nursing_home',
      job_type: 'IV Therapy',
      local: 'en-US',
      job_type_id: 'iv_therapy',
    },
    {
      vertical: 'nursing_home',
      job_type: 'Meal Servics',
      local: 'en-US',
      job_type_id: 'nursing_home_meal_services',
    },
    {
      vertical: 'nursing_home',
      job_type: 'Memory Care',
      local: 'en-US',
      job_type_id: 'nursing_home_memory_care',
    },
    {
      vertical: 'nursing_home',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'nursing_home_other',
    },
    {
      vertical: 'nursing_home',
      job_type: 'Pain Management',
      local: 'en-US',
      job_type_id: 'nursing_home_pain_management',
    },
    {
      vertical: 'nursing_home',
      job_type: 'Rehabilitation Services',
      local: 'en-US',
      job_type_id: 'nursing_home_rehabilitation_services',
    },
    {
      vertical: 'nursing_home',
      job_type: 'Wellness Programs',
      local: 'en-US',
      job_type_id: 'nursing_home_wellness_programs',
    },
    {
      vertical: 'nursing_home',
      job_type: 'Outpatient Rehabilitation',
      local: 'en-US',
      job_type_id: 'outpatient_rehabilitation',
    },
    {
      vertical: 'nursing_home',
      job_type: 'Post-hospital Care',
      local: 'en-US',
      job_type_id: 'post_hospital_care',
    },
    {
      vertical: 'nursing_home',
      job_type: 'Respiratory And Pulmonary Care',
      local: 'en-US',
      job_type_id: 'respiratory_and_pulmonary_care',
    },
    {
      vertical: 'nursing_home',
      job_type: 'Senior Activity Programs',
      local: 'en-US',
      job_type_id: 'senior_activity_programs',
    },
    {
      vertical: 'nursing_home',
      job_type: 'Short-term Rehabilitation',
      local: 'en-US',
      job_type_id: 'short_term_rehabilitation',
    },
    {
      vertical: 'nursing_home',
      job_type: 'Wound Care',
      local: 'en-US',
      job_type_id: 'wound_care',
    },
  ],
  painter: [
    {
      vertical: 'painter',
      job_type: 'Exterior Painting',
      local: 'en-US',
      job_type_id: 'exterior_painting',
    },
    {
      vertical: 'painter',
      job_type: 'Interior Painting',
      local: 'en-US',
      job_type_id: 'interior_painting',
    },
    {
      vertical: 'painter',
      job_type: 'Paint Cabinets',
      local: 'en-US',
      job_type_id: 'paint_cabinets',
    },
    {
      vertical: 'painter',
      job_type: 'Paint Doors',
      local: 'en-US',
      job_type_id: 'paint_doors',
    },
    {
      vertical: 'painter',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'painter_other',
    },
    {
      vertical: 'painter',
      job_type: 'Repair Drywall',
      local: 'en-US',
      job_type_id: 'repair_drywall',
    },
    {
      vertical: 'painter',
      job_type: 'Repair Water Damage',
      local: 'en-US',
      job_type_id: 'repair_water_damage',
    },
    {
      vertical: 'painter',
      job_type: 'Staining',
      local: 'en-US',
      job_type_id: 'staining',
    },
  ],
  personal_injury_lawyer: [
    {
      vertical: 'personal_injury_lawyer',
      job_type: 'Assault & Battery',
      local: 'en-US',
      job_type_id: 'assault_battery',
    },
    {
      vertical: 'personal_injury_lawyer',
      job_type: 'Auto Accidents',
      local: 'en-US',
      job_type_id: 'auto_accidents',
    },
    {
      vertical: 'personal_injury_lawyer',
      job_type: 'Bicycle Accidents',
      local: 'en-US',
      job_type_id: 'bicycle_accidents',
    },
    {
      vertical: 'personal_injury_lawyer',
      job_type: 'Catastrophic Injuries',
      local: 'en-US',
      job_type_id: 'catastrophic_injuries',
    },
    {
      vertical: 'personal_injury_lawyer',
      job_type: 'Dog Bites',
      local: 'en-US',
      job_type_id: 'dog_bites',
    },
    {
      vertical: 'personal_injury_lawyer',
      job_type: 'Injuries From DUI',
      local: 'en-US',
      job_type_id: 'dui_injuries_personal_injury_lawyer',
    },
    {
      vertical: 'personal_injury_lawyer',
      job_type: 'Medical Malpractice',
      local: 'en-US',
      job_type_id: 'medical_malpractice',
    },
    {
      vertical: 'personal_injury_lawyer',
      job_type: 'Motorcycle Accidents',
      local: 'en-US',
      job_type_id: 'motorcycle_accidents',
    },
    {
      vertical: 'personal_injury_lawyer',
      job_type: 'Pain & Suffering',
      local: 'en-US',
      job_type_id: 'pain_suffering',
    },
    {
      vertical: 'personal_injury_lawyer',
      job_type: 'Pedestrian Accidents',
      local: 'en-US',
      job_type_id: 'pedestrian_accidents',
    },
    {
      vertical: 'personal_injury_lawyer',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'personal_injury_lawyer_other',
    },
    {
      vertical: 'personal_injury_lawyer',
      job_type: 'Product Liability',
      local: 'en-US',
      job_type_id: 'product_liability',
    },
    {
      vertical: 'personal_injury_lawyer',
      job_type: 'Slip & Fall',
      local: 'en-US',
      job_type_id: 'slip_fall',
    },
    {
      vertical: 'personal_injury_lawyer',
      job_type: 'Motorist Insurance Claims',
      local: 'en-US',
      job_type_id: 'uninsured_motorist_claims',
    },
    {
      vertical: 'personal_injury_lawyer',
      job_type: "Workers' Compensation",
      local: 'en-US',
      job_type_id: 'workers_compensation_personal_injury_lawyer',
    },
    {
      vertical: 'personal_injury_lawyer',
      job_type: 'Wrongful Death',
      local: 'en-US',
      job_type_id: 'wrongful_death',
    },
  ],
  personal_trainer: [
    {
      vertical: 'personal_trainer',
      job_type: 'Aerobics',
      local: 'en-US',
      job_type_id: 'aerobics',
    },
    {
      vertical: 'personal_trainer',
      job_type: 'Athletic Performance',
      local: 'en-US',
      job_type_id: 'athletic_performance',
    },
    {
      vertical: 'personal_trainer',
      job_type: 'Circuit Training',
      local: 'en-US',
      job_type_id: 'circuit_training',
    },
    {
      vertical: 'personal_trainer',
      job_type: 'Endurance',
      local: 'en-US',
      job_type_id: 'endurance',
    },
    {
      vertical: 'personal_trainer',
      job_type: 'Fitness Assessment',
      local: 'en-US',
      job_type_id: 'fitness_assessment',
    },
    {
      vertical: 'personal_trainer',
      job_type: 'HIIT',
      local: 'en-US',
      job_type_id: 'hiit',
    },
    {
      vertical: 'personal_trainer',
      job_type: 'Nutritional Counseling',
      local: 'en-US',
      job_type_id: 'nutritional_counseling',
    },
    {
      vertical: 'personal_trainer',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'personal_trainer_other',
    },
    {
      vertical: 'personal_trainer',
      job_type: 'Strength And Conditioning',
      local: 'en-US',
      job_type_id: 'strength_conditioning',
    },
    {
      vertical: 'personal_trainer',
      job_type: 'Weight Loss',
      local: 'en-US',
      job_type_id: 'weight_loss',
    },
    {
      vertical: 'personal_trainer',
      job_type: 'Yoga',
      local: 'en-US',
      job_type_id: 'yoga',
    },
  ],
  pest_control: [
    {
      vertical: 'pest_control',
      job_type: 'Ants',
      local: 'en-US',
      job_type_id: 'ants',
    },
    {
      vertical: 'pest_control',
      job_type: 'Bed Bugs',
      local: 'en-US',
      job_type_id: 'bed_bugs',
    },
    {
      vertical: 'pest_control',
      job_type: 'Bees',
      local: 'en-US',
      job_type_id: 'bees',
    },
    {
      vertical: 'pest_control',
      job_type: 'Cockroaches',
      local: 'en-US',
      job_type_id: 'cockroaches',
    },
    {
      vertical: 'pest_control',
      job_type: 'Fleas Or Mites',
      local: 'en-US',
      job_type_id: 'fleas_or_mites',
    },
    {
      vertical: 'pest_control',
      job_type: 'Hornets Or Wasps',
      local: 'en-US',
      job_type_id: 'hornets_or_wasps',
    },
    {
      vertical: 'pest_control',
      job_type: 'Mosquitoes',
      local: 'en-US',
      job_type_id: 'mosquitoes',
    },
    {
      vertical: 'pest_control',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'pest_control_other',
    },
    {
      vertical: 'pest_control',
      job_type: 'Inspection',
      local: 'en-US',
      job_type_id: 'pest_inspection',
    },
    {
      vertical: 'pest_control',
      job_type: 'Rodents',
      local: 'en-US',
      job_type_id: 'rodents',
    },
    {
      vertical: 'pest_control',
      job_type: 'Spiders',
      local: 'en-US',
      job_type_id: 'spiders',
    },
    {
      vertical: 'pest_control',
      job_type: 'Termites',
      local: 'en-US',
      job_type_id: 'termites',
    },
    {
      vertical: 'pest_control',
      job_type: 'Wildlife',
      local: 'en-US',
      job_type_id: 'wildlife',
    },
  ],
  pet_adoption: [
    {
      vertical: 'pet_adoption',
      job_type: 'Birds',
      local: 'en-US',
      job_type_id: 'pet_adoption_birds',
    },
    {
      vertical: 'pet_adoption',
      job_type: 'Cats',
      local: 'en-US',
      job_type_id: 'pet_adoption_cats',
    },
    {
      vertical: 'pet_adoption',
      job_type: 'Dogs',
      local: 'en-US',
      job_type_id: 'pet_adoption_dogs',
    },
    {
      vertical: 'pet_adoption',
      job_type: 'Farm Animals',
      local: 'en-US',
      job_type_id: 'pet_adoption_farm_animals',
    },
    {
      vertical: 'pet_adoption',
      job_type: 'Fish',
      local: 'en-US',
      job_type_id: 'pet_adoption_fish',
    },
    {
      vertical: 'pet_adoption',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'pet_adoption_other',
    },
    {
      vertical: 'pet_adoption',
      job_type: 'Reptiles',
      local: 'en-US',
      job_type_id: 'pet_adoption_reptiles',
    },
    {
      vertical: 'pet_adoption',
      job_type: 'Small Mammals',
      local: 'en-US',
      job_type_id: 'pet_adoption_small_mammals',
    },
    {
      vertical: 'pet_adoption',
      job_type: 'Special Needs Animals',
      local: 'en-US',
      job_type_id: 'pet_adoption_special_needs_animals',
    },
  ],
  pet_boarding: [
    {
      vertical: 'pet_boarding',
      job_type: 'Cat Boarding',
      local: 'en-US',
      job_type_id: 'cat_boarding',
    },
    {
      vertical: 'pet_boarding',
      job_type: 'Cat Grooming',
      local: 'en-US',
      job_type_id: 'cat_grooming',
    },
    {
      vertical: 'pet_boarding',
      job_type: 'Cat Sitting (in-home)',
      local: 'en-US',
      job_type_id: 'cat_sitting_in_home',
    },
    {
      vertical: 'pet_boarding',
      job_type: 'Dog Boarding',
      local: 'en-US',
      job_type_id: 'dog_boarding',
    },
    {
      vertical: 'pet_boarding',
      job_type: 'Dog Boarding With Training',
      local: 'en-US',
      job_type_id: 'dog_boarding_with_training',
    },
    {
      vertical: 'pet_boarding',
      job_type: 'Dog Daycare',
      local: 'en-US',
      job_type_id: 'dog_daycare',
    },
    {
      vertical: 'pet_boarding',
      job_type: 'Dog Grooming',
      local: 'en-US',
      job_type_id: 'dog_grooming',
    },
    {
      vertical: 'pet_boarding',
      job_type: 'Dog Sitting (in-home)',
      local: 'en-US',
      job_type_id: 'dog_sitting_in_home',
    },
    {
      vertical: 'pet_boarding',
      job_type: 'Dog Training',
      local: 'en-US',
      job_type_id: 'dog_training',
    },
    {
      vertical: 'pet_boarding',
      job_type: 'Dog Walking',
      local: 'en-US',
      job_type_id: 'dog_walking',
    },
    {
      vertical: 'pet_boarding',
      job_type: 'Other Pet Boarding',
      local: 'en-US',
      job_type_id: 'other_pet_boarding',
    },
    {
      vertical: 'pet_boarding',
      job_type: 'Other Pet Daycare',
      local: 'en-US',
      job_type_id: 'other_pet_daycare',
    },
    {
      vertical: 'pet_boarding',
      job_type: 'Other Pet Grooming',
      local: 'en-US',
      job_type_id: 'other_pet_grooming',
    },
    {
      vertical: 'pet_boarding',
      job_type: 'Other Pet Sitting (in-home)',
      local: 'en-US',
      job_type_id: 'other_pet_sitting_in_home',
    },
    {
      vertical: 'pet_boarding',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'pet_boarding_other',
    },
  ],
  pet_grooming: [
    {
      vertical: 'pet_grooming',
      job_type: 'Cat Bathing',
      local: 'en-US',
      job_type_id: 'cat_bathing',
    },
    {
      vertical: 'pet_grooming',
      job_type: 'Cat Ear Cleaning',
      local: 'en-US',
      job_type_id: 'cat_ear_cleaning',
    },
    {
      vertical: 'pet_grooming',
      job_type: 'Cat Grooming And Styling',
      local: 'en-US',
      job_type_id: 'cat_grooming_and_styling',
    },
    {
      vertical: 'pet_grooming',
      job_type: 'Cat Nail Trimming',
      local: 'en-US',
      job_type_id: 'cat_nail_trimming',
    },
    {
      vertical: 'pet_grooming',
      job_type: 'Dog Anal Gland Expression',
      local: 'en-US',
      job_type_id: 'dog_anal_gland_expression',
    },
    {
      vertical: 'pet_grooming',
      job_type: 'Dog Bathing And Blow Dry',
      local: 'en-US',
      job_type_id: 'dog_bathing_and_blow_dry',
    },
    {
      vertical: 'pet_grooming',
      job_type: 'Dog Ear Cleaning',
      local: 'en-US',
      job_type_id: 'dog_ear_cleaning',
    },
    {
      vertical: 'pet_grooming',
      job_type: 'Dog Flea And Tick Treatment',
      local: 'en-US',
      job_type_id: 'dog_flea_and_tick_treatment',
    },
    {
      vertical: 'pet_grooming',
      job_type: 'Dog Full Service Grooming',
      local: 'en-US',
      job_type_id: 'dog_full_service_grooming',
    },
    {
      vertical: 'pet_grooming',
      job_type: 'Dog Grooming And Styling',
      local: 'en-US',
      job_type_id: 'dog_grooming_and_styling',
    },
    {
      vertical: 'pet_grooming',
      job_type: 'Dog Nail Trimming',
      local: 'en-US',
      job_type_id: 'dog_nail_trimming',
    },
    {
      vertical: 'pet_grooming',
      job_type: 'Dog Teeth Cleaning',
      local: 'en-US',
      job_type_id: 'dog_teeth_brushing',
    },
    {
      vertical: 'pet_grooming',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'pet_grooming_other',
    },
  ],
  pet_trainer: [
    {
      vertical: 'pet_trainer',
      job_type: 'Aggressive Dog Training',
      local: 'en-US',
      job_type_id: 'aggressive_dog_training',
    },
    {
      vertical: 'pet_trainer',
      job_type: 'Behavior Modification',
      local: 'en-US',
      job_type_id: 'behavior_modification',
    },
    {
      vertical: 'pet_trainer',
      job_type: 'Obedience Training',
      local: 'en-US',
      job_type_id: 'obedience_training',
    },
    {
      vertical: 'pet_trainer',
      job_type: 'Off-leash Training',
      local: 'en-US',
      job_type_id: 'offleash_training',
    },
    {
      vertical: 'pet_trainer',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'pet_trainer_other',
    },
    {
      vertical: 'pet_trainer',
      job_type: 'Puppy Training',
      local: 'en-US',
      job_type_id: 'puppy_training',
    },
    {
      vertical: 'pet_trainer',
      job_type: 'Service Animal Training',
      local: 'en-US',
      job_type_id: 'service_animal_training',
    },
    {
      vertical: 'pet_trainer',
      job_type: 'Sport Or Trick Training',
      local: 'en-US',
      job_type_id: 'sport_trick_training',
    },
    {
      vertical: 'pet_trainer',
      job_type: 'Training For Other Animals',
      local: 'en-US',
      job_type_id: 'training_other_animals',
    },
  ],
  photographer: [
    {
      vertical: 'photographer',
      job_type: 'Corporate',
      local: 'en-US',
      job_type_id: 'corporate_photo',
    },
    {
      vertical: 'photographer',
      job_type: 'Events And Parties',
      local: 'en-US',
      job_type_id: 'events_and_parties_photo',
    },
    {
      vertical: 'photographer',
      job_type: 'Family And Group',
      local: 'en-US',
      job_type_id: 'family_and_group_photo',
    },
    {
      vertical: 'photographer',
      job_type: 'Headshots And Portraits',
      local: 'en-US',
      job_type_id: 'headshots_and_portraits',
    },
    {
      vertical: 'photographer',
      job_type: 'Individual',
      local: 'en-US',
      job_type_id: 'individual_photo',
    },
    {
      vertical: 'photographer',
      job_type: 'Maternity And Newborn',
      local: 'en-US',
      job_type_id: 'maternity_and_newborn_photo',
    },
    {
      vertical: 'photographer',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'photographer_other',
    },
    {
      vertical: 'photographer',
      job_type: 'Product',
      local: 'en-US',
      job_type_id: 'product_photo',
    },
    {
      vertical: 'photographer',
      job_type: 'Real Estate',
      local: 'en-US',
      job_type_id: 'real_estate_photo',
    },
    {
      vertical: 'photographer',
      job_type: 'School Portraits',
      local: 'en-US',
      job_type_id: 'school_portraits',
    },
    {
      vertical: 'photographer',
      job_type: 'Wedding And Engagement',
      local: 'en-US',
      job_type_id: 'wedding_and_engagement_photo',
    },
  ],
  plumber: [
    {
      vertical: 'plumber',
      job_type: 'Find & Repair Leak',
      local: 'en-US',
      job_type_id: 'find_leak',
    },
    {
      vertical: 'plumber',
      job_type: 'Install Faucet',
      local: 'en-US',
      job_type_id: 'install_faucet',
    },
    {
      vertical: 'plumber',
      job_type: 'Install Garbage Disposal',
      local: 'en-US',
      job_type_id: 'install_garbage_disposal',
    },
    {
      vertical: 'plumber',
      job_type: 'Install Shower',
      local: 'en-US',
      job_type_id: 'install_shower',
    },
    {
      vertical: 'plumber',
      job_type: 'Install Toilet',
      local: 'en-US',
      job_type_id: 'install_toilet',
    },
    {
      vertical: 'plumber',
      job_type: 'Install Water Heater',
      local: 'en-US',
      job_type_id: 'install_water_heater',
    },
    {
      vertical: 'plumber',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'plumbing_other',
    },
    {
      vertical: 'plumber',
      job_type: 'Repair Faucet',
      local: 'en-US',
      job_type_id: 'repair_faucet',
    },
    {
      vertical: 'plumber',
      job_type: 'Repair Garbage Disposal',
      local: 'en-US',
      job_type_id: 'repair_garbage_disposal',
    },
    {
      vertical: 'plumber',
      job_type: 'Repair Outdoor Systems',
      local: 'en-US',
      job_type_id: 'repair_outdoor_systems',
    },
    {
      vertical: 'plumber',
      job_type: 'Repair Pipe',
      local: 'en-US',
      job_type_id: 'repair_pipe',
    },
    {
      vertical: 'plumber',
      job_type: 'Repair Sewer',
      local: 'en-US',
      job_type_id: 'repair_sewer',
    },
    {
      vertical: 'plumber',
      job_type: 'Repair Shower',
      local: 'en-US',
      job_type_id: 'repair_shower',
    },
    {
      vertical: 'plumber',
      job_type: 'Repair Toilet',
      local: 'en-US',
      job_type_id: 'repair_toilet',
    },
    {
      vertical: 'plumber',
      job_type: 'Repair Water Heater',
      local: 'en-US',
      job_type_id: 'repair_water_heater',
    },
    {
      vertical: 'plumber',
      job_type: 'Unclog Drain',
      local: 'en-US',
      job_type_id: 'unclog_drain',
    },
  ],
  pool_cleaner: [
    {
      vertical: 'pool_cleaner',
      job_type: 'Commercial Pool Cleaning And Maintenance',
      local: 'en-US',
      job_type_id: 'commercial_pool_cleaning_maintenance',
    },
    {
      vertical: 'pool_cleaner',
      job_type: 'Equipment Maintenance Repair',
      local: 'en-US',
      job_type_id: 'equipment_maintenance_repair',
    },
    {
      vertical: 'pool_cleaner',
      job_type: 'One Time Pool Cleaning',
      local: 'en-US',
      job_type_id: 'one_time_pool_cleaning',
    },
    {
      vertical: 'pool_cleaner',
      job_type: 'Ongoing Pool Cleaning',
      local: 'en-US',
      job_type_id: 'ongoing_pool_cleaning',
    },
    {
      vertical: 'pool_cleaner',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'pool_cleaner_other',
    },
    {
      vertical: 'pool_cleaner',
      job_type: 'Water Features',
      local: 'en-US',
      job_type_id: 'pool_cleaner_water_features',
    },
    {
      vertical: 'pool_cleaner',
      job_type: 'Seasonal Opening And Closing',
      local: 'en-US',
      job_type_id: 'seasonal_opening_closing',
    },
    {
      vertical: 'pool_cleaner',
      job_type: 'Spa And Hot Tub Cleaning',
      local: 'en-US',
      job_type_id: 'spa_hot_tub_cleaning',
    },
    {
      vertical: 'pool_cleaner',
      job_type: 'Spa And Hot Tub Repairs',
      local: 'en-US',
      job_type_id: 'spa_hot_tub_repairs',
    },
    {
      vertical: 'pool_cleaner',
      job_type: 'Tile And Pool Wall Cleaning',
      local: 'en-US',
      job_type_id: 'tile_pool_wall_cleaning',
    },
    {
      vertical: 'pool_cleaner',
      job_type: 'Water Testing And Balancing',
      local: 'en-US',
      job_type_id: 'water_testing_balancing',
    },
  ],
  pool_contractor: [
    {
      vertical: 'pool_contractor',
      job_type: 'Jetted Lap Pools',
      local: 'en-US',
      job_type_id: 'jetted_lap_pools',
    },
    {
      vertical: 'pool_contractor',
      job_type: 'Pool Construction',
      local: 'en-US',
      job_type_id: 'pool_construction',
    },
    {
      vertical: 'pool_contractor',
      job_type: 'Commercial Projects',
      local: 'en-US',
      job_type_id: 'pool_contractor_commercial_projects',
    },
    {
      vertical: 'pool_contractor',
      job_type: 'Custom Designs',
      local: 'en-US',
      job_type_id: 'pool_contractor_custom_designs',
    },
    {
      vertical: 'pool_contractor',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'pool_contractor_other',
    },
    {
      vertical: 'pool_contractor',
      job_type: 'Renovation',
      local: 'en-US',
      job_type_id: 'pool_contractor_renovation',
    },
    {
      vertical: 'pool_contractor',
      job_type: 'Repair Cracks',
      local: 'en-US',
      job_type_id: 'pool_contractor_repair_cracks',
    },
    {
      vertical: 'pool_contractor',
      job_type: 'Repair Tile',
      local: 'en-US',
      job_type_id: 'pool_contractor_repair_tile',
    },
    {
      vertical: 'pool_contractor',
      job_type: 'Resurfacing',
      local: 'en-US',
      job_type_id: 'pool_contractor_resurfacing',
    },
    {
      vertical: 'pool_contractor',
      job_type: 'Water Features',
      local: 'en-US',
      job_type_id: 'pool_contractor_water_features',
    },
    {
      vertical: 'pool_contractor',
      job_type: 'Pool And Hot Tub Equipment',
      local: 'en-US',
      job_type_id: 'pool_hot_tub_equipment',
    },
    {
      vertical: 'pool_contractor',
      job_type: 'Spa And Hot Tub Installation',
      local: 'en-US',
      job_type_id: 'spa_hot_tub_installation',
    },
  ],
  preschool: [
    {
      vertical: 'preschool',
      job_type: 'Bilingual Programs',
      local: 'en-US',
      job_type_id: 'bilingual_programs',
    },
    {
      vertical: 'preschool',
      job_type: 'Early Preschool',
      local: 'en-US',
      job_type_id: 'early_preschool',
    },
    {
      vertical: 'preschool',
      job_type: 'Full-day Preschool',
      local: 'en-US',
      job_type_id: 'full_day_preschool',
    },
    {
      vertical: 'preschool',
      job_type: 'Half-day Preschool',
      local: 'en-US',
      job_type_id: 'half_day_preschool',
    },
    {
      vertical: 'preschool',
      job_type: 'Kindergarten Classes',
      local: 'en-US',
      job_type_id: 'kindergarten_classes',
    },
    {
      vertical: 'preschool',
      job_type: 'Montessori Programs',
      local: 'en-US',
      job_type_id: 'montessori_programs',
    },
    {
      vertical: 'preschool',
      job_type: 'Play-based Preschool',
      local: 'en-US',
      job_type_id: 'play_based_preschool',
    },
    {
      vertical: 'preschool',
      job_type: 'Infant Programs',
      local: 'en-US',
      job_type_id: 'preschool_infant_programs',
    },
    {
      vertical: 'preschool',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'preschool_other',
    },
    {
      vertical: 'preschool',
      job_type: 'Reggio Emilia Preschool',
      local: 'en-US',
      job_type_id: 'reggio_emilia_preschool',
    },
    {
      vertical: 'preschool',
      job_type: 'Special Needs Programs',
      local: 'en-US',
      job_type_id: 'special_needs_programs',
    },
    {
      vertical: 'preschool',
      job_type: 'Summer Camp',
      local: 'en-US',
      job_type_id: 'summer_camp',
    },
    {
      vertical: 'preschool',
      job_type: 'Waldorf Preschool',
      local: 'en-US',
      job_type_id: 'waldorf_preschool',
    },
  ],
  psychologist: [
    {
      vertical: 'psychologist',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'psychologist_other',
    },
  ],
  real_estate_agent: [
    {
      vertical: 'real_estate_agent',
      job_type: "Buyer's Agent",
      local: 'en-US',
      job_type_id: 'buyer_agent',
    },
    {
      vertical: 'real_estate_agent',
      job_type: 'First Time Home Buyer',
      local: 'en-US',
      job_type_id: 'first_time_home_buyer',
    },
    {
      vertical: 'real_estate_agent',
      job_type: 'Foreclosed Properties',
      local: 'en-US',
      job_type_id: 'foreclosed_properties',
    },
    {
      vertical: 'real_estate_agent',
      job_type: 'Luxury Properties',
      local: 'en-US',
      job_type_id: 'luxury_properties',
    },
    {
      vertical: 'real_estate_agent',
      job_type: 'New Construction',
      local: 'en-US',
      job_type_id: 'new_construction',
    },
    {
      vertical: 'real_estate_agent',
      job_type: 'Property Management',
      local: 'en-US',
      job_type_id: 'property_management',
    },
    {
      vertical: 'real_estate_agent',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'real_estate_agent_other',
    },
    {
      vertical: 'real_estate_agent',
      job_type: 'Relocation',
      local: 'en-US',
      job_type_id: 'relocation',
    },
    {
      vertical: 'real_estate_agent',
      job_type: 'Rentals',
      local: 'en-US',
      job_type_id: 'rentals',
    },
    {
      vertical: 'real_estate_agent',
      job_type: "Seller's Agent",
      local: 'en-US',
      job_type_id: 'sellers_agent',
    },
  ],
  real_estate_lawyer: [
    {
      vertical: 'real_estate_lawyer',
      job_type: 'Commercial Real Estate',
      local: 'en-US',
      job_type_id: 'commercial_real_estate',
    },
    {
      vertical: 'real_estate_lawyer',
      job_type: 'Easements',
      local: 'en-US',
      job_type_id: 'easements',
    },
    {
      vertical: 'real_estate_lawyer',
      job_type: 'Encroachment',
      local: 'en-US',
      job_type_id: 'encroachment',
    },
    {
      vertical: 'real_estate_lawyer',
      job_type: 'Foreclosure',
      local: 'en-US',
      job_type_id: 'foreclosure_real_esate_lawyer',
    },
    {
      vertical: 'real_estate_lawyer',
      job_type: 'Landlord & Tenant Law',
      local: 'en-US',
      job_type_id: 'landlord_tenant_law',
    },
    {
      vertical: 'real_estate_lawyer',
      job_type: 'Litigation',
      local: 'en-US',
      job_type_id: 'litigation_real_estate_lawyer',
    },
    {
      vertical: 'real_estate_lawyer',
      job_type: 'Mortgage & Lending Law',
      local: 'en-US',
      job_type_id: 'mortgage_lending_law',
    },
    {
      vertical: 'real_estate_lawyer',
      job_type: 'Neighbor & HOA Disputes',
      local: 'en-US',
      job_type_id: 'neighbor_hoa_disputes',
    },
    {
      vertical: 'real_estate_lawyer',
      job_type: 'Property Damage',
      local: 'en-US',
      job_type_id: 'property_damage',
    },
    {
      vertical: 'real_estate_lawyer',
      job_type: 'Purchase Agreements',
      local: 'en-US',
      job_type_id: 'purchase_agreements',
    },
    {
      vertical: 'real_estate_lawyer',
      job_type: 'Real Estate Transactions',
      local: 'en-US',
      job_type_id: 'real_estate_contracts_transactions',
    },
    {
      vertical: 'real_estate_lawyer',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'real_estate_lawyer_other',
    },
    {
      vertical: 'real_estate_lawyer',
      job_type: 'Residential Real Estate',
      local: 'en-US',
      job_type_id: 'residential_real_estate',
    },
    {
      vertical: 'real_estate_lawyer',
      job_type: 'Title Services',
      local: 'en-US',
      job_type_id: 'title_services',
    },
    {
      vertical: 'real_estate_lawyer',
      job_type: 'Trespassing',
      local: 'en-US',
      job_type_id: 'trespassing',
    },
  ],
  rehab: [
    {
      vertical: 'rehab',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'rehab_other',
    },
  ],
  roofer: [
    {
      vertical: 'roofer',
      job_type: 'Attic Venting',
      local: 'en-US',
      job_type_id: 'attic_venting',
    },
    {
      vertical: 'roofer',
      job_type: 'Gutter Installation',
      local: 'en-US',
      job_type_id: 'gutter_installation',
    },
    {
      vertical: 'roofer',
      job_type: 'Gutter Repair',
      local: 'en-US',
      job_type_id: 'gutter_repair',
    },
    {
      vertical: 'roofer',
      job_type: 'Roof Inspection',
      local: 'en-US',
      job_type_id: 'roof_inspection',
    },
    {
      vertical: 'roofer',
      job_type: 'Roof Installation',
      local: 'en-US',
      job_type_id: 'roof_installation',
    },
    {
      vertical: 'roofer',
      job_type: 'Roof Repair',
      local: 'en-US',
      job_type_id: 'roof_repair',
    },
    {
      vertical: 'roofer',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'roofer_other',
    },
    {
      vertical: 'roofer',
      job_type: 'Skylight Installation',
      local: 'en-US',
      job_type_id: 'skylight_installation',
    },
    {
      vertical: 'roofer',
      job_type: 'Skylight Repair',
      local: 'en-US',
      job_type_id: 'skylight_repair',
    },
    {
      vertical: 'roofer',
      job_type: 'Roof Damage Repair',
      local: 'en-US',
      job_type_id: 'storm_wind_damage_roof_repair',
    },
  ],
  sewage_pro: [
    {
      vertical: 'sewage_pro',
      job_type: 'Cleaning',
      local: 'en-US',
      job_type_id: 'sewage_cleaning',
    },
    {
      vertical: 'sewage_pro',
      job_type: 'Excavation',
      local: 'en-US',
      job_type_id: 'sewage_excavation',
    },
    {
      vertical: 'sewage_pro',
      job_type: 'Inspection',
      local: 'en-US',
      job_type_id: 'sewage_inspection',
    },
    {
      vertical: 'sewage_pro',
      job_type: 'Installation',
      local: 'en-US',
      job_type_id: 'sewage_installation',
    },
    {
      vertical: 'sewage_pro',
      job_type: 'Locating',
      local: 'en-US',
      job_type_id: 'sewage_locating',
    },
    {
      vertical: 'sewage_pro',
      job_type: 'Maintenance And Repairs',
      local: 'en-US',
      job_type_id: 'sewage_maintenance_repairs',
    },
    {
      vertical: 'sewage_pro',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'sewage_pro_other',
    },
    {
      vertical: 'sewage_pro',
      job_type: 'Pumping',
      local: 'en-US',
      job_type_id: 'sewage_pumping',
    },
    {
      vertical: 'sewage_pro',
      job_type: 'Removal Or Demolition',
      local: 'en-US',
      job_type_id: 'sewage_removal_demolition',
    },
  ],
  siding_pro: [
    {
      vertical: 'siding_pro',
      job_type: 'Remodeling',
      local: 'en-US',
      job_type_id: 'remodeling_siding_pro',
    },
    {
      vertical: 'siding_pro',
      job_type: 'Repair & Maintenance',
      local: 'en-US',
      job_type_id: 'repair_maintenance_siding_pro',
    },
    {
      vertical: 'siding_pro',
      job_type: 'Siding Installation',
      local: 'en-US',
      job_type_id: 'siding_installation',
    },
    {
      vertical: 'siding_pro',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'siding_pro_other',
    },
    {
      vertical: 'siding_pro',
      job_type: 'Siding Removal',
      local: 'en-US',
      job_type_id: 'siding_removal',
    },
  ],
  smart_home: [
    {
      vertical: 'smart_home',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'smart_home_other',
    },
  ],
  snow_removal: [
    {
      vertical: 'snow_removal',
      job_type: 'Commercial Snow Removal',
      local: 'en-US',
      job_type_id: 'commercial_snow_removal',
    },
    {
      vertical: 'snow_removal',
      job_type: 'Residential Plowing',
      local: 'en-US',
      job_type_id: 'residential_plowing',
    },
    {
      vertical: 'snow_removal',
      job_type: 'Residential Shoveling Blowing',
      local: 'en-US',
      job_type_id: 'residential_shoveling_blowing',
    },
    {
      vertical: 'snow_removal',
      job_type: 'Salt Sand And De-icing',
      local: 'en-US',
      job_type_id: 'salt_sand_deicing',
    },
    {
      vertical: 'snow_removal',
      job_type: 'Snow Relocation',
      local: 'en-US',
      job_type_id: 'snow_relocation',
    },
    {
      vertical: 'snow_removal',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'snow_removal_other',
    },
  ],
  spa: [
    {
      vertical: 'spa',
      job_type: 'Body Polish',
      local: 'en-US',
      job_type_id: 'body_polish',
    },
    {
      vertical: 'spa',
      job_type: 'Body Wrap',
      local: 'en-US',
      job_type_id: 'body_wrap',
    },
    {
      vertical: 'spa',
      job_type: 'Facial',
      local: 'en-US',
      job_type_id: 'facial',
    },
    {
      vertical: 'spa',
      job_type: 'Hydrotherapy',
      local: 'en-US',
      job_type_id: 'hydrotherapy',
    },
    {
      vertical: 'spa',
      job_type: 'Manicure',
      local: 'en-US',
      job_type_id: 'manicure',
    },
    {
      vertical: 'spa',
      job_type: 'Massage',
      local: 'en-US',
      job_type_id: 'massage',
    },
    {
      vertical: 'spa',
      job_type: 'Pedicure',
      local: 'en-US',
      job_type_id: 'pedicure',
    },
    {
      vertical: 'spa',
      job_type: 'Peels',
      local: 'en-US',
      job_type_id: 'peels',
    },
    {
      vertical: 'spa',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'spa_other',
    },
    {
      vertical: 'spa',
      job_type: 'Spa Packages',
      local: 'en-US',
      job_type_id: 'spa_packages',
    },
    {
      vertical: 'spa',
      job_type: 'Tanning',
      local: 'en-US',
      job_type_id: 'tanning',
    },
    {
      vertical: 'spa',
      job_type: 'Waxing',
      local: 'en-US',
      job_type_id: 'waxing',
    },
  ],
  tax_lawyer: [
    {
      vertical: 'tax_lawyer',
      job_type: 'Civil Tax Litigation',
      local: 'en-US',
      job_type_id: 'civil_tax_litigation',
    },
    {
      vertical: 'tax_lawyer',
      job_type: 'Corporate Taxes',
      local: 'en-US',
      job_type_id: 'corporate_taxes',
    },
    {
      vertical: 'tax_lawyer',
      job_type: 'Criminal Tax Litigation',
      local: 'en-US',
      job_type_id: 'criminal_tax_litigation',
    },
    {
      vertical: 'tax_lawyer',
      job_type: 'Estate Tax Planning',
      local: 'en-US',
      job_type_id: 'estate_tax_planning',
    },
    {
      vertical: 'tax_lawyer',
      job_type: 'Failure To File',
      local: 'en-US',
      job_type_id: 'failure_to_file',
    },
    {
      vertical: 'tax_lawyer',
      job_type: 'Innocent Spouse Relief',
      local: 'en-US',
      job_type_id: 'innocent_spouse_relief',
    },
    {
      vertical: 'tax_lawyer',
      job_type: 'International Tax Services',
      local: 'en-US',
      job_type_id: 'international_tax_services',
    },
    {
      vertical: 'tax_lawyer',
      job_type: 'IRS Tax Problems',
      local: 'en-US',
      job_type_id: 'irs_tax_problems',
    },
    {
      vertical: 'tax_lawyer',
      job_type: 'Non-collectible Status',
      local: 'en-US',
      job_type_id: 'non_collectible_status',
    },
    {
      vertical: 'tax_lawyer',
      job_type: 'Offer In Compromise',
      local: 'en-US',
      job_type_id: 'offer_in_compromise',
    },
    {
      vertical: 'tax_lawyer',
      job_type: 'Tax Audits',
      local: 'en-US',
      job_type_id: 'tax_audits',
    },
    {
      vertical: 'tax_lawyer',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'tax_lawyer_other',
    },
    {
      vertical: 'tax_lawyer',
      job_type: 'Tax Liens & Levies',
      local: 'en-US',
      job_type_id: 'tax_liens_levies',
    },
    {
      vertical: 'tax_lawyer',
      job_type: 'Tax Planning',
      local: 'en-US',
      job_type_id: 'tax_planning_lawyer',
    },
    {
      vertical: 'tax_lawyer',
      job_type: 'Tax Relief',
      local: 'en-US',
      job_type_id: 'tax_relief',
    },
  ],
  tax_specialist: [
    {
      vertical: 'tax_specialist',
      job_type: 'Accounting Support',
      local: 'en-US',
      job_type_id: 'accounting_support',
    },
    {
      vertical: 'tax_specialist',
      job_type: 'Incorporation & Restructuring',
      local: 'en-US',
      job_type_id: 'business_incorporation_restructuring',
    },
    {
      vertical: 'tax_specialist',
      job_type: 'Cryptocurrency Taxes',
      local: 'en-US',
      job_type_id: 'cryptocurrency_taxes',
    },
    {
      vertical: 'tax_specialist',
      job_type: 'IRS Audit Representation',
      local: 'en-US',
      job_type_id: 'irs_audit_representation',
    },
    {
      vertical: 'tax_specialist',
      job_type: 'IRS Filing',
      local: 'en-US',
      job_type_id: 'irs_filing',
    },
    {
      vertical: 'tax_specialist',
      job_type: 'IRS Problem Consulting',
      local: 'en-US',
      job_type_id: 'irs_problem_consulting',
    },
    {
      vertical: 'tax_specialist',
      job_type: 'New Business Services',
      local: 'en-US',
      job_type_id: 'new_business_services',
    },
    {
      vertical: 'tax_specialist',
      job_type: 'Payroll Support',
      local: 'en-US',
      job_type_id: 'payroll_support',
    },
    {
      vertical: 'tax_specialist',
      job_type: 'Tax Planning',
      local: 'en-US',
      job_type_id: 'tax_planning_tax_specialist',
    },
    {
      vertical: 'tax_specialist',
      job_type: 'Tax Preparation',
      local: 'en-US',
      job_type_id: 'tax_preparation',
    },
    {
      vertical: 'tax_specialist',
      job_type: 'Tax Problem Consulting',
      local: 'en-US',
      job_type_id: 'tax_problem_consulting_tax_specialist',
    },
    {
      vertical: 'tax_specialist',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'tax_specialist_other',
    },
  ],
  therapist: [
    {
      vertical: 'therapist',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'therapist_other',
    },
  ],
  towing_pro: [
    {
      vertical: 'towing_pro',
      job_type: 'Battery Service',
      local: 'en-US',
      job_type_id: 'battery_service',
    },
    {
      vertical: 'towing_pro',
      job_type: 'Car Towing',
      local: 'en-US',
      job_type_id: 'car_towing',
    },
    {
      vertical: 'towing_pro',
      job_type: 'Fuel Delivery',
      local: 'en-US',
      job_type_id: 'fuel_delivery',
    },
    {
      vertical: 'towing_pro',
      job_type: 'Local Hauling',
      local: 'en-US',
      job_type_id: 'local_hauling',
    },
    {
      vertical: 'towing_pro',
      job_type: 'Long Distance Hauling',
      local: 'en-US',
      job_type_id: 'long_distance_hauling',
    },
    {
      vertical: 'towing_pro',
      job_type: 'Motorcycle Towing',
      local: 'en-US',
      job_type_id: 'motorcycle_towing',
    },
    {
      vertical: 'towing_pro',
      job_type: 'Special Vehicle Towing',
      local: 'en-US',
      job_type_id: 'special_vehicle_towing',
    },
    {
      vertical: 'towing_pro',
      job_type: 'Tire Change',
      local: 'en-US',
      job_type_id: 'tire_change',
    },
    {
      vertical: 'towing_pro',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'towing_other',
    },
    {
      vertical: 'towing_pro',
      job_type: 'Vehicle Lockout',
      local: 'en-US',
      job_type_id: 'vehicle_lockout',
    },
  ],
  traffic_lawyer: [
    {
      vertical: 'traffic_lawyer',
      job_type: 'Case Assessment',
      local: 'en-US',
      job_type_id: 'case_assessment_traffic_lawyer',
    },
    {
      vertical: 'traffic_lawyer',
      job_type: 'CDL Traffic Ticket',
      local: 'en-US',
      job_type_id: 'cdl_traffic_ticket',
    },
    {
      vertical: 'traffic_lawyer',
      job_type: 'DUI',
      local: 'en-US',
      job_type_id: 'dui_dwi_traffic_lawyer',
    },
    {
      vertical: 'traffic_lawyer',
      job_type: 'Fix-it Ticket',
      local: 'en-US',
      job_type_id: 'fixit_ticket',
    },
    {
      vertical: 'traffic_lawyer',
      job_type: 'Parking Ticket',
      local: 'en-US',
      job_type_id: 'parking_ticket',
    },
    {
      vertical: 'traffic_lawyer',
      job_type: 'Reckless Driving',
      local: 'en-US',
      job_type_id: 'reckless_driving_traffic_lawyer',
    },
    {
      vertical: 'traffic_lawyer',
      job_type: 'Red Light Ticket',
      local: 'en-US',
      job_type_id: 'red_light_ticket',
    },
    {
      vertical: 'traffic_lawyer',
      job_type: 'Speeding Ticket',
      local: 'en-US',
      job_type_id: 'speeding_ticket',
    },
    {
      vertical: 'traffic_lawyer',
      job_type: 'Suspended License',
      local: 'en-US',
      job_type_id: 'suspended_license',
    },
    {
      vertical: 'traffic_lawyer',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'traffic_lawyer_other',
    },
    {
      vertical: 'traffic_lawyer',
      job_type: 'Traffic Ticket',
      local: 'en-US',
      job_type_id: 'traffic_ticket',
    },
  ],
  tree_service: [
    {
      vertical: 'tree_service',
      job_type: 'Cabling And Bracing',
      local: 'en-US',
      job_type_id: 'cabling_and_bracing',
    },
    {
      vertical: 'tree_service',
      job_type: 'Stump Removal',
      local: 'en-US',
      job_type_id: 'stump_removal',
    },
    {
      vertical: 'tree_service',
      job_type: 'Tree Planting',
      local: 'en-US',
      job_type_id: 'tree_planting',
    },
    {
      vertical: 'tree_service',
      job_type: 'Tree Removal',
      local: 'en-US',
      job_type_id: 'tree_removal',
    },
    {
      vertical: 'tree_service',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'tree_service_other',
    },
    {
      vertical: 'tree_service',
      job_type: 'Tree Transplanting',
      local: 'en-US',
      job_type_id: 'tree_transplanting',
    },
    {
      vertical: 'tree_service',
      job_type: 'Tree Trimming And Pruning',
      local: 'en-US',
      job_type_id: 'tree_trimming_and_pruning',
    },
  ],
  tutor: [
    {
      vertical: 'tutor',
      job_type: 'Computer Science',
      local: 'en-US',
      job_type_id: 'tutor_computer_science',
    },
    {
      vertical: 'tutor',
      job_type: 'Foreign Languages',
      local: 'en-US',
      job_type_id: 'tutor_foreign_languages',
    },
    {
      vertical: 'tutor',
      job_type: 'Geography',
      local: 'en-US',
      job_type_id: 'tutor_geography',
    },
    {
      vertical: 'tutor',
      job_type: 'Government & Economics',
      local: 'en-US',
      job_type_id: 'tutor_government_and_economics',
    },
    {
      vertical: 'tutor',
      job_type: 'History',
      local: 'en-US',
      job_type_id: 'tutor_history',
    },
    {
      vertical: 'tutor',
      job_type: 'Homework Help',
      local: 'en-US',
      job_type_id: 'tutor_homework_help',
    },
    {
      vertical: 'tutor',
      job_type: 'Math',
      local: 'en-US',
      job_type_id: 'tutor_math',
    },
    {
      vertical: 'tutor',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'tutor_other',
    },
    {
      vertical: 'tutor',
      job_type: 'Reading & Writing',
      local: 'en-US',
      job_type_id: 'tutor_reading_and_writing',
    },
    {
      vertical: 'tutor',
      job_type: 'Science',
      local: 'en-US',
      job_type_id: 'tutor_science',
    },
    {
      vertical: 'tutor',
      job_type: 'Special Education',
      local: 'en-US',
      job_type_id: 'tutor_special_education',
    },
    {
      vertical: 'tutor',
      job_type: 'Test Prep: College Entrance',
      local: 'en-US',
      job_type_id: 'tutor_test_pre_college_entrance',
    },
    {
      vertical: 'tutor',
      job_type: 'Test Prep: Grad School Entrance',
      local: 'en-US',
      job_type_id: 'tutor_test_prep_grad_school_entrance',
    },
    {
      vertical: 'tutor',
      job_type: 'Test Prep: K-8',
      local: 'en-US',
      job_type_id: 'tutor_test_prep_k8',
    },
  ],
  upholstery_cleaner: [
    {
      vertical: 'upholstery_cleaner',
      job_type: 'Area Rug Cleaning',
      local: 'en-US',
      job_type_id: 'area_rug_cleaning',
    },
    {
      vertical: 'upholstery_cleaner',
      job_type: 'Drapery & Blind Cleaning',
      local: 'en-US',
      job_type_id: 'drapery_and_blind_cleaning',
    },
    {
      vertical: 'upholstery_cleaner',
      job_type: 'Leather Cleaning',
      local: 'en-US',
      job_type_id: 'leather_cleaning',
    },
    {
      vertical: 'upholstery_cleaner',
      job_type: 'Mattress Cleaning',
      local: 'en-US',
      job_type_id: 'mattress_cleaning',
    },
    {
      vertical: 'upholstery_cleaner',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'upholstery_cleaner_other',
    },
    {
      vertical: 'upholstery_cleaner',
      job_type: 'Upholstery Cleaning',
      local: 'en-US',
      job_type_id: 'upholstery_cleaning',
    },
    {
      vertical: 'upholstery_cleaner',
      job_type: 'Water Damage Cleanup',
      local: 'en-US',
      job_type_id: 'upholstery_water_damage_cleanup',
    },
  ],
  veterinarian: [
    {
      vertical: 'veterinarian',
      job_type: 'Bird And Exotic Animal Care',
      local: 'en-US',
      job_type_id: 'bird_exotic_animal_care',
    },
    {
      vertical: 'veterinarian',
      job_type: 'Farm And Barn Animal Care',
      local: 'en-US',
      job_type_id: 'farm_barn_animal_care',
    },
    {
      vertical: 'veterinarian',
      job_type: 'Microchipping',
      local: 'en-US',
      job_type_id: 'microchipping',
    },
    {
      vertical: 'veterinarian',
      job_type: 'Allergies',
      local: 'en-US',
      job_type_id: 'veterinarian_allergies',
    },
    {
      vertical: 'veterinarian',
      job_type: 'Behavior Counseling',
      local: 'en-US',
      job_type_id: 'veterinarian_behavior_counseling',
    },
    {
      vertical: 'veterinarian',
      job_type: 'Dental Care',
      local: 'en-US',
      job_type_id: 'veterinarian_dental_care',
    },
    {
      vertical: 'veterinarian',
      job_type: 'Dermatology',
      local: 'en-US',
      job_type_id: 'veterinarian_dermatology',
    },
    {
      vertical: 'veterinarian',
      job_type: 'Emergency Care',
      local: 'en-US',
      job_type_id: 'veterinarian_emergency_care',
    },
    {
      vertical: 'veterinarian',
      job_type: 'End Of Life Care',
      local: 'en-US',
      job_type_id: 'veterinarian_end_of_life_care',
    },
    {
      vertical: 'veterinarian',
      job_type: 'Examinations',
      local: 'en-US',
      job_type_id: 'veterinarian_examinations',
    },
    {
      vertical: 'veterinarian',
      job_type: 'Nutrition Counseling',
      local: 'en-US',
      job_type_id: 'veterinarian_nutrition_counseling',
    },
    {
      vertical: 'veterinarian',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'veterinarian_other',
    },
    {
      vertical: 'veterinarian',
      job_type: 'Preventive Care',
      local: 'en-US',
      job_type_id: 'veterinarian_preventive_care',
    },
    {
      vertical: 'veterinarian',
      job_type: 'Spay Or Neuter',
      local: 'en-US',
      job_type_id: 'veterinarian_spay_neuter',
    },
    {
      vertical: 'veterinarian',
      job_type: 'Surgery',
      local: 'en-US',
      job_type_id: 'veterinarian_surgery',
    },
    {
      vertical: 'veterinarian',
      job_type: 'Vaccinations',
      local: 'en-US',
      job_type_id: 'veterinarian_vaccinations',
    },
  ],
  videographer: [
    {
      vertical: 'videographer',
      job_type: 'Aerial Videography',
      local: 'en-US',
      job_type_id: 'aerial_videography',
    },
    {
      vertical: 'videographer',
      job_type: 'Documentary Production',
      local: 'en-US',
      job_type_id: 'documentary_video_production',
    },
    {
      vertical: 'videographer',
      job_type: 'Family Video Production',
      local: 'en-US',
      job_type_id: 'family_video_production',
    },
    {
      vertical: 'videographer',
      job_type: 'Live Streaming',
      local: 'en-US',
      job_type_id: 'live_streaming',
    },
    {
      vertical: 'videographer',
      job_type: 'Photography',
      local: 'en-US',
      job_type_id: 'photography',
    },
    {
      vertical: 'videographer',
      job_type: 'Product Demonstration',
      local: 'en-US',
      job_type_id: 'product_demonstrations',
    },
    {
      vertical: 'videographer',
      job_type: 'Promotional Video Production',
      local: 'en-US',
      job_type_id: 'promotional_video_production',
    },
    {
      vertical: 'videographer',
      job_type: 'Real Estate Video Production',
      local: 'en-US',
      job_type_id: 'real_estate_video_production',
    },
    {
      vertical: 'videographer',
      job_type: 'Special Event Video Production',
      local: 'en-US',
      job_type_id: 'special_event_video_production',
    },
    {
      vertical: 'videographer',
      job_type: 'Video Editing',
      local: 'en-US',
      job_type_id: 'video_editing',
    },
    {
      vertical: 'videographer',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'videographer_other',
    },
    {
      vertical: 'videographer',
      job_type: 'Voice Over',
      local: 'en-US',
      job_type_id: 'voice_overs',
    },
    {
      vertical: 'videographer',
      job_type: 'Wedding Video Production',
      local: 'en-US',
      job_type_id: 'wedding_video_production',
    },
  ],
  water_damage: [
    {
      vertical: 'water_damage',
      job_type: 'Fire Damage Cleanup/repair',
      local: 'en-US',
      job_type_id: 'fire_damage_cleanup_repair',
    },
    {
      vertical: 'water_damage',
      job_type: 'Water Damage Cleanup',
      local: 'en-US',
      job_type_id: 'water_damage_cleanup_repair',
    },
    {
      vertical: 'water_damage',
      job_type: 'Flooring',
      local: 'en-US',
      job_type_id: 'water_damage_flooring',
    },
    {
      vertical: 'water_damage',
      job_type: 'Mold Inspection',
      local: 'en-US',
      job_type_id: 'water_damage_mold_inspection',
    },
    {
      vertical: 'water_damage',
      job_type: 'Mold Removal',
      local: 'en-US',
      job_type_id: 'water_damage_mold_removal',
    },
    {
      vertical: 'water_damage',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'water_damage_other',
    },
    {
      vertical: 'water_damage',
      job_type: 'Painting',
      local: 'en-US',
      job_type_id: 'water_damage_painting',
    },
    {
      vertical: 'water_damage',
      job_type: 'Pest Control',
      local: 'en-US',
      job_type_id: 'water_damage_pest_control',
    },
    {
      vertical: 'water_damage',
      job_type: 'Plumbing',
      local: 'en-US',
      job_type_id: 'water_damage_plumbing',
    },
    {
      vertical: 'water_damage',
      job_type: 'Remodeling',
      local: 'en-US',
      job_type_id: 'water_damage_remodeling',
    },
    {
      vertical: 'water_damage',
      job_type: 'Roofing',
      local: 'en-US',
      job_type_id: 'water_damage_roofing',
    },
    {
      vertical: 'water_damage',
      job_type: 'Sewage Cleanup',
      local: 'en-US',
      job_type_id: 'water_damage_sewage_cleanup',
    },
  ],
  weight_loss_service: [
    {
      vertical: 'weight_loss_service',
      job_type: 'Adult Weight Loss Programs',
      local: 'en-US',
      job_type_id: 'adult_weight_loss_programs',
    },
    {
      vertical: 'weight_loss_service',
      job_type: 'Diet And Nutrition Counseling',
      local: 'en-US',
      job_type_id: 'diet_and_nutrition_counseling',
    },
    {
      vertical: 'weight_loss_service',
      job_type: 'Kids Weight Loss Programs',
      local: 'en-US',
      job_type_id: 'kids_weight_loss_programs',
    },
    {
      vertical: 'weight_loss_service',
      job_type: 'Metabolic Testing',
      local: 'en-US',
      job_type_id: 'metabolic_testing',
    },
    {
      vertical: 'weight_loss_service',
      job_type: 'Consultation And Training',
      local: 'en-US',
      job_type_id: 'weight_loss_consultation_and_training',
    },
    {
      vertical: 'weight_loss_service',
      job_type: 'Fitness Programs',
      local: 'en-US',
      job_type_id: 'weight_loss_fitness_programs',
    },
    {
      vertical: 'weight_loss_service',
      job_type: 'Meal Planning',
      local: 'en-US',
      job_type_id: 'weight_loss_meal_planning',
    },
    {
      vertical: 'weight_loss_service',
      job_type: 'Personal Training',
      local: 'en-US',
      job_type_id: 'weight_loss_personal_training',
    },
    {
      vertical: 'weight_loss_service',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'weight_loss_service_other',
    },
    {
      vertical: 'weight_loss_service',
      job_type: 'Wellness Programs',
      local: 'en-US',
      job_type_id: 'weight_loss_wellness_programs',
    },
  ],
  window_cleaner: [
    {
      vertical: 'window_cleaner',
      job_type: 'Glass & Mirror Cleaning',
      local: 'en-US',
      job_type_id: 'glass_and_mirror_cleaning',
    },
    {
      vertical: 'window_cleaner',
      job_type: 'Gutter Cleaning',
      local: 'en-US',
      job_type_id: 'gutter_cleaning',
    },
    {
      vertical: 'window_cleaner',
      job_type: 'Power/pressure Washing',
      local: 'en-US',
      job_type_id: 'power_pressure_washing',
    },
    {
      vertical: 'window_cleaner',
      job_type: 'Rooftop/skylight Cleaning',
      local: 'en-US',
      job_type_id: 'rooftop_skylight_cleaning',
    },
    {
      vertical: 'window_cleaner',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'window_cleaner_other',
    },
    {
      vertical: 'window_cleaner',
      job_type: 'Window Cleaning',
      local: 'en-US',
      job_type_id: 'window_cleaning',
    },
  ],
  window_repair: [
    {
      vertical: 'window_repair',
      job_type: 'Door Installation',
      local: 'en-US',
      job_type_id: 'door_installation',
    },
    {
      vertical: 'window_repair',
      job_type: 'Door Repair',
      local: 'en-US',
      job_type_id: 'door_repair',
    },
    {
      vertical: 'window_repair',
      job_type: 'Glass Installation',
      local: 'en-US',
      job_type_id: 'glass_installation',
    },
    {
      vertical: 'window_repair',
      job_type: 'Skylight Installation',
      local: 'en-US',
      job_type_id: 'skylight_installation',
    },
    {
      vertical: 'window_repair',
      job_type: 'Skylight Repair',
      local: 'en-US',
      job_type_id: 'skylight_repair',
    },
    {
      vertical: 'window_repair',
      job_type: 'Boardup',
      local: 'en-US',
      job_type_id: 'window_boardup',
    },
    {
      vertical: 'window_repair',
      job_type: 'Window Installation',
      local: 'en-US',
      job_type_id: 'window_installation',
    },
    {
      vertical: 'window_repair',
      job_type: 'Window Repair',
      local: 'en-US',
      job_type_id: 'window_repair',
    },
    {
      vertical: 'window_repair',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'window_repair_other',
    },
  ],
  windshield_repair_pro: [
    {
      vertical: 'windshield_repair_pro',
      job_type: 'Install New Wiper Blades',
      local: 'en-US',
      job_type_id: 'install_new_wiper_blades',
    },
    {
      vertical: 'windshield_repair_pro',
      job_type: 'Repair Power Window',
      local: 'en-US',
      job_type_id: 'repair_power_window',
    },
    {
      vertical: 'windshield_repair_pro',
      job_type: 'Repair Water Leak',
      local: 'en-US',
      job_type_id: 'repair_water_leak',
    },
    {
      vertical: 'windshield_repair_pro',
      job_type: 'Repair Windshield',
      local: 'en-US',
      job_type_id: 'repair_windshield',
    },
    {
      vertical: 'windshield_repair_pro',
      job_type: 'Replace Rear Window',
      local: 'en-US',
      job_type_id: 'replace_rear_window',
    },
    {
      vertical: 'windshield_repair_pro',
      job_type: 'Repair Side View Mirrors',
      local: 'en-US',
      job_type_id: 'replace_side_view_mirrors',
    },
    {
      vertical: 'windshield_repair_pro',
      job_type: 'Replace Side Window',
      local: 'en-US',
      job_type_id: 'replace_side_window',
    },
    {
      vertical: 'windshield_repair_pro',
      job_type: 'Replace Sunroof Glass',
      local: 'en-US',
      job_type_id: 'replace_sunroof',
    },
    {
      vertical: 'windshield_repair_pro',
      job_type: 'Replace Windshield',
      local: 'en-US',
      job_type_id: 'replace_windshield',
    },
    {
      vertical: 'windshield_repair_pro',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'windshield_other',
    },
  ],
  yoga_instructor: [
    {
      vertical: 'yoga_instructor',
      job_type: 'Ashtanga',
      local: 'en-US',
      job_type_id: 'ashtanga',
    },
    {
      vertical: 'yoga_instructor',
      job_type: 'Hatha',
      local: 'en-US',
      job_type_id: 'hatha',
    },
    {
      vertical: 'yoga_instructor',
      job_type: 'Hot Yoga',
      local: 'en-US',
      job_type_id: 'hot_yoga',
    },
    {
      vertical: 'yoga_instructor',
      job_type: 'Iyengar',
      local: 'en-US',
      job_type_id: 'iyengar',
    },
    {
      vertical: 'yoga_instructor',
      job_type: 'Kundalini',
      local: 'en-US',
      job_type_id: 'kundalini',
    },
    {
      vertical: 'yoga_instructor',
      job_type: 'Meditation',
      local: 'en-US',
      job_type_id: 'meditation',
    },
    {
      vertical: 'yoga_instructor',
      job_type: 'Power Yoga',
      local: 'en-US',
      job_type_id: 'power_yoga',
    },
    {
      vertical: 'yoga_instructor',
      job_type: 'Restorative',
      local: 'en-US',
      job_type_id: 'restorative',
    },
    {
      vertical: 'yoga_instructor',
      job_type: 'Vinyasa',
      local: 'en-US',
      job_type_id: 'vinyasa',
    },
    {
      vertical: 'yoga_instructor',
      job_type: 'Yin',
      local: 'en-US',
      job_type_id: 'yin',
    },
    {
      vertical: 'yoga_instructor',
      job_type: 'Other',
      local: 'en-US',
      job_type_id: 'yoga_instructor_other',
    },
  ],
}
