import config from '@/config'
import { sortOptions } from '@/utils/generalFunctions'
import AppState, { ISelectOption } from '../../states/app'
import { requests } from './BaseService'
const emailBuilderEndpoint = `/emails/builder/all`

export const TemplateService = {
  async getEmailTemplates(skip = 0, limit = 2000): Promise<ISelectOption[]> {
    const [emailSnippets, emailBuilderTemplates] = await Promise.all([
      getTemplates('email', skip, limit),
      getEmailBuilderTemplates(),
    ])

    return sortOptions(
      emailSnippets
        .concat(emailBuilderTemplates as any)
        .map(t => ({ ...t, value: t.id, label: t.name || 'No Name' })),
      'label'
    )
  },

  async getSMSTemplates(skip = 0, limit = 2000): Promise<ISelectOption[]> {
    const templates = await getTemplates('sms', skip, limit)
    return sortOptions(
      templates.map(t => ({ ...t, value: t.id, label: t.name })),
      'label'
    )
  },
}

async function getTemplates(type: 'email' | 'sms', skip = 0, limit = 1000) {
  try {
    const { templates } = (await requests().get(
      `${config.restApiUrl}/locations/${AppState.locationId}/templates`,
      {
        params: {
          type,
          skip,
          limit,
        },
      }
    )) as {
      templates: Array<{
        id: string
        name: string
        template: {
          attachments: Array<string>
          html: string
          subject: string
        }
        type: string
      }>
    }
    return templates.map(t => ({
      ...t,
      name: `${t.name} (HTML snippet)`,
      source: 'other',
    }))
  } catch (e) {
    console.warn(`Error Fetching Templates ${type}`)
    return []
  }
}

async function getEmailBuilderTemplates() {
  const fields = [
    'versionHistory',
    'builderVersion',
    'fromAddress',
    'fromName',
    'subjectLine',
    'previewText',
    'locationId',
    'type',
    'id',
    'archived',
    'emailData',
    'downloadUrl',
    'dateAdded',
    'dateUpdated',
    'subjectLine',
  ].join(',')

  try {
    const response = await requests().get(
      `${config.restApiUrl}${emailBuilderEndpoint}`,
      {
        params: {
          locationId: AppState.locationId,
          deleted: false,
          fields,
        },
      }
    )

    const emailTemplates = response?.data as Array<{
      downloadUrl: string
      dateAdded: string
      dateUpdated: string
      [key: string]: string | boolean | number
    }>

    return emailTemplates.map(template => ({
      ...template,
      type: 'email',
      source: 'email-builder',
      previewUrl: template.downloadUrl,
      createdAt: template.dateAdded,
      updatedAt: template.dateUpdated,
      subject: template.subjectLine,
    }))
  } catch (error) {
    console.warn('Error Fetching Templates: Email Builder', error)
    return []
  }
}
