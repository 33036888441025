<script setup lang="ts">
import {
  UIModal,
  UIModalHeader,
  UIModalFooter,
  UISelect,
} from '@gohighlevel/ghl-ui'
import { CalendarCheck01Icon } from '@gohighlevel/ghl-icons/24/outline'

import { computed, ref, watch } from 'vue'
import AppState from '@/states/app'
import Team from '@/models/team'
const props = defineProps({
  show: Boolean,
})

const emit = defineEmits(['onConfirm', 'onModalClose', 'update:show'])

const handleSave = () => {
  emit('onConfirm', selectedTeam.value)
}
const handleCancel = () => {
  emit('onModalClose')
}

const teams = computed(() => {
  return AppState.globalTeams?.teams || []
})

const teamOptions = computed(() => {
  return teams.value.map((team: Team) => ({
    label: team.name,
    value: team.id,
  }))
})

watch(
  () => teamOptions.value,
  () => {
    if (teamOptions.value.length > 0) {
      selectedTeam.value = teamOptions.value[0].value
    }
  }
)

const selectedTeam = ref('')
</script>

<template>
  <UIModal
    id="generalConfirmationModal"
    :width="700"
    :show="show"
    @update:show="val => $emit('update:show', val)"
  >
    <template #header>
      <UIModalHeader
        id="generalConfirmationModalHeader"
        type="primary"
        title="Select Calendar Group"
        :icon="CalendarCheck01Icon"
        @close="handleCancel"
      >
      </UIModalHeader>
    </template>

    <UISelect
      id=""
      v-model:value="selectedTeam"
      placeholder="Select one"
      :options="teamOptions"
    />

    <template #footer>
      <UIModalFooter
        id="generalConfirmationModalFooter"
        :negative-text="$t('settings.calendar.cancel')"
        :positive-text="$t('settings.calendar.select')"
        @negative-click="handleCancel"
        @positive-click="handleSave"
      />
    </template>
  </UIModal>
</template>
