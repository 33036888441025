<script setup lang="ts">
// Import necessary components and libraries
import NavLink from '@/components/CalendarSetup/NavLink.vue'
import Details from '@/components/ServiceMenuSetup/Details.vue'
import {
  ArrowRightIcon,
  InfoCircleIcon,
} from '@gohighlevel/ghl-icons/24/outline'
import { UIAlert, UIButton } from '@gohighlevel/ghl-ui'
import _ from 'lodash'
import { computed, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { CalendarServiceMenuService } from '../class/services/CalendarServiceMenuService'
import ArrangeGroups from '../components/ServiceMenuSetup/ArrangeGroups.vue'
import SelectGroups from '../components/ServiceMenuSetup/SelectGroups.vue'
import ServiceMenu from '../models/service_menu'
import router from '../router'
import AppState, {
  getEditingServiceMenu,
  setEditingServiceMenu,
  setIsAdvancedCalendarPageLoading,
} from '../states/app'
import { toggleAdvancedView } from '../utils/commonModalFunctions'

const { t } = useI18n()
// Define reactive variables
const detailsPage = ref()
const selectGroupsPage = ref()
const arrangeGroupsPage = ref()
const selectedGroups: any = ref([])
const currentServiceMenuId = ref('')
const showInfo = ref(true)
// Get current route
const route = useRoute()

// Define a reactive state object
const state = reactive({
  isLoading: false,
  isSaving: false,
  currentStep: 0,
})

// Define methods for handling data and actions
const methods = {
  // Load data when the component is mounted
  async loadData() {
    setIsAdvancedCalendarPageLoading(true)
    await Promise.all([methods.loadServiceMenu()])
    setIsAdvancedCalendarPageLoading(false)
  },

  // Load the service menu based on route parameters
  async loadServiceMenu() {
    const params = route.params

    if (params && params?.serviceMenuId !== 'create') {
      getEditingServiceMenu(params?.serviceMenuId as string)
    }
  },

  // Handle cancel action
  async handleCancel() {
    AppState.editingServiceMenu = null
    redirectToListView()
  },

  // Handle save action
  async handleSave() {
    state.isSaving = true

    // Retrieve data from child components
    const detailsPageData = await detailsPage.value.methods.emitSave()
    const selectedGroupsPageData =
      await selectGroupsPage.value?.methods.emitSave()
    const arrangeGroupsPageData =
      await arrangeGroupsPage.value.methods.emitSave()

    // Determine menu details based on available data
    const menuDetails =
      state.currentStep === 1 || !arrangeGroupsPageData.length
        ? selectedGroupsPageData
        : arrangeGroupsPageData

    if (!detailsPageData) {
      state.currentStep = 0
      state.isSaving = false
      return
    } else if (!arrangeGroupsPageData) {
      state.currentStep = 2
      state.isSaving = false
      return
    }

    // Create a payload for saving
    const payload = {
      locationId: AppState.locationId,
      ...detailsPageData,
      menu: menuDetails,
    }
    // Remove unnecessary properties if editing an existing service menu
    if (editingServiceMenu.value) {
      delete payload.isActive
      delete payload.locationId
      await CalendarServiceMenuService.edit(
        editingServiceMenu.value?.id,
        payload
      )
    } else {
      const { data } = await CalendarServiceMenuService.create(payload)
      const serviceMenu = new ServiceMenu(data?.serviceMenu)
      currentServiceMenuId.value = serviceMenu.id
      setEditingServiceMenu(new ServiceMenu(data?.serviceMenu))
    }
    debouncedServiceMenuRefresh()
  },
  // Refresh the calendar and redirect
  async refreshServiceMenu() {
    const groups = await selectGroupsPage.value?.methods.emitSave()
    if (!editingServiceMenu.value?.id) {
      await getEditingServiceMenu(
        editingServiceMenu.value?.id ?? currentServiceMenuId.value
      )
    }
    if (state.currentStep === 0) {
      state.currentStep = 1
    } else if (state.currentStep === 1 && groups.length > 0) {
      selectedGroups.value = groups
      state.currentStep = 2
    } else if (state.currentStep === 2) {
      toggleAdvancedView()
      AppState.editingServiceMenu = null
      redirectToListView()
    }
    state.isSaving = false
  },
}

const redirectToListView = () => {
  toggleAdvancedView()
  router.push({
    name: 'calendar-settings__default',
    params: {
      locationId: AppState.locationId,
    },
    query: {
      tab: 'services',
    },
  })
}

// Compute the editingServiceMenu property
const editingServiceMenu = computed(() => {
  return AppState.editingServiceMenu as ServiceMenu
})

// Define the steps for the wizard
const steps = [
  {
    title: t('service_menu_advanced.new_service_menu_details_header'),
    id: 'service-details',
  },
  {
    title: t('service_menu_advanced.select_groups_header'),
    id: 'select-groups',
  },
  {
    title: t('service_menu_advanced.arrange_groups_header'),
    id: 'arrange-groups',
  },
]

// Handle navigation between steps
const handleNavLink = async (step: number) => {
  state.currentStep = step
  if (step === 2) {
    const groups = await selectGroupsPage.value?.methods.emitSave()
    selectedGroups.value = groups
  }
}

const onClickDontShow = () => {
  localStorage.setItem('showCalendarServiceMenuAlert', 'false')
  showInfo.value = false
}

const showAlert = () => {
  const data = localStorage.getItem('showCalendarServiceMenuAlert')
  showInfo.value = data === 'false' ? false : true
}
// Load data when the component is mounted
methods.loadData()
showAlert()

// Debounce calendar refresh function
const debouncedServiceMenuRefresh = _.debounce(methods.refreshServiceMenu, 2000)

const redirectToSelectService = () => {
  state.currentStep = 1
}

const onClickLearnMore = () => {
  window.open(
    'https://help.leadconnectorhq.com/en/support/solutions/articles/155000001376-service-menu',
    '_blank'
  )
}
</script>

<template>
  <div class="flex w-full flex-col justify-center">
    <div
      class="mb-7 flex w-full items-center justify-between rounded-sm px-5 py-3 text-sm shadow-md"
    >
      <div class="flex flex-col gap-1">
        <div v-if="editingServiceMenu" class="pl-3 text-2xl">
          {{ $t('calendar_advanced.general.edit') }} -
          {{ editingServiceMenu.name }}
        </div>
        <div v-else class="pl-3 text-2xl">
          {{ $t('service_menu_advanced.new_service_menu') }}
        </div>
        <div class="pl-3 text-gray-500">
          {{ $t('service_menu_advanced.service_menu_sub_title') }}
        </div>
      </div>

      <div class="flex space-x-3">
        <UIButton
          id="create-service-menu-close"
          type="default"
          @click="methods.handleCancel"
        >
          {{ $t('calendar_advanced.general.close') }}
        </UIButton>
        <UIButton
          id="create-service-menu-save"
          type="primary"
          :loading="state.isSaving"
          @click="methods.handleSave"
        >
          {{ $t('calendar_advanced.general.save') }}
        </UIButton>
      </div>
    </div>

    <div class="flex w-full justify-center space-x-14 p-3">
      <div class="flex w-[12rem] flex-col space-y-3">
        <NavLink
          v-for="(step, ind) in steps"
          :key="step.title"
          :is-active="state.currentStep === ind"
          :text="step.title"
          @click="() => handleNavLink(ind)"
        />

        <div
          v-if="state.currentStep !== 0"
          class="flex flex-col space-y-2 pt-10"
        >
          <div class="flex items-center space-x-2">
            <InfoCircleIcon class="h-5 w-5 text-gray-400" />
            <div class="text-md font-medium text-gray-400">
              {{ $t('calendar_advanced.general.quick_tip') }}
            </div>
          </div>
          <div class="pl-1 text-xs text-gray-400">
            {{
              state.currentStep == 2
                ? $t('service_menu_advanced.arrange_services_quicktip')
                : $t('service_menu_advanced.quick_tip')
            }}
          </div>
        </div>
      </div>

      <div class="flex w-[60%] flex-col gap-3">
        <UIAlert
          v-if="showInfo"
          id="alert"
          type="info"
          @close="showInfo = !showInfo"
        >
          <template #title>
            <div class="font-medium text-[#344054]">
              {{ $t('service_menu_advanced.create_service_calendars') }}
            </div>
          </template>
          <template #content>
            <div class="text-[#475467]">
              {{ $t('service_menu_advanced.alert_description') }}
            </div>
          </template>
          <template #actions>
            <div class="flex justify-between gap-2">
              <UIButton
                id="btn"
                text
                class="text-[#344054]"
                @click="onClickDontShow"
                >{{ $t('service_menu_advanced.dont_show_again') }}
              </UIButton>
              <UIButton id="btn" text @click="onClickLearnMore"
                >{{ $t('service_menu_advanced.learn_more') }}
                <ArrowRightIcon class="ml-2 h-5 w-5" />
              </UIButton>
            </div>
          </template>
        </UIAlert>

        <div
          class="max-w-4xl flex-grow overflow-y-auto rounded-xl border bg-white p-10 shadow-md"
          :class="{
            'pointer-events-none blur-md filter':
              AppState.loadingAssets.editingServiceMenu,
            'max-h-[calc(100vh-18rem)]': showInfo,
            'max-h-[calc(100vh-8rem)]': !showInfo,
          }"
        >
          <div
            :class="{
              hidden: state.currentStep !== 0,
            }"
          >
            <Details ref="detailsPage" />
          </div>

          <div
            :class="{
              hidden: state.currentStep !== 1,
            }"
          >
            <SelectGroups ref="selectGroupsPage" />
          </div>

          <div
            :class="{
              hidden: state.currentStep !== 2,
            }"
          >
            <ArrangeGroups
              ref="arrangeGroupsPage"
              :groups="selectedGroups"
              :redirect-to-select-service="redirectToSelectService"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
