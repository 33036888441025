<template>
  <div class="items-center justify-between py-3">
    <ConnectAccountCard
      :logo="type === 'outlook' ? OutlookLogo : GmailLogo"
      :connection-name="type"
      :account-name="accountName"
      :oauth-id="connectedCalendars[0].oauthId"
    />
    <div class="mt-8 flex h-48 flex-col space-y-6 overflow-auto">
      <div
        v-for="connectedCalendar in connectedCalendars"
        :key="connectedCalendar.id"
        class=""
      >
        <div class="flex items-center space-x-4">
          <UIToggle
            :checked="
              userCalendars
                .filter(x => x.isCheckForConflictsCalendar)
                .map(x => x.googleCalendarId)
                .includes(connectedCalendar.id)
            "
            :disabled="
              userCalendars
                .filter(x => !x.isCheckForConflictsCalendar)
                .map(x => x.googleCalendarId)
                .includes(connectedCalendar.id) || isLoading
            "
            @update:checked="
              val =>
                handleCalendarSelectionChange(
                  val,
                  connectedCalendar.id,
                  connectedCalendar.summary,
                  connectedCalendar.oauthId,
                  type
                )
            "
          >
          </UIToggle>

          <div class="text-sm font-medium text-gray-900">
            {{ connectedCalendar.summary }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { UIToggle } from '@gohighlevel/ghl-canvas'

import AppState from '@/states/app'
import { IConnectedCalendar } from '@/types/connection'
import { PropType, computed } from 'vue'

import GmailLogo from '@/assets/gmail-logo.png'
import OutlookLogo from '@/assets/outlook-logo.png'

import ConnectAccountCard from './ConnectAccountCard.vue'
const props = defineProps({
  type: String,
  accountName: String,
  connectedCalendars: {
    type: Array as PropType<IConnectedCalendar[]>,
    required: true,
  },
  handleCalendarSelectionChange: {
    type: Function,
    required: true,
  },
  isLoading: Boolean,
})

const userCalendars = computed(() => {
  return AppState?.userCalendars
})
</script>
