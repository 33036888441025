import { Plugin } from 'vue'
import UITextInput from './UITextInput.vue'
import UISelect from './UISelect.vue'
import UITooltip from './UITooltip.vue'
import UIDatePicker from './UIDatePicker.vue'
import UIDropdown from './UIDropdown.vue'
import UIModal from './UIModal.vue'

import Icon from './Icon.vue'

export const registerComponents: Plugin = {
  install(app) {
    app.component('UITextInput', UITextInput)
    app.component('UISelect', UISelect)
    app.component('UIDatePicker', UIDatePicker)
    app.component('UITooltip', UITooltip)
    app.component('Icon', Icon)
    app.component('UIDropdown', UIDropdown)
    app.component('UIModal', UIModal)
  },
}
