<template>
  <div class="fixed z-10 inset-0 overflow-y-auto" v-if="show">
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
      <!-- BG -->
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-gray-600 opacity-75"></div>
      </div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
      >&#8203;
      <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    -->
      <div
        :class="`${dialogClasses} ${classStyle}`"
        class="py-5 px-4 inline-block align-bottom bg-white rounded text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <!-- body -->
        <!-- footer -->
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
const modalSize: { [key: string]: string } = {
  default: 'sm:max-w-lg',
  small: 'sm:max-w-md',
  xtraLarge: 'sm:max-w-xl',
  xtra2Large: 'sm:max-w-2xl',
}
export default defineComponent({
  name: 'UIModal',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'default',
    },
    dialogClasses: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    function toggle(e: any) {
      console.log('toggle', e)
    }
    const styles = new Set()
    styles.add(modalSize[props.size])
    return {
      toggle,
      classStyle: [...styles],
    }
  },
})
</script>
