<script setup lang="ts">
import {
  UICheckbox,
  UIForm,
  UIFormItem,
  UIInput,
  UIInputGroup,
  UIInputNumber,
  UIModal,
  UIModalFooter,
  UIModalHeader,
  UISelect,
} from '@gohighlevel/ghl-ui'
import { computed, reactive, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { POSITION, TYPE, useToast } from 'vue-toastification'
import { MyAvailability } from '../../class/pages/MyAvailability'
import AppState from '../../states/app'
const formRef = ref()

const { t } = useI18n()
const toast = useToast()

const state = reactive({
  isLoading: false,
  isStandardAvailability: true,
  formData: {
    enableRecurring: false,
    freq: 'DAILY',
    monthlyRecurrence: 'ON_DAY',
    monthDay: 1,
    interval: '1',
  },
  showCustomRecurrenceModal: false,
  monthlyRecurrenceOptions: [
    {
      value: 'ON_DAY',
      label: t('calendar_advanced.availability.month_on_day'),
    },
    {
      value: 'ON_LAST_DAY',
      label: t('calendar_advanced.availability.month_on_last_day'),
    },
  ],
  recurringFrequencyOptions: [
    {
      value: 'DAILY',
      label: t('calendar_advanced.availability.day'),
    },
    {
      value: 'WEEKLY',
      label: t('calendar_advanced.availability.week'),
    },
    {
      value: 'MONTHLY',
      label: t('calendar_advanced.availability.month'),
    },
  ],
})

const rules = computed(() => {
  return {
    interval: {
      required: true,
      message: 'This is required',
      trigger: ['input'],
    },
    monthDay: {
      validator() {
        if (
          state.formData.monthlyRecurrence === 'ON_DAY' &&
          !state.formData.monthDay
        ) {
          return new Error('This is required')
        }
        if (state.formData.monthDay > 31) {
          return new Error('Month day should not exceed 31')
        }
        return true
      },
      trigger: ['input', 'blur'],
    },
  }
})

const myAvailability = ref(new MyAvailability(''))

const props = defineProps({
  show: Boolean,
  disabledConfirm: Boolean,
})

const editingCalendar = computed(() => {
  return AppState.editingCalendar
})

const emit = defineEmits(['onConfirm', 'onModalClose', 'update:show'])

const handleSave = async () => {
  try {
    if (state.formData.freq === 'WEEKLY') {
      if (myAvailability.value.availableDays.length === 0) {
        toast('Please select at least one day', {
          type: TYPE.ERROR,
          position: POSITION.BOTTOM_RIGHT,
        })
        throw new Error('Please select at least one day')
      }
    }

    await formRef.value.getForm().validate()
    emit('onConfirm', {
      weekDays:
        state.formData.freq === 'WEEKLY'
          ? [
              ...new Set(
                myAvailability.value.availableDays.map(x =>
                  x.day.substring(0, 2).toUpperCase()
                )
              ),
            ]
          : [],
      monthDays:
        state.formData.freq === 'MONTHLY'
          ? state.formData.monthlyRecurrence === 'ON_LAST_DAY'
            ? [-1]
            : [state.formData.monthDay]
          : [],
      interval: parseInt(state.formData.interval),
      freq: state.formData.freq,
    })
  } catch (error) {
    console.error(error)
  }
}
const handleCancel = () => {
  emit('onModalClose')
}

const methods = {
  async loadData() {
    if (editingCalendar?.value?.recurring) {
      if (editingCalendar.value.recurring?.interval) {
        state.formData.interval =
          editingCalendar.value.recurring?.interval.toString()
      }
      myAvailability.value.dayWiseOpenMap = convertArrayToDaysObject()
      state.formData.freq = editingCalendar.value?.recurring?.freq
      state.formData.monthlyRecurrence =
        editingCalendar.value?.recurring?.monthDays![0] === -1
          ? state.monthlyRecurrenceOptions[1].value
          : state.monthlyRecurrenceOptions[0].value
      if (editingCalendar.value?.recurring?.monthDays![0] !== -1) {
        state.formData.monthDay = Number(
          editingCalendar?.value?.recurring?.monthDays
        )
      }
    }
  },
}

const convertArrayToDaysObject = () => {
  const daysObject = myAvailability?.value?.dayWiseOpenMap

  editingCalendar?.value?.recurring?.weekDays?.forEach(
    (dayAbbreviation: string) => {
      switch (dayAbbreviation) {
        case 'SU':
          daysObject.Sunday = true
          myAvailability.value.addAvailabilityDay('Sunday')
          break
        case 'MO':
          daysObject.Monday = true
          myAvailability.value.addAvailabilityDay('Monday')
          break
        case 'TU':
          daysObject.Tuesday = true
          myAvailability.value.addAvailabilityDay('Tuesday')
          break
        case 'WE':
          daysObject.Wednesday = true
          myAvailability.value.addAvailabilityDay('Wednesday')
          break
        case 'TH':
          daysObject.Thursday = true
          myAvailability.value.addAvailabilityDay('Thursday')
          break
        case 'FR':
          daysObject.Friday = true
          myAvailability.value.addAvailabilityDay('Friday')
          break
        case 'SA':
          daysObject.Saturday = true
          myAvailability.value.addAvailabilityDay('Saturday')
          break
        default:
          // Handle invalid day abbreviations if needed
          break
      }
    }
  )

  return daysObject
}

watch(
  () => editingCalendar.value,
  () => {
    methods.loadData()
  },
  { immediate: true }
)
</script>

<template>
  <UIModal :show="props.show">
    <template #header>
      <UIModalHeader
        id="modal-header"
        title="Custom recurrence"
        @close="handleCancel"
      >
      </UIModalHeader>
    </template>
    <UIForm
      id="recurring-form"
      ref="formRef"
      :model="state.formData"
      :rules="rules"
    >
      <UIFormItem label="Repeats Every" class="mt-4 w-full" path="interval">
        <UIInputGroup class="w-full">
          <UIInput
            id="custom-recuurence-repeatCount"
            v-model="state.formData.interval"
            class="w-96"
            placeholder=""
          />
          <UISelect
            id="custom-recurrence-units"
            v-model:value="state.formData.freq"
            class="w-60"
            :options="state.recurringFrequencyOptions"
          >
          </UISelect>
        </UIInputGroup>
      </UIFormItem>

      <div v-if="state.formData.freq === 'WEEKLY'" class="flex space-x-3">
        <UICheckbox
          v-for="day in MyAvailability.OFFICE_DAYS"
          id="office-hours-checkbox"
          :key="day"
          v-model:checked="myAvailability.dayWiseOpenMap[day]"
          :name="day"
          class="text-md flex items-center rounded-md border bg-white p-2 text-xs"
          :class="{
            'border-primary-500 bg-primary-50':
              myAvailability.availableDays.find(x => x.day === day),
          }"
          @change="
            x =>
              x
                ? myAvailability.addAvailabilityDay(day)
                : myAvailability.removeAvailabilityDay(day)
          "
        >
          <span class="ml-0.5">{{ day.substring(0, 1) }}</span>
        </UICheckbox>
      </div>

      <div v-if="state.formData.freq === 'MONTHLY'" class="flex space-x-3">
        <UISelect
          id="monthlyRecurrence-select"
          v-model:value="state.formData.monthlyRecurrence"
          class="w-60"
          :options="state.monthlyRecurrenceOptions"
        >
        </UISelect>
        <UIFormItem
          v-if="state.formData.monthlyRecurrence === 'ON_DAY'"
          path="monthDay"
        >
          <UIInputNumber
            id="monthDay-input"
            v-model="state.formData.monthDay"
            class="-mt-11"
            placeholder=""
          />
        </UIFormItem>
      </div>
    </UIForm>

    <template #footer>
      <UIModalFooter
        id="modal-footer"
        positive-text="Save"
        negative-text="Cancel"
        :loading="false"
        :disabled="false"
        type="primary"
        @positive-click="handleSave"
        @negative-click="handleCancel"
      >
      </UIModalFooter>
    </template>
  </UIModal>
</template>
