<!-- eslint-disable vue/require-default-prop -->
<script setup lang="ts">
import NoServiceImg from '@/assets/no-service.svg'
import {
  ArrowNarrowLeftIcon,
  Menu05Icon,
} from '@gohighlevel/ghl-icons/24/outline'
import { UIDivider } from '@gohighlevel/ghl-ui'
import { defineExpose, onMounted, ref, watch } from 'vue'
import { VueDraggableNext } from 'vue-draggable-next'

// Define props for the component
const props = defineProps({
  groups: {
    type: Array,
    default: [] as any,
  },
  redirectToSelectService: Function,
})
const reorderedGroups: any = ref([])

//On component mount, initialize reorderedGroups with props.groups
onMounted(() => {
  reorderedGroups.value = props.groups
})

// Watch for changes in props.groups and update reorderedGroups accordingly
watch(
  () => props.groups,
  newValue => {
    if (newValue) {
      reorderedGroups.value = props.groups
    }
  }
)

// Function to handle group order change
const onChangeGroup = () => {
  // Map and update group data with new sort order
  const updatedData = reorderedGroups.value?.map((item, index) => ({
    groupId: item?.groupId,
    name: item?.name,
    calendarIds: item?.calendarIds,
    sortOrder: index,
  }))
  return updatedData
}

// Define methods, including emitSave to emit the updated group order
const methods = {
  async emitSave() {
    return onChangeGroup()
  },
}

// Expose methods to the parent component
defineExpose({
  methods,
})
</script>

<template>
  <div class="mb-4 flex flex-col">
    <div class="font-medium text-gray-700">
      {{ $t('service_menu_advanced.arrange_groups') }}
    </div>
    <div class="mt-1 text-gray-600">
      {{ $t('service_menu_advanced.arrange_groups_desc') }}
    </div>
  </div>
  <UIDivider v-if="groups?.length <= 0" />

  <VueDraggableNext
    v-if="groups?.length > 0"
    :list="reorderedGroups"
    class="dragArea list-group w-full"
  >
    <transition-group name=" flip-list">
      <div
        v-for="teamCal in groups"
        :key="teamCal.id"
        class="flex cursor-move items-center space-x-5 border-t py-4 font-medium"
      >
        <div class="">
          <Menu05Icon class="h-4 w-4 text-gray-500" />
        </div>
        <div class="text-gray-700">
          {{ teamCal.name }}
        </div>
      </div>
    </transition-group>
  </VueDraggableNext>

  <div v-else class="mt-8 flex flex-col items-center justify-center gap-2">
    <img :src="NoServiceImg" />
    <div class="text-[16px] font-medium text-gray-900">
      {{ $t('service_menu_advanced.no_service_selected') }}
    </div>
    <div
      class="mt-3 cursor-pointer font-medium text-primary-700"
      @click="redirectToSelectService"
    >
      <ArrowNarrowLeftIcon class="mr-2 h-5 w-5 text-primary-700" />
      {{ $t('service_menu_advanced.select_groups_header') }}
    </div>
  </div>
</template>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}
</style>
