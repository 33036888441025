<template>
  <div class="relative w-full inline-block text-left">
    <div>
      <span class="rounded-md shadow-sm">
        <button
          type="button"
          class="inline-flex content-between justify-between w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm leading-5 font-normal text-black focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150"
          id="options-menu"
          aria-haspopup="true"
          aria-expanded="true"
          :disabled="disabled"
          @click="toggleOpen()"
        >
          {{ selectedOption }}
          <svg
            class="-mr-1 ml-2 h-5 w-5 text-gray-400"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </span>
    </div>
    <div
      class="origin-top-right absolute z-50 right-0 mt-2 w-full rounded-md shadow-lg"
      v-if="open"
      v-click-away="() => (open = false)"
    >
      <div class="p-2 w-full bg-white">
        <UITextInput
          v-model="searchText"
          placeholder="Search"
          id="uidropdown-input-search"
        />
      </div>
      <div class="rounded-md bg-white shadow-xs max-h-64 overflow-y-scroll">
        <div
          class="py-1"
          v-for="(option, index) in filteredOptions"
          :key="index"
        >
          <a
            v-if="option.title"
            href="#"
            class="block px-4 py-1 text-sm leading-5 text-white bg-elephant-400"
          >
            {{ option.title }}
          </a>
          <span v-if="option.options && option.options.length > 0">
            <a
              href="#"
              class="block px-4 py-2 text-sm leading-5"
              :class="[
                item.isDisabled
                  ? 'cursor-not-allowed text-gray-400'
                  : 'cursor-pointer text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900',
              ]"
              v-for="item in option.options"
              :key="item.value"
              @click="pickItem(item)"
              v-show="item.enableForHLOnly ? shouldShowOption : true"
            >
              <div class="text-sm">
                {{ item.title }}
              </div>
              <div v-if="item.description" class="text-xs">
                {{ item.description }}
              </div>
            </a>
          </span>

          <a
            v-else
            class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
          >
            <div class="text-sm">No data available</div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, nextTick, ref, toRefs } from 'vue'
import { UITextInput } from '@gohighlevel/ghl-canvas'
import { isArray } from 'lodash'
import { sortOptions } from '../../utils/generalFunctions'
import { MasterOption, Option } from '../../states/app'

export default defineComponent({
  name: 'UIDropdown',
  props: {
    options: {
      type: Array as () => MasterOption[],
      required: true,
    },
    defaultOpen: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Select',
    },
    selectedValue: String,
    sort: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    autoFocus: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    UITextInput,
  },

  setup(props, { emit }) {
    const { options, selectedValue } = toRefs(props)
    const { defaultOpen } = toRefs(props)
    const open = ref(defaultOpen.value)
    const searchText = ref('')

    const shouldShowOption = true

    const selectedOption = ref(props.placeholder)
    if (selectedValue) {
      const allOptions: Option[] = []
      options.value?.forEach(arr => {
        if (arr.options && isArray(arr.options)) allOptions.push(...arr.options)
      })
      let found = allOptions.find(item => item.value === selectedValue.value)
      if (found) {
        selectedOption.value = found.title
      }
    }

    const filteredOptions = computed(() => {
      if (props.sort) {
        props.options.map(item => {
          item.options = sortOptions(item.options || [], 'title')
        })
      }

      if (!searchText.value) return props.options

      let totalOptions: Option[] = []

      props.options.forEach(masterOption => {
        if (masterOption.options) totalOptions.push(...masterOption.options)
      })

      let options = totalOptions.filter((item: Option) =>
        item.title.toLowerCase().includes(searchText.value.toLowerCase())
      )

      if (props.sort) {
        options = sortOptions(options, 'title')
      }

      return [
        {
          title: 'Search Result',
          options,
        },
      ]
    })

    function pickItem(option: Option) {
      if (option.isDisabled) return

      selectedOption.value = option.title
      searchText.value = ''
      open.value = false
      emit('update:selected', option)
    }

    async function toggleOpen() {
      open.value = !open.value
      if (open.value && props.autoFocus) {
        await nextTick()
        const element = document.getElementById('uidropdown-input-search')
        element?.focus()
      }
    }

    // const parentRef = ref()
    // const itemsRef = ref()
    // onMounted(() => {
    //   let firstTime = defaultOpen.value ? true : false
    //   function handler(event: MouseEvent) {
    //     const target = event.target as HTMLElement
    //     const active = document.activeElement
    //     if (parentRef.value?.contains(target)) return
    //     if (!itemsRef.value?.contains(target) && !firstTime) {
    //       open.value = false
    //     }
    //     firstTime = false
    //     if (active !== document.body && active?.contains(target)) return // Keep focus on newly clicked/focused element
    //   }
    //   window.addEventListener('click', handler)
    //   onUnmounted(() => window.removeEventListener('click', handler))
    // })

    return {
      options,
      open,
      pickItem,
      selectedOption,
      filteredOptions,
      searchText,
      shouldShowOption,
      toggleOpen,
    }
  },
})
</script>
