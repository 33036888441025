<script setup lang="ts">
import CalendarLinkModal from '@/components/Settings/CalendarLinkModal.vue'
import CalendarListTable from '@/components/Settings/CalendarListTable.vue'
import CreateCalendarModalVue from '@/components/Settings/CreateCalendarModal.vue'
import CreateGroupModal from '@/components/Settings/CreateGroupModal.vue'
import GroupListTable from '@/components/Settings/GroupListTable.vue'
import SelectCalendarTypeModalOldVue from '@/components/Settings/SelectCalendarTypeModalOld.vue'
import SelectCalendarTypeModalVue_V2 from '@/components/Settings/SelectCalendarTypeModalV2.vue'
import SelectTeamModalVue from '@/components/Settings/SelectTeamModal.vue'
import Calendar, { CalendarType } from '@/models/calendar'
import router from '@/router'
import AppState, {
  getCalendarsInLocation,
  getEquipmentsInLocation,
  getRoomsInLocation,
  getServiceMenusInLocation,
  getTeamsInLocation,
  isEquipmentEnabled,
  isRoomEnabled,
  isServiceCalendarEnabled,
  resetCalendarEditValidationData,
  setEditingCalendar,
  setIsLoading,
} from '@/states/app'
import {
  CalendarIcon,
  PlusIcon,
  UserPlus01Icon,
} from '@gohighlevel/ghl-icons/24/outline'

import { UIHeader, UITabPane, UITabs } from '@gohighlevel/ghl-ui'
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import CreateEquipmentModal from '../components/Settings/CreateEquipmentModal.vue'
import CreateRoomModal from '../components/Settings/CreateRoomModal.vue'
import EquipmentsListTable from '../components/Settings/EquipmentsListTable.vue'
import RoomsListTable from '../components/Settings/RoomsListTable.vue'
import ServicesListTable from '../components/Settings/ServicesListTable.vue'
import { openCalendarModal } from '../utils/commonModalFunctions'

const showNewGroupModal = ref(false)
const showNewEquipmentModal = ref(false)
const showNewRoomModal = ref(false)
const showCalendarTypeModal = ref(false)
const showCreateCalendarModal = ref(false)
const showCalendarLinkModal = ref(false)
const showSelectTeamModal = ref(false)
const selectedCalendarType = ref(CalendarType.EVENT)
const route = useRoute()
const tabType = ref(route.query?.tab || 'calendars')

const isNewCalendarSetupFlowEnabled = computed(() => {
  return AppState.isNewCalendarSetupFlowEnabled
})

const closeGroupModal = () => {
  showNewGroupModal.value = false
}

const newGroupCreated = () => {
  getTeamsInLocation()
  closeGroupModal()
}
const openSelectTeamModal = (calendarType: CalendarType) => {
  if (isNewCalendarSetupFlowEnabled.value) {
    selectedCalendarType.value = calendarType
    showCreateCalendarModal.value = true
  } else {
    openCalendarModal('', '', calendarType)
  }

  showCalendarTypeModal.value = false
}

const openClassBookingCalendarModal = () => {
  if (isNewCalendarSetupFlowEnabled.value) {
    selectedCalendarType.value = CalendarType.CLASS_BOOKING
    showCreateCalendarModal.value = true
  } else {
    openCalendarModal('', '', CalendarType.CLASS_BOOKING)
  }

  showCalendarTypeModal.value = false
}

const openCollectiveBookingCalendarModal = () => {
  if (isNewCalendarSetupFlowEnabled.value) {
    selectedCalendarType.value = CalendarType.COLLECTIVE
    showCreateCalendarModal.value = true
  } else {
    openCalendarModal('', '', CalendarType.COLLECTIVE)
  }

  showCalendarTypeModal.value = false
}

const openUnassignedCalendar = () => {
  if (isNewCalendarSetupFlowEnabled.value) {
    selectedCalendarType.value = CalendarType.EVENT
    showCreateCalendarModal.value = true
  } else {
    openCalendarModal('', '')
  }

  showCalendarTypeModal.value = false
}

const openTeamCalendar = selectedGroupId => {
  if (isNewCalendarSetupFlowEnabled.value) {
    showCreateCalendarModal.value = true
  } else {
    showSelectTeamModal.value = false
    openCalendarModal('', selectedGroupId)
  }
}

const openCalendarTypeModal = () => {
  resetCalendarEditValidationData()
  showCalendarTypeModal.value = true
}

const newCalendarCreated = async (calendar: Calendar) => {
  showCreateCalendarModal.value = false
  showCalendarLinkModal.value = true
  setEditingCalendar(calendar)
  setIsLoading(true)
  await getTeamsInLocation()
  await getCalendarsInLocation()
  setIsLoading(false)
}

const openServiceMenu = () => {
  router.push({
    name: 'service-menu__advanced',
    params: {
      serviceMenuId: 'create',
    },
  })
}

onMounted(async () => {
  setIsLoading(true)
  await getTeamsInLocation()
  await getCalendarsInLocation()
  if (isServiceCalendarEnabled()) {
    await getServiceMenusInLocation()
  }
  if (isRoomEnabled()) {
    await getRoomsInLocation()
  }
  if (isEquipmentEnabled()) {
    await getEquipmentsInLocation()
  }
  setIsLoading(false)
})

const openCreateResourceModal = () => {
  if (tabType.value === 'equipments') {
    showNewEquipmentModal.value = true
  } else if (tabType.value === 'rooms') {
    showNewRoomModal.value = true
  }
}

const closeEquipmentModal = () => {
  showNewEquipmentModal.value = false
}

const newEquipmentCreated = () => {
  getEquipmentsInLocation()
  closeEquipmentModal()
}

const closeRoomModal = () => {
  showNewRoomModal.value = false
}

const newRoomCreated = () => {
  getRoomsInLocation()
  closeRoomModal()
}

const showServiceMenuTab = computed(() => {
  return route.query?.tab === 'services' || isServiceCalendarEnabled()
})
</script>

<template>
  <div class="p-8">
    <!-- <SelectCalendarTypeModalVue
      v-if="isNewCalendarSetupFlowEnabled"
      :show="showCalendarTypeModal"
      :open-unassigned-calendar-modal="openUnassignedCalendar"
      :open-team-calendar-modal="openSelectTeamModal"
      :open-class-booking-calendar-modal="openClassBookingCalendarModal"
      :open-collective-booking-calendar-modal="
        openCollectiveBookingCalendarModal
      "
      @on-modal-close="() => (showCalendarTypeModal = false)"
    /> -->

    <SelectCalendarTypeModalVue_V2
      v-if="isNewCalendarSetupFlowEnabled"
      :show="showCalendarTypeModal"
      :open-unassigned-calendar-modal="openUnassignedCalendar"
      :open-team-calendar-modal="openSelectTeamModal"
      :open-class-booking-calendar-modal="openClassBookingCalendarModal"
      :open-collective-booking-calendar-modal="
        openCollectiveBookingCalendarModal
      "
      @on-modal-close="() => (showCalendarTypeModal = false)"
    />

    <SelectCalendarTypeModalOldVue
      v-else
      :show="showCalendarTypeModal"
      :open-unassigned-calendar-modal="openUnassignedCalendar"
      :open-team-calendar-modal="openSelectTeamModal"
      :open-class-booking-calendar-modal="openClassBookingCalendarModal"
      @on-modal-close="() => (showCalendarTypeModal = false)"
    />

    <CalendarLinkModal
      :show="showCalendarLinkModal"
      @on-modal-close="() => (showCalendarLinkModal = false)"
    />
    <CreateCalendarModalVue
      :show="showCreateCalendarModal"
      :selected-calendar-type="selectedCalendarType"
      @on-modal-close="() => (showCreateCalendarModal = false)"
      @on-confirm="newCalendarCreated"
    />
    <SelectTeamModalVue
      :show="showSelectTeamModal"
      @on-modal-close="() => (showSelectTeamModal = false)"
      @on-confirm="openTeamCalendar"
    />

    <UIHeader
      v-if="tabType === 'services'"
      id="service-menu-settings-headers"
      :title="$t('settings.calendar.calendar_settings')"
      :description="
        $t('settings.calendar.manage_your_calendars_groups_and_service_menus')
      "
      :primary-text="$t('settings.calendar.new_service_menu')"
      :primary-action-icon="PlusIcon"
      @primaryClick="openServiceMenu"
    >
    </UIHeader>
    <UIHeader
      v-if="!['services', 'rooms', 'equipments'].includes(tabType)"
      id="calendar-settings-headers"
      :title="$t('settings.calendar.calendar_settings')"
      :description="$t('settings.calendar.manage_your_calendars_and_groups')"
      :secondary-text="$t('settings.calendar.create_group')"
      :primary-text="$t('settings.calendar.create_calendar')"
      :primary-action-icon="CalendarIcon"
      :secondary-action-icon="UserPlus01Icon"
      @primaryClick="openCalendarTypeModal"
      @secondaryClick="x => (showNewGroupModal = true)"
    >
    </UIHeader>
    <UIHeader
      v-if="tabType === 'rooms'"
      id="room-menu-headers"
      :title="$t('settings.calendar.calendar_settings')"
      :description="$t('settings.calendar.manage_your_rooms')"
      :primary-text="$t(`settings.calendar.create_${tabType}`)"
      :primary-action-icon="PlusIcon"
      @primaryClick="openCreateResourceModal"
    >
    </UIHeader>
    <UIHeader
      v-if="tabType === 'equipments'"
      id="equipments-menu-headers"
      :title="$t('settings.calendar.calendar_settings')"
      :description="$t('settings.calendar.manage_your_equipments')"
      :primary-text="$t(`settings.calendar.create_${tabType}`)"
      :primary-action-icon="PlusIcon"
      @primaryClick="openCreateResourceModal"
    >
    </UIHeader>

    <UITabs v-model:value="tabType" type="line">
      <UITabPane
        name="calendars"
        :tab="$t('settings.calendar.calendars')"
        class="mt-6"
      >
        <CalendarListTable
          :open-calendar-type-modal="openCalendarTypeModal"
        ></CalendarListTable>
      </UITabPane>

      <UITabPane
        v-if="AppState.isEnabledNeoGroupWidget"
        name="groups"
        class="mt-6"
        :tab="$t('settings.calendar.groups')"
      >
        <GroupListTable></GroupListTable>
      </UITabPane>

      <UITabPane
        v-else
        name="groups"
        :tab="$t('settings.calendar.groups')"
        class="mt-6"
      >
        <GroupListTable></GroupListTable>
      </UITabPane>

      <UITabPane
        v-if="showServiceMenuTab"
        name="services"
        :tab="$t('settings.calendar.service_menus')"
        class="mt-6"
      >
        <ServicesListTable></ServicesListTable>
      </UITabPane>

      <UITabPane
        v-if="isRoomEnabled()"
        name="rooms"
        :tab="$t('settings.calendar.rooms')"
        class="mt-6"
      >
        <RoomsListTable></RoomsListTable>
      </UITabPane>

      <UITabPane
        v-if="isEquipmentEnabled()"
        name="equipments"
        :tab="$t('settings.calendar.equipments')"
        class="mt-6"
      >
        <EquipmentsListTable></EquipmentsListTable>
      </UITabPane>
    </UITabs>

    <CreateGroupModal
      :show="showNewGroupModal"
      @createdRecord="newGroupCreated"
      @closeModal="closeGroupModal"
    />
    <CreateEquipmentModal
      :show="showNewEquipmentModal"
      @createdRecord="newEquipmentCreated"
      @closeModal="closeEquipmentModal"
    />
    <CreateRoomModal
      :show="showNewRoomModal"
      @createdRecord="newRoomCreated"
      @closeModal="closeRoomModal"
    />
  </div>
</template>
