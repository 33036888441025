<template>
  <div>
    <div
      class="flex cursor-pointer items-center text-center"
      @click="toggleSourceCode"
    >
      <CodeSnippet02Icon class="h-4 w-4" />
    </div>
  </div>
  <div>
    <UIModal
      v-model:show="modal.sourceCode"
      class-name="fixed top-10 left-0 right-0 flex justify-center"
    >
      <template #header>
        <UIModalHeader
          id="modal-header"
          type="primary"
          title="Source Code"
          description=""
          :icon="CheckDone01Icon"
          @close="toggleSourceCode"
        />
      </template>
      <UIInput
        id=""
        v-model="htmlContent"
        placeholder=""
        type="textarea"
        :rows="10"
      />
      <template #footer>
        <UIModalFooter
          id="modal-footer"
          :negative-text="$t('calendar_advanced.general.close')"
          :positive-text="$t('calendar_advanced.general.save')"
          :loading="false"
          :disabled="false"
          type="primary"
          @positive-click="updateSourceCode"
          @negative-click="toggleSourceCode"
        >
        </UIModalFooter>
      </template>
    </UIModal>
  </div>
</template>

<script lang="ts">
import {
  CheckDone01Icon,
  CodeSnippet02Icon,
} from '@gohighlevel/ghl-icons/24/outline'
import {
  UIInput,
  UIModal,
  UIModalFooter,
  UIModalHeader,
} from '@gohighlevel/ghl-ui'
import { Editor } from '@tiptap/vue-3'
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'RTEControlSourceCode',
  components: {
    CodeSnippet02Icon,
    UIModal,
    UIModalFooter,
    UIModalHeader,
    UIInput,
  },
  props: {
    editor: {
      required: true,
      type: Editor,
    },
  },
  setup(props) {
    const htmlContent = ref<string>('')
    const modal = ref<{ sourceCode: boolean }>({
      sourceCode: false,
    })

    const toggleSourceCode = () => {
      modal.value.sourceCode = !modal.value.sourceCode
      if (modal.value.sourceCode) {
        htmlContent.value = props.editor.getHTML()
      }
    }

    const updateSourceCode = () => {
      props.editor.commands.setContent(htmlContent.value, true)
      toggleSourceCode()
    }

    return {
      htmlContent,
      modal,
      toggleSourceCode,
      updateSourceCode,
      CheckDone01Icon,
    }
  },
})
</script>
