<template>
  <div class="flex w-full justify-between space-x-4 border-b pb-4 pt-2">
    <div
      class="group relative flex h-60 w-1/3 items-center justify-center rounded border p-2"
    >
      <div
        class="absolute inset-0 z-10 flex cursor-pointer items-center justify-center"
      >
        <UIButton
          id="edit-email-template"
          size="small"
          class="z-20 opacity-0 group-hover:opacity-100"
          @click="openEmailTemplateBuilder"
        >
          <Pencil01Icon class="h-6 w-6 text-black" />
          <span class="ml-2 text-black">Edit</span>
        </UIButton>
      </div>
      <div :class="'thumbnail-container ' + (activePreview ? 'blur-sm' : '')">
        <div class="thumbnail">
          <iframe :src="previewUrl" frameborder="0"></iframe>
        </div>
      </div>
    </div>
    <div
      class="align-start flex w-2/3 flex-col justify-start space-y-2 text-left text-xs text-gray-500"
    >
      <div class="py-1">
        <div class="pb-1">Created On</div>
        <div class="text-xs text-gray-900">
          {{ formatDate(createdAt, 'MMMM DD,YYYY hh:mm A') }}
        </div>
      </div>
      <div class="py-1">
        <div class="pb-1">Updated On</div>
        <div class="text-xs text-gray-900">
          {{ formatDate(updatedAt, 'MMMM DD,YYYY hh:mm A') }}
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import AppState, { setClonedEmailTemplateId } from '@/states/app'
import { Pencil01Icon } from '@gohighlevel/ghl-icons/24/outline'
import { UIButton } from '@gohighlevel/ghl-ui'
import moment from 'moment'
import { defineComponent, ref } from 'vue'
export default defineComponent({
  name: 'EditEmailTemplate',
  components: {
    Pencil01Icon,
    UIButton,
  },
  props: {
    templateId: {
      type: String,
      required: true,
    },
    createdAt: {
      type: String,
      default: '',
    },
    updatedAt: {
      type: String,
      default: '',
    },
    previewUrl: {
      type: String,
      default: '',
    },
  },
  emits: ['open'],
  setup(props) {
    const activePreview = ref(false)
    function formatDate(date: any, format = 'YYYY-MM-DD') {
      return moment(date).format(format)
    }
    function openEmailTemplateBuilder() {
      if (AppState.parentApp) {
        AppState.parentApp.then((parent: any) => {
          setClonedEmailTemplateId({ clonedTemplateId: '' })
          parent.emit('edit-email-template', props.templateId)
        })
      }
    }
    return {
      activePreview,
      formatDate,
      openEmailTemplateBuilder,
    }
  },
})
</script>
<style lang="scss" scoped>
.thumbnail-container {
  width: calc(1440px * 0.25);
  height: calc(900px * 0.25);
  display: inline-block;
  overflow: hidden;
  position: relative;

  .thumbnail {
    position: relative;
    transform: scale(0.25);
    transform-origin: 0 0;
    -moz-transform: scale(0.25);
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.25);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.25);
    -webkit-transform-origin: 0 0;

    iframe {
      width: 700px;
      height: 900px;
    }
  }
}
</style>
