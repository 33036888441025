import * as Icons from '@gohighlevel/ghl-icons/24/outline'
import { renderIcon, UIAvatar, useNotification } from '@gohighlevel/ghl-ui'
import { h } from 'vue'
const { ...icons } = Icons

export function useNotify() {
  const notification = useNotification()

  interface NotificationParam {
    title: string
    description?: string
    type?: 'default' | 'error' | 'info' | 'success' | 'warning'
    icon?: string
  }

  const getStyleForType = type => {
    const styles = {
      success: {
        backgroundColor: 'var(--success-100)',
        border: '5px solid var(--success-50)',
      },
      error: {
        backgroundColor: 'var(--error-100)',
        border: '5px solid var(--error-50)',
      },
      default: {
        backgroundColor: 'var(--primary-100)',
        border: '5px solid var(--primary-50)',
      },
    }

    const commonStyles = {
      width: '28px',
      height: '28px',
      padding: '14px',
    }

    return {
      ...commonStyles,
      ...(styles[type] || styles.default),
    }
  }

  const getIconClass = (type: string) => {
    switch (type) {
      case 'success':
        return 'text-success-600'
      case 'error':
        return 'text-error-600'
      default:
        return 'text-primary-600'
    }
  }

  function sendNotification({
    title,
    description,
    type = 'default',
    icon = 'InfoCircleIcon',
  }: NotificationParam) {
    const currentIcon = icons[icon]
    notification.create({
      title: title,
      description: description,
      duration: 5000,
      type: type || 'default',
      avatar: () =>
        h(
          UIAvatar,
          {
            round: true,
            size: 'large',
            style: getStyleForType(type || 'default'),
          },
          () => [
            h(renderIcon(currentIcon), {
              class: `!${getIconClass(type)} w-3 h-3`,
            }),
          ]
        ),
    })
  }
  return {
    sendNotification,
  }
}
