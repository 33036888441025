<script setup lang="ts">
import ClassBookingImg from '@/assets/class-booking.png'
import CollectiveBookingImg from '@/assets/collective-booking.png'
import EventBookingImg from '@/assets/event-booking.png'
import PersonalCalendarImg from '@/assets/personal-calendar.png'
import RoundRobinImg from '@/assets/round-robin.png'
import ServiceBookingImg from '@/assets/service-booking-v2.png'

import ScheduleTypeTile from '@/components/Settings/ScheculeTypeTile.vue'
import { XCloseIcon } from '@gohighlevel/ghl-icons/24/outline'
import { UICollapse, UICollapseItem, UIModal } from '@gohighlevel/ghl-ui'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { CalendarType } from '../../models/calendar'
import { isServiceCalendarEnabled } from '../../states/app'

const { t } = useI18n()

const props = defineProps({
  show: Boolean,
  primaryButtonText: String,
  disabledConfirm: Boolean,
  openUnassignedCalendarModal: Function,
  openTeamCalendarModal: Function,
  openClassBookingCalendarModal: Function,
  openCollectiveBookingCalendarModal: Function,
})

const emit = defineEmits(['onConfirm', 'onModalClose', 'update:show'])

const handleCancel = () => {
  isExpanded.value = false
  emit('onModalClose')
}

const isExpanded = ref(false)

onMounted(() => {
  isExpanded.value = false
})
</script>

<template>
  <UIModal
    id="generalConfirmationModalV2"
    :width="1108"
    :show="show"
    @update:show="val => $emit('update:show', val)"
  >
    <template #header>
      <div class="flex flex-col">
        <div class="mb-5 flex justify-between">
          <div id="text-only-header">
            <div class="text-lg">
              {{ $t('settings.calendar.scheduling_type') }}
            </div>
            <div class="text-sm font-normal text-gray-400">
              {{ $t('settings.calendar.scheduling_type_description') }}
            </div>
          </div>

          <XCloseIcon
            class="h-5 w-5 cursor-pointer text-gray-500"
            @click="handleCancel"
          />
        </div>
        <UIDivider
          title-placement="center"
          :dashed="false"
          :vertical="false"
          class="w-full border-t border-gray-200"
        />
      </div>
    </template>

    <div class="flex w-full flex-col flex-wrap justify-start md:flex-row">
      <ScheduleTypeTile
        id="personal-booking-select"
        :modal-function="openTeamCalendarModal"
        :image-source="PersonalCalendarImg"
        :title="$t('settings.calendar.personal_booking')"
        :description="$t('settings.calendar.personal_booking_description')"
        :calendar-type="CalendarType.PERSONAL"
        :example="$t('settings.calendar.personal_booking_example')"
      />
      <ScheduleTypeTile
        id="round-robin-booking-select"
        :modal-function="openTeamCalendarModal"
        :image-source="RoundRobinImg"
        :title="$t('settings.calendar.round_robin_booking')"
        :description="$t('settings.calendar.round_robin_description')"
        :calendar-type="CalendarType.ROUND_ROBIN"
        :example="$t('settings.calendar.round_robin_example')"
      />

      <ScheduleTypeTile
        id="class-booking-select"
        :modal-function="openClassBookingCalendarModal"
        :image-source="ClassBookingImg"
        :title="$t('settings.calendar.class_booking')"
        :description="$t('settings.calendar.class_booking_description')"
        :example="$t('settings.calendar.class_booking_example')"
      />

      <ScheduleTypeTile
        id="collective-booking-select"
        :modal-function="openCollectiveBookingCalendarModal"
        :image-source="CollectiveBookingImg"
        :title="$t('settings.calendar.collective_booking')"
        :description="$t('settings.calendar.collective_booking_description')"
        :example="$t('settings.calendar.collective_booking_example')"
      />

      <div class="mb-0 mt-3 flex w-full px-2 py-2">
        <div class="w-full">
          <UICollapse
            :accordion="false"
            :arrow-placement="'left'"
            :default-expanded-names="undefined"
            :display-directive="'if'"
            :expanded-names="undefined"
          >
            <UICollapseItem
              id="1"
              :title="$t('settings.calendar.explore_more_types')"
              :name="$t('settings.calendar.explore_more_types')"
            >
              <div class="flex w-full flex-wrap justify-start">
                <ScheduleTypeTile
                  id="event-booking-select"
                  :modal-function="openUnassignedCalendarModal"
                  :image-source="EventBookingImg"
                  :title="$t('settings.calendar.unassigned_booking')"
                  :description="$t('settings.calendar.unassigned_description')"
                  :example="$t('settings.calendar.unassigned_example')"
                />
                <ScheduleTypeTile
                  v-if="isServiceCalendarEnabled()"
                  id="service-booking-select"
                  :modal-function="openTeamCalendarModal"
                  :image-source="ServiceBookingImg"
                  :title="$t('settings.calendar.service_booking')"
                  :description="
                    $t('settings.calendar.service_booking_description')
                  "
                  :calendar-type="CalendarType.SERVICE"
                  :example="$t('settings.calendar.service_booking_example')"
                />
              </div>
            </UICollapseItem>
          </UICollapse>
        </div>
      </div>
    </div>
  </UIModal>
</template>

<style lang="scss">
#generalConfirmationModalV2 {
  .hl-header {
    margin-bottom: 0px !important;
  }
}
</style>
