import AppState from '@/states/app'
import moment from 'moment-timezone'
import { computed } from 'vue'
import { timezones } from './timezones'

export const locationTimezone = computed(() => {
  return AppState?.locationTimezone || 'UTC'
})

export const computedTimezones = computed(() => {
  let timezoneOptions = timezones.map(timezone => {
    return {
      label: timezone.label,
      value: timezone.value,
    }
  })

  const locationTimezoneOption = timezoneOptions.find(
    timezone => timezone.value == locationTimezone.value
  )

  const systemTimezoneOption = timezoneOptions.find(
    timezone => timezone.value == moment.tz.guess()
  )

  timezoneOptions = timezoneOptions.filter(
    timezone =>
      timezone.value != locationTimezoneOption?.value &&
      timezone.value != systemTimezoneOption?.value
  )

  const recommendedOptions = [] as { label: string; value: string }[]

  if (locationTimezoneOption) {
    if (
      recommendedOptions.findIndex(
        x => x.value === locationTimezoneOption?.value
      ) === -1
    ) {
      recommendedOptions.push({
        label: `${locationTimezoneOption?.label}`,
        value: locationTimezoneOption?.value,
      })
    }
  }

  if (systemTimezoneOption) {
    if (
      recommendedOptions.findIndex(
        x => x.value === systemTimezoneOption?.value
      ) === -1
    ) {
      recommendedOptions.push({
        label: `${systemTimezoneOption?.label}`,
        value: systemTimezoneOption?.value,
      })
    }
  }

  return [
    {
      type: 'group',
      label: 'Recommended Timezones',
      key: 'recommended',
      children: recommendedOptions,
    },
    {
      type: 'group',
      label: 'All Timezones',
      key: 'all',
      children: timezoneOptions,
    },
  ]
})
