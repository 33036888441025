import config from '@/config'
import { requests } from './BaseService'

export const CalendarLocationSettingsService = {
  getLocationSettings: (locationId: string) =>
    requests().get(
      `${config.calendarApiUrl}/calendars/location-settings/${locationId}`
    ),
  updateLocationSettings: (locationId: string, data: any) =>
    requests().post(
      `${config.calendarApiUrl}/calendars/location-settings/${locationId}/feature-flags`,

      data
    ),
}
