import { sortBy } from 'lodash'
import moment from 'moment-timezone'
const timezonesWhiteList = [
  'Etc/GMT+12',
  'Pacific/Midway',
  'Pacific/Honolulu',
  'America/Juneau',
  'US/Alaska',
  'America/Dawson',
  'America/Los_Angeles',
  'America/Phoenix',
  'America/Tijuana',
  'US/Arizona',
  'America/Belize',
  'America/Boise',
  'America/Chihuahua',
  'America/Denver',
  'America/Edmonton',
  'America/Guatemala',
  'America/Managua',
  'America/Regina',
  'Canada/Saskatchewan',
  'US/Mountain',
  'America/Bahia_Banderas',
  'America/Bogota',
  'America/Cancun',
  'America/Chicago',
  'America/Mexico_City',
  'US/Central',
  'America/Caracas',
  'America/Detroit',
  'America/Indiana/Indianapolis',
  'America/Louisville',
  'America/Manaus',
  'America/New_York',
  'America/Santiago',
  'America/Santo_Domingo',
  'America/Toronto',
  'US/East-Indiana',
  'US/Eastern',
  'America/Argentina/Buenos_Aires',
  'America/Glace_Bay',
  'America/Montevideo',
  'America/Sao_Paulo',
  'Canada/Atlantic',
  'America/St_Johns',
  'Canada/Newfoundland',
  'America/Godthab',
  'America/Noronha',
  'Etc/GMT+2',
  'Atlantic/Cape_Verde',
  'Atlantic/Azores',
  'UTC',
  'Africa/Algiers',
  'Africa/Casablanca',
  'Africa/Lagos',
  'Atlantic/Canary',
  'Europe/London',
  'Africa/Cairo',
  'Africa/Harare',
  'Europe/Amsterdam',
  'Europe/Belgrade',
  'Europe/Brussels',
  'Europe/Madrid',
  'Europe/Oslo',
  'Europe/Sarajevo',
  'Africa/Nairobi',
  'Asia/Amman',
  'Asia/Baghdad',
  'Asia/Jerusalem',
  'Asia/Kuwait',
  'Asia/Qatar',
  'Europe/Athens',
  'Europe/Bucharest',
  'Europe/Helsinki',
  'Europe/Moscow',
  'Asia/Baku',
  'Asia/Dubai',
  'Asia/Kabul',
  'Asia/Tehran',
  'Asia/Karachi',
  'Asia/Yekaterinburg',
  'Asia/Colombo',
  'Asia/Kolkata',
  'Asia/Calcutta',
  'Asia/Kathmandu',
  'Asia/Almaty',
  'Asia/Dhaka',
  'Asia/Rangoon',
  'Asia/Bangkok',
  'Asia/Krasnoyarsk',
  'Asia/Irkutsk',
  'Asia/Kuala_Lumpur',
  'Asia/Shanghai',
  'Asia/Taipei',
  'Australia/Perth',
  'Asia/Seoul',
  'Asia/Tokyo',
  'Asia/Yakutsk',
  'Australia/Adelaide',
  'Australia/Darwin',
  'Asia/Vladivostok',
  'Australia/Brisbane',
  'Australia/Canberra',
  'Australia/Hobart',
  'Australia/Sydney',
  'Pacific/Guam',
  'Asia/Magadan',
  'Pacific/Auckland',
  'Pacific/Fiji',
  'Pacific/Tongatapu',
]

const timezones = sortBy(
  moment.tz
    .names()
    .filter(tz => {
      // tz === this.timezone
      return timezonesWhiteList.includes(tz)
    })
    .map(zone => {
      const now = moment.tz(zone)
      // const now = moment(new Date('11-08-2022 23:45')).tz(zone)
      // console.log('timezones ', now)
      return {
        label: `GMT${now.format('Z')} ${zone} (${now.format('z')})`,
        value: zone,
        currentTime: now.format('hh:mm A'),
      }
    }),
  m => {
    return moment.tz(m.value).utcOffset()
  }
)

const getTimezones = (date: string) => {
  return sortBy(
    moment.tz
      .names()
      .filter(tz => {
        // tz === this.timezone
        return timezonesWhiteList.includes(tz)
      })
      .map(zone => {
        const now = date ? moment(new Date(date)).tz(zone) : moment.tz(zone)
        // console.log('timezones ', now)
        return {
          label: `GMT${now.format('Z')} ${zone} (${now.format('z')})`,
          value: zone,
          currentTime: now.format('hh:mm A'),
        }
      }),
    m => {
      return moment.tz(m.value).utcOffset()
    }
  )
}

const getGMTOffset = (timezone: string) => {
  if (!timezone) {
    return ''
  }
  const offset = moment.tz(timezone).utcOffset()
  const hours = Math.floor(Math.abs(offset) / 60)
  const minutes = Math.abs(offset) % 60
  const sign = offset < 0 ? '-' : '+'
  return `${sign}${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''
    }${minutes}`.replace(/ /g, '')
}

const getLocationTzTimezone = (tz: string, value?: number) => {
  const timezoneDifference =
    moment.tz(moment.tz.guess()).utcOffset() - moment.tz(tz).utcOffset()

  return moment(value).subtract(timezoneDifference, 'minutes')
}

export { getGMTOffset, getLocationTzTimezone, getTimezones, timezones }

