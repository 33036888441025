<script setup lang="ts">
import AppState, {
  getEquipmentsInLocation,
  getTargetTimezone,
} from '@/states/app'
import {
  CalendarCheck01Icon,
  Pencil02Icon,
  PlusIcon,
  Toggle03LeftIcon,
  Toggle03RightIcon,
  Trash01Icon
} from '@gohighlevel/ghl-icons/24/outline'
import {
  UIActionDropdown,
  UIEmpty,
  UITable,
  UITag,
  renderIcon,
  useNotification,
} from '@gohighlevel/ghl-ui'

import { h, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { ResourceList } from '../../class/pages/ResourceList'
import { CalendarResourceService } from '../../class/services/CalendarResourceService'
import {
  CALENDAR_RESOURCE_TYPES,
  CalendarResource,
} from '../../models/calendar_resource'
import CreateEquipmentModal from './CreateEquipmentModal.vue'

import DeleteConfirmation from '@/components/common/DeleteConfirmation.vue'
import GeneralConfirmation from '@/components/common/GeneralConfirmation.vue'
import moment from 'moment-timezone'

const { t } = useI18n()

const tableActionsOptionsForActiveEquipments = [
  {
    label: t('settings.calendar.edit'),
    key: 'edit',
    icon: renderIcon(Pencil02Icon),
  },
  {
    label: t('settings.calendar.equipments_disable'),
    key: 'disable',
    icon: renderIcon(Toggle03RightIcon),
  },
  {
    label: t('settings.calendar.delete_group'),
    key: 'delete_equipment',
    icon: renderIcon(Trash01Icon),
  },
]

const tableActionsOptionsForDisabledEquipments = [...tableActionsOptionsForActiveEquipments]
tableActionsOptionsForDisabledEquipments[1] = {
  label: t('settings.calendar.equipments_activate'),
  key: 'disable',
  icon: renderIcon(Toggle03LeftIcon),
}

const targetTimezone = getTargetTimezone()

const columns = [
  {
    title: t('settings.calendar.equipment_name'),
    key: 'name',
  },
  {
    title: t('settings.calendar.equipment_quantity'),
    key: 'quantity',
  },
  {
    title: t('settings.calendar.calendar_list'),
    key: 'calendars_tags',
    render(row) {
      const tags: any = []
      const calendarIds = row.calendarIds
      if (!calendarIds?.length) {
        return
      }
      const activeCalendars = calendarIds.filter(e => !equipmentList.isCalendarDeleted(e))
      for (let i = 0; i < 3; i++) {
        if (activeCalendars[i]) {
          tags.push(
            h(
              UITag,
              {
                type: 'info',
                id: activeCalendars[i],
              },
              equipmentList.getCalName(activeCalendars[i])
            )
          )
        }
      }
      if (activeCalendars.length > 3) {
        tags.push(
          h(
            UITag,
            {
              id: `count`,
            },
            `+${activeCalendars.length - 3}`
          )
        )
      }
      return tags
    }
  },
  {
    title: t('settings.calendar.status'),
    key: 'isActive',
    render(equipment) {
      return h(
        'span',
        {
          class: 'inline-flex rounded-full px-2 text-xs leading-5',
        },
        equipment.isActive
          ? t('settings.calendar.active')
          : t('settings.calendar.draft')
      )
    }
  },
  {
    title: t('settings.calendar.date_updated'),
    key: 'dateUpdated',
    render(equipment) {
      return h('div', [
        h(
          'h5',
          {
            class: 'm-0 text-sm font-normal	',
          },
          moment(equipment.updatedAt).tz(targetTimezone).format('MMM DD YYYY')
        ),
        h(
          'h6',
          {
            class: 'm-0 text-xs font-normal text-gray-300',
          },
          moment(equipment.updatedAt).tz(targetTimezone).format('hh mm A')
        ),
      ])
    },
  },
  {
    title: t('settings.calendar.action_dropdown'),
    key: 'actions',
    render(row) {
      return h(
        UIActionDropdown,
        {
          options: row.isActive
            ? tableActionsOptionsForActiveEquipments.filter(
              x => x.key !== 'rearrange_calendars' || row.nosOfCalendars > 0
            )
            : tableActionsOptionsForDisabledEquipments.filter(
              x => x.key !== 'rearrange_calendars' || row.nosOfCalendars > 0
            ),
          onSelect: val => dropDownActionChange(val, row),
          id: 'equipment-' + row._id,
        },
        {}
      )
    },
  },
]

const selectedEquipment = ref()
const isProcessing = ref(false)
const showDeleteConfirmationModal = ref(false)
const showNewEquipmentModal = ref(false)
const notification = useNotification()
const equipmentList = new ResourceList(CALENDAR_RESOURCE_TYPES.EQUIPMENTS)
const showDisableConfirmationModal = ref(false)
const equipmentEditPayload = ref()

const dropDownActionChange = (action, equipment) => {
  selectedEquipment.value = equipment
  if (action === 'edit') {
    openEquipmentModal(equipment)
  } else if (action === 'disable') {
    showDisableConfirmationModal.value = true
  } else if (action === 'delete_equipment') {
    showDeleteConfirmationModal.value = true
  }
}

const closeModal = () => {
  showDisableConfirmationModal.value = false
  showNewEquipmentModal.value = false
  showDeleteConfirmationModal.value = false
  isProcessing.value = false
}

const openEquipmentModal = (equipment: CalendarResource) => {
  showNewEquipmentModal.value = true
  equipmentEditPayload.value = equipment
}

const showInfoNotification = (message: string) => {
  notification.info({
    content: message,
    duration: 1 * 1000,
  })
}

// API calls start
const runPostApiCallsCleanUps = async () => {
  closeModal()
  await getEquipmentsInLocation()
}

const deleteEquipment = async () => {
  if (!selectedEquipment.value) {
    return
  }
  isProcessing.value = true
  await CalendarResourceService.delete(
    selectedEquipment.value._id,
    CALENDAR_RESOURCE_TYPES.EQUIPMENTS
  )
  showInfoNotification('Equipment deleted')
  await runPostApiCallsCleanUps()
}

const disableEquipment = async () => {
  if (!selectedEquipment.value) {
    return
  }
  isProcessing.value = true
  await CalendarResourceService.update(
    selectedEquipment.value._id,
    { isActive: !selectedEquipment.value.isActive },
    CALENDAR_RESOURCE_TYPES.EQUIPMENTS
  )
  showInfoNotification(
    `Equipment ${selectedEquipment.value.isActive ? 'Disabled' : 'Activated'}`
  )
  await runPostApiCallsCleanUps()
}
// API calls end
</script>
<template>
  <CreateEquipmentModal :show="showNewEquipmentModal" :edit-payload="equipmentEditPayload"
    @createdRecord="runPostApiCallsCleanUps" @closeModal="closeModal" />
  <UITable id="equipment-list-table" v-model:searchText="equipmentList.filters.value.searchText"
    :search-input-placeholder="$t('settings.calendar.equipment_name_search')" :columns="columns"
    :data="equipmentList.resourceList" :page-count="equipmentList.totalPages"
    :loading="AppState.isLoading || AppState.loadingAssets.equipments" :table-layout="'fixed'"
    @update:page="x => (equipmentList.filters.value.page = x)">
    <template #empty>
      <UIEmpty v-if="equipmentList.allResources.length === 0" id="empty-equipments-title"
        :title="$t('settings.calendar.no_equipments_in_location_title')"
        :description="$t('settings.calendar.no_equipments_in_location')"
        :positive-text="$t('settings.calendar.add_new_equipment')" :icon="CalendarCheck01Icon" :positive-icon="PlusIcon"
        @positiveClick="openEquipmentModal">
      </UIEmpty>

      <UIEmpty v-else id="empty-equipments-description" :title="$t('settings.calendar.no_equipments_in_location_title')"
        :description="$t('settings.calendar.no_equipments_found')">
      </UIEmpty>
    </template>
  </UITable>
  <DeleteConfirmation v-model:show="showDeleteConfirmationModal" :disabled-confirm="isProcessing"
    :header-title="$t('settings.calendar.delete_this_equipment')" @onConfirm="deleteEquipment" @onModalClose="closeModal">
    <template #message>
      <p class="text-sm leading-5 text-gray-500">
        {{ $t('settings.calendar.delete_equipment_confirmation') }}
      </p>
    </template>
  </DeleteConfirmation>

  <GeneralConfirmation v-model:show="showDisableConfirmationModal" :disabled-confirm="isProcessing" :header-title="$t('settings.calendar.disable_equipment_title', {
    status: selectedEquipment?.isActive ? 'Disable' : 'Activate'
  })" @onConfirm="disableEquipment" @onModalClose="closeModal">
    <template #message>
      <p class="text-sm leading-5 text-gray-500">
        {{
          t('settings.calendar.equipments_status_message', {
            status: selectedEquipment?.isActive ? 'Disable' : 'Activate',
          })
        }}
      </p>
      <p class="text-sm leading-5 text-gray-500">
        {{ t('settings.calendar.calendar_resource_disclaimer') }}
      </p>
      <br>
      <p v-if="selectedEquipment?.isActive" class="text-sm leading-5 text-gray-500">
        {{ t('settings.calendar.equipment_disclaimer') }}
      </p>
    </template>
  </GeneralConfirmation>
</template>
