<template>
  <div>
    <UIFormItem label="Scheduling Link" :show-feedback="false">
      <UIInput
        id="scheduling-link-input"
        v-model="scheduleLink"
        class="mr-2 !w-9/12"
        :readonly="true"
        size="medium"
        :disabled="true"
      />
      <UIButton
        id="copySchedulingLinkButton"
        type="primary"
        size="small"
        class="!w-3/12 bg-gray-300"
        @click="methods.copySchedulingLink"
        ><Copy01Icon class="mr-2 h-4 w-4" />Copy</UIButton
      >
    </UIFormItem>
    <div class="py-4 text-sm text-gray-500">
      The scheduling link is determined by the slug. Adjust the slug, and the
      scheduling link automatically adapts to the modification.
    </div>
  </div>
  <div class="mt-6 pt-4">
    <UIFormItem label="Permanent Link" :show-feedback="false">
      <UIInput
        id="scheduling-link-input"
        v-model="permanentLink"
        class="mr-2 !w-9/12"
        :readonly="true"
        size="medium"
        :disabled="true"
      />
      <UIButton
        id="copyPermanentLinkButton"
        type="primary"
        size="small"
        class="!w-3/12"
        @click="methods.copyPermanentLink"
        ><Copy01Icon class="mr-2 h-4 w-4" />Copy</UIButton
      >
    </UIFormItem>
    <div class="py-4 text-sm text-gray-500">
      Ideal for funnels, website redirects, or ads, the permanent link remains
      constant, unaffected by slug changes.
    </div>
  </div>
</template>

<script setup lang="ts">
import { Prop, computed } from 'vue'
import {
  UIInput,
  UIFormItem,
  UIButton,
  useNotification,
} from '@gohighlevel/ghl-ui'
import { Copy01Icon } from '@gohighlevel/ghl-icons/24/outline'
import Calendar, { CalendarType } from '../../../models/calendar'
import AppState from '../../../states/app'
import { copyToClipboard } from '../../../utils/generalFunctions'
const notification = useNotification()

const props = defineProps({
  calendar: {} as Prop<Calendar>,
})
const scheduleLink = computed(() => {
  return `${AppState?.baseUrl}/widget/bookings/${props.calendar?.widgetSlug}`
})
const permanentLink = computed(() => {
  return `${AppState?.baseUrl}/widget/booking/${props.calendar?.id}`
})
const methods = {
  async copySchedulingLink() {
    copyToClipboard(scheduleLink.value)
    notification.info({
      content: 'Copied to clipboard',
      duration: 1 * 1000,
    })
  },
  async copyPermanentLink() {
    copyToClipboard(permanentLink.value)
    notification.info({
      content: 'Copied to clipboard',
      duration: 1 * 1000,
    })
  },
}
</script>
