<!--  Added a hack where we are replacing white-space:pre with white-space:pre-wrap when user
   Copies content from somewhere and has this property
   Ticket to refer: https://app.clickup.com/t/86cveyf81 -->
<template>
  <UITooltip>
    <template #trigger>
      <div
        class="flex cursor-pointer items-center text-center"
        @click="handleClick"
      >
        <ParagraphWrapIcon class="h-4 w-4" />
      </div>
    </template>
    Text wrap
  </UITooltip>
</template>

<script lang="ts">
import { ParagraphWrapIcon } from '@gohighlevel/ghl-icons/24/outline'
import { UITooltip } from '@gohighlevel/ghl-ui'
import { Editor } from '@tiptap/vue-3'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'RTEControlTextWrap',
  components: {
    ParagraphWrapIcon,
    UITooltip,
  },
  props: {
    editor: {
      required: true,
      type: Editor,
    },
  },
  setup(props) {
    const handleClick = () => {
      const currentText = props.editor.getHTML()
      const textWrappedText = currentText.replace(
        /white-space:pre/g,
        'white-space:pre-wrap'
      )
      props.editor.chain().focus().setContent(textWrappedText, true).run()
    }

    return {
      handleClick,
    }
  },
})
</script>
