<template>
  <!-- calendar notification list -->
  <div id="calendar-notification-list-id">
    <div
      v-for="(notification, index) in notificationData"
      :id="'event-notification-' + index"
      :key="'event-notification-' + index"
      :class="{ 'mt-4': index !== 0 }"
    >
      <div class="flex items-start gap-4 rounded-lg border px-4 py-2">
        <!-- Notification Icon -->
        <div class="mt-1">
          <Bell01Icon class="h-5 w-5 text-gray-500" />
        </div>

        <!-- Notification Content -->
        <div class="flex-1">
          <div class="flex items-start justify-between">
            <!-- Title, Description and Chips -->
            <div>
              <div class="mb-1 flex items-center gap-3">
                <h3 class="font-medium text-gray-800">
                  {{ notification.title }}
                </h3>
                <div class="flex gap-2">
                  <UITag
                    v-for="channel in notification.channels"
                    :key="channel.name"
                    :type="channel.isActive ? 'success' : 'default'"
                    :closable="false"
                    size="medium"
                    round="true"
                    :bordered="true"
                  >
                    {{ $t(`eventNotification.${channel.name}.channelName`) }}
                  </UITag>
                </div>
              </div>
              <p class="text-sm text-gray-500">
                {{ notification.subTitle }}
              </p>
            </div>

            <!-- Edit Button -->
            <UIButton
              :id="`${notification.type}-edit-btn`"
              type="default"
              size="medium"
              :ghost="false"
              :quaternary="true"
              :circle="true"
              :text="false"
              class="p-2"
              @click="editEmailTemplate(notification)"
            >
              <Pencil02Icon class="h-4 w-4 text-gray-600" />
            </UIButton>
          </div>
        </div>
      </div>
    </div>
    <div class="my-4 flex items-center space-x-2">
      <span> {{ t('eventNotification.statusLabel') }}:</span>
      <UITag
        key="Enabled"
        type="success"
        :closable="false"
        size="small"
        round="true"
        :bordered="false"
        >{{ t('eventNotification.enabled') }}</UITag
      ><UITag
        key="Disabled"
        :type="'default'"
        :closable="false"
        size="small"
        round="true"
        :bordered="false"
        >{{ t('eventNotification.disabled') }}</UITag
      >
    </div>
    <!-- modal -->
    <EditEmailNotificationModal
      v-if="state.showEmailTemplate"
      :show-modal="state.showEmailTemplate"
      :close-modal="closeModal"
      :notification="state.templateData"
      :calendar-id="props.calendarId"
      @update-notification="updateNotification"
    />
  </div>
</template>

<script setup lang="ts">
import { EmailNotification } from '@/types/notification'
import { Bell01Icon, Pencil02Icon } from '@gohighlevel/ghl-icons/24/outline'
import { UIButton, UITag } from '@gohighlevel/ghl-ui'
import { computed, defineEmits, defineProps, reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import EditEmailNotificationModal from './notifications/EditEmailNotificationModal.vue'
const { t } = useI18n()

const props = defineProps({
  calendarId: {
    type: String,
    required: true,
  },
  notifications: {
    type: Array<EmailNotification>,
    required: true,
  },
})

const state = reactive({
  showEmailTemplate: false,
  templateData: {},
})

const emits = defineEmits(['toggleNotification'])

const notificationTypes = {
  booked: {
    index: 0,
    title: t('eventNotification.list.booked.title'),
    subTitle: t('eventNotification.list.booked.subTitle'),
  },
  confirmation: {
    index: 1,
    title: t('eventNotification.list.confirmation.title'),
    subTitle: t('eventNotification.list.confirmation.subTitle'),
  },
  cancellation: {
    index: 2,
    title: t('eventNotification.list.cancellation.title'),
    subTitle: t('eventNotification.list.cancellation.subTitle'),
  },
  reschedule: {
    index: 3,
    title: t('eventNotification.list.reschedule.title'),
    subTitle: t('eventNotification.list.reschedule.subTitle'),
  },
  reminder: {
    index: 4,
    title: t('eventNotification.list.reminder.title'),
    subTitle: t('eventNotification.list.reminder.subTitle'),
  },
  followup: {
    index: 5,
    title: t('eventNotification.list.followup.title'),
    subTitle: t('eventNotification.list.followup.subTitle'),
  },
}

const notificationData = computed(() => {
  return props.notifications
    .map(notification => {
      return {
        type: notification.type,
        title: notificationTypes[notification.type]?.title,
        subTitle: notificationTypes[notification.type]?.subTitle,
        id: notificationTypes[notification.type]?.index,
        channels: notification.channels,
      }
    })
    .sort((a, b) => {
      if (a.id < b.id) {
        return -1
      }
      if (a.id > b.id) {
        return 1
      }
      return 0
    })
})

function editEmailTemplate(notification: EmailNotification) {
  state.showEmailTemplate = true
  state.templateData = {
    calendarId: props.calendarId,
    type: notification.type,
  }
}
function closeModal() {
  state.showEmailTemplate = false
  state.templateData = {}
}

function updateNotification(value, showNotification = false) {
  emits('toggleNotification', value, showNotification)
}
</script>
