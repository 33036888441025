import Calendar, { CalendarType } from '@/models/calendar'
import { drop } from 'lodash'
import { ref } from 'vue'
import AppState from './../../states/app'

export class CalendarList {
  filters = ref({
    searchText: '',
    page: 1,
    group: '',
    calendar_type: '',
    status: 'all',
  })

  limit = 10

  constructor() {
    //
  }

  get offet() {
    return (this.filters?.value.page - 1) * this.limit
  }

  get calendarList(): Calendar[] {
    return drop(this.filterdCalendars, this.offet)
      .slice(0, this.limit)
      .map(calendar => {
        calendar.groupName = AppState.globalTeams?.teams?.find(
          team => team.id === calendar.providerId
        )?.name

        calendar.isActive = calendar.isActive === false ? false : true

        return calendar
      })
  }

  get filterdCalendars() {
    return this.allCalendars.filter(calendar => {
      return (
        (this.filters.value.searchText === '' ||
          calendar.name
            ?.toLowerCase()
            .includes(this.filters.value.searchText.toLowerCase())) &&
        (this.filters.value.group === '' ||
          calendar.providerId === this.filters.value.group) &&
        (this.filters.value.status === 'all' ||
          (this.filters.value.status === 'active' && calendar.isActive) ||
          (this.filters.value.status === 'draft' && !calendar.isActive)) &&
        (this.filters.value.calendar_type === '' ||
          (this.filters.value.calendar_type === CalendarType.EVENT &&
            calendar.calendarType === CalendarType.EVENT) ||
          (this.filters.value.calendar_type === CalendarType.ROUND_ROBIN &&
            calendar.calendarType === CalendarType.ROUND_ROBIN) ||
          (this.filters.value.calendar_type === CalendarType.COLLECTIVE &&
            calendar.calendarType === CalendarType.COLLECTIVE) ||
          (this.filters.value.calendar_type === CalendarType.CLASS_BOOKING &&
            calendar.calendarType === CalendarType.CLASS_BOOKING) ||
          (this.filters.value.calendar_type === CalendarType.SERVICE &&
            calendar.calendarType === CalendarType.SERVICE) ||
          (this.filters.value.calendar_type === CalendarType.PERSONAL &&
            calendar.calendarType === CalendarType.PERSONAL))
      )
    })
  }

  get allCalendars(): Calendar[] {
    const currentLocationId = AppState.locationId
    const _calendars = AppState.globalCalendars?.calendars || []
    const user = AppState.loggedInUserDetail
    const assigned_data_only = user?.permissions?.assignedDataOnly
    if (assigned_data_only) {
      return _calendars.filter(
        c =>
          user.userCalendar[currentLocationId] === c.id ||
          (c.teamMembers &&
            c.teamMembers.find(x => x.selected && x.userId === user?.id))
      )
    }
    return _calendars
  }

  get nosTotalPages() {
    return Math.ceil(this.filterdCalendars.length / this.limit)
  }
}
