<script setup lang="ts">
import AppState, {
  getTeamsInLocation,
  setEditCalendarValidationData,
  setIsAdvancedCalendarPageLoading,
} from '@/states/app'
import {
  Copy06Icon,
  InfoCircleIcon,
  PlusIcon,
  Trash02Icon,
  XIcon,
} from '@gohighlevel/ghl-icons/24/outline'
import {
  UIButton,
  UICheckbox,
  UIDivider,
  UIForm,
  UIFormItem,
  UIHeader,
  UIInputNumber,
  UIPopover,
  UISelect,
  UISwitch,
  UITimePicker,
  UITooltip,
} from '@gohighlevel/ghl-ui'
import moment from 'moment'
import { computed, reactive, ref, watch, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import { POSITION, TYPE, useToast } from 'vue-toastification'
import { MyAvailability } from '../../class/pages/MyAvailability'
import { CalendarService } from '../../class/services/CalendarService'
import { CalendarType } from '../../models/calendar'
import { getTimeSpanOptions, getTooltip } from '../../utils/generalFunctions'
import CustomAvailabilityModal from '../Settings/CustomAvailabilityModal.vue'
import CustomRecurrenceModal from './CustomRecurrenceModal.vue'
import DurationPicker from './DurationPicker.vue'

const myAvailability = ref(new MyAvailability(''))
const customAvailabilityPicker = ref()
const formRef = ref()
const { t } = useI18n()
const daysOfWeekInOrder = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]
const toast = useToast()

const recurringFrequencyOptions = [
  {
    value: 'DAILY',
    label: t('calendar_advanced.availability.daily'),
  },
  {
    value: 'WEEKLY',
    label: t('calendar_advanced.availability.weekly'),
  },
  {
    value: 'MONTHLY',
    label: t('calendar_advanced.availability.monthly'),
  },
  {
    value: 'CUSTOM',
    label: t('calendar_advanced.availability.custom'),
  },
]

const recurringBookingOptions = [
  {
    value: 'skip',
    label: t('calendar_advanced.availability.skip_booking'),
  },
  {
    value: 'continue',
    label: t('calendar_advanced.availability.continue_booking'),
  },
  {
    value: 'book_next',
    label: t('calendar_advanced.availability.book_next_available'),
  },
]

const recurringOverlapStatusOptions = [
  {
    value: 'confirmed',
    label: t('calendar_advanced.availability.confirmed'),
  },
  {
    value: 'new',
    label: t('calendar_advanced.availability.unconfirmed'),
  },
]

const state = reactive({
  isLoading: false,
  // isStandardAvailability: true,
  isStandardAvailability: false,
  oldFreqValue: '',
  isCustomRecurring: false,
  rruleText: '',
  formData: {
    openHours: [],
    enableRecurring: false,
    recurring: {
      count: 1,
      bookingOption: 'skip',
      bookingOverlapDefaultStatus: '',
      interval: null as null | number,
      freq: 'DAILY',
      monthDays: [],
      weekDays: [],
    },
    slotDuration: '30',
    slotDurationUnit: 'mins',
    slotInterval: '30',
    slotIntervalUnit: 'mins',
    slotBuffer: '',
    slotBufferUnit: 'mins',
    allowBookingFor: '',
    allowBookingForUnit: 'days',
    allowBookingAfter: '',
    allowBookingAfterUnit: 'days',
    appoinmentPerSlot: 1 as number | undefined,
    appoinmentPerDay: 1 as number | undefined,
    preBuffer: '',
    preBufferUnit: 'mins',
    lookBusyConfig: {
      enabled: false,
      lookBusyPercentage: 0,
    },
  },
  showCustomRecurrenceModal: false,
})
const editingCalendar = computed(() => {
  return AppState.editingCalendar
})

const copyToDays = ref<string[]>([])
const activeHourIndexForCopy = ref(-1)

const emit = defineEmits(['onSave', 'onCancel'])

const methods = {
  async loadData() {
    setIsAdvancedCalendarPageLoading(true)

    myAvailability.value.setOpenHoursDataFromDatabase(
      editingCalendar.value?.openHours &&
        Array.isArray(editingCalendar?.value?.openHours)
        ? editingCalendar.value?.openHours // {}
        : []
    )

    state.isStandardAvailability = editingCalendar.value?.availabilityType !== 1

    state.formData.recurring = editingCalendar.value?.recurring || {
      count: 1,
      bookingOption: 'skip',
      bookingOverlapDefaultStatus: '',
      interval: null as null | number,
      freq: 'DAILY',
      monthDays: [],
      weekDays: [],
    }

    state.formData.recurring.bookingOption =
      editingCalendar.value?.recurring?.bookingOption || 'skip'

    state.formData.recurring.freq =
      editingCalendar.value?.recurring?.freq || 'DAILY'

    state.formData.recurring.count =
      editingCalendar.value?.recurring?.count || 1

    if (
      state.formData.recurring?.monthDays?.length > 0 ||
      state.formData.recurring?.weekDays?.length > 0 ||
      state.formData.recurring?.interval
    ) {
      state.isCustomRecurring = true
    }

    state.formData.enableRecurring = editingCalendar.value
      ?.enableRecurring as boolean

    state.formData.slotDuration =
      editingCalendar.value?.slotDurationUnit === 'hours'
        ? String(editingCalendar.value?.slotDuration / 60 || '')
        : String(editingCalendar.value?.slotDuration || '')
    state.formData.slotDurationUnit =
      editingCalendar.value?.slotDurationUnit || 'mins'
    state.formData.slotInterval =
      editingCalendar.value?.slotIntervalUnit === 'hours'
        ? String(editingCalendar.value?.slotInterval / 60 || '')
        : String(editingCalendar.value?.slotInterval || '')
    state.formData.slotIntervalUnit =
      editingCalendar.value?.slotIntervalUnit || 'mins'
    state.formData.slotBuffer =
      editingCalendar.value?.slotBufferUnit === 'hours'
        ? String(editingCalendar.value?.slotBuffer / 60 || '')
        : String(editingCalendar.value?.slotBuffer || '')
    state.formData.slotBufferUnit =
      editingCalendar.value?.slotBufferUnit || 'mins'
    state.formData.preBuffer =
      editingCalendar.value?.preBufferUnit === 'hours'
        ? String(editingCalendar.value?.preBuffer / 60 || '')
        : String(editingCalendar.value?.preBuffer || '')
    state.formData.preBufferUnit =
      editingCalendar.value?.preBufferUnit || 'mins'
    state.formData.allowBookingFor = String(
      editingCalendar.value?.allowBookingFor || ''
    )
    state.formData.allowBookingForUnit =
      editingCalendar.value?.allowBookingForUnit || 'days'
    state.formData.allowBookingAfter = String(
      editingCalendar.value?.allowBookingAfter || ''
    )
    state.formData.allowBookingAfterUnit =
      editingCalendar.value?.allowBookingAfterUnit || 'days'
    state.formData.appoinmentPerSlot = editingCalendar.value?.appoinmentPerSlot
    state.formData.appoinmentPerDay = editingCalendar.value?.appoinmentPerDay

    state.formData.lookBusyConfig = editingCalendar.value?.lookBusyConfig || {
      enabled: false,
      lookBusyPercentage: 0,
    }

    await Promise.all([methods.loadGroups()])

    setIsAdvancedCalendarPageLoading(false)
  },
  async loadGroups() {
    await getTeamsInLocation()
  },
  async emitSave() {
    if (state.isStandardAvailability) {
      const hasDuplicateSlot = myAvailability.value.hasDuplicateSlot
      let has24hourError = false
      myAvailability.value.availableDays.forEach(day => {
        if (day.hours.length > 1) {
          if (
            day.hours.some(
              hour => hour.startTime === '0:0' && hour.endTime === '0:0'
            )
          ) {
            has24hourError = true
          }
        }
      })
      if (hasDuplicateSlot || has24hourError) {
        toast(t('calendar_advanced.availability.enter_valid_hours'), {
          type: TYPE.ERROR,
          position: POSITION.BOTTOM_RIGHT,
        })
        return null
      }
    }
    if (customAvailabilityPicker.value?.methods.hasErrors()) {
      toast(t('calendar_advanced.availability.enter_valid_avail'), {
        type: TYPE.ERROR,
        position: POSITION.BOTTOM_RIGHT,
      })
      return null
    }

    // if (
    //   !hideAvailabilityType.value &&
    //   existInvalidTimes(myAvailability.value.availableDays)
    // ) {
    //   toast(t('calendar_advanced.availability.enter_valid_hours'), {
    //     type: TYPE.ERROR,
    //     position: POSITION.BOTTOM_RIGHT,
    //   })
    //   return null
    // }

    try {
      await formRef.value.getForm().validate()
    } catch (error) {
      return
    }
    if (
      AppState.errorInDurationPickerMap.slotDuration ||
      AppState.errorInDurationPickerMap.slotInterval ||
      AppState.errorInDurationPickerMap.slotBuffer ||
      AppState.errorInDurationPickerMap.preBuffer
    ) {
      return null
    }

    const data = {
      openHours: myAvailability.value.availableDays
        ?.filter(i => i?.hours?.length)
        .map(day => day.serializeAsPerAPIFormat()),
      availabilities:
        customAvailabilityPicker.value?.methods.getAvailability() || [],
      // availabilityType: 1,
      // availabilityType: 0,
      recurring: state.formData.recurring,
      enableRecurring: state.formData.enableRecurring,
      slotDuration:
        state.formData.slotDurationUnit === 'mins'
          ? parseInt(state.formData.slotDuration)
          : parseInt(state.formData.slotDuration) * 60,
      slotDurationUnit: state.formData.slotDurationUnit,
      slotInterval:
        state.formData.slotIntervalUnit === 'mins'
          ? parseInt(state.formData.slotInterval)
          : parseInt(state.formData.slotInterval) * 60,
      slotIntervalUnit: state.formData.slotIntervalUnit,
      slotBuffer:
        state.formData.slotBufferUnit === 'mins'
          ? parseInt(state.formData.slotBuffer)
          : parseInt(state.formData.slotBuffer) * 60,
      slotBufferUnit: state.formData.slotBufferUnit,
      preBuffer:
        state.formData.preBufferUnit === 'mins'
          ? parseInt(state.formData.preBuffer)
          : parseInt(state.formData.preBuffer) * 60,
      preBufferUnit: state.formData.preBufferUnit,
      allowBookingFor: parseInt(state.formData.allowBookingFor),
      allowBookingForUnit: state.formData.allowBookingForUnit,
      allowBookingAfter: parseInt(state.formData.allowBookingAfter),
      allowBookingAfterUnit: state.formData.allowBookingAfterUnit,
      appoinmentPerSlot: state.formData.appoinmentPerSlot
        ? Number(state.formData.appoinmentPerSlot)
        : '',
      appoinmentPerDay: state.formData.appoinmentPerDay
        ? Number(state.formData.appoinmentPerDay)
        : '',
      lookBusyConfig: state.formData.lookBusyConfig,
    }
    return data
  },
  updateFrequency(value: string) {
    if (value === 'CUSTOM') {
      state.oldFreqValue = state.formData.recurring.freq
      state.showCustomRecurrenceModal = true
    } else {
      state.isCustomRecurring = false
      state.formData.recurring.monthDays = []
      state.formData.recurring.weekDays = []
      state.formData.recurring.interval = null
      state.formData.recurring.freq = value
    }
  },
  customRecurrenceModalSave(data: any) {
    state.isCustomRecurring = true
    state.formData.recurring = {
      ...state.formData.recurring,
      ...data,
    }
    state.showCustomRecurrenceModal = false
  },
  customRecurrenceModalCancel() {
    state.showCustomRecurrenceModal = false
    state.formData.recurring.freq = state.oldFreqValue
  },
}

defineExpose({
  methods,
})
watchEffect(async () => {
  const { data } = await CalendarService.generateRRuleText({
    freq: state.formData.recurring.freq,
    interval: state.formData.recurring.interval,
    count: state.formData.recurring.count,
    weekDays: state.formData.recurring.weekDays,
    monthDays: state.formData.recurring.monthDays,
  })

  state.rruleText = data
})

const rules = {
  appoinmentPerSlot: {
    validator() {
      if (editingCalendar.value?.calendarType === CalendarType.CLASS_BOOKING) {
        if (!state.formData.appoinmentPerSlot) {
          return new Error('This is required')
        }

        if (state.formData.appoinmentPerSlot > 9999) {
          return new Error('This cannot be greater than 9999')
        }
      }

      if (state.formData.appoinmentPerSlot < 0) {
        return new Error('This cannot be less than 0')
      }

      return true
    },
    trigger: ['input', 'blur'],
  },
  recurringCount: {
    validator() {
      if (state.formData.enableRecurring) {
        if (state.formData.recurring.count > 24) {
          return new Error('Count cannot be greater than 24')
        } else if (state.formData.recurring.count < 1) {
          return new Error('Count cannot be less than 1')
        }
      }

      return true
    },
    trigger: ['input', 'blur'],
  },
  appoinmentPerDay: {
    validator() {
      if (state.formData.appoinmentPerDay < 0) {
        return new Error('This cannot be less than 0')
      }
      return true
    },
    trigger: ['input', 'blur'],
  },
}

watchEffect(() => {
  setEditCalendarValidationData(
    'enableRecurring',
    state.formData.enableRecurring
  )
})

// watch(
//   () => state.isStandardAvailability,
//   () => {
//     if (!state.isStandardAvailability) {
//       state.formData.enableRecurring = false
//     }
//   }
// )

watch(
  () => editingCalendar.value,
  () => {
    methods.loadData()
  },
  { immediate: true }
)

watch(
  () => myAvailability.value.getAvailableDaysCount(),
  () => {
    if (myAvailability.value.getAvailableDaysCount() === 0) {
      state.formData.enableRecurring = false
    }
  }
)

const handleIsHourDisabled = (hour: number, startTime?: string) => {
  const startHour = moment(startTime, 'H:m').hour()
  if (hour === 0) {
    return false
  }
  if (hour < startHour) {
    return true
  }
  return false
}

const handleIsMinDisabled = (
  min: number,
  hour: number | null,
  startTime?: string
) => {
  const startMin = moment(startTime, 'H:m').minute()
  const startHour = moment(startTime, 'H:m').hour()
  if (hour === 0) {
    return false
  }
  if (hour === startHour && min <= startMin) {
    return true
  }
  return false
}
const isServiceCalendar = computed(() => {
  return editingCalendar.value?.isServiceCalendar
})

const isCollectiveCalendar = computed(() => {
  return editingCalendar.value?.isCollectiveCalendar
})

const bufferOptionForServices = computed(() => {
  const MAX_BUFFER = 90
  return getTimeSpanOptions(MAX_BUFFER, 0, 15)
})

const existInvalidTimes = days => {
  const hours = days.map(i => i.hours).flat()
  return (
    hours.filter(
      x =>
        moment(x.startTime, 'H:m').isSameOrAfter(moment(x.endTime, 'H:m')) ||
        moment(x.endTime, 'H:m').isSameOrBefore(moment(x.startTime, 'H:m'))
    ).length > 0
  )
}

const shouldDisableRecurringMeetingSwitch = computed(() => {
  return (
    customAvailabilityPicker.value?.methods?.getAvailabilityLength() > 0 ||
    (AppState.isRecurringPaymentEnabled ? false : AppState.shouldAcceptPayment)
  )
})
</script>

<template>
  <UIHeader
    id="text-only-header"
    :secondary-title="$t('calendar_advanced.availability.my_availability')"
    :description="$t('calendar_advanced.availability.set_availability')"
  >
  </UIHeader>

  <UIDivider />

  <CustomRecurrenceModal
    :show="state.showCustomRecurrenceModal"
    @on-modal-close="methods.customRecurrenceModalCancel"
    @on-confirm="methods.customRecurrenceModalSave"
  />

  <UIForm
    id="create-calendar-form"
    ref="formRef"
    :model="state.formData"
    :rules="rules"
    class="w-full"
  >
    <section class="flex items-start overflow-hidden rounded-lg">
      <div
        id="availableDaySlotPicker"
        class="max-h-[725px] w-2/3 overflow-y-auto border-r border-gray-200 p-6"
      >
        <div class="mb-4 flex items-center gap-1">
          <!-- <p class="text-sm font-semibold">Weekly Available Hours</p> -->
          <p class="text-sm font-semibold">
            {{ $t('calendar_advanced.availability.heading_1') }}
          </p>
          <UITooltip :placement="'top'">
            <template #trigger>
              <InfoCircleIcon class="w-4 text-gray-500" />
            </template>
            <p class="max-w-sm">
              {{ $t('calendar_advanced.availability.tooltip_1') }}
            </p>
          </UITooltip>
        </div>
        <div
          v-for="(availableDay, index) in myAvailability.availableDays.sort(
            (a, b) =>
              daysOfWeekInOrder.indexOf(a.day) -
              daysOfWeekInOrder.indexOf(b.day)
          )"
          :id="'available-day-hours-' + index"
          :key="index"
          class="my-4 flex gap-3 border-b border-gray-200 py-4"
        >
          <UICheckbox
            :id="availableDay.day"
            :key="availableDay.day"
            :name="availableDay.day"
            :checked="availableDay.hours.length > 0"
            class="text-md flex w-20"
            @change="(x: boolean) => myAvailability.updateAvailabilityDay(availableDay, x)
              "
          >
            <div class="font-md mr-32 w-12 font-semibold text-gray-700">
              {{ availableDay?.day?.slice(0, 3) }}
            </div>
          </UICheckbox>

          <div>
            <div
              v-if="availableDay?.hours?.length === 0"
              class="text-sm text-gray-500"
            >
              {{ $t('calendar_advanced.availability.unavailable_label') }}
            </div>
            <div
              v-for="(availableDayHour, hourIndex) in availableDay.hours"
              v-else
              :key="hourIndex"
            >
              <div class="mb-2 flex items-center">
                <UITimePicker
                  v-model:formatted-value="availableDayHour.startTime"
                  value-format="H:m"
                  :minutes="5"
                  format="hh:mm a"
                  use-12-hours
                  :actions="['confirm']"
                />

                <span class="mx-2 font-semibold">{{
                  $t('calendar_advanced.availability.to')
                }}</span>
                <UITimePicker
                  v-model:formatted-value="availableDayHour.endTime"
                  value-format="H:m"
                  :minutes="5"
                  format="hh:mm a"
                  use-12-hours
                  :actions="['confirm']"
                  :is-hour-disabled="
                    hour =>
                      handleIsHourDisabled(hour, availableDayHour.startTime)
                  "
                  :is-minute-disabled="
                    (min, hour) =>
                      handleIsMinDisabled(min, hour, availableDayHour.startTime)
                  "
                />

                <div class="flex w-32 items-center">
                  <UIButton
                    v-if="hourIndex == 0"
                    id="add-availability-hour-std"
                    :text="true"
                    class="ml-4 text-gray-700"
                    :disabled="
                      availableDay.getLastHour().endTime === '0:0' &&
                      availableDay.getLastHour().startTime === '0:0'
                    "
                    @click="
                      x => {
                        const { startTime, endTime } =
                          availableDay.getLastHour()
                        availableDay.addNewAvailabilityHourWithStartTimeAndEndTime(
                          startTime,
                          endTime
                        )
                      }
                    "
                  >
                    <PlusIcon class="w-4" />
                  </UIButton>
                  <UIButton
                    v-if="hourIndex > -1"
                    id="remove-availability-hour-std"
                    class="ml-4 text-red-600"
                    :text="true"
                    @click="availableDay.removeAvailabilityHour(hourIndex)"
                  >
                    <Trash02Icon class="w-4"></Trash02Icon>
                  </UIButton>

                  <div class="relative">
                    <UIPopover
                      v-if="hourIndex == 0"
                      trigger="click"
                      :show="activeHourIndexForCopy === index && hourIndex == 0"
                      class="!rounded-md !pb-0"
                      @clickoutside="
                        x => {
                          copyToDays = []
                          activeHourIndexForCopy = -1
                        }
                      "
                    >
                      <template #trigger>
                        <UIButton
                          id="copy-availability-hour-std"
                          class="ml-4 text-gray-700"
                          :text="true"
                          @click="
                            x => {
                              activeHourIndexForCopy > -1
                                ? (activeHourIndexForCopy = -1)
                                : (activeHourIndexForCopy = index)
                              // myAvailability.copyAvailabilieToOneDay(availableDay)
                            }
                          "
                        >
                          <Copy06Icon class="mt-[2px] w-4"></Copy06Icon>
                        </UIButton>
                      </template>
                      <div class="w-40">
                        <p
                          class="text-md mt-0 px-0.5 font-normal text-gray-600"
                        >
                          {{
                            $t('calendar_advanced.availability.copy_times_to')
                          }}...
                        </p>
                        <UICheckbox
                          :id="'All Days'"
                          :key="'All Days'"
                          :name="'All Days'"
                          :checked="copyToDays.length === 7"
                          class="text-md flex w-20 p-2"
                          @change="
                            x =>
                              x
                                ? (copyToDays = [
                                    'Monday',
                                    'Tuesday',
                                    'Wednesday',
                                    'Thursday',
                                    'Friday',
                                    'Saturday',
                                    'Sunday',
                                  ])
                                : (copyToDays = [])
                          "
                        >
                          <div
                            class="font-md mr-32 w-full font-semibold text-gray-700"
                          >
                            {{
                              $t('calendar_advanced.availability.copy_to_all')
                            }}
                          </div>
                        </UICheckbox>
                        <div
                          v-for="(day, k) in MyAvailability.OFFICE_DAYS"
                          :key="k"
                          class="flex items-center p-2"
                        >
                          <UICheckbox
                            :id="day"
                            :key="day"
                            :name="day"
                            :checked="
                              copyToDays.includes(day) ||
                              day === availableDay.day
                            "
                            :disabled="day === availableDay.day"
                            class="text-md flex w-20"
                            @change="
                              x => {
                                x
                                  ? copyToDays.push(day)
                                  : copyToDays.splice(
                                      copyToDays.indexOf(day),
                                      1
                                    )
                              }
                            "
                          >
                            <div
                              class="font-md mr-32 w-full font-semibold text-gray-700"
                            >
                              {{ day }}
                            </div>
                          </UICheckbox>
                        </div>
                        <UIButton
                          id="submit"
                          type="primary"
                          size="small"
                          class="my-2 w-full text-center"
                          @click="
                            x => {
                              myAvailability.copyFromOneToMany(
                                availableDay.day,
                                copyToDays
                              )
                              copyToDays = []
                              activeHourIndexForCopy = -1
                            }
                          "
                        >
                          {{ $t('calendar_advanced.availability.apply') }}
                        </UIButton>
                      </div>
                    </UIPopover>
                  </div>
                </div>
              </div>
              <p
                v-if="availableDay.isOverlapIndex(hourIndex)"
                class="text-left text-sm text-red-600"
              >
                {{ $t('calendar_advanced.availability.overlap_error') }}
              </p>
            </div>
          </div>
        </div>

        <!-- <div
              :class="{
                'ml-40 mt-2 pl-3': myAvailability.availableDays.length > 0,
              }"
            >
              <UIButton
                id=""
                :loading="myAvailability.isProcessing"
                :disabled="myAvailability.hasAnyInvalidHours"
                type="primary"
                @click="myAvailability.saveMyAvailability()"
              >
                Save Availability
              </UIButton>
            </div> -->
      </div>
      <div class="max-h-[725px] w-1/3 overflow-y-auto p-6">
        <CustomAvailabilityModal
          ref="customAvailabilityPicker"
          :is-recurring-enabled="state.formData.enableRecurring"
        />
      </div>
    </section>

    <div v-if="myAvailability.getAvailableDaysCount() !== 0">
      <UIDivider />

      <div
        v-if="editingCalendar?.calendarType !== CalendarType.CLASS_BOOKING"
        class="flex gap-20 pb-3 pt-4"
      >
        <div class="flex h-full items-center space-x-3 p-0">
          <div id="calendar-lookBusy-switch">
            <UISwitch v-model:value="state.formData.lookBusyConfig.enabled">
            </UISwitch>
          </div>
          <div class="flex items-center gap-1">
            <div class="font-normal">
              {{ $t('calendar_advanced.availability.look_busy_title') }}
            </div>
            <UITooltip :placement="'top'">
              <template #trigger>
                <InfoCircleIcon class="w-4 text-gray-500" />
              </template>
              <p class="max-w-sm">
                {{ t('calendar_advanced.availability.look_busy_helper_text') }}
              </p>
            </UITooltip>
          </div>
        </div>
        <div>
          <UIInputNumber
            :id="'calendar-lookBusy-value'"
            v-model="state.formData.lookBusyConfig.lookBusyPercentage"
            :placeholder="''"
            :readonly="false"
            :loading="false"
            :clearable="false"
            :disabled="!state.formData.lookBusyConfig.enabled"
            :precision="undefined"
            :size="'medium'"
            :max="'100'"
            :min="'0'"
            :show-button="false"
          >
            <template #suffix>%</template>
          </UIInputNumber>
          <div
            class="leading-20 pt-2 text-left text-sm font-normal text-gray-500"
          >
            {{
              t(
                'calendar_advanced.availability.look_busy_percentage_description'
              )
            }}
          </div>
        </div>
      </div>

      <div
        v-if="
          !isServiceCalendar &&
          (editingCalendar?.calendarType === CalendarType.EVENT ||
            (AppState.editCalendarValidationData?.teamMembers &&
              AppState.editCalendarValidationData?.teamMembers?.length <= 1))
        "
        class="flex items-center space-x-3 pb-3 pt-4"
      >
        <div id="calendar-recurringMeeting-switch">
          <UISwitch
            v-model:value="state.formData.enableRecurring"
            :disabled="shouldDisableRecurringMeetingSwitch"
          >
          </UISwitch>
        </div>
        <div class="flex items-center gap-1">
          <div class="font-normal">
            {{ $t('calendar_advanced.availability.recurring_meeting') }}
          </div>
          <UITooltip
            v-if="shouldDisableRecurringMeetingSwitch"
            :placement="'top'"
          >
            <template #trigger>
              <InfoCircleIcon class="w-4 text-gray-500" />
            </template>
            <p class="max-w-sm">
              {{
                editingCalendar?.calendarType === CalendarType.EVENT ||
                editingCalendar?.calendarType === CalendarType.CLASS_BOOKING
                  ? t(
                      'calendar_advanced.availability.recurring_disable_event_calendar_conditions'
                    )
                  : t(
                      'calendar_advanced.availability.recurring_disabled_conditions_tooltip'
                    )
              }}
            </p>
          </UITooltip>
        </div>
      </div>

      <div
        v-else-if="!isServiceCalendar"
        class="flex items-center space-x-3 pb-3 pt-4"
      >
        <UISwitch
          v-model:value="state.formData.enableRecurring"
          :disabled="true"
        >
        </UISwitch>
        <div class="flex items-center gap-1">
          <div class="font-normal">
            {{ $t('calendar_advanced.availability.recurring_meeting') }}
          </div>
          <UITooltip :placement="'top'">
            <template #trigger>
              <InfoCircleIcon class="w-4 text-gray-500" />
            </template>
            <p class="max-w-sm">
              {{
                editingCalendar?.calendarType === CalendarType.CLASS_BOOKING
                  ? `
                  Recurring meetings are disabled. To activate them, ensure that payment is turned off and no date-specific hours are added.`
                  : t(
                      'calendar_advanced.availability.recurring_disabled_conditions_tooltip'
                    )
              }}
            </p>
          </UITooltip>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="editingCalendar?.calendarType !== CalendarType.CLASS_BOOKING">
        <UIDivider />
        <div class="flex gap-20 pb-3 pt-4">
          <div class="flex h-full items-center space-x-3 p-0">
            <div id="calendar-lookBusy-switch">
              <UISwitch v-model:value="state.formData.lookBusyConfig.enabled">
              </UISwitch>
            </div>
            <div class="flex items-center gap-1">
              <div class="font-normal">
                {{ $t('calendar_advanced.availability.look_busy_title') }}
              </div>
              <UITooltip :placement="'top'">
                <template #trigger>
                  <InfoCircleIcon class="w-4 text-gray-500" />
                </template>
                <p class="max-w-sm">
                  {{
                    t('calendar_advanced.availability.look_busy_helper_text')
                  }}
                </p>
              </UITooltip>
            </div>
          </div>
          <div>
            <UIInputNumber
              :id="'calendar-lookBusy-value'"
              v-model="state.formData.lookBusyConfig.lookBusyPercentage"
              :placeholder="''"
              :readonly="false"
              :loading="false"
              :clearable="false"
              :disabled="!state.formData.lookBusyConfig.enabled"
              :precision="undefined"
              :size="'medium'"
              :max="'100'"
              :min="'0'"
              :show-button="false"
            >
              <template #suffix>%</template>
            </UIInputNumber>
            <div
              class="leading-20 pt-2 text-left text-sm font-normal text-gray-500"
            >
              {{
                t(
                  'calendar_advanced.availability.look_busy_percentage_description'
                )
              }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="
        state.formData.enableRecurring &&
        myAvailability.getAvailableDaysCount() !== 0
      "
      class="mt-6"
    >
      <div class="flex items-start space-x-9">
        <UIFormItem
          :label="$t('calendar_advanced.availability.repeat')"
          path="recurring.freq"
          :tooltip="getTooltip('Select the frequency of this recurring event.')"
        >
          <div class="flex flex-col space-y-1">
            <UISelect
              id="calendar-recurrence-repeat"
              :value="
                state.isCustomRecurring
                  ? 'CUSTOM'
                  : state.formData.recurring.freq
              "
              class="w-64"
              :options="recurringFrequencyOptions"
              @update:value="methods.updateFrequency"
            />

            <div class="w-64">
              <div
                v-if="state.isCustomRecurring"
                class="flex items-center space-x-2 capitalize text-gray-400"
              >
                <div class="">
                  {{ state.rruleText }}
                </div>
                <XIcon
                  class="h-5 w-5 flex-grow cursor-pointer"
                  @click="() => methods.updateFrequency('DAILY')"
                />
              </div>
              <div v-else class="capitalize text-gray-400">
                {{ state.rruleText }}
              </div>
            </div>
          </div>
        </UIFormItem>

        <UIFormItem
          :label="$t('calendar_advanced.availability.times_to_repeat')"
          path="recurringCount"
        >
          <UIInputNumber
            id="calendar-recurrence-count"
            v-model="state.formData.recurring.count"
            class="w-64"
          />
        </UIFormItem>
      </div>

      <div class="flex space-x-9">
        <UIFormItem
          :label="$t('calendar_advanced.availability.slots_unavailable')"
          path="recurring.bookingOption"
        >
          <UISelect
            id="calendar-recurrence-recurrenceFreq"
            v-model:value="state.formData.recurring.bookingOption"
            class="w-64"
            :options="recurringBookingOptions"
          />
        </UIFormItem>

        <UIFormItem
          v-if="state.formData.recurring.bookingOption === 'continue'"
          :label="$t('calendar_advanced.availability.overlap_status')"
          path="recurring.bookingOverlapDefaultStatus"
        >
          <UISelect
            id="calendar-bookingOverlapDefaultStatus"
            v-model:value="state.formData.recurring.bookingOverlapDefaultStatus"
            class="w-80"
            :options="recurringOverlapStatusOptions"
          />
        </UIFormItem>
      </div>
    </div>
    <UIDivider v-if="!hideAvailabilityType" />

    <div class="flex space-x-8">
      <DurationPicker
        :label="
          isServiceCalendar
            ? $t('calendar_advanced.availability.service_interval')
            : $t('calendar_advanced.availability.meeting_interval')
        "
        :tooltip="
          isServiceCalendar
            ? $t('calendar_advanced.availability.service_interval_tooltip')
            : $t('calendar_advanced.availability.meeting_interval_tooltip')
        "
        path="slotInterval"
        :value="state.formData.slotInterval"
        :unit="state.formData.slotIntervalUnit"
        @on-value-change="
          value => {
            state.formData.slotInterval = value.duration
            state.formData.slotIntervalUnit = value.timeUnit
          }
        "
      />
      <DurationPicker
        :label="
          isServiceCalendar
            ? $t('calendar_advanced.availability.service_duration')
            : $t('calendar_advanced.availability.meeting_duration')
        "
        :tooltip="
          isServiceCalendar
            ? $t('calendar_advanced.availability.service_duration_tooltip')
            : undefined
        "
        path="slotDuration"
        :value="state.formData.slotDuration"
        :unit="state.formData.slotDurationUnit"
        @on-value-change="
          value => {
            state.formData.slotDuration = value.duration
            state.formData.slotDurationUnit = value.timeUnit
          }
        "
      />
    </div>

    <div class="flex space-x-8">
      <DurationPicker
        :label="$t('calendar_advanced.availability.minimum_scheduling')"
        :tooltip="
          isServiceCalendar
            ? $t('calendar_advanced.availability.minimum_scheduling_tooltip')
            : undefined
        "
        path="allowBookingAfter"
        :value="state.formData.allowBookingAfter"
        :unit="state.formData.allowBookingAfterUnit"
        @on-value-change="
          value => {
            state.formData.allowBookingAfter = value.duration
            state.formData.allowBookingAfterUnit = value.timeUnit
          }
        "
      />
      <DurationPicker
        :label="$t('calendar_advanced.availability.date_range')"
        :tooltip="$t('calendar_advanced.availability.date_range_tooltip')"
        path="allowBookingFor"
        :value="state.formData.allowBookingFor"
        :unit="state.formData.allowBookingForUnit"
        @on-value-change="
          value => {
            state.formData.allowBookingFor = value.duration
            state.formData.allowBookingForUnit = value.timeUnit
          }
        "
      />
    </div>
    <div
      v-if="
        ![CalendarType.CLASS_BOOKING, CalendarType.SERVICE].includes(
          editingCalendar?.calendarType
        )
      "
      class="mt-4 flex items-end space-x-8"
    >
      <div class="w-1/2">
        <UIFormItem
          :label="$t('calendar_advanced.availability.maximum_bookings_per_day')"
          path="appoinmentPerDay"
          :tooltip="
            getTooltip(
              $t(
                'calendar_advanced.availability.maximum_bookings_per_day_tooltip'
              )
            )
          "
        >
          <UIInputNumber
            id="calendar-appointments-perDay"
            v-model="state.formData.appoinmentPerDay"
            class="w-full"
            placeholder=""
          />
        </UIFormItem>
      </div>
      <div v-if="!isServiceCalendar && !isCollectiveCalendar" class="w-1/2">
        <UIFormItem
          :label="
            editingCalendar?.calendarType === CalendarType.ROUND_ROBIN ||
            editingCalendar?.calendarType === CalendarType.PERSONAL
              ? $t(
                  'calendar_advanced.availability.maximum_bookings_per_slot_per_user'
                )
              : $t('calendar_advanced.availability.maximum_bookings_per_slot')
          "
          path="appoinmentPerSlot"
          :tooltip="
            getTooltip(
              $t(
                editingCalendar?.calendarType === CalendarType.PERSONAL
                  ? 'calendar_advanced.availability.maximum_bookings_per_slot_tooltip_personal'
                  : 'calendar_advanced.availability.maximum_bookings_per_slot_tooltip'
              )
            )
          "
        >
          <UIInputNumber
            id="calendar-appointments-perSlot"
            v-model="state.formData.appoinmentPerSlot"
            class="w-full"
            placeholder=""
          />
        </UIFormItem>
      </div>
    </div>

    <UIForm v-if="isServiceCalendar" class="flex w-1/2 space-x-8">
      <UIFormItem
        class="mt-4 w-full"
        :label="$t('calendar_advanced.availability.post_buffer_time')"
        path="slotBuffer"
      >
        <UISelect
          id="calendar-slot-buffer"
          :value="`${state.formData.slotBuffer || 0} mins`"
          class="w-full"
          :options="bufferOptionForServices"
          @update:value="state.formData.slotBuffer = $event"
        />
      </UIFormItem>
    </UIForm>

    <div class="flex items-end space-x-8">
      <div
        v-if="editingCalendar?.calendarType === CalendarType.CLASS_BOOKING"
        class="w-1/2"
      >
        <UIFormItem
          :label="$t('calendar_advanced.availability.seats_per_class')"
          path="appoinmentPerSlot"
        >
          <UIInputNumber
            id="calendar-appointments-per-seat"
            v-model="state.formData.appoinmentPerSlot"
            class="w-full"
            placeholder=""
          />
        </UIFormItem>
      </div>
      <div class="w-1/2">
        <DurationPicker
          v-if="!isServiceCalendar"
          :label="$t('calendar_advanced.availability.pre_buffer_time')"
          :tooltip="$t('calendar_advanced.availability.pre_buffer_tooltip')"
          path="preBuffer"
          :value="state.formData.preBuffer"
          :unit="state.formData.preBufferUnit"
          @on-value-change="
            value => {
              state.formData.preBuffer = value.duration
              state.formData.preBufferUnit = value.timeUnit
            }
          "
        />
      </div>
      <div class="w-1/2">
        <DurationPicker
          v-if="!isServiceCalendar"
          :tooltip="$t('calendar_advanced.availability.post_buffer_tooltip')"
          :label="$t('calendar_advanced.availability.post_buffer_time')"
          path="slotBuffer"
          :value="state.formData.slotBuffer"
          :unit="state.formData.slotBufferUnit"
          @on-value-change="
            value => {
              state.formData.slotBuffer = value.duration
              state.formData.slotBufferUnit = value.timeUnit
            }
          "
        />
      </div>
    </div>
  </UIForm>
</template>

<!-- <style scoped>
* {
  border: 1px solid red;
}
</style> -->
