const HandlebarValidator = {
  // Check if string has valid handlebar string format
  isValidHandlebarString(stringToCompile: string) {
    if (!stringToCompile) {
      return true
    }

    const regex =
      /((^{{|[^{\n\r]{{)(?!{))(?!( ?([#/]?'?[\w-]'?[. ]?)+ ?(}}(?!}))))|((^{{{|[^{\n\r]{{{)(?!{))(?!( ?([#/]?\w\.?)+ ?(}}}(?!}))))|{{4,}|^{(?= )/m

    return regex.test(stringToCompile) == false
  },

  RSSItemBlockValidator(value: string) {
    if (!value) {
      return { msg: '', status: false }
    }
    const validation = { msg: '', status: true }
    if (!value.includes(`{{#rss_items rss_items}}`)) {
      validation.msg = `Missing opening rss items variable, {{#rss_items rss_items}}`
      validation.status = false
      return validation
    }
    if (!value.includes(`{{/rss_items}}`)) {
      validation.msg = `Missing closing rss items variable, {{/rss_items}}`
      validation.status = false
      return validation
    }
    value = value.replace(`{{#rss_items rss_items}}`, '')
    value = value.replace(`{{/rss_items}}`, '')
    value = value.replace(/({{rss_img )(.*?)}}/g, '')

    return HandlebarValidator.isValidHandlebarString(value)
      ? validation
      : {
        msg: 'There are issues in your custom variables, please fix them before saving.',
        status: false,
      }
  },
}

export default HandlebarValidator
