<template>
  <div class="text-left" ref="buttonRef">
    <span ref="insideButtonRef">
      <slot></slot>
    </span>
    <div
      id="tooltip"
      role="tooltip"
      ref="tooltipRef"
      class="
        text-[13px] bg-[#111828]
        font-normal
        text-white
        px-3
        py-2
        rounded-lg
        z-50
        leading-5
      "
    >
      <div id="arrow" data-popper-arrow></div>
      <div v-html="title"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import { createPopper, Placement } from '@popperjs/core'
export default defineComponent({
  name: 'UITooltip',
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      required: true,
    },
    placement: {
      type: String,
      default: 'top',
    },
    stayOnHover: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const buttonRef = ref()
    const tooltipRef = ref()
    const insideButtonRef = ref()
    let popperInstance: any = null
    function create() {
      if (props.stayOnHover) {
        popperInstance = createPopper(buttonRef.value, tooltipRef.value, {
          placement: props.placement as Placement,
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 8],
              },
            },
          ],
        })
      } else {
        popperInstance = createPopper(insideButtonRef.value, tooltipRef.value, {
          placement: props.placement as Placement,
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 8],
              },
            },
          ],
        })
      }
    }
    function destroy() {
      if (popperInstance) {
        popperInstance.destroy()
        popperInstance = null
      }
    }
    function show() {
      tooltipRef.value.setAttribute('data-show', '')
      create()
    }
    function hide() {
      tooltipRef.value.removeAttribute('data-show')
      destroy()
    }
    onMounted(() => {
      const showEvents = ['mouseenter', 'focus']
      const hideEvents = ['mouseleave', 'blur']
      showEvents.forEach(event => {
        if (props.stayOnHover) {
          buttonRef.value.addEventListener(event, show)
        } else {
          insideButtonRef.value.addEventListener(event, show)
        }
      })
      hideEvents.forEach(event => {
        if (props.stayOnHover) {
          buttonRef.value.addEventListener(event, hide)
        } else {
          insideButtonRef.value.addEventListener(event, hide)
        }
      })
    })
    function resetPosition() {
      destroy()
      create()
    }
    return {
      buttonRef,
      tooltipRef,
      resetPosition,
      insideButtonRef,
    }
  },
})
</script>

<style scoped>
#tooltip {
  display: none;
  max-width: 250px;
}
#tooltip[data-show] {
  display: block;
}
#arrow,
#arrow::before {
  position: absolute;
  width: 12px;
  height: 12px;
  z-index: -1;
}
#arrow::before {
  content: '';
  transform: rotate(45deg);
  background: #000000;
}
#tooltip[data-popper-placement^='top'] > #arrow {
  bottom: -6px;
}
#tooltip[data-popper-placement^='bottom'] > #arrow {
  top: -6px;
}
#tooltip[data-popper-placement^='left'] > #arrow {
  right: -6px;
}
#tooltip[data-popper-placement^='right'] > #arrow {
  left: -6px;
}
</style>
