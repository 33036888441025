<template>
  <UIDropdownTree
    :options="menu"
    trigger-label=""
    :debug="true"
    @on-select="updateValue"
  >
    <template #trigger="triggerProps">
      <UIButton id="dd" quaternary class="px-1">
        <Tag01Icon class="h-5 w-5" />
      </UIButton>
    </template>
  </UIDropdownTree>
</template>

<script lang="ts">
import { Tag01Icon } from '@gohighlevel/ghl-icons/24/outline'
import { UIButton, UIDropdownTree } from '@gohighlevel/ghl-ui'
import { computed, defineComponent, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
export enum CustomFieldDataType {
  TEXT = 'TEXT',
  LARGE_TEXT = 'LARGE_TEXT',
  NUMERICAL = 'NUMERICAL',
  PHONE = 'PHONE',
  MONETORY = 'MONETORY',
  CHECKBOX = 'CHECKBOX',
  SINGLE_OPTIONS = 'SINGLE_OPTIONS',
  MULTIPLE_OPTIONS = 'MULTIPLE_OPTIONS',
  RADIO = 'RADIO',
  DATE = 'DATE',
  TEXTBOX_LIST = 'TEXTBOX_LIST',
  FILE_UPLOAD = 'FILE_UPLOAD',
  SIGNATURE = 'SIGNATURE',
}
export type MenuV2 = {
  label: string
  value: string
  children?: MenuV2[]
  type?: string
}
import { getCustomValues } from '../../states/app'
import { fetchCustomFields } from '@/utils/customValueMapper'
export default defineComponent({
  name: 'CustomVariablePickerV2',
  components: {
    Tag01Icon,
    UIButton,
    UIDropdownTree,
  },
  props: {
    class: String,
    noMarginTop: {
      type: Boolean,
      default: false,
    },
    isNumericOnly: {
      type: Boolean,
      default: false,
    },
    isDateOnly: {
      type: Boolean,
      default: false,
    },
    outsideField: {
      type: Boolean,
      default: false,
    },
    openFromTop: {
      type: Boolean,
      default: false,
    },
    openLeft: {
      type: Boolean,
      default: false,
    },
    showTextSelector: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:selectedValue'],
  setup(props, { emit }) {
    const { t } = useI18n()
    let customFields = [] as any[]
    const isLoading = ref(false)

    const currentMenuItem = ref({} as MenuV2)
    const currentSubMenuItem = ref({} as MenuV2)
    const showMenu = ref(false as boolean)
    const menu = ref([] as MenuV2[])

    const isStaticHeight = computed(
      () => !props.isNumericOnly && !props.isDateOnly
    )

    const contactIdx = menu.value.findIndex(
      option => option.label.toLowerCase() === 'contact'
    )

    if (contactIdx === -1) {
      menu.value.push({
        label: 'Contact',
        value: 'contact',
        children: [],
      })
    }

    function updateMenuItem(payload: MenuV2) {
      currentMenuItem.value = payload
      currentSubMenuItem.value = {} as MenuV2
    }

    function updateSubMenuItem(payload: MenuV2) {
      currentSubMenuItem.value = payload
      if (!currentSubMenuItem.value.children) {
        emit('update:selectedValue', currentSubMenuItem.value)
        showMenu.value = false
      }
    }

    function updateValue(selected: string) {
      emit('update:selectedValue', selected)
      showMenu.value = false
    }

    function toggleMenu() {
      showMenu.value = !showMenu.value
      currentSubMenuItem.value = {} as MenuV2
      currentMenuItem.value = {} as MenuV2
    }

    const nestedMenu = computed(() => {
      return currentMenuItem.value.children || []
    })

    const nestedSubMenu = computed(() => {
      return currentSubMenuItem.value.children || []
    })

    async function initializeCustomFields() {
      isLoading.value = true

      const requiredFields = [
        'contact',
        'user',
        'appointment',
        'calendar',
        'account',
        'right_now',
        'attribution',
      ]

      // Fetch the custom fields based on required fields
      const availableCustomFields = fetchCustomFields(requiredFields)
      customFields = availableCustomFields

      // Fetch custom values asynchronously
      const customValues = (await getCustomValues()) as any[]

      if (customValues) {
        const customValuesObject: {
          label?: string
          value?: string
          children?: { label: string; value: string }[]
        } = {}

        customValuesObject.label = 'Custom Value'
        customValuesObject.value = 'customValue'
        customValuesObject.children = customValues
          .filter(item => item.name && item.fieldKey)
          .map(item => ({
            label: item.name,
            value: item.fieldKey,
          }))

        // Push the new object with custom values to the custom fields array
        customFields.push(customValuesObject)
      }

      // Set menu to the updated custom fields
      menu.value = [...customFields]
      isLoading.value = false
    }

    onMounted(async () => {
      await initializeCustomFields()
    })

    return {
      nestedMenu,
      updateMenuItem,
      updateSubMenuItem,
      currentMenuItem,
      currentSubMenuItem,
      nestedSubMenu,
      showMenu,
      toggleMenu,
      menu,
      updateValue,
      isLoading,
      isStaticHeight,
    }
  },
  mounted() {
    if (this.class) {
      const classList = this.class.split(' ')
      classList.forEach(customClass => {
        this.$refs.picker.classList.add(customClass)
      })
    }
  },
})
</script>
