import config from '@/config'
const bucket_url = config.bucketUrl

const defaultEmailSubjectForUserForNewEvents = `New Appointment Booked with {{contact.name}} on {{appointment.start_time}} ({{appointment.timezone_offset}})`
const defaultEmailBodyForUserForNewEvents = `
Hi,
<br><br>
A new appointment has been scheduled with {{contact.name}}. Please review the details below and confirm the appointment:
<br><br>
Appointment Title: {{appointment.title}}<br>
Date and Time: {{appointment.start_time}} ({{appointment.timezone_offset}})<br>
Meeting Link / Location: {{appointment.meeting_location}}<br>
Contact Name: {{contact.name}}<br>
<br><br>
Click below to approve, reschedule, or cancel the request.
<br><br>
<table width="100%" border="0" cellspacing="0" cellpadding="0">
<tr>
<td>
<table border="0" cellspacing="0" cellpadding="0">
<tr>
<td bgcolor="#EB7035" style="padding: 10px 9px 10px 9px;border-radius:3px;background-color: #37ca37;" align="center">
<a href="{{appointment_link}}" target="_blank" style="font-size: 16px; font-family: Helvetica, Arial, sans-serif; font-weight: normal; color: #ffffff; text-decoration: none; display: inline-block;">Review Request &rarr;</a>
</td>
</tr>
</table>
</td>
</tr>
</table>
`

const defaultEmailForUserForNewEvents = {
  subject: defaultEmailSubjectForUserForNewEvents,
  html: defaultEmailBodyForUserForNewEvents,
}

const defaultEmailSubjectForContactForNewEvents = `Appointment Request Received for {{appointment.start_time}} ({{appointment.timezone_offset}})`
const defaultEmailBodyForContactForNewEvents = `
Hi {{contact.name}},
<br><br>
We’ve received your appointment request and will confirm it shortly.
<br><br>
Here are the details of your requested appointment:
<br><br>
Appointment Title: {{appointment.title}}<br>
Date and Time: {{appointment.start_time}} ({{appointment.timezone_offset}})<br>
Meeting Link / Location: {{appointment.meeting_location}}<br>
<br><br><br>
`

const defaultEmailForContactForNewEvents = {
  subject: defaultEmailSubjectForContactForNewEvents,
  html: defaultEmailBodyForContactForNewEvents,
}

const defaultEmailSubjectForUserForConfirmedEvents = `Appointment Confirmation of {{contact.name}} on {{appointment.start_time}} ({{appointment.timezone_offset}})`
const defaultEmailBodyForUserForConfirmedEvents = `
Hi,
<br><br>
Your appointment has been scheduled with {{contact.name}}. Here are the details of your upcoming appointment:
<br><br>
Appointment Title: {{appointment.title}}<br>
Date and Time: {{appointment.start_time}} ({{appointment.timezone_offset}})<br>
Meeting Link / Location: {{appointment.meeting_location}}<br>
Contact Name: {{contact.name}}<br>
<br><br>
Click below to approve, reschedule, or cancel the request.
<br><br>
<table width="100%" border="0" cellspacing="0" cellpadding="0">
<tr>
<td>
<table border="0" cellspacing="0" cellpadding="0">
<tr>
<td bgcolor="#EB7035" style="padding: 10px 9px 10px 9px;border-radius:3px;background-color: #37ca37;" align="center">
<a href="{{appointment_link}}" target="_blank" style="font-size: 16px; font-family: Helvetica, Arial, sans-serif; font-weight: normal; color: #ffffff; text-decoration: none; display: inline-block;">Review Request &rarr;</a>
</td>
</tr>
</table>
</td>
</tr>
</table>
`

const defaultEmailForUserForConfirmedEvents = {
  subject: defaultEmailSubjectForUserForConfirmedEvents,
  html: defaultEmailBodyForUserForConfirmedEvents,
}

const defaultEmailSubjectForContactForConfirmedEvents = `Appointment Confirmation on {{appointment.start_time}} ({{appointment.timezone_offset}})`
const addToCalendarButtonTemplate = `
<div style="text-align: left; margin-top: 28px;">
  <p style="font-weight: 500; line-height: 4px; text-align: left;">Add to
    Calendar</p><br>
  <a href="{{base_url}}/google/calendar/add-event/{{event_id}}"
    style="display: inline-block; padding: 10px 16px; border-radius: 8px; border: 1px solid #D0D5DD; color: #344054; cursor: pointer; text-decoration: none;"
    target="_blank"><img style="width:16px;height:16px;vertical-align:middle;margin-right:4px;border-style: none;padding: 2px 2px;"
      src="${bucket_url}/g-calendar.png" />Google</a>
  <a href="{{base_url}}/google/calendar/get-ics/{{event_id}}"
    style="display: inline-block; margin: 0 15px; padding: 10px 16px; border-radius: 8px; border: 1px solid #D0D5DD;color: #344054; cursor: pointer; text-decoration: none;"
    target="_blank"><img style="width:16px;height:16px;vertical-align:middle;margin-right:4px;border-style: none;padding: 2px 2px;"
      src="${bucket_url}/ms_outlook.png" />Outlook</a>
  <a href="{{base_url}}/google/calendar/get-ics/{{event_id}}"
    style="display: inline-block; padding: 10px 16px; border-radius: 8px; border: 1px solid #D0D5DD;color: #344054; cursor: pointer; text-decoration: none;"
    target="_blank"><img style="width:16px;height:16px;vertical-align:middle;margin-right:4px;border-style: none;padding: 2px 2px;"
      src="${bucket_url}/iCal.png" />iCloud</a>
</div>
`
const defaultEmailBodyForContactForConfirmedEvents = `
Hi {{contact.name}},
<br><br>
Your appointment has been scheduled. Here are the details of your upcoming appointment:
<br><br>
Appointment Title: {{appointment.title}}<br>
Date and Time: {{appointment.start_time}} ({{appointment.timezone_offset}})<br>
Meeting Link / Location: {{appointment.meeting_location}}<br>
${addToCalendarButtonTemplate}
`
const defaultEmailForContactForConfirmedEvents = {
  subject: defaultEmailSubjectForContactForConfirmedEvents,
  html: defaultEmailBodyForContactForConfirmedEvents,
}

const defaultEmailSubjectForGuestForConfirmedEvents = `Appointment Confirmation on {{appointment.start_time}} ({{appointment.timezone_offset}})`
const defaultEmailBodyForGuestForConfirmedEvents = `
Hi {{guest.name}},
<br><br>
You have been invited to an appointment by {{contact.name}}. Here are the details of your upcoming appointment:
<br><br>
Appointment Title: {{appointment.title}}<br>
Date and Time: {{appointment.start_time}} ({{appointment.timezone_offset}})<br>
Meeting Link: {{appointment.meeting_location}}<br>
${addToCalendarButtonTemplate}
`
const defaultEmailForGuestForConfirmedEvents = {
  subject: defaultEmailSubjectForGuestForConfirmedEvents,
  html: defaultEmailBodyForGuestForConfirmedEvents,
}

const defaultEmailBodyForGuestForConfirmedEventsWithCustomLink = `
Hi {{guest.name}},
<br><br>
You have been invited to an appointment by {{contact.name}}. Here are the details of your upcoming appointment:
<br><br>
Appointment Title: {{appointment.title}}<br>
Date and Time: {{appointment.start_time}} ({{appointment.timezone_offset}})<br>
Meeting Link: {{appointment.meeting_location}}<br>
The appointment organizer has requested additional information. Please <a href="{{calendar.form_link}}">click here</a> to provide the requested details.<br>

In case the above link does not work you can use this link {{calendar.form_link}}<br>
`
const defaultEmailForGuestForConfirmedEventsWithCustomLink = {
  subject: defaultEmailSubjectForGuestForConfirmedEvents,
  html: defaultEmailBodyForGuestForConfirmedEventsWithCustomLink,
}

const defaultEmailSubjectForUserForCancelledEvents = `Cancelled: Appointment with {{contact.name}} on {{appointment.start_time}} ({{appointment.timezone_offset}})`
const defaultEmailBodyForUserForCancelledEvents = `
Hi,
<br><br>
Your appointment with {{contact.name}}, originally scheduled for {{appointment.start_time}} ({{appointment.timezone_offset}}), has been cancelled.
<br><br>
Here are the details for reference:
<br><br>
Appointment Title: {{appointment.title}}<br>
Date and Time: {{appointment.start_time}} ({{appointment.timezone_offset}})<br>
Meeting Link / Location: {{appointment.meeting_location}}<br>
Contact Name: {{contact.name}}<br>
<br><br>
`
const defaultEmailForUserForCancelledEvents = {
  subject: defaultEmailSubjectForUserForCancelledEvents,
  html: defaultEmailBodyForUserForCancelledEvents,
}

const defaultEmailSubjectForContactForCancelledEvents = `Appointment Cancelled: {{appointment.start_time}} ({{appointment.timezone_offset}})`
const defaultEmailBodyForContactForCancelledEvents = `
Hi {{contact.name}},
<br><br>
We regret to inform you that your upcoming appointment, originally scheduled for {{appointment.start_time}} ({{appointment.timezone_offset}}), has been cancelled.
<br><br>
Here are the details for your reference:
<br><br>
Appointment Title: {{appointment.title}}<br>
Date and Time: {{appointment.start_time}} ({{appointment.timezone_offset}})<br>
Meeting Link / Location: {{appointment.meeting_location}}<br>
`
const defaultEmailForContactForCancelledEvents = {
  subject: defaultEmailSubjectForContactForCancelledEvents,
  html: defaultEmailBodyForContactForCancelledEvents,
}

const defaultEmailSubjectForUserForRescheduledEvents = `Appointment Rescheduled: {{contact.name}} on {{appointment.start_time}} ({{appointment.timezone_offset}})`
const defaultEmailBodyForUserForRescheduledEvents = `
Hi,
<br><br>
Your appointment with {{contact.name}} has been rescheduled. Below are the updated details:
<br><br>
Appointment Title: {{appointment.title}}<br>
New Date and Time: {{appointment.start_time}} ({{appointment.timezone_offset}})<br>
Meeting Link / Location: {{appointment.meeting_location}}<br>
Contact Name: {{contact.name}}<br>
<br><br>
`
const defaultEmailForUserForRescheduledEvents = {
  subject: defaultEmailSubjectForUserForRescheduledEvents,
  html: defaultEmailBodyForUserForRescheduledEvents,
}

const defaultEmailSubjectForContactForRescheduledEvents = `Appointment Rescheduled to {{appointment.start_time}} ({{appointment.timezone_offset}})`
const defaultEmailBodyForContactForRescheduledEvents = `
Hi {{contact.name}},
<br><br>
Your upcoming appointment has been rescheduled. Here are the updated details:
<br><br>
Appointment Title: {{appointment.title}}<br>
Date and Time: {{appointment.start_time}} ({{appointment.timezone_offset}})<br>
Meeting Link / Location: {{appointment.meeting_location}}<br>
${addToCalendarButtonTemplate}
`
const defaultEmailForContactForRescheduledEvents = {
  subject: defaultEmailSubjectForContactForRescheduledEvents,
  html: defaultEmailBodyForContactForRescheduledEvents,
}

const defaultEmailSubjectForUserReminder = `Reminder: Upcoming Appointment with {{contact.name}} on {{appointment.start_time}} ({{appointment.timezone_offset}})`
const defaultEmailBodyForUserReminder = `
Hi,
<br><br>
This is a reminder of your upcoming appointment with {{contact.name}}. Please review the details below:
<br><br>
Appointment Title: {{appointment.title}}<br>
New Date and Time: {{appointment.start_time}} ({{appointment.timezone_offset}})<br>
Meeting Link / Location: {{appointment.meeting_location}}<br>
Contact Name: {{contact.name}}<br>
<br><br>
`
const defaultEmailForUserForReminders = {
  subject: defaultEmailSubjectForUserReminder,
  html: defaultEmailBodyForUserReminder,
}

const defaultEmailSubjectForContactForReminder = `Reminder: Upcoming Appointment on {{appointment.start_time}} ({{appointment.timezone_offset}})`
const defaultEmailBodyForContactForReminder = `
Hi {{contact.name}},
<br><br>
This is a friendly reminder about your upcoming appointment. Here are the details:
<br><br>
Appointment Title: {{appointment.title}}<br>
Date and Time: {{appointment.start_time}} ({{appointment.timezone_offset}})<br>
Meeting Link / Location: {{appointment.meeting_location}}<br>
${addToCalendarButtonTemplate}
`
const defaultEmailForContactForReminder = {
  subject: defaultEmailSubjectForContactForReminder,
  html: defaultEmailBodyForContactForReminder,
}

const defaultEmailSubjectForUserFollowup = `Follow-Up: Appointment Completed with {{contact.name}}`
const defaultEmailBodyForUserFollowup = `
Hi,
<br><br>
You have completed your appointment with {{contact.name}} on {{appointment.start_time}} ({{appointment.timezone_offset}}). This is a follow-up email to ensure all relevant details have been successfully updated in the system.
<br><br>
If you have any additional notes or feedback about the appointment, please feel free to share.
<br><br>
`
const defaultEmailForUserForFollowup = {
  subject: defaultEmailSubjectForUserFollowup,
  html: defaultEmailBodyForUserFollowup,
}

const defaultEmailSubjectForContactForFollowup = `We’d love your feedback on your recent appointment!`
const defaultEmailBodyForContactForFollowup = `
Hi {{contact.name}},
<br><br>
Thank you for attending the appointment on {{appointment.start_time}} ({{appointment.timezone_offset}}). We’d love to hear your feedback!
<br><br>
Feel free to reply directly to this email with your thoughts.
${addToCalendarButtonTemplate}
`
const defaultEmailForContactForFollowup = {
  subject: defaultEmailSubjectForContactForFollowup,
  html: defaultEmailBodyForContactForFollowup,
}

export const inAppNotificationTemplate = {
  booked: {
    title: `New Appointment Booked`,
    body: `You have a new appointment booked with {{contact.name}}`,
  },
  confirmation: {
    title: `Appointment Confirmed`,
    body: `Appointment with {{contact.name}} is confirmed`,
  },
  cancellation: {
    title: `Appointment Cancelled`,
    body: `Appointment with {{contact.name}} is cancelled`,
  },
  reschedule: {
    title: `Appointment Rescheduled`,
    body: `Appointment with {{contact.name}} is rescheduled`,
  },
  reminder: {
    title: `Appointment Reminder`,
    body: `Appointment with {{contact.name}} is starting in `,
  },
  followup: {
    title: `Appointment Follow-up`,
    body: `Reminder to follow-up regarding your appointment with {{contact.name}}`,
  },
}

const DEFAULT_TEMPLATES = {
  booked: {
    assignedUser: defaultEmailForUserForNewEvents,
    emails: defaultEmailForUserForNewEvents,
    contact: defaultEmailForContactForNewEvents,
    guest: defaultEmailForContactForNewEvents,
  },
  confirmation: {
    assignedUser: defaultEmailForUserForConfirmedEvents,
    emails: defaultEmailForUserForConfirmedEvents,
    contact: defaultEmailForContactForConfirmedEvents,
    guest: {
      default: defaultEmailForGuestForConfirmedEvents,
      customLink: defaultEmailForGuestForConfirmedEventsWithCustomLink,
    },
  },
  cancellation: {
    assignedUser: defaultEmailForUserForCancelledEvents,
    emails: defaultEmailForUserForCancelledEvents,
    contact: defaultEmailForContactForCancelledEvents,
    guest: defaultEmailForContactForCancelledEvents,
  },
  reschedule: {
    assignedUser: defaultEmailForUserForRescheduledEvents,
    emails: defaultEmailForUserForRescheduledEvents,
    contact: defaultEmailForContactForRescheduledEvents,
    guest: defaultEmailForContactForRescheduledEvents,
  },
  reminder: {
    assignedUser: defaultEmailForUserForReminders,
    emails: defaultEmailForUserForReminders,
    contact: defaultEmailForContactForReminder,
    guest: defaultEmailForContactForReminder,
  },
  followup: {
    assignedUser: defaultEmailForUserForFollowup,
    emails: defaultEmailForUserForFollowup,
    contact: defaultEmailForContactForFollowup,
    guest: defaultEmailForContactForFollowup,
  },
}
export function getDefaultEmailSubjectAndBody(
  notification,
  isCustom?: boolean
): { subject: string; html: string } {
  const { notificationType, receiverType, channel } = notification
  if ((!notificationType && !receiverType) || channel !== 'email') {
    return { subject: '', html: '' }
  }
  if (notificationType === 'confirmation' && receiverType === 'guest') {
    return isCustom
      ? DEFAULT_TEMPLATES[notificationType][receiverType].customLink
      : DEFAULT_TEMPLATES[notificationType][receiverType].default
  }
  return DEFAULT_TEMPLATES[notificationType][receiverType] as {
    subject: string
    html: string
  }
}
