<template>
  <div>
    <UIFormItem label="One time link" :show-label="false" :show-feedback="false" class="mt-2">
      <UIInput id="scheduling-link-input" v-model="oneTimeLink" class="mr-2 !w-9/12" :readonly="true" size="medium"
        :disabled="true" placeholder="Loading..." :loading="isLoading" />
      <UIButton id="copyOneTimeLinkButton" type="primary" size="small" class="!w-3/12" :disabled="isGeneratingLink"
        @click="methods.copyOneTimeLink">
        <Copy01Icon class="mr-2 h-4 w-4" /> Copy
      </UIButton>
    </UIFormItem>
    <UIButton id="regenerateOneTimeLink" class="mt-3 p-2 text-blue-700" :text="true" :disabled="isGeneratingLink"
      @click="methods.generateOneTimeLink">
      <RefreshCcw05Icon class="mr-2 h-4 w-4" /> Generate New Link
    </UIButton>
    <div class="py-2 text-sm text-gray-500">
      <h2 class="py-2 font-semibold">One Time Link</h2>
      Share your availability every time with a unique link that expires after a
      booking, ensuring controlled access.
    </div>
  </div>
</template>

<script setup lang="ts">
import { Copy01Icon, RefreshCcw05Icon } from '@gohighlevel/ghl-icons/24/outline'
import {
  UIButton,
  UIFormItem,
  UIInput,
  useNotification,
} from '@gohighlevel/ghl-ui'
import { Prop, onMounted, ref } from 'vue'
import { CalendarService } from '../../../class/services/CalendarService'
import Calendar from '../../../models/calendar'
import AppState from '../../../states/app'
import { copyToClipboard } from '../../../utils/generalFunctions'

const notification = useNotification()
const oneTimeLink = ref('')
const isGeneratingLink = ref(false)
const isLoading = ref(true)
const props = defineProps({
  calendar: {} as Prop<Calendar>,
})
onMounted(async () => {
  await methods.generateOneTimeLink()
  isLoading.value = false
})

const methods = {
  async copyOneTimeLink() {
    if (oneTimeLink.value) {
      copyToClipboard(oneTimeLink.value)
      notification.info({
        content: 'Copied to clipboard',
        duration: 1 * 1000,
      })
    }
  },
  async generateOneTimeLink() {
    let temporaryUrl
    isGeneratingLink.value = true
    try {
      if (props.calendar?.id) {
        temporaryUrl = await CalendarService.generateOneTimeSlug(
          props.calendar?.id
        )
        if (!temporaryUrl?.data?.oneTimeSlug) {
          throw 'Invalid '
        }
        oneTimeLink.value = `${AppState?.baseUrl}/widget/otl/${temporaryUrl?.data?.oneTimeSlug.slugId}?slug=${props.calendar.widgetSlug}`
      }
    } catch (error) {
      console.error('Error: one time link fetching', props.calendar?.id, error)
      notification.error({
        content: 'Couldn\'t generate one time link. Please try again',
        duration: 1 * 1000,
      })
    }
    isGeneratingLink.value = false
  },
}
</script>
