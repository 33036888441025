<script setup lang="ts">
import AppState, {
  fetchLocationDetails,
  getCalendarsInLocation,
  getTeamsInLocation,
} from '@/states/app'
import {
  UIButton,
  UIDivider,
  UIFormItem,
  UISelect,
  UISpin,
  UISwitch,
  useNotification,
} from '@gohighlevel/ghl-ui'
import { computed, onMounted, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { CalendarService } from '../../class/services/CalendarService'
import { CalendarServiceMenuService } from '../../class/services/CalendarServiceMenuService'
import Calendar from '../../models/calendar'
import Team from '../../models/team'
import { GoogleOrganicEntity, Menu, ServiceMenu } from '../../types'
import { getTooltip } from '../../utils/generalFunctions'
import ConfirmDialog from '../common/ConfirmDialog.vue'
const { t } = useI18n()
const notification = useNotification()
enum ServiceGroupType {
  SERVICE_MENU = 'service_menu',
  GROUP = 'group',
}
const confirmDialog = ref<any>(null)
const state = reactive({
  isLoading: false,
  calendars: [] as Array<any>,
  groupCalendars: [],
  entityId: '',
  entity: {} as GoogleOrganicEntity,
  serviceMenus: [] as Array<ServiceMenu>,
  formData: {
    locationId: AppState.locationId,
    name: '',
    latitude: '',
    longitude: '',
    address: {
      country: '',
      streetAddress: '',
      locality: '',
      postalCode: '',
      region: '',
    },
    telephone: '',
    url: '',
    serviceGroupId: '',
    serviceGroupType: '',
    serviceIds: [] as Array<string>,
    isActive: false,
  },
})
const isSaving = ref(false)

const method = {
  async updateEntity(value) {
    state.formData.isActive = value
    try {
      const ok = await confirmDialog.value.show({
        title: `${value ? 'Enable' : 'Disable'} Google Organic Booking`,
        message: `Are you sure you want to ${
          value ? 'enable' : 'disable'
        } Google Organic Booking? It may take 24 to 48 hours for this change to be reflected on your Google My Business Profile.`,
        okButton: 'Confirm',
      })
      if (ok && state.entityId) {
        await CalendarService.updateEntity(state.entityId, {
          isActive: state.formData.isActive,
        })
        return
      }
    } catch (error) {
      notification.error({
        content: 'Error while updating! please try again',
        duration: 1 * 1000,
      })
    }
    state.formData.isActive = state.entityId ? !value : value
  },
  async saveEntity() {
    isSaving.value = true
    try {
      if (state.entityId) {
        await CalendarService.updateEntity(state.entityId, state.formData)
      } else if (state.formData.serviceGroupId) {
        const response = await CalendarService.createEntity(state.formData)
        method.setEntity(response.data.entity, null)
        AppState.parentApp?.then(parent => {
          parent.emit('organic-booking-integrated')
        })
      }
    } catch (error) {
      console.error('failed to update entity', error)
    }
    isSaving.value = false
  },
  async loadData() {
    state.isLoading = true
    try {
      const [entityResponse, serviceMenuResponse] = await Promise.all([
        CalendarService.fetchEntity(AppState.locationId),
        CalendarServiceMenuService.getAll(AppState.locationId),
      ])
      let location = AppState.currentLocationDetail?.data
      if (!entityResponse?.data?.entity) {
        if (!location) {
          await fetchLocationDetails(AppState.locationId)
          location = AppState.currentLocationDetail?.data
        }
      }
      state.serviceMenus = serviceMenuResponse?.data?.serviceMenus || []
      await Promise.all([getTeamsInLocation(), getCalendarsInLocation()])
      state.entity = entityResponse.data.entity
      method.setEntity(entityResponse.data.entity, location)
      method.setSelectedCalendars()
      state.isLoading = false
    } catch (error) {
      console.error('error in loading')
    }
  },
  setSelectedCalendars() {
    const selectedServiceCalendarIds = method.findServiceId()
    if (selectedServiceCalendarIds?.length) {
      state.calendars = (AppState.globalCalendars as any)?.calendars.filter(
        y => selectedServiceCalendarIds.includes(y.id) && y.isActive
      )
    } else {
      state.calendars = (AppState.globalCalendars as any)?.calendars.filter(
        y =>
          state.formData.serviceIds.includes(y.id) &&
          y.isActive &&
          y.groupId === state.formData.serviceGroupId
      )
    }
    state.formData.serviceIds = state.calendars.map(c => c.id)
  },

  setEntity(entity: GoogleOrganicEntity, location: any) {
    state.entityId = entity?.id
    state.formData.name = entity?.name || location?.business?.name
    state.formData.telephone = entity?.telephone || location?.phone
    state.formData.url = entity?.url || location?.website
    state.formData.latitude = entity?.latitude || location?.latitude
    state.formData.longitude = entity?.longitude || location?.longitude
    state.formData.address.country =
      entity?.address.country || location?.country
    state.formData.address.postalCode =
      entity?.address.postalCode || location?.postalCode
    state.formData.address.locality = entity?.address.locality || location?.city
    state.formData.address.region = entity?.address.region || location?.state
    state.formData.address.streetAddress =
      entity?.address.streetAddress || location?.address
    state.formData.serviceGroupId = entity?.serviceGroupId || ''
    state.formData.serviceIds = entity?.serviceIds || []
    state.formData.serviceGroupType = entity?.serviceGroupType || 'group'
    state.formData.isActive = entity?.isActive || false
  },
  updateGroupSelection(value) {
    state.calendars = []
    state.formData.serviceGroupType = method.getServiceGroupType(value)
  },
  getServiceGroupType(value) {
    if (state.serviceMenus?.find(s => s.id === value)) {
      return ServiceGroupType.SERVICE_MENU
    }
    return ServiceGroupType.GROUP
  },
  updateSelectedCalendar(value) {
    const existingTeamMemberIds = state.calendars.map(x => x.id)
    const removedTeamMembers = existingTeamMemberIds.filter(
      x => !value.includes(x)
    )
    const addedTeamMembers = value.filter(
      x => !existingTeamMemberIds.includes(x)
    )
    state.calendars = state.calendars.filter(
      x => !removedTeamMembers.includes(x.id)
    )
    state.calendars = [
      ...state.calendars,
      ...addedTeamMembers.map(x =>
        (AppState.globalCalendars as any)?.calendars.find(y => y.id === x)
      ),
    ]
    state.formData.serviceIds = state.calendars.map(x => x.id)
  },
  cancelEdit() {
    method.setEntity(state.entity, {})
    method.setSelectedCalendars()
  },
  openBusinessProfile() {
    AppState.parentApp?.then(parent => {
      parent.emit('go-to-business-profile')
    })
  },
  findServiceId(): string[] {
    let calendarIds: Array<string> = []
    state.serviceMenus.forEach((serviceMenu: ServiceMenu) => {
      if (serviceMenu?.id === state.formData.serviceGroupId) {
        serviceMenu?.menu?.forEach((menu: Menu) => {
          const calIds = menu.calendarIds.map(x => x.id)
          calendarIds = calendarIds.concat(calIds)
        })
      }
    })
    return calendarIds
  },
}
const groupsOptions = computed(() => {
  const groupAndServiceOption: any = []
  const activeGroups = (AppState.globalTeams as any)?.teams?.map((x: Team) => {
    return {
      value: x.id,
      label: x.name,
      disabled: !x.isActive,
    }
  })

  if (activeGroups.length) {
    groupAndServiceOption.push({
      type: 'group',
      label: 'Groups',
      key: 'groups',
      // description: 'Groups',
      children: activeGroups,
    })
  }
  const activeService = state.serviceMenus.map((x: any) => {
    return {
      value: x.id,
      label: x.name,
      disabled: !x.isActive,
    }
  })
  if (activeService.length) {
    groupAndServiceOption.push({
      type: 'group',
      label: 'Services',
      key: 'services',
      // description: 'Services',
      children: activeService,
    })
  }

  return groupAndServiceOption
})

const calendarInLocationOptions = computed(() => {
  if (state.formData.serviceGroupId) {
    const calendarIds = method.findServiceId()
    if (calendarIds?.length) {
      return (AppState.globalCalendars as any)?.calendars
        .filter(cal => calendarIds.includes(cal.id) && cal.isActive)
        .map((x: Calendar) => {
          return {
            value: x.id,
            label: x.name,
          }
        })
    }
    return (AppState.globalCalendars as any)?.calendars
      .filter(y => y.groupId === state.formData.serviceGroupId && y.isActive)
      .map((x: Calendar) => {
        return {
          value: x.id,
          label: x.name,
        }
      })
  }
  return (AppState.globalCalendars as any)?.calendars
    .filter(y => y.isActive)
    .map((x: Calendar) => {
      return {
        value: x.id,
        label: x.name,
      }
    })
})

const calendarsWithFullDetails = computed(() => {
  return state.calendars.map(x => {
    const calendar = (AppState.globalCalendars as any)?.calendars.find(
      (y: any) => y.id === x.id && y.isActive
    )
    return {
      ...x,
      ...calendar,
    }
  })
})
onMounted(async () => {
  await method.loadData()
})
</script>
<template>
  <div class="max-w-[28rem]">
    <div class="mb-4 flex flex-col rounded bg-blue-50 p-5 text-blue-700">
      <span class="font-semibold">Before you start</span>
      <span class="mt-4">Verify Location Details:</span>
      <div>
        Ensure your Business Profile's location information matches your GMB
        profile. Consistency is key for Google's approval process.
        <a
          href="#"
          class="text-sm font-normal text-blue-600 underline"
          @click="method.openBusinessProfile"
        >
          Check here</a
        >
      </div>
      <div class="flex flex-col">
        <span class="mt-4">Match Descriptions:</span>
        <div>
          Match the descriptions of your Calendars/Services to their names.
          Google's team will verify this information for approval
        </div>
      </div>
      <a
        href="https://doc.clickup.com/8631005/d/h/87cpx-121087/b8f5a98078c1453"
        target="_blank"
        rel="noopener noreferrer"
        class="mt-6 py-1 text-sm font-semibold text-blue-500"
        ><u>Learn more</u></a
      >
    </div>
    <div v-if="state.isLoading">
      <UISpin
        class="flex h-[400px] items-center justify-center overflow-auto"
      />
    </div>
    <div v-else class="flex flex-col bg-white">
      <UIDivider />
      <div class="mb-4 flex space-x-3 pt-3">
        <div id="organic-booking-form-toggle">
          <UISwitch
            v-model:value="state.formData.isActive"
            size="medium"
            @update:value="
              value => {
                method.updateEntity(value)
              }
            "
          >
          </UISwitch>
        </div>
        <div class="flex flex-col">
          {{ state.formData.isActive ? 'Disable' : 'Enable' }} Google Organic
          Booking
          <span class="text-gray-400">
            {{ state.formData.isActive ? 'Disabling' : 'Enabling' }} may take 24
            to 48 hrs to reflect on GMB profile
          </span>
        </div>
      </div>
      <UIFormItem
        label="Primary Action Feed (Service Menu/Group)"
        :tooltip="
          getTooltip(
            'Choose a service menu or group to link with the \'Book Now\' button on Google'
          )
        "
        :required="true"
      >
        <UISelect
          id="calendar-group-select"
          v-model:value="state.formData.serviceGroupId"
          :options="groupsOptions"
          :disabled="!state.formData.isActive"
          @change="method.updateGroupSelection"
        />
      </UIFormItem>
      <UIFormItem
        label="Services Feed (Calendars)"
        :tooltip="
          getTooltip('Choose calendars to be mapped under Services on Google')
        "
      >
        <UISelect
          id="select-calendars-as-entity-service"
          :value="calendarsWithFullDetails.map(x => x.id)"
          :multiple="true"
          :filterable="true"
          :disabled="!state.formData.isActive || !state.formData.serviceGroupId"
          :options="calendarInLocationOptions"
          @change="method.updateSelectedCalendar"
        />
      </UIFormItem>

      <UIDivider v-if="state.formData.isActive" />
      <div
        v-if="state.formData.isActive"
        class="mt-4 flex flex-row justify-end space-x-3"
      >
        <UIButton
          id="entity-cancel"
          class="px-4"
          :disabled="state.isLoading || isSaving"
          @click="method.cancelEdit"
        >
          Reset
        </UIButton>
        <UIButton
          id="entity-save"
          class="px-4"
          :loading="isSaving"
          :disabled="state.isLoading"
          type="primary"
          @click="method.saveEntity"
        >
          Upload
        </UIButton>
      </div>
    </div>
    <ConfirmDialog ref="confirmDialog"></ConfirmDialog>
  </div>
</template>
