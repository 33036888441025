import config from '@/config'
import { requests } from './BaseService'

export const CalendarServiceMenuService = {
  getAll: (locationId: string) =>
    requests().get(
      `${config.calendarApiUrl}/calendars/service-menus/${locationId}`,
      {}
    ),
  get: (calendarId: string) =>
    requests().get(
      `${config.calendarApiUrl}/calendars/service-menu/${calendarId}`
    ),
  create: (body: any) =>
    requests().post(`${config.calendarApiUrl}/calendars/service-menus`, body),
  edit: (serviceMenuId: string, body: any) =>
    requests().put(
      `${config.calendarApiUrl}/calendars/service-menus/${serviceMenuId}`,
      body
    ),
  validateSlug: (locationId: string, slug: string) =>
    requests().post(
      `${config.calendarApiUrl}/calendars/service-menus/validate-slug`,
      {
        locationId,
        slug,
      }
    ),
  updateStatus: (serviceMenuId: string, isActive: boolean) =>
    requests().put(
      `${config.calendarApiUrl}/calendars/service-menus/${serviceMenuId}/status`,
      {
        isActive,
      }
    ),
  delete: (id: string) =>
    requests().delete(`${config.calendarApiUrl}/calendars/service-menus/${id}`),
}
