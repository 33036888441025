<!-- eslint-disable vue/no-v-html -->
<template>
  <UIModal id="confirmation-modal" v-model:show="confirmation.show">
    <template #header>
      <UIModalHeader
        id="confirmation-header"
        :type="confirmation.type"
        :title="confirmation.title"
        :icon="
          confirmation.type === 'error' ? Trash01Icon : AnnotationCheckIcon
        "
        @close="handleCancel"
      >
      </UIModalHeader>
    </template>

    <p class="text-sm leading-5 text-gray-700">
      {{ confirmation.message }}
    </p>

    <p
      v-if="confirmation.message2"
      class="mt-3 text-sm leading-5 text-gray-700"
      v-html="confirmation.message2"
    ></p>

    <template #footer>
      <UIModalFooter
        id="confirmation-footer"
        :positive-text="confirmation.okButton"
        :negative-text="$t('settings.calendar.cancel')"
        :type="confirmation.type"
        @positive-click="handleConfirm"
        @negative-click="handleCancel"
      />
    </template>
  </UIModal>
</template>

<script setup lang="ts">
import {
  AnnotationCheckIcon,
  Trash01Icon,
} from '@gohighlevel/ghl-icons/24/outline'
import { UIModal, UIModalFooter, UIModalHeader } from '@gohighlevel/ghl-ui'
import { ref } from 'vue'

const confirmation = ref<any>({
  show: false,
  title: '',
  message: '',
  message2: '',
  okButton: '',
  type: '',
})

const resolvePromise = ref<any>(undefined)
const rejectPromise = ref<any>(undefined)

const show = (opts: any = {}) => {
  confirmation.value.title = opts.title
  confirmation.value.message = opts.message
  confirmation.value.message2 = opts?.message2
  confirmation.value.okButton = opts.okButton
  confirmation.value.type = opts.type || 'primary'
  confirmation.value.show = true
  return new Promise((resolve, reject) => {
    resolvePromise.value = resolve
    rejectPromise.value = reject
  })
}

const handleConfirm = () => {
  confirmation.value.show = false
  resolvePromise.value(true)
}

const handleCancel = () => {
  confirmation.value.show = false
  resolvePromise.value(false)
}

defineExpose({
  show,
})
</script>
