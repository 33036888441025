<template>
    <div :class="theme" class="w-full relative shadow-sm rounded flex items-center">

        <span v-if="icon" class="iconify w-5 h-5 absolute ml-5 pointer-events-none text-gray-400" :data-icon="icon" :key="icon"></span>
        <div class="w-full">
        <input
            :type="type"
            :disabled="disabled"
            :readonly="readOnly"
            v-model="value"
            :autofocus="autofocus"
            :placeholder="placeholder"
            class="w-full rounded focus:ring-curious-blue-500 focus:border-curious-blue-500 border-gray-300 sm:text-sm"
            :class="{'pl-14' : icon, 'pl-3' : !icon}"
        />
        <div v-if="errorMsg"> {{errorMsg}} </div>
    </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { UserAddIcon } from "@heroicons/vue/outline";
import { UIIcon } from '@gohighlevel/ghl-canvas'

export default defineComponent({
    name: 'UITextInput',
    props: {
        modelValue: {
            type: [String, Number],
            default: '',
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'text',
        },
        error: {
            type: Boolean,
            default: false,
        },
        errorMsg: {
            type: String,
            default: '',
        },
        autofocus: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: '',
        },
        icon: {
            type: String,
            default: '',
        },
    },
    setup(props, { emit }) {
        const value = computed({
            get(): string | number {
                return props.modelValue
            },
            set(val: string | number) {
                emit('update:modelValue', val)
            },
        })

        const theme = computed(() => {
            const theme = new Set()
            if (props.disabled) theme.add('bg-gray-50  cursor-not-allowed')
            if (props.error)
                theme.add(
                    'border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red'
                )
            else theme.add('border-color-battleship focus:shadow-focus')
            return [...theme]
        })
        return {
            theme,
            value,
        }
    },
})
</script>
