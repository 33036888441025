<script setup lang="ts">
import AppState, {
  getRoomsInLocation,
  getTargetTimezone,
} from '@/states/app'
import {
  CalendarCheck01Icon,
  Pencil02Icon,
  PlusIcon,
  Toggle03LeftIcon,
  Toggle03RightIcon,
  Trash01Icon,
} from '@gohighlevel/ghl-icons/24/outline'
import {
  UIActionDropdown,
  UIEmpty,
  UITable,
  UITag,
  renderIcon,
  useNotification,
} from '@gohighlevel/ghl-ui'
import { h, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { ResourceList } from '../../class/pages/ResourceList'
import { CalendarResourceService } from '../../class/services/CalendarResourceService'
import {
  CALENDAR_RESOURCE_TYPES,
  CalendarResource,
} from '../../models/calendar_resource'
import CreateRoomModal from './CreateRoomModal.vue'

import DeleteConfirmation from '@/components/common/DeleteConfirmation.vue'
import GeneralConfirmation from '@/components/common/GeneralConfirmation.vue'
import moment from 'moment-timezone'

const { t } = useI18n()

const tableActionsOptionsForActiveRooms = [
  {
    label: t('settings.calendar.edit'),
    key: 'edit',
    icon: renderIcon(Pencil02Icon),
  },
  {
    label: t('settings.calendar.rooms_disable'),
    key: 'disable',
    icon: renderIcon(Toggle03RightIcon),
  },
  {
    label: t('settings.calendar.delete_group'),
    key: 'delete_room',
    icon: renderIcon(Trash01Icon),
  },
]

const tableActionsOptionsForDisabledRooms = [...tableActionsOptionsForActiveRooms]
tableActionsOptionsForDisabledRooms[1] = {
  label: t('settings.calendar.rooms_activate'),
  key: 'disable',
  icon: renderIcon(Toggle03LeftIcon),
}

const targetTimezone = getTargetTimezone()

const columns = [
  {
    title: t('settings.calendar.rooms_name'),
    key: 'name',
  },
  {
    title: t('settings.calendar.rooms_capacity'),
    key: 'capacity',
  },
  {
    title: t('settings.calendar.calendar_list'),
    key: 'calendars_tags',
    render(row) {
      const tags: any = []
      const calendarIds = row.calendarIds
      if (!calendarIds?.length) {
        return
      }
      const activeCalendars = calendarIds.filter(e => !roomList.isCalendarDeleted(e))
      for (let i = 0; i < 3; i++) {
        if (activeCalendars[i]) {
          tags.push(
            h(
              UITag,
              {
                type: 'info',
                id: activeCalendars[i],
              },
              roomList.getCalName(activeCalendars[i])
            )
          )
        }
      }
      if (activeCalendars.length > 3) {
        tags.push(
          h(
            UITag,
            {
              id: `count`,
            },
            `+${activeCalendars.length - 3}`
          )
        )
      }
      return tags
    }
  },
  {
    title: t('settings.calendar.status'),
    key: 'isActive',
    render(room) {
      return h(
        'span',
        {
          class: 'inline-flex rounded-full px-2 text-xs leading-5',
        },
        room.isActive
          ? t('settings.calendar.active')
          : t('settings.calendar.draft')
      )
    }
  },
  {
    title: t('settings.calendar.date_updated'),
    key: 'dateUpdated',
    render(room) {
      return h('div', [
        h(
          'h5',
          {
            class: 'm-0 text-sm font-normal	',
          },
          moment(room.updatedAt).tz(targetTimezone).format('MMM DD YYYY')
        ),
        h(
          'h6',
          {
            class: 'm-0 text-xs font-normal text-gray-300',
          },
          moment(room.updatedAt).tz(targetTimezone).format('hh mm A')
        ),
      ])
    },
  },
  {
    title: t('settings.calendar.action_dropdown'),
    key: 'actions',
    render(row) {
      return h(
        UIActionDropdown,
        {
          options: row.isActive
            ? tableActionsOptionsForActiveRooms.filter(
              x => x.key !== 'rearrange_calendars' || row.nosOfCalendars > 0
            )
            : tableActionsOptionsForDisabledRooms.filter(
              x => x.key !== 'rearrange_calendars' || row.nosOfCalendars > 0
            ),
          onSelect: val => dropDownActionChange(val, row),
          id: 'room-' + row._id,
        },
        {}
      )
    },
  },
]

const selectedRoom = ref()
const isProcessing = ref(false)
const showDeleteConfirmationModal = ref(false)
const showNewRoomModal = ref(false)
const notification = useNotification()
const roomList = new ResourceList(CALENDAR_RESOURCE_TYPES.ROOMS)
const showDisableConfirmationModal = ref(false)
const roomEditPayload = ref()

const dropDownActionChange = (action, room) => {
  selectedRoom.value = room
  if (action === 'edit') {
    openRoomsModal(room)
  } else if (action === 'disable') {
    showDisableConfirmationModal.value = true
  } else if (action === 'delete_room') {
    showDeleteConfirmationModal.value = true
  }
}

const closeModal = () => {
  showDisableConfirmationModal.value = false
  showNewRoomModal.value = false
  showDeleteConfirmationModal.value = false
  isProcessing.value = false
}

const openRoomsModal = (room: CalendarResource) => {
  showNewRoomModal.value = true
  roomEditPayload.value = room
}

const showInfoNotification = (message: string) => {
  notification.info({
    content: message,
    duration: 1 * 1000,
  })
}

// API calls start
const runPostApiCallsCleanUps = async () => {
  closeModal()
  await getRoomsInLocation()
}

const deleteRoom = async () => {
  if (!selectedRoom.value) {
    return
  }
  isProcessing.value = true
  await CalendarResourceService.delete(
    selectedRoom.value._id,
    CALENDAR_RESOURCE_TYPES.ROOMS
  )
  showInfoNotification(`Room deleted`)
  await runPostApiCallsCleanUps()
}

const disableRoom = async () => {
  if (!selectedRoom.value) {
    return
  }
  isProcessing.value = true
  await CalendarResourceService.update(
    selectedRoom.value._id,
    { isActive: !selectedRoom.value.isActive },
    CALENDAR_RESOURCE_TYPES.ROOMS
  )
  showInfoNotification(
    `Room ${selectedRoom.value.isActive ? 'Disabled' : 'Activated'}`
  )
  await runPostApiCallsCleanUps()
}
// API calls end
</script>
<template>
  <CreateRoomModal :show="showNewRoomModal" :edit-payload="roomEditPayload" @createdRecord="runPostApiCallsCleanUps"
    @closeModal="closeModal" />
  <UITable id="room-list-table" v-model:searchText="roomList.filters.value.searchText"
    :search-input-placeholder="$t('settings.calendar.room_name')" :columns="columns" :data="roomList.resourceList"
    :page-count="roomList.totalPages" :loading="AppState.isLoading || AppState.loadingAssets.rooms"
    @update:page="x => (roomList.filters.value.page = x)" :table-layout="'fixed'">
    <template #empty>
      <UIEmpty v-if="roomList.allResources.length === 0" id="empty-room-title"
        :title="$t('settings.calendar.no_rooms_in_location_title')"
        :description="$t('settings.calendar.no_rooms_in_location')"
        :positive-text="$t('settings.calendar.add_new_calendar_rooms')" :icon="CalendarCheck01Icon"
        :positive-icon="PlusIcon" @positiveClick="openRoomsModal">
      </UIEmpty>

      <UIEmpty v-else id="empty-room-description" :title="$t('settings.calendar.no_rooms_in_location_title')"
        :description="$t('settings.calendar.no_rooms_found')">
      </UIEmpty>
    </template>
  </UITable>
  <DeleteConfirmation v-model:show="showDeleteConfirmationModal" :disabled-confirm="isProcessing"
    :header-title="$t('settings.calendar.delete_this_rooms')" @onConfirm="deleteRoom" @onModalClose="closeModal">
    <template #message>
      <p class="text-sm leading-5 text-gray-500">
        {{ $t('settings.calendar.delete_rooms_confirmation') }}
      </p>
    </template>
  </DeleteConfirmation>

  <GeneralConfirmation v-model:show="showDisableConfirmationModal" :disabled-confirm="isProcessing" :header-title="$t('settings.calendar.disable_room_title', {
    status: selectedRoom?.isActive ? 'Disable' : 'Activate'
  })" @onConfirm="disableRoom" @onModalClose="closeModal">
    <template #message>
      <p class="text-sm leading-5 text-gray-500">
        {{
          t('settings.calendar.rooms_status_message', {
            status: selectedRoom?.isActive ? 'Disable' : 'Activate',
          })
        }}
      </p>
      <p class="text-sm leading-5 text-gray-500">
        {{ t('settings.calendar.calendar_resource_disclaimer') }}
      </p>
      <br>
      <p v-if="selectedRoom?.isActive" class="text-sm leading-5 text-gray-500">
        {{ t('settings.calendar.room_disclaimer') }}
      </p>
    </template>
  </GeneralConfirmation>
</template>
