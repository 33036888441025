{
  "settings": {
    "calendar": {
      "calendar_settings": "Kalenderindstillinger",
      "group": "Gruppe",
      "manage_your_calendars_and_groups": "Administrer dine kalendere og grupper",
      "groups": "Grupper",
      "create_group": "Opret Gruppe",
      "create_calendar": "Opret Kalender",
      "calendar_name": "Kalender Navn",
      "group_duration": "Gruppens Varighed",
      "calendar_type": "Kalendertype",
      "action": "Handling",
      "round_robin": "Round Robin",
      "event": "Begivenhed",
      "group_name": "Gruppe Navn",
      "calendars": "Kalendere",
      "date_updated": "Dato Opdateret",
      "status": "Status",
      "action_dropdown": "Handling Dropdown",
      "edit": "Ret",
      "duplicate": "Dupliker",
      "copy_embed_code": "Kopiér indlejringskode",
      "copy_permanent_link": "Kopiér Permanent Link",
      "copy_new_slug_link": "Kopiér planlægningslink",
      "copy_scheduling_link": "Kopiér planlægningslink (forældet forældet)",
      "deactivate_calendar": "Deaktiver kalender",
      "move_to_group": "Flyt Til Gruppe",
      "delete_calendar": "Slet Kalender",
      "delete_group": "Slet",
      "delete_this_group": "Slet denne gruppe",
      "activate_calendar": "Aktiver Kalender",
      "duration": "Varighed (min)",
      "all": "Alle",
      "draft": "Udkast",
      "active": "Aktiv",
      "rearrange_calendars": "Omarranger kalendere",
      "deactivate_all_calendars_in_group": "Deaktiver alle kalendere i gruppen",
      "activate_all_calendars_in_group": "Aktiver alle kalendere i gruppen",
      "no_groups_found": "Din søgning matchede ikke nogen kalendergrupper.",
      "no_groups_in_location": "Kom i gang med at bruge gruppen ved at oprette en ny kalendergruppe.",
      "no_calendars_found": "Kom godt i gang med at bruge kalenderen ved at oprette en ny kalender.",
      "no_calendars_found_search": "Din søgning matchede ikke nogen kalendere.",
      "delete_calendar_message": "Du er ved at slette Kalender. Når du trykker på slet, vil det også slette alle sine aftaler.",
      "delete_calendar_message_title": "Vil du slette denne kalender?",
      "no_calendars_found_title": "Ingen kalendere fundet",
      "no_groups_in_location_title": "Ingen grupper fundet",
      "delete_group_confirmation_title": "Vil du slette denne gruppe?",
      "delete_group_confirmation": "Er du sikker på, at du ønsker at slette den valgte gruppe?",
      "delete_group_confirmation_checkbox_title": "Slet tilknyttede kalendere og aftaler",
      "delete_group_confirmation_checkbox_description": "Valg af denne indstilling vil også slette kalendere i denne gruppe sammen med alle aftaler i disse kalendere",
      "group_status_message_title": "{status} denne gruppe?",
      "calendar_status_message_title": "{status} denne kalender?",
      "calendar_status_message": "Du er ved at {status} kalender",
      "group_status_message": "Du er ved at {status} gruppe",
      "select_calendar_type": "Vælg kalendertype",
      "add_new_calendar_group": "Tilføj ny kalendergruppe",
      "new_calendar": "Ny kalender",
      "calendar_group_description": "Tilføj kalendergrupper for at gruppere flere kalendere sammen.",
      "group_description": "Gruppebeskrivelse",
      "calendar_url": "Kalender URL",
      "coming_soon": "Kommer snart",
      "select": "Vælg",
      "15mins": "15 minutter",
      "cancel": "Annuller",
      "save": "Gem",
      "create": "Opret",
      "select_calendar_group": "Vælg kalendergruppe",
      "move_calendar_confirmation": "Bemærk, at Legacy Link ville stoppe med at arbejde, når du fortsætter med ændringen. Bekræft venligst ved at vælge afkrydsningsfeltet.",
      "service_menus": "Service Menu",
      "manage_your_calendars_groups_and_service_menus": "Administrer dine kalendere, grupper og servicemenu",
      "new_service_menu": "Nyt service-menu",
      "deactivate_service_menu": "Deaktiver tjenestemenuen",
      "delete_service_menu": "Slet denne service menu",
      "delete_service_menu_confirmation": "Er du sikker på, at du vil slette denne servicemenu?",
      "service_menu_status_message_title": "{status} denne Servicemenu?",
      "service_menu_status_message": "Du er ved at {status} Service Menuen",
      "no_service_menus_in_location_title": "Opret din tilpassede servicedatabase",
      "add_new_service_menu": "Tilføj nyt service-menu",
      "no_service_menus_in_location": "Start med at oprette en ny service-menu og tilføj grupper til den",
      "no_service_menu_found": "Ingen service-menu fundet",
      "no_service_menu_found_desc": "Din søgning gav ingen matchende servicemenuer",
      "share_otl": "Del din tilgængelighed hver gang med et unikt link, der udløber efter en booking, hvilket sikrer kontrolleret adgang",
      "generate_link": "Generér nyt link",
      "share_schedule_link": "Planlægningslinket bestemmes af slug'en. Justér slug'en, og planlægningslinket tilpasses automatisk ændringen",
      "share_permanent_link": "Ideel til tragt, websideredirections eller annoncer, forbliver det permanente link konstant, upåvirket af slug-ændringer",
      "copy": "Kopi",
      "share_embed_code": "Placer denne kode i din HTML, hvor du vil have din {embedType} widget til at vises",
      "scheduling_link": "Planlægning Link",
      "permanent_link": "Permanent Link",
      "one_time_link": "Engangslink",
      "embed_code": "Integreringskode",
      "loading": "Indlæser...",
      "round_robin_booking": "Rundt-Robin",
      "round_robin_description": "Fordeler aftaler blandt holdmedlemmer i en roterende orden",
      "round_robin_example": "F.eks.: Salgsopkald, onboarding sessioner",
      "unassigned_booking": "Begivenhedskalender",
      "unassigned_description": "Til planlægning af fysiske arrangementer uden værtsforening",
      "unassigned_example": "F.eks.: Konferencer, offentlige seminarer, eksponeringer",
      "class_booking": "Klassebooking",
      "class_booking_description": "En vært mødes med flere deltagere",
      "class_booking_example": "F.eks.: Webinarer, gruppetræning, online holdundervisning",
      "collective_booking": "Fælles booking",
      "collective_booking_description": "Flere værter mødes med en deltager",
      "collective_booking_example": "F.eks.: Panelinterviews, komitevurderinger",
      "service_booking": "Servicebooking",
      "service_booking_description": "Problemfri planlægning for servicebaserede virksomheder",
      "service_booking_example": "Planlægger en-til-en møder med et bestemt teammedlem",
      "personal_booking": "Personlig booking",
      "personal_booking_description": "Planlægger en-til-en møder med et bestemt teammedlem",
      "personal_booking_example": "F.eks.: Klientmøder, private konsultationer",
      "personal": "Personlig",
      "explore_more_types": "Udforsk flere typer",
      "scheduling_type": "Planlægningstype",
      "scheduling_type_description": "Vælg en planlægningstype til din nye kalender"
    },
    "preferences": {
      "preferences": "Præferencer",
      "my_preferences": "Min præference",
      "account_preferences": "Kontopræference",
      "user_preferences": "Brugerpræferencer",
      "set_preferences": "Indstil dine præferencer for din konto.",
      "scheduling_options": "Planlægningsmuligheder",
      "meeting_location": "Mødested",
      "zoom": "Zoom",
      "save_preferences": "Gem Præferencer",
      "in_app_preferences": "I App-præferencer",
      "set_start_day": "Indstil præferencer som startdag for appen",
      "view_options": "Vis indstillinger",
      "week_starts_on": "Ugen starter på",
      "sunday": "Søndag",
      "widget_preferences": "Widget-præferencer",
      "set_widget_language": "Indstil præferencer som sprog for widget",
      "language_and_region": "Sprog og region",
      "language": "Sprog",
      "english": "Engelsk",
      "time_format": "Tids Format",
      "time_format_value": "13:30",
      "monday": "Mandag",
      "custom_meeting_location": "Tilpasset Mødested"
    },
    "availability": {
      "availability": "Tilgængelighed",
      "my_availability": "Min Tilgængelighed",
      "account_availability": "Kontotilgængelighed",
      "timezone": "Tidszone",
      "working_hours": "Arbejdstimer",
      "set_recurring_hours": "Indstil dine tilbagevendende arbejdstider for mødet",
      "sunday": "Søndag",
      "monday": "Mandag",
      "tuesday": "Tirsdag",
      "wednesday": "Onsdag",
      "thursday": "Torsdag",
      "friday": "Fredag",
      "saturday": "Lørdag",
      "widget_preferences": "Widget præferencer",
      "set_widget_language": "Indstil præferencer som sprog for widget",
      "language_and_region": "Sprog og region",
      "language": "Sprog",
      "english": "Engelsk",
      "copy_to_all": "Kopier til alle",
      "save_availability": "Gem tilgængeligheden",
      "add_time": "Tilføj tid",
      "select_time": "Vælg tid"
    },
    "connections": {
      "connections": "Forbindelser",
      "conferencing": "Konferencer",
      "my_connections": "Mine forbindelser",
      "account_connections": "Kontoforbindelser",
      "connection_preferences": "Forbindelsespræferencer",
      "manage_calendars_conferencing_channels": "Administrer kalendere og konferencekanaler",
      "main_integration_calendar": "Hovedintegrationskalender",
      "event_integration_calendar": "Begivenheder oprettet på en systemkalender, som du er en del af, vil også blive oprettet på denne integrerede kalender",
      "2way_sync": "2-vejs synkronisering: Opret systembegivenheder fra begivenheder, der er booket på den integrerede hovedkalender",
      "calendars": "Kalendere",
      "google": "Google",
      "outlook": "Outlook",
      "check_for_conflicts": "Tjek for konflikter",
      "choose_accounts_to_connect": "Vælg hvilke konti du ønsker at forbinde",
      "payments": "Betalinger",
      "stripe": "Stripe",
      "ads": "Ads",
      "local_service_ads": "Lokale Service Ads",
      "fire_workflow": "Fire Workflow-udløsere til Hændelser booket via 2-vejs synkronisering",
      "connect_google_outlook": "Tilslut venligst din Google- eller Outlook-konto for at kunne vælge den primære kalender.",
      "execute_automation": "Udfør automatisering",
      "save": "Gem",
      "lsa": "Lokale Service Ads",
      "connect": "Opret forbindelse",
      "no_write_access": "Du skriver ikke adgang til denne kalender",
      "connection_remove_confirmation": "Du er ved at fjerne denne integration",
      "active": "Aktiv"
    }
  },
  "appointment_modal": {
    "select_a_contact": "Vælg en Kontakt",
    "start_time": "Start Tid",
    "end_time": "Slut Tid",
    "search_by_name_email_phone_or_company": "Søg på navn, e-mail, telefon eller virksomhed",
    "add_new": "Tilføj Ny",
    "pick_from_available_contacts": "Eller vælg blandt tilgængelige kontakter",
    "book_appointment": "Book Aftale",
    "calendar": "Kalender",
    "repeat": "Gentag",
    "date_and_time": "Dato og Tid",
    "close": "Luk",
    "team_member": "Medarbejder",
    "recurring_booking": "Tilbagevendende booking",
    "one_slot_booking": "Et Slot Booking",
    "event_type": "Begivenhedstype",
    "occurrences_booked": "hændelser vil blive booket",
    "repeats": "Gentager",
    "booking_details": "Booking detaljer",
    "showing_slots_in_this_timezone": "Viser slots i denne tidszone:",
    "update_contact_tz": "Opdater også kontakttidszone til",
    "date": "Dato",
    "slot": "Slot",
    "calendar_default": "Kalender Standard",
    "custom": "Brugerdefineret",
    "meeting_notes": "(f.eks.) Mødenotater til vores kommende aftale",
    "out_of_office": "(f.eks.) Ikke på kontoret i en uge!",
    "standard": "Standart",
    "appointment_title": "Aftale Titel",
    "event_title": "Begivenhedens Titel",
    "appt_with_bob": "(f.eks.) Aftale med Bob",
    "vacation": "(f.eks.) Ferie",
    "show_notes": "Vis Noter",
    "hide_notes": "Skjul Noter",
    "additional_preferences": "Yderligere Præferencer",
    "meeting_location": "Mødested",
    "appointment_status": "Aftale Status",
    "appt_description": "Aftale Beskrivelse",
    "recurring_details": "Tilbagevendende Detaljer",
    "event_description": "Begivenhedsbeskrivelse",
    "in_contact_timezone": "I kontaktens tidszone",
    "vacation_message": "Skal på ferie? Tager du noget fri? Bloker tid på din kalender for at forhindre kunder i at booke aftaler, eksisterende aftaler forbliver stadig i din kalender).",
    "contact": "Kontakt",
    "delete": "Slet",
    "cancel": "Annuler",
    "back": "Tilbage",
    "pick_available_contacts": "Eller vælg blandt tilgængelige kontakter",
    "no_contacts_found": "Ingen kontakter fundet",
    "found": "fundet",
    "select_one": "Vælg En",
    "tz_tooltip": "Dette har kun betydning, hvis du bruger brugerdefinerede værdier; såsom appointment_start_time appointment_end_time appointment_timezone osv.. i din udgående kommunikation med dine kunder eller kunder. Og hvad det blot gør, er at konvertere de førnævnte detaljer til deres lokale tid.",
    "meeting_location_tooltip": "Mødestedet vil blive indstillet som konfigureret i kalenderen.",
    "appt_meeting_location_tooltip": "Brugeren kan indstille mødestedet specifikt for aftalen.",
    "gmeet_tooltip": "Sørg for, at det respektive medarbejder har en Google-kalender indstillet som deres primære kalender",
    "zoom_tooltip": "Sørg for, at det respektive medarbejder har Zoom integreret",
    "enter_meeting_location": "Indtast Mødested",
    "save_appointment": "Gem Aftale",
    "save_event": "Gem Begivenhed",
    "search_contacts": "Søg på navn, e-mail, telefon eller virksomhed",
    "recurring_slots": "{recurringSlotsLength}/{count}",
    "account_timezone": "Konto Tidszone",
    "an_error_occurred": "Der opstod en fejl",
    "contact_timezone": "Kontakt Tidszone",
    "custom_timezone": "Brugerdefineret Tidszone",
    "system_timezone": "System Tidszone",
    "unassigned": "Ikke tildelt",
    "recommended_timezones": "Anbefalede Tidszoner",
    "all_timezones": "Alle Tidszoner",
    "add_blocked_off_time": "Tilføj Blokeret Tid"
  },
  "popup": {
    "calendar": "Kalender",
    "appointment_owner": "Aftale Ejer",
    "source": "Kilde",
    "booked_by": "Booket Af",
    "view_activity_log": "Se Aktivitetslog",
    "status": "Status",
    "cancel_appointment": "Annuler Aftale",
    "google_event": "Google-begivenhed",
    "outlook_event": "Outlook-begivenhed",
    "blocked_slot": "Afspærret slot",
    "appointment": "Aftale",
    "confirmed": "Bekræftet",
    "unconfirmed": "Ubekræftet",
    "cancelled": "Aflyst",
    "showed": "Fremmødt",
    "no_show": "Ikke Fremmødt",
    "invalid": "Ugyldig",
    "cancel_all": "Annuller Alle",
    "edit": "Rediger",
    "less": "Mindre",
    "more": "Mere",
    "reschedule_all": "Genplanlæg Alle",
    "guests": "Gæster",
    "guest_count": "Gæster tæller"
  },
  "activity_log": {
    "activity_log": "Aktivitetslog",
    "date": "Dato",
    "filter_by": "Filtrér efter",
    "calendar_update": "Opdater kalender",
    "start_time_update": "Opdater starttidspunkt",
    "end_time_update": "Opdater sluttidspunkt",
    "title_update": "Opdater titel",
    "notes_update": "Opdater noter",
    "appointment_status_update": "Opdater status for aftale",
    "meeting_location_update": "Opdater mødested",
    "google_sync_update": "Opdater Google-synkronisering",
    "outlook_sync_update": "Opdater Outlook-synkronisering",
    "assigned_user_update": "Opdater tildelt bruger",
    "appointment_status": "Aftalestatus",
    "assigned_user": "Tildelt bruger",
    "uploaded_to": "Uploadet til",
    "cancelled": "Aflyst",
    "edited": "redigeret",
    "other_edits": "Andre ændringer",
    "view_details": "Vis detaljer",
    "all": "Alle"
  },
  "appointment_list": {
    "group_all": "alle",
    "group_primary": "PRIMÆR",
    "group_user": "Brugere",
    "group_team": "GRUPPER",
    "group_calendar": "KALENDERE",
    "all_appointments": "Alle aftaler",
    "my_calendar": "Min kalender",
    "status_all": "Alle",
    "status_confirmed": "Bekræftet",
    "status_new": "Ny (Handling påkrævet)",
    "status_show": "Viste",
    "status_no_show": "Ingen fremmøde",
    "status_cancelled": "Afbestilt",
    "status_invalid": "ugyldig",
    "filter_date_added_desc": "Dato tilføjet (nedadgående)",
    "filter_start_added_desc": "Startdato (Ned)",
    "filter_start_added_asc": "Startdato stigende fra i dag",
    "button_new_appointment": "Ny udnævnelse",
    "name": "Navn",
    "title": "Titel",
    "requested_time": "Efterspurgt tid",
    "date_added": "Dato tilføjet",
    "calendar": "Kalender",
    "appointment_owner": "Aftaleindehaver",
    "notes": "Noter",
    "status": "Status",
    "action_view_detail": "Se detaljer",
    "action_view_notes": "Vis noter",
    "action_edit": "Rediger",
    "action_delete": "Slet",
    "action_reschedule": "Omlægning.",
    "page_previous": "Tidligere",
    "page_next": "Næste",
    "appointment_notes": "Aftale-notater",
    "close": "Lukket",
    "confirm_delete_header": "Slet aftale",
    "confirm_delete_message": "Er du sikker på,at du vil slette denne aftale?",
    "confirm_delete_button": "Slet",
    "confirm_update_header": "Opdater aftalestatus.",
    "confirm_update_confirmed_message": "Er du sikker på, at du vil markere denne aftale som bekræftet?",
    "confirm_update_invalid_message": "Er du sikker på, at du vil markere denne aftale som ugyldig?",
    "confirm_update_showed_message": "Er du sikker på, at du vil markere denne aftale som afholdt?",
    "confirm_update_noshow_message": "Er du sikker på, at du vil markere denne aftale som 'ikke fremmødt'?",
    "confirm_update_cancelled_message": "Er du sikker på, at du vil markere denne aftale som aflyst?",
    "confirm_update_button": "Opdatering",
    "empty_state_title": "Ingen aftaler fundet",
    "empty_state_description": "Ingen aftaler fundet",
    "disable_menu_warning": "For øjeblikket har du ikke tilladelse til at se eller redigere oplysningerne om aftalen, da den tilknyttede kontakt ikke er tildelt dig. Venligst kontakt din administrator for yderligere assistance",
    "no_contact_warning": "Du har ikke øjeblikkeligt adgang til kontaktoplysningerne. Dette kan skyldes, at kontakten ikke er tildelt til dig. Kontakt venligst din administrator for hjælp",
    "youre_viewing_in": "Currently viewing in",
    "rescheduled": "Omlagt"
  },
  "create_calendar": {
    "calendar_name": "Kalendernavn",
    "calendar_logo": "Kalenderlogo",
    "select_team_member": "Vælg holdmedlemmer",
    "eg_outbound_reach": "(eg) Udgående rækkevidde",
    "description": "Beskrivelse",
    "amount": "Beløb",
    "description_placeholder": "Skriv beskrivelse",
    "meeting_duration": "Mødeduration",
    "seats_per_class": "Sæder per klasse",
    "group": "Gruppe",
    "custom_url": "Brugerdefineret internetadresse",
    "meeting_invite_title": "Mødeinvitationstitel",
    "event_color": "Begivenhedsfarve",
    "availability_type": "Tilgængelighedstype",
    "deposit_amount": "Indsætningsbeløb",
    "collect_deposit_amount": "Accepter delvis betaling",
    "percentage": "Procentandel",
    "total_amount": "Samlet beløb",
    "reschedule_preference": "Omlæg præference",
    "reschedule_preference_subtext": "Hvis en kontaktperson ændrer tidspunktet for en aftale:",
    "reassign_through_round_robin": "Omfordel gennem round robin",
    "keep_same_assinged_user": "Behold den samme aftaleejer",
    "new_appointment_preference": "Ny udnævnelsespræference",
    "new_appointment_preference_text": "Book altid med den tildelte brugers kontakt",
    "new_appointment_preference_subtext": "Bestil nye aftaler med den tildelte bruger til kontakten i stedet for at bruge round-robin-planlægning"
  },
  "calendar_advanced": {
    "general": {
      "quick_tip": "Hurtig Tip",
      "timezone_change_tip": "Tidszoner kan ændres når som helst under indstillinger for tidszoner",
      "save": "Gem",
      "close": "Tæt",
      "meeting_details": "Mødedetaljer",
      "availability": "Tilgængelighed",
      "forms_and_payment": "Formularer og betaling",
      "notifications_and_additional_options": "Meddelelser og yderligere muligheder",
      "connections": "Forbindelser",
      "customizations": "Tilpasninger",
      "create": "Opret",
      "edit": "Redigér"
    },
    "meeting_details": {
      "meeting_location": "Mødested",
      "enter_meeting_location": "Indtast mødestedet",
      "click_to_upload": "Klik for at uploade",
      "square": "Firkant",
      "circle": "Cirkel",
      "remove_logo": "Fjern logo",
      "meeting_invite_title_tooltip": "Dette er titlen på kalenderbegivenheden, som vises i Google Calendar, Outlook, Apple Calendar osv",
      "event_color_tooltip": "Vælg en farve til denne booking begivenhed. Farverne synkroniseres med begivenheder i Google Kalendere",
      "enter_a_name": "Indtast venligst et navn",
      "enter_an_event_title": "Indtast venligst en arrangementstitel",
      "high_priority": "Høj prioritet",
      "medium_priority": "Middel prioritet",
      "low_priority": "Lav prioritet",
      "custom": "Tilpasset",
      "zoom_not_added": "Brugeren har endnu ikke oprettet forbindelse til deres Zoom-konto",
      "opt_avail": "Optimer for tilgængelighed",
      "opt_equal": "Optimer for lige fordeling",
      "team_members": "Vælg teammedlemmer",
      "team_member": "Vælg holdmedlem",
      "meeting_location_required": "Mødested er påkrævet",
      "team_member_disabled_recurring_tooltip": "Gentagne møder tillader kun én holdmedlem. For at tilføje flere holdmedlemmer, skal gentagne møder deaktiveres.",
      "team_member_disabled_class_booking_tooltip": "Kun ét holdmedlem kan tilføjes til en bookingskalender for et hold.",
      "enable_contact_assigned_to_setting": "For at aktivere denne indstilling skal formularen være den første i widget-rækkefølgen. Venligst omorganiser widget-rækkefølgen fra Formularer og betaling fanen",
      "reschedule_preference_setting_tooltip": "Vælg, hvem aftalen skal tildeles, når en kontakt ændrer tidspunkt fra booking-widgetten",
      "appointment_preference_tooltip": "Vælg, om nye aftaler altid skal bookes med kontaktpersonens tildelte bruger. Hvis en kontaktperson har en tildelt bruger, får denne bruger prioritet; ellers vil der være en round-robin tildeling. Tip: Slå 'Tillad Valg af Personale' fra for at forhindre bookere i at ændre personale på booking-widgetten",
      "add_location": "Tilføj placering",
      "multiple_locations_neo": "Flere mødesteder understøttes kun af Neo-widgeten med standardformularen",
      "make_owner": "Gør ejer",
      "only_one_team_member": "Kun ét holdmedlem er tilladt, når der tilføjes flere placeringer",
      "only_one_team_member_personal_calendar": "Kun ét holdmedlem er tilladt for personlig booking",
      "ms_teams_not_added": "Brugeren har endnu ikke tilsluttet deres Microsoft Teams-konto"
    },
    "availability": {
      "my_availability": "Min tilgængelighed",
      "set_availability": "Indstil din tilgængelighed for kalenderen her",
      "standard": "Standard",
      "custom": "Brugerdefineret",
      "to": "Til",
      "copy_to_all": "Kopier til alle",
      "add_time": "Tilføj tid",
      "recurring_meeting": "Gentagen møde",
      "recurring_info": "Kun én bruger per kalender til gentagende bookinger",
      "repeat": "Gentag",
      "times_to_repeat": "Gange til at gentage",
      "slots_unavailable": "Hvis der ikke er nogen ledige pladser?",
      "overlap_status": "Status for aftaler for overlappende tidsrum",
      "meeting_interval": "Mødeinterval",
      "meeting_duration": "Mødeduration",
      "minimum_scheduling": "Minimum varslingsfrist",
      "date_range": "Datointerval",
      "maximum_bookings_per_day": "Maksimalt antal reservationer pr. dag",
      "maximum_bookings_per_slot": "Maksimale bookinger pr. slot",
      "maximum_bookings_per_slot_per_user": "Højstbooking per tidsinterval (pr. bruger)",
      "seats_per_class": "Sæder per klasse",
      "pre_buffer_time": "Forhåndsbuffer tid",
      "post_buffer_time": "Eftersendingsbuffer tid.",
      "pre_buffer_tooltip": "Forberedelsestiden er ekstra tid, der kan tilføjes før en aftale, hvilket giver ekstra tid til at gøre sig klar før ethvert møde",
      "post_buffer_tooltip": "Post-Buffer er ekstra tid, der kan tilføjes efter en aftale, hvilket giver ekstra tid til at afslutte",
      "buffer_time": "Buffer tid",
      "daily": "daglig",
      "weekly": "Ugentlig",
      "monthly": "Månedlig",
      "skip_booking": "Spring overbooking af uledige tider over",
      "continue_booking": "Fortsæt booking",
      "book_next_available": "Reservér næste tilgængelige tidsslot",
      "confirmed": "Bekræftet",
      "unconfirmed": "Ukendt",
      "enter_valid_hours": "Indtast venligst gyldige åbningstider",
      "enter_valid_avail": "Indtast venligst gyldig brugerdefineret tilgængelighed",
      "availability_type_tooltip": "Vælg standard tilgængelighed for normale bookinger tilbudt ugentligt. Vælg tilpasset til bookinger, der kun er tilgængelige på specifikke datoer",
      "meeting_interval_tooltip": "Mødeintervallet afspejler den mængde tid, der ligger mellem bookede tidsslots, som vil blive vist i kalenderen. For en begivenhed på 30 minutter, der skal være tilgængelig hver hele time, vil intervallet være 1 time. For en begivenhed på 30 minutter, der skal være tilgængelig hver 30 minutter, vil intervallet være 30 minutter",
      "date_range_tooltip": "Begivenheder kan planlægges i løbet af de næste n dage",
      "maximum_bookings_per_day_tooltip": "Maksimum antal aftaler tilladt per dag på denne kalender",
      "maximum_bookings_per_slot_tooltip": "Maksimalt antal tilladte aftaler pr. tidsrum for brugeren (Hvis antallet af aftaler overstiger det tilladte antal for det givne tidsrum for brugeren, skifter det enten over til den næste bruger i den round-robin kø eller markere tidsrummet som utilgængeligt for udsigten til at booke)",
      "add_days": "Tilføj dage",
      "month_on_day": "Månedlig på dag",
      "month_on_last_day": "Månedligt den sidste dag",
      "day": "Dag",
      "week": "uge",
      "month": "Måned",
      "mins": "Minutter",
      "hours": "Timer",
      "days": "Dage",
      "weeks": "Uger",
      "months": "Måneder",
      "meeting_interval_error": "Mødeintervallet skal være mellem 5 minutter og 12 timer",
      "meeting_duration_error": "Mødedurationen skal være mellem 1 minut og 12 timer",
      "buffer_time_error": "Buffer tid skal være mindre end 12 timer",
      "subTitle": "Vælg datoen for at fastlægge specifikke timer",
      "heading_1": "Ugentlige tilgængelige timer",
      "tooltip_1": "Angiv din ugentlige tilgængelighedsplan. Dette er dine normale timer, der vil blive anvendt konsekvent på ugentlig basis",
      "heading_2": "Når er du tilgængelig?",
      "unavailable_label": "Utilgængelig",
      "tooltip_2": "Juster din tidsplan for specifikke datoer. Overskriv dine almindelige ugentlige timer ved at markere tilgængelighed eller utilgængelighed for udvalgte datoer",
      "date_specific_level": "Tilføj dato specifikke timer",
      "specific_hours_button": "Dato Specifikke timer",
      "overlap_error": "Tidsintervaller må ikke overlappe",
      "apply": "Anvend",
      "copy_times_to": "Kopier tider til",
      "no_date_specific_heading": "Ingen dato bestemt tid tilføjet",
      "no_date_specific_description": "Du kan tilføje/fjerne en bestemt dato og tid til din tilgængelighed",
      "recurring_disabled_conditions_tooltip": "Gentagne møder er deaktiveret. For at aktivere dem, skal du sørge for, at kalenderen kun har én holdmedlem, og at der ikke er tilføjet dato-specifikke timer",
      "date_specific_hours_disabled_conditions_tooltip": "For at inkludere en dato-specifik time, skal du sørge for, at gentagne møder er slået fra",
      "recurring_disable_event_calendar_conditions": "Gentagne møder er deaktiveret. For at aktivere dem og tilføje ingen dato-specifikke timer",
      "service_interval": "Service interval",
      "service_interval_tooltip": "Mødeintervallet angiver, hvor ofte booking-slots vises på kalenderen. For eksempel, for et arrangement på 30 minutter, betyder et interval på en time, at der vises slots hver time. Et interval på 30 minutter betyder, at der vises slots hver 30. minut",
      "service_duration_tooltip": "Dette bestemmer længden på din aftale og afgør, hvor længe den varer",
      "minimum_scheduling_tooltip": "Dette angiver den mindste tid, der kræves for at booke en aftale via kalenderwidgeten. For eksempel, hvis den er indstillet til 4 timer, kan aftaler ikke bookes inden for 4 timer før deres starttid; bookinger skal foretages mindst 4 timer i forvejen",
      "service_duration": "Service duration",
      "service_menus": "Service Menu",
      "rooms": "Værelser",
      "equipments": "Udstyr",
      "look_busy_title": "Lav om at være travlt optaget",
      "look_busy_percentage_description": "Skjul antallet af tilgængelige pladser med x%",
      "look_busy_helper_text": "Skjul en procentdel af dine tilgængelige aftaleblokke på booking-widgeten for at fremstå travl og efterspurgt",
      "maximum_bookings_per_slot_tooltip_personal": "Maksimalt antal tilladte aftaler pr. slot for brugeren"
    },
    "forms_payment": {
      "forms": "Skemaer",
      "forms_sub": "Angiv formindstillinger for tidsplanlægning af opslag",
      "select_form": "Vælg formularen",
      "sticky_contacts": "Forududfyld felter (vedhæftede kontakter)",
      "confirmation_page": "Bekræftelsesside",
      "default": "Standard",
      "redirect_url": "Omdirigerings-URL",
      "thank_you": "Takkebesked",
      "enter_url": "Angiv URL",
      "pixel_id": "Facebook pixel ID (valgfrit)",
      "auto_confirm": "Automatisk bekræftelse af nye møder på kalenderen",
      "configure_payment": "Konfigurer betalingsindstillinger",
      "accept_payments": "Accepter betalinger",
      "test": "Test",
      "live": "Levende",
      "enable_guests": "Tilføj gæster",
      "add_guest_tooltip": "Ved at tilføje et gæst kan din deltager tilføje flere deltagere til samme aftale",
      "add_guest_same_form_check": "Send samme formular via post for at indsamle oplysninger fra alle gæster",
      "payment_tooltip": "Betalinger ville kun blive overvejet for hoveddeltageren og ikke for gæsterne",
      "default_form": "Standard (Fornavn, Efternavn, E-mail, Telefon, Noter)",
      "select_form_tooltip": "Hvis du ønsker at indsamle mere information end de standardoplysninger om Fornavn, Efternavn, E-mail og Telefon fra dem, der booker på denne kalender, skal du oprette en brugerdefineret formular i Sites > Forms > Builder og derefter vælge formularen via rullemenuen nedenfor",
      "confirmation_page_tooltip": "Når nogen laver en reservation, kan du vælge at vise dem en tilpasset besked på samme side (standard) eller omdirigere dem til en side efter eget valg (omdirigering)",
      "add_guest_collect_email": "Med navn og e-mail",
      "add_guest_count_only": "Kun tæl",
      "custom_email_notification": "Send tilpasset formular til gæsterne",
      "deposit_amount_error": "Indskuddet skal være mindre end det samlede beløb",
      "deposit_percentage_error": "Indskudsprocenten skal være mindre end 100%",
      "partial_payment_tooltip": "Muliggør delvise betalinger for at indsamle depositum fra kunder under aftalebogens proces",
      "partial_payment_enable_error": "Det samlede beløb indtastet skal være større end 0 for at muliggøre delvise betalinger",
      "payment_disable_text_integration": "Integrer en betalingsudbyder under Betalinger > Integrationer for at begynde at acceptere betalinger",
      "payment_disable_text_recurring": "Accepter betalingsmulighed er ikke tilgængelig for gentagne møder. Slå gentagelser fra for at muliggøre betalinger",
      "date_and_time_selector": "Dato- og tidsvælger",
      "form_selector": "Formulær",
      "order_step": "Trin",
      "widget_order_label": "Widget rækkefølge",
      "widget_order_tool_tip": "Omarranger 'Dato & Tid Selector' og 'Form' rækkefølgen på kalenderwidgetten. Træk simpelthen og omarranger efter din præference",
      "same_user_assignment_tooltip": "For at aktivere denne indstilling skal formularen være den første på widgetten. Omarrangér widgetten så formularen er placeret først for at aktivere denne indstilling",
      "widget_re_order_tooltip": "Omlægning er kun tilgængelig med Neo-widget-stilen. Skift til Neo for at aktivere omarrangering",
      "widget_re_order_disabled_tooltip": "For at omarrangere widget-rækkefølgen, skal du deaktivere 'Ny aftalepræference (Altid book med kontaktpersonens tildelte bruger)' fra Mødedetaljer > Teammedlemmer > Avancerede indstillinger afsnittet",
      "enable_same_user_assignment": "Tildel kontakter til deres respektive kalenderteammedlemmer hver gang en aftale bookes",
      "recurring_payment_label": "Gentagne aftaler betaling",
      "first_appointment_only": "Kun første aftale",
      "all_appointments": "Alle aftaler"
    },
    "notifications": {
      "notifications": "Notifikationer og yderligere indstillinger",
      "notifications_sub": "Konfigurer notifikation og ekstra muligheder",
      "select_notification_type": "Vælg typen af notifikation",
      "notification_person": "Hvem skal modtage denne meddelelse?",
      "contact": "Kontakt",
      "assigned_user": "Tildelt bruger",
      "emails": "E-mails",
      "alert_email_address": "Advarsels-e-mail-adresse",
      "ack_email": "Anerkendelsesmail",
      "send_google_invites": "Tillad Google Kalender at sende invitationer eller opdateringsemails til deltagere",
      "send_google_outlook_invites": "Tillad Google / Outlook Calendar at sende invitationer og opdateringsemails til deltagere",
      "assign_user_to_contact": "Tildel kontakter til deres respektive kalenderholdmedlemmer, hver gang en aftale bliver booket",
      "skip_assigning_user_to_contact": "Spring tildeling af kontaktperson over, hvis kontakten allerede har en tildelt bruger",
      "guest_label_count": "Antal gæster",
      "guest_count_error": "Antallet af gæster skal være mellem 1 og 100",
      "allow_cancellation": "Tillad aflysning af møde",
      "allow_reschedule": "Tillad omplanlægning af møde",
      "allow_cancellation_tooltip": "Når det er aktiveret, vil et annulleringslink blive tilføjet i afsnittet med yderligere noter og inkluderet i kalenderinvitationen. Dette link udløber som konfigureret før mødets starttidspunkt, hvilket forhindrer bookingen i at annullere mødet",
      "allow_reschedule_tooltip": "Når aktiveret, vil et genplanlægningslink blive tilføjet til ekstra noter-sektionen og inkluderet i kalenderinvitationen. Dette link vil udløbe som konfigureret før mødet begynder, hvilket forhindrer bookeren i at genplanlægge mødet",
      "cancellation_link_expiry": "Aflysningslinket udløber",
      "reschedule_link_expiry": "Ændring af tidspunkt linket udløber",
      "before_meeting": "før mødet",
      "cancellation_and_reschedule_policy": "Afbestilling og ombooking politik:"
    },
    "connections": {
      "connections_heading": "Ekstern kalendersynkronisering",
      "connections_sub": "Synkronisér begivenheder med ekstern kalender",
      "link_to_calendar": "Link til kalender",
      "sync_option": "Synkroniseringsindstilling",
      "execute_automation": "Udfør automatisering",
      "fire_workflow": "Udløs arbejdsgangstriggere for begivenheder, der er booket via tovejs synkronisering",
      "reserve_with_google": "Reserver med Google",
      "connect_lsa": "Forbind til din lokale tjenesteannoncer-konto",
      "reserve_error": "For øjeblikket understøttes Reserve with Google kun for virksomheder inden for \"Hjemmetjenester\", \"Fast ejendom\" og \"Juridiske tjenester\" (lokale serviceudbydere) i USA",
      "one_way": "Envejs",
      "two_way": "To-vejs",
      "smart": "Smart",
      "confirm_remove": "Er du sikker på, at du vil fjerne Google-kalenderen?",
      "read_only_access": "Du har ikke skriveadgang til denne kalender. Vælg venligst en anden kalender",
      "reserve_with_google_desc": "Denne kalenderservice vil kun blive opført som reserveløsning sammen med Google ved succesfuld integration",
      "check_integration": "Tjek din integrationsstatus"
    },
    "extras": {
      "extras": "Tilpasninger",
      "extras_sub": "Indstil widget-stil og andre præferencer",
      "calendar_widget_style": "Kalender widget stil",
      "neo": "Neo",
      "classic": "Klassisk",
      "insert_custom_code": "Indsæt tilpasset kode",
      "show_seats_per_slot": "Vis siddepladser pr. tidsluke",
      "allow_reschedule_meeting": "Tillad omlægning af møde",
      "allow_cancelling_meeting": "Tillad aflysning af møde",
      "additional_notes": "Yderligere noter",
      "cancellation_and_reschedule": "Aflysning og planlægning af ny tid:",
      "allow_staff": "Tillad personalevalg under booking",
      "code_block_tooltip": "Tredjeparts-scripts inde i HTML-blokken kan give kraftig funktionalitet, men de bringer også risici for privatliv, sikkerhed, ydeevne og sideadfærd. Derfor anbefaler vi, at du gennemgår indholdet af dette script, før du gemmer det i kalenderen"
    }
  },
  "add_guest": {
    "guest_label": "Gæst(er)",
    "search_placeholder": "Søg fra kontakter",
    "add_new": "Tilføj ny",
    "save": "Gem",
    "close": "Luk",
    "name": "Navn",
    "phone": "Telefon",
    "email": "E-mail"
  },
  "languages": {
    "english": "Engelsk",
    "dutch": "Hollandsk",
    "french": "Fransk",
    "german": "Tysk",
    "hungarian": "Ungarsk",
    "italian": "Italiensk",
    "polish": "Polsk",
    "portuguese_brazil": "Portugisisk (Brasilien)",
    "portuguese_portugal": "Portugisisk (Portugal)",
    "spanish": "Spansk",
    "danish": "Dansk"
  },
  "service_menu_advanced": {
    "new_service_menu_details_header": "Service menuoplysninger",
    "select_groups_header": "Vælg tjenester",
    "arrange_groups_header": "Arranger dine services",
    "new_service_menu": "Nyt service-menu",
    "service_menu_sub_title": "Opret en personlig service-menu til dine kunder med blot et par klik",
    "quick_tip": "Du kan tilføje grupper til service-menuen",
    "service_menu_name": "Service Menu navn",
    "service_menu_description": "Beskrivelse",
    "service_menu_slug": "Service URL: Service-url",
    "select_groups_calendars": "Vælg grupper og kalendere",
    "select_groups_calendars_desc": "Tilføj tjenester til din servicemenu",
    "select_all": "Vælg alle",
    "arrange_groups": "Arrangere rækkefølgen af ​​tjenester",
    "arrange_groups_desc": "Bestem sekvensen af tjenester på tjenestemenuen",
    "arrange_services_quicktip": "For at omarrangere kalendere inden for en gruppe, gå til Grupper > Indstillinger > Omarranger kalendere.",
    "enable_add_guest_desc": "Tillader kunder at inkludere ekstra gæster ved booking",
    "enable_multiple_service_desc": "Giver kunder mulighed for at booke flere tjenester i én aftale...",
    "enable_staff_selection_desc": "Lader kunderne vælge foretrukne medarbejdere, når de laver en reservation",
    "enable_add_guest": "Tillad tilføjelse af gæster",
    "enable_multiple_service": "Tillad valg af flere tjenester",
    "enable_staff_selection": "Muliggør personalevalg",
    "additional_options": "Yderligere muligheder",
    "code_block_tooltip": "Tredjepartsskripter inde i HTML-blokken kan give kraftfuld funktionalitet, men de medfører også risici for privatlivets fred, sikkerhed, ydeevne og sideadfærd. Derfor anbefaler vi, at du gennemgår indholdet af dette skript, før du gemmer det i servicemenuen.",
    "custom_code_placeholder": "Venligst indtast brugerdefineret kode her",
    "insert_custom_code": "Indsæt brugerdefineret kode",
    "no_service": "Ingen service-menu fundet",
    "no_service_desc": "Ingen tjenester fundet. Opret tjenester og tilføj dem til grupper for at oprette en servicemenu",
    "learn_more": "Lær mere",
    "no_service_selected": "Ingen service valgt",
    "create_service_calendars": "Opret servicekalendere",
    "alert_description": "For at oprette en servicemenu skal du oprette servicetidslister og tilføje dem til grupper",
    "dont_show_again": "Vis ikke dette igen",
    "service_name_required": "Service Menu navn er påkrævet",
    "slug_already_taken": "Denne snegl er allerede optaget",
    "slug_required": "Det er påkrævet at indtaste en snegl",
    "please_enter_name": "Indtast venligst et navn",
    "service_name_placeholder": "Hovedmenu",
    "consent_label": "Tilladelses afkrydsningsfelt",
    "consent_input_placeholder": "Tilføj samtykketekst",
    "consent_tooltip": "Aktiver denne indstilling for at tillade samtykke fra kontakter, når de giver deres telefonnummer under aftalebogen processen"
  },
  "widget_configuration": {
    "customise_calendar_widget": "Tilpas kalenderwidget",
    "works_with_neo_widget": "Fungerer kun med Neo-widget",
    "customise_widget_desc": "Tilpas widgetens udseende: primær farve, baggrundsfarve og knaptekst",
    "learn_more": "Lær mere",
    "primary_settings": "Primære indstillinger",
    "primary_settings_desc": "Konfigurer primærfarven, baggrundsfarven og andre widget-indstillinger for at tilpasse din kalender-widget",
    "primary_color": "Primærfarve",
    "primary_color_desc": "Definer farven for knapper, datoer, tidsintervaller og handlinger",
    "background_color": "Baggrundsfarve",
    "background_color_desc": "Indstil baggrundsfarven, der fungerer som baggrund for både dato-tidsvælgeren og formularen",
    "button_text": "Knap tekst",
    "button_text_desc": "Tilpas teksten, der vises på knappen ved booking af en aftale",
    "calendar_title": "Kalendertitel",
    "calendar_title_desc": "Navnet på din kalender",
    "calendar_description": "Kalenderbeskrivelse",
    "calendar_description_desc": "Den indtastede beskrivelse",
    "calendar_details": "Kalenderdetaljer",
    "calendar_details_desc": "Inkluderer information såsom varighed, dato og tidspunkt, gentagelsesdetaljer og tidszone",
    "reset_to_default": "Nulstil til standardindstillinger",
    "preview_widget": "Preview Widget - Forhåndsvisningsværktøj"
  },
  "eventNotification": {
    "list": {
      "booked": {
        "title": "Aftale booket meddelelse (Status: Ubestemt)",
        "note": "Denne meddelelse sendes, når aftalen er ubekræftet",
        "subTitle": "Underretter, når en aftale bookes med status \"ubekræftet"
      },
      "confirmation": {
        "title": "Aftale booket meddelelse (Status: Bekræftet)",
        "note": "Denne meddelelse sendes, når aftalen er bekræftet",
        "subTitle": "Underretter, når en aftale er bekræftet med succes"
      },
      "cancellation": {
        "title": "Aflysningsmeddelelse",
        "note": "Denne besked sendes, når aftalen annulleres, markeres som ingen fremmøde eller ugyldig",
        "subTitle": "Advarsler, når en aftale annulleres"
      },
      "reschedule": {
        "title": "Omlæg meddelelse",
        "note": "Denne meddelelse sendes for aftaler, der ikke er bekræftede, bekræftede eller viste",
        "subTitle": "Underretter når en aftale ændres"
      },
      "reminder": {
        "title": "Påmindelsesmeddelelse",
        "note": "Denne meddelelse sendes for ubekræftede, bekræftede eller viste aftaler",
        "subTitle": "Sender en påmindelse før aftalen"
      },
      "followup": {
        "title": "Opfølgningsmeddelelse",
        "note": "Denne meddelelse sendes for ikke-bekræftede, bekræftede eller viste aftaler",
        "subTitle": "Sender en opfølgningsbesked, efter aftalen er afsluttet"
      }
    },
    "saveToastMsg": "Indstillinger gemt!",
    "saveToastMsgDesc": "Dine ændringer er blevet gemt med succes",
    "email": {
      "noneTemplate": "Ingen",
      "notificationLabel": "Hvem skal modtage denne underretning?",
      "sendButtonLabel": "Send test e-mail",
      "testEmailLabel": "Test e-mail",
      "testEmailPlaceholder": "Indtast modtagerens e-mailadresse til testning",
      "body": "E-mailtekst",
      "bodyPlaceholder": "Skriv din besked her",
      "subject": "Emne",
      "subjectPlaceholder": "Indtast emnefeltet for e-mailen",
      "templateLabel": "E-mail skabelon",
      "templatePlaceholder": "Vælg en e-mail skabelon eller start fra bunden",
      "alertEmailAddress": "Advarsel e-mailadresse",
      "addMore": "Tilføj mere",
      "afterAppointmentEnds": "efter aftalen slutter",
      "beforeAppointmentStarts": "før aftalen begynder",
      "durationLabel": "Hvornår vil du gerne aktivere underretningen?",
      "disableEmailNotification": "Deaktiver e-mailnotifikationer",
      "enableEmailNotification": "Aktivér e-mailnotifikationer",
      "enableSuccess": "Email underretning aktiveret!",
      "enableSuccessDesc": "Du vil nu begynde at modtage denne notifikation",
      "disableSuccess": "E-mail underretning deaktiveret!",
      "disableSuccessDesc": "Du vil ikke længere modtage denne meddelelse"
    },
    "inApp": {
      "title": "Meddelelsesindstillinger",
      "selectedUser": {
        "label": "Send til",
        "helperText": "Denne interne meddelelse sendes via web og mobilapps",
        "placeholder": "Vælg Bruger(e)"
      },
      "enableSuccess": "In-App meddelelser aktiveret!",
      "enableSuccessDesc": "Du vil nu begynde at modtage denne meddelelse",
      "disableSuccess": "Meddelelser i app deaktiveret!",
      "disableSuccessDesc": "Du vil ikke længere modtage denne meddelelse"
    }
  }
}