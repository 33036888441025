import { requests } from './BaseService'
import config from '@/config'

export const UserService = {
  getAll: (locationId: string) =>
    requests().get(`${config.restApiUrl}/users/`, {
      params: {
        locationId,
      },
    }),
  getById: (userId: string) =>
    requests().get(`${config.restApiUrl}/users/${userId}`),
}
