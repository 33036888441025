{
  "settings": {
    "calendar": {
      "calendar_settings": "Kalender Innstillinger",
      "group": "Gruppe",
      "manage_your_calendars_and_groups": "Administrer dine kalendere og grupper",
      "groups": "Grupper",
      "create_group": "Opprett Gruppe",
      "create_calendar": "Opprett Kalender",
      "calendar_name": "Kalendernavn",
      "group_duration": "Gruppevarighet",
      "calendar_type": "Kalender Type",
      "action": "Handlig",
      "round_robin": "Fordel avtaler på tilordnede",
      "event": "Begivenhet",
      "group_name": "Gruppenavn",
      "calendars": "Kalendere",
      "date_updated": "Dato Oppdatert",
      "status": "Status",
      "action_dropdown": "Handlingsmeny",
      "edit": "Rediger",
      "duplicate": "Dupliser",
      "copy_embed_code": "Kopier Innebygd Kode",
      "copy_permanent_link": "Kopier Permanent Lenke",
      "copy_new_slug_link": "Kopier Planleggingslenke",
      "copy_scheduling_link": "Kopier Planleggingslenke (Eldre Avviklet)",
      "deactivate_calendar": "Deaktiver Kalender",
      "move_to_group": "Flytt til Gruppe",
      "delete_calendar": "Slett Kalender",
      "delete_group": "Slett",
      "delete_this_group": "Slett denne gruppen",
      "activate_calendar": "Aktiver Kalender",
      "duration": "Varighet (minutter)",
      "all": "Alle",
      "draft": "Utkast\r",
      "active": "Aktiv",
      "rearrange_calendars": "Omorganiser Kalendere",
      "deactivate_all_calendars_in_group": "Deaktiver alle kalenderene i gruppen",
      "activate_all_calendars_in_group": "Aktiver alle kalenderene i gruppen",
      "no_groups_found": "Ditt søk samsvarte ikke med noen kalender grupper",
      "no_groups_in_location": "Start å bruke grupper ved å opprette en kalender gruppe",
      "no_calendars_found": "Start å bruke kalendere ved å opprette en kalender",
      "no_calendars_found_search": "Ditt søk samsvarte ikke med noen kalendere",
      "delete_calendar_message": "Du er i ferd med å slette Kalender. Når du trykker på slett, vil den også slette alle avtalene",
      "delete_calendar_message_title": "Slette denne Kalenderen?",
      "no_calendars_found_title": "Ingen Kalender Funnet",
      "no_groups_in_location_title": "Ingen Grupper Funnet",
      "delete_group_confirmation_title": "Slette denne Gruppen?",
      "delete_group_confirmation": "Er du sikker på at du ønsker å slette den valgte gruppen?",
      "delete_group_confirmation_checkbox_title": "Slett tilknyttede kalendere og avtaler",
      "delete_group_confirmation_checkbox_description": "Å velge dette alternativet vil også slette kalenderne i denne gruppen, sammen med alle avtalene i disse kalenderne.",
      "group_status_message_title": "{status} denne Gruppen?",
      "calendar_status_message_title": "{status} denne Kalenderen?",
      "calendar_status_message": "Du er i ferd med å {status} Kalenderen",
      "group_status_message": "Du er i ferd med å {status} Gruppen",
      "cancel": "Avbryt",
      "new_service_menu": "Nytt tjenestemeny",
      "service_menus": "Tjenestemeny",
      "manage_your_calendars_groups_and_service_menus": "Administrer kalenderne dine, grupper og tjenestemenyen",
      "deactivate_service_menu": "Deaktivere servicemenyen",
      "delete_service_menu": "Slett denne tjenestemenyen",
      "delete_service_menu_confirmation": "Er du sikker på at du vil slette denne tjenestemenyen?",
      "service_menu_status_message_title": "{status} denne Service-menyen?",
      "service_menu_status_message": "Du er i ferd med å {status} Service-menyen",
      "no_service_menus_in_location_title": "Lag ditt tilpassede tjenestemeny",
      "add_new_service_menu": "Legg til ny tjenestemeny",
      "no_service_menus_in_location": "Begynn med å opprette en ny tjenestemeny og legge til grupper i den",
      "no_service_menu_found": "Ingen service-meny funnet",
      "no_service_menu_found_desc": "Din søk ga ingen treff på tjenestemenyer",
      "rooms": "Rom",
      "equipments": "Utstyr",
      "share_otl": "Partagez votre disponibilité à chaque fois avec un lien unique qui expire après une réservation, garantissant un accès contrôlé",
      "generate_link": "Generar nuevo enlace",
      "share_schedule_link": "La programación del enlace está determinada por el slug. Ajusta el slug y el enlace de programación se adapta automáticamente a la modificación",
      "share_permanent_link": "Idéal pour entonnoirs, redirections de site Web ou publicités, le lien permanent reste constant, sans être affecté par les modifications d'identifiant",
      "copy": "Copiar",
      "share_embed_code": "Coloca este código en tu HTML donde quieras que aparezca tu widget de {embedType}",
      "scheduling_link": "Programmation du lien",
      "permanent_link": "Enlace permanente",
      "one_time_link": "Enlace de un solo uso",
      "embed_code": "Código de inserción",
      "loading": "Chargement...",
      "round_robin_booking": "Round Robin",
      "round_robin_description": "Fordeler avtaler blant teammedlemmer i en roterende rekkefølge.",
      "round_robin_example": "F.eks.: Salgssamtaler, onboarding-økter.",
      "unassigned_booking": "Hendelseskalender",
      "unassigned_description": "For planlegging av fysiske hendelser uten vertsforening.",
      "unassigned_example": "F.eks.: Konferanser, offentlige seminarer, messer.",
      "class_booking": "Klassetimebestilling",
      "class_booking_description": "En vert møter flere deltakere.",
      "class_booking_example": "F.eks.: Webinarer, gruppeopplæring, nettbaserte kurs.",
      "collective_booking": "Kollektiv bestilling",
      "collective_booking_description": "Flere verter møter én deltaker.",
      "collective_booking_example": "F.eks.: Panelintervjuer, komitévurderinger.",
      "service_booking": "Tjenestebestilling",
      "service_booking_description": "Sømløs planlegging for tjenestebaserte virksomheter.",
      "service_booking_example": "F.eks.: Spa-avtaler, reparasjonstjenester, konsultasjoner.",
      "personal_booking": "Personlig bestilling",
      "personal_booking_description": "Planlegger en-til-en-møter med en spesifikk teammedlem.",
      "personal_booking_example": "F.eks.: Klientmøter, private konsultasjoner.",
      "personal": "Personlig",
      "explore_more_types": "Utforsk flere typer",
      "scheduling_type": "Planleggingstype",
      "scheduling_type_description": "Velg en planleggingstype for din nye kalender"
    },
    "preferences": {
      "preferences": "Preferanser",
      "my_preferences": "Mine Preferanser",
      "account_preferences": "Konto Preferanser",
      "user_preferences": "Bruker Preferanser",
      "set_preferences": "Angi preferanser for kontoen din",
      "scheduling_options": "Planleggingsalternativer",
      "meeting_location": "Møte lokasjon",
      "zoom": "Zoom",
      "save_preferences": "Lagre Preferanser",
      "in_app_preferences": "Preferanser i Appen",
      "set_start_day": "Angi preferanser som ukestart dag for appen",
      "view_options": "Vis Alternativer",
      "week_starts_on": "Uke Starter På",
      "sunday": "Søndag",
      "widget_preferences": "Widget-preferanser",
      "set_widget_language": "Angi preferanser som språk for widget",
      "language_and_region": "Språk og region",
      "language": "Språk",
      "english": "Engelsk",
      "time_format": "Tidsformat",
      "time_format_value": "Sat Dec 30 1899 13:30:00 GMT+0521 (India Standard Time)",
      "monday": "Mandag",
      "custom_meeting_location": "Egendefinert Møtelokasjon"
    },
    "availability": {
      "availability": "Tilgjengelighet",
      "my_availability": "Min Tilgjengelighet",
      "account_availability": "Konto Tilgjengelighet",
      "timezone": "Tidssone",
      "working_hours": "Arbeidstid",
      "set_recurring_hours": "Angi gjentakende arbeidstid for avtalen",
      "sunday": "Søndag",
      "monday": "Mandag",
      "tuesday": "Tirsdag",
      "wednesday": "Onsdag",
      "thursday": "Torsdag",
      "friday": "Fredag",
      "saturday": "Lørdag",
      "widget_preferences": "Widget-preferanser",
      "set_widget_language": "Angi preferanser som språk for widget",
      "language_and_region": "Språk og region",
      "language": "Språk",
      "english": "Engelsk"
    },
    "connections": {
      "connections": "Tilkoblinger",
      "conferencing": "Konferanse",
      "my_connections": "Mine Tilkoblinger",
      "account_connections": "Konto Tilkoblinger",
      "connection_preferences": "Tilkoblings Preferanser",
      "manage_calendars_conferencing_channels": "Administrer Kalendere og Konferansekanaler",
      "main_integration_calendar": "Hovedintegrasjonskalender",
      "event_integration_calendar": "Hendelser opprettet på en systemkalender som du er en del av vil også bli opprettet på denne integrerte kalenderen",
      "2way_sync": "2-veis Synkronisering: Opprett Systemhendelser fra hendelser som er booket på den integrerte hovedkalenderen",
      "calendars": "Kalendere",
      "google": "Google",
      "outlook": "Outlook",
      "check_for_conflicts": "Sjekk for Konflikter",
      "choose_accounts_to_connect": "Velg hvilke kontoer du vil koble til",
      "payments": "Betalinger",
      "stripe": "Stripe",
      "ads": "Annonser",
      "local_service_ads": "Lokale Tjenesteannonser",
      "fire_workflow": "Utløs Automasjon-utløsere for Hendelser booket via 2-veis synkronisering",
      "connect_google_outlook": "Koble til Google- eller Outlook-kontoen din for å kunne velge en primær kalender",
      "execute_automation": "Utfør Automasjon",
      "save": "Lagre",
      "lsa": "Lokale Tjenesteannonser"
    }
  },
  "appointment_modal": {
    "select_a_contact": "Velg en Kontakt",
    "start_time": "Starttid",
    "end_time": "Sluttid",
    "search_by_name_email_phone_or_company": "Søk etter navn, e-post, telefon eller firma",
    "add_new": "Legg til",
    "pick_from_available_contacts": "Eller velg fra tilgjengelige kontakter",
    "book_appointment": "Bestill Avtale",
    "calendar": "Kalender",
    "repeat": "Repeter",
    "date_and_time": "Dato og Tid",
    "close": "Lukk",
    "team_member": "Teammedlem",
    "recurring_booking": "Gjentagende Avtale",
    "one_slot_booking": "Engangs Avtale",
    "event_type": "Hendelsetype",
    "occurrences_booked": "begivenheter vil bli booket",
    "repeats": "Repeterer",
    "booking_details": "Avtale Detaljer",
    "showing_slots_in_this_timezone": "Viser klokkeslett i denne tidssonen:",
    "update_contact_tz": "Oppdater tidssone for kontakt til",
    "date": "Dato",
    "slot": "Klokkeslett",
    "calendar_default": "Kalender Standard",
    "custom": "Egendefinert",
    "meeting_notes": "(f.eks.) Møtenotater for vår kommende avtale",
    "out_of_office": "(f.eks.) Ikke på kontoret denne uken!",
    "standard": "Standard",
    "appointment_title": "Avtaletittel",
    "event_title": "Hendelsetittel",
    "appt_with_bob": "(f.eks.) Avtale med Ola",
    "vacation": "(f.eks.) Ferie",
    "show_notes": "Vis Notater",
    "hide_notes": "Skjul Notater",
    "additional_preferences": "Ytterligere Preferanser",
    "meeting_location": "Møte Lokasjon",
    "appointment_status": "Avtale Status",
    "appt_description": "Avtale Beskrivelse",
    "recurring_details": "Gjentagende Detaljer",
    "event_description": "Hendelse Beskrivelse",
    "in_contact_timezone": "I kontaktens Tidssone",
    "vacation_message": "Skal du på ferie? Eller vil du sette av fokus tid? Sperr av tid i kalenderen din for å hindre kunder i å bestille nye avtaler i kalenderen din)",
    "contact": "Kontakt",
    "delete": "Slett",
    "cancel": "Avbryt",
    "back": "Tilbake",
    "pick_available_contacts": "Eller velg fra tilgjengelige kontakter",
    "no_contacts_found": "Ingen kontakter funnet",
    "found": "funnet",
    "select_one": "Velg En",
    "tz_tooltip": "Dette er bare viktig hvis du bruker egendefinerte verdier; slik som appointment_start_time appointment_end_time appointment_timezone osv.. i din utgående kommunikasjon med dine klienter eller kunder. Og det den ganske enkelt gjør er å konvertere de nevnte detaljene til lokal tid",
    "meeting_location_tooltip": "Møtestedet vil bli satt som konfigurert i kalenderen",
    "appt_meeting_location_tooltip": "Brukeren kan angi møtestedet spesifikt for avtalen",
    "gmeet_tooltip": "Sørg for at det respektive teammedlemmet har en Google-kalender angitt som primærkalender",
    "zoom_tooltip": "Vennligst sørg for at det aktuelle teammedlemmet har Zoom integrert",
    "enter_meeting_location": "Angi Møteplass",
    "save_appointment": "Lagre Avtale",
    "save_event": "Lagre Arrangement",
    "search_contacts": "Søk etter navn, e-post, telefon eller firma",
    "recurring_slots": "{recurringSlotsLength}/{count}"
  },
  "popup": {
    "calendar": "Kalender",
    "appointment_owner": "Avtaleeier",
    "source": "Kilde",
    "booked_by": "Bestilt Av",
    "view_activity_log": "Se Aktivitetslogg",
    "status": "Status",
    "cancel_appointment": "Avbryt Avtale",
    "google_event": "Google-arrangement",
    "outlook_event": "Outlook-arrangement",
    "blocked_slot": "Blokkert Tidsluke",
    "appointment": "Avtale",
    "confirmed": "Bekreftet",
    "unconfirmed": "Ubekreftet",
    "cancelled": "Avlyst",
    "showed": "Fremmøtt",
    "no_show": "Ikke Møtt",
    "invalid": "Ugyldig"
  },
  "appointment_list": {
    "group_all": "ALLE",
    "group_primary": "PRIMÆR",
    "group_user": "BRUKERE",
    "group_team": "GRUPPER",
    "group_calendar": "Kalendere",
    "all_appointments": "Alle avtaler",
    "my_calendar": "Min kalender",
    "status_all": "Alle",
    "status_confirmed": "Bekreftet",
    "status_new": "Ny (Handling påkrevd)",
    "status_show": "Viste",
    "status_no_show": "Ingen oppmøte",
    "status_cancelled": "Avlyst",
    "status_invalid": "Ugyldig",
    "filter_date_added_desc": "Dato lagt til (synkende)",
    "filter_start_added_desc": "Startdato (Synkende)",
    "filter_start_added_asc": "Startdato stigende fra i dag",
    "button_new_appointment": "Ny avtale",
    "name": "Navn",
    "title": "Tittel",
    "requested_time": "Ønsket tid",
    "date_added": "Dato lagt til",
    "calendar": "Kalender",
    "appointment_owner": "Avtaleeier",
    "notes": "Notater",
    "status": "Status",
    "action_view_detail": "Vis detaljer",
    "action_view_notes": "Vis notater",
    "action_edit": "Rediger",
    "action_delete": "Slett",
    "action_reschedule": "Omløplassere",
    "page_previous": "Forrige",
    "page_next": "Neste",
    "appointment_notes": "Møtenotater",
    "close": "Nær",
    "confirm_delete_header": "Slett avtale",
    "confirm_delete_message": "Er du sikker på at du vil slette denne avtalen?",
    "confirm_delete_button": "Slett",
    "confirm_update_header": "Oppdater avtalestatus",
    "confirm_update_confirmed_message": "Er du sikker på at du vil merke denne avtalen som bekreftet?",
    "confirm_update_invalid_message": "Er du sikker på at du vil markere denne avtalen som ugyldig?",
    "confirm_update_showed_message": "Er du sikker på at du vil merke dette avtalepunktet som vist?",
    "confirm_update_noshow_message": "Er du sikker på at du vil merke denne avtalen som ikke møtt?",
    "confirm_update_cancelled_message": "Er du sikker på at du vil merke denne avtalen som avlyst?",
    "confirm_update_button": "Oppdatering",
    "disable_menu_warning": "For øyeblikket har du ikke tillatelse til å se eller redigere avtaleinformasjonen, da den tilknyttede kontakten ikke er tildelt deg. Vennligst ta kontakt med administratoren din for hjelp",
    "no_contact_warning": "Du har for øyeblikket ikke tilgang til kontaktinformasjon. Dette kan skyldes at kontakten ikke er tildelt deg. Vennligst ta kontakt med administratoren din for hjelp",
    "youre_viewing_in": "Currently viewing in",
    "rescheduled": "Omskjema"
  },
  "create_calendar": {
    "calendar_name": "Kalendernavn",
    "calendar_logo": "Kalenderlogo",
    "select_team_member": "Velg teammedlemmer",
    "eg_outbound_reach": "(eg) Utgående rekkevidde",
    "description": "Beskrivelse",
    "amount": "Beløp",
    "description_placeholder": "Skriv beskrivelse",
    "meeting_duration": "Møtevarighet",
    "seats_per_class": "Antall seter per klasse",
    "group": "Gruppe",
    "custom_url": "Tilpasset nettadresse",
    "meeting_invite_title": "Møteinnkallingstittel",
    "event_color": "Hendelsesfarge",
    "availability_type": "Tilgjengelighetstype",
    "deposit_amount": "Monto del depósito",
    "collect_deposit_amount": "Aceptar pago parcial",
    "percentage": "Percentage",
    "total_amount": "Montant total",
    "reschedule_preference": "Preferanse for omplanlegging",
    "reschedule_preference_subtext": "Hvis en kontakt omplanlegger en avtale:",
    "reassign_through_round_robin": "Tildel gjennom rundgang",
    "keep_same_assinged_user": "Behold samme avtaleeier",
    "new_appointment_preference": "Ny avtalepreferanse",
    "new_appointment_preference_text": "Bestill alltid med kontaktens tildelte bruker",
    "new_appointment_preference_subtext": "Bestill nye avtaler med kontaktens tildelte bruker i stedet for å bruke rundgangsplanlegging."
  },
  "calendar_advanced": {
    "general": {
      "quick_tip": "Rask tips",
      "timezone_change_tip": "Tidssoner kan endres når som helst under tidssoneinnstillinger",
      "save": "Lagre",
      "close": "Lukk",
      "meeting_details": "Møtedetaljer",
      "availability": "Tilgjengelighet",
      "forms_and_payment": "Skjemaer og betaling",
      "notifications_and_additional_options": "Varslinger og tilleggsalternativer",
      "connections": "Tilkoblinger",
      "customizations": "Tilpasninger",
      "create": "Opprett",
      "edit": "Rediger"
    },
    "meeting_details": {
      "meeting_location": "Møtested",
      "enter_meeting_location": "Angi møtestedet",
      "click_to_upload": "Klikk for å laste opp",
      "square": "Kvadrat",
      "circle": "Sirkel",
      "remove_logo": "Fjern logo",
      "meeting_invite_title_tooltip": "Dette er tittelen for kalenderhendelsen som vises i Google Kalender, Outlook, Apple Kalender, osv",
      "event_color_tooltip": "Velg en farge for dette bestillingsarrangementet. Farger synkroniseres med hendelser i Google-kalenderen",
      "enter_a_name": "Vennligst skriv inn et navn",
      "enter_an_event_title": "Vennligst skriv inn en hendelsestittel",
      "high_priority": "Høy prioritet",
      "medium_priority": "Medium prioritet",
      "low_priority": "Lav prioritet",
      "custom": "Tilpasset",
      "zoom_not_added": "Brukeren har ikke koblet til Zoom-kontoen sin ennå",
      "opt_avail": "Optimaliser for tilgjengelighet",
      "opt_equal": "Optimaliser for jevn fordeling",
      "team_members": "Velg teammedlemmer",
      "team_member": "Velg teammedlem",
      "meeting_location_required": "Møtested er påkrevd",
      "team_member_disabled_recurring_tooltip": "Les réunions récurrentes ne permettent qu'à un membre de l'équipe de participer. Pour ajouter plusieurs membres de l'équipe, veuillez désactiver les réunions récurrentes",
      "team_member_disabled_class_booking_tooltip": "Un seul membre de l'équipe peut être ajouté au calendrier de réservation d'une classe",
      "enable_contact_assigned_to_setting": "For å aktivere denne innstillingen må skjemaet være først i widgetrekkefølgen. Vennligst omorganiser widgetrekkefølgen fra fanen Skjemaer og betaling.",
      "reschedule_preference_setting_tooltip": "Velg hvem avtalen skal tildeles når en kontakt omplanlegger fra bestillingswidgeten.",
      "appointment_preference_tooltip": "Velg om nye avtaler alltid skal bestilles med kontaktens tildelte bruker. Hvis en kontakt har en tildelt bruker, vil den brukeren bli gitt preferanse; ellers vil det være en rundgangstildeling. Tips: Slå av 'Tillat personalvalg' for å forhindre at bookere endrer personalmedlem på bestillingswidgeten.",
      "add_location": "Legg til Lokasjon",
      "multiple_locations_neo": "Flere møtelokasjoner støttes kun av Neo Widget med standard skjema.",
      "make_owner": "Gjør til eier",
      "only_one_team_member": "Kun ett teammedlem er tillatt når flere lokasjoner legges til.",
      "only_one_team_member_personal_calendar": "Kun ett teammedlem er tillatt for personlig bestilling",
      "ms_teams_not_added": "Brukeren har ikke koblet til sin Microsoft Teams-konto ennå"
    },
    "availability": {
      "my_availability": "Mitt tilgjengelighetsnivå",
      "set_availability": "Angi tilgjengeligheten din for kalenderen her",
      "standard": "Standard",
      "custom": "tilpasset",
      "to": "til",
      "copy_to_all": "Kopier til alle",
      "add_time": "Legg til tid",
      "recurring_meeting": "Gjentakende møte",
      "recurring_info": "Bare én bruker per kalender for gjentakende bestilling",
      "repeat": "Gjenta",
      "times_to_repeat": "Antall gjentakelser",
      "slots_unavailable": "Hvis det ikke er tilgjengelige spilleautomater?",
      "overlap_status": "Status for avtale for overlappinge tidsintervaller",
      "meeting_interval": "Møteintervall",
      "meeting_duration": "Møtetid",
      "minimum_scheduling": "Minimum varselfrist",
      "date_range": "Datoområde",
      "maximum_bookings_per_day": "Maksimum bestillinger per dag",
      "maximum_bookings_per_slot": "Maksimum antall bookinger per tidsintervall",
      "maximum_bookings_per_slot_per_user": "Maksimalt antall bookinger per tidsrom (per bruker)",
      "seats_per_class": "Seter per klasse",
      "pre_buffer_time": "Forhåndsbuffer tid",
      "post_buffer_time": "Tidsbuffer for sending",
      "pre_buffer_tooltip": "Pre-Bufring er ekstra tid som kan legges til før en avtale, slik at det blir ekstra tid til å gjøre seg klar før et møte",
      "post_buffer_tooltip": "Post-Buffer er ekstra tid som kan legges til etter en avtale, for å tillate ekstra tid til oppsummering",
      "buffer_time": "Buffer tid",
      "daily": "daglig",
      "weekly": "Ukentlig",
      "monthly": "månedlig",
      "skip_booking": "Bestilling av utilgjengelige tidsrom for hopping",
      "continue_booking": "Fortsett bestilling",
      "book_next_available": "Bestill neste tilgjengelige tidspunkt",
      "confirmed": "Bekreftet",
      "unconfirmed": "Ubekreftet",
      "enter_valid_hours": "Vennligst oppgi gyldige åpningstider",
      "enter_valid_avail": "Vennligst oppgi gyldig tilgjengelighet etter eget ønske",
      "availability_type_tooltip": "Velg Standard tilgjengelighet for vanlige bookinger som tilbys ukentlig. Velg Tilpasset for bookinger som bare er tilgjengelige på spesifikke datoer",
      "meeting_interval_tooltip": "Møteintervall reflekterer tidsmengden mellom bestillingsperioder som vil vises i kalenderen. For en 30-minutters hendelse som skal være tilgjengelig på toppen av hver time, vil intervallet være 1 time. For en 30-minutters hendelse som skal være tilgjengelig hver 30. minutt, vil intervallet være 30 minutter",
      "date_range_tooltip": "Arrangementer kan planlegges de neste n dagene",
      "maximum_bookings_per_day_tooltip": "Maksimalt antall avtaler tillatt per dag på denne kalenderen",
      "maximum_bookings_per_slot_tooltip": "Maksimum antall avtaler tillatt per tidsintervall for brukeren (Hvis antall avtaler overstiger for det angitte tidsintervallet for brukeren, vil enten systemet bytte til neste bruker i round-robin-køen, eller merke tidsintervallet som utilgjengelig for potensielle bookinger)",
      "add_days": "Legg til dager",
      "month_on_day": "Månedlig på dag",
      "month_on_last_day": "Månedlig på siste dag",
      "day": "Dag",
      "week": "Uke",
      "month": "Måned",
      "mins": "Minutter",
      "hours": "Timer",
      "days": "Dager",
      "weeks": "Uker",
      "months": "måneder",
      "meeting_interval_error": "Møteintervallet må være mellom 5 minutter og 12 timer",
      "meeting_duration_error": "Møtedurasjonen må være mellom 1 minutt og 12 timer",
      "buffer_time_error": "Buffertiden må være mindre enn 12 timer",
      "subTitle": "Velg datoen for å sette spesifikke timer",
      "heading_1": "Ukentlige tilgjengelige timer",
      "tooltip_1": "Angi din ukentlige tilgjengelighetstimeplan. Dette er dine standardtider som vil bli brukt konsekvent på ukentlig basis",
      "heading_2": "Når er du tilgjengelig?",
      "unavailable_label": "Utilgjengelig",
      "tooltip_2": "Justér timeplanen din for spesifikke datoer. Overstyr dine vanlige ukentlige timer ved å markere tilgjengelighet eller utilgjengelighet for valgte datoer",
      "date_specific_level": "Legg til tidsspesifikke timer",
      "specific_hours_button": "Dato-spesifikke timer",
      "overlap_error": "Tidsperioder kan ikke overlappe",
      "apply": "Søk",
      "copy_times_to": "Kopier tider til",
      "no_date_specific_heading": "Ingen spesifikk dato lagt til",
      "no_date_specific_description": "Du kan legge til/fjerne en bestemt dato og tid tilgjengeligheten din",
      "recurring_disabled_conditions_tooltip": "Gjentakende møter er deaktivert. For å aktivere dem, må du sørge for at kalenderen har bare ett teammedlem, og at det ikke er lagt til spesifikke timer for dato.",
      "date_specific_hours_disabled_conditions_tooltip": "Pour inclure une heure spécifique pour une date précise, veuillez vous assurer que les réunions récurrentes sont désactivées",
      "recurring_disable_event_calendar_conditions": "Gjentakende møter er deaktivert. For å aktivere dem må det ikke legges til spesifikke timer for dato.",
      "service_interval": "Service intervall",
      "service_interval_tooltip": "Møteintervallene angir hvor ofte bookingsporene vises på kalenderen. For eksempel, for et arrangement på 30 minutter, betyr et timesintervall at det vises spor hver time. Et 30-minutters intervall betyr at det vises spor hver 30 minutter",
      "service_duration_tooltip": "Dette setter lengden på avtalen din og bestemmer hvor lenge den vil vare",
      "minimum_scheduling_tooltip": "Dette angir den minste tiden som kreves for å bestille en avtale via kalenderwidgeten. For eksempel, hvis satt til 4 timer, kan avtaler ikke bestilles innen 4 timer etter starttidspunktet; bestillinger må gjøres minst 4 timer i forkant",
      "service_duration": "Service duration",
      "look_busy_title": "Se opptatt ut",
      "look_busy_percentage_description": "Skjul antall tilgjengelige tider med x%",
      "look_busy_helper_text": "Skjul en prosentandel av dine tilgjengelige avtalespor på bestillingswidgeten for å fremstå mer opptatt og etterspurt.",
      "maximum_bookings_per_slot_tooltip_personal": "Maksimalt antall avtaler tillatt per slot for brukeren"
    },
    "forms_payment": {
      "forms": "Skjemaer",
      "forms_sub": "Angi preferanser for innstillinger av postplanleggingskjema",
      "select_form": "Velg skjema",
      "sticky_contacts": "Fyll inn felt automatisk på forhånd (klebrig kontakter)",
      "confirmation_page": "Bekreftelsesside",
      "default": "Standard",
      "redirect_url": "Omdiriger lenke",
      "thank_you": "Takkemelding",
      "enter_url": "Angi nettadresse",
      "pixel_id": "Facebook piksel-ID (valgfritt)",
      "auto_confirm": "Automatisk bekreftelse av nye kalendermøter",
      "configure_payment": "Konfigurer betalingsalternativer",
      "accept_payments": "Godta betalinger",
      "test": "Test",
      "live": "Lev",
      "enable_guests": "Legg til gjester",
      "add_guest_tooltip": "Legge til gjesten vil tillate deltakeren din å legge til flere deltakere i samme avtale",
      "add_guest_same_form_check": "Send samme skjema for å samle informasjon via post til alle gjester",
      "payment_tooltip": "Betaling vil kun bli vurdert for hoveddeltakeren og ikke for gjestene",
      "default_form": "Standard (Fornavn, Etternavn, E-post, Telefon, Notater)",
      "select_form_tooltip": "Hvis du ønsker å samle inn mer informasjon enn standard fornavn, etternavn, e-post og telefon fra de som booker på denne kalenderen, opprett et tilpasset skjema i Sites > Skjemaer > Bygger, og velg deretter skjemaet via rullegardinmenyen nedenfor",
      "confirmation_page_tooltip": "Når noen bestiller, kan du velge å vise dem en tilpasset melding på samme side (Standard) eller omdirigere dem til en side du velger (Omdirigering)",
      "add_guest_collect_email": "Med navn og e-post",
      "add_guest_count_only": "Telle bare",
      "custom_email_notification": "Send tilpasset skjema til gjestene",
      "deposit_amount_error": "Le montant du dépôt doit être inférieur au montant total",
      "deposit_percentage_error": "Le pourcentage du dépôt doit être inférieur à 100%",
      "partial_payment_tooltip": "Activez les paiements partiels pour collecter le montant de l'acompte des clients lors du processus de réservation de rendez-vous",
      "partial_payment_enable_error": "El monto total ingresado debe ser mayor que 0 para habilitar pagos parciales",
      "payment_disable_text_integration": "Integrer en betalingsleverandør under Betalinger> Integreringer for å begynne å godta betalinger",
      "payment_disable_text_recurring": "Akseptere betalingsalternativet er utilgjengelig for gjentatte møter. Slå av gjentagende for å aktivere betalinger.",
      "date_and_time_selector": "Dato- og Tidvelger",
      "form_selector": "Skjema",
      "order_step": "Trinn",
      "widget_order_label": "Widget-rekkefølge",
      "widget_order_tool_tip": "Omorganiser 'Dato- og Tidvelger' og 'Skjema'-rekkefølge på kalenderwidgeten. Bare dra og omorganiser etter dine preferanser.",
      "same_user_assignment_tooltip": "For å aktivere denne innstillingen må skjemaet være først på widgeten. Omorganiser widgeten for å plassere skjemaet først for å aktivere denne innstillingen.",
      "widget_re_order_tooltip": "Omorganisering er bare tilgjengelig med Neo-widgetstilen. Bytt til Neo for å aktivere omorganisering.",
      "widget_re_order_disabled_tooltip": "For å omorganisere widgetrekkefølgen, deaktiver 'Ny avtalepreferanse (Bestill alltid med kontaktens tildelte bruker)' fra Møtedetaljer > Teammedlemmer > Avansert innstilling-seksjonen.",
      "enable_same_user_assignment": "Tildel kontakter til sine respektive kalendarteammedlemmer hver gang en avtale bestilles",
      "recurring_payment_label": "Betaling for gjentakende avtaler",
      "first_appointment_only": "Første avtale kun",
      "all_appointments": "Alle avtaler"
    },
    "notifications": {
      "notifications": "Varsler og tilleggsalternativer",
      "notifications_sub": "Konfigurer notifikasjon og ekstra alternativer",
      "select_notification_type": "Velg type varsel",
      "notification_person": "Hvem skal motta denne varslingen?",
      "contact": "Kontakt",
      "assigned_user": "Tildelt bruker",
      "emails": "E-post",
      "alert_email_address": "Varsle e-postadresse",
      "ack_email": "Bekreftelsesemail",
      "send_google_invites": "La Google Kalender sende invitasjoner eller oppdatering på e-post til deltakere",
      "send_google_outlook_invites": "Tillat Google/Outlook-kalender å sende invitasjon og oppdaterings-e-poster til deltakere",
      "assign_user_to_contact": "Tilordne kontakter til deres tilhørende kalenderteammedlemmer hver gang en avtale blir booket",
      "skip_assigning_user_to_contact": "Hopp over tildeling av kontakt hvis kontakten allerede har en tildelt bruker",
      "guest_label_count": "Gjest(er) antall",
      "guest_count_error": "Gjestetallet skal være mellom 1 og 100",
      "allow_cancellation": "Tillat Avlysning av Møte",
      "allow_reschedule": "Tillat Omscheduling av Møte",
      "allow_cancellation_tooltip": "Når aktivert, vil en avbestillingslenke legges til i tilleggsnotater-seksjonen og inkluderes i kalenderinvitasjonen. Denne lenken vil utløpe som konfigurert før møtestart, og hindrer bestilleren fra å avlyse møtet.",
      "allow_reschedule_tooltip": "Når aktivert, vil en omleggingslenke legges til i tilleggsnotater-seksjonen og inkluderes i kalenderinvitasjonen. Denne lenken vil utløpe som konfigurert før møtestart, og hindrer bestilleren fra å omlegge møtet.",
      "cancellation_link_expiry": "Avbestillingslenke vil utløpe",
      "reschedule_link_expiry": "Omleggingslenke vil utløpe",
      "before_meeting": "før møtet",
      "cancellation_and_reschedule_policy": "Avbestillings- og Omschedule Politikk:"
    },
    "connections": {
      "connections_heading": "Ekstern kalendersynkronisering",
      "connections_sub": "Synkroniser hendelser med ekstern kalender",
      "link_to_calendar": "Lenke til kalender",
      "sync_option": "Synkroniseringsvalg",
      "execute_automation": "Utfør automatisering",
      "fire_workflow": "Fire arbeidsflytutløsere for arrangementer booket via toveis synkronisering",
      "reserve_with_google": "Bestill med Google",
      "connect_lsa": "Koble deg til din lokale tjenester Annonser-konto",
      "reserve_error": "For øyeblikket støttes Reserve with Google bare for \"Hjemmetjenester\", \"Eiendomsmegling\" og \"Juridiske tjenester\" (lokale tjenesteleverandører) i USA",
      "one_way": "En vei",
      "two_way": "To-veis",
      "smart": "smart",
      "confirm_remove": "Er du sikker på at du vil fjerne Google-kalenderen?",
      "read_only_access": "Du har ikke skrivetilgang til denne kalenderen. Vennligst velg en annen kalender",
      "reserve_with_google_desc": "Denne kalendertjenesten vil bli oppført som reservert hos Google bare ved vellykket integrering",
      "check_integration": "Sjekk integrasjonsstatusen din"
    },
    "extras": {
      "extras": "Tilpasninger",
      "extras_sub": "Angi widgetstil og andre preferanser",
      "calendar_widget_style": "Kalender-widgetstil",
      "neo": "Neo",
      "classic": "Klassisk",
      "insert_custom_code": "Sett inn tilpasset kode",
      "show_seats_per_slot": "Vis plasser per spilleautomat",
      "allow_reschedule_meeting": "Tillat endring av møte",
      "allow_cancelling_meeting": "Tillat avbestilling av møte",
      "additional_notes": "Ytterligere merknader",
      "cancellation_and_reschedule": "Avlysning og ombooking:",
      "allow_staff": "Tillat valg av ansatte under bestilling",
      "code_block_tooltip": "Tredjeparts skript inne i HTML-blokken kan gi kraftig funksjonalitet, men de bringer også risikoer for personvern, sikkerhet, ytelse og sideadferd. Derfor anbefaler vi at du gjennomgår innholdet i dette skriptet før du lagrer det i kalenderen"
    }
  },
  "add_guest": {
    "guest_label": "Gjest(e)",
    "search_placeholder": "Søk blant kontakter",
    "add_new": "Legg til nytt",
    "save": "Lagre",
    "close": "lukk",
    "name": "Navn",
    "phone": "Telefon",
    "email": "E-post"
  },
  "languages": {
    "english": "Engelsk",
    "dutch": "Nederlandsk",
    "french": "Fransk",
    "german": "Tysk",
    "hungarian": "Ungarsk",
    "italian": "Italiensk",
    "polish": "Polsk",
    "portuguese_brazil": "Portugisisk (Brasil)",
    "portuguese_portugal": "Portugisisk (Portugal)",
    "spanish": "Spansk",
    "danish": "Dansk"
  },
  "service_menu_advanced": {
    "new_service_menu_details_header": "Detaljer om servicemenyen",
    "select_groups_header": "Velg tjenester",
    "arrange_groups_header": "Organiser tjenestene dine",
    "new_service_menu": "Nytt tjenestemeny",
    "service_menu_sub_title": "Lag en personlig service-meny for kundene dine med bare noen få klikk",
    "quick_tip": "Du kan legge til grupper i tjenestemenyen",
    "service_menu_name": "Tjenestemenynavn",
    "service_menu_description": "Beskrivelse",
    "service_menu_slug": "Tjeneste-URL",
    "select_groups_calendars": "Velg grupper og kalendere",
    "select_groups_calendars_desc": "Legg til tjenester i tjenestemenyen din",
    "select_all": "Velg alle",
    "arrange_groups": "Organisere rekkefølge for tjenester",
    "arrange_groups_desc": "Finn rekkefølgen på tjenestene på tjenestemenyen",
    "arrange_services_quicktip": "For å omorganisere kalendere innad i en gruppe, gå til Grupper > Alternativer > Omorganisere kalendere",
    "enable_add_guest_desc": "Tillater kunder å inkludere ekstra gjester ved bestilling",
    "enable_multiple_service_desc": "Tillater kunder å bestille flere tjenester i en enkelt avtale...",
    "enable_staff_selection_desc": "Tillater kunder å velge foretrukne ansatte når de gjør en booking",
    "enable_add_guest": "Tillat legge til gjester",
    "enable_multiple_service": "Tillate flere tjenestevalg",
    "enable_staff_selection": "Aktiver ansattvalg",
    "additional_options": "Ekstra alternativer",
    "code_block_tooltip": "Tredjeparts skript inne i HTML blokken kan gi kraftig funksjonalitet, men de bringer også risikoer til personvern, sikkerhet, ytelse og sidens oppførsel. Derfor anbefaler vi at du gjennomgår innholdet i dette skriptet før du lagrer det i tjenestemenyen",
    "custom_code_placeholder": "Vennligst skriv inn egendefinert kode her",
    "insert_custom_code": "Sett inn tilpasset kode",
    "no_service": "Ingen tjeneste funnet",
    "no_service_desc": " Ingen tjenester funnet. Opprett tjenester og legg dem til grupper for å opprette en meny for tjenester",
    "learn_more": "Lær mer",
    "no_service_selected": "Ingen tjeneste valgt",
    "create_service_calendars": "Opprett tjenestekalendere",
    "alert_description": "For å opprette en servicemeny, må du opprette tjenestekalendere og legge dem til grupper",
    "dont_show_again": "Vis ikke dette igjen",
    "service_name_required": "Vennligst oppgi navn på servicemenyen",
    "slug_already_taken": "Denne sneglen er allerede tatt",
    "slug_required": "Sneglen er påkrevd",
    "please_enter_name": "Vennligst skriv inn et navne",
    "service_name_placeholder": "Hovedmeny",
    "consent_label": "Samtykke avmerkingsboks",
    "consent_input_placeholder": "Legg til samtykketekst",
    "consent_tooltip": "Aktiver denne innstillingen for å kunne innhente samtykke fra kontakter når de oppgir telefonnummeret sitt under avtalebokingsprosessen"
  },
  "widget_configuration": {
    "customise_calendar_widget": "Tilpass kalender-widget",
    "works_with_neo_widget": "Bare fungerer med Neo-widget",
    "customise_widget_desc": "Tilpass utseendet på widgeten: primærfarge, bakgrunnsfarge og knappetekst",
    "learn_more": "Lær mer",
    "primary_settings": "Hovedinnstillinger",
    "primary_settings_desc": "Konfigurer primærfargen, bakgrunnsfargen og andre widgetinnstillinger for å tilpasse kalenderwidgeten din",
    "primary_color": "Primærfarge",
    "primary_color_desc": "Definer fargen for knapper, datoer, tidsluker og handlinger",
    "background_color": "Bakgrunnsfarge",
    "background_color_desc": "Angi bakgrunnsfargen som fungerer som bakgrunn for både dato-tid-velgeren og skjemaet",
    "button_text": "Knappetekst",
    "button_text_desc": "Tilpass teksten som vises på knappen ved bestilling av en avtale",
    "calendar_title": "Kalendertittel",
    "calendar_title_desc": "Navnet på kalenderen din",
    "calendar_description": "Kalenderbeskrivelse",
    "calendar_description_desc": "Den innskrivne beskrivelsen",
    "calendar_details": "Kalenderdetaljer",
    "calendar_details_desc": "Inkluderer informasjon som varighet, dato og tid, gjentakende detaljer og tidssone",
    "reset_to_default": "Tilbakestill til standard",
    "preview_widget": "Forhåndsvisningswidget"
  },
  "eventNotification": {
    "list": {
      "booked": {
        "title": "Varsel om Bestilt Avtale (Status: Ubekreftet)",
        "note": "Dette varselet sendes når avtalen er ubekreftet",
        "subTitle": "Varsler når en avtale er bestilt med en ubekreftet status"
      },
      "confirmation": {
        "title": "Varsel om Bestilt Avtale (Status: Bekreftet)",
        "note": "Dette varselet sendes når avtalen er bekreftet",
        "subTitle": "Varsler når en avtale er bekreftet"
      },
      "cancellation": {
        "title": "Avbestillingsvarsel",
        "note": "Dette varselet sendes når avtalen er avbestilt, markert som ikke møtt opp, eller ugyldig",
        "subTitle": "Varsler når en avtale er avbestilt"
      },
      "reschedule": {
        "title": "Ombookingsvarsel",
        "note": "Dette varselet sendes for ubekreftede, bekreftede, eller møtte avtaler",
        "subTitle": "Varsler når en avtale ombookes"
      },
      "reminder": {
        "title": "Påminnelsesvarsel",
        "note": "Dette varselet sendes for ubekreftede, bekreftede, eller møtte avtaler",
        "subTitle": "Sender en påminnelse før avtalen"
      },
      "followup": {
        "title": "Oppfølgingsvarsel",
        "note": "Dette varselet sendes for ubekreftede, bekreftede, eller møtte avtaler",
        "subTitle": "Sender en oppfølgingsmelding etter at avtalen er fullført"
      }
    },
    "saveToastMsg": "Innstillinger Lagret!",
    "saveToastMsgDesc": "Endringene dine har blitt lagret",
    "email": {
      "noneTemplate": "Ingen",
      "notificationLabel": "Hvem skal motta dette varselet?",
      "sendButtonLabel": "Send Test E-post",
      "testEmailLabel": "Test E-post",
      "testEmailPlaceholder": "Skriv inn mottakerens e-postadresse for testing",
      "body": "E-postinnhold",
      "bodyPlaceholder": "Skriv inn meldingen din her",
      "subject": "Emne",
      "subjectPlaceholder": "Skriv inn emnet for e-posten",
      "templateLabel": "E-postmal",
      "templatePlaceholder": "Velg en e-postmal eller start fra bunnen av",
      "alertEmailAddress": "Varslings-e-postadresse",
      "addMore": "Legg til flere",
      "afterAppointmentEnds": "etter at avtalen er over",
      "beforeAppointmentStarts": "før avtalen starter",
      "durationLabel": "Når vil du utløse varselet?",
      "disableEmailNotification": "Deaktiver E-postvarsler",
      "enableEmailNotification": "Aktiver E-postvarsler",
      "enableSuccess": "E-postvarsler Aktivert!",
      "enableSuccessDesc": "Du vil nå begynne å motta dette varselet",
      "disableSuccess": "E-postvarsler Deaktivert!",
      "disableSuccessDesc": "Du vil ikke lenger motta dette varselet"
    },
    "inApp": {
      "title": "Varslingsinnstillinger",
      "selectedUser": {
        "label": "Send til",
        "helperText": "Dette interne varselet sendes via nett- og mobilapper",
        "placeholder": "Velg Bruker(e)"
      },
      "enableSuccess": "Appvarsler Aktivert!",
      "enableSuccessDesc": "Du vil nå begynne å motta dette varselet",
      "disableSuccess": "Appvarsler Deaktivert!",
      "disableSuccessDesc": "Du vil ikke lenger motta dette varselet"
    }
  }
}