<script setup lang="ts">
import AppState, { setConnectedCalendars, setUserCalendars } from '@/states/app'
import { UISelect, UISpin, UITextLgMedium } from '@gohighlevel/ghl-ui'
import { computed, ref, watchEffect } from 'vue'

import { ConnectionService } from '@/class/services/ConnectionService'
import ConflictCalendarsCard from './ConflictCalendarsCard.vue'

const connectedCalendars = computed(() => {
  return AppState?.connectedCalendars
})

const props = defineProps({
  selectedConflictAccount: {
    type: String,
    required: true,
  },
})

const isLoading = ref(false)
const selectedAccount = ref('')

const handleCalendarSelectionChange = async (
  isChecked: boolean,
  thirdPartyCalendarId: string,
  thirdPartyCalendarName: string,
  oauthId: string,
  type: string
) => {
  isLoading.value = true

  if (isChecked) {
    const { data } = await ConnectionService.addConnectedCalendar({
      thirdPartyCalendarId,
      thirdPartyCalendarName,
      type,
      oauthId,
      isConflictCalendar: true,
      locationId: AppState.locationId,
      userId: AppState.user?.id,
    })
  } else {
    const userCalendarToBeDeleted = userCalendars.value
      .filter(x => x.isCheckForConflictsCalendar)
      .find(x => x.googleCalendarId === thirdPartyCalendarId)

    if (!userCalendarToBeDeleted) {
      return
    }
    const { data } = await ConnectionService.deleteConnectedCalendar(
      true,
      userCalendarToBeDeleted.id
    )
  }

  const { data: connectedCalendasData } =
    await ConnectionService.getConnectedCalendars(AppState.locationId)

  setConnectedCalendars(connectedCalendasData.thirdPartyCalendars)
  setUserCalendars(connectedCalendasData.userCalendars)

  isLoading.value = false
}

const userCalendars = computed(() => {
  return AppState?.userCalendars
})

const accountOptions = computed(() => {
  return Object.keys(
    connectedCalendars.value[props.selectedConflictAccount]
  ).map(key => {
    return {
      label: key,
      value: key,
    }
  })
})

watchEffect(() => {
  selectedAccount.value =
    accountOptions.value.length && accountOptions.value[0].value
})

const selectedAccountCalendars = computed(() => {
  return connectedCalendars.value[props.selectedConflictAccount][
    selectedAccount.value
  ]
})
</script>

<template>
  <UITextLgMedium class="mb-3">
    {{ $t('settings.connections.check_for_conflicts') }}
  </UITextLgMedium>

  <div v-if="isLoading" class="relative">
    <UISpin :show="true" class="absolute right-44 top-10 z-50"> </UISpin>
  </div>

  <div class="border-b border-gray-300 pb-4">
    <UISelect id="select-account" v-model:value="selectedAccount" :options="accountOptions" />
  </div>

  <ConflictCalendarsCard :key="selectedAccount" :is-loading="isLoading" :account-name="selectedAccount"
    :type="selectedConflictAccount" :connected-calendars="connectedCalendars[selectedConflictAccount][selectedAccount] || []
      " :handle-calendar-selection-change="handleCalendarSelectionChange" />
</template>
