import AppState from '@/states/app'
import drop from 'lodash/drop'
import { ref } from 'vue'
import { CALENDAR_RESOURCE_TYPES } from '../../models/calendar_resource'

export class ResourceList {
  limit
  resourceType
  filters = ref({
    searchText: '',
    page: 1,
  })
  calendars

  constructor(resourceType: string) {
    this.limit = 10
    this.resourceType = resourceType
    this.calendars = (AppState?.globalCalendars as any)?.calendars
  }

  get resourceList() {
    const data = drop(
      this.filters.value.searchText ? this.search : this.allResources,
      this.offset
    )
      .slice(0, this.limit)
      .map((e: any) => {
        return {
          ...e,
        }
      })
    return data
  }

  get offset() {
    const temp = (this.filters?.value.page - 1) * this.limit
    return temp
  }
  getCalName(calendarId) {
    return this.calendars?.find(e => e.id === calendarId)?.name
  }

  isCalendarDeleted(calendarId) {
    const cal = this.calendars?.find(e => e.id === calendarId)
    return !cal || cal?.deleted
  }

  get allResources() {
    return CALENDAR_RESOURCE_TYPES.EQUIPMENTS === this.resourceType
      ? AppState.globalEquipments
      : AppState.globalRooms
  }

  get totalPages() {
    return Math.ceil((this?.allResources?.length || 0) / this.limit)
  }

  get search() {
    const searchText = this.filters.value.searchText?.toLowerCase()
    return this.allResources.filter((resource: any) => {
      return (
        resource.name?.toLowerCase()?.includes(searchText) ||
        resource?.description?.toLowerCase()?.includes(searchText)
      )
    })
  }
}
