<script setup lang="ts">
import AppState, {
  getTeamsInLocation,
  setEditCalendarValidationData,
  setIsAdvancedCalendarPageLoading,
} from '@/states/app'
import {
  CalendarIcon,
  Copy01Icon,
  Edit01Icon,
  PlusIcon,
  Trash01Icon,
  XIcon,
} from '@gohighlevel/ghl-icons/24/outline'
import {
  UIButton,
  UICheckbox,
  UIDivider,
  UIForm,
  UIFormItem,
  UIHeader,
  UIInputNumber,
  UIRadio,
  UISelect,
  UISwitch,
  UITimePicker,
  UITooltip,
} from '@gohighlevel/ghl-ui'
import { computed, reactive, ref, watch, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import { POSITION, TYPE, useToast } from 'vue-toastification'
import { MyAvailability } from '../../class/pages/MyAvailability'
import { CalendarService } from '../../class/services/CalendarService'
import { CalendarType } from '../../models/calendar'
import { getTimeSpanOptions, getTooltip } from '../../utils/generalFunctions'
import CustomAvailabilityPicker from '../Settings/CustomAvailabilityPicker.vue'
import CustomRecurrenceModal from './CustomRecurrenceModal.vue'
import DurationPicker from './DurationPicker.vue'

const myAvailability = ref(new MyAvailability(''))
const customAvailabilityPicker = ref()
const formRef = ref()
const { t } = useI18n()

const toast = useToast()

const recurringFrequencyOptions = [
  {
    value: 'DAILY',
    label: t('calendar_advanced.availability.daily'),
  },
  {
    value: 'WEEKLY',
    label: t('calendar_advanced.availability.weekly'),
  },
  {
    value: 'MONTHLY',
    label: t('calendar_advanced.availability.monthly'),
  },
  {
    value: 'CUSTOM',
    label: t('calendar_advanced.availability.custom'),
  },
]

const recurringBookingOptions = [
  {
    value: 'skip',
    label: t('calendar_advanced.availability.skip_booking'),
  },
  {
    value: 'continue',
    label: t('calendar_advanced.availability.continue_booking'),
  },
  {
    value: 'book_next',
    label: t('calendar_advanced.availability.book_next_available'),
  },
]

const recurringOverlapStatusOptions = [
  {
    value: 'confirmed',
    label: t('calendar_advanced.availability.confirmed'),
  },
  {
    value: 'new',
    label: t('calendar_advanced.availability.unconfirmed'),
  },
]

const state = reactive({
  isLoading: false,
  isStandardAvailability: true,
  oldFreqValue: '',
  isCustomRecurring: false,
  rruleText: '',
  formData: {
    openHours: [],
    enableRecurring: false,
    recurring: {
      count: 1,
      bookingOption: 'skip',
      bookingOverlapDefaultStatus: '',
      interval: null as null | number,
      freq: 'DAILY',
      monthDays: [],
      weekDays: [],
    },
    slotDuration: '30',
    slotDurationUnit: 'mins',
    slotInterval: '30',
    slotIntervalUnit: 'mins',
    slotBuffer: '',
    slotBufferUnit: 'mins',
    allowBookingFor: '',
    allowBookingForUnit: 'days',
    allowBookingAfter: '',
    allowBookingAfterUnit: 'days',
    appoinmentPerSlot: 1 as number | undefined,
    appoinmentPerDay: 1 as number | undefined,
  },
  showCustomRecurrenceModal: false,
})
const editingCalendar = computed(() => {
  return AppState.editingCalendar
})

const emit = defineEmits(['onSave', 'onCancel'])

const methods = {
  async loadData() {
    setIsAdvancedCalendarPageLoading(true)

    myAvailability.value.setOpenHoursDataFromDatabase(
      editingCalendar.value?.openHours || []
    )

    state.isStandardAvailability = editingCalendar.value?.availabilityType !== 1

    state.formData.recurring = editingCalendar.value?.recurring || {
      count: 1,
      bookingOption: 'skip',
      bookingOverlapDefaultStatus: '',
      interval: null as null | number,
      freq: 'DAILY',
      monthDays: [],
      weekDays: [],
    }

    state.formData.recurring.bookingOption =
      editingCalendar.value?.recurring?.bookingOption || 'skip'

    state.formData.recurring.freq =
      editingCalendar.value?.recurring?.freq || 'DAILY'

    state.formData.recurring.count =
      editingCalendar.value?.recurring?.count || 1

    if (
      state.formData.recurring?.monthDays?.length > 0 ||
      state.formData.recurring?.weekDays?.length > 0 ||
      state.formData.recurring?.interval
    ) {
      state.isCustomRecurring = true
    }

    state.formData.enableRecurring = editingCalendar.value
      ?.enableRecurring as boolean

    state.formData.slotDuration =
      editingCalendar.value?.slotDurationUnit === 'hours'
        ? String(editingCalendar.value?.slotDuration / 60 || '')
        : String(editingCalendar.value?.slotDuration || '')
    state.formData.slotDurationUnit =
      editingCalendar.value?.slotDurationUnit || 'mins'
    state.formData.slotInterval =
      editingCalendar.value?.slotIntervalUnit === 'hours'
        ? String(editingCalendar.value?.slotInterval / 60 || '')
        : String(editingCalendar.value?.slotInterval || '')
    state.formData.slotIntervalUnit =
      editingCalendar.value?.slotIntervalUnit || 'mins'
    state.formData.slotBuffer =
      editingCalendar.value?.slotBufferUnit === 'hours'
        ? String(editingCalendar.value?.slotBuffer / 60 || '')
        : String(editingCalendar.value?.slotBuffer || '')
    state.formData.slotBufferUnit =
      editingCalendar.value?.slotBufferUnit || 'mins'
    state.formData.allowBookingFor = String(
      editingCalendar.value?.allowBookingFor || ''
    )
    state.formData.allowBookingForUnit =
      editingCalendar.value?.allowBookingForUnit || 'days'
    state.formData.allowBookingAfter = String(
      editingCalendar.value?.allowBookingAfter || ''
    )
    state.formData.allowBookingAfterUnit =
      editingCalendar.value?.allowBookingAfterUnit || 'days'
    state.formData.appoinmentPerSlot = editingCalendar.value?.appoinmentPerSlot
    state.formData.appoinmentPerDay = editingCalendar.value?.appoinmentPerDay

    await Promise.all([methods.loadGroups()])

    setIsAdvancedCalendarPageLoading(false)
  },
  async loadGroups() {
    await getTeamsInLocation()
  },
  async emitSave() {
    if (state.isStandardAvailability) {
      if (myAvailability.value.hasAnyInvalidHours) {
        toast(t('calendar_advanced.availability.enter_valid_hours'), {
          type: TYPE.ERROR,
          position: POSITION.BOTTOM_RIGHT,
        })
        return null
      }
    } else if (customAvailabilityPicker.value.methods.hasErrors()) {
      toast(t('calendar_advanced.availability.enter_valid_avail'), {
        type: TYPE.ERROR,
        position: POSITION.BOTTOM_RIGHT,
      })
      return null
    }

    try {
      await formRef.value.getForm().validate()
    } catch (error) {
      return
    }
    if (
      AppState.errorInDurationPickerMap.slotDuration ||
      AppState.errorInDurationPickerMap.slotInterval ||
      AppState.errorInDurationPickerMap.slotBuffer
    ) {
      return null
    }

    const data = {
      openHours: myAvailability.value.availableDays.map(day =>
        day.serializeAsPerAPIFormat()
      ),
      availabilities: !state.isStandardAvailability
        ? customAvailabilityPicker.value.methods.getAvailability()
        : [],
      availabilityType: state.isStandardAvailability ? 0 : 1,
      recurring: state.formData.recurring,
      enableRecurring: state.formData.enableRecurring,
      slotDuration:
        state.formData.slotDurationUnit === 'mins'
          ? parseInt(state.formData.slotDuration)
          : parseInt(state.formData.slotDuration) * 60,
      slotDurationUnit: state.formData.slotDurationUnit,
      slotInterval:
        state.formData.slotIntervalUnit === 'mins'
          ? parseInt(state.formData.slotInterval)
          : parseInt(state.formData.slotInterval) * 60,
      slotIntervalUnit: state.formData.slotIntervalUnit,
      slotBuffer:
        state.formData.slotBufferUnit === 'mins'
          ? parseInt(state.formData.slotBuffer)
          : parseInt(state.formData.slotBuffer) * 60,
      slotBufferUnit: state.formData.slotBufferUnit,
      allowBookingFor: parseInt(state.formData.allowBookingFor),
      allowBookingForUnit: state.formData.allowBookingForUnit,
      allowBookingAfter: parseInt(state.formData.allowBookingAfter),
      allowBookingAfterUnit: state.formData.allowBookingAfterUnit,
      appoinmentPerSlot: state.formData.appoinmentPerSlot
        ? Number(state.formData.appoinmentPerSlot)
        : '',
      appoinmentPerDay: state.formData.appoinmentPerDay
        ? Number(state.formData.appoinmentPerDay)
        : '',
    }

    return data
  },
  updateFrequency(value: string) {
    if (value === 'CUSTOM') {
      state.oldFreqValue = state.formData.recurring.freq
      state.showCustomRecurrenceModal = true
    } else {
      state.isCustomRecurring = false
      state.formData.recurring.monthDays = []
      state.formData.recurring.weekDays = []
      state.formData.recurring.interval = null
      state.formData.recurring.freq = value
    }
  },
  customRecurrenceModalSave(data: any) {
    state.isCustomRecurring = true
    state.formData.recurring = {
      ...state.formData.recurring,
      ...data,
    }
    state.showCustomRecurrenceModal = false
  },
  customRecurrenceModalCancel() {
    state.showCustomRecurrenceModal = false
    state.formData.recurring.freq = state.oldFreqValue
  },
}

defineExpose({
  methods,
})
watchEffect(async () => {
  const { data } = await CalendarService.generateRRuleText({
    freq: state.formData.recurring.freq,
    interval: state.formData.recurring.interval,
    count: state.formData.recurring.count,
    weekDays: state.formData.recurring.weekDays,
    monthDays: state.formData.recurring.monthDays,
  })

  state.rruleText = data
})

const rules = {
  appoinmentPerSlot: {
    validator() {
      if (editingCalendar.value?.calendarType === CalendarType.CLASS_BOOKING) {
        if (!state.formData.appoinmentPerSlot) {
          return new Error('This is required')
        }

        if (state.formData.appoinmentPerSlot > 9999) {
          return new Error('This cannot be greater than 9999')
        }
      }

      if (state.formData.appoinmentPerSlot < 0) {
        return new Error('This cannot be less than 0')
      }

      return true
    },
    trigger: ['input', 'blur'],
  },
  recurringCount: {
    validator() {
      if (state.formData.enableRecurring) {
        if (state.formData.recurring.count > 24) {
          return new Error('Count cannot be greater than 24')
        } else if (state.formData.recurring.count < 1) {
          return new Error('Count cannot be less than 1')
        }
      }

      return true
    },
    trigger: ['input', 'blur'],
  },
  appoinmentPerDay: {
    validator() {
      if (state.formData.appoinmentPerDay < 0) {
        return new Error('This cannot be less than 0')
      }
      return true
    },
    trigger: ['input', 'blur'],
  },
}

watch(
  () => state.formData.enableRecurring,
  () => {
    setEditCalendarValidationData(
      'enableRecurring',
      state.formData.enableRecurring
    )
  },
  { immediate: true }
)

watch(
  () => state.isStandardAvailability,
  () => {
    if (!state.isStandardAvailability) {
      state.formData.enableRecurring = false
    }
  }
)

watch(
  () => editingCalendar.value,
  () => {
    methods.loadData()
  },
  { immediate: true }
)

const isServiceCalendar = computed(() => {
  return editingCalendar.value?.isServiceCalendar
})

const hideAvailabilityType = computed(() => isServiceCalendar.value)

const bufferOptionForServices = computed(() => {
  const MAX_BUFFER = 90
  return getTimeSpanOptions(
    MAX_BUFFER,
    0,
    editingCalendar.value?.slotInterval || 0
  )
})

const durationOptionForServices = computed(() => {
  const MAX_DURATION = 360
  return getTimeSpanOptions(
    MAX_DURATION,
    editingCalendar.value?.slotInterval || 0,
    editingCalendar.value?.slotInterval || 0
  )
})
</script>

<template>
  <UIHeader id="text-only-header" :secondary-title="$t('calendar_advanced.availability.my_availability')"
    :description="$t('calendar_advanced.availability.set_availability')">
  </UIHeader>

  <UIDivider />

  <CustomRecurrenceModal :show="state.showCustomRecurrenceModal" @on-modal-close="methods.customRecurrenceModalCancel"
    @on-confirm="methods.customRecurrenceModalSave" />

  <UIForm id="create-calendar-form" ref="formRef" :model="state.formData" :rules="rules" class="w-full">
    <UIFormItem v-if="!hideAvailabilityType" :label="$t('create_calendar.availability_type')" :tooltip="getTooltip(
      $t('calendar_advanced.availability.availability_type_tooltip')
    )
      " path="logo">
      <div class="flex space-x-10">
        <div id="standard" class="flex w-56 cursor-pointer items-center justify-between rounded-lg border p-4" :class="{
          'border-blue-500 bg-blue-50': state.isStandardAvailability,
        }" @click="state.isStandardAvailability = true">
          <div class="flex items-center space-x-4">
            <div class="rounded-full bg-blue-100 p-1">
              <CalendarIcon class="h-4 w-6 text-blue-700" />
            </div>
            <div class="">
              {{ $t('calendar_advanced.availability.standard') }}
            </div>
          </div>
          <UIRadio id="standard-radio" :checked="state.isStandardAvailability" value="standard" />
        </div>

        <div id="custom" class="flex w-56 cursor-pointer items-center justify-between rounded-lg border p-4" :class="{
          'border-blue-500 bg-blue-50': !state.isStandardAvailability,
        }" @click="state.isStandardAvailability = false">
          <div class="flex items-center space-x-4">
            <div class="rounded-full bg-blue-100 p-1">
              <Edit01Icon class="h-4 w-6 text-blue-700" />
            </div>

            <div class="">
              {{ $t('calendar_advanced.availability.custom') }}
            </div>
          </div>
          <UIRadio id="custom-radio" :checked="!state.isStandardAvailability" value="custom" />
        </div>
      </div>
    </UIFormItem>

    <UIDivider v-if="!hideAvailabilityType" />

    <div v-if="!hideAvailabilityType && state.isStandardAvailability">
      <div class="flex space-x-3">
        <UICheckbox v-for="day in MyAvailability.OFFICE_DAYS" :id="day" :key="day"
          v-model:checked="myAvailability.dayWiseOpenMap[day]" :name="day"
          class="text-md flex w-24 content-center items-center rounded-md border bg-white p-2" :class="{
            'border-primary-500 bg-primary-50':
              myAvailability.availableDays.find(x => x.day === day),
          }" @change="x =>
    x
      ? myAvailability.addAvailabilityDay(day)
      : myAvailability.removeAvailabilityDay(day)
  ">
          <span class="ml-0.5">{{ day.substring(0, 3) }}</span>
        </UICheckbox>
      </div>

      <div>
        <div v-for="(availableDay, index) in myAvailability.availableDays" :key="index" class="my-4 flex">
          <div class="font-md my-2 mr-32 w-12 font-semibold text-gray-700">
            {{ availableDay.day }}
          </div>

          <div>
            <div v-for="(availableDayHour, hourIndex) in availableDay.hours" :key="hourIndex"
              class="my-2 flex items-center">
              <UITimePicker v-model:formatted-value="availableDayHour.startTime" value-format="H:m" :minutes="5"
                format="hh:mm a" use-12-hours :actions="['confirm']" :status="availableDay.hoursErrorMapping[hourIndex] ||
                    !availableDayHour.startTime
                    ? 'error'
                    : 'success'
                  " />

              <span class="mx-2 font-semibold">{{
                $t('calendar_advanced.availability.to')
              }}</span>
              <UITimePicker v-model:formatted-value="availableDayHour.endTime" value-format="H:m" :minutes="5"
                format="hh:mm a" use-12-hours :actions="['confirm']" :status="availableDay.hoursErrorMapping[hourIndex] ||
                    !availableDayHour.endTime
                    ? 'error'
                    : 'success'
                  " />

              <UIButton v-if="hourIndex > 0" id="" class="ml-7 text-red-600" :text="true"
                @click="availableDay.removeAvailabilityHour(hourIndex)">
                <Trash01Icon class="w-4 w-4"></Trash01Icon>
              </UIButton>

              <UIButton v-if="index === 0 && hourIndex === 0" id="" class="ml-4" @click="x => myAvailability.copyAvailabilieToAllDays(availableDay)
                ">
                <Copy01Icon class="mr-2 w-4 w-4"></Copy01Icon>
                {{ $t('calendar_advanced.availability.copy_to_all') }}
              </UIButton>
            </div>

            <UIButton id="" :text="true" class="mt-2" @click="x => availableDay.addNewAvailabilityHour()">
              <PlusIcon class="mr-2 h-4 w-4" />
              {{ $t('calendar_advanced.availability.add_time') }}
            </UIButton>
          </div>
        </div>

        <!-- <div
              :class="{
                'ml-40 mt-2 pl-3': myAvailability.availableDays.length > 0,
              }"
            >
              <UIButton
                id=""
                :loading="myAvailability.isProcessing"
                :disabled="myAvailability.hasAnyInvalidHours"
                type="primary"
                @click="myAvailability.saveMyAvailability()"
              >
                Save Availability
              </UIButton>
            </div> -->
      </div>
    </div>

    <div v-if="!hideAvailabilityType && !state.isStandardAvailability">
      <CustomAvailabilityPicker ref="customAvailabilityPicker" />
    </div>

    <div v-if="state.isStandardAvailability && !isServiceCalendar" class="">
      <UIDivider />

      <div v-if="editingCalendar?.calendarType === CalendarType.EVENT ||
        (AppState.editCalendarValidationData?.teamMembers &&
          AppState.editCalendarValidationData?.teamMembers?.length <= 1)
        " class="flex items-center space-x-3 pb-3 pt-4">
        <UISwitch id="calendar-recurringMeeting-switch" v-model:value="state.formData.enableRecurring">
        </UISwitch>
        <div class="font-normal">
          {{ $t('calendar_advanced.availability.recurring_meeting') }}
        </div>
      </div>

      <UITooltip v-else placement="top-start">
        <template #trigger>
          <div class="flex items-center space-x-3 pb-3 pt-4">
            <UISwitch v-model:value="state.formData.enableRecurring" :disabled="true">
            </UISwitch>
            <div class="font-normal">
              {{ $t('calendar_advanced.availability.recurring_meeting') }}
            </div>
          </div>
        </template>

        {{ $t('calendar_advanced.availability.recurring_info') }}
      </UITooltip>
    </div>

    <div v-if="state.formData.enableRecurring" class="mt-6">
      <div class="flex items-start space-x-9">
        <UIFormItem :label="$t('calendar_advanced.availability.repeat')" path="recurring.freq"
          :tooltip="getTooltip('Select the frequency of this recurring event.')">
          <div class="flex flex-col space-y-1">
            <UISelect id="calendar-recurrence-repeat" :value="state.isCustomRecurring
                ? 'CUSTOM'
                : state.formData.recurring.freq
              " class="w-64" :options="recurringFrequencyOptions" @update:value="methods.updateFrequency" />

            <div class="w-64">
              <div v-if="state.isCustomRecurring" class="flex items-center space-x-2 capitalize text-gray-400">
                <div class="">
                  {{ state.rruleText }}
                </div>
                <XIcon class="h-5 w-5 flex-grow cursor-pointer" @click="() => methods.updateFrequency('DAILY')" />
              </div>
              <div v-else class="capitalize text-gray-400">
                {{ state.rruleText }}
              </div>
            </div>
          </div>
        </UIFormItem>

        <UIFormItem :label="$t('calendar_advanced.availability.times_to_repeat')" path="recurringCount">
          <UIInputNumber id="calendar-recurrence-count" v-model="state.formData.recurring.count" class="w-64" />
        </UIFormItem>
      </div>

      <div class="flex space-x-9">
        <UIFormItem :label="$t('calendar_advanced.availability.slots_unavailable')" path="recurring.bookingOption">
          <UISelect id="calendar-recurrence-recurrenceFreq" v-model:value="state.formData.recurring.bookingOption"
            class="w-64" :options="recurringBookingOptions" />
        </UIFormItem>

        <UIFormItem v-if="state.formData.recurring.bookingOption === 'continue'"
          :label="$t('calendar_advanced.availability.overlap_status')" path="recurring.bookingOverlapDefaultStatus">
          <UISelect id="calendar-bookingOverlapDefaultStatus"
            v-model:value="state.formData.recurring.bookingOverlapDefaultStatus" class="w-80"
            :options="recurringOverlapStatusOptions" />
        </UIFormItem>
      </div>
    </div>
    <UIDivider v-if="!hideAvailabilityType" />

    <div class="flex space-x-8">
      <DurationPicker v-if="!isServiceCalendar" :label="$t('calendar_advanced.availability.meeting_interval')"
        :tooltip="$t('calendar_advanced.availability.meeting_interval_tooltip')" path="slotInterval"
        :value="state.formData.slotInterval" :unit="state.formData.slotIntervalUnit" @on-value-change="value => {
            state.formData.slotInterval = value.duration
            state.formData.slotIntervalUnit = value.timeUnit
          }
          " />
      <DurationPicker v-if="!isServiceCalendar" :label="$t('calendar_advanced.availability.meeting_duration')"
        path="slotDuration" :value="state.formData.slotDuration" :unit="state.formData.slotDurationUnit" @on-value-change="value => {
            state.formData.slotDuration = value.duration
            state.formData.slotDurationUnit = value.timeUnit
          }
          " />

      <UIForm v-if="isServiceCalendar" class="flex w-full space-x-8">
        <UIFormItem class="mt-4 w-full" :label="$t('create_calendar.service_duration')" path="slotDuration">
          <UISelect id="calendar-slot-duration" :value="`${state.formData.slotDuration || 0} mins`" class="w-full"
            :options="durationOptionForServices" @update:value="state.formData.slotDuration = $event" />
        </UIFormItem>
        <UIFormItem class="mt-4 w-full" :label="$t('calendar_advanced.availability.buffer_time')" path="slotBuffer">
          <UISelect id="calendar-slot-buffer" :value="`${state.formData.slotBuffer || 0} mins`" class="w-full"
            :options="bufferOptionForServices" @update:value="state.formData.slotBuffer = $event" />
        </UIFormItem>
      </UIForm>
    </div>

    <div class="flex space-x-8">
      <DurationPicker :label="$t('calendar_advanced.availability.minimum_scheduling')" path="allowBookingAfter"
        :disabled="!state.isStandardAvailability" :value="state.formData.allowBookingAfter"
        :unit="state.formData.allowBookingAfterUnit" @on-value-change="value => {
            state.formData.allowBookingAfter = value.duration
            state.formData.allowBookingAfterUnit = value.timeUnit
          }
          " />
      <DurationPicker :label="$t('calendar_advanced.availability.date_range')"
        :tooltip="$t('calendar_advanced.availability.date_range_tooltip')" path="allowBookingFor"
        :disabled="!state.isStandardAvailability" :value="state.formData.allowBookingFor"
        :unit="state.formData.allowBookingForUnit" @on-value-change="value => {
            state.formData.allowBookingFor = value.duration
            state.formData.allowBookingForUnit = value.timeUnit
          }
          " />
    </div>
    <div v-if="![CalendarType.CLASS_BOOKING, CalendarType.SERVICE].includes(
      editingCalendar?.calendarType
    )
      " class="mt-4 flex items-end space-x-8">
      <div class="w-1/2">
        <UIFormItem :label="$t('calendar_advanced.availability.maximum_bookings_per_day')" path="appoinmentPerDay"
          :tooltip="getTooltip(
            $t(
              'calendar_advanced.availability.maximum_bookings_per_day_tooltip'
            )
          )
            ">
          <UIInputNumber id="calendar-appointments-perDay" v-model="state.formData.appoinmentPerDay" class="w-full"
            placeholder="" />
        </UIFormItem>
      </div>
      <div v-if="!isServiceCalendar" class="w-1/2">
        <UIFormItem :label="(editingCalendar?.calendarType === CalendarType.ROUND_ROBIN || editingCalendar?.calendarType === CalendarType.PERSONAL)
            ? $t(
              'calendar_advanced.availability.maximum_bookings_per_slot_per_user'
            )
            : $t('calendar_advanced.availability.maximum_bookings_per_slot')
          " path="appoinmentPerSlot" :tooltip="getTooltip(
    $t(
      editingCalendar?.calendarType === CalendarType.PERSONAL
      ? 'calendar_advanced.availability.maximum_bookings_per_slot_tooltip_personal'
      : 'calendar_advanced.availability.maximum_bookings_per_slot_tooltip'
    )
  )
    ">
          <UIInputNumber id="calendar-appointments-perSlot" v-model="state.formData.appoinmentPerSlot" class="w-full"
            placeholder="" />
        </UIFormItem>
      </div>
    </div>
    <div class="flex items-end space-x-8">
      <div v-if="editingCalendar?.calendarType === CalendarType.CLASS_BOOKING" class="w-1/2">
        <UIFormItem :label="$t('calendar_advanced.availability.seats_per_class')" path="appoinmentPerSlot">
          <UIInputNumber id="calendar-appointments-per-seat" v-model="state.formData.appoinmentPerSlot" class="w-full"
            placeholder="" />
        </UIFormItem>
      </div>
      <div class="w-1/2">
        <DurationPicker v-if="!isServiceCalendar" :label="$t('calendar_advanced.availability.buffer_time')"
          path="slotBuffer" :value="state.formData.slotBuffer" :unit="state.formData.slotBufferUnit" @on-value-change="value => {
              state.formData.slotBuffer = value.duration
              state.formData.slotBufferUnit = value.timeUnit
            }
            " />
      </div>
    </div>
  </UIForm>
</template>
