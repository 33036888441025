export const EVENT_COLORS = [
  {
    colorName: 'Tomato',
    colorHexCode: '#D50000',
  },
  {
    colorName: 'Flamingo',
    colorHexCode: '#E67C73',
  },
  {
    colorName: 'Tangerine',
    colorHexCode: '#F4511E',
  },
  {
    colorName: 'Banana',
    colorHexCode: '#F6BF26',
  },
  {
    colorName: 'Sage',
    colorHexCode: '#33B679',
  },
  {
    colorName: 'Basil',
    colorHexCode: '#0B8043',
  },
  {
    colorName: 'Peacock',
    colorHexCode: '#039BE5',
  },
  {
    colorName: 'Blueberry',
    colorHexCode: '#3F51B5',
  },
  {
    colorName: 'Lavender',
    colorHexCode: '#7986CB',
  },
  {
    colorName: 'Grape',
    colorHexCode: '#8E24AA',
  },
  {
    colorName: 'Graphite',
    colorHexCode: '#616161',
  },
]
