import config from '@/config'
import { requests } from './BaseService'

export const ConnectionService = {
  getConnections: (locationId: string) => {
    if (!locationId) {
      return
    }
    return requests().get(`${config.calendarApiUrl}/calendars/connections`, {
      params: {
        locationId,
      },
    })
  },
  getConnectedCalendars: (locationId: string) =>
    requests().get(`${config.calendarApiUrl}/calendars/connections/calendars`, {
      params: {
        locationId,
      },
    }),
  addConnectedCalendar: (body: any) =>
    requests().post(
      `${config.calendarApiUrl}/calendars/connections/calendars`,
      body
    ),
  deleteConnectedCalendar: (
    isConflictCalendar: boolean,
    userCalendarId?: string
  ) =>
    requests().delete(
      `${config.calendarApiUrl}/calendars/connections/calendars`,
      {
        data: {
          isConflictCalendar,
          userCalendarId,
        },
      }
    ),
  updatePrimaryCalSettings: (body: any) =>
    requests().put(
      `${config.calendarApiUrl}/calendars/connections/settings`,
      body
    ),
  removeIntegration: (locationId: string, oauthId: string) => {
    return requests().get(
      `${config.calendarApiUrl}/calendars/connections/remove`,
      {
        params: {
          locationId,
          oauthId,
        },
      }
    )
  },
  integrateICloud: (body: {
    locationId: string;
    userId: string;
    email: string;
    password: string;
  }) => requests().post<{
    locationId: string;
    userId: string;
    email: string;
    password: string;
  }>("/calendars/external-calendars/calDav/icloud", body),
}
