<script setup lang="ts">
import AppState, { isEquipmentEnabled, isRoomEnabled } from '@/states/app'
import {
  UICheckbox,
  UIEmpty,
  UIForm,
  UIFormItem,
  UIHeader,
  UIRadio,
  UITabPane,
  UITabs,
} from '@gohighlevel/ghl-ui'
import uniq from 'lodash/uniq'
import { computed, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
import { ResourceList } from '../../class/pages/ResourceList'
import { CALENDAR_RESOURCE_TYPES } from '../../models/calendar_resource'

const state = reactive({
  isLoading: false,
  equipments: AppState.globalEquipments,
  rooms: AppState.globalRooms,
})
const selectedRooms: any = ref([])
const toRemoveRooms: any = ref([])
const roomsResources = new ResourceList(CALENDAR_RESOURCE_TYPES.ROOMS)
const rooms = computed(() => {
  return roomsResources.allResources?.filter((room: any) => room.isActive)
})

const selectedEquipment: any = ref()
const toRemoveEquipments: any = ref([])
const equipmentsResources = new ResourceList(CALENDAR_RESOURCE_TYPES.EQUIPMENTS)
const equipments = computed(() => {
  return equipmentsResources.allResources?.filter((equip: any) => equip.isActive)
})
const route = useRoute()
const calendarId = route.params.calendarId as string
const tabType = ref(isRoomEnabled() ? 'rooms' : 'equipments')

if (calendarId && rooms.value?.length) {
  for (const room of rooms.value) {
    if ((room as any)?.calendarIds?.includes(calendarId)) {
      selectedRooms.value?.push((room as any)._id)
    }
  }
}

if (calendarId && equipments.value?.length) {
  const equipment = equipments.value?.find(e => (e as any)?.calendarIds?.includes(calendarId))
  selectedEquipment.value = (equipment as any)?._id
}

const emit = defineEmits(['onSave', 'onCancel'])

const isRoomSelected = id => {
  return selectedRooms.value?.map(e => e)?.includes(id)
}

const selectRoom = (val, id: string) => {
  if (val) {
    const _rooms = selectedRooms?.value?.map(e => e)?.concat([id])
    selectedRooms.value = uniq(_rooms)
    toRemoveRooms.value = uniq(toRemoveRooms.value?.filter(e => e !== id))
  } else {
    selectedRooms.value = selectedRooms.value
      ?.map(e => e)
      ?.filter(e => e !== id)
    toRemoveRooms.value = uniq(toRemoveRooms.value?.concat([id]))
  }
}

const isEquipmentSelected = id => {
  return selectedEquipment.value === id
}

const selectEquipments = (event) => {
  const resourceId = event.target.value
  if (resourceId) {
    selectedEquipment.value = resourceId
    const _toRemoveEquipments = equipments?.value?.filter((equipment: any) => {
      return equipment?._id !== resourceId && equipment?.calendarIds?.includes(calendarId)
    })
    toRemoveEquipments.value = _toRemoveEquipments?.map((e: any) => e._id)
  }
}

const methods = {
  async emitSave() {
    return {
      selectedRooms: selectedRooms.value,
      selectedEquipments: selectedEquipment.value,
      toRemoveEquipments: toRemoveEquipments.value,
      toRemoveRooms: toRemoveRooms.value,
    }
  },
}
defineExpose({
  methods,
})
</script>
<template>
  <UIHeader id="text-only-header" :secondary-title="$t('calendar_advanced.roomsAndEquipments.title')"
    :description="$t('calendar_advanced.roomsAndEquipments.description')">
  </UIHeader>
  <UITabs v-model:value="tabType" type="line">
    <UITabPane v-if="isRoomEnabled()" name="rooms" :tab="$t('settings.calendar.rooms_tab_name')">
      <UIForm id="room-form" class="w-full">
        <UIEmpty v-if="roomsResources.allResources.length === 0" id="empty-rooms"
          :title="$t('settings.calendar.no_rooms_in_location_title')"
          :description="$t('settings.calendar.no_rooms_in_location')">
        </UIEmpty>
        <UIFormItem v-else path="logo">
          <div class="flex flex-col space-y-5">
            <div v-for="(room, index) in rooms" :key="index">
              <UICheckbox :id="$t(`rooms-check-${(room as any)._id}`)" :checked="isRoomSelected((room as any)._id)"
                @update:checked="val => selectRoom(val, (room as any)._id)">{{ $t((room as any).name) }}
              </UICheckbox>
            </div>
          </div>
        </UIFormItem>
      </UIForm>
    </UITabPane>
    <UITabPane v-if="isEquipmentEnabled()" name="equipments" :tab="$t('settings.calendar.equipment_tab_name')">
      <UIForm id="eqiupments-form" class="w-full">
        <UIEmpty v-if="equipmentsResources.allResources?.length === 0" id="empty-equipments"
          :title="$t('settings.calendar.no_equipments_in_location_title')"
          :description="$t('settings.calendar.no_equipments_in_location')">
        </UIEmpty>
        <UIFormItem v-else path="logo">
          <div class="flex flex-col space-y-5">
            <div v-for="(equipment, index) in equipments" :key="index">
              <UIRadio :id="$t(`equipment_radio_${(equipment as any)._id}`)"
                :checked="isEquipmentSelected((equipment as any)._id)" :label="$t((equipment as any).name)"
                :value="$t((equipment as any)._id)" @change="selectEquipments" />
            </div>
          </div>
        </UIFormItem>
      </UIForm>
    </UITabPane>
  </UITabs>
</template>
